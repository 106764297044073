import React, { useState, useEffect } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { OuterContainer, HomeContainer } from "../Home/Home.styles";
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import UserDashboard from "../../components/Home/UserDashboard";
import { API } from "../../services/api";

function Home(props) {
  const [userData, setUserData] = useState({});

  const me = async () => {
    let res = await API.get(`me`);

    if (res) {
      const logo_name =
        res.data.first_name?.charAt(0).toUpperCase() +
        res.data.last_name?.charAt(0).toUpperCase();
      setUserData({ ...res.data, logo_name });
      sessionStorage.setItem("me", logo_name);
      sessionStorage.setItem("name",res.data.first_name+" "+res.data.last_name)
    }
  };
  useEffect(() => me(), []);
  return(
    <OuterContainer>
      <UserSidebar headerPath="Dashboard" />
      <HomeContainer>
        <Header data={userData} headerPath="/Dashboard" />
        <UserDashboard />
      </HomeContainer>
    </OuterContainer>
  );
}

export default Home;
