import { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { GS, isWhatPercentOf, publish } from "../../services/global";
import "./css.css";
import GradientButton from "../Button/GradientButton";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setLockedProperties,
} from "../../redux/actions";
import { commaSeparators, CurrencyFormatter, getRate } from "../../utils";
import { InputNumbers, MySlider } from "../Equity/Equity.style";
import { Toast } from "primereact/toast";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";
import { getTaxIncome,c,cp } from "../../services/global";
import { InputTexts } from "../Equity/Equity.style";
import { SetAutoSave } from "../../redux/actions";
import { useGeneratorECLE } from "../../CustomHooks/useGeneratorECLE";

let isloaded = false;
function PropertyGenerator(props: any) {
  const toast: any = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [propertytype, setPropertytype] = useState(
    props?.Data?.summaryData?.propertyType
  );
  const [PurchasePrice, setPurchasePrice] = useState(
    props?.Data?.summaryData?.PurchasePrice
  );
  const [Deposit, setDeposit] = useState(props?.Data?.summaryData?.Deposit);
  const [StampDuty, setStampDuty] = useState(
    props?.Data?.summaryData?.StampDuty
  );
  const [AcquisitionCost, setAcquisitionCost] = useState(
    props?.Data?.summaryData?.AcquisitionCost
  );
  const [TotalDepositRequired, setTotalDepositRequired] = useState(
    props?.Data?.summaryData?.TotalDepositRequired
  );
  const [mortgageInsuranceApply, setMortgageInsuranceApply] = useState(
    props?.Data?.summaryData?.LMI == 0 ? "N" : "Y" 
  );
  const [LMI, setLMI] = useState(props?.Data?.summaryData?.LMI);
  const [TotalPurchaseCosts, setTotalPurchaseCosts] = useState(
    props?.Data?.summaryData?.TotalPurchaseCosts
  );
  const [LoantoValueRatioLVR, setLoantoValueRatioLVR] = useState(
    props?.Data?.summaryData?.LoantoValueRatioLVR
  );
  const [TotalDebt, setTotalDebt] = useState(
    props?.Data?.summaryData?.TotalDebt
  );

  const [TotalDepositEquity, setTotalDepositEquity] = useState(
    props?.Data?.summaryData?.TotalDepositEquity
  );
  const [TotalDepositCash, setTotalDepositCash] = useState(
    props?.Data?.summaryData?.TotalDepositCash
  );
  const [useExtraCash, setUseExtraCash] = useState(props?.Data?.useExtraCash);
  const [TotalDepositExtraCash, setTotalDepositExtraCash] = useState(
    props?.Data?.summaryData?.TotalDepositExtraCash || 0
  );
  const [useLiquidAssets, setUseLiquidAssets] = useState(
    props?.Data?.useLiquidAssets
  );
  const [TotalDepositLiquidAssets, setTotalDepositLiquidAssets] = useState(
    props?.Data?.summaryData?.TotalDepositLiquidAssets || 0
  );
  const [PropertyRentIncomeYear1, setPropertyRentIncomeYear1] = useState(
    props?.Data?.summaryData?.PropertyRentIncomeYear1
  );
  const [InterestPayments, setInterestPayments] = useState(
    props?.Data?.summaryData?.InterestPayments || 0
  )
  const [PrinciplePayments, setPrinciplePayments] = useState(
    props?.Data?.summaryData?.PrinciplePayments || 0
  )
  const [CashflowAnnual, setCashflowAnnual] = useState(
    props.Data.summaryData.CashflowAnnual
  );
  const [CashflowMonthly, setCashflowMonthly] = useState(
    props?.Data?.summaryData?.CashflowMonthly
  );
  const [EquityGainEdit, setEquitGainEdit] = useState(false)
  const [AnnualGrowthEdit, setAnnualGrowthEdit] = useState(false)
  const [cashGainEdit, setCashGainEdit] = useState(false);
  const [AppliedGrowthRateannualPERC, setAppliedGrowthRateannualPERC] =
    useState(props?.Data?.summaryData?.AppliedGrowthRateannualPERC);
  const [EquityGainPERCorDOLLAR, setEquityGainPERCorDOLLAR] = useState<any>(
    props?.Data?.summaryData?.EquityGainPERCorDOLLAR
  );
 
  const [CashGainDOLLAR, setCashGainDOLLAR] = useState(
    props?.Data?.summaryData?.CashGainDOLLAR
  );
  const [notice, setNotice] = useState("");
  const [equityAvailable, setEquityAvailable] = useState(
    props.number === 0 ? props?.Data?.equityGain : props?.Data?.equityAvailable
  );
  const [cashAvailable, setCashAvailable] = useState(
    props.number === 0
      ? isNaN(GS.SOT?.Equity?.Cash?.value) 
        ? 0
        : GS.SOT?.Equity?.Cash?.value
      : props?.Data?.cashAvailable
  );
  const [liquidAssets, setLiquidAssets] = useState(props?.Data?.liquidAssets);
  const [targetYieldReturn, setTargetYieldReturn] = useState(
    Number(props?.Data?.summaryData?.RiskFactoredYieldReturn || 0) 
  );
  const [slider1, setSlider1] = useState(props?.Data?.slider1);
  const [useEquity, setUseEquity] = useState(props?.Data?.useEquity);
  const [percentageEquity, setPercentageEquity] = useState( 
    props?.Data?.percentageEquity
  );
  const [useCash, setUseCash] = useState(props?.Data?.useCash);
  const [percentageCash, setPercentageCash] = useState<any>(
    !isNaN(props?.Data?.percentageCash) ? props?.Data?.percentageCash : 0
  );
  const [PercentageLiquidAssets, setPercentageLiquidAssets] = useState(props?.Data?.percentageLiquidAssets)
  const [extraCash, setExtraCash] = useState(props?.Data?.extraCash);
  const [TotalCostofLending, setTotalCostofLending] = useState(
    props?.Data?.summaryData?.TotalCostofLending
  );
  const [EquityInterestRate, setEquityInterestRate] = useState(
    props?.Data?.summaryData?.EquityInterestRate || 0
  );
  const [DebtInterestRate, setDebtInterestRate] = useState(
    props?.Data?.summaryData?.DebtInterestRate || 0
  );
  const [DebtPrincipleRate, setDebtPrincipleRate] = useState(
    props?.Data?.summaryData?.DebtPrincipleRate || 0
  )

  const [EquityPrincipleRate, setEquityPrincipleRate] = useState(
    props?.Data?.summaryData?.EquityPrincipleRate || 0
  )
  const [PropertyRentIncome, setPropertyRentIncome] = useState(
    props?.Data?.summaryData?.PropertyRentIncomeYear1
  );
  const [AppliedInterestRate, setAppliedInterestRate] = useState(
    props?.Data?.summaryData?.interestRate
  );
  const lockedPropeties = useSelector((state: any) => state.PropertyLocks);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [ReturnonEquityCOC, setReturnonEquityCOC] = useState(
    props?.Data?.summaryData?.ReturnonEquityCOC
  );
  const [region, setRegion] = useState(props?.Data.summaryData.regionNo);
  const [cityName] = useState(
    GS.SOT.PortfolioSummary.Properties[
      GS.SOT.PropertyGenerators[props.number].medianPropertyType
    ].length === 0
      ? ""
      : GS.SOT.PortfolioSummary.Properties[
        GS.SOT.PropertyGenerators[props.number].medianPropertyType
      ].name
  );
  const [initalLmi, setinitialLmi] = useState(true);
  const[outgoingExpenses,setoutgoingExpenses]=useState(props?.Data?.summaryData?.outgoingExpenses || PropertyRentIncomeYear1*0.325)
  const [outgoingExpensePercent,setoutgoingExpensePercent]=useState(props?.Data?.summaryData?.outgoingExpensePercent ||0.325*100)
  const handleCallBack = (
    totaldepositextracash:any,
    totaldepositLiquidAssets:any,
    liquidPercent:any, 
    totaldepositequity:any,
    totaldepositcash:any,
    equityPercentage:any,
    cashPercentage:any
    ) => {
      setTotalDepositLiquidAssets(totaldepositLiquidAssets)
      setTotalDepositExtraCash(totaldepositextracash)
      setTotalDepositEquity(totaldepositequity)
      setTotalDepositCash(totaldepositcash)
      setPercentageCash((totaldepositcash/cashAvailable)*100 || 0)
      setPercentageEquity((totaldepositequity/equityAvailable)*100 || 0)
    // console.log(
    //   "callback",
    //   totaldepositextracash,
    //   totaldepositLiquidAssets,
    //   liquidPercent,
    //   totaldepositequity,
    //   totaldepositcash,
    //   equityPercentage,
    //   cashPercentage
    //   )
  }
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const { } =
    useGeneratorECLE( 
      useEquity, 
      percentageEquity,
      useCash,
      percentageCash,
      useLiquidAssets,
      PercentageLiquidAssets,
      liquidAssets,
      useExtraCash,
      extraCash,
      TotalDepositEquity,
      TotalDepositCash,
      cashAvailable,
      equityAvailable,
      TotalDepositLiquidAssets,
      TotalDepositExtraCash,
      props.Data.isLocked,
      TotalDepositRequired,
      handleCallBack
    )
  // console.log(props, "props")
  const getLMI = (mortgageInsuranceApply: any) => {
    if (mortgageInsuranceApply === "Y") { 
      let totalmortgage = TotalDebt; //630000;
      let purchasePrice = TotalPurchaseCosts; //700000;
      let lvr = (totalmortgage / purchasePrice) * 100;

      let lmiRate: any = getRate(totalmortgage, LoantoValueRatioLVR * 100);
      let lmiPremium: any = totalmortgage * (lmiRate / 100);
      let stampDutyRate: any = 0;
      if (cityName === "NSW") {
        stampDutyRate = 9 / 100;
      } else if (cityName === "VIC") {
        stampDutyRate = 10 / 100;
      } else if (cityName === "QLD") {
        stampDutyRate = 9 / 100;
      } else if (cityName === "SA") {
        stampDutyRate = 11 / 100;
      } else if (cityName === "WA") {
        stampDutyRate = 10 / 100; 
      } else if (cityName === "ACT") {
        stampDutyRate = 6 / 100;
      } else if (cityName === "NT") {
        stampDutyRate = 10 / 100;
      } else if (cityName === "TAS") {
        stampDutyRate = 10 / 100;
      } else {
        stampDutyRate = 9 / 100;
      }

      let stampDutyVal = lmiPremium * stampDutyRate;
      let totalLmi = stampDutyVal + lmiPremium;
      lvr = LoantoValueRatioLVR * 100;

      if (lvr >= 80) {
        return totalLmi;
      } else {
        setMortgageInsuranceApply("N");
        return -1;

      }
    } else {
      if (LMI > 0) {
        return LMI;
      }
    }
  };
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error Message",
      detail: "Your property LVR should be greater than or equal to 80",
      life: 3000,
    });
  };
  const UpdateTargetYield=()=>{
    let MEDIANRENTPRICE_RentIncome = 0;
    let thePurchasePrice: any; 
    let totalDebt = TotalDebt;
    let TotalDepositReq = 0;
    let totalCostOfLending = 0;
    let lmivalue = 0;
    if (mortgageInsuranceApply == "Y") {
      lmivalue = getLMI(mortgageInsuranceApply)
        ? getLMI(mortgageInsuranceApply)
        : 0;
      if (lmivalue == -1) {
        showError(); 
      }
    }
    let ResidentialMedianPrice = GS?.SOT?.PortfolioSummary?.Properties[
      "residential"
    ][region]
      ? GS?.SOT?.PortfolioSummary?.Properties["residential"][region]?.price *
      0.68 *
      52
      : GS?.SOT?.PortfolioSummary?.Properties["residential"][0]?.price * 100;
    let ComercialMedianPrice = GS?.SOT?.PortfolioSummary?.Properties["office"][region]
      ? GS?.SOT?.PortfolioSummary?.Properties["office"][region]?.price * 100
      : GS?.SOT?.PortfolioSummary?.Properties["office"][0]?.price * 100;
    let RetailMedianPrice =
      GS?.SOT?.PortfolioSummary?.Properties["retail"][region]?.price * 100
        ? GS?.SOT?.PortfolioSummary?.Properties["retail"][region]?.price * 100
        : GS?.SOT?.PortfolioSummary?.Properties["retail"][0]?.price * 100;
        if (
          propertytype === "Residential" || 
          propertytype === "Industrial" || 
          propertytype === "Granny Flat" || 
          propertytype === "NDIS" ||
          propertytype === "Rooming House" || 
          propertytype === "Micro Apartment"
          ) {
            console.log(ResidentialMedianPrice,targetYieldReturn,props.number,"chekcing")
          thePurchasePrice =
            (ResidentialMedianPrice / targetYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
        } else if (propertytype === "Commercial Retail") {
          thePurchasePrice =
            (RetailMedianPrice / targetYieldReturn)* 100;
          MEDIANRENTPRICE_RentIncome = RetailMedianPrice / 100;
        } else {
          thePurchasePrice =
            (ComercialMedianPrice / targetYieldReturn)* 100;
          MEDIANRENTPRICE_RentIncome = ComercialMedianPrice / 100
        }
    
        setPurchasePrice(thePurchasePrice);
        totalDebt = thePurchasePrice * LoantoValueRatioLVR;
        if (mortgageInsuranceApply === "Y") {
          totalDebt = totalDebt + lmivalue;
        } else {
          totalDebt = totalDebt - lmivalue;
          if (lmivalue > 0) {
            totalDebt = totalDebt - lmivalue;
          }
        }
        TotalDepositReq = Deposit + StampDuty + AcquisitionCost;
        setLMI(lmivalue);
        let RentIncomeYear1;
        RentIncomeYear1 = MEDIANRENTPRICE_RentIncome;
        setPropertyRentIncomeYear1(RentIncomeYear1);
        setTotalDebt(totalDebt);
        setDeposit(thePurchasePrice - totalDebt);
        setTotalDepositRequired(Math.floor(TotalDepositReq));
        setTotalPurchaseCosts(thePurchasePrice + StampDuty + AcquisitionCost);
        totalCostOfLending =
        totalDebt * (DebtInterestRate / 100) +
        TotalDepositEquity * (EquityInterestRate / 100);
        setTotalCostofLending(totalCostOfLending);
        setCashflowAnnual(RentIncomeYear1 - totalCostOfLending);
        setCashflowMonthly((RentIncomeYear1 - totalCostOfLending) / 12);
        let returnon =
        ((RentIncomeYear1 - totalCostOfLending) / TotalDepositReq) * 100;
        setReturnonEquityCOC(returnon);
  }
const ReducePurchasePrice=( 
  equityPercent: any,
    cashPercent: any,
    TotalDepositRequired: any
)=>{
 
 let depositequity=((equityAvailable*(Number(equityPercent/100)))||0)
 let depositcash=((cashAvailable*(Number(cashPercent/100)))||0)
  let totalequity=depositequity+depositcash
  let checkdeposit=totalequity>TotalDepositRequired
  let priceReduce = false
    let newTotalPurchasePrice=props?.Data?.summaryData?.PurchasePrice
    const stateName =
        GS.SOT.PortfolioSummary.Properties[props.Data.medianPropertyType][region]
          .name;
          let StampDuty = GS.stampDutyRate(stateName, newTotalPurchasePrice)
         let debt = (newTotalPurchasePrice * (LoantoValueRatioLVR)) || 0
         let Deposit = newTotalPurchasePrice - debt 
         let  newTotalAcquistonCost = newTotalPurchasePrice * 0.025
         let totaldepositrequired = TotalDepositRequired || 0
         let riskfactor = targetYieldReturn || 6
         let newpropertyIncome = newTotalPurchasePrice * (riskfactor / 100) || 0
         
        
         if(
          (useLiquidAssets=='N') &&
          (useExtraCash=='N') &&
          (Math.round(equityPercent)==Math.round(Number(props?.Data?.percentageEquity||0))) &&
           (Math.round(cashPercent)==Math.round(Number(props?.Data?.percentageCash||0))))
           {
          newTotalPurchasePrice=Number(props?.Data?.summaryData?.PurchasePrice) || 0
       
          StampDuty = GS.stampDutyRate(stateName, newTotalPurchasePrice)
          debt = (newTotalPurchasePrice * (LoantoValueRatioLVR)) || 0
          Deposit = newTotalPurchasePrice - debt  
          newTotalAcquistonCost = newTotalPurchasePrice * 0.025
          newpropertyIncome = newTotalPurchasePrice * (riskfactor / 100) || 0
          totaldepositrequired = Deposit + newTotalAcquistonCost + StampDuty
          if(propertytype.toLowerCase().includes("office")){
            riskfactor=7
            newpropertyIncome = newTotalPurchasePrice * (7 / 100) || 0
           
            setPropertyRentIncome(newpropertyIncome)
          }else if(propertytype.toLowerCase().includes("retail")){
            riskfactor=6
            newpropertyIncome = newTotalPurchasePrice * (6 / 100) || 0
            
            setPropertyRentIncome(newpropertyIncome) 
          }else if(propertytype.toLowerCase().includes("industrial")||propertytype.toLowerCase().includes("residential")){
            riskfactor=5
            newpropertyIncome = newTotalPurchasePrice * (5 / 100) || 0
            
            setPropertyRentIncome(newpropertyIncome) 
          }
          else{
            riskfactor=7
            newpropertyIncome = newTotalPurchasePrice * (7 / 100) || 0 
           
            setPropertyRentIncome(newpropertyIncome)
          }
          let PurchasePrice = newTotalPurchasePrice || 0;
          let TotalDebt = PurchasePrice*(LoantoValueRatioLVR) || 0  //newTotalDebt
          const stampDutyRate = GS.stampDutyRate(stateName, PurchasePrice) || 0;
          StampDuty = stampDutyRate || 0
          let AcquisitionCost = newTotalAcquistonCost || 0
          let lmivalue = 0;
      if (mortgageInsuranceApply == "Y") {
        lmivalue = getLMI(mortgageInsuranceApply)
          ? getLMI(mortgageInsuranceApply)
          : 0; 
        if (lmivalue == -1) {
          lmivalue = 0
        }
      } 
      TotalDepositRequired = Deposit + newTotalAcquistonCost + StampDuty + lmivalue || 0
    
    
      let TotalPurchaseCosts = (Number(PurchasePrice) + Number(StampDuty) + Number(AcquisitionCost)) || 0
      let PropertyRentIncomeYear1 = newpropertyIncome || 0
      let TotalCostofLending = (TotalDebt  + depositequity )*(AppliedInterestRate/100 || 0)  // *(AppliedInterestRate/100) || 0
      let equityInterestPayable = depositequity * ((Number(EquityInterestRate) / 100)||0)
      let depositInterestPayable = TotalDebt * ((Number(DebtInterestRate) / 100)||0)
      let TotalInterestPayable=equityInterestPayable+depositInterestPayable
      let equityPrincipalPayable = depositequity * ((Number(EquityPrincipleRate) / 100)||0)
      let depositPrincipalPayable = TotalDebt * ((Number(DebtPrincipleRate) / 100)||0)
      let TotalPricipalPayable=equityPrincipalPayable+depositPrincipalPayable
      let CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending  || 0;
      let CashflowMonthly = CashflowAnnual / 12 || 0; 
      let ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100 || 0;
      if (!props.Data.isLocked) {
        setAcquisitionCost(newTotalAcquistonCost || 0)
        setStampDuty(StampDuty || 0) 
        setCashflowAnnual(CashflowAnnual || 0)
        setCashflowMonthly(CashflowMonthly || 0)
        setPurchasePrice(PurchasePrice || 0)
        setTotalDepositRequired(TotalDepositRequired || 0)
        setTotalDepositEquity(depositequity || 0)
        setTotalDepositCash(depositcash || 0) 
        setTotalDebt(TotalDebt || 0) 
        setTotalPurchaseCosts(TotalPurchaseCosts || 0)
        setPropertyRentIncomeYear1(PropertyRentIncomeYear1 || 0)
        setReturnonEquityCOC(ReturnonEquityCOC || 0)
        setDeposit(Deposit || 0)
        setPrinciplePayments(TotalPricipalPayable || 0)
      
        setInterestPayments(TotalInterestPayable || 0)
      }
         }else if(checkdeposit==false){ 
          while(checkdeposit==false){
            priceReduce = true
            newTotalPurchasePrice = newTotalPurchasePrice - 1 || 0;
            StampDuty = GS.stampDutyRate(stateName, newTotalPurchasePrice)
            debt = (newTotalPurchasePrice * (LoantoValueRatioLVR)) || 0
            Deposit = newTotalPurchasePrice - debt
             totalequity=depositequity+depositcash
            newTotalAcquistonCost = newTotalPurchasePrice * 0.025
            checkdeposit = (totalequity) > (Deposit + newTotalAcquistonCost + StampDuty)
            totaldepositrequired = Deposit + newTotalAcquistonCost + StampDuty
          }
          if(propertytype.toLowerCase().includes("office")){
            riskfactor=7
            newpropertyIncome = newTotalPurchasePrice * (7 / 100) || 0
           
            setPropertyRentIncome(newpropertyIncome)
          }else if(propertytype.toLowerCase().includes("retail")){
            riskfactor=6
            newpropertyIncome = newTotalPurchasePrice * (6 / 100) || 0
            
            setPropertyRentIncome(newpropertyIncome) 
          }else if(propertytype.toLowerCase().includes("industrial")||propertytype.toLowerCase().includes("residential")){
            riskfactor=5
            newpropertyIncome = newTotalPurchasePrice * (5 / 100) || 0
            
            setPropertyRentIncome(newpropertyIncome) 
          }
          else{
            riskfactor=7
            newpropertyIncome = newTotalPurchasePrice * (7 / 100) || 0 
           
            setPropertyRentIncome(newpropertyIncome)
          }
          let PurchasePrice = newTotalPurchasePrice || 0;
          let TotalDebt = PurchasePrice*(LoantoValueRatioLVR) || 0  //newTotalDebt
    
          //Deposit =TotalDebt  || 0 //newTotalDebt
          const stampDutyRate = GS.stampDutyRate(stateName, PurchasePrice) || 0;
          StampDuty = stampDutyRate || 0
          let AcquisitionCost = newTotalAcquistonCost || 0
          let lmivalue = 0; 
      if (mortgageInsuranceApply == "Y") {
        lmivalue = getLMI(mortgageInsuranceApply)
          ? getLMI(mortgageInsuranceApply)
          : 0; 
        if (lmivalue == -1) {
          lmivalue = 0
        }
      } 
          TotalDepositRequired = Deposit + newTotalAcquistonCost + StampDuty + lmivalue || 0
    
    
          let TotalPurchaseCosts = (Number(PurchasePrice) + Number(StampDuty) + Number(AcquisitionCost)) || 0
          let PropertyRentIncomeYear1 = newpropertyIncome || 0
          let TotalCostofLending = (TotalDebt  + depositequity )*(AppliedInterestRate/100 || 0)  // *(AppliedInterestRate/100) || 0
          let equityInterestPayable = depositequity * ((Number(EquityInterestRate) / 100)||0)
          let depositInterestPayable = TotalDebt * ((Number(DebtInterestRate) / 100)||0)
          let TotalInterestPayable=equityInterestPayable+depositInterestPayable
          let equityPrincipalPayable =depositequity * ((Number(EquityPrincipleRate) / 100)||0)
          let depositPrincipalPayable = TotalDebt * ((Number(DebtPrincipleRate) / 100)||0)
          let TotalPricipalPayable=equityPrincipalPayable+depositPrincipalPayable
          let CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending  || 0;
          let CashflowMonthly = CashflowAnnual / 12 || 0;
          let ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100 || 0;

          if (!props.Data.isLocked) {
            setAcquisitionCost(newTotalAcquistonCost || 0)
            setStampDuty(StampDuty || 0) 
            setCashflowAnnual(CashflowAnnual || 0)
            setCashflowMonthly(CashflowMonthly || 0)
            setPurchasePrice(PurchasePrice || 0)
            setTotalDepositRequired(TotalDepositRequired || 0)
            setTotalDepositEquity(depositequity || 0)
            setTotalDepositCash(depositcash || 0) 
            setTotalDebt(TotalDebt || 0) 
            setTotalPurchaseCosts(TotalPurchaseCosts || 0)
            setPropertyRentIncomeYear1(PropertyRentIncomeYear1 || 0)
            setReturnonEquityCOC(ReturnonEquityCOC || 0)
            setDeposit(Deposit || 0)
            setPrinciplePayments(TotalPricipalPayable || 0)
          
            setInterestPayments(TotalInterestPayable || 0)
          }
          // console.log(newTotalPurchasePrice,"newTotalPurchasePrice")
        }else{

          let extra=totalequity-TotalDepositRequired
          newTotalPurchasePrice=Number(props?.Data?.summaryData?.PurchasePrice)+extra || 0
          StampDuty = GS.stampDutyRate(stateName, newTotalPurchasePrice)
          debt = (newTotalPurchasePrice * (LoantoValueRatioLVR)) || 0
          Deposit = newTotalPurchasePrice - debt  
          newTotalAcquistonCost = newTotalPurchasePrice * 0.025
          newpropertyIncome = newTotalPurchasePrice * (riskfactor / 100) || 0
          totaldepositrequired = Deposit + newTotalAcquistonCost + StampDuty


          if(propertytype.toLowerCase().includes("office")){
            riskfactor=7
            newpropertyIncome = newTotalPurchasePrice * (7 / 100) || 0
           
            setPropertyRentIncome(newpropertyIncome)
          }else if(propertytype.toLowerCase().includes("retail")){
            riskfactor=6
            newpropertyIncome = newTotalPurchasePrice * (6 / 100) || 0
            
            setPropertyRentIncome(newpropertyIncome) 
          }else if(propertytype.toLowerCase().includes("industrial")||propertytype.toLowerCase().includes("residential")){
            riskfactor=5
            newpropertyIncome = newTotalPurchasePrice * (5 / 100) || 0
            
            setPropertyRentIncome(newpropertyIncome) 
          }
          else{
            riskfactor=7
            newpropertyIncome = newTotalPurchasePrice * (7 / 100) || 0 
           
            setPropertyRentIncome(newpropertyIncome)
          }
          let PurchasePrice = newTotalPurchasePrice || 0;
          let TotalDebt = PurchasePrice*(LoantoValueRatioLVR) || 0  //newTotalDebt
    
          //Deposit =TotalDebt  || 0 //newTotalDebt
          const stampDutyRate = GS.stampDutyRate(stateName, PurchasePrice) || 0;
          StampDuty = stampDutyRate || 0
          let AcquisitionCost = newTotalAcquistonCost || 0
          let lmivalue = 0;
      if (mortgageInsuranceApply == "Y") {
        lmivalue = getLMI(mortgageInsuranceApply)
          ? getLMI(mortgageInsuranceApply)
          : 0; 
        if (lmivalue == -1) { 
          lmivalue = 0
        }
      }  
          TotalDepositRequired = Deposit + newTotalAcquistonCost + StampDuty + lmivalue || 0
    
    
          let TotalPurchaseCosts = (Number(PurchasePrice) + Number(StampDuty) + Number(AcquisitionCost)) || 0
          let PropertyRentIncomeYear1 = newpropertyIncome || 0
          let TotalCostofLending = (TotalDebt  + depositequity )*(AppliedInterestRate/100 || 0)  // *(AppliedInterestRate/100) || 0
          let equityInterestPayable = depositequity * ((Number(EquityInterestRate) / 100)||0)
          let depositInterestPayable = TotalDebt * ((Number(DebtInterestRate) / 100)||0)
          let TotalInterestPayable=equityInterestPayable+depositInterestPayable
          let equityPrincipalPayable = depositequity * ((Number(EquityPrincipleRate) / 100)||0)
          let depositPrincipalPayable = TotalDebt * ((Number(DebtPrincipleRate) / 100)||0)
          let TotalPricipalPayable=equityPrincipalPayable+depositPrincipalPayable
          let CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending  || 0;
          let CashflowMonthly = CashflowAnnual / 12 || 0;
          let ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100 || 0;

          if (!props.Data.isLocked) {
            setAcquisitionCost(newTotalAcquistonCost || 0)
            setStampDuty(StampDuty || 0) 
            setCashflowAnnual(CashflowAnnual || 0)
            setCashflowMonthly(CashflowMonthly || 0)
            setPurchasePrice(PurchasePrice || 0)
            setTotalDepositRequired(TotalDepositRequired || 0)
            setTotalDepositEquity(depositequity || 0)
            setTotalDepositCash(depositcash || 0) 
            setTotalDebt(TotalDebt || 0) 
            setTotalPurchaseCosts(TotalPurchaseCosts || 0)
            setPropertyRentIncomeYear1(PropertyRentIncomeYear1 || 0)
            setReturnonEquityCOC(ReturnonEquityCOC || 0)
            setDeposit(Deposit || 0)
            setPrinciplePayments(TotalPricipalPayable || 0)
          
            setInterestPayments(TotalInterestPayable || 0)
          }
        }
  //        console.log(
  //   "props?.Data?.summaryData?.PurchasePrice",props?.Data?.summaryData?.PurchasePrice,
  //   "equityPercent",equityPercent,
  //   "cashPercent",cashPercent,
  //  "checkdeposit", checkdeposit,
  //  "totalequity", totalequity,
  //  "equityAvailable", equityAvailable,
  //  "equityValuePercent",depositequity,
  //  "cashAvailable", cashAvailable,
  //  "cashValuePercent",depositcash,
  //  "TotalDepositRequired", TotalDepositRequired
  //  )
}
  useEffect(() => {
    const propeties = [...GS.SOT.PropertyGenerators]
    let availableLiquidAssets = parseInt(GS.SOT.Equity.LiquidAssets.value)
    let index = 0
    let previousLiquidAssets = 0
    while (index < props.number) {
      previousLiquidAssets = previousLiquidAssets + propeties[index].liquidAssets
      index++;
    }
    availableLiquidAssets = (availableLiquidAssets - previousLiquidAssets) < 0 ? 0 : (availableLiquidAssets - previousLiquidAssets)
    setLiquidAssets(availableLiquidAssets)

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  const lockSummary = () => {
    dispatch(SetAutoSave(true))
    let data = JSON.parse(JSON.stringify(GS?.SOT?.PortfolioSummary?.tableData));
    let properties = JSON.parse(JSON.stringify(GS?.SOT?.PropertyGenerators));
   
    properties[props.number].B54 = targetYieldReturn;
    properties[props.number].slider1 = slider1;
    properties[props.number].extraCash = extraCash;
    properties[props.number].useExtraCash = extraCash > 0 ? "Y" : "N";
    properties[props.number].useLiquidAssets = useLiquidAssets;
    properties[props.number].percentageCash = percentageCash;
    properties[props.number].percentageEquity = percentageEquity;
    properties[props.number].useEquity = useEquity;
    properties[props.number].useCash = useCash;
    properties[props.number].liquidAssets = TotalDepositLiquidAssets
    properties[props.number].percentageLiquidAssets = PercentageLiquidAssets

    data[0][props.number + 1] = propertytype;
    data[2][props.number + 1] = PurchasePrice;
    data[3][props.number + 1] = PurchasePrice - TotalDebt; // Deposit
    data[6][props.number + 1] = LMI; //LMI
    data[7][props.number + 1] = TotalDepositRequired;
    data[8][props.number + 1] = Number(PurchasePrice) + Number(StampDuty) + Number(AcquisitionCost);
    data[10][props.number + 1] = LoantoValueRatioLVR * 100; 
    data[11][props.number + 1] = DebtInterestRate; 
    data[12][props.number + 1] = TotalDebt;
    data[13][props.number + 1] = TotalDepositEquity;
    data[14][props.number + 1] = TotalDepositCash;
    data[16][props.number + 1] = PropertyRentIncomeYear1;
    data[17][props.number + 1] = TotalCostofLending;
    data[18][props.number + 1]= CashflowAnnual 
    data[19][props.number + 1]= CashflowMonthly 
    // data[18][props.number + 1] = PropertyRentIncomeYear1 - TotalCostofLending;
    // data[19][props.number + 1] =
    //   (PropertyRentIncomeYear1 - TotalCostofLending) / 12;
    data[20][props.number + 1] = ReturnonEquityCOC;
    data[22][props.number + 1] = AppliedGrowthRateannualPERC;
    data[23][props.number + 1] = EquityGainPERCorDOLLAR;
    data[24][props.number + 1] = CashGainDOLLAR;
    properties[props.number].summaryData.propertyType = propertytype;
    properties[props.number].summaryData.regionNo = region;

   
    properties[props.number].summaryData.PurchasePrice = PurchasePrice;
    properties[props.number].summaryData.RiskFactoredYieldReturn=targetYieldReturn
    properties[props.number].targetYieldReturn=targetYieldReturn
    properties[props.number].summaryData.LoantoValueRatioLVR =
      LoantoValueRatioLVR;
    properties[props.number].tableData.NetYieldonPropertyYear1 =
      targetYieldReturn;
    properties[props.number].summaryData.LMI = LMI;
    properties[props.number].summaryData.PropertyIncomeYear1 = 
      PropertyRentIncomeYear1;
    properties[props.number].summaryData.Deposit = Deposit;
    properties[props.number].summaryData.TotalDepositRequired =
      TotalDepositRequired;
    properties[props.number].summaryData.TotalCostofLending =
      TotalCostofLending;
    properties[props.number].summaryData.AppliedInterestRate = DebtInterestRate;
    properties[props.number].summaryData.interestRate=DebtInterestRate
    properties[props.number].summaryData.ReturnonEquityCOC = ReturnonEquityCOC;
    properties[props.number].summaryData.TotalDebt = TotalDebt;
    properties[props.number].summaryData.DebtInterestRate = DebtInterestRate;
    properties[props.number].summaryData.EquityInterestRate =
      EquityInterestRate;
    properties[props.number].summaryData.TotalDepositEquity =
      TotalDepositEquity;
    properties[props.number].summaryData.TotalDepositCash = TotalDepositCash;
    properties[props.number].summaryData.CashflowAnnual = CashflowAnnual;
    properties[props.number].summaryData.CashflowMonthly = CashflowMonthly;
    properties[props.number].summaryData.EquityInterestRate =
      EquityInterestRate; 
    properties[props.number].summaryData.DebtInterestRate = DebtInterestRate;
    properties[props.number].summaryData.DebtPrincipleRate = DebtPrincipleRate;
    properties[props.number].summaryData.EquityInterestRate = EquityInterestRate;
    properties[props.number].summaryData.EquityPrincipleRate = EquityPrincipleRate;
    properties[props.number].summaryData.InterestPayments=InterestPayments
    properties[props.number].summaryData.PrinciplePayments=PrinciplePayments
    properties[props.number].summaryData.CashGainDOLLAR=Number(CashGainDOLLAR)
    properties[props.number].summaryData.EquityGainPERCorDOLLAR=Number(EquityGainPERCorDOLLAR)
    properties[props.number].summaryData.AppliedGrowthRateannualPERC=Number(AppliedGrowthRateannualPERC)
    properties[props.number].summaryData.TotalPurchaseCosts=TotalPurchaseCosts
    properties[props.number].summaryData.outgoingExpenses=outgoingExpenses
    properties[props.number].summaryData.outgoingExpensePercent=outgoingExpensePercent
    properties[props.number].isLocked = true;

    let arr: any = [];
    data[23].slice(1).forEach((item: any, index: any) => {
      arr.push({
        AnnualGrowth: item,
        equityGainGrowth: data[23][index + 1],
        cashGainGrowth: data[24][index + 1],
      });
    });
    dispatch(
      setLockedProperties({
        tableData: data,
        PropertyGenerators: properties,
      })
    ); 
  };
  useEffect(() => {
    const newDebt = PurchasePrice * parseFloat(String((LoantoValueRatioLVR) || 0));
    const newDeposit = PurchasePrice - newDebt;
    const totalDepositReq = newDeposit + parseFloat(AcquisitionCost) + parseFloat(StampDuty);
    let TotalCostofLending = (newDebt + TotalDepositEquity) * (DebtInterestRate / 100 || 0)
    let equityInterestPayable = TotalDepositEquity * ((Number(EquityInterestRate) / 100) || 0)
    let depositInterestPayable = newDebt * ((Number(DebtInterestRate) / 100) || 0)
    let TotalInterestPayable = equityInterestPayable + depositInterestPayable
    let equityPrincipalPayable = TotalDepositEquity * ((Number(EquityPrincipleRate) / 100) || 0)
    let depositPrincipalPayable = newDebt * ((Number(DebtPrincipleRate) / 100) || 0)
    let TotalPricipalPayable = equityPrincipalPayable + depositPrincipalPayable
    let CashflowAnnual = PropertyRentIncomeYear1 - (TotalInterestPayable + TotalPricipalPayable) || 0;
    let CashflowMonthly = CashflowAnnual / 12 || 0;
    let ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100 || 0;
    if (!props.Data.isLocked) {
      if (slider1 < 80) {
        setMortgageInsuranceApply("N");
      }
      setTotalDebt(newDebt);
      setDeposit(newDeposit);
      setTotalDepositRequired(totalDepositReq);
      setPrinciplePayments(TotalPricipalPayable || 0)
      setTotalCostofLending(TotalCostofLending || 0)
      setInterestPayments(TotalInterestPayable || 0)
      setCashflowAnnual(CashflowAnnual || 0)
      setCashflowMonthly(CashflowMonthly)
      setReturnonEquityCOC(ReturnonEquityCOC)
    }

  }, [
    DebtInterestRate,
    EquityInterestRate,
    DebtPrincipleRate,
    EquityPrincipleRate,
    LoantoValueRatioLVR
  ])
  useEffect(()=>{
    if(props.number===0 || props.number===1 || props.number===2 || props.number===3){
      if(!props.Data.isLocked){
        setPropertyRentIncomeYear1(PurchasePrice*(targetYieldReturn/100))
      }
    }else{
        UpdateTargetYield()
    }
  },[targetYieldReturn])
  useEffect(() => {
    if (initalLmi) {
      setinitialLmi(false);
    } else {
      UpdateTargetYield()
    }
  }, [mortgageInsuranceApply]);
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        showHeader={false}
        visible={showDialog}
        style={{ width: "25vw" }}
        onHide={() => setShowDialog(false)}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
            Available Extra Cash &nbsp;
            {/* {commaSeparators(availableExtraCash)} */}
          </label>

          <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
            Extra Cash
          </label>
          <InputNumbers
            mode="currency"
            currency="USD"
            locale="en-US"
            minfractiondigits={0}
            onFocus={(e: any) => (e.target.value = "")}

          />
          <div className="mt-3 grid gap-2">
            <GradientButton
              width="124px"
              icon={undefined} 
              txt={"Cancel "}
              onClick={() => {
                setShowDialog(false);
                // setUseExtraCash("N");
              }}
            />

            <GradientButton
              width="124px"
              icon={undefined}
              txt={"Add"}
              onClick={() => {
                setShowDialog(false);
              }}
            />
          </div>
        </div>
      </Dialog>
      <div>
        <div className="boxs-container">
          <div className="boxs">
            <Row
              lg={12}
              className={`${scrollPosition > 300 ? "grid fixed" : "grid"}`}
            >
              <Col lg={2} md={3} sm={3} className="col">
                <p>Accessible Equity Available</p>
                <p>{c(equityAvailable, 2)}</p>
              </Col>
              <Col lg={2} md={3} sm={3} className="col">
                <p>Cash On Hand</p>
                <p>{c(cashAvailable, 2)}</p>
              </Col>
              <Col lg={2} md={3} sm={3} className="col">
                <p>Available Liquid Assets</p>
                <p>{c(liquidAssets, 2)}</p>
              </Col>
              <Col lg={2} md={3} sm={3} className="col">
                <p>Total Available Funds</p>
                <p>{c((cashAvailable + equityAvailable), 2)}</p>
              </Col>
              <Col lg={2} md={3} sm={3} className="col">
                <p>Year Goal Achieved</p>
                <p>{props?.Data?.nextDate}</p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="grid px-2">
          <div
            className="col"
            style={{ backgroundColor: "#ebf2fa", padding: "52px 52px" }}
          >
            <p className="heading">Property #{props.number + 1}</p>
            <table className="propertytable">
              <thead>
                <tr>
                  <th colSpan={1}>
                    <span className="heading2">FEASIBILITY MARKERS</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <span>Property Type</span>
                  </td>
                  <td>
                    <span>{propertytype}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Purchase Price</span>
                  </td>
                  <td>
                    <span>
                      {commaSeparators(Number((PurchasePrice || 0)).toFixed(2))}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Deposit</span>
                  </td>
                  <td>
                    <span>{commaSeparators(Deposit || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Stamp Duty</span>
                  </td>
                  <td>
                    <span>{commaSeparators(StampDuty || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Acquisition Cost</span>
                  </td>
                  <td>
                    <span>{commaSeparators(AcquisitionCost || 0)}</span>
                  </td>
                </tr>
                {mortgageInsuranceApply === "Y" && (
                  <tr>
                    <td>
                      <span>Total LMI</span>
                    </td>
                    <td>
                      <span>{commaSeparators(LMI)}</span>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <span className="total">Total Funds Required</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDepositRequired || 0)} </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Purchase Costs</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalPurchaseCosts || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1}>
                    <span className="heading2">FINANCE</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Loan To Value Ratio(LVR)</span>
                  </td>
                  <td>
                    <span>{(LoantoValueRatioLVR * 100).toFixed(2)}%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Debt</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDebt || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Deposit Equity</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDepositEquity || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Deposit Cash</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDepositCash || 0)}</span>
                  </td>
                </tr>
                {useExtraCash == 'Y' &&
                  <tr>
                    <td>
                      <span>Total Deposit Extra Cash</span>
                    </td>
                    <td>
                      <span>{commaSeparators(TotalDepositExtraCash || 0)}</span>
                    </td>
                  </tr>
                }
                {useLiquidAssets == 'Y' &&
                  <tr>
                    <td>
                      <span>Total Liquid Assets </span>
                    </td>
                    <td>
                      <span>{commaSeparators(TotalDepositLiquidAssets || 0)}</span>
                    </td>
                  </tr>
                }


                <tr>
                  <td colSpan={1}>
                    <span className="heading2">CASHFLOW</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Property Rent Income (Year 1)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(PropertyRentIncomeYear1 || 0)}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Interest Payments</span>
                  </td>
                  <td>
                    <span>{commaSeparators(InterestPayments || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Principal Payments</span>
                  </td>
                  <td>
                    <span>{commaSeparators(PrinciplePayments || 0)}</span>
                  </td>
                </tr>
                <tr> 
                  <td>
                    <span>Outgoing Expenses</span>
                  </td>
                  <td>
                    <span>{commaSeparators(outgoingExpenses || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Cashflow (Annual)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(CashflowAnnual || 0)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Cashflow (Monthly)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(CashflowMonthly || 0)}</span>
                  </td>
                </tr>

                <tr>
                  <td colSpan={1}>
                    <span className="heading2">GROWTH HACKING</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Applied Growth Rate (annual) (%)</span>
                  </td>
                  <td>
                    {AnnualGrowthEdit == false ?
                      <span onClick={() => setAnnualGrowthEdit(true)}>{Number(AppliedGrowthRateannualPERC).toFixed(2)}%</span>
                      :
                      <InputTexts
                        onFocus={(e: any) =>
                          (e.target.value = "")
                        }
                        value={AppliedGrowthRateannualPERC}
                        onChange={(e: any) => {
                          setAppliedGrowthRateannualPERC(e.target.value)
                          let AnnualGrow = Number(e.target.value)
                          let EquityGainPERCorDOLLAR2 = (Number(PurchasePrice) / 100) * Number(AnnualGrow)
                          setEquityGainPERCorDOLLAR(Number(EquityGainPERCorDOLLAR2))
                        }}

                        onBlur={(e: any) => {
                          setAnnualGrowthEdit(false)
                        }
                        }
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Equity Gain ($)</span>
                  </td>
                  <td>
                    {EquityGainEdit == false ? <span onClick={() => setEquitGainEdit(true)}>{commaSeparators(Number(EquityGainPERCorDOLLAR).toFixed(2))}</span>
                      :
                      <InputTexts
                        onFocus={(e: any) =>
                          (e.target.value = "")
                        }
                        value={EquityGainPERCorDOLLAR}
                        onChange={(e: any) => setEquityGainPERCorDOLLAR(e.target.value)}
                        onBlur={(e: any) => {
                          setEquitGainEdit(false)
                          let EquityGainPERCorDOLLAR2 = Number(e.target.value)
                          let AnnualGrow = (EquityGainPERCorDOLLAR2 / Number(PurchasePrice)) * 100
                          setAppliedGrowthRateannualPERC(AnnualGrow)
                        }
                        }
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Cash Gain ($)</span>
                  </td>
                  <td>
                    {cashGainEdit == false ?
                      <span onClick={() => setCashGainEdit(true)}>{commaSeparators(Number(CashGainDOLLAR).toFixed(2))}</span>
                      :
                      <InputTexts
                        onFocus={(e: any) =>
                          (e.target.value = "")
                        }
                        value={CashGainDOLLAR}
                        onChange={(e: any) => setCashGainDOLLAR(e.target.value)}

                        onBlur={(e: any) => {
                          setCashGainEdit(false)
                        }
                        }
                      />
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col"
            style={{
              backgroundColor: "var(--white)",
              padding: "0 52px 52px 52px",
            }}
          >
            <div>
              {props.number >= 0 ? (
                <p className="lbl notice">{notice}</p> //
              ) : (
                <p className="lbl notice"></p>
              )}
              <div className="mb-3">
                <span className="lbl">Property Type</span>
                <div className="d-flex flex-wrap">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype1"
                      name="propertytype"
                      value="Residential"
                      onChange={(e) => { 
                        setPropertytype(e.value); 
                        setTargetYieldReturn(5);
                        setLoantoValueRatioLVR(80 / 100);
                        setSlider1(80)
                       }}
                      checked={propertytype === "Residential"}
                    />
                    <label htmlFor="propertytype1">Residential</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype2"
                      name="propertytype"
                      value="Commercial Retail"
                      onChange={(e) => { 
                      setPropertytype(e.value);
                      setTargetYieldReturn(6.50);
                      setLoantoValueRatioLVR(65 / 100);
                      setSlider1(65)
                     }}
                      checked={propertytype === "Commercial Retail"}
                    />
                    <label htmlFor="propertytype2">Retail</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype3"
                      name="propertytype"
                      value="Commercial Office"
                      onChange={(e) => { 
                        setPropertytype(e.value);
                        setTargetYieldReturn(7);
                        setLoantoValueRatioLVR(65 / 100);
                        setSlider1(65)
                       }}
                      checked={propertytype === "Commercial Office"}
                    />
                    <label htmlFor="propertytype3">Office</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype4"
                      name="propertytype"
                      value="Industrial"
                      onChange={(e) => { 
                        setPropertytype(e.value);
                        setTargetYieldReturn(5.50);
                        setLoantoValueRatioLVR(65 / 100);
                        setSlider1(65)
                       }}
                      checked={propertytype === "Industrial"}
                    />
                    <label htmlFor="propertytype4">Industrial</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype5"
                      name="propertytype"
                      value="Granny Flat"
                      onChange={(e) => { 
                      setPropertytype(e.value);
                      setTargetYieldReturn(10);
                      setLoantoValueRatioLVR(75 / 100);
                      setSlider1(75)
                     }}
                      checked={propertytype === "Granny Flat"}
                    />
                    <label htmlFor="propertytype5">Granny Flat</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype6"
                      name="propertytype"
                      value="NDIS"
                      onChange={(e) => { 
                      setPropertytype(e.value);
                      setTargetYieldReturn(10);
                      setLoantoValueRatioLVR(75 / 100);
                      setSlider1(75)
                     }}
                      checked={propertytype === "NDIS"}
                    />
                    <label htmlFor="propertytype6">NDIS</label>
                  </div>

                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype7"
                      name="propertytype"
                      value="Rooming House"
                      onChange={(e) => { 
                        setPropertytype(e.value);
                        setTargetYieldReturn(10);
                        setLoantoValueRatioLVR(65 / 100);
                        setSlider1(65)
                       }}
                      checked={propertytype === "Rooming House"}
                    />
                    <label htmlFor="propertytype7">Rooming House</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype8"
                      name="propertytype"
                      value="Micro Apartment"
                      onChange={(e) => { 
                      setPropertytype(e.value);
                      setTargetYieldReturn(8);
                      setLoantoValueRatioLVR(80 / 100);
                      setSlider1(80)
                     }}
                      checked={propertytype === "Micro Apartment"}
                    />
                    <label htmlFor="propertytype8">Micro Apartment</label>
                  </div>


                </div>
              </div>
              <div className="mb-3"> 
                <span className="lbl">Use Equity?</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="seEquity1" 
                      name="seEquity"
                      value="Y"
                      onChange={(e) => {
                        setUseEquity(e.value);
                      }}
                      checked={useEquity === "Y"}
                    />
                    <label htmlFor="seEquity1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="seEquity2"
                      name="seEquity"
                      value="N"
                      onChange={(e) => {
                        setUseEquity(e.value);
                        setPercentageEquity(0);
                      }}
                      checked={useEquity === "N"}
                    />
                    <label htmlFor="seEquity2">No</label>
                  </div>
                </div>
                <div className="w-100 p-1 mb-3">
                  <InputNumber
                    suffix="%"
                    minFractionDigits={0}
                    maxFractionDigits={1}
                    max={100}
                    min={0}
                    value={percentageEquity}
                    className="w-100"
                    placeholder="Percentage Equity"
                    onChange={(e: any) => {
                      setPercentageEquity(e?.value);
                      // setUseEquity(e?.value > 0 ? "Y" : "N");
                      ReducePurchasePrice(
                        e?.value,
                        percentageCash,
                        props?.Data?.summaryData?.TotalDepositRequired
                      )
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <span className="lbl">Use Cash</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useCash1"
                      name="useCash"
                      value="Y"
                      onChange={(e) => {
                        setUseCash(e.value);
                      }
                      }
                      checked={useCash === "Y"}
                    />
                    <label htmlFor="useCash1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useCash2"
                      name="useCash"
                      value="N"
                      onChange={(e) => {
                        setUseCash(e.value);
                        setPercentageCash(0);
                      }}
                      checked={useCash === "N"}
                    />
                    <label htmlFor="useCash2">No</label>
                  </div>
                </div>
                <div className="w-100 p-1 mb-3">
                  <InputNumber
                    suffix="%"
                    minFractionDigits={0}
                    maxFractionDigits={1}
                    max={100}
                    value={percentageCash}
                    className="w-100"
                    placeholder="Percentage Cash"
                    onChange={(e: any) => {
                      setPercentageCash(e?.value);
                      // setUseCash(e.value > 0 ? "Y" : "N");
                      ReducePurchasePrice(
                       percentageEquity,
                        e?.value,
                        props?.Data?.summaryData?.TotalDepositRequired
                      )

                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <span className="lbl">Use Liquid Assets</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useLiquidAssets1"
                      name="useLiquidAssets"
                      value="Y"
                      onChange={(e) => {
                        setUseLiquidAssets(e.value);
                      }}
                      checked={useLiquidAssets === "Y"}
                    />
                    <label htmlFor="useLiquidAssets1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useLiquidAssets2"
                      name="useLiquidAssets"
                      value="N"
                      onChange={(e) => {
                        setUseLiquidAssets(e.value);
                        setPercentageLiquidAssets(0)

                      }}
                      checked={useLiquidAssets === "N"}
                    />
                    <label htmlFor="useLiquidAssets2">No</label>
                  </div>
                </div>
              </div>
              <div className="mb-3">

                <MySlider
                  value={PercentageLiquidAssets}
                  onChange={(e: any) => {
                    setPercentageLiquidAssets(e.value);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">{PercentageLiquidAssets}%</span>

                </div>
              </div>
              <div className="mb-3">
                <span className="lbl">Use Extra Cash</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useExtraCash1"
                      name="useExtraCash"
                      value="Y"
                      onChange={(e) => {
                        setUseExtraCash(e.value);

                      }}
                      checked={useExtraCash === "Y"}
                    />
                    <label htmlFor="useExtraCash1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useExtraCash2"
                      name="useExtraCash"
                      value="N"
                      onChange={(e) => {
                        setUseExtraCash(e.value);
                        setExtraCash(0);




                      }
                      }
                      checked={useExtraCash === "N"}
                    />
                    <label htmlFor="useExtraCash2">No</label>
                  </div>

                </div>
                {useExtraCash == 'Y' &&
                  <div className="w-full p-1 mb-3" >
                    <InputNumbers
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={0}
                      onFocus={(e: any) => (e.target.value = "")}
                      onBlur={(e: any) => (e.target.value = commaSeparators(extraCash))}
                      value={extraCash}
                      onChange={(e: any) => {
                        setExtraCash(e.value);
                      }}
                    />
                  </div>

                }
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">
                  Total Acquisition @ {cp(slider1)} LVR
                </p>
                <MySlider
                  value={slider1}
                  min={1}
                  onChange={(e: any) => {
                    setSlider1(e.value)
                    setLoantoValueRatioLVR((e.value / 100) || 0);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">{slider1}%</span>
                  <InputText
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    onFocus={(e: any) => (e.target.value = "")}
                    value={slider1}
                    onChange={(e: any) => {
                      let value = Number(e?.target?.value)
                      setSlider1(value);
                      setLoantoValueRatioLVR((value / 100) || 0);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Risk Factored Yield Return</p>
                <MySlider
                  min={5}
                  max={10}
                  step={0.01}
                  value={targetYieldReturn}
                  onChange={(e: any) => {
                    setTargetYieldReturn(e.value);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">
                    {targetYieldReturn?.toFixed(2)}%
                  </span>

                  <InputNumbers
                    mode="decimal"
                    minFractionDigits={1}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    onFocus={(e: any) => (e.target.value = "")}
                    value={targetYieldReturn?.toFixed(2)}
                    onChange={(e: any) => {
                      setTargetYieldReturn(e.value);

                    }}
                  />
                </div>
                <div className="d-flex">
                  <div className="eclipse"></div>
                  <span className="fixed-percentage">Fixed percentage</span>
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Debt Interest Rate</p>
                <MySlider
                  step={0.025}
                  min={0}
                  max={16}
                  value={DebtInterestRate}
                  onChange={(e: any) => {
                    setDebtInterestRate(e.value);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">{DebtInterestRate?.toFixed(2)}%</span>
                  <InputNumbers
                    mode="decimal"
                    minFractionDigits={1}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    onFocus={(e: any) => (e.target.value = "")}
                    value={DebtInterestRate?.toFixed(2)}
                    onChange={(e: any) => {
                      setDebtInterestRate(e.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Debt Principal Rate</p>
                <MySlider
                  step={0.025}
                  min={0}
                  max={16}
                  value={DebtPrincipleRate}
                  onChange={(e: any) => {

                    setDebtPrincipleRate(e.value);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">{DebtPrincipleRate?.toFixed(2)}%</span>
                  <InputNumbers
                    mode="decimal"
                    minFractionDigits={0}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    onFocus={(e: any) => (e.target.value = "")}
                    value={DebtPrincipleRate?.toFixed(2)}
                    onChange={(e: any) => {
                      setDebtPrincipleRate(e.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Equity Interest Rate </p>
                <MySlider
                  step={0.025}
                  min={0}
                  max={16}
                  value={EquityInterestRate}
                  onChange={(e: any) => {

                    setEquityInterestRate(e.value);
                    // setAppliedInterestRate(e.value + DebtInterestRate);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">
                    {EquityInterestRate?.toFixed(2)}%
                  </span>
                  <InputNumbers
                    onFocus={(e: any) => (e.target.value = "")}
                    onBlur={(e: any) =>
                      (e.target.value = EquityInterestRate?.toFixed(2))
                    }
                    minFractionDigits={1}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    value={EquityInterestRate?.toFixed(2)}
                    onChange={(e: any) => {

                      setEquityInterestRate(e.value);

                      // setAppliedInterestRate(e.value + DebtInterestRate);
                    }}
                  />
                </div>
                <div className="d-flex">
                  <div className="eclipse"></div>
                  <span className="fixed-percentage">Fixed percentage</span>
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Equity Principal Rate </p>
                <MySlider
                  step={0.025}
                  min={0}
                  max={16}
                  value={EquityPrincipleRate}
                  onChange={(e: any) => {

                    setEquityPrincipleRate(e.value);
                    // setAppliedInterestRate(e.value + DebtInterestRate);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">
                    {EquityPrincipleRate?.toFixed(2)}%
                  </span>
                  <InputNumbers
                    onFocus={(e: any) => (e.target.value = "")}
                    onBlur={(e: any) =>
                      (e.target.value = EquityPrincipleRate?.toFixed(2))
                    }
                    minFractionDigits={1}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    value={EquityPrincipleRate?.toFixed(2)}
                    onChange={(e: any) => {

                      setEquityPrincipleRate(e.value);

                      // setAppliedInterestRate(e.value + DebtInterestRate);
                    }}
                  />
                </div>
                <div className="d-flex">
                  <div className="eclipse"></div>
                  <span className="fixed-percentage">Fixed percentage</span>
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">
                Outgoing Expenses </p>
                <MySlider
                  step={0.025}
                  min={0}
                  max={100}
                  value={outgoingExpensePercent}
                  onChange={(e: any) => {
                    setoutgoingExpensePercent(e.value)
                    setoutgoingExpenses(PropertyRentIncomeYear1 * Number(e.value/100))  
               
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">
                    {outgoingExpensePercent?.toFixed(2)}%
                  </span>
                  <InputNumbers
                    onFocus={(e: any) => (e.target.value = "")}
                    onBlur={(e: any) =>
                      (e.target.value =outgoingExpensePercent?.toFixed(2))
                    }
                    minFractionDigits={1}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    value={outgoingExpensePercent?.toFixed(2)}
                    onChange={(e: any) => {
                      setoutgoingExpensePercent(e.value)
                      setoutgoingExpenses(PropertyRentIncomeYear1 * Number(e.value/100))  
                     
                    }} 
                  />
                </div>
                <div className="d-flex">
                  <div className="eclipse"></div>
                  <span className="fixed-percentage">Fixed percentage</span>
                </div>
              </div>
              <div className="mb-4">
                <span className="lbl">Mortgage Insurance apply?</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="mortgageInsuranceApply1"
                      name="mortgageInsuranceApply"
                      value="Y"
                      onChange={(e) => setMortgageInsuranceApply(e.value)}
                      checked={mortgageInsuranceApply === "Y"}
                    />
                    <label htmlFor="useExtraCash1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="mortgageInsuranceApply2"
                      name="mortgageInsuranceApply"
                      value="N"
                      onChange={(e) => setMortgageInsuranceApply(e.value)}
                      checked={mortgageInsuranceApply === "N"}
                    />
                    <label htmlFor="useExtraCash2">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              {lockedPropeties.PropertyGenerators?.length > 0 &&
                lockedPropeties?.PropertyGenerators[props.number]?.isLocked ===
                true ? (
                <GradientButton
                  width="124px"
                  icon={undefined}
                  txt={"Updates Locked"}
                  onClick={() => {
                    setLoading(false);
                    GS.SOT.PropertyGenerators[props.number].isLocked = false;
                    dispatch(
                      setLockedProperties({
                        tableData: [...GS.SOT.PortfolioSummary.tableData],
                        PropertyGenerators: [...GS.SOT.PropertyGenerators],
                      })
                    );
                    setTimeout(() => {
                      setLoading(false);
                    }, 2000);
                  }}
                />
              ) : (
                <GradientButton
                  width="124px"
                  icon={undefined}
                  txt={"Lock Updates"}
                  onClick={() => {
                    setLoading(true); 
                    lockSummary();
                    setTimeout(() => {
                      setLoading(false);
                    }, 2000);
                  }}
                />
              )}
              {loading == true && (
                <div className="px-1">
                  <Loading height="0" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <div>
          <GradientButton
            width="89px"
            icon={undefined}
            style={{
              position: "fixed",
              bottom: "31px",
              right: "94px",
              width: "89px !important"
            }}
            txt={"Next"}
            onClick={() => {
              publish("PropertyActivate", null);
              window.scroll(0, 200);
            }}
          />
          <GradientButton
            width="89px"
            icon={undefined}
            style={{
              position: "fixed",
              bottom: "31px",
              right: "196px",

            }}
            onClick={() => {
              props.onBack("Generator");
            }}
            txt="Back"
          />
        </div>
      </div>
    </>
  );
}
export default PropertyGenerator;
