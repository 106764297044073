import styled from "styled-components";

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow:${({overflow})=>overflow && overflow} !important;
`;
export const HomeContainer = styled.div`
  width:100%;
  background-color: var(--off-white);
  
`;
