const initialState = {
  data: null,
};

const IncomePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROPERTY_IMAGES":
      return { ...state, data: action.payload };
    case "UPDATE_PROPERTY_IMAGES":
      let UpdatedData = [...state.data];
      UpdatedData[action.payload.idx] = action.payload;
      return { data: UpdatedData };
    default:
      return state;
  }
};
export default IncomePlanReducer;
