import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import { CurrencyFormatter } from "../../utils";
import "./styleSheets.css";
import { GS } from "../../services/global";
import { Image } from "react-bootstrap";
import PlusIcon from "../../assets/images/arrowdown.png";
import MinusIcon from "../../assets/images/arrowup.png";
import { commaSeparators } from "../../utils";
export default function StrategicSpendingTableColumn({
  PrincipleData,
  EquityGains,
  updateEquityGains,
  FilterBy,
  Editable,
  setEditable,
  data,
  isSideBar = false,
  setBalanceSheet,
  balanceSheet,
  setCashOnHand,
  cashOnHand,
  accountYour,
  setAccountYour,
  accountPartner,
  setAccountPartner,
  liabilities,
  setLiabilities,
  monthlyCashflow,
  setMonthlyCashflow,
  income,
  setIncome,
  setOtherIncome,
  OtherIncome,
  netSalaryYour,
  setNetSalaryYour,
  netSalaryPartner,
  setNetSalaryPartner,
  rentalIncome,
  setRentalIncome,
  
  PrimaryResidence,
  setPrimaryResidence,
  AlcoholicBeverages,
  setAlcoholicBeverages,
                        ClothingAndFootwear,
                        setClothingAndFootwear,
                        Communication,
                        setCommunication,
                        CurrentHousingCosts,
                        setCurrentHousingCosts,
                        DomesticFuelAndPower,
                        setDomesticFuelAndPower,
                        FoodAndNonAlcoholicBeverages,
                        setFoodAndNonAlcoholicBeverages,
                        HouseholdFurnishingsAndEquipment,
                        setHouseholdFurnishingsAndEquipment,
                        HouseholdServicesAndOperation,
                        setHouseholdServicesAndOperation,
                        HealthFitness,
                        setHealthFitness,
                        PersonalCare,
                        setPersonalCare,
                        Recreation,
                        setRecreation,
                        Travel,
                        setTravel,
                        AccountantFees,
                        setAccountantFees,
                        BodyCorporatePayments,
                        setBodyCorporatePayments,
                        CashGiftsAndDonations,
                        setCashGiftsAndDonations,
                        FeesNecGovernment,
                        setFeesNecGovernment,
                        FeesNecPrivate,
                        setFeesNecPrivate,
                        FinancialInstitutionCharges,
                        setFinancialInstitutionCharges,
                        HireServicesNec,
                        setHireServicesNec,
                        InsuranceNec,
                        setInsuranceNec,
                        MiscellaneousGoodsNec,
                        setMiscellaneousGoodsNec,
                        MiscellaneousGoodsNfd,
                        setMiscellaneousGoodsNfd,
                        MiscellaneousServicesNec,
                        setMiscellaneousServicesNec,
                        NonHolidayAccommodation,
                          setNonHolidayAccommodation,
                          StationeryEquipmentnfd,
                          setStationeryEquipmentnfd,
                          TravelGoods,
                          setTravelGoods,
                          UnionDues,
                          setUnionDues,
                          cashWithdrawals,
  setcashWithdrawals,
  BankFees,
  setBankFees,
  loanInterests,
  setloanInterests,
  externalTransfers,
  setexternalTransfers,
  Education,
  setEducation,
  CarLoan,
  setCarLoan,
  setOtherExpenses,
  OtherExpenses,
  LiabilitiesColumns,
  MonthlyCashFlowColumns,
  ExpensesColumns,
  BalanceSheetColumns,
  CashColumns,
  PrincipleColumns,
  IncomeColums,
  OnChange,
  AsutralianAverageExpensesColumn,
  uaaae,
  PersonalExpenses,
  setPersonalExpenses,
  setEquityGain,
  CreditCards,
  updateCreditCard,
  ScrollPosition,
  ExtraCash,
  ShowPrinciples,
  setShowPrinciples,
  ShowIncome,
  setShowIncome,
  ShowLiabilities,
  setShowLiabilities,
  ShowLivingExpenses,
  setShowLivingExpenses,
  expenses
}) {
 
  return (
    <>
      <div
        className={`tbl-column ${
          isSideBar
            ? "tbl-header-column"
            : data?.date === moment().format("MMM-YYYY") &&
              ScrollPosition >= 120
            ? "table-title-current"
            : ""
        }`}
      > 
        {isSideBar ? (
          <span className={`${ScrollPosition >= 120 ? "table-title" : ""}`}>
            <div className="placeholder-cell"></div>
            {FilterBy === "ByMonth" &&
              BalanceSheetColumns?.map((column, idx) => (
                <div className={column.classes} key={idx}>
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title} 
                  </p>
                </div>
              ))}
            {FilterBy === "ByMonth" || FilterBy === "Cash"
              ? CashColumns?.map((column, idx) => (
                
                 <>
                 {(column.title==="Cash-on hand" && column.value!==0) &&
                  <div
                  className={column.classes}
                  key={idx}
                  onClick={() =>
                    column.title === "Equity Gain" &&
                    setShowPrinciples(!ShowPrinciples)
                  }
                >
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title === "Equity Gain" ? (
                      <Image
                        className={"collapseIcon"}
                        src={ShowPrinciples ? MinusIcon : PlusIcon}
                      />
                    ) : (
                      ""
                    )}
                    {column.title.includes("Property")
                      ? `IP${
                          column.title[column.title.length - 25]
                        } - Capital Growth Equity`
                      : column.title}
                  </p>
                </div>
                 }
                    {(column.title==="Account (yours)" && column.value!==0) &&
                  <div
                  className={column.classes}
                  key={idx}
                  onClick={() =>
                    column.title === "Equity Gain" &&
                    setShowPrinciples(!ShowPrinciples)
                  }
                > 
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title === "Equity Gain" ? (
                      <Image
                        className={"collapseIcon"}
                        src={ShowPrinciples ? MinusIcon : PlusIcon}
                      />
                    ) : (
                      ""
                    )}
                    {column.title.includes("Property")
                      ? `IP${
                          column.title[column.title.length - 25]
                        } - Capital Growth Equity`
                      : column.title}
                  </p>
                </div>
                 }
                    {(column.title==="Account (partners)" && column.value!==0) &&
                  <div
                  className={column.classes}
                  key={idx}
                  onClick={() =>
                    column.title === "Equity Gain" &&
                    setShowPrinciples(!ShowPrinciples)
                  }
                >
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title === "Equity Gain" ? (
                      <Image
                        className={"collapseIcon"}
                        src={ShowPrinciples ? MinusIcon : PlusIcon}
                      />
                    ) : (
                      ""
                    )}
                    {column.title.includes("Property")
                      ? `IP${
                          column.title[column.title.length - 25]
                        } - Capital Growth Equity`
                      : column.title}
                  </p>
                </div>
                 }
                
                    {(column.title==="Extra Cash"  ) &&
                  <div
                  className={column.classes}
                  key={idx}
                  onClick={() =>
                    column.title === "Equity Gain" &&
                    setShowPrinciples(!ShowPrinciples)
                  }
                >
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title === "Equity Gain" ? (
                      <Image
                        className={"collapseIcon"}
                        src={ShowPrinciples ? MinusIcon : PlusIcon}
                      />
                    ) : (
                      ""
                    )}
                    {column.title.includes("Property")
                      ? `IP${
                          column.title[column.title.length - 25]
                        } - Capital Growth Equity`
                      : column.title}
                  </p>
                
                </div>
                 
                 }
                    {(column.title!=="Cash-on hand" && column.title!=="Account (yours)"&&column.title!=="Account (partners)"&&column.title!=="Extra Cash") &&
                  <div

                  className={column.classes}
                  key={idx}
                  onClick={() =>
                    column.title === "Equity Gain" &&
                    setShowPrinciples(!ShowPrinciples)
                  }
                >
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title === "Equity Gain" ? (
                      <Image
                        className={"collapseIcon"}
                        src={ShowPrinciples ? MinusIcon : PlusIcon}
                      />
                    ) : (
                      ""
                    )}
                    {column.title.includes("Property")
                      ? `IP${
                          column.title[column.title.length - 25]
                        } - Capital Growth Equity`
                      : column.title}
                  </p>
                </div>
                 }
                 </>
                ))
              : ""}
            {FilterBy === "ByMonth" || FilterBy === "Cash"
              ? ShowPrinciples &&
                PrincipleColumns?.map((column, idx) => (
                  <div className={column.classes} key={idx}>
                    <p
                      className={`${
                        Editable === column.title && "EditableColumnTitle"
                      }`}
                    >
                      {column.title.includes("Property")
                        ? `IP${idx} - Principle`
                        : column.title}
                    </p>
                  </div>
                ))
              : ""}
            {FilterBy === "ByMonth" &&
              MonthlyCashFlowColumns?.map((column, idx) => (
                <div className={column.classes} key={idx}>
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title}
                  </p>
                </div>
              ))}

            {FilterBy === "ByMonth" || FilterBy === "Income"
              ? IncomeColums?.map((column, idx) => (
                <>
                {(column.title==="Income" && column.value!==0) &&
                <div className={column.classes} key={idx}>
                <p
                  className={`${
                    Editable === column.title && "EditableColumnTitle"
                  }`}
                  onClick={()=>setShowIncome(!ShowIncome)}
                >
                  <Image
                        className={"collapseIcon"}
                        src={ShowIncome ? MinusIcon : PlusIcon}
                      />
                  {column.title}
                </p>
              </div>
                }
                {ShowIncome && 
                <>
                   {(column.title==="Net Salary (Yours)" && column.value!==0) &&
                <div className={column.classes} key={idx}>
                <p
                  className={`${
                    Editable === column.title && "EditableColumnTitle"
                  }`}
                >
                  {column.title}
                </p>
              </div>
                }
                 {(column.title==="Net Salary (Partners)" && column.value!==0) &&
                <div className={column.classes} key={idx}>
                <p
                  className={`${
                    Editable === column.title && "EditableColumnTitle"
                  }`}
                >
                  {column.title}
                </p>
              </div>
                }
                 {(column.title==="Rental Income" && column.value!==0) &&
                <div className={column.classes} key={idx}>
                <p
                  className={`${
                    Editable === column.title && "EditableColumnTitle"
                  }`}
                >
                  {column.title}
                </p>
              </div>
                }
                 {(column.title==="Other Income" && column.value!==0) &&
                <div className={column.classes} key={idx}>
                <p
                  className={`${
                    Editable === column.title && "EditableColumnTitle"
                  }`}
                >
                  {column.title}
                </p>
              </div>
                }
                </>
                }
              
                </>
                  
                ))
              : ""}

            {FilterBy === "ByMonth" || FilterBy === "Liabilties"
              ? LiabilitiesColumns?.map((column, idx) => (
                <>
                {(column.title==="Liabilities"&& column.value!==0) &&
                 <div className={column.classes} key={idx}>
                 <p
                   className={`${
                     Editable === column.title && "EditableColumnTitle"
                   }`}
                   onClick={()=>setShowLiabilities(!ShowLiabilities)}
                 >
                  <Image
                        className={"collapseIcon"}
                        src={ShowLiabilities ? MinusIcon : PlusIcon}
                      />
                   {column.title}
                 </p>
               </div>
                }
                {(column.title!=="Liabilities"&& column.value!==0) &&
                 <div className={column.classes} key={idx}>
                 <p
                   className={`${
                     Editable === column.title && "EditableColumnTitle"
                   }`}
                 >
                   {column.title}
                 </p>
               </div>
                }
                </>
                 
                ))
              : ""}
            {FilterBy === "ByMonth" ? (
              <>
              {((GS?.SOT?.Equity?.PPR?.HomeLoanRepayments!==0)&& (ShowLiabilities==true))&&
               <div className="sidebar-cell income-secondary">
               <p>PPR Monthly Mortgage</p>
             </div>
              }
              </>
             
            ) : (
              ""
            )}
            {FilterBy === "ByMonth"
              ? GS.SOT.Equity.properties.map((item, index) => {
                  return (
                    <>
                    {((item.LoanRepayments!==0)&&(ShowLiabilities==true)) &&<div key={index} className="sidebar-cell income-secondary">
                      <p>IP{index + 1} Monthly Mortgage</p>
                    </div> }
                    </>
                    
                  );
                })
              : ""}
            {FilterBy === "ByMonth" || FilterBy === "Expenses"
              ? uaaae
                ? AsutralianAverageExpensesColumn.map((column, idx) => (
                   <>
                   {(column.title=="Expenses" && column.value!==0) &&
                     <div className={column.classes} key={idx}>
                     <p
                       className={`${
                         Editable === column.title && "EditableColumnTitle"
                       }`}
                       onClick={()=>setShowLivingExpenses(!ShowLivingExpenses)}
                     >
                       <Image
                        className={"collapseIcon"}
                        src={ShowLivingExpenses ? MinusIcon : PlusIcon}
                      />
                      Living {column.title}
                     </p>
                   </div>
                   } {(column.title=="Other" && column.value!==0) &&
                   <div className={column.classes} key={idx}>
                   <p
                     className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                   >
                     {column.title}
                   </p>
                 </div>
                 }
                  
                   </>
                  )) 
                : ExpensesColumns?.map((column, idx) => (
                  <>
                   {(column.title==="Expenses" && column.value!==0) &&  <div className={column.classes} key={idx}>
                   <p
                     className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                     onClick={()=>setShowLivingExpenses(!ShowLivingExpenses)}
                    >
                      <Image
                        className={"collapseIcon"}
                        src={ShowLivingExpenses ? MinusIcon : PlusIcon}
                      />
                    Living  {column.title}
                   </p>
                 </div>}
                 {((column.title==="Alcoholic beverages") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Clothing and footwear") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Communication") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Current housing costs") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Domestic Fuel & Power") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Education") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Food & Non-Alcoholic Beverages") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Household Furnishings & Equipment") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Household Services & Operation") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Medical Care & Health Expenses") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Personal Care") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Recreation") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Transport") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Accountant Fees") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Body Corporate Payments") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Cash Gifts, Donations To Charity Nec") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Fees Nec - Government") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Fees Nec - Private") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Financial Institution Charges") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Hire Services Nec") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Insurance Nec") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Miscellaneous Goods Nec") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Miscellaneous Goods Nfd") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Miscellaneous Services Nec") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Non-Holiday Accommodation") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Stationery Equipment Nfd") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Travel goods, handbags & related") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Cash Withdrawls") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Bank Fees") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Union dues, professional association subscriptions") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="Loan Interest") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
                  {((column.title==="External Transfers") && (column.value!==0)&&(ShowLivingExpenses)) &&  <div className={column.classes} key={idx}>
                   <p
                      className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                   </p>
                  </div>}
{(column.title==="Other" && (column.value!==undefined &&column.value!==0) ) &&  <div className={column.classes} key={idx}>
                    <p
                     className={`${
                       Editable === column.title && "EditableColumnTitle"
                     }`}
                    >
                      {column.title}
                    </p>
                 </div>}
                  </>
                    // <div className={column.classes} key={idx}>
                    //   <p
                    //     className={`${
                    //       Editable === column.title && "EditableColumnTitle"
                    //     }`}
                    //   >
                    //     {column.title}
                    //   </p>
                    // </div>
                  ))
              : ""}
          </span>
        ) : (
          <>
            <div
              style={{ paddingTop: 10 }}
              className="header-cell block text-center"
            >
              <p>
                {data?.date === moment().format("MMM-YYYY")
                  ? "Current"
                  : "Forcast"}
              </p>
              <span>{data?.date}</span>
            </div>
            {FilterBy === "ByMonth" && (
              <div
                className="tbl-cell balanceSheet"
                onClick={() => setEditable("Balance Sheet")}
              >
                {Editable === "Balance Sheet" &&
                data?.date === moment().format("MMM-YYYY") ? (
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `$${balanceSheet.toFixed(2)}`)
                    }
                    value={balanceSheet}
                    onChange={(e) => {
                      setBalanceSheet(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="tbl-input border-none bg-white rounded-0"
                  />
                ) : data?.balanceSheet?.total ? (
                  CurrencyFormatter.format(data?.balanceSheet?.total)
                ) : (
                  "$0.00"
                )}
              </div>
            )}
            {FilterBy === "ByMonth" || FilterBy === "Cash" ? (
              <>
              {cashOnHand!==0 && 
              <div
              className="tbl-cell cashOnHand-primary"
              onClick={() => setEditable("Cash-on hand")}
            >
              {Editable === "Cash-on hand" &&
              data?.date === moment().format("MMM-YYYY") ? (
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `$${cashOnHand.toFixed(2)}`)
                  }
                  value={cashOnHand}
                  onChange={(e) => {
                    setCashOnHand(e.value);
                    OnChange();
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={1}
                  inputClassName="tbl-input border-none bg-white rounded-0"
                />
              ) : data?.balanceSheet?.cashOnHand?.total2 ? (
                CurrencyFormatter.format(
                  data?.balanceSheet?.cashOnHand?.total2
                )
              ) : (
                "$0.00"
              )}
            </div>
              }
                {accountYour!==0 && 
                  <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Account (yours)")}
                >
                  {Editable === "Account (yours)" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${accountYour.toFixed(2)}`)
                      }
                      value={accountYour}
                      onChange={(e) => {
                        setAccountYour(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.balanceSheet?.cashOnHand?.accountYour ? (
                    CurrencyFormatter.format(
                      data?.balanceSheet?.cashOnHand?.accountYour
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                }
              {accountPartner!==0 &&
                <div
                className="tbl-cell cashOnHand-secondary"
                onClick={() => setEditable("Account (partners)")}
              >
                {Editable === "Account (partners)" &&
                data?.date === moment().format("MMM-YYYY") ? (
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `$${accountPartner.toFixed(2)}`)
                    }
                    value={accountPartner}
                    onChange={(e) => {
                      setAccountPartner(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="tbl-input border-none bg-white rounded-0"
                  />
                ) : data?.balanceSheet?.cashOnHand?.accountPartner ? (
                  CurrencyFormatter.format(
                    data?.balanceSheet?.cashOnHand?.accountPartner
                  )
                ) : (
                  "$0.00"
                )}
              </div>
              }
                <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Extra Cash")}
                >
                  {/* { data?.balanceSheet?.cashOnHand?.extraCash ? (
                  CurrencyFormatter.format(
                    data?.balanceSheet?.cashOnHand?.extraCash
                  )
                ) : (
                  "$0.00"
                )} */}
                  {ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  ) &&
                  ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  )?.value
                    ? CurrencyFormatter.format(
                        ExtraCash.find(
                          (item) =>
                            data?.date === moment(item?.date).format("MMM-YYYY")
                        )?.value
                      )
                    : CurrencyFormatter.format(
                      data?.balanceSheet?.cashOnHand?.extraCash
                    )}
                </div>
                <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Liquid Assets")}
                >
               
                  {CurrencyFormatter.format(
                      data?.balanceSheet?.cashOnHand?.liquidassets)}
                </div> 
              {/* {ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  ) &&
                  ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  )?.value!==0 && 
                  <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Extra Cash")}
                >
                  {ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  ) &&
                  ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  )?.value
                    ? CurrencyFormatter.format(
                        ExtraCash.find(
                          (item) =>
                            data?.date === moment(item?.date).format("MMM-YYYY")
                        )?.value
                      )
                    : "$0.00"}
                </div>} */}
                
                {EquityGains.map((item, idx) => {
                  return (
                    <div
                    key={idx}
                      className="tbl-cell cashOnHand-secondary"
                      onClick={() => setEditable(item.PropertyName)}
                    >
                      {Editable === item.PropertyName &&
                      moment(data?.date).isBetween(
                        moment(item.startDate)
                          .subtract(1, "M")
                          .format("MMM-YYYY"),
                        moment(item.endDate).add(1, "M").format("MMM-YYYY"),
                        "+"
                      ) ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${
                              item.value / item.noOfMonths
                            }`)
                          }
                          value={item.value / item.noOfMonths}
                          onChange={(e) => {
                            updateEquityGains(
                              idx,
                              "value",
                              e.value * item.noOfMonths
                            );
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : item.value &&
                        moment(data?.date).isBetween(
                          moment(item.startDate)
                            .subtract(1, "M")
                            .format("MMM-YYYY"),
                          moment(item.endDate).add(1, "M").format("MMM-YYYY"),
                          "+"
                        ) ? (
                        CurrencyFormatter.format(item.value / item.noOfMonths)
                      ) : (
                        "$0.00"
                      )}
                    </div>
                  );
                })}
                <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Equity Gain")}
                >
                  {Editable === "Equity Gain" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${data?.balanceSheet?.EquityGain.toFixed(
                          2
                        )}`)
                      }
                      value={data?.balanceSheet?.EquityGain}
                      onChange={(e) => {
                        GS.SOT.PropertyGenerators[0].equityGained = e.value;
                        setEquityGain(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.balanceSheet?.EquityGain ? (
                    CurrencyFormatter.format(data?.balanceSheet?.EquityGain2)
                  ) : (
                    "$0.00"
                  )} 
                 
                </div>  
                {/* idhar */}
                {ShowPrinciples &&
                  PrincipleData?.map((item,index) => {
                    return (
                      <div
                      key={index}
                        className="tbl-cell cashOnHand-secondary"
                        onClick={() => 
                          setEditable(`${item.PropertyName} - Principle`)
                        }
                      >
                        {Editable === `${item.PropertyName} - Principle` ? (
                          <InputNumber
                            onFocus={(e) => (e.target.value = "")}
                            onBlur={(e) =>
                              (e.target.value = `$${item.PrincipleValue}`)
                            }
                            value={item.PrincipleValue}
                            onChange={item.PrincipleValue}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            minfractiondigits={1}
                            inputClassName="tbl-input border-none bg-white rounded-0"
                          />
                        ) : item.PrincipleValue ? (
                          CurrencyFormatter.format(item.PrincipleValue)
                        ) : (
                          "$0.00"
                        )}
                      </div>
                    );
                  })}
              </>
            ) : (
              ""
            )}
            {FilterBy === "ByMonth" && (
              <div
                className="tbl-cell monthlyCashFlow"
                onClick={() => setEditable("MONTHLY SAVINGS")}
              >
                {Editable === "MONTHLY SAVINGS" &&
                data?.date === moment().format("MMM-YYYY") ? (
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `$${monthlyCashflow.toFixed(2)}`)
                    }
                    value={monthlyCashflow}
                    onChange={(e) => {
                      setMonthlyCashflow(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="tbl-input border-none bg-white rounded-0"
                  />
                ) : data?.monthlyCashflow?.total ? (
                  CurrencyFormatter.format(data?.monthlyCashflow?.total)
                ) : (
                  "$0.00"
                )}
              </div>
            )}
            {FilterBy === "ByMonth" || FilterBy === "Income" ? (
              <>
              {income!==0 &&
              <div
              className="tbl-cell income-primary"
              onClick={() => setEditable("Income")}
            >
              {Editable === "Income" &&
              data?.date === moment().format("MMM-YYYY") ? (
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) => (e.target.value = `$${income.toFixed(2)}`)}
                  value={income}
                  onChange={(e) => {
                    setIncome(e.value);
                    OnChange();
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={1}
                  inputClassName="tbl-input border-none bg-white rounded-0"
                />
              ) : data?.monthlyCashflow?.income?.total ? (
                CurrencyFormatter.format(
                  data?.monthlyCashflow?.income?.total
                )
              ) : (
                "$0.00"
              )}
            </div>
              }
              {ShowIncome && 
              <>
              {netSalaryYour!==0 && 
                  <div
                  className="tbl-cell income-secondary"
                  onClick={() => setEditable("Net Salary (Yours)")}
                >
                  {Editable === "Net Salary (Yours)" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${netSalaryYour.toFixed(2)}`)
                      }
                      value={netSalaryYour}
                      onChange={(e) => {
                        setNetSalaryYour(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.income?.netSalaryYour ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.income?.netSalaryYour
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                }
              {netSalaryPartner!==0 && 
              <div
              className="tbl-cell income-secondary"
              onClick={() => setEditable("Net Salary (Partners)")}
            >
              {Editable === "Net Salary (Partners)" &&
              data?.date === moment().format("MMM-YYYY") ? (
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `$${netSalaryPartner.toFixed(2)}`)
                  }
                  value={netSalaryPartner}
                  onChange={(e) => {
                    setNetSalaryPartner(e.value);
                    OnChange();
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={1}
                  inputClassName="tbl-input border-none bg-white rounded-0"
                />
              ) : data?.monthlyCashflow?.income?.netSalaryPartner ? (
                CurrencyFormatter.format(
                  data?.monthlyCashflow?.income?.netSalaryPartner
                )
              ) : (
                "$0.00"
              )} 
            </div>
              }
                {rentalIncome!==0 && 
                <div
                className="tbl-cell income-secondary"
                onClick={() => setEditable("Rental Income")}
              >
                {Editable === "Rental Income" &&
                data?.date === moment().format("MMM-YYYY") ? (
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `$${rentalIncome.toFixed(2)}`)
                    }
                    value={rentalIncome || 0}
                    onChange={(e) => {
                      setRentalIncome(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="tbl-input border-none bg-white rounded-0"
                  />
                ) : data?.monthlyCashflow?.total ? (
                  CurrencyFormatter.format(
                    data?.monthlyCashflow?.income?.rentalIncome
                  )
                ) : (
                  "$0.00"
                )}
              </div>
                }
                {OtherIncome!==0 &&
                 <div
                 className="tbl-cell income-secondary"
                 onClick={() => setEditable("Other Income")}
               > 
                 {Editable === "Other Income" &&
                 data?.date === moment().format("MMM-YYYY") ? (
                   <InputNumber
                     onFocus={(e) => (e.target.value = "")}
                     onBlur={(e) =>
                       (e.target.value = `$${OtherIncome.toFixed(2)}`)
                     }
                     value={OtherIncome}
                     onChange={(e) => {
                       setOtherIncome(e.value);
                       OnChange();
                     }}
                     mode="currency"
                     currency="USD"
                     locale="en-US"
                     minfractiondigits={1}
                     inputClassName="tbl-input border-none bg-white rounded-0"
                   />
                 ) : data?.monthlyCashflow?.income?.OtherIncome ? (
                   CurrencyFormatter.format(
                     data?.monthlyCashflow?.income?.OtherIncome
                   )
                 ) : (
                   "$0.00"
                 )}
               </div>
                }
              </>
              }
                
               
              </>
            ) : (
              ""
            )}

            {FilterBy === "ByMonth" || FilterBy === "Liabilties" ? (
              <>
              {liabilities!==0 &&
              <div
              className="tbl-cell liabilities-primary"
              onClick={() => setEditable("Liabilities")}
            >
              {Editable === "Liabilities" &&
              data?.date === moment().format("MMM-YYYY") ? (
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `$${liabilities.toFixed(2)}`)
                  }
                  value={liabilities}
                  onChange={(e) => {
                    setLiabilities(e.value);
                    OnChange();
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={1}
                  inputClassName="tbl-input border-none bg-white rounded-0"
                />
              ) : data?.balanceSheet?.liabilities?.total ? (
                CurrencyFormatter.format(
                  data?.balanceSheet?.liabilities?.total
                )
              ) : (
                "$0.00"
              )}
            </div>
              }
                
                {CreditCards?.map((cardItem, idx) => {
                  return (
                   <>
                   {cardItem?.minimumPayment!==0 &&
                     <div
                     key={idx}
                     className="tbl-cell cashOnHand-secondary"
                     onClick={() =>
                       setEditable(
                         `${cardItem?.name}-${cardItem?.owner}-${cardItem?.type}`
                       )
                     }
                   >
                     {Editable ===
                       `${cardItem?.name}-${cardItem?.owner}-${cardItem?.type}` &&
                     data?.date === moment().format("MMM-YYYY") ? (
                       <InputNumber
                         onFocus={(e) => (e.target.value = "")}
                         onBlur={(e) =>
                           (e.target.value = `$${cardItem?.minimumPayment}`)
                         }
                         value={cardItem?.minimumPayment}
                         onChange={(e) => {
                           updateCreditCard(idx, "minimumPayment", e.value);
                           OnChange();
                         }}
                         mode="currency"
                         currency="USD"
                         locale="en-US"
                         minfractiondigits={1}
                         inputClassName="tbl-input border-none bg-white rounded-0"
                       />
                     ) : cardItem?.minimumPayment ? (
                       CurrencyFormatter.format(cardItem?.minimumPayment)
                     ) : (
                       "$0.00"
                     )}
                   </div>
                   }
                  
                   </>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {FilterBy === "ByMonth" ? (
              <>
              {((GS?.SOT?.Equity?.PPR?.HomeLoanRepayments!==0)&&(ShowLiabilities==true)) &&
                <div
                className="tbl-cell income-secondary"
                onClick={() => setEditable("PPR Monthly Mortgage")}
              >
                <p>{GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentType==="Monthly"?commaSeparators(GS?.SOT?.Equity?.PPR?.HomeLoanRepayments):GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentType==="Weekly"?(commaSeparators((GS?.SOT?.Equity?.PPR?.HomeLoanRepayments*52)/12)):(commaSeparators((GS?.SOT?.Equity?.PPR?.HomeLoanRepayments)/12))}</p>
                
              </div>
              }
              
                {GS.SOT.Equity.properties.map((item, index) => {
                  return (
                    <>
                    {((item.LoanRepayments!==0)&&(ShowLiabilities==true)) &&
                     <div
                     className="tbl-cell income-secondary"
                     onClick={() => setEditable("PPR Monthly Mortgage")}
                   >
                     <p>{item.LoanRepaymentsType==="Monthly"?commaSeparators(item.LoanRepayments):item.LoanRepaymentsType==="Weekly"?(commaSeparators((item.LoanRepayments*52)/12)):(commaSeparators((item.LoanRepayments)/12))}</p>
                    
                   </div>
                    }
                    
                    </>
                   
                  )
                })}
               
              </>
            ) : (
              ""
            )}
            {FilterBy === "ByMonth" || FilterBy === "Expenses" ? (
              <>
              {expenses!==0 && <div
                  className="tbl-cell expense-primary"
                  onClick={() => setEditable("Expenses")}
                >
                  {Editable === "Expenses" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${PersonalExpenses.toFixed(2)}`)
                      }
                      value={PersonalExpenses}
                      onChange={(e) => {
                        setPersonalExpenses(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.PersonalExpenses ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.PersonalExpenses
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>}
                
                {!uaaae && (
                  <>
                  {((AlcoholicBeverages!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Alcoholic beverages")}
                >
                  {Editable === "Alcoholic beverages" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${AlcoholicBeverages.toFixed(2)}`)
                      }
                      value={AlcoholicBeverages}
                      onChange={(e) => {
                        setAlcoholicBeverages(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.AlcoholicBeverages ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.AlcoholicBeverages
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((ClothingAndFootwear!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Clothing and footwear")}
                >
                  {Editable === "Clothing and footwear" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${ClothingAndFootwear.toFixed(2)}`)
                      }
                      value={ClothingAndFootwear}
                      onChange={(e) => {
                        setClothingAndFootwear(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.ClothingAndFootwear ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.ClothingAndFootwear
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((Communication!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Communication")}
                >
                  {Editable === "Communication" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${Communication.toFixed(2)}`)
                      }
                      value={Communication}
                      onChange={(e) => {
                        setCommunication(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.Communication ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.Communication
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((CurrentHousingCosts!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Current housing costs")}
                >
                  {Editable === "Current housing costs" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${CurrentHousingCosts.toFixed(2)}`)
                      }
                      value={CurrentHousingCosts}
                      onChange={(e) => {
                        setCurrentHousingCosts(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.CurrentHousingCosts ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.CurrentHousingCosts
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((DomesticFuelAndPower!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Domestic Fuel & Power")}
                >
                  {Editable === "Domestic Fuel & Power" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${DomesticFuelAndPower.toFixed(2)}`)
                      }
                      value={DomesticFuelAndPower}
                      onChange={(e) => {
                        setDomesticFuelAndPower(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.DomesticFuelAndPower ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.DomesticFuelAndPower
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((Education!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Education")}
                >
                  {Editable === "Education" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${Education.toFixed(2)}`)
                      }
                      value={Education}
                      onChange={(e) => {
                        setEducation(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.Education ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.Education
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((FoodAndNonAlcoholicBeverages!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Food & Non-Alcoholic Beverages")}
                >
                  {Editable === "Food & Non-Alcoholic Beverages" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${FoodAndNonAlcoholicBeverages.toFixed(2)}`)
                      }
                      value={FoodAndNonAlcoholicBeverages}
                      onChange={(e) => {
                        setFoodAndNonAlcoholicBeverages(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.FoodAndNonAlcoholicBeverages ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.FoodAndNonAlcoholicBeverages
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((HouseholdFurnishingsAndEquipment!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Household Furnishings & Equipment")}
                >
                  {Editable === "Household Furnishings & Equipment" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${HouseholdFurnishingsAndEquipment.toFixed(2)}`)
                      }
                      value={HouseholdFurnishingsAndEquipment}
                      onChange={(e) => {
                        setHouseholdFurnishingsAndEquipment(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.HouseholdFurnishingsAndEquipment ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.HouseholdFurnishingsAndEquipment
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((HouseholdServicesAndOperation!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Household Services & Operation")}
                >
                  {Editable === "Household Services & Operation" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${HouseholdServicesAndOperation.toFixed(2)}`)
                      }
                      value={HouseholdServicesAndOperation}
                      onChange={(e) => {
                        setHouseholdServicesAndOperation(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.HouseholdServicesAndOperation ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.HouseholdServicesAndOperation
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((HealthFitness!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Medical Care & Health Expenses")}
                >
                  {Editable === "Medical Care & Health Expenses" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${HealthFitness.toFixed(2)}`)
                      }
                      value={HealthFitness}
                      onChange={(e) => {
                        setHealthFitness(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.HealthFitness ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.HealthFitness
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((PersonalCare!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Personal Care")}
                >
                  {Editable === "Personal Care" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${PersonalCare.toFixed(2)}`)
                      }
                      value={PersonalCare}
                      onChange={(e) => {
                        setPersonalCare(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.PersonalCare ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.PersonalCare
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((Recreation!==0)&&(ShowLivingExpenses))&&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Recreation")}
                >
                  {Editable === "Recreation" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${Recreation.toFixed(2)}`)
                      }
                      value={Recreation}
                      onChange={(e) => {
                        setRecreation(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.Recreation ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.Recreation
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((Travel!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Transport")}
                >
                  {Editable === "Transport" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${Travel.toFixed(2)}`)
                      }
                      value={Travel}
                      onChange={(e) => {
                        setTravel(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.Travel ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.Travel
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((AccountantFees!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Accountant Fees")}
                >
                  {Editable === "Accountant Fees" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${AccountantFees.toFixed(2)}`)
                      }
                      value={AccountantFees}
                      onChange={(e) => {
                        setAccountantFees(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.AccountantFees ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.AccountantFees 
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((DomesticFuelAndPower!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Body Corporate Payments")}
                >
                  {Editable === "Body Corporate Payments" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${DomesticFuelAndPower.toFixed(2)}`)
                      }
                      value={DomesticFuelAndPower}
                      onChange={(e) => {
                        setDomesticFuelAndPower(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.DomesticFuelAndPower ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.DomesticFuelAndPower
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((CashGiftsAndDonations!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Cash Gifts, Donations To Charity Nec")}
                >
                  {Editable === "Cash Gifts, Donations To Charity Nec" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${CashGiftsAndDonations.toFixed(2)}`)
                      }
                      value={CashGiftsAndDonations}
                      onChange={(e) => {
                        setCashGiftsAndDonations(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.CashGiftsAndDonations ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.CashGiftsAndDonations
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((FeesNecGovernment!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Fees Nec - Government")}
                >
                  {Editable === "Fees Nec - Government" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${FeesNecGovernment.toFixed(2)}`)
                      }
                      value={FeesNecGovernment}
                      onChange={(e) => {
                        setFeesNecGovernment(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.FeesNecGovernment ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.FeesNecGovernment
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((FeesNecPrivate!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Fees Nec - Private")}
                >
                  {Editable === "Fees Nec - Private" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${FeesNecPrivate.toFixed(2)}`)
                      }
                      value={FeesNecPrivate}
                      onChange={(e) => {
                        setFeesNecPrivate(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.FeesNecPrivate ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.FeesNecPrivate
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((FinancialInstitutionCharges!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Financial Institution Charges")}
                >
                  {Editable === "Financial Institution Charges" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${FinancialInstitutionCharges.toFixed(2)}`)
                      }
                      value={FinancialInstitutionCharges}
                      onChange={(e) => {
                        setFinancialInstitutionCharges(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.FinancialInstitutionCharges ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.FinancialInstitutionCharges
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((HireServicesNec!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Hire Services Nec")}
                >
                  {Editable === "Hire Services Nec" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${HireServicesNec.toFixed(2)}`)
                      }
                      value={HireServicesNec}
                      onChange={(e) => {
                        setHireServicesNec(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.HireServicesNec ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.HireServicesNec
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((InsuranceNec!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Insurance Nec")}
                >
                  {Editable === "Insurance Nec" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${InsuranceNec.toFixed(2)}`)
                      }
                      value={InsuranceNec}
                      onChange={(e) => {
                        setInsuranceNec(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.InsuranceNec ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.InsuranceNec
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((MiscellaneousGoodsNec!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Miscellaneous Goods Nec")}
                >
                  {Editable === "Miscellaneous Goods Nec" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${MiscellaneousGoodsNec.toFixed(2)}`)
                      }
                      value={MiscellaneousGoodsNec}
                      onChange={(e) => {
                        setMiscellaneousGoodsNec(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.MiscellaneousGoodsNec ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.MiscellaneousGoodsNec
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((MiscellaneousGoodsNfd!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Miscellaneous Goods Nfd")}
                >
                  {Editable === "Miscellaneous Goods Nfd" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${MiscellaneousGoodsNfd.toFixed(2)}`)
                      }
                      value={MiscellaneousGoodsNfd}
                      onChange={(e) => {
                        setMiscellaneousGoodsNfd(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.MiscellaneousGoodsNfd ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.MiscellaneousGoodsNfd
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((MiscellaneousServicesNec!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Miscellaneous Services Nec")}
                >
                  {Editable === "Miscellaneous Services Nec" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${MiscellaneousServicesNec.toFixed(2)}`)
                      }
                      value={MiscellaneousServicesNec}
                      onChange={(e) => {
                        setMiscellaneousServicesNec(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.MiscellaneousServicesNec ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.MiscellaneousServicesNec
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((NonHolidayAccommodation!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Non-Holiday Accommodation")}
                >
                  {Editable === "Non-Holiday Accommodation" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${NonHolidayAccommodation.toFixed(2)}`)
                      }
                      value={NonHolidayAccommodation}
                      onChange={(e) => {
                        setNonHolidayAccommodation(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.NonHolidayAccommodation ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.NonHolidayAccommodation
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((StationeryEquipmentnfd!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Stationery Equipment Nfd")}
                >
                  {Editable === "Stationery Equipment Nfd" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${StationeryEquipmentnfd.toFixed(2)}`)
                      }
                      value={StationeryEquipmentnfd}
                      onChange={(e) => {
                        setStationeryEquipmentnfd(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.StationeryEquipmentnfd ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.StationeryEquipmentnfd
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((TravelGoods!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Travel goods, handbags & related")}
                >
                  {Editable === "Travel goods, handbags & related" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${TravelGoods.toFixed(2)}`)
                      }
                      value={TravelGoods}
                      onChange={(e) => {
                        setStationeryEquipmentnfd(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.TravelGoods ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.TravelGoods
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((cashWithdrawals!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Cash Withdrawls")}
                >
                  {Editable === "Cash Withdrawls" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${cashWithdrawals.toFixed(2)}`)
                      }
                      value={cashWithdrawals}
                      onChange={(e) => {
                        setcashWithdrawals(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.cashWithdrawals ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.cashWithdrawals
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((BankFees!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Bank Fees")}
                >
                  {Editable === "Bank Fees" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${BankFees.toFixed(2)}`)
                      }
                      value={BankFees}
                      onChange={(e) => {
                        setBankFees(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.BankFees ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.BankFees
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((UnionDues!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Union dues, professional association subscriptions")}
                >
                  {Editable === "Union dues, professional association subscriptions" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${UnionDues.toFixed(2)}`)
                      }
                      value={UnionDues}
                      onChange={(e) => {
                        setUnionDues(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.UnionDues ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.UnionDues
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((loanInterests!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Loan Interest")}
                >
                  {Editable === "Loan Interest" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${loanInterests.toFixed(2)}`)
                      }
                      value={loanInterests}
                      onChange={(e) => {
                        setloanInterests(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.loanInterests ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.loanInterests
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
{((externalTransfers!==0)&&(ShowLivingExpenses)) &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("External Transfers")}
                >
                  {Editable === "External Transfers" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${externalTransfers.toFixed(2)}`)
                      }
                      value={externalTransfers}
                      onChange={(e) => {
                        setexternalTransfers(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.externalTransfers ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.externalTransfers
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}         
                  </>
                )}
{OtherExpenses!==0 &&
  <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Other")}
                >
                  {Editable === "Other" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${OtherExpenses.toFixed(2)}`)
                      }
                      value={OtherExpenses}
                      onChange={(e) => {
                        setOtherExpenses(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US" 
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.OtherExpenses ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.OtherExpenses
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
}
                
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </>
  );
}
