import { useState, useEffect } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { OuterContainer, HomeContainer } from "../Home/Home.styles";
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import CreateAdmin from "../../components/Contact/CreateAdmin";
import { API } from "../../services/api";
const CreateAdmins = () => {
  
 
  return (
    <OuterContainer>
      <UserSidebar headerPath="Contacts" />
      <HomeContainer>
        <Header headerPath="/Contact" />
        <CreateAdmin  />
      </HomeContainer>
    </OuterContainer>
  );
};

export default CreateAdmins;
