import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./stylesheet.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { API } from "../../services/api";
// import GradientButton from "../../components/Button/GradientButton";
import { CurrencyFormatter } from "../../utils";
import { getMedianPrice, setMedianData } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
export default function Suburbs() {
  const [loading, setloading] = useState(false);
  const [ResidentialData, setResidentialData] = useState([]);
  const [OfficeData, setOfficeData] = useState([]);
  const [RetailData, setRetailData] = useState([]);
  const dispatch = useDispatch();

  const avgMedianPrice = (rowData) => {
    return (
      <div>
        {CurrencyFormatter.format(isNaN(rowData?.median) ? 0 : rowData?.median)}
      </div>
    );
  };
  const avgMedianRentalPrice = (rowData) => {
    return (
      <div>
        {CurrencyFormatter.format(
          isNaN(rowData?.medianRental) ? 0 : rowData?.medianRental
        )}
      </div>
    );
  };
  const getSuburbStats = async () => {
    setloading(true);
    const regionsData = await API.get(`median/suburb/all`); //median/regions/all  median/suburb/all
    if (regionsData?.data) {
      setloading(false);
      const resdata = regionsData?.data?.find(
        (i) => i.propertyType === "Residential"
      ).regions;
      const officeData = regionsData?.data?.find(
        (i) => i.propertyType === "Offices"
      ).regions;
      const retailData = regionsData?.data?.find(
        (i) => i.propertyType === "Retail"
      ).regions;
      // const others = regionsData?.data?.find(
      //   (i) => i.propertyType === "Other"
      // ).regions;
      // const industrial = regionsData?.data?.find(
      //   (i) => i.propertyType === "Industrial Warehouse"
      // ).regions;
      let residentialdata = [];
      let officedata = [];
      // let otherdata = [];
      // let industrialdata = [];
      let retaildata = [];
      for (let i = 0; i < resdata.length; i++) {
        const res = resdata[i];
        if (res && res.median > 0) {
          residentialdata.push({
            ...res,
            Suburb_Name: "",
            region: res.name,
            State: res.state,
            Median_Sale_Price: res.median,
            Median_Rental_Price: res.medianRental,
            postcode: res.postcode,
            growthPercent: res.growth_percent,
          });
        }
      }
      for (let i = 0; i < officeData.length; i++) {
        const off = officeData[i];
        if (off && off.median > 0) {
          officedata.push({
            ...off,
            Suburb_Name: "",
            region: off.name,
            State: off.state,
            Median_Sale_Price: off.median,
            Median_Rental_Price: off.medianRental,
            postcode: off.postcode,
            growthPercent: off.growth_percent,
          });
        }
      }
      for (let i = 0; i < retailData.length; i++) {
        const ret = retailData[i];
        if (ret && ret.median > 0) {
          retaildata.push({
            ...ret,
            Suburb_Name: "",
            region: ret.name,
            State: ret.state,
            Median_Sale_Price: ret.median,
            Median_Rental_Price: ret.medianRental,
            postcode: ret.postcode,
            growthPercent: ret.growth_percent,
          });
        }
      }
      // for(let i=0;i<others.length;i++){
      //   const other = others[i];
      //   if (other && other.median>0) {
      //     otherdata.push({
      //       ...other,
      //       Suburb_Name: "",
      //       region: other.name,
      //       State: other.state,
      //       Median_Sale_Price: other.median,
      //       Median_Rental_Price: other.medianRental,
      //       postcode:other.postcode

      //     });
      //   }
      // }
      // for(let i=0;i<industrial.length;i++){
      //   const ind = industrial[i];
      //   if (ind && ind.median>0) {
      //     industrialdata.push({
      //       ...ind,
      //       Suburb_Name: "",
      //       region: ind.name,
      //       State: ind.state,
      //       Median_Sale_Price: ind.median,
      //       Median_Rental_Price: ind.medianRental,
      //       postcode:ind.postcode

      //     });
      //   }
      // }
      const medianPrices = {
        office: officedata,
        // other: otherdata,
        retail: retaildata,
        // industry: industrialdata,
        residential: residentialdata,
      };
      dispatch(getMedianPrice(medianPrices));
      setResidentialData(medianPrices.residential);
      setOfficeData(medianPrices.office);
      setRetailData(medianPrices.retail);
      console.log(medianPrices, "medianPrices");
    }
  };
  useEffect(() => {
    getSuburbStats();
  }, []);
  return (
    <OuterContainer>
      <UserSidebar headerPath="Settings" />
      <HomeContainer>
        <Header headerPath="/Settings/Median Prices" />
        <Card className="mycard customtabs">
          <h1 className="card-title mb-3">Median Prices - Suburbs</h1>
          <div>
            <TabView>
              <TabPanel header="Residential">
                <h1 className="card-title mb-3">Residential</h1>
                {loading ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <DataTable
                    value={ResidentialData}
                    editMode="cell"
                    className="editable-cells-table"
                    responsiveLayout="scroll"
                  >
                    <Column
                      header="Suburb"
                      field="name"
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="State"
                      field="state"
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="Median"
                      body={avgMedianPrice}
                      field="median"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Median Rental Price"
                      body={avgMedianRentalPrice}
                      field="Median_Rental_Price"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Growth"
                      field="growth_percent"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Postcode"
                      field="postcode"
                      style={{ width: "35%" }}
                    ></Column>
                  </DataTable>
                )}
              </TabPanel>
              <TabPanel header="Retail">
                <h1 className="card-title mb-3">Retail</h1>
                {loading ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <DataTable
                    value={RetailData}
                    editMode="cell"
                    className="editable-cells-table"
                    responsiveLayout="scroll"
                  >
                    <Column
                      header="Suburb"
                      field="name"
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="State"
                      field="state"
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="Median"
                      body={avgMedianPrice}
                      field="median"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Median Rental Price"
                      body={avgMedianRentalPrice}
                      field="Median_Rental_Price"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Growth"
                      field="growth_percent"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Postcode"
                      field="postcode"
                      style={{ width: "35%" }}
                    ></Column>
                  </DataTable>
                )}
              </TabPanel>
              <TabPanel header="Offices">
                <h1 className="card-title mb-3">Offices</h1>
                {loading ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <DataTable
                    value={OfficeData}
                    editMode="cell"
                    className="editable-cells-table"
                    responsiveLayout="scroll"
                  >
                    <Column
                      header="Suburb"
                      field="name"
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="State"
                      field="state"
                      style={{ width: "25%" }}
                    ></Column>
                    <Column
                      header="Median"
                      body={avgMedianPrice}
                      field="median"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Median Rental Price"
                      body={avgMedianRentalPrice}
                      field="Median_Rental_Price"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Growth"
                      field="growth_percent"
                      style={{ width: "35%" }}
                    ></Column>
                    <Column
                      header="Postcode"
                      field="postcode"
                      style={{ width: "35%" }}
                    ></Column>
                  </DataTable>
                )}
              </TabPanel>
            </TabView>
          </div>
        </Card>
      </HomeContainer>
    </OuterContainer>
  );
}
