import styled from "styled-components";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";

import Sliderimg from "../../assets/images/PurpleCircle.png";
export const MySlider = styled(Slider)`
  .p-slider-handle {
    background: url("${Sliderimg}") !important;
    width: 20px;
    height: 20px;
    /* background:url("../../assets/images/SliderCirlce.png") !important; */
    /* https://media.geeksforgeeks.org/wp-content/uploads/rk.png */
  }
`;

export const InputNumbers = styled(InputNumber)`
  position: relative;
  &:hover::after {
    content: "Premium Access Only";
    position: absolute;
    font-size: 13px;
    width: 100%;
    left: 0;
    top: 0px;
    border-radius: 6px;
    background-color: #272660;
    padding: 9px;
    color: white !important;
    text-shadow: none !important;
    opacity: 1 !important;
    z-index: 1000 !important;
    text-align: center;
    ${({ txtBlur }) => (txtBlur ? "display:block" : "display:none")}
  }
  .p-inputtext:enabled:focus {
    border-image-source: linear-gradient(
      to right,
      var(--gredient-blue-1) 0%,
      var(--gredient-blue-2) 100%
    ) !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-image-slice: 1;
    border-radius: 4px !important;
  }
  .p-inputnumber-input {
    width: ${({ width }) => (width ? width : "320px")} !important;
    border: 1px solid #cccccc;
    border-radius: 4px !important;
    background-color: ${({ bgColor }) =>
      bgColor ? bgColor : "white"} !important;
    line-height: normal;
    letter-spacing: normal;

    font-family: "Inter" !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    height: 36px;
    padding: 15px 0px 14px 16.8px;
    color: var(--black-main-color);

    overflow: hidden;
    position: relative;
    ${({ txtBlur }) =>
      txtBlur
        ? "color: transparent !important;text-shadow: 0 0 8px #000;background-color: transparent !important;user-select: none"
        : ""}
  }
`;
export const InputTexts = styled(InputText)`
  width: ${({ width }) => (width ? width : "320px !important")};
  border: 1px solid #cccccc;
  border-radius: 4px !important;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Inter" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  height: 36px;
  padding: 15px 0px 14px 16.8px;
  color: var(--black-main-color);
  overflow: hidden;
  position: relative !important; 
  z-index: 5;
  
  &::placeholder {
    font-family: "Inter" !important;
    font-size: 12px !important;
  }
  &:focus {
    ${({ txtBlur }) => (txtBlur ? "border: 1px solid #cccccc !important;box-shadow:none !important" : " border: 1px solid blue !important")}
   
  }
  &:hover{
    ${({ txtBlur }) => (txtBlur ? "border: 1px solid #cccccc !important" : "")}
  
  }
  &:hover::after {
    content: "Premium Access Only";
    position: absolute;
    font-size: 13px;
    width: 100%;
    left: 0;
    top: 0px;
    border-radius: 6px;
    background-color: #272660;
    color: white !important;
    text-shadow: none !important;
    text-align: center;
    opacity: 1 !important; 
    z-index: 1000 !important;
    padding: 9px;
    ${({ txtBlur }) => (txtBlur ? "display:block !important" : "display:none")}
  }
  ${({ txtBlur }) =>
    txtBlur
      ? "color: transparent !important;text-shadow: 0 0 8px #000;background-color: transparent !important;user-select: none"
      : ""}
`;
export const EquityContainer = styled.div`
  padding: 20px 0px 0px 0px;
  .error{
    color:red;
    padding:5px 35px 10px 0px;
    margin-top:-50px;
    width:300px;
    font-size:14px; 
    font-weight:500;
  }
  .error1{
    position: absolute;
    color:red;
    padding:5px 35px 10px 0px;
    bottom:-85px;
    width:300px;
    font-size:14px;
    font-weight:500;
  }
`;
export const CashContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);
  padding-top: 1.5rem;
  padding-left: 32px;
  padding-bottom: 1.5rem;

  .contact-input-group-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 740px) {
      flex-direction: column;
      align-items: flex-start;
      .contact-input1 {
        margin-bottom: 25px;
      }
    }
    margin-bottom: 1.5rem;
    .contact-group-column1 {
      width: 500px;
    }
  }
`;
export const ContactColumn = styled.div`
  width: ${(props) => props.width};
`;
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  padding-top: 25px;
`;
export const PropertiesContainer = styled.div``;

export const GradientContainer = styled.div`
  margin-top: 20px;

  .text-right {
    margin-left: 40px;
  }
`;
