import styled from 'styled-components'
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

export const CustomToast=styled(Toast)`
 .p-toast-message-info{
width: 100% !important;
.p-toast-message-content{
    button{
        margin-left: 10px;
    }
}
}
`
export const CustomProgressBar=styled(ProgressBar)`
width: 30%;
height: 7px;
.p-progressbar-value{
    background-color: #62c68a;
}
.p-progressbar-label{
    opacity: 0;
}
`