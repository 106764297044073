import { ContactListContainer } from "./Contactlist.styles";
import Button from "../Button/Button";
import ContactTable from "./ContactTable";
import Searchbar from "../Searchbar/Searchbar";
import { useNavigate } from "react-router-dom";
const Contactlist = ({
  VendorsData,
  SearchQuery,
  setSearchQuery,
  UpdateData,
}) => {
  const navigate = useNavigate();
  let useremail=""
  if(localStorage.getItem("email")!==null && localStorage.getItem("email")!==undefined){
    useremail=JSON.parse(localStorage.getItem("email"))
  }
  return (
    <>
      <ContactListContainer>
        <div className="profile-container">
          <h1>Contacts</h1>
          <div className="content-right-container">
            <Searchbar
              SearchQuery={SearchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Vendors"
            />
            <Button
              paddingTop="10px"
              height="36px"
              onClick={(e) => {
                e.preventDefault();
                navigate("/CreateContact");
              }}
              txt="Create Contact"
            />
            {useremail==process.env.REACT_APP_ADMIN_EMAIL &&
             <Button
             paddingTop="10px"
             height="36px"
             onClick={(e) => { 
               e.preventDefault();
               navigate("/CreateAdmin");
             }}
             txt="Create Admin"
           />
            }
          </div>
        </div>
        <ContactTable
          VendorsData={VendorsData}
          UpdateData={UpdateData}
          SearchQuery={SearchQuery}
        />
   
      </ContactListContainer>
    </>
  );
};

export default Contactlist;
