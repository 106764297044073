import styled from 'styled-components'

export const ButtonContainer=styled.div`
padding: ${({paddingTop})=>paddingTop!=null?'10px 22px':'14px 40px'};
border-radius: 4px;
box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02), 0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02), 0 19px 122px 0 rgba(7, 62, 132, 0.04);
background-color: var(--main-blue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  height: ${({height})=>height?height:'40px'};
 
  p{
    margin:0;
    padding:0;
    font-family: 'Inter' !important;
    font-size: ${({paddingTop})=>paddingTop!=null?'13px':'15px'};;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal; 
  letter-spacing: normal;
  color:var(--white)
    }
`

export const ButtonGradient=styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space:nowrap;
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  width: ${({width})=>width?width:"124px"};
  height: 40px;
  object-fit: contain;
  border-radius: 4px;
  padding: 10px 22px;
 

  
  /* border-radius: 4px;
  color:var(--main-blue) ;
  background-color: white; */
  background-image: linear-gradient(to right, var(--gredient-blue-1) 0%, var(--gredient-blue-2) 100%);
  ${({txt})=>(
    (txt=="Next"|| txt=="Back") && `
  border-radius:4px;
  color:var(--main-blue);
  background:white !important;
  border: 1px solid var(--main-blue);
  
  `)}
  &:hover{
    background: var(--main-blue);

  };
`