import classes from "./InfoBox.module.css";

export default function InfoBox({
  children,
  show,
  onMouseLeave = () => {},
  className = "",
  style = {},
}) {
  return (
    show && (
      <span
        onMouseLeave={onMouseLeave}
        style={style}
        className={`${className} ${classes.InfoBox}`}
      > 
        {children}
      </span>
    )
    
  );
}
