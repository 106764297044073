/* eslint no-eval: 0 */
import moment from "moment";

import {
  stampDutyNSW,
  stampDutyQLD,
  stampDutySA,
  stampDutyVIC,
  stampDutyWA,
} from "../utils";
import { API } from "./api";
import { SetMBC } from "../redux/actions";
import { useDispatch } from "react-redux";
import pako from 'pako';
class GlobalService {
  public title = "PropVest";
  public isFetchedSOT = false;
  public PortfolioSummarytableData: any = [
    ["Purchase Price"],
    ["Deposit assuming leverage @ 20.00%"],
    ["Stamp Duty"],
    ["Solicitor + Valuation"],
    ["Building Report*"],
    ["Loan Arrangement Fee *"],
    ["Total Cash Required"],
    ["Total Purchase Costs"],
    ["Net Annual Cashflow Return (Year 1)"],
    ["Net Yield on Property (Year 1)"],
    ["Net Yield on Total Purchase Costs (Year 1)"],
    ["Cash on Cash Return"],
    ["Total Cash Required  (20% + Costs)"],
    ["Cost of Loan @ 3.5%pa on leverage"],
    ["Cashflow after mortage costs"],
    ["Cashflow after mortgage (Monthly)"],
    ["Return on Equity (pure cashflow return)"],
    ["Return on Equity with a 5.0% capital growth rate"],
    ["Return on Equity with a 7.0% capital growth rate"],
    ["Return on Equity with a 10.0% capital growth rate"],
  ];
  public update_user: any = {
    first_name: "",
    last_name: "",
    email: "",

    mobile_no: "",
    telephone_no: "",
    contact_address: "",
    enquiry_source: "",
    contact_type: "",
  };
  public user_id: any = "";
  public TotalMortgage: any = 0;

  public SOT: any = {
    VendorId: null,
    
    Sheets: {},
    ai_matrix: {
      Scenario: [1, 3, 3, 2, 1, 1, 3, 3, 2, 1],
      Dependant: 7000,
      BaseExpense: [17800, 26040],

      BuildingReport: 1200,
      PurchasePriceDeviation: 5.3,
      RiskPercentage: { 1: 5, 2: 6, 3: 7 },
    },
    PortfolioSummary: {
      isLocked: false,
      tableData: [],
      selectedRegions:[],
      Properties: {
        retail: [],
        office: [],
        residential: [],
        other: [],
        industry: [],
      },
      PropertyPurchase: [],
    },
    PropertyGenerators: [],

    BorrowingCalculator: {
      own_properties:false,
      income_goal: 0,
      FinancialYear: 0,
      salary_after_tax: 0,
      averageAustralianAnnual:true,
      manualInput:false,
      salary_after_tax_term: "Annually",
      NetRentalIncome_term: "Annually",
      other_income_net: 0,
      other_income_net_term: "Annually",
      partner_salary_after_tax: 0,
      partner_salary_after_tax_term: "Annually",
      dependants: 0,
      car_loan_payment: 0,
      car_loan_payment_2: 0,
      car_loan_payment_2_term: "Monthly",
      other_loan_payment:0,
      annual_expenses: 0,
      annual_expenses_2: 0,
      other_payments: 0,
      other_payments_2: 0,
      other_payments_2_term: "Monthly",
      uaaae: true,
      credit_card_limit: 0,
      credit_card_limit_2: 0,
      credit_card_limit_2_term: "Annually",
      existing_home_loan: 0,
      existing_home_loan_2_term: "Monthly",
      interest_rate: 0,
      loan_term: 0,
      uirb: false,
      base_expanse: 0,
      dependant_base_expanse: 0,
      servicable_income_factor: 0,
      default_bp_interest_rate: 0,
      can_borrow1: 0,
      newmbc:0,
      monthly_repayment: 0,
      can_borrow2: 0,
      monthly_repayment2: 0,
      period: "Annually",
      value1: 0,
      value2: 0,
      Checked: false,
      InterestOnly: false,
      PrincipalInterest: false,
      IncomeType: "Net",
      IncomeGoalStatus: false,
      HECSPayments: {
        type: "Annualy",
        balance: 0,
      },
      HECS: 0,
    },
    Equity: {
      AnnualInterestRate: "5%",
      InterestRateBuffer: 0,
      TequityGain: 0,
      Cash: {
        type: "Net",
        value: 0,
      },
      LiquidAssets: {
        type: "Net",
        value: 0,
      },
      PPR: {
        Address: "",
        PPRMortgage: {
          type: "Annually",
          value: 0,
        },
        PPRInterestRate: "0",
        PurchaseDate: "",
        RemainingLoanTerm: 0,
        LoanTerm: 30,
        DomainAutoVal: "",
        HomeLoanRepayments: 0,
        HomeLoanRepaymentType: "Monthly",
        interest: false,
        PrincipleInterest: true,
        totalEquity: 0,
        PPREquity: 0,
        totalAvailableEquity: 0,
      },
      properties: [],
    },
    StrategicSpending: {
      othercreditcardloans:0,
      totalnetincome:0,
    
      totalcreditCardLimit:0,
      totalliabilities:0,
      balanceSheet: 0,
      cashOnHand: 0,
      accountYour: 0,
      accountPartner: 0,
      liabilities: 0,
      creditCardYours: 0,
      creditCardPartner: 0,
      monthlyCashflow: 0,
      income: 0,
      netSalaryYour: 0,
      netSalaryPartner: 0,
      rentalIncome: 0,
      expenses: 0,
      AutoTransport: 0,
      BillsUtilities: 0,
      Entertainment: 0,
      Equipment: 0,
      FeesCharges: 0,
      FinancialAccounting: 0,
      FoodDining: 0,
      GiftsDonations: 0,
      HealthInsurance: 0,
      LoansCreditCards: 0,
      Misc: 0,
      RentMortgageProperty: 0,
      Shopping: 0,
      CarLoan: 0,

      AlcoholicBeverages:0,
      ClothingAndFootwear:0,
      Communication:0,
      CurrentHousingCosts:0,
      DomesticFuelAndPower:0,
      Education: 0,
      FoodAndNonAlcoholicBeverages:0,
      HouseholdFurnishingsAndEquipment:0,
      HouseholdServicesAndOperation:0,
      HealthFitness: 0,
      PersonalCare:0,
      Recreation:0,
      Travel: 0,
      GoodsAndServices:{
        value:0,
        summary:[
          {
            AccountantFees:0,
          },
          {
            BodyCorporatePayments:0,
          },
          {
            CashGiftsAndDonations:0,
          },
          {
            FeesNecGovernment:0,
          },
          {
            FeesNecPrivate:0,
          },
          {
            FinancialInstitutionCharges:0,
          },
          {
            HireServicesNec:0,
          },
          {
            InsuranceNec:0,
          },
          {
            MiscellaneousGoodsNec:0,
          },
          {
            MiscellaneousGoodsNfd:0,
          },
          {
            MiscellaneousServicesNec:0,
          },
          {
            NonHolidayAccommodation:0,
          },
          {
            StationeryEquipmentnfd:0,
          },
          {
            TravelGoods:0,
          },
          {
            UnionDues:0,
          }
        ]
      },
      OtherExpenses: 0,
      cashWithdrawals:0,
      BankFees:0,
      loanInterests:0,
      loanRepayments:0,
      externalTransfers:0,
      Data: [],
    },
    Progress: {
      steps: 5,
      completed: 0,
      current: 1,
    },
    RiskProfile: {
      NetYieldonProperty: "5%",
      initialPropertyType: "Blended",
    },
    IncomePlan:[]
  };
  public SOT2:any={ 
    PropertyGenerators:[],
    Equity:{},
    StrategicSpending:{},
    RiskProfile:{},
  }

  public initPortfolioTable() {
    this.SOT.PropertyGenerators = [];
    this.SOT.PortfolioSummary.isLocked = false;
    this.SOT.PortfolioSummary.tableData = [...this.PortfolioSummarytableData];
    for (let i = 1; i <= 1; i++) {
      this.SOT.PropertyGenerators.push({
        liquidAssets: 0,
        netIncome: 0,
        extraCash: 0,
        cashAvailable: 0,
        useEquity: "Y",
        equityAvailable: 0,
        percentageEquity: 80,
        totalAvailableFunds: 0,
        useCash: "Y",
        slider1: 80,
        percentageCash: 100,
        totalAcquisitionlabel: 0,
        useLiquidAssets: "N",
        totalAcquisition: 0,
        targetYieldReturn: 5,
        useExtraCash: "N",
        slider2: 0,
        totalAssetsRequiredLeveragedLabel: 0,
        totalAssetsRequiredLeveraged: 0,
        cashRequiredLeveragedLabel: 0,
        cashRequiredLeveraged: 0,
        hidden: false,
        // methodforPurchasePrice: "Target estimator",
        methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
        tableData: {
          PurchasePrice: 0,
          Depositassumingleverage20: 0,
          StampDuty: 0,
          SolicitorValuation: 0,
          BuildingReport: 0,
          LoanArrangementFee: 0,
          TotalCashRequired: 0,
          TotalPurchaseCosts: 0,
          NetAnnualCashflowReturnYear1: 0,
          NetYieldonPropertyYear1: 0,
          NetYieldonTotalPurchaseCostsYear1: 0,
          CashonCashReturn: 0,
          TotalCashRequired20Costs: 0,
          CostofLoan5paonleverage: 0,
          Cashflowaftermortagecosts: 0,
          CashflowaftermortgageMonthly: 0,
          ReturnonEquitypurecashflowreturn: 0,
          ReturnonEquitywitha5capitalgrowthrate: 0,
          ReturnonEquitywitha7capitalgrowthrate: 0,
          ReturnonEquitywitha10capitalgrowthrate: 0,
        },
        summaryData: {
          PurchasePrice: 0,
          interestRate:5.00,
          Depositassumingleverage20: 0,
          AcquisitionCost: 0,
          TotalEquityRequired: 0,
          CurrentEquity: 0,
          TotalPurchaseCosts: 0,
          NetAnnualCashflowReturnYear1: 0,
          NetYieldonPropertyYear1: 0,
          CashonCashReturn: 0,
          TotalCashRequired20Costs: 0,
          CostofLoan5paonleverage: 0,
          Cashflowaftermortagecosts: 0,
          CashflowaftermortgageMonthly: 0,
          ReturnonEquitypurecashflowreturn: 0,
        },
        G3: "",
        A44: 0,
        F44: 0,
        C44: 0,
        A45: "",
        B45: 0,
        A46: 0,
        B46: 0,
        B47: 0,
        B48: 0,
        B49: 0,
        B50: 0,
        B51: 0,
        C51: 0,
        B52: 0,
        B53: 0,
        B54: 0,
        B55: 0,
        B56: 0,
        B57: 0,
        A58: 0,
        B58: 0,
        B59: 0,
        C59: 0,
        B60: 0,
        B61: 0,
        A62: 0,
        B62: 0,
        A63: 0,
        B63: 0,
        A64: 0,
        B64: 0,
      });
    }
  }

  private _lookforkey = (thekey: any, data: any) => {
    let itmIdxByName = null;
    for (let i = 0; i < data.length; i++) {
      if (data[i][0] === thekey) {
        itmIdxByName = i;
        i = 9999999;
      }
    }
    return itmIdxByName;
  };
  private _generatePortfolioSummaryData = () => {
    let PortfolioSummarytableData = [
      ["Property Type"],
      ["Region"],
      ["Purchase Price"],
      ["Deposit"],
      ["Stamp Duty"],
      ["Acquisition Cost"],
      ["LMI"],
      ["Total Deposit Required"],
      ["Total Purchase Costs"],
      ["FINANCE"], //9
      ["Loan to Value Ratio (LVR)"], //10
       ["Applied Interest Rate"],
      ["Total Debt"],
      ["Total Deposit Equity"],
      ["Total Deposit Cash"],    //14
     
      ["CASHFLOW"], //15
      ["Property Rent Income (Year 1)"],
      ["Total Cost of Lending"],
      ["Cashflow (Annual)"],
      ["Cashflow (Monthly)"],
      ["Return on Equity (COC)"], //20
      ["GROWTH HACKING"], //21
      ["Applied Growth Rate (Annual)(%)"], //22
      ["Equity Gain ($)"],
      ["Cash Gain ($)"],
    ];
    const pGenerators: any = this.SOT.PropertyGenerators;
   
    this.SOT.PortfolioSummary.tableData = [...PortfolioSummarytableData];
    const interestRateString = localStorage.getItem("InterestRate");
    let interestRate=5
    if ((interestRateString !== null)&&(interestRateString !== undefined)) {
      interestRate = JSON.parse(interestRateString);
      
    } else {
      interestRate = 5;
    }
    for (let i = 0; i <= this.SOT.PortfolioSummary.tableData.length - 1; i++) {
      if (i === 11) {
        for (let z = 0; z <= this.SOT.PropertyGenerators.length; z++) {
          this.SOT.PortfolioSummary.tableData[i].push("");
        }
      } else {
        for (let z = 0; z <= this.SOT.PropertyGenerators.length; z++) {
          this.SOT.PortfolioSummary.tableData[i].push(0);
        }
      }
    }
    let tempTableData: any = [...this.SOT.PortfolioSummary.tableData];
   
    for (let i = 0; i <= this.SOT.PropertyGenerators.length - 1; i++) {
      for (let propidx = 0; propidx < pGenerators.length; propidx++) {
        let itmIdx = this._lookforkey("Property Type", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["propertyType"];
        }
        itmIdx = itmIdx = this._lookforkey("Region", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["region"];
        }

        itmIdx = itmIdx = this._lookforkey("Purchase Price", tempTableData);
      
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["PurchasePrice"];
          
            
        }

        itmIdx = itmIdx = this._lookforkey("Deposit", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["Deposit"];
        }

        itmIdx = itmIdx = this._lookforkey("Stamp Duty", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["StampDuty"];
        }

        itmIdx = itmIdx = this._lookforkey("Acquisition Cost", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["AcquisitionCost"];
        }

        itmIdx = itmIdx = this._lookforkey("LMI", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["LMI"];
        } 
        itmIdx = itmIdx = this._lookforkey(
          "Total Deposit Required",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDepositRequired"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Total Purchase Costs",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalPurchaseCosts"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Loan to Value Ratio (LVR)",
          tempTableData
        );
       
        if (itmIdx !== null) {
         
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["LoantoValueRatioLVR"] * 100;
        }

        itmIdx = itmIdx = this._lookforkey("Total Debt", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDebt"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Total Deposit Equity",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDepositEquity"];
        }

        itmIdx = itmIdx = this._lookforkey("Total Deposit Cash", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDepositCash"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Applied Interest Rate",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] = Number(pGenerators[propidx].summaryData["interestRate"]);
        }

        itmIdx = itmIdx = this._lookforkey(
          "Property Rent Income (Year 1)",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["PropertyRentIncomeYear1"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Total Cost of Lending",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalCostofLending"];
        }

        itmIdx = itmIdx = this._lookforkey("Cashflow (Annual)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["CashflowAnnual"];
        }

        itmIdx = itmIdx = this._lookforkey("Cashflow (Monthly)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["CashflowMonthly"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Return on Equity (COC)",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["ReturnonEquityCOC"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Applied Growth Rate (Annual)(%)",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["AppliedGrowthRateannualPERC"];
        }

        itmIdx = itmIdx = this._lookforkey("Equity Gain ($)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["EquityGainPERCorDOLLAR"];
        }

        itmIdx = itmIdx = this._lookforkey("Cash Gain ($)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["CashGainDOLLAR"];
        }
      }

      let tmpT: any = [];
      try {
        for (let idx = 1; idx <= tempTableData.length; idx++) {
          let row = tempTableData[idx];
          let col = 0;
          tmpT[idx] = 0;

          for (let i = 0; i < this.SOT.PropertyGenerators.length; i++) {
            col = tempTableData[idx][1 + i];
          
            if (!isNaN(col)) {
              tmpT[idx] += col * 1;
            }                                     
          }
         
          tempTableData[idx][row.length - 1] = tmpT[idx];
         
        }                    
      } catch (e) {}
    }
   
    this.SOT.PortfolioSummary.tableData = tempTableData;
 
    publish("rendersummary", {});
  };
  public get generatePortfolioSummaryData() {
    return this._generatePortfolioSummaryData;
  }
  public set generatePortfolioSummaryData(value) {
    this._generatePortfolioSummaryData = value;
  }
  

  getCountries() {
    return fetch("/data/countries.json")
      .then((res) => res.json())
      .then((d) => d.data);
  }

  public Dates: any = [];
  public Data: any = [];
  public generateDates = (years: any = 100) => {
    let no_of_months = years * 12;
    const months = [];
    const dateStart = moment();
    const dateEnd = moment().add(no_of_months, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push(dateStart.format("MMM-YYYY"));
      dateStart.add(1, "month");
    }
    this.Dates = [...months]; 
  };

  public generateData = (startDate: any = moment(), propno: any = 0) => {
    this.Data = [];
    var SS = { ...this.SOT.StrategicSpending };
    SS.income =
      SS.netSalaryYour + SS.netSalaryPartner + parseFloat(SS.rentalIncome);
    SS.monthlyCashflow =
      SS.Data && SS.Data.length > 0 ? SS.Data[0].monthlyCashflow.total : 0;

    SS.cashOnHand = SS.accountYour + SS.accountPartner + SS.monthlyCashflow;
    SS.balanceSheet = SS.cashOnHand - SS.liabilities;

    let Today = startDate;
    let NextMonth = moment();
    NextMonth.add(1, "month");

    this.generateDates(2000);

    let prevCashOnHand = 0;
    let prevEquityGainValue = 0;

    let InterestValue = this.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyInterestValue;
    let PrincipleValue = this.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyPaymentValue;
    let cal = PrincipleValue - InterestValue;
    cal=cal<0?0:cal
    // console.log(InterestValue,PrincipleValue,"checking",this.SOT?.Equity?.PPR)
    let Principles = [
      {
        PropertyName: "PPR",
        PrincipleValue: cal,
      },
    ];

    this.SOT?.Equity?.properties?.map((property: any) => {
      let value=(property.LoanRepaymentsMonthlyPrincipleInterest-property.LoanRepaymentsMonthlyInterest)
      Principles.push({
        PropertyName: property.PropertyName,
        PrincipleValue:value<0?0:value
      });
      // return null;
    });

    const equityGain = this.SOT?.Equity?.PPR?.totalEquity || 0.0;
 this.Dates?.map((date: any, idx: any) => {
      let cashOnHandValue = 0;
      let balanceSheetValue = 0;
      SS.income =
        SS.netSalaryYour +
        SS.netSalaryPartner +
        (parseFloat(SS.rentalIncome) +
          parseFloat(
            this.SOT.PropertyGenerators[propno].tableData
              .CashflowaftermortgageMonthly
          ));
      // SS.monthlyCashflow = SS.income - (SS.expenses + SS.liabilities);
      // SS.cashOnHand = SS.accountYour + SS.accountPartner + SS.monthlyCashflow;
      SS.balanceSheet = SS.cashOnHand - SS.liabilities;
      // cashOnHandValue =
      //   date === Today.format("MMM-YYYY")
      //     ? this.SOT.StrategicSpending?.Data[0]?.balanceSheet?.cashOnHand.total
      //     : prevCashOnHand +
      //       (SS.accountYour -
      //         SS.accountYour +
      //         (SS.accountPartner - SS.accountPartner)) +
      //       SS.monthlyCashflow;
      cashOnHandValue =
        date === Today.format("MMM-YYYY")
          ? this.SOT?.Equity?.Cash?.value //first month
          : date === NextMonth.format("MMM-YYYY")
          ? SS.cashOnHand
          : prevCashOnHand +
            (SS.accountYour -
              SS.accountYour +
              (SS.accountPartner - SS.accountPartner)) +
            SS.monthlyCashflow;

      balanceSheetValue =
        date === Today.format("MMM-YYYY")
          ? equityGain
          : date === NextMonth.format("MMM-YYYY")
          ? prevEquityGainValue +
            Principles[Principles?.length - 1]?.PrincipleValue
          : prevEquityGainValue +
            Principles[Principles?.length - 1]?.PrincipleValue;
      prevCashOnHand = cashOnHandValue;
      prevEquityGainValue = balanceSheetValue;
      SS.accountYour = 0; 
      SS.accountPartner = 0;
 
      return {
        thedate: Today,
        date,
        balanceSheet: {
          total: balanceSheetValue,
          cashOnHand: {
            total: cashOnHandValue,
            accountYour: SS.accountYour,
            accountPartner: SS.accountPartner,
          },
          liabilities: {
            total: SS.liabilities,
            creditCardPartner: SS.creditCardPartner,
            creditCardYours: SS.creditCardYours,
          },
        },
        monthlyCashflow: {
          total: SS.monthlyCashflow,
          expenses: {
            total: SS.expenses,
            AutoTransport: SS.AutoTransport,
            BillsUtilities: SS.BillsUtilities,
            Education: SS.Education,
            Entertainment: SS.Entertainment,
            Equipment: SS.Equipment,
            FeesCharges: SS.FeesCharges,
            FinancialAccounting: SS.FinancialAccounting,
            FoodDining: SS.FoodDining,
            GiftsDonations: SS.GiftsDonations,
            HealthFitness: SS.HealthFitness,
            HealthInsurance: SS.HealthInsurance,
            LoansCreditCards: SS.LoansCreditCards,
            Misc: SS.Misc,
            RentMortgageProperty: SS.RentMortgageProperty,
            Shopping: SS.Shopping,
            Travel: SS.Travel,
            CarLoan: SS.CarLoan,
          },
          income: {
            total: SS.income,
            netSalaryYour: SS.netSalaryYour,
            netSalaryPartner: SS.netSalaryPartner,
            rentalIncome: SS.rentalIncome,
          },
          static: {
            incomeIdentifier: 1,
            expenseIdentifier: 1,
            addCashFlow: "Yes",
          },
        },
      };
    });
  };

  public generateDataForProperty = (
    startDate: any = moment(),
    propno: any = 0
  ) => {
   
    var SS = { ...this.SOT.StrategicSpending };
    SS.income =
      SS.netSalaryYour + SS.netSalaryPartner + parseFloat(SS.rentalIncome);
    SS.monthlyCashflow =
      SS.Data && SS.Data.length > 0 ? SS.Data[0].monthlyCashflow.total : 0;

    SS.cashOnHand = SS.accountYour + SS.accountPartner + SS.monthlyCashflow;
    SS.balanceSheet = SS.cashOnHand - SS.liabilities;

    let Today = startDate;
    let NextMonth = moment();
    NextMonth.add(1, "month");

    this.generateDates(2000);

    let prevCashOnHand = 0;
    let prevEquityGainValue = 0;
    let prevEquityGain = 0;
    let InterestValue = this.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyInterestValue;
    let PrincipleValue = this.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyPaymentValue;
    let cal = PrincipleValue - InterestValue;
    cal =cal<0 ?0 :cal
    // console.log(InterestValue,PrincipleValue,cal,"checking2",this.SOT?.Equity?.PPR)
    let Principles = [
      {
        PropertyName: "PPR",
        PrincipleValue: cal,
      },
    ];

    this.SOT?.Equity?.properties?.map((property: any) => {
      let value=(property.LoanRepaymentsMonthlyPrincipleInterest-property.LoanRepaymentsMonthlyInterest)
      Principles.push({
        PropertyName: property.PropertyName,
        PrincipleValue:value<0?0:value
          // property.LoanRepaymentsPrincipleInterest -
          // property.LoanRepaymentsInterest,
      });
      // return null;
    });

    const equityGain = this.SOT?.Equity?.PPR?.totalEquity || 0.0;
  
    let data = this.Dates?.map((date: any, idx: any) => {
      let cashOnHandValue = 0;
      let balanceSheetValue = 0;
      SS.income =
        SS.netSalaryYour +
        SS.netSalaryPartner +
        (parseFloat(SS.rentalIncome) +
          parseFloat(
            this.SOT.PropertyGenerators[propno].tableData
              .CashflowaftermortgageMonthly
          ));

      SS.balanceSheet = SS.cashOnHand - SS.liabilities;
      cashOnHandValue =
        date === Today.format("MMM-YYYY")
          ? this.SOT?.Equity?.Cash?.value //first month
          : date === NextMonth.format("MMM-YYYY") 
          ?  SS.cashOnHand
          : 
            (SS.accountYour -
              SS.accountYour +
              (SS.accountPartner - SS.accountPartner)) +
            SS.monthlyCashflow;

            let overallprincipleValue=Principles.reduce((total:any,item:any)=>{
              return total+item.PrincipleValue
            },0)
      
           
          
      balanceSheetValue =
        date === Today.format("MMM-YYYY")
          ? equityGain
          : date === NextMonth.format("MMM-YYYY") 
          ? prevEquityGainValue +overallprincipleValue
            // Principles[Principles?.length - 1]?.PrincipleValue
          : prevEquityGainValue +overallprincipleValue
            // Principles[Principles?.length - 1]?.PrincipleValue;
              
      prevCashOnHand += cashOnHandValue;
      prevEquityGainValue = balanceSheetValue;
      
      SS.accountYour = 0;
      SS.accountPartner = 0;
      return {
        thedate: Today,
        date,
        balanceSheet: {
          total: balanceSheetValue,
          PropertyVal:overallprincipleValue,
          cashOnHand: {
            total: prevCashOnHand,
            accountYour: SS.accountYour,
            accountPartner: SS.accountPartner,
          }, 
          liabilities: {
            total: SS.liabilities,
            creditCardPartner: SS.creditCardPartner,
            creditCardYours: SS.creditCardYours,
          },
        },
        monthlyCashflow: {
          total: SS.monthlyCashflow,
          expenses: {
            total: SS.expenses,
            AutoTransport: SS.AutoTransport,
            BillsUtilities: SS.BillsUtilities,
            Education: SS.Education,
            Entertainment: SS.Entertainment,
            Equipment: SS.Equipment,
            FeesCharges: SS.FeesCharges,
            FinancialAccounting: SS.FinancialAccounting,
            FoodDining: SS.FoodDining,
            GiftsDonations: SS.GiftsDonations,
            HealthFitness: SS.HealthFitness,
            HealthInsurance: SS.HealthInsurance,
            LoansCreditCards: SS.LoansCreditCards,
            Misc: SS.Misc,
            RentMortgageProperty: SS.RentMortgageProperty,
            Shopping: SS.Shopping,
            Travel: SS.Travel,
            CarLoan: SS.CarLoan,
          },
          income: {
            total: SS.income,
            netSalaryYour: SS.netSalaryYour,
            netSalaryPartner: SS.netSalaryPartner,
            rentalIncome: SS.rentalIncome,
          },
          static: {
            incomeIdentifier: 1,
            expenseIdentifier: 1,
            addCashFlow: "Yes",
          },
        },
      };
    });

    this.Data.push([...data]);
  };

  public calculatePropertyGenerator(props: any, propertyno: any = 0) {
    let purchasepr= props?.Data?.purchasePrice||0
  
    let RISKProfilingCommercialE7 = props?.Data?.RISKProfilingCommercialE7;
    let MedianPrices = props?.Data.MedianPrices;
    let RISKProfilingCommercialM7 = props?.Data?.RISKProfilingCommercialM7;
    let RISKProfilingCommercialL7 = props?.Data?.RISKProfilingCommercialL7;
    let RISKProfilingCommercialT7 = props?.Data?.RISKProfilingCommercialT7;
    let RISKProfilingCommercialS7 = props?.Data?.RISKProfilingCommercialS7;
    let ClientInputsC35 = props?.Data?.ClientInputsC35;
    let RemoveTabCalculateG49 = props?.Data?.RemoveTabCalculateG49;
    let liquidAssets = props?.Data?.liquidAssets;
    let netIncome = props?.Data?.netIncome;
    let extraCash = props?.Data?.extraCash;
    let cashAvailable = props?.Data?.cashAvailable;
    let useEquity = props?.Data?.useEquity;
    let equityGain = props?.Data?.equityGain || 0;
    let equityGainGrowth = props?.Data?.equityGainGrowth || 0;
    let cashGainGrowth = props?.Data?.cashGainGrowth || 0;
    let annualGrowth = props?.Data?.AnnualGrowth || 0;
    let percentageEquity = props?.Data?.percentageEquity;
    let equityAvailable = props?.Data?.equityAvailable;
    let totalAvailableFunds = props?.Data?.totalAvailableFunds;
    let useCash = props?.Data?.useCash;
    let slider1 = props?.Data?.slider1;
    let percentageCash = props?.Data?.percentageCash;
    let totalAcquisitionlabel = props?.Data?.totalAcquisitionlabel;
    let useLiquidAssets = props?.Data?.useLiquidAssets;
    let totalAcquisition = props?.Data?.totalAcquisition;
    let targetYieldReturn = props?.Data?.targetYieldReturn;
    let initialPropertyType = props?.Data?.initialPropertyType;
    let useExtraCash = props?.Data?.useExtraCash;
    let slider2 = props?.Data?.slider2;
    let totalAssetsRequiredLeveragedLabel =
      props?.Data?.totalAssetsRequiredLeveragedLabel;
    let totalAssetsRequiredLeveraged =
      props?.Data?.totalAssetsRequiredLeveraged;
    let cashRequiredLeveragedLabel = props?.Data?.cashRequiredLeveragedLabel;
    let cashRequiredLeveraged = props?.Data?.cashRequiredLeveraged;
    let methodforPurchasePrice = props?.Data?.methodforPurchasePrice;
    let interestRate=5
    let EquityInterestRate=1
    let DebtInterestRate=4
    let DebtPrincipleRate= props?.Data?.summaryData?.DebtPrincipleRate || 0
    let EquityPrincipleRate=props?.Data?.summaryData?.EquityPrincipleRate || 0



    const interestRateString = localStorage.getItem("InterestRate");
    const EquityInterestRateString = localStorage.getItem("EquityInterestRate");
    const DebtInterestRateString = localStorage.getItem("DebtInterestRate");

    const DebtPrincipleRateString = localStorage.getItem("DebtPrincipalRate");
    const EquityPrincipleRateString = localStorage.getItem("EquityPrincipalRate");
   
    if((DebtPrincipleRateString !==null) && (DebtPrincipleRateString !==undefined)){
      DebtPrincipleRate=JSON.parse(DebtPrincipleRateString)
    }else{
      DebtPrincipleRate=0
    }
    if((EquityPrincipleRateString!==null)&& (EquityPrincipleRateString!==null)){
      EquityPrincipleRate=JSON.parse(EquityPrincipleRateString)
    }else{
      EquityPrincipleRate=0 
    }

    if ((interestRateString !== null)&&(interestRateString !== undefined)) {
      interestRate = JSON.parse(interestRateString);
      
    } else {
      interestRate = 5;
    }
    if (EquityInterestRateString !== null) {
      EquityInterestRate = JSON.parse(EquityInterestRateString);
    } else {
      EquityInterestRate = 5;
    }
    if (DebtInterestRateString !== null) {
      DebtInterestRate = JSON.parse(DebtInterestRateString);
      interestRate=JSON.parse(DebtInterestRateString);
    } else {
      DebtInterestRate = 5;
      interestRate=5
    }
   
    let G3 = props?.Data?.G3;
    let A44 = props?.Data?.A44;
    let F44 = props?.Data?.F44;
    let C44 = props?.Data?.C44;
    let A45 = props?.Data?.A45;
    let B45 = props?.Data?.B45;
    let A46 = props?.Data?.A46;
    let B46 = props?.Data?.B46;
    let B47 = props?.Data?.B47;
    let B48 = props?.Data?.B48;
    let B49 = props?.Data?.B49;
    let B50 = props?.Data?.B50;
    let B51 = props?.Data?.B51;
    let C51 = props?.Data?.C51;
    let B52 = props?.Data?.B52;
    let B53 = props?.Data?.B53;
    let B54 = props?.Data?.B54;
    let B55 = props?.Data?.B55;
    let B56 = props?.Data?.B56;
    let B57 = props?.Data?.B57;
    let A58 = props?.Data?.A58;
    let B58 = props?.Data?.B58;
    let B59 = props?.Data?.B59;
    let C59 = props?.Data?.C59;
    let B60 = props?.Data?.B60;
    let B61 = props?.Data?.B61;
    let A62 = props?.Data?.A62;
    let B62 = props?.Data?.B62;
    let A63 = props?.Data?.A63;
    let B63 = props?.Data?.B63;
    let A64 = props?.Data?.A64;
    let B64 = props?.Data?.B64;

    let PurchasePrice =purchasepr===0 ? props?.Data?.summaryData?.PurchasePrice:purchasepr;
    let Deposit = props?.Data?.summaryData?.Deposit;
    let StampDuty = props?.Data?.summaryData?.StampDuty;
    let AcquisitionCost = props?.Data?.summaryData?.AcquisitionCost;
    let TotalDepositRequired = props?.Data?.summaryData?.TotalDepositRequired;
    let TotalPurchaseCosts = props?.Data?.summaryData?.TotalPurchaseCosts;
    let LoantoValueRatioLVR = props?.Data?.summaryData?.LoantoValueRatioLVR;
    let TotalDebt = props?.Data?.summaryData?.TotalDebt;
    let TotalDepositEquity = props?.Data?.summaryData?.TotalDepositEquity;
    let TotalDepositCash = props?.Data?.summaryData?.TotalDepositCash;
    let AppliedInterestRate = props?.Data?.summaryData?.AppliedInterestRate;
    let PropertyRentIncomeYear1 =
      props?.Data?.summaryData?.PropertyRentIncomeYear1;
    let TotalCostofLending = props?.Data?.summaryData?.TotalCostofLending;
    let CashflowAnnual = props?.Data?.summaryData?.CashflowAnnual;
    let CashflowMonthly = props?.Data?.summaryData?.CashflowMonthly;
    let ReturnonEquityCOC = props?.Data?.summaryData?.ReturnonEquityCOC;
    let AppliedGrowthRateannualPERC =
      props?.Data?.summaryData?.AppliedGrowthRateannualPERC;
    let EquityGainPERCorDOLLAR =
      props?.Data?.summaryData?.EquityGainPERCorDOLLAR;
    let CashGainDOLLAR = props?.Data?.summaryData?.CashGainDOLLAR;
    let LMI = props?.Data?.summaryData?.LMI;
    let propertytype = props?.Data?.propertyType;
    let selectedRegions=props?.Data?.regions
    
    function theforumla(
      d: any = {
        liquidAssets: null,
        netIncome: null,
        extraCash: null,
        cashAvailable: null,
        useEquity: null,
        equityAvailable: null,
        percentageEquity: null,
        totalAvailableFunds: null,
        useCash: null,
        slider1: null,
        percentageCash: null,
        totalAcquisitionlabel: null,
        useLiquidAssets: null,
        totalAcquisition: null,
        targetYieldReturn: null,
        initialPropertyType: null,
        useExtraCash: null,
        slider2: null,
        totalAssetsRequiredLeveragedLabel: null,
        totalAssetsRequiredLeveraged: null,
        cashRequiredLeveragedLabel: null,
        cashRequiredLeveraged: null,
        methodforPurchasePrice: null,
        propertyType: null,
        hidden: false,
        G3: null,
        A44: null,
        F44: null,
        C44: null,
        A45: null,
        B45: null,
        A46: null,
        B46: null,
        B47: null,
        B48: null,
        B49: null,
        B50: null,
        B51: null,
        C51: null,
        B52: null,
        B53: null,
        B54: null,
        B55: null,
        B56: null,
        B57: null,
        A58: null,
        B58: null,
        B59: null,
        C59: null,
        B60: null,
        B61: null,
        A62: null,
        B62: null,
        A63: null,
        B63: null,
        A64: null,
        B64: null,
      }
    ) {
      let vlookup: any = {};

      let F1 = 0;
      let C2 = liquidAssets;
      let E2 = extraCash;
      let F2 = 0;
      let B3 = cashAvailable;
      let F3 = 0;
      let G3 = "";
      let I3 = useEquity;
      let K3 = 900000;
      let F4 = 0;
      let I4 = percentageEquity;
      let K4 = 432000;
      let B4 = 0;
      if (propertyno === 0) {
        B4 = (equityAvailable / 100) * I4;
      } else {
        B4 = equityAvailable;
      }

      let B5 = totalAvailableFunds;
      let F5 = 0;
      let I5 = useCash;
      let K5 = 612000;
      let F6 = 0;
      let I6 = percentageCash;
      // let K6 = 432000;
      let A7 = 0;
      // let B7 = 0;
      let D7 = slider1;
      let F7 = 1;
      // let G7 = 1;
      let I7 = useLiquidAssets;
      // let K7 = 522000;
      let F8 = 3321772.14;
      // let H8 = "";
      let I8 = 0;
     
      // let K8 = 648000;
      let B9 = targetYieldReturn;
      let I9 = useExtraCash;
      // let A11 = 0;
      // let B11 = 0;
      let A12 = 0;
      // let B12 = 0;
      let F13 = -12.55;

      let F44: any = 0;

      let A45 = "Purchase Price";
      let B45: any = 0;
      // let C45 = 0;
      let A46 = 0;
      let B46 = 0;
      let B47 = 0;
      let B48 = 0;
      // let F48 = 0;
      let B49 = 0;
      // let F49 = 0;
      let B50 = 0;
      // let F50 = 0;
      let B51 = 0;
      let C51 = 0;
      let B52 = 0;
      let B53 = 0;
      let B54 = 0;
      let B55 = 0;
      let B57 = 0;
      let A58 = 3.5;
      let B58 = 0;
      let B59 = 0;
      let C59 = 0;
      let B60 = 0;
      let B61 = 0;
      let A62 = 5;
      let B62 = 0;
      let B63 = 0;
      let A63 = 7;
      let B64 = 0;
      let A64 = 10;


      let propertytype = "Residential";

      vlookup[1] = RISKProfilingCommercialE7;
      vlookup[2] = RISKProfilingCommercialM7 - RISKProfilingCommercialL7;
      vlookup[3] = RISKProfilingCommercialT7 - RISKProfilingCommercialS7;
      vlookup[4] = K4;
      vlookup[5] = (K4 + K5) / 2;
      vlookup[6] = (K3 + K4 + K5) / 3;

      F1 = I9 === "Y" ? E2 : 0;

      F2 = I7 === "Y" ? (I8 === 0 ? C2 : I8 * C2) : 0;

      F3 = I5 === "Y" ? (I6 === 0 ? B3 : I6 * (B3 / 100)) : 0;

      K3 = RISKProfilingCommercialE7;

      F4 = I3 === "Y" ? (I4 < 1 ? B4 : I4 * (B4 / 100)) : 0;

      K4 = RISKProfilingCommercialM7 - RISKProfilingCommercialL7;

      B5 = B3 + B4;

      F5 = F1 + F2 + F3 + F4;

      K5 = RISKProfilingCommercialT7 - RISKProfilingCommercialS7;

      // K6 = K4; 

      A7 = D7 / 100;

      F7 =
        F3 >= RISKProfilingCommercialS7 && F3 < RISKProfilingCommercialT7
          ? F3 >= RISKProfilingCommercialL7 && F3 < RISKProfilingCommercialM7
            ? F3 >= RISKProfilingCommercialE7
              ? 6
              : 5
            : 3
          : 0;

      // B7 = F5 / A7 - (F5 / A7) * (A7 - 0.13);

      // G7 = vlookup[F7] * 1.1 > F3 && vlookup[F7] / 1.1 ? 1 : 2;

      // K7 = (K4 + K5) / 2;

      F8 = B5 / (D7 / 100) - (B5 / (D7 / 100)) * (D7 / 100 - 0.13);

      // H8 = I7 === "Y" ? "Percentage Liquid Assets?" : "";

      // K8 = (K3 + K4 + K5) / 3;

      B9 = ClientInputsC35;

      // B11 = B7;
      A12 = A7;
      // A11 = 1 - A12;
      // B12 = B11 * A12 + B11 * 0.06;

      A45 = "Purchase Price ";

      F44 = RemoveTabCalculateG49 + " + " + A7 * RemoveTabCalculateG49;

      // F48 = F4 + B3;

      // F50 =
      // F48 / A7 -
      // (F48 / A7 / 100) * (A7 * 100 + GS.SOT.ai_matrix.PurchasePriceDeviation);
      // let cashnequity = B3 + F4 + F3;
      // let cashnequity = cashAvailable;
      let medianPropertyType = propertytype;

      

  
      if (ClientInputsC35 <= 5) {
        medianPropertyType = "residential";
      } else if (ClientInputsC35 > 5 && ClientInputsC35 < 6.9) {
        medianPropertyType = "retail";
      } else if (ClientInputsC35 <= 7.5) {
        medianPropertyType = "office";
      } else {
        medianPropertyType = "other";
      }

      if (!GS?.SOT.PortfolioSummary.hasOwnProperty("Properties")) {
        GS.SOT.PortfolioSummary.Properties = {
          retail: [],
          office: [],
          residential: [],
          other: [],
          industry: [],
        };
      } 
      // console.log(GS.SOT.PortfolioSummary.Properties,"GS.SOT.PortfolioSummary.Properties",MedianPrices)
    
      if (GS?.SOT.PortfolioSummary.Properties["office"].length === 0) {
       for(let i=0;i<MedianPrices["office"].length;i++){
        if (MedianPrices["office"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["office"], MedianPrices["office"][i])) {
          GS.SOT.PortfolioSummary.Properties["office"].push({
            region: MedianPrices["office"][i].region,
            suburb: MedianPrices["office"][i].Suburb_Name,
            name: MedianPrices["office"][i].State,
            sale_price:MedianPrices["office"][i].Median_Sale_Price,
            price: MedianPrices["office"][i].Median_Rental_Price,
            postcode:MedianPrices["office"][i]?.postcode,
            growth_percent:MedianPrices["office"][i]?.growth_percent
          });
        }
       }
       for(let i=0;i<MedianPrices["residential"].length;i++){
        if (MedianPrices["residential"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["residential"], MedianPrices["residential"][i])) {
          GS.SOT.PortfolioSummary.Properties["residential"].push({
            region: MedianPrices["residential"][i].region,
            suburb: MedianPrices["residential"][i].Suburb_Name,
            name: MedianPrices["residential"][i].State,
            sale_price:MedianPrices["residential"][i].Median_Sale_Price,
            price: MedianPrices["residential"][i].Median_Rental_Price,
            postcode:MedianPrices["residential"][i]?.postcode,
            growth_percent:MedianPrices["residential"][i]?.growth_percent
          });
        }
       }
      //  for(let i=0;i<MedianPrices["industry"].length;i++){
      //   if (MedianPrices["industry"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["industry"], MedianPrices["industry"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["industry"].push({
      //       region: MedianPrices["industry"][i].region,
      //       suburb: MedianPrices["industry"][i].Suburb_Name,
      //       name: MedianPrices["industry"][i].State,
      //       sale_price:MedianPrices["industry"][i].Median_Sale_Price,
      //       price: MedianPrices["industry"][i].Median_Rental_Price,
      //       postcode:MedianPrices["industry"][i]?.postcode,
      //     });
      //   }
      //  }
      //  for(let i=0;i<MedianPrices["other"].length;i++){
      //   if (MedianPrices["other"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["other"], MedianPrices["other"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["other"].push({
      //       region: MedianPrices["other"][i].region,
      //       suburb: MedianPrices["other"][i].Suburb_Name,
      //       name: MedianPrices["other"][i].State,
      //       sale_price:MedianPrices["other"][i].Median_Sale_Price,
      //       price: MedianPrices["other"][i].Median_Rental_Price,
      //       postcode:MedianPrices["other"][i]?.postcode,
      //     });
      //   }
      //  }
       for(let i=0;i<MedianPrices["retail"].length;i++){
        if (MedianPrices["retail"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["retail"], MedianPrices["retail"][i])) {
          GS.SOT.PortfolioSummary.Properties["retail"].push({
            region: MedianPrices["retail"][i].region,
            suburb: MedianPrices["retail"][i].Suburb_Name,
            name: MedianPrices["retail"][i].State,
            sale_price:MedianPrices["retail"][i].Median_Sale_Price,
            price: MedianPrices["retail"][i].Median_Rental_Price,
            postcode:MedianPrices["retail"][i]?.postcode,
            growth_percent:MedianPrices["retail"][i]?.growth_percent
          });
        }
       }
        // for (let i = 0; i < 10; i++) {
        //   if (MedianPrices["office"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["office"], MedianPrices["office"][i])) {
        //     GS.SOT.PortfolioSummary.Properties["office"].push({
        //       region: MedianPrices["office"][i].region,
        //       suburb: MedianPrices["office"][i].Suburb_Name,
        //       name: MedianPrices["office"][i].State,
        //       sale_price:MedianPrices["office"][i].Median_Sale_Price,
        //       price: MedianPrices["office"][i].Median_Rental_Price,
        //       postcode:MedianPrices["office"][i]?.postcode,
        //     });
        //   }

        //   if (MedianPrices["residential"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["residential"], MedianPrices["residential"][i])) {
        //     GS.SOT.PortfolioSummary.Properties["residential"].push({
        //       region: MedianPrices["residential"][i].region,
        //       suburb: MedianPrices["residential"][i].Suburb_Name,
        //       name: MedianPrices["residential"][i].State,
        //       sale_price:MedianPrices["residential"][i].Median_Sale_Price,
        //       price: MedianPrices["residential"][i].Median_Rental_Price,
        //       postcode:MedianPrices["residential"][i]?.postcode,

        //     }); 
        //   }
        //   if (MedianPrices["industry"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["industry"], MedianPrices["industry"][i])) {
        //     GS.SOT.PortfolioSummary.Properties["industry"].push({
        //       region: MedianPrices["industry"][i].region,
        //       suburb: MedianPrices["industry"][i].Suburb_Name,
        //       name: MedianPrices["industry"][i].State,
        //       sale_price:MedianPrices["industry"][i].Median_Sale_Price,
        //       price: MedianPrices["industry"][i].Median_Rental_Price,
        //       postcode:MedianPrices["industry"][i]?.postcode,

        //     });
        //   }

        //   if (MedianPrices["other"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["other"], MedianPrices["other"][i])) {
        //     GS.SOT.PortfolioSummary.Properties["other"].push({
        //       region: MedianPrices["other"][i].region,
        //       suburb: MedianPrices["other"][i].Suburb_Name,
        //       name: MedianPrices["other"][i].State,
        //       sale_price:MedianPrices["other"][i].Median_Sale_Price,
        //       price: MedianPrices["other"][i].Median_Rental_Price,
        //       postcode:MedianPrices["other"][i]?.postcode,

        //     });
        //   } 

        //   if (MedianPrices["retail"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["retail"], MedianPrices["retail"][i])) {
        //     GS.SOT.PortfolioSummary.Properties["retail"].push({
        //       region: MedianPrices["retail"][i].region,
        //       suburb: MedianPrices["retail"][i].Suburb_Name,
        //       name: MedianPrices["retail"][i].State,
        //       sale_price:MedianPrices["retail"][i].Median_Sale_Price,
        //       price: MedianPrices["retail"][i].Median_Rental_Price,
        //       postcode:MedianPrices["retail"][i]?.postcode,
        //     });
        //   }
        // }
      }
      GS?.SOT.PortfolioSummary.Properties["office"].sort((a: any, b: any) => {
        return b.price - a.price;
      });
      GS?.SOT.PortfolioSummary.Properties["retail"].sort((a: any, b: any) => {
        return a.price - b.price;
      });

      // GS?.SOT.PortfolioSummary.Properties["other"].sort((a: any, b: any) => {
      //   return a.price - b.price;
      // });

      // GS?.SOT.PortfolioSummary.Properties["industry"].sort((a: any, b: any) => {
      //   return a.price - b.price;
      // }); 
      let regionNo
      if(selectedRegions && selectedRegions.length>0 && (selectedRegions[propertyno]===0 ||selectedRegions[propertyno])){
        regionNo = selectedRegions[propertyno]
         if(!GS.SOT.PortfolioSummary.Properties[medianPropertyType][regionNo]){
            regionNo = 0
          }
          
      }else{ 
       const rengionLength= GS?.SOT.PortfolioSummary.Properties[medianPropertyType].length
        regionNo =  Math.floor(Math.random() * rengionLength);
     
      }
    
      let RiskFactoredYieldReturn = 0;
      let MEDIANRENTPRICE_RentIncome = 0;
      let stateName =
        GS.SOT.PortfolioSummary.Properties[medianPropertyType][regionNo]?.name;

        
      let ResidentialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["residential"][regionNo]
          ?.price *
        0.68 *
        52;
      let ComercialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["office"][regionNo]?.price* 100
      ;
     

     
      let RetailMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["retail"][regionNo]?.price * 100;
      // let IndustryMedianPrice =
      //   GS?.SOT?.PortfolioSummary?.Properties["industry"][regionNo]?.price;
      // let OtherMedianPrice =
      //   GS?.SOT?.PortfolioSummary?.Properties["other"][regionNo]?.price*100;
      if (propertyno === 0) {
        if (ClientInputsC35 !== null) {
          RiskFactoredYieldReturn = parseFloat(
            ClientInputsC35.toString().replace("%", "")
          );
        }
        let tequity = 0; 
        let tcash = 0; 
        let totalcash = 0;
        if (useEquity === "Y") tequity = (equityGain / 100) * percentageEquity;
        if (useCash === "Y") tcash = (cashAvailable / 100) * percentageCash;
        totalcash =
          (useCash === "Y" ? tcash : 0) +
          (useLiquidAssets === "Y" ? liquidAssets : 0) +
          (useExtraCash === "Y" ? extraCash : 0);
          B45 = purchasepr===0? GS.GetPurchasePrice(totalcash + tequity, D7, stateName):purchasepr;
          // console.log('B45 property',propertyno,B45,"stateName",stateName,"regionNo",regionNo,GS?.SOT.PortfolioSummary.Properties[medianPropertyType])
        RiskFactoredYieldReturn = ((ClientInputsC35 * (B45 / 100)) / B45) * 100;
     
        if (RiskFactoredYieldReturn < 5.1) {
          D7 = 80;
        } else {
          D7 = 65;
        }
        if (RiskFactoredYieldReturn < 5.99) {
          MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
        } else if (RiskFactoredYieldReturn < 6.99) {
          MEDIANRENTPRICE_RentIncome = RetailMedianPrice;
        } else {
          MEDIANRENTPRICE_RentIncome = ComercialMedianPrice;
        }
        
      } else {
        let thePurchasePrice = 0;
        if (ClientInputsC35 !== null) {
          RiskFactoredYieldReturn = parseFloat(
            ClientInputsC35.toString().replace("%", "")
          );
        }
        if (RiskFactoredYieldReturn < 5.99) {
          thePurchasePrice = purchasepr===0?
            (ResidentialMedianPrice / RiskFactoredYieldReturn) *100:purchasepr;
          MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice
          //  ResidentialMedianPrice;
        } else if (RiskFactoredYieldReturn < 6.99) {
          thePurchasePrice =purchasepr===0?
          (RetailMedianPrice / RiskFactoredYieldReturn)*100:purchasepr;
          MEDIANRENTPRICE_RentIncome = RetailMedianPrice/100
          //  RetailMedianPrice / 100;
        } else {
          thePurchasePrice = purchasepr===0?
          // ComercialMedianSalePrice / RiskFactoredYieldReturn
           (ComercialMedianPrice / RiskFactoredYieldReturn)*100:purchasepr;
          MEDIANRENTPRICE_RentIncome = ComercialMedianPrice/100
         
          //  ComercialMedianPrice / 100;
        }
        
        B45 = thePurchasePrice;
      }
      // console.log(B45,"B45","property ",propertyno)
      if (RiskFactoredYieldReturn <= 5) {
        propertytype = "Residential";
      } else if (RiskFactoredYieldReturn > 5 && RiskFactoredYieldReturn <= 6) {
        propertytype = "Commercial Retail";
      } else if (RiskFactoredYieldReturn > 6 && RiskFactoredYieldReturn <= 8) {
        propertytype = "Commercial Office";
      } else {
        propertytype = "Commercial";
      }

      // C45 = B45;

      A46 = A12;

      B46 = B45 / 5;

      B47 = B45 * 0.055;
      B48 = B45 * 0.0045;

      B49 = GS.SOT.ai_matrix.BuildingReport;

      // F49 = F48 / A7;

      B50 = (B45 - B46) * 0.0075;

      B51 = B46 + B47 + B48 + B49 + B50;

      F6 = F5 - B51;

      C51 = F5 - F6;

      F13 = ((B51 - F5) / B3) * 100; 

      G3 =
        F13 > 0
          ? `You still need  ${
              isFinite(round_up(F13, 2)) ? round_up(F13, 2) + "%" : "more cash"
            } from your cash amount!`
          : I5 === "N" && I9 === "N"
          ? "The equity can cover the initial amount!"
          : "Cash covered!";

      B52 = B47 + B47 + B48 + B49 + B50 + B45;

      B53 = B9 * (B45 / 100);

      B54 = (B53 / B45) * 100;

      B55 = (B53 / B52) * 100;

      B57 = B51;

      B58 = ((B45 - B46 + B4) / 100) * A58;

      B59 = B53 - B58;

      C59 = B59;

      B60 = B59 / 12;

      B61 = (B59 / B51) * 100;

      B62 = (((B45 / 100) * (100 + A62) - B45 + B59) / B57) * 100;

      B63 = (((B45 / 100) * (100 + A63) - B45 + B59) / B57) * 100;

      B64 = (((B45 / 100) * (100 + A64) - B45 + B59) / B57) * 100;

      let region;
      A7 = RiskFactoredYieldReturn < 5.1 ? 0.8 : 0.65;
     
       

      const stampDutyRate = GS.stampDutyRate(stateName, B45);
      StampDuty = stampDutyRate;  


     
PurchasePrice = B45;
TotalDebt = B45 * A7; //B45 * A7
AcquisitionCost = B45 * 0.025;
Deposit = B45 - TotalDebt;
TotalDepositRequired = Math.floor(Deposit + StampDuty + AcquisitionCost);
      
      LoantoValueRatioLVR = A7;
      let slider = RiskFactoredYieldReturn < 5.1 ? 80 : 65;
      TotalPurchaseCosts = PurchasePrice + StampDuty + AcquisitionCost;

      // AppliedInterestRate = AppliedInterestRate || 5; 
      if (propertyno == 0) {
        PropertyRentIncomeYear1 = B53;
        TotalDepositEquity =
        (equityGain * percentageEquity) / 100 > TotalDepositRequired
          ? TotalDepositRequired
          : (equityGain * percentageEquity) / 100;
          TotalDepositCash = TotalDepositRequired - TotalDepositEquity;
      }  else {
        TotalDepositEquity = 
        (equityAvailable * percentageEquity) / 100 > TotalDepositRequired
          ? TotalDepositRequired
          : (equityAvailable * percentageEquity) / 100;
        PropertyRentIncomeYear1 = Number(PurchasePrice)*(RiskFactoredYieldReturn/100)   //MEDIANRENTPRICE_RentIncome;
        TotalDepositCash = (cashAvailable * percentageCash) / 100; 
        // console.log(propertyno,"PropertyRentIncomeYear1",PropertyRentIncomeYear1,"PurchasePRice",PurchasePrice)
      }    
    
      // TotalCostofLending =
      //   TotalDebt * (DebtInterestRate / 100) + TotalDepositEquity * (EquityInterestRate / 100);

      TotalCostofLending =(TotalDebt  + TotalDepositEquity)* (interestRate/100);

      // (TotalDebt + TotalDepositEquity) * (AppliedInterestRate / 100);
      CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending;
      CashflowMonthly = CashflowAnnual / 12;
      ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100;
      AppliedGrowthRateannualPERC = annualGrowth;
      EquityGainPERCorDOLLAR = equityGainGrowth;
      CashGainDOLLAR = cashGainGrowth;
      LMI = 0; 
     
      let growthRate=GS?.SOT?.PortfolioSummary?.Properties[medianPropertyType][regionNo]?.growth_percent;
      if(growthRate){
        growthRate=growthRate.slice(0,growthRate.length-2)
        
      }
        region =
          GS?.SOT?.PortfolioSummary?.Properties[medianPropertyType][regionNo]
            ?.region ;

            let newMaxBorrowCapacity=0
            let currentmbc=0
            let priceReduce:any=false

          
            let InterestPayments=props?.Data?.summaryData?.InterestPayments || 0
            let PrinciplePayments=props?.Data?.summaryData?.PrinciplePayments || 0
            let NetYield=Number(PurchasePrice)*Number(RiskFactoredYieldReturn/100)


            // console.log(propertytype,"propno",propertyno)

    // console.log(RiskFactoredYieldReturn,"prop",propertyno)
            //new formulas && props.Data.isLocked==false
            if(propertyno==0  ){
              let newPurchasePrice:any=0
              let totaldebt:number=0 
            
              let totalAcquistonCost=0
              let lvr= 65
              let acquistonCost=6.5
              let riskfactor= RiskFactoredYieldReturn || 6
              // console.log(RiskFactoredYieldReturn,"RiskFactoredYieldReturn")
              let newInterestDebtRate=DebtInterestRate
              let newInterestEquityRate=EquityInterestRate
              let homeloanRepayment=GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsFinalValue || 0
              let propertyLoanRepayment=0
              let totalpropertydebt=GS?.SOT?.Equity?.PPR?.PPRMortgage?.value || 0  ;
              let totaldebtrepayments=0;
              let totalestimatedoutgoings=0
              let propertyGrossIncome=0;
    
              if(GS?.SOT?.Equity?.properties?.length>0){
                propertyLoanRepayment=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
                  return total+item.LoanRepaymentsFinalValue
                  },0)
                  let propertymortgage=0;
                  propertymortgage=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
                    return total+item.IPMortage
                    },0)
                    totaldebtrepayments=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
                      return total+item.LoanRepaymentsFinalValue
                      },0) 
                      totalestimatedoutgoings=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
                        return total+item.EstimatedOutgoings
                        },0) 
                        propertyGrossIncome=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
                          return total+Number(item.RentalReceived.value)
                               
                          },0)
                      totaldebtrepayments=totaldebtrepayments+GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsFinalValue
                    totalpropertydebt=totalpropertydebt+propertymortgage
              }
              
            let yourSalary= getTaxIncome(GS?.SOT?.BorrowingCalculator?.salary_after_tax)
            let partnerSalary= getTaxIncome(GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax)
            let sum =  (yourSalary + partnerSalary + GS?.SOT?.BorrowingCalculator?.other_income_net+ propertyGrossIncome )|| 0; 
      
      
            let totalFinanceRepayment= (homeloanRepayment+propertyLoanRepayment) || 0  
      let totalLiabilities=GS?.SOT?.StrategicSpending?.totalliabilities || 0
      let OtherLoanPayments= GS?.SOT?.BorrowingCalculator?.CreditCards?.reduce(
        (partialSum:any,nextItem:any)=>
        nextItem?.type !== "Credit Card"  ?
        partialSum+nextItem.minimumPayment:partialSum,
        0
      )*12 || 0
      let total_card_limit =GS?.SOT?.BorrowingCalculator?.CreditCards?.reduce(
        (partialSum:any, nextItem:any) =>
          nextItem?.type === "Credit Card"
            ? partialSum + nextItem.limit
            : partialSum,
        0
      );
      let totalCreditCardLimits= total_card_limit || 0  
      let totalLivingExpense=GS?.SOT?.BorrowingCalculator?.annual_expenses_2 || 0    

            let dti=props?.DTI || 6
              const totalLiabilitiesAndExpenses=(totalFinanceRepayment+OtherLoanPayments+totalCreditCardLimits+totalLivingExpense+totalpropertydebt+totalestimatedoutgoings) || 0
             let maxborrow:any= (sum*dti)-(totalLiabilitiesAndExpenses) || GS?.SOT?.BorrowingCalculator?.can_borrow1 || 0
           
             currentmbc=maxborrow
      let RequiredDSR=props?.DSR || 40
      let NewLoanTerm=5
     
      if(propertytype == "Residential"){
        totaldebt=(maxborrow*0.2)/(80/100)
      
      }else{
        totaldebt=(maxborrow*0.35)/(65/100)
      }
      // console.log(totalLiabilitiesAndExpenses,"totalLiabilitiesAndExpenses",totaldebt)
      newPurchasePrice=purchasepr===0? (totaldebt+maxborrow).toFixed(2) || 0:purchasepr
      B45=newPurchasePrice
   
      let newTotalPurchasePrice:any=0
      let checkdeposit=false
      totalAcquistonCost=newPurchasePrice*(acquistonCost/100) || 0
      let totalLoanAmountPrincipal=maxborrow || 0
      let totalDepositAndAcquiston=totalAcquistonCost+totaldebt || 0
      let newPropertyIncome=Math.round(newPurchasePrice*(riskfactor/100)) || 0
      let newAnnualRepaymentDebt=Math.round(maxborrow*(newInterestDebtRate/100)) || 0
      let newAnnualRepaymentEquity=Math.round(totaldebt*(newInterestEquityRate/100)) || 0
      let currentDSRpart1=totalLivingExpense+(newAnnualRepaymentDebt)+(newAnnualRepaymentEquity)+totalFinanceRepayment+OtherLoanPayments+totalCreditCardLimits || 0
      let currentDSRpart2=sum+(newPropertyIncome) || 0
      let currentDSR=((currentDSRpart1)/(currentDSRpart2))*100 || 0
      
      let newGrosIncome=Math.round(((totalFinanceRepayment+totalCreditCardLimits+newAnnualRepaymentDebt+newAnnualRepaymentEquity+OtherLoanPayments)/12)/(currentDSR/100)) || 0
      // console.log(totalFinanceRepayment," ",totalCreditCardLimits," ",newAnnualRepaymentDebt," ",newAnnualRepaymentEquity," ",OtherLoanPayments," ",totalFinanceRepayment+totalCreditCardLimits+newAnnualRepaymentDebt+newAnnualRepaymentEquity+OtherLoanPayments)
      let targetDebtRepayment=Math.round(newGrosIncome*(RequiredDSR/100)) || 0
      let DecreaseInTotalPayment= 0  
      if(currentDSR>40){
        DecreaseInTotalPayment=newGrosIncome-targetDebtRepayment || 0
       
      }  
       
      let DecreaseinMBCpart2=((NewLoanTerm/(1-Math.pow((1+0.07),-NewLoanTerm)))) || 0
      let DecreaseInMBC=Math.round(DecreaseInTotalPayment * DecreaseinMBCpart2) || 0
       newMaxBorrowCapacity= maxborrow //maxborrow-DecreaseInMBC || 0
      // console.log(newMaxBorrowCapacity,"newMaxBorrow",maxborrow)
      let newTotalAmountPrincipal=  newMaxBorrowCapacity || 0
      // let newTotalDebt=(newTotalAmountPrincipal*(1-(lvr/100)))/(lvr/100)|| 0
      let newTotalDebt=0
      if(propertytype == "Residential"){
        newTotalDebt=(newMaxBorrowCapacity*0.2)/(80/100)
      
      }else{
        newTotalDebt=(newMaxBorrowCapacity*0.35)/(65/100)
      }
        newTotalPurchasePrice=purchasepr===0? (Number((newTotalAmountPrincipal+newTotalDebt)).toFixed(2))|| 0 :purchasepr
        let newTotalAcquistonCost=newTotalPurchasePrice*0.025|| 0
        let newTotalDebtAndAcquistonCost=newTotalDebt+newTotalAcquistonCost|| 0
        let newpropertyIncome=newTotalPurchasePrice*(riskfactor/100)|| 0
        let newannualRepaymentDebt=newTotalAmountPrincipal*(newInterestDebtRate/100)|| 0
        let newannualRepaymentEquity=newTotalDebt*(newInterestEquityRate/100)|| 0
        let newIncomeAfterInterest=newpropertyIncome-newannualRepaymentDebt-newannualRepaymentEquity|| 0
        let newpropertyYield=newpropertyIncome/newTotalPurchasePrice || 0
        targetYieldReturn=(newpropertyYield*100).toFixed(2)
        // console.log(
        //   "maxborrow",maxborrow,"\n",
        //   "RequiredDSR",RequiredDSR,"\n",
        //   "totalFinanceRepayment",totalFinanceRepayment,"\n",
        //   "totalCreditCardLimits",totalCreditCardLimits,"\n",
        //   "totalLivingExpense",totalLivingExpense,"\n",
        //   "OtherLoanPayments",OtherLoanPayments,"\n",
        //   "newPurchasePrice", newPurchasePrice,"\n",
        //   "totaldebt",totaldebt,"\n",
        //   "totalAcquistonCost", totalAcquistonCost,"\n",
        //   "totalDepositAndAcquiston",totalDepositAndAcquiston,"\n",
        //   "propertyno", propertyno,"\n",
        //   "newPropertyIncome",newPropertyIncome,"\n",
        //   "newAnnualRepaymentDebt", newAnnualRepaymentDebt,"\n",
        //   "newAnnualRepaymentEquity",newAnnualRepaymentEquity,"\n",
        //   "currentDSRpart1",currentDSRpart1,"\n",
        //   "currentDSRpart2",currentDSRpart2,"\n",
        //   "currentDSR",currentDSR,"\n",
        //   "newGrosIncome",newGrosIncome,"\n",


        //   "targetDebtRepayment",targetDebtRepayment,"\n",
        //   "DecreaseInTotalPayment",DecreaseInTotalPayment,"\n",
        //   "DecreaseInMBC",DecreaseInMBC,"\n",
        //   "newMaxBorrowCapacity",newMaxBorrowCapacity,"\n",
        //   "newTotalDebt",newTotalDebt,"\n",
        //   "newTotalPurchasePrice",newTotalPurchasePrice,"\n",
        //   "newTotalAcquistonCost",newTotalAcquistonCost,"\n",
        //   "newTotalDebtAndAcquistonCost",newTotalDebtAndAcquistonCost,"\n",
        //   "newpropertyIncome",newpropertyIncome,"\n",
        //   "newannualRepaymentDebt",newannualRepaymentDebt,"\n",
        //   "newannualRepaymentEquity",newannualRepaymentEquity,"\n",
        //   "newIncomeAfterInterest",newIncomeAfterInterest,"\n",
        //   "newpropertyYield",newpropertyYield
        // )
      
          

    
         //here changes
          let cash=cashAvailable || 0
          let domainAutoVal=GS?.SOT?.Equity?.PPR?.DomainAutoVal || 0
          let totalEquity=GS?.SOT?.Equity?.TequityGain||0
          let equity=totalEquity || 0
          StampDuty=GS.stampDutyRate(stateName, newTotalPurchasePrice)
          Deposit=totaldebt
          TotalDepositRequired=Deposit+newTotalAcquistonCost+StampDuty
           
 
          newTotalAcquistonCost=newTotalPurchasePrice*0.025 || 0
          newpropertyIncome=newTotalPurchasePrice*(riskfactor/100) || 0
           checkdeposit=(cash+equityGain)>(TotalDepositRequired)  //cash+equity
          let check=checkdeposit?"deposit covered":"more cash required"
          let totaldepositrequired=TotalDepositRequired || 0
          let cashandequityavailable=0 
          // console.log(
          // TotalDepositRequired,"TotalDepositRequired",
          // cash+equityGain,"newTotalPurchasePrice",
          // newTotalPurchasePrice,"Deposit",Deposit,
          // check,"check"
          // )
        priceReduce=false;
            while(checkdeposit==false){ 
            priceReduce=true
            newTotalPurchasePrice=newTotalPurchasePrice-1 || 0;
            StampDuty=GS.stampDutyRate(stateName, newTotalPurchasePrice)
            let debt=(newTotalPurchasePrice*(lvr/100)) || 0
             Deposit=newTotalPurchasePrice-debt
              totalEquity=cash+equityGain||0
              cashandequityavailable=totalEquity
            newTotalAcquistonCost=newTotalPurchasePrice*0.025 
            newpropertyIncome=newTotalPurchasePrice*(riskfactor/100)
             checkdeposit=(totalEquity)>(Deposit+newTotalAcquistonCost+StampDuty)
             check=checkdeposit?"deposit covered":"more cash required"
             totaldepositrequired=Deposit+newTotalAcquistonCost+StampDuty
         }
        

          
          PurchasePrice =purchasepr===0? Number(newTotalPurchasePrice) || 0:purchasepr;
          console.log(purchasepr,"purchasepr3",PurchasePrice,propertyno)
          B45=PurchasePrice
         TotalDebt= PurchasePrice*((65/100)) || 0  //newTotalDebt
         Deposit=PurchasePrice-TotalDebt
          const stampDutyRate = GS.stampDutyRate(stateName, PurchasePrice) || 0;
          StampDuty=stampDutyRate|| 0
          newTotalAcquistonCost=PurchasePrice*(acquistonCost/100)
          AcquisitionCost=newTotalAcquistonCost|| 0
          TotalDepositRequired=Deposit+StampDuty+AcquisitionCost|| 0  //+1000000
          TotalPurchaseCosts=(Number(PurchasePrice)+Number(StampDuty)+Number(AcquisitionCost))|| 0
          LoantoValueRatioLVR=65/100|| 0 
          // console.log('EquityGain',equityGain) 
          TotalDepositEquity = 
        (equityGain * percentageEquity) / 100 > TotalDepositRequired 
          ? TotalDepositRequired
          : (equityGain * percentageEquity) / 100;
          
          TotalDepositCash = TotalDepositRequired - TotalDepositEquity;
           
          // console.log(TotalDepositCash, cashAvailable,TotalDepositCash/cashAvailable,(cash+equityGain)==totaldepositrequired,"check1",((cash+equityGain)>totaldepositrequired)&& (equityGain<totaldepositrequired),"check2",equityGain==totaldepositrequired,"check3")
          if((cash+equityGain)==totaldepositrequired){
           useCash='Y'
           useEquity='Y'
           percentageEquity=100
           percentageCash=100
          //  console.log("here1")
          }else if(((cash+equityGain)>totaldepositrequired)&& (equityGain<totaldepositrequired)){
             useEquity='Y'
             percentageEquity=(TotalDepositEquity/equityGain)*100
             useCash='Y'
             percentageCash=(TotalDepositCash/cashAvailable)*100
            //  console.log(percentageCash,"percentageCash")
             if(percentageCash==0){
              useCash='N'
             }
            
          }else {
           useEquity='Y'
             percentageEquity=(TotalDepositEquity/equityGain)*100
             useCash='N'
             percentageCash=0
            //  console.log("here3")
          }
          useLiquidAssets='N'
          
          useExtraCash='N'
          newpropertyIncome=PurchasePrice*(riskfactor/100)|| 0
          PropertyRentIncomeYear1=newpropertyIncome|| 0
         
          // TotalCostofLending=(TotalDebt * ((DebtInterestRate) / 100)+ TotalDepositEquity * (EquityInterestRate / 100))*(interestRate/100)|| 0
          TotalCostofLending=(TotalDebt + TotalDepositEquity)*(interestRate/100)|| 0
          CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending|| 0;
          CashflowMonthly = CashflowAnnual / 12|| 0;
          ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100|| 0;
          // AppliedGrowthRateannualPERC = annualGrowth|| 0;
          EquityGainPERCorDOLLAR = equityGainGrowth|| 0;
          CashGainDOLLAR = cashGainGrowth|| 0;
          LMI = 0;
          NetYield=Number(PurchasePrice)*(Number(riskfactor)/100)
          // if(MEDIANRENTPRICE_RentIncome<PurchasePrice){
          //   region="" 
          // }
          GS.SOT.BorrowingCalculator.can_borrow1=newMaxBorrowCapacity || 0
         if(PurchasePrice==0){
          CashflowAnnual=0
          CashflowMonthly=0
          ReturnonEquityCOC=0
         }
            }
            
            else if((propertyno==1) || (propertyno==2) || (propertyno==3)){
              let newPurchasePrice:any=0
              let totaldebt:number=0
             
              priceReduce=false 
              let totalAcquistonCost=0
              let lvr=65
             
              let riskfactor= RiskFactoredYieldReturn || 6
              
              let newInterestDebtRate=DebtInterestRate
              let newInterestEquityRate=EquityInterestRate
              let homeloanRepayment=GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsFinalValue || 0
              let propertyLoanRepayment=0
              let propertyGrossIncome=0;
              if(GS?.SOT?.Equity?.properties?.length>0){
                propertyLoanRepayment=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
                  return total+item.LoanRepaymentsFinalValue
                  },0)
                  propertyGrossIncome=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
                    return total+Number(item.RentalReceived.value)
                         
                    },0)
              }
             
            
              let totalFinanceRepayment= (homeloanRepayment+propertyLoanRepayment) || 0   //129550
              let totalLiabilities=GS?.SOT?.StrategicSpending?.totalliabilities || 0
              let OtherLoanPayments= GS?.SOT?.BorrowingCalculator?.CreditCards?.reduce(
                (partialSum:any,nextItem:any)=>
                nextItem?.type !== "Credit Card"  ?
                partialSum+nextItem.minimumPayment:partialSum,
                0
              )*12 || 0
              let total_card_limit =GS?.SOT?.BorrowingCalculator?.CreditCards?.reduce(
                (partialSum:any, nextItem:any) =>
                  nextItem?.type === "Credit Card"
                    ? partialSum + nextItem.limit
                    : partialSum,
                0
              );
              let totalCreditCardLimits= total_card_limit || 0  //12000
              let totalLivingExpense=GS?.SOT?.BorrowingCalculator?.annual_expenses_2 || 0    //74760 GS?.SOT?.StrategicSpending?.expenses
            const totalLiabilitiesAndExpenses=((totalLivingExpense)+OtherLoanPayments+totalFinanceRepayment+totalCreditCardLimits) || 0
             let propertyCashflowAnnual:any=0.0 
             let index=propertyno
             if(propertyno==1){
          
              propertyCashflowAnnual=(GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual)  //(GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual)
              if(propertyCashflowAnnual<0){
                propertyCashflowAnnual=0
              }
             }else if(propertyno==2){
              propertyCashflowAnnual=(GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual)+(GS?.SOT?.PropertyGenerators[1]?.summaryData?.CashflowAnnual)
              if(propertyCashflowAnnual<0){
                propertyCashflowAnnual=0 
              } 
              
            }else if(propertyno==3){
              propertyCashflowAnnual=(GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual)+(GS?.SOT?.PropertyGenerators[1]?.summaryData?.CashflowAnnual)+(GS?.SOT?.PropertyGenerators[2]?.summaryData?.CashflowAnnual)
              if(propertyCashflowAnnual<0){
                propertyCashflowAnnual=0
              }
            }else{
              propertyCashflowAnnual=(GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual)+(GS?.SOT?.PropertyGenerators[1]?.summaryData?.CashflowAnnual)+(GS?.SOT?.PropertyGenerators[2]?.summaryData?.CashflowAnnual)+(GS?.SOT?.PropertyGenerators[3]?.summaryData?.CashflowAnnual)
              if(propertyCashflowAnnual<0){
                propertyCashflowAnnual=0
              }
            }
            //  while(index>0){
             
            //   if(GS?.SOT?.PropertyGenerators[index-1]?.summaryData?.CashflowAnnual){
            //     let cashflowincome=(GS?.SOT?.PropertyGenerators[index-1].summaryData.CashflowAnnual)
            //     propertyCashflowAnnual=propertyCashflowAnnual+cashflowincome
              
            //   } 
            //   index--;
            //  }
          
             let sum =  Math.round( GS?.SOT?.BorrowingCalculator?.salary_after_tax + GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax + GS?.SOT?.BorrowingCalculator?.other_income_net+propertyCashflowAnnual+propertyGrossIncome);
            //  console.log(
            //   GS?.SOT?.BorrowingCalculator?.salary_after_tax,"\n",
            //   GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax,"\n",
            //   GS?.SOT?.BorrowingCalculator?.other_income_net,"\n",
            //   propertyCashflowAnnual,"\n",
            //   GS?.SOT?.BorrowingCalculator?.NetRentalIncome,"\n",
            //  )
             let dti=props?.DTI || 6
             let maxborrow= (sum* dti)-(totalLiabilitiesAndExpenses)  || 0
            //  console.log("maxborrow",maxborrow,"propertyno",propertyno)
             currentmbc=maxborrow
            // console.log(propertyno,"propertyno",maxborrow,"sum",sum,"totalLiabilitiesAndExpenses",totalLiabilitiesAndExpenses)
             let RequiredDSR=props?.DSR || 40
             let NewLoanTerm=5
             if(propertytype == "Residential"){
              totaldebt=(maxborrow*0.6)/(lvr/100) //0/2
            
            }else{
              totaldebt=(maxborrow*0.4)/(lvr/100)
            }
            newPurchasePrice=purchasepr===0? (totaldebt+maxborrow).toFixed(2):purchasepr
            B45=newPurchasePrice
            let newTotalPurchasePrice:any=0
            let checkdeposit=false
            totalAcquistonCost=newPurchasePrice*0.025
            let totalLoanAmountPrincipal=maxborrow
            let totalDepositAndAcquiston=totalAcquistonCost+totaldebt 
            let newPropertyIncome=Math.round(newPurchasePrice*(riskfactor/100))
            let newAnnualRepaymentDebt=Math.round(maxborrow*(newInterestDebtRate/100))
            let newAnnualRepaymentEquity=Math.round(totalDepositAndAcquiston*(newInterestEquityRate/100))
            let currentDSRpart1=(newAnnualRepaymentDebt)+(newAnnualRepaymentEquity)+totalFinanceRepayment+OtherLoanPayments+totalCreditCardLimits
            let currentDSRpart2=(sum)+(newPropertyIncome)
            let currentDSR=((currentDSRpart1)/(currentDSRpart2))*100 || 0
            let newGrosIncome=Math.round(((totalFinanceRepayment+totalLivingExpense+totalCreditCardLimits+newAnnualRepaymentDebt+newAnnualRepaymentEquity)/12)/(currentDSR/100)) || 0
            let targetDebtRepayment=Math.round(newGrosIncome*(RequiredDSR/100)) 
            let DecreaseInTotalPayment=newGrosIncome-targetDebtRepayment
            let DecreaseinMBCpart2=((NewLoanTerm/(1-Math.pow((1+0.07),-NewLoanTerm))))
            let DecreaseInMBC=Math.round(DecreaseInTotalPayment * DecreaseinMBCpart2)
             newMaxBorrowCapacity=totalLoanAmountPrincipal-DecreaseInMBC 
             let newTotalAmountPrincipal=newMaxBorrowCapacity 
             let newTotalDebt=(newTotalAmountPrincipal*(1-(lvr/100)))/(lvr/100)
               newTotalPurchasePrice=Number((newTotalAmountPrincipal+newTotalDebt)).toFixed(2)
               let newTotalAcquistonCost=newTotalPurchasePrice*0.025 
               let newTotalDebtAndAcquistonCost=newTotalDebt+newTotalAcquistonCost
               let newpropertyIncome=newTotalPurchasePrice*(riskfactor/100)
               let newannualRepaymentDebt=newTotalAmountPrincipal*(newInterestDebtRate/100)
               let newannualRepaymentEquity=newTotalDebt*(newInterestEquityRate/100)
               let newIncomeAfterInterest=newpropertyIncome-newannualRepaymentDebt-newannualRepaymentEquity
               let newpropertyYield=newpropertyIncome/newTotalPurchasePrice || 0
               targetYieldReturn=(newpropertyYield*100).toFixed(2)
//                console.log(
//                 "GS?.SOT?.BorrowingCalculator?.salary_after_tax",GS?.SOT?.BorrowingCalculator?.salary_after_tax,"\n",
//                 "GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax",GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax,"\n",
//                 "GS?.SOT?.BorrowingCalculator?.other_income_net GS?.SOT?.BorrowingCalculator?.NetRentalIncome",GS?.SOT?.BorrowingCalculator?.other_income_net,"\n",
//                 "propertyCashflowAnnual",propertyCashflowAnnual,"\n",
//                 "GS?.SOT?.BorrowingCalculator?.NetRentalIncome",GS?.SOT?.BorrowingCalculator?.NetRentalIncome,"\n",
//                  "totalnetIncome",sum,"\n",
//                  "totalLiabilitiesAndExpenses",totalLiabilitiesAndExpenses,"\n",
// "riskfactor",riskfactor,"\n",
//                  "maxborrow",maxborrow,"\n",
//                  "RequiredDSR",RequiredDSR,"\n",
//                  "totalFinanceRepayment",totalFinanceRepayment,"\n",
//                  "totalCreditCardLimits",totalCreditCardLimits,"\n",
//                  "totalLivingExpense",totalLivingExpense,"\n",
//                  "OtherLoanPayments",OtherLoanPayments,"\n",
//                  "newPurchasePrice", newPurchasePrice,"\n",
//                  "totaldebt",totaldebt,"\n",
//                  "totalAcquistonCost", totalAcquistonCost,"\n",
//                  "totalDepositAndAcquiston",totalDepositAndAcquiston,"\n",
//                  "propertyno", propertyno,"\n",
//                  "propertyType",propertytype,"\n",
//                  "newPropertyIncome",newPropertyIncome,"\n",
//                  "newAnnualRepaymentDebt", newAnnualRepaymentDebt,"\n",
//                  "newAnnualRepaymentEquity",newAnnualRepaymentEquity,"\n",
//                  "currentDSRpart1",currentDSRpart1,"\n",
//                  "currentDSRpart2",currentDSRpart2,"\n",
//                  "currentDSR",currentDSR,"\n",
//                  "newGrosIncome",newGrosIncome,"\n",
//                  "targetDebtRepayment",targetDebtRepayment,"\n",
//                  "DecreaseInTotalPayment",DecreaseInTotalPayment,"\n",
//                  "DecreaseInMBC",DecreaseInMBC,"\n",
//                  "newMaxBorrowCapacity",newMaxBorrowCapacity,"\n",
//                  "newTotalDebt",newTotalDebt,"\n",
//                  "newTotalPurchasePrice",newTotalPurchasePrice,"\n",
//                  "newTotalAcquistonCost",newTotalAcquistonCost,"\n",
//                  "newTotalDebtAndAcquistonCost",newTotalDebtAndAcquistonCost,"\n",
//                  "newpropertyIncome",newpropertyIncome,"\n",
//                  "newannualRepaymentDebt",newannualRepaymentDebt,"\n",
//                  "newannualRepaymentEquity",newannualRepaymentEquity,"\n",
//                  "newIncomeAfterInterest",newIncomeAfterInterest,"\n",
//                  "newpropertyYield",newpropertyYield,"\n",
//                  "propertyno",propertyno,"\n"
//                )
               let G50=newTotalPurchasePrice-(newTotalPurchasePrice*(lvr/100))
               let debt=(newTotalPurchasePrice*(lvr/100))
               let cash=cashAvailable
               let totalEquity=GS?.SOT?.Equity?.TequityGain||0
              
               let equitydeposit=(G50)-cash 
               StampDuty=GS.stampDutyRate(stateName, newTotalPurchasePrice)
               newTotalAcquistonCost=newTotalPurchasePrice*0.025
               TotalDepositRequired=newTotalDebt+newTotalAcquistonCost+StampDuty
               newpropertyIncome=newTotalPurchasePrice*(riskfactor/100)
                checkdeposit=(cash+equityGain)>(TotalDepositRequired)
               let check=checkdeposit?"deposit covered":"more cash required"
               let totaldepositrequired=TotalDepositRequired 
                priceReduce=false
                let cashandequityavailable=0 
              
             
           
                 PurchasePrice =purchasepr===0? newTotalPurchasePrice:purchasepr;
                 B45=PurchasePrice
                 debt=(PurchasePrice*(lvr/100))
          TotalDebt= debt //newTotalDebt
          Deposit=PurchasePrice-TotalDebt
          const stampDutyRate = GS.stampDutyRate(stateName, PurchasePrice);
          StampDuty=stampDutyRate
          newTotalAcquistonCost=PurchasePrice*0.025
          AcquisitionCost=newTotalAcquistonCost
          TotalDepositRequired=Deposit+AcquisitionCost+StampDuty
          
        
          TotalPurchaseCosts=(Number(PurchasePrice)+Number(StampDuty)+Number(AcquisitionCost))
          LoantoValueRatioLVR=65/100
          // TotalDepositEquity=equitydeposit
          // TotalDepositCash=cash 
           
          TotalDepositEquity =
          (equityAvailable * percentageEquity) / 100 > TotalDepositRequired
            ? TotalDepositRequired
            : (equityAvailable * percentageEquity) / 100;
            let LeftAfterEquity=TotalDepositRequired - TotalDepositEquity;

            TotalDepositCash =(cashAvailable*percentageCash)/100> LeftAfterEquity
                              ?LeftAfterEquity:(cashAvailable*percentageCash)/100   //TotalDepositRequired - TotalDepositEquity;
            // console.log((equityAvailable * percentageEquity)/100,TotalDepositRequired,equityAvailable ,percentageEquity,"propsdasd",propertyno,TotalDepositCash,cashAvailable)
           
          percentageEquity=(TotalDepositEquity/equityAvailable)*100
          percentageCash=(TotalDepositCash/cashAvailable)*100
          if(percentageEquity>0){
            useEquity='Y'
          }else{
            useEquity='N'
          }
          if(percentageCash>0){
            useCash='Y'
          }else{
            useCash='N'
          }
    
           useLiquidAssets='N'
          useExtraCash='N'
          PropertyRentIncomeYear1=newpropertyIncome
          TotalCostofLending=(TotalDebt + TotalDepositEquity )*(interestRate/100)
          CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending;
          CashflowMonthly = CashflowAnnual / 12;
          ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100;
          // AppliedGrowthRateannualPERC = annualGrowth; 
          EquityGainPERCorDOLLAR = equityGainGrowth;
          CashGainDOLLAR = cashGainGrowth;
          LMI = 0;
         
          // if(MEDIANRENTPRICE_RentIncome<PurchasePrice){
          //   region=""
          // }
         
          GS.SOT.BorrowingCalculator.can_borrow1=newMaxBorrowCapacity
          NetYield=Number(PurchasePrice)*(Number(riskfactor)/100)
            }
   
     if(annualGrowth==0){
      growthRate=Number(growthRate).toFixed(2)
      AppliedGrowthRateannualPERC=growthRate
      EquityGainPERCorDOLLAR=(PurchasePrice/100)*growthRate
     }else{
      growthRate=Number(annualGrowth).toFixed(2)
      AppliedGrowthRateannualPERC=growthRate
      EquityGainPERCorDOLLAR=(PurchasePrice/100)*growthRate
     }
     
     if(PurchasePrice<0){ 
      EquityGainPERCorDOLLAR=0
      CashflowAnnual=0
      CashflowMonthly=0
      ReturnonEquityCOC=0

     }
    //  console.log(annualGrowth,"annualGrowth")

    //  console.log(
    //   "annualGrowth",annualGrowth,
    //   growthRate,"growthRate","\n",
   
    //   AppliedGrowthRateannualPERC,"AppliedGrowthRateannualPERC","\n",
    //   equityGainGrowth,"equityGainGrowth",
    //   propertyno,"propertyno"
    //   )
 
      // console.log(props,"props",propertyno)
      liquidAssets=0
      extraCash=0
      // console.log(B45,"purchasepr",propertyno,PurchasePrice)
      return { 
        liquidAssets: liquidAssets,
        percentageLiquidAssets:0,
        netIncome: netIncome,
        extraCash: extraCash,
        cashAvailable: cashAvailable,
        useEquity: useEquity,
        equityGain: equityGain, 
        equityAvailable: equityAvailable,
        percentageEquity: percentageEquity,
        totalAvailableFunds: totalAvailableFunds,
        useCash: useCash,
        slider1: slider,
        percentageCash: percentageCash,
        totalAcquisitionlabel: totalAcquisitionlabel,
        useLiquidAssets: useLiquidAssets, 
        totalAcquisition: totalAcquisition,
        targetYieldReturn: targetYieldReturn,
        initialPropertyType: initialPropertyType,
        useExtraCash: useExtraCash,
        slider2: slider2,
        totalAssetsRequiredLeveragedLabel: totalAssetsRequiredLeveragedLabel,
        totalAssetsRequiredLeveraged: totalAssetsRequiredLeveraged,
        cashRequiredLeveragedLabel: cashRequiredLeveragedLabel,
        cashRequiredLeveraged: cashRequiredLeveraged,
        methodforPurchasePrice: methodforPurchasePrice,
        nextDate: props?.Data?.nextDate,
        propertyType: propertytype,
        medianPropertyType: medianPropertyType,
        hidden: false,
        isLocked:props?.Data?.isLocked||  false,
        isRequird:true,
        newmbc:newMaxBorrowCapacity,
        currentmbc:currentmbc,
        priceReduce:priceReduce===true?"Y":"N",  //priceReduce==true?"Y":"N"
        tableData: {
          PurchasePrice: B45,
          Depositassumingleverage20: B46,
          StampDuty: B47,
          SolicitorValuation: B48,
          BuildingReport: B49,
          LoanArrangementFee: B50,
          TotalCashRequired: B51,
          TotalPurchaseCosts: B52,
          NetAnnualCashflowReturnYear1: B53,
          NetYieldonPropertyYear1: B54,
          NetYieldonTotalPurchaseCostsYear1: B55,
          CashonCashReturn: B56,
          TotalCashRequired20Costs: B57,
          CostofLoan5paonleverage: B58,
          Cashflowaftermortagecosts: B59,
          CashflowaftermortgageMonthly: B60,
          ReturnonEquitypurecashflowreturn: B61,
          ReturnonEquitywitha5capitalgrowthrate: B62,
          ReturnonEquitywitha7capitalgrowthrate: B63,
          ReturnonEquitywitha10capitalgrowthrate: B64,
        },
        summaryData: {
          propertyType: propertytype,
          interestRate:Number(interestRate),
          region: region,
          PurchasePrice: PurchasePrice>0?PurchasePrice:0,
          Deposit: Deposit>0?Deposit:0,
          StampDuty: StampDuty>0?StampDuty:0,
          TotalDepositRequired: TotalDepositRequired>0?TotalDepositRequired:0,
          AcquisitionCost: AcquisitionCost>0?AcquisitionCost:0,
          LMI: LMI,
          TotalPurchaseCosts: TotalPurchaseCosts>0?TotalPurchaseCosts:0,
          LoantoValueRatioLVR: LoantoValueRatioLVR,
          TotalDebt: TotalDebt>0?TotalDebt:0,
          TotalDepositEquity: TotalDepositEquity>0?TotalDepositEquity:0,
          TotalDepositCash: TotalDepositCash>0?TotalDepositCash:0,
          // AppliedInterestRate: AppliedInterestRate,
          regionNo:regionNo,
          EquityInterestRate: EquityInterestRate, //1
          DebtInterestRate: DebtInterestRate, //4
          PropertyRentIncomeYear1: PropertyRentIncomeYear1>0?PropertyRentIncomeYear1:0,
          TotalCostofLending: TotalCostofLending>0?TotalCostofLending:0,
          CashflowAnnual: CashflowAnnual,
          CashflowMonthly:CashflowMonthly,
          ReturnonEquityCOC: ReturnonEquityCOC,
          AppliedGrowthRateannualPERC: AppliedGrowthRateannualPERC,
          EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
          CashGainDOLLAR: CashGainDOLLAR,
          RiskFactoredYieldReturn:RiskFactoredYieldReturn,
          DebtPrincipleRate:DebtPrincipleRate || 0,
          EquityPrincipleRate:EquityPrincipleRate || 0,
          InterestPayments:InterestPayments, 
          PrinciplePayments:PrinciplePayments,
          DSR:props.DSR,
          DTI:props.DTI,
          NetYield:NetYield,
          outgoingExpenses:PropertyRentIncomeYear1>0?PropertyRentIncomeYear1*0.325:0,
          outgoingExpensePercent:0.325*100
        },
        G3: G3,
        A44: A44,
        F44: F44,
        C44: C44,
        A45: A45,
        B45: B45,
        A46: A46,
        B46: B46,
        B47: B47,
        B48: B48,
        B49: B49,
        B50: B50,
        B51: B51,
        C51: C51,
        B52: B52,
        B53: B53,
        B54: B54,
        B55: B55,
        B56: B56,
        B57: B57,
        A58: A58,
        B58: B58,
        B59: B59,
        C59: C59,
        B60: B60,
        B61: B61,
        A62: A62,
        B62: B62,
        A63: A63,
        B63: B63,
        A64: A64,
        B64: B64,
        F1: F1,
        F2: F2,
        F3: F3,
        F4: F4,
        F5: F5,
        F6: F6,
        F7: F7,
        F8: F8,
      };
    }

    function round_up(value: any, precision: any = 0) {
      const power = Math.pow(10, precision);
      let result = Math.ceil(value * power) / power;

      return result;
    }
   
 
    GS.SOT.PropertyGenerators[propertyno] = {
      liquidAssets: liquidAssets,
      percentageLiquidAssets:0,
      netIncome: netIncome,
      extraCash: extraCash,
      cashAvailable: cashAvailable,
      useEquity: useEquity,
      equityAvailable: equityAvailable,
      percentageEquity: percentageEquity,
      totalAvailableFunds: totalAvailableFunds,
      useCash: useCash,
      slider1: slider1,
      percentageCash: percentageCash,
      totalAcquisitionlabel: totalAcquisitionlabel,
      useLiquidAssets: useLiquidAssets,
      totalAcquisition: totalAcquisition,
      targetYieldReturn: targetYieldReturn,
      initialPropertyType: initialPropertyType,
      useExtraCash: useExtraCash,
      slider2: slider2,
      totalAssetsRequiredLeveragedLabel: totalAssetsRequiredLeveragedLabel,
      totalAssetsRequiredLeveraged: totalAssetsRequiredLeveraged,
      cashRequiredLeveragedLabel: cashRequiredLeveragedLabel,
      cashRequiredLeveraged: cashRequiredLeveraged,
      methodforPurchasePrice: methodforPurchasePrice,
      nextDate: GS?.SOT?.PropertyGenerators[propertyno]?.nextDate,
      propertyType: propertytype,
      hidden: false,
      isLocked: false,
      tableData: {
        PurchasePrice: B45,
        Depositassumingleverage20: B46,
        StampDuty: B47,
        SolicitorValuation: B48,
        BuildingReport: B49,
        LoanArrangementFee: B50,
        TotalCashRequired: B51,
        TotalPurchaseCosts: B52,
        NetAnnualCashflowReturnYear1: B53,
        NetYieldonPropertyYear1: B54,
        NetYieldonTotalPurchaseCostsYear1: B55,
        CashonCashReturn: B56,
        TotalCashRequired20Costs: B57,
        CostofLoan5paonleverage: B58,
        Cashflowaftermortagecosts: B59,
        CashflowaftermortgageMonthly: B60,
        ReturnonEquitypurecashflowreturn: B61,
        ReturnonEquitywitha5capitalgrowthrate: B62,
        ReturnonEquitywitha7capitalgrowthrate: B63,
        ReturnonEquitywitha10capitalgrowthrate: B64,
      },

      summaryData: {
        propertyType: propertytype,
        PurchasePrice: PurchasePrice,
        Deposit: Deposit,
        StampDuty: StampDuty,
        TotalDepositRequired: TotalDepositRequired,
        AcquisitionCost: AcquisitionCost,
        LMI: LMI,
        TotalPurchaseCosts: TotalPurchaseCosts,
        LoantoValueRatioLVR: LoantoValueRatioLVR,
        TotalDebt: TotalDebt,
        TotalDepositEquity: TotalDepositEquity,
        TotalDepositCash: TotalDepositCash,
        AppliedInterestRate: AppliedInterestRate,
        PropertyRentIncomeYear1: PropertyRentIncomeYear1,
        TotalCostofLending: TotalCostofLending,
        CashflowAnnual: CashflowAnnual,
        CashflowMonthly: CashflowMonthly,
        ReturnonEquityCOC: ReturnonEquityCOC,
        AppliedGrowthRateannualPERC: AppliedGrowthRateannualPERC,
        EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
        CashGainDOLLAR: CashGainDOLLAR,
      },
      G3: G3,
      A44: A44,
      F44: F44,
      C44: C44,
      A45: A45,
      B45: B45,
      A46: A46,
      B46: B46,
      B47: B47,
      B48: B48,
      B49: B49,
      B50: B50,
      B51: B51,
      C51: C51,
      B52: B52,
      B53: B53,
      B54: B54, 
      B55: B55,
      B56: B56,
      B57: B57,
      A58: A58,
      B58: B58,
      B59: B59,
      C59: C59,
      B60: B60,
      B61: B61,
      A62: A62,
      B62: B62,
      A63: A63,
      B63: B63,
      A64: A64,
      B64: B64,
    };

    let values = GS.SOT.PropertyGenerators[propertyno];
    let rs = theforumla(values);
    GS.SOT.PropertyGenerators[propertyno] = rs;
   
    GS.generatePortfolioSummaryData();

    API.AutoSave(window.location.href);
  }
  public GetUpdatedMaxBorrowCapacity=(DSR:any)=>{
    let homeloanRepayment:any=GS?.SOT?.Equity?.PPR?.HomeLoanRepayments || 0
    let propertyLoanRepayment=0
    let newPurchasePrice=0
              let totaldebt:number=0
              let maxborrow=GS?.SOT.BorrowingCalculator?.can_borrow1
              let totalAcquistonCost=0
              let lvr=65
              let acquistonCost=6.5
              let riskfactor=6
              let newInterestDebtRate=7
              let newInterestEquityRate=7
              let RequiredDSR=DSR || 40
      let NewLoanTerm=5
    if(GS?.SOT?.Equity?.properties?.length>0){
      propertyLoanRepayment=GS?.SOT?.Equity?.properties?.reduce((total:any,item:any)=>{
        return total+item.LoanRepayments
        },0)
    }
    let totalFinanceRepayment= (homeloanRepayment+propertyLoanRepayment) || 0   //129550
      let OtherLoanPayments= GS?.SOT?.StrategicSpending?.totalliabilities || 0 //11760  (GS.SOT.StrategicSpending?.othercreditcardloans)*12
      let total_card_limit =GS?.SOT?.BorrowingCalculator.CreditCards?.reduce(
        (partialSum:any, nextItem:any) =>
          nextItem?.type === "Credit Card"
            ? partialSum + nextItem.limit
            : partialSum,
        0
      );
      let totalCreditCardLimits= total_card_limit || 0  //12000
      let totalLivingExpense=GS?.SOT?.StrategicSpending?.expenses*12 || 0    //74760 GS?.SOT?.StrategicSpending?.expenses
            // console.log("totalLiabilitiesAndExpenses",(totalFinanceRepayment+OtherLoanPayments+totalCreditCardLimits+totalLivingExpense),"\n",
            // "OtherLoanPayments",OtherLoanPayments,"\n",
            // "totalLivingExpense",totalLivingExpense
            
            // )
            
  }
  public GetPurchasePrice(
    iniDeposit: any = 0,
    LVR: any = null,
    stateName: any
  ) {
    let requiredDeposit = iniDeposit;
    // let initialvalue = (iniDeposit / 20) * 100;

    let A7 = LVR / 100;
    let PurchasePrice = 0;
    let StampDuty = 0;
    let AcquisitionCost = 0;
    let TotalDebt = 0;
    let Deposit = 0;
    let TotalDepositRequired = 0;
    let B45 = 0;
    let estimate: any = {};
    do {
      B45 += 1;
      PurchasePrice = B45;
      // StampDuty = B45 * 0.055;
      StampDuty = GS.stampDutyRate(stateName, B45);

      AcquisitionCost = B45 * 0.025;
      TotalDebt = B45 * A7;
      Deposit = B45 - TotalDebt;
      TotalDepositRequired = Deposit + StampDuty + AcquisitionCost;
    } while (TotalDepositRequired < requiredDeposit); 
    return Math.floor(PurchasePrice);
  }
  public stampDutyRate1(stateName: any = "NSW", pp: any = 0) {
    let stampDutyRate = 0;
    console.log(pp,"pp",331440,stateName)
    if (stateName === "NSW") {
      stampDutyRate = stampDutyNSW(pp);
      //  9 / 100;
    } else if (stateName === "VIC") { 
      stampDutyRate = stampDutyVIC(pp);
      // stampDutyRate = 10 / 100;
    } else if (stateName === "QLD") {
      stampDutyRate = stampDutyQLD(331440);
      // stampDutyRate = 9 / 100;
    } else if (stateName === "SA") {
      stampDutyRate = stampDutySA(pp);
      // stampDutyRate = 11 / 100;
    } else if (stateName === "WA") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 10 / 100;
    } else if (stateName === "ACT") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 6 / 100;
    } else if (stateName === "NT") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 10 / 100;
    } else if (stateName === "TAS") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 10 / 100;
    } else {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 9 / 100;
    }
    return stampDutyRate;
  }
  public stampDutyRate(stateName: any = "NSW", pp: any = 0) {
    let stampDutyRate = 0;
    
    if (stateName === "NSW") {
      stampDutyRate = stampDutyNSW(pp);
      //  9 / 100;
    } else if (stateName === "VIC") {
      stampDutyRate = stampDutyVIC(pp);
      // stampDutyRate = 10 / 100;
    } else if (stateName === "QLD") {
      stampDutyRate = stampDutyQLD(pp);
      // stampDutyRate = 9 / 100;
    } else if (stateName === "SA") {
      stampDutyRate = stampDutySA(pp);
      // stampDutyRate = 11 / 100;
    } else if (stateName === "WA") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 10 / 100;
    } else if (stateName === "ACT") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 6 / 100;
    } else if (stateName === "NT") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 10 / 100;
    } else if (stateName === "TAS") {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 10 / 100;
    } else {
      stampDutyRate = stampDutyWA(pp);
      // stampDutyRate = 9 / 100;
    }
    return stampDutyRate;
  }
  public  rounddownToNearestTenThousand(number:any) {
    var roundedNumber = Math.floor(number / 10000) * 10000;
    return roundedNumber;
  }
  
  public PurchasePrice(iniDeposit: any = 0, LVR: any = null, stateName: any) {
    return new Promise((resolve, reject) => {
      let requiredDeposit = iniDeposit;
      // let initialvalue = (iniDeposit / 20) * 100;

      let A7 = LVR / 100;
      let PurchasePrice = 0;
      let StampDuty = 0;
      let AcquisitionCost = 0;
      let TotalDebt = 0;
      let Deposit = 0;
      let TotalDepositRequired = 0;
      let B45 = 0;
      let estimate: any = {};
      do {
        B45 += 1; 
        PurchasePrice = B45;
        // StampDuty = B45 * 0.055;
        StampDuty =
          // B45 *
          GS.stampDutyRate(stateName, B45);

        AcquisitionCost = B45 * 0.025;
        TotalDebt = B45 * A7;
        Deposit = B45 - TotalDebt;
        TotalDepositRequired = Deposit + StampDuty + AcquisitionCost;
     
      } while (TotalDepositRequired < requiredDeposit);
      return resolve(Math.floor(PurchasePrice));
    });
  }

  public medianChart: any = {};
  public getRandMedianPriceChart() {
    let states = ["NSW", "QLD", "VIC"];
    let items = [
      {
        city: "Sydney",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.5508317929759704,
          median: 1397000,
          numberAuctioned: 541,
          numberListedForAuction: 669,
          numberSold: 298,
          numberUnreported: 3,
          numberWithdrawn: 111,
          totalSales: 287351500,
        },
        _id: "637365805e133609dca921bb",
      },
      {
        city: "Melbourne",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.592391304347826,
          median: 947750,
          numberAuctioned: 736,
          numberListedForAuction: 822,
          numberSold: 436,
          numberUnreported: 11,
          numberWithdrawn: 71,
          totalSales: 335780769,
        },
        _id: "637365805e133609dca921bc",
      },
      {
        city: "Brisbane",
        data: {
          auctionedDate: "2022-11-12", 
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.34328358208955223,
          median: 1030000,
          numberAuctioned: 67,
          numberListedForAuction: 81,
          numberSold: 23,
          numberUnreported: 2,
          numberWithdrawn: 7,
          totalSales: 12770751,
        },
        _id: "637365805e133609dca921bd",
      },
      {
        city: "Adelaide",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.6739130434782609,
          median: 772500,
          numberAuctioned: 92,
          numberListedForAuction: 111,
          numberSold: 62,
          numberUnreported: 4,
          numberWithdrawn: 2,
          totalSales: 40390500,
        },
        _id: "637365805e133609dca921be",
      },
      {
        city: "Canberra",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.527027027027027,
          median: 1181250,
          numberAuctioned: 74,
          numberListedForAuction: 78,
          numberSold: 39,
          numberUnreported: 2,
          numberWithdrawn: 8,
          totalSales: 36240500,
        },
        _id: "637365805e133609dca921bf",
      },
    ];

    this.medianChart = {};
    for (let idx = 0; idx <= states.length - 1; idx++) {
      let nkey = states[idx];
      this.medianChart[`${nkey}`] = { "0": 0 };
      for (let i = 5; i <= 8; i = i + 0.01) {
        let index = Math.floor(Math.random() * (items.length - 0) + 0);

        this.medianChart[`${nkey}`][`${i.toFixed(2)}`] = {
          name: nkey,
          price: (items[index].data.median / 100) * 0.15,
          listingData: {},
        };
      }
    } 
  }

  public shuffle(array: any) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  public getRandMedianPrice(items: any) {
    let index = Math.floor(Math.random() * (items.length - 0) + 0);
    if (GS?.SOT?.PortfolioSummary?.Properties?.length < 1) {
      return {
        suburb: items[2].Suburb_Name,
        name: items[2].State,
        price: items[2].Median_Rental_Price,
      };
    } else { 
      return {
        suburb: items[index].Suburb_Name,
        name: items[index].State,
        price: items[index].Median_Rental_Price,
      };
    } 
  }
  public Round(val: any, num: any) {
    var coef = Math.pow(10, num);
    return Math.round(val * coef) / coef;
  }
  public PMT(ir: any, np: any, pv: any, fv: any = 0) {
    var presentValueInterstFector = Math.pow(1 + ir, np);
    var pmt =
      (ir * pv * (presentValueInterstFector + fv)) /
      (presentValueInterstFector - 1);
    return pmt;
  }
 
  public round_up(value: any, precision: any = 0) {
    const power = Math.pow(10, precision);
    return Math.ceil(value * power) / power;
  }
  public round_down(value: any, precision: any = 0) {
    const power = Math.pow(10, precision);
    return Math.floor(value * power) / power;
  }

  public conv_number(expr: any, decplaces: any) {
    var str = "" + Math.round(eval(expr) * Math.pow(10, decplaces));
    while (str.length <= decplaces) {
      str = "0" + str;
    }

    var decpoint = str.length - decplaces;
    return (
      str.substring(0, decpoint) + "." + str.substring(decpoint, str.length)
    );
  }

  public pv(rate: any, nper: any, pmt: any, fv: any) {
    let pv_value, x, y;

    rate = parseFloat(rate);
    nper = parseFloat(nper);
    pmt = parseFloat(pmt);
    fv = parseFloat(fv);
    if (nper === 0) {
      alert("Why do you want to test me with zeros?");
      return 0;
    }
    if (rate === 0) {
      pv_value = -(fv + pmt * nper);
    } else {
      x = Math.pow(1 + rate, -nper);
      y = Math.pow(1 + rate, nper);
      pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate;
    }
    pv_value = this.conv_number(pv_value, 2);
    return pv_value;
  }

  public fv(rate: any, nper: any, pmt: any, pv: any) {
    let fv_value, x;
    rate = parseFloat(rate);
    nper = parseFloat(nper);
    pmt = parseFloat(pmt);
    pv = parseFloat(pv);
    if (nper === 0) { 
      return 0;
    }
    if (rate === 0) {
      fv_value = -(pv + pmt * nper); 
    } else {
      x = Math.pow(1 + rate, nper);
      fv_value = -(-pmt + x * pmt + rate * x * pv) / rate;
    }
    fv_value = this.conv_number(fv_value, 2);
    return fv_value;
  }
  public PV(rate: any, nper: any, pmt: any, fv: any = 0) {
    return (pmt / rate) * (1 - Math.pow(1 + rate, -nper));
  }
  
}

let GS = new GlobalService();
function isInArray(array:any, item:any) {
  return array.some((elem:any) => elem.region === item.region && elem.suburb === item.Suburb_Name && elem.name === item.State);
}
function subscribe(eventName: any, listener: any) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: any, listener: any) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: any, data: any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

function isWhatPercentOf(numA: any, numB: any) {
  let percentage = (numA / numB) * 100;
  return percentage;
}

function getPercentageIncrease(numA: any, numB: any) {
  return ((numA - numB) / numB) * 100;
}
function TaxLookUp(number:any,columnIndex:any){
  var data = [
    [0, 0, 0],
    [18201, 0, 0.19],
    [45001, 5092, 0.325],
    [120001, 29467, 0.37],
    [180001, 51667, 0.45]
  ];
  var result = data.find(function(row:any, index:any, array:any) {
    if (index === 0) {
      return false; // Skip the first row since there is no previous number to compare with
    }
  
    var previousNumber = array[index - 1][0];
    var currentNumber = row[0];
  
    if (index === array.length - 1) {
      return previousNumber < number && number >= currentNumber;
    }
  
    var nextNumber = array[index + 1][0];
   
    return previousNumber < number && number >= currentNumber && number < nextNumber;
  }); 
  
  if (result) {
    var rate = result[2];
    return rate
  } 

  
  return null;
} 

function rounddown(value:any, decimalPlaces:any) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.floor(value * factor) / factor;
}
function getTaxIncome(number:any){

let getTaxRate:any=TaxLookUp(number,3)

// var result = rounddownToNearestTenThousand(number / (1 -  getTaxRate));
let C3=Math.round(number / (1 -  getTaxRate))
var result=rounddown(C3,-4)

return result

}
const SplitContactType=(value:any)=>{
  if(value?.includes("Contact")){
      let newvalue=value.split(" ")[1]
      return newvalue
  }else{
    return value
  }
}
const formatAusNumber=(Mobile:any)=>{
  let mobile=Mobile?.split(" ").join("") || ""
  let number=""
  if(Mobile[0]=="0"){
    number="+61"+mobile.slice(1)
  }else{
    number=mobile
  }
  return number
   
}
const SendMail=(EmailData:any,email:any,subject:any)=>{

  const bodyObj = EmailData;
  const body = Object.entries(bodyObj)
  .map(([key, value]) => `${key}: ${value}`)
  .join('\n');
  const encodedEmail = encodeURIComponent(email);
  const encodedSubject = encodeURIComponent(subject);


  // const uint8Array = new TextEncoder().encode(body);
  // const compressedUint8Array = pako.gzip(uint8Array);
  // const compressedArray = Array.from(new Uint8Array(compressedUint8Array));
  // const base64CompressedBody = btoa(compressedArray.map((item) => String.fromCharCode(item)).join(''));
  const encodedBody = encodeURIComponent(body);
  const mailtoLink = `mailto:${encodedEmail}?subject=${encodedSubject}&body=${encodedBody}`;
  window.location.href = mailtoLink;
    }
    function c(number: number, maximumFractionDigits: number = 0) {
      if (isNaN(number)) {
        return "$0";
      }
      return Intl.NumberFormat("en-US", {
        style: "currency", 
        currency: "USD",
        maximumFractionDigits: maximumFractionDigits,
      }).format(number);
    }
    function cp(number: number) {
      if (isNaN(number)) {
        return "0%";
      }
      return (
        Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(number) +
        "%"
      );
    }
export {
  GS,
  publish, 
  subscribe,
  unsubscribe,
  isWhatPercentOf,
  getPercentageIncrease,
  getTaxIncome,
  SplitContactType,
  isInArray,
  formatAusNumber,
  SendMail,
  c,
  cp
  
};
