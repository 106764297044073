import React, { useState, useEffect } from "react";

export const useFetchParamsData = (
  url,
  appyFilter,
  TopFilters = {
    PostCode: [],
    Page: 1,
    PerPage: 50, 
    MaxPrice: 0,
    MinPrice: 0,
    PropertyType: [],
    ListingType: [],
    Keyword: "",
    State: "",
    
  },
  propertyType = ""
) => {
  const [TableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [totalResult, setTotalResults] = useState();

  useEffect(() => {
    const CallListingApi = async () => {
      setIsLoading(true);
      let queryparams = "";
      if (TopFilters?.PostCode?.length > 0) {
        let newPostCode = TopFilters.PostCode.map((item) => {
          queryparams = queryparams + `postcode[]=${item.code}&`;
          return item.code;
        });

        // queryparams = queryparams + `postcode=[${newPostCode}]&`;
      }
      if (TopFilters?.Page > 0) {
        queryparams = queryparams + `page=${Number(TopFilters?.Page)}&`;
      }
      if (TopFilters?.PerPage > 0) {
        queryparams = queryparams + `perPage=${Number(TopFilters?.PerPage)}&`;
      }
      if (TopFilters?.MaxPrice > 0) { 
        queryparams = queryparams + `maxPrice=${Number(TopFilters?.MaxPrice)}&`;
      }
      if (TopFilters?.MinPrice > 0) {
        queryparams = queryparams + `minPrice=${Number(TopFilters?.MinPrice)}&`;
      }
      if (TopFilters?.PropertyType?.length > 0) {
        let newPropertyType = TopFilters.PropertyType.map((item) => {
          queryparams = queryparams + `propertyType[]=${item.code}&`;
          return item.code;
        });
        // queryparams = queryparams + `propertyType=[${newPropertyType}]&`;
      }
      if (TopFilters?.ListingType?.length > 0) {
        let newListingType = TopFilters.ListingType.map((item) => {
          queryparams = queryparams + `listingType[]=${item.code}&`;
          return item.code;
        });

        // queryparams = queryparams + `listingType=[${newListingType}]&`;
      }
      if (TopFilters?.Keyword?.length > 0) {
        queryparams = queryparams + `keyword=${TopFilters?.Keyword}`;
      }
      if (TopFilters?.State?.length > 0) {
        let newState = TopFilters.State.map((item) => {
          queryparams = queryparams + `state[]=${item.code}&`;
          return item.code;
        });
      }

      let result = await fetch(
        `${process.env.REACT_APP_API_BASEURL}${url}${queryparams}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((property) => property);
      if (result) {
        let newArr = [];
        if (propertyType == "Commercial") {
          newArr = result.data.map((item) => {
            let newObject = {
              ...item,
              ClientName: item?.extraData?.ClientName,
              TenantName: item?.extraData?.TenantName || "",
              LeaseExpiry: item?.extraData?.LeaseExpiry || "",
              CashOrEquity: item?.extraData?.CashOrEquity || "",
              lvr: item?.extraData?.lvr || "", 
              listing: item?.extraData?.listing  || "",
              RentalIncome: item?.extraData?.RentalIncome || "",
              YieldReturn: item?.extraData?.YieldReturn || "",
              LandArea: item?.extraData?.LandArea || "",
              FloorArea: item?.extraData?.FloorArea || "",
              TenureType: item?.extraData?.TenureType || "",
              Occupation: item?.extraData?.Occupation || "",

              Description: item?.extraData?.Description || "",
              EmailTitle: item?.extraData?.EmailTitle || "",
              EmailIntroduction: item?.extraData?.EmailIntroduction || "",
              Swot: item?.extraData?.Swot || "",
              SuburbProfile: item?.extraData?.SuburbProfile || "",
              DateAdded: item?.extraData?.DateAdded || "",
              AdvertUpdate: item?.extraData?.AdvertUpdate || "",
              PurchasePrice: item?.extraData?.PurchasePrice || "",
              Deposit: item?.extraData?.Deposit || "",
              StampDuty: item?.extraData?.StampDuty || "",
              ValuationAndSolicitor:
                item?.extraData?.ValuationAndSolicitor || "",
              BuyersAgent: item?.extraData?.BuyersAgent || "",
              TotalAcquistion: item?.extraData?.TotalAcquistion || "",
              TotalDeposit: item?.extraData?.TotalDeposit || "",
              DebtFacility: item?.extraData?.DebtFacility || "",
              NetIncome: item?.extraData?.NetIncome || "",
              Outgoings: item?.extraData?.Outgoings || "",
              NetInitialYield: item?.extraData?.NetInitialYield || "",
              EquityInt: item?.extraData?.EquityInt || "",
              DebtInt: item?.extraData?.DebtInt || "",
              PropertyManagement: item?.extraData?.PropertyManagement || "",
              AnnualCashFlow: item?.extraData?.AnnualCashFlow || "",
              MonthlyCashFlow: item?.extraData?.MonthlyCashFlow || "",
              InteretCover: item?.extraData?.InteretCover || "",
              ReturnOnCash: item?.extraData?.ReturnOnCash || "",
              ImageUrl: item?.extraData?.ImageUrl || "",

              postcode: item?.metadata.addressComponents.postcode,
              extraData: {
                ClientName: item?.extraData?.ClientName || "",
                TenantName: item?.extraData?.TenantName || "",
                LeaseExpiry: item?.extraData?.LeaseExpiry || "",
                CashOrEquity: item?.extraData?.CashOrEquity || "",
                lvr: item?.extraData?.lvr || "",
                listing: item?.extraData?.listing || "",
                RentalIncome: item?.extraData?.RentalIncome || "",
                YieldReturn: item?.extraData?.YieldReturn || "",
                LandArea: item?.extraData?.LandArea || "",
                FloorArea: item?.extraData?.FloorArea || "",
                TenureType: item?.extraData?.TenureType || "",
                Occupation: item?.extraData?.Occupation || "",
                Description: item?.extraData?.Description || "",
                EmailTitle: item?.extraData?.EmailTitle || "",
                EmailIntroduction: item?.extraData?.EmailIntroduction || "",
                Swot: item?.extraData?.Swot || "",
                SuburbProfile: item?.extraData?.SuburbProfile || "",
                DateAdded: item?.extraData?.DateAdded || "",
                AdvertUpdate: item?.extraData?.AdvertUpdate || "",
                PurchasePrice: item?.extraData?.PurchasePrice || "",
                Deposit: item?.extraData?.Deposit || "",
                StampDuty: item?.extraData?.StampDuty || "",
                ValuationAndSolicitor:
                  item?.extraData?.ValuationAndSolicitor || "",
                BuyersAgent: item?.extraData?.BuyersAgent || "",
                TotalAcquistion: item?.extraData?.TotalAcquistion || "",
                TotalDeposit: item?.extraData?.TotalDeposit || "",
                DebtFacility: item?.extraData?.DebtFacility || "",
                NetIncome: item?.extraData?.NetIncome || "",
                Outgoings: item?.extraData?.Outgoings || "",
                NetInitialYield: item?.extraData?.NetInitialYield || "",
                EquityInt: item?.extraData?.EquityInt || "",
                DebtInt: item?.extraData?.DebtInt || "",
                PropertyManagement: item?.extraData?.PropertyManagement || "",
                AnnualCashFlow: item?.extraData?.AnnualCashFlow || "",
                MonthlyCashFlow: item?.extraData?.MonthlyCashFlow || "",
                InteretCover: item?.extraData?.InteretCover || "",
                ReturnOnCash: item?.extraData?.ReturnOnCash || "",
                ImageUrl: item?.extraData?.ImageUrl || "",
              }, 
            };
            return newObject;
          });
        } else {
          newArr = result.data.map((item) => {
            let newObject = {
              ...item,
              ClientName: item?.extraData?.ClientName,
              TenantName: item?.extraData?.TenantName || "",
              LeaseExpiry: item?.extraData?.LeaseExpiry || "",
              CashOrEquity: item?.extraData?.CashOrEquity || "",
              lvr: item?.extraData?.lvr || "",
              listing: item?.extraData?.listing || item?.listing ,
              listingtype:item?.extraData?.listingtype || item?.listing?.listingType || "",
              RentalIncome: item?.extraData?.RentalIncome || "",
              YieldReturn: item?.extraData?.YieldReturn || "",
              LandArea: item?.extraData?.LandArea || "",
              FloorArea: item?.extraData?.FloorArea || "",
              TenureType: item?.extraData?.TenureType || "",
              Occupation: item?.extraData?.Occupation || "",
              Description: item?.extraData?.Description || "",
              EmailTitle: item?.extraData?.EmailTitle || "",
              EmailIntroduction: item?.extraData?.EmailIntroduction || "",
              Swot: item?.extraData?.Swot || "",
              SuburbProfile: item?.extraData?.SuburbProfile || "",
              DateAdded: item?.extraData?.DateAdded || "",
              AdvertUpdate: item?.extraData?.AdvertUpdate || "",
              PurchasePrice: item?.extraData?.PurchasePrice || "",
              Deposit: item?.extraData?.Deposit || "",
              StampDuty: item?.extraData?.StampDuty || "",
              ValuationAndSolicitor:
                item?.extraData?.ValuationAndSolicitor || "",
              BuyersAgent: item?.extraData?.BuyersAgent || "",
              TotalAcquistion: item?.extraData?.TotalAcquistion || "",
              TotalDeposit: item?.extraData?.TotalDeposit || "",
              DebtFacility: item?.extraData?.DebtFacility || "",
              NetIncome: item?.extraData?.NetIncome || "",
              Outgoings: item?.extraData?.Outgoings || "",
              NetInitialYield: item?.extraData?.NetInitialYield || "",
              EquityInt: item?.extraData?.EquityInt || "",
              DebtInt: item?.extraData?.DebtInt || "",
              PropertyManagement: item?.extraData?.PropertyManagement || "",
              AnnualCashFlow: item?.extraData?.AnnualCashFlow || "",
              MonthlyCashFlow: item?.extraData?.MonthlyCashFlow || "",
              InteretCover: item?.extraData?.InteretCover || "",
              ReturnOnCash: item?.extraData?.ReturnOnCash || "",
              ImageUrl: item?.extraData?.ImageUrl || "",
              postcode: item?.metadata?.addressComponents?.postcode,
              extraData: {
                ClientName: item?.extraData?.ClientName || "",
                TenantName: item?.extraData?.TenantName || "",
                LeaseExpiry: item?.extraData?.LeaseExpiry || "",
                CashOrEquity: item?.extraData?.CashOrEquity || "",
                lvr: item?.extraData?.lvr || "",
                listingtype:item?.extraData?.listingtype || item?.listing?.listingType || "",
                listing: item?.extraData?.listing || item?.listing ,
                RentalIncome: item?.extraData?.RentalIncome || "",
                YieldReturn: item?.extraData?.YieldReturn || "",
                LandArea: item?.extraData?.LandArea || "",
                FloorArea: item?.extraData?.FloorArea || "",
                TenureType: item?.extraData?.TenureType || "",
                Occupation: item?.extraData?.Occupation || "",
                Description: item?.extraData?.Description || "",
                EmailTitle: item?.extraData?.EmailTitle || "",
                EmailIntroduction: item?.extraData?.EmailIntroduction || "",
                Swot: item?.extraData?.Swot || "",
                SuburbProfile: item?.extraData?.SuburbProfile || "",
                DateAdded: item?.extraData?.DateAdded || "",
                AdvertUpdate: item?.extraData?.AdvertUpdate || "",
                PurchasePrice: item?.extraData?.PurchasePrice || "",
                Deposit: item?.extraData?.Deposit || "",
                StampDuty: item?.extraData?.StampDuty || "",
                ValuationAndSolicitor:
                  item?.extraData?.ValuationAndSolicitor || "",
                BuyersAgent: item?.extraData?.BuyersAgent || "",
                TotalAcquistion: item?.extraData?.TotalAcquistion || "",
                TotalDeposit: item?.extraData?.TotalDeposit || "",
                DebtFacility: item?.extraData?.DebtFacility || "",
                NetIncome: item?.extraData?.NetIncome || "",
                Outgoings: item?.extraData?.Outgoings || "",
                NetInitialYield: item?.extraData?.NetInitialYield || "",
                EquityInt: item?.extraData?.EquityInt || "",
                DebtInt: item?.extraData?.DebtInt || "",
                PropertyManagement: item?.extraData?.PropertyManagement || "",
                AnnualCashFlow: item?.extraData?.AnnualCashFlow || "",
                MonthlyCashFlow: item?.extraData?.MonthlyCashFlow || "",
                InteretCover: item?.extraData?.InteretCover || "",
                ReturnOnCash: item?.extraData?.ReturnOnCash || "",
                ImageUrl: item?.extraData?.ImageUrl || "",
              },
              displayableAddress:
                item?.listing?.propertyDetails?.displayableAddress,
              postcode: item?.listing?.propertyDetails?.postcode,
              bannerUrl: item?.project?.bannerUrl,
              propertyType: item?.listing?.propertyDetails?.propertyType,
              area: item?.listing?.propertyDetails?.area,
            };
            return newObject;
          });
          console.log(newArr,"newArr")
        }

        setTableData(newArr);
        setTotalResults(result.pagination.total);
        setIsLoading(false);
      }
    };
    CallListingApi();
  }, [TopFilters]);
  return { isLoading, TableData, totalResult };
};
