import Header from "../../components/header/Header";
import Liveproperties from "../../components/PinderPage/LiveProperties/Liveproperties";
import UserSidebar from "../../components/SideBar/UserSidebar";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

function LiveProperties() {
  return (
    <OuterContainer>
     
     
      <UserSidebar headerPath="Pinder" />
      <HomeContainer>
        <Header headerPath="/Pinder" />
        <Liveproperties />
      </HomeContainer>
    </OuterContainer>
  );
}

export default LiveProperties;
