import { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import OpenImg from "../../../assets/images/Expand_down1.svg";
import CloseImg from "../../../assets/images/Expand_down2.svg";
import AnimatedHeight from "../../AnimateHeight";
import { VideoCardContainer, VideoContent } from "./VideoCard.style";
import Tyson from "../../../assets/tyson.png";

const VideoCard = ({ property, riskVal, video }) => {
  const [open, setOpen] = useState(true);
  const height = open ? "auto" : 0;
  const [riskVal1, setriskVal1] = useState(riskVal);
  const [riskvalue, setriskvalue] = useState(riskVal);
  const [, setcolor] = useState("");

  useEffect(() => {
    if (riskVal === 5) {
      setcolor("#00a779");
      setriskvalue(1);
    } else if (riskVal === 6) {
      setcolor("#cd4a5e");
      setriskvalue(50);
    } else {
      setcolor("#fee468");
      setriskvalue(100);
    }
  }, [riskVal]);

  useEffect(() => {
    if (riskvalue >= 1 && riskvalue <= 49) {
      setriskVal1(5);
      setcolor("#00a779");
    } else if (riskvalue >= 50 && riskvalue <= 99) {
      setriskVal1(6);
      setcolor("#fee468");
    } else {
      setriskVal1(7);
      setcolor("#cd4a5e");
    }
  }, [riskvalue]);

  return (
    <VideoCardContainer>
      <VideoContent>
        {/* <div className="d-flex justify-content-start">
          <p></p>
        </div> */}
        <div className="d-flex justify-content-center">
          <h5>Deciding your Risk Profile?</h5>
        </div>
        <div className="d-flex justify-content-center">
          <h5
            style={{
              color:
                property === "Aggressive"
                  ? "#cd4a5e"
                  : property === "Moderate"
                  ? "#fee468"
                  : property === "Conservative"
                  ? "#00a779"
                  : "#00a779",
              fontSize: "15px",
            }}
          >
            {property} - {riskVal1}% Return
          </h5>
        </div>
        <div className="Video-Content-header">
          <Image
            src={open === true ? OpenImg : CloseImg}
            onClick={() => setOpen(!open)}
          />
        </div>

        <AnimatedHeight duration={500} height={height}>
          <div>
            <ReactPlayer
              width="100%"
              height="155px"
              controls={true}
              url="https://propvesting-xm.s3.us-east-2.amazonaws.com/videos/IL+property+Portfolio-1.mp4"
              light={Tyson}
              title="Risk Profiling"
              playing
            ></ReactPlayer>
          </div>
        </AnimatedHeight>
      </VideoContent>
    </VideoCardContainer>
  );
};

export default VideoCard;
