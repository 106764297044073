import {
  NavItemContainer,
  NavItem,
  NavIcon,
  NavText,
  NavSubContainer,
} from "./sidebar.styles";
import React, { useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import PlusIcon from "../../assets/images/plus.svg";
import MinusIcon from "../../assets/images/minus.svg";

import { useDispatch, useSelector } from "react-redux";
import { ToggleSubSidebar } from "../../redux/actions";
import { GS } from "../../services/global";
import SubSidebarItem from "./SubSidebarItem";
import AnimatedHeight from "../AnimateHeight";

const Sidebaritem = ({ item, activePath }) => {

  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.SubSidebar);
  
  const navigate = useNavigate();
  const location = useLocation();
  const mypath = location.pathname;
  const height = open[item.name] ? "auto" : 0;
  const newlink = item.name;

  const result = newlink === activePath;
  const handleLogOut = (link) => {
    if (link === "/logout") {
      sessionStorage.removeItem("Token");
      localStorage.removeItem("email")
      navigate("/signin");
    } else {
      let x = link.includes("/Clients/");
      if (x === true) {
        navigate(`${link}?id=${GS.SOT.VendorId}`);
      } else {
        navigate(link);
      }
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/settings")) {
      let prop = "Settings";
      open[prop] = true;
      open["Clients"]=false;
      open["Contacts"]=false;
      open["Pinder 2.0"]=false;
      open["Portfolio Summary"]=false;
      open["Vendors"]=false;
      open["Agency Profile"]=false;
      dispatch(ToggleSubSidebar({ ...open }));
    }else if(location.pathname.includes("/pinder")){
      let prop = "Pinder 2.0";
      open[prop] = true;
      open["Clients"]=false;
      open["Contacts"]=false;
      open["Settings"]=false;
      open["Portfolio Summary"]=false;
      open["Vendors"]=false;
      open["Agency Profile"]=false;
      dispatch(ToggleSubSidebar({ ...open }));
    }else if(location.pathname.includes("/ContactList")){
      let prop = "Pinder 2.0";
      open[prop] = false;
      open["Clients"]=false;
      open["Contacts"]=true;
      open["Settings"]=false;
      open["Portfolio Summary"]=false;
      open["Vendors"]=false;
      open["Agency Profile"]=false;
      dispatch(ToggleSubSidebar({ ...open }));
    }else if(location.pathname.includes("/Clients")){
      let prop = "Pinder 2.0";
      open[prop] = false;
      open["Clients"]=true;
      open["Contacts"]=false;
      open["Settings"]=false;
      open["Portfolio Summary"]=false;
      open["Vendors"]=false;
      open["Agency Profile"]=false;
      dispatch(ToggleSubSidebar({ ...open }));
    }else if(location.pathname.includes("/agency")){
      let prop = "Pinder 2.0";
      open[prop] = false;
      open["Clients"]=false;
      open["Contacts"]=false;
      open["Settings"]=false;
      open["Portfolio Summary"]=false;
      open["Vendors"]=false;
      open["Agency Profile"]=true;
      dispatch(ToggleSubSidebar({ ...open }));
    }else{
      let prop = "Pinder 2.0";
      open[prop] = false;
      open["Clients"]=false;
      open["Contacts"]=false;
      open["Settings"]=false;
      open["Portfolio Summary"]=false;
      open["Vendors"]=false;
      open["Agency Profile"]=false;
      dispatch(ToggleSubSidebar({ ...open }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (item.subItems) {
    return (
      <>
        <NavItemContainer
          onClick={() => {
            handleLogOut(item.link);
          }}
         
          active={result}
          activeColor={item.activeColor}
        >
          <NavItem
            onClick={(e) => {
              let prop = item.name;
              open[prop] = !open[prop];
              dispatch(ToggleSubSidebar({ ...open }));
            }}
          >
            {item.icon && (
              <NavIcon>
                <Image src={item.icon} width="100%" />
              </NavIcon>
            )}
          <NavText>{item.name}</NavText>
          </NavItem>

          {item.name === "Client Lifestyle Goals" &&
          !location.pathname.includes("/Clients/") ? (
            <></>
          ) : (
            <Image
              src={open[item.name] === false ? PlusIcon : MinusIcon}
              onClick={(e) => {
                e.stopPropagation();
                let prop = item.name;
                open[prop] = !open[prop];
                dispatch(ToggleSubSidebar({ ...open }));
              }}
            />
          )}
        </NavItemContainer>
      
        <AnimatedHeight  duration={500} height={height}>
          <NavSubContainer
          
            style={{ display: "flex", flexDirection: "column", gap: "7px" }}
          >
            {item.subItems.map((subitem, index) => (
              <SubSidebarItem  item={subitem} key={index} />
            ))}
          </NavSubContainer>
        </AnimatedHeight> 
        
      </>
    );
  } else {
    return (
      <NavItemContainer
      
        active={item.link === mypath}
        onClick={() => handleLogOut(item.link)}
        activeColor={item.activeColor}
      >
        <NavItem>
          {item.icon && (
            <NavIcon>
              <Image src={item.icon} width="100%" />
            </NavIcon>
          )}
         <NavText>{item.name}</NavText>
        </NavItem>
      </NavItemContainer>
    );
  }
};

export default Sidebaritem;
