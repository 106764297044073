const initialState = "residential";

const PinderFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMMERCIAL":
      state = "commercial";
      return state;
    case "SET_RESIDENTIAL":
      state = "residential";
      return state;
    default:
      return state;
  }
};
export default PinderFilterReducer;
