import { useState, useEffect,useRef } from "react";
import classes from "./PinderPage.module.css";
import useWindowDimensions from "../../hooks";
import "./stylesheet.css";
import LocalResidential from "./LocalResidential";
import LocalCommercial from "./LocalCommercial";
import { InputText } from "primereact/inputtext";
import { Button } from "react-bootstrap";
import { API } from "../../services/api";
import { CustomDropDown } from "./pinder.style";
import { CustomMultiSelect } from "./pinder.style";
import {
  PerPageOptions,
  statesOfAustralia,
  LocalResultsItems,
} from "../../utils/constants";
import ClearImg from "../../assets/SVG/clear-format.svg";
import { CustomOverLayPannel } from "./pinder.style";
import OverviewImg from "../../assets/SVG/Overview.svg";
import { useSelector,useDispatch } from "react-redux";
import { SetPinderFilter } from "../../redux/actions";
export default function Savedproperties() {
  const { width } = useWindowDimensions();
  const dispatch=useDispatch()
  const pinderstate=localStorage.getItem("pinderfilter")
  const ListRef=useRef(null)
  const[Clear,setClear]=useState(false)
  const [LocalItem, setLocalItem] = useState(pinderstate || "residential"); //commercial
  const [TopFilters, setTopFilters] = useState({
    PostCode: [],
    Page: 1,
    PerPage: 0,
    MaxPrice: 0,
    MinPrice: 0,
    PropertyType: [],
    ListingType: [],
    Keyword: "",
    State: [],
  });
  const ColumnRef=useRef()
  const ClearFilters = () => {
    setTopFilters({
      PostCode: [],
      Page: 1,
      PerPage: 0,
      MaxPrice: 0,
      MinPrice: 0,
      PropertyType: [],
      ListingType: [],
      Keyword: "",
      State: [],
    });
    setClear(true)

   
  };
  const [ListingTypeSelectItems, setListingTypeSelectItems] = useState([]);
  const [postcodes, setpostcodes] = useState([]);
  const [propertyOptions, setpropertyOptions] = useState([]);

  const getFilterOptions = async () => {
    let response = await API.get(`type-properties-totals/${LocalItem}`);
    let postoptions = response?.data?.postcodes?.map((item) => {
      let newObj = {
        name: item._id,
        code: item._id,
      };
      return newObj;
    });
    postoptions.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setpostcodes(postoptions);
    console.log(response?.data?.listingType,"response?.data?.listingType")
    let listingoptions = response?.data?.listingType?.map((item) => {
      let listing = item._id.charAt(0).toUpperCase() + item._id.slice(1);
      let capitalLetterCount = 0;
      let indexSecondCapitalLetter = -1;
      for (let i = 0; i < listing.length; i++) {
        if (listing[i] === listing[i].toUpperCase()) {
          capitalLetterCount++;
          if (capitalLetterCount === 2) {
            indexSecondCapitalLetter = i;
            break;
          }
        }
      }
      if (indexSecondCapitalLetter != -1) {
        listing =
          listing.slice(0, indexSecondCapitalLetter) +
          " " +
          listing.slice(indexSecondCapitalLetter);
      }
      let newObj = {
        name: listing,
        code: item._id,
      };
      return newObj;
    });

    setListingTypeSelectItems(listingoptions);
    let propertyoptions = response?.data?.propType?.map((item) => {
      let listing=item._id
      if(LocalItem=="residential"){
         listing = item._id.charAt(0).toUpperCase() + item._id.slice(1);
        let capitalLetterCount = 0;
        let indexSecondCapitalLetter = -1;
        let indexThirdCapitalLetter = -1;
        for (let i = 0; i < listing.length; i++) {
          if (listing[i] === listing[i].toUpperCase()) {
            capitalLetterCount++;
            if (capitalLetterCount === 2) {
              indexSecondCapitalLetter = i;
              
            }else if(capitalLetterCount === 3){
              indexThirdCapitalLetter=i
              break;
            }
          }
        }
        if (indexThirdCapitalLetter!=-1) {
          listing =
            listing.slice(0, indexSecondCapitalLetter) +
            " " +
            listing.slice(indexSecondCapitalLetter,indexThirdCapitalLetter) +
            " "+listing.slice(indexThirdCapitalLetter)
            
            ;
        }else if(indexSecondCapitalLetter != -1 ){
          listing =
          listing.slice(0, indexSecondCapitalLetter) +
          " " +listing.slice(indexSecondCapitalLetter)
        }
      }
      let newObj = {
        name: listing,
        code: item._id,
      };
      return newObj;
    });
    propertyoptions.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
    if(LocalItem=="commercial"){
      setpropertyOptions([
        {
          name: "Carspace",
          code: "Carspace",
        },
        {
          name: "Development Land",
          code: "Development Land",
        },
        {
          name: "Hotel Leisure",
          code: "Hotel Leisure",
        },
        {
          name: "Industrial",
          code: "Industrial",
        },
        {
          name: "Medical",
          code: "Medical",
        },
        {
          name: "Medical Consulting",
          code: "Medical Consulting",
        },
        {
          name: "Motel",
          code: "Motel",
        },
        {
          name: "Offices",
          code: "Offices",
        },
        {
          name: "Other",
          code: "Other",
        },
        {
          name: "Parking CarSpace",
          code: "Parking CarSpace",
        },
        {
          name: "Retail",
          code: "Retail",
        },
        {
          name: "Rural",
          code: "Rural",
        },
        {
          name: "Rural Commercial Farming",
          code: "Rural Commercial Farming",
        },
        {
          name: "Serviced Offices",
          code: "Serviced Offices",
        },
        {
          name: "Showrooms Bulky Goods",
          code: "Showrooms Bulky Goods",
        },
        {
          name: "Warehouse",
          code: "Warehouse",
        },
      ])
    }else{
      setpropertyOptions(propertyoptions);
    }
   
    setTopFilters({
      PostCode: [],
      Page: 1,
      PerPage: 0,
      MaxPrice: 0,
      MinPrice: 0,
      PropertyType: [],
      ListingType: [],
      Keyword: "",
      State: [],
    });
  };

  useEffect(() => {
    getFilterOptions(LocalItem);
  }, [LocalItem]);
  return (
    <div
      style={{ width: width - 290 + "px", overflowX: "hidden !important" }}
      className={`pinderContainer ${classes.PinderPageContainer}`}
    >
      <div className="d-flex flex-wrap  justify-content-between mt-4">
        <div>
          <h2 className={classes.pageTitle}>Local Data</h2>
        </div>
        <div style={{ width: "150px" }}>
          <CustomDropDown
            filter
            className={classes.filterDropdown}
            value={LocalItem}
            options={LocalResultsItems}
            onChange={(e) => {setLocalItem(e.value);
            dispatch(SetPinderFilter(e.value));
            localStorage.setItem("pinderfilter",e.value)
            }}
            placeholder="Data Type"
            color="#ced4da"
          />
        </div>
      </div>
      <div className={`${classes.FilterContainer}`}>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search " style={{ marginTop: "-5px" }} />
            <InputText
              placeholder="Search"
              value={TopFilters.Keyword}
              onChange={(e) =>
               { setTopFilters({ ...TopFilters, Keyword: e.target.value });
               setClear(false)
              }
              }
            />
          </span>
        </div>
        <div className="w-10rem">
          <CustomMultiSelect
        
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.ListingType}
            options={ListingTypeSelectItems}
            onChange={(e) =>
             { setTopFilters({ ...TopFilters, ListingType: e.value });
             setClear(false)
            }
             
            }
            filterPlaceholder="Search"
            resetFilterOnHide={true}
            placeholder="Listing Type"
            color="#ced4da" 
            maxSelectedLabels={3}
           
            
          />
        </div>
        <div className="w-10rem" style={{ overflowX: "hidden" }}>
          <CustomMultiSelect
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.PropertyType}
            options={propertyOptions}
            onChange={(e) =>
              {setTopFilters({ ...TopFilters, PropertyType: e.value });
              setClear(false)
            }
            }
            placeholder="Property Type"
            color="#ced4da"
            maxSelectedLabels={3}
            resetFilterOnHide={true}
          />
        </div>
        <div className="w-10rem">
          <CustomMultiSelect
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.PostCode}
            options={postcodes}
            onChange={(e) =>
             { setTopFilters({ ...TopFilters, PostCode: e.value });
             setClear(false)
            }
            } 
            placeholder="Postcode"
            color="#ced4da"
            maxSelectedLabels={3}
            resetFilterOnHide={true} 
          />
        </div>
        <div className="w-10rem">
          <CustomMultiSelect
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.State}
            options={statesOfAustralia}
            onChange={(e) =>{ setTopFilters({ ...TopFilters, State: e.value });setClear(false)}}
            placeholder="State"
            color="#ced4da"
            maxSelectedLabels={3}
             resetFilterOnHide={true}
          />
        </div>

        <div className="w-10rem">
          <CustomDropDown
            filter
            showClear={TopFilters.PerPage > 0 ? true : false}
            className={classes.filterDropdown}
            value={TopFilters.PerPage}
            options={PerPageOptions}
            onChange={(e) =>{ setTopFilters({ ...TopFilters, PerPage: e.value });setClear(false)}}
            placeholder="Per Page"
            color="#ced4da"
           
          />
        </div> 

        <div>
          <Button
            className={`${classes.ClearFilterButton}`}
            onClick={ClearFilters}
            type="button"
            severity="success"
          >
            <img style={{ width: "15px" }} src={ClearImg} />
          </Button>
        </div>
        
       
      </div>
      {LocalItem == "commercial" ? (
        <LocalCommercial
          TopFilters={TopFilters}
          setTopFilters={setTopFilters}
          Clear={Clear}
        />
      ) : (
        <LocalResidential
          TopFilters={TopFilters}
          setTopFilters={setTopFilters}
          Clear={Clear}
        />
      )}
    </div>
  );
}
