import React from 'react'
import {ToolTipContainer} from "./ToolTip.style"

const ToolTip = ({show,txt,width,textAlign,left,top,style,borderRadius}) => {
  
  return (
    <ToolTipContainer borderRadius={borderRadius} top={top} style={style} textAlign={textAlign} width={width} show={show} left={left}>{txt}</ToolTipContainer>
  )
}

export default ToolTip