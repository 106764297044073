import React, { useState,useRef } from "react";
import {
  PropertyCardContainer,
  PropertyBox,
  PropertyContent,
} from "./PropertyCard.style";
import { Image } from "react-bootstrap";
import OpenImg from "../../assets/images/Expand_down1.svg";
import CloseImg from "../../assets/images/Expand_down2.svg";

import AnimatedHeight from "../AnimateHeight";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ToolTip from "../ToolTip/ToolTip";
import GradientButton from "../Button/GradientButton";
import { API } from "../../services/api";
import { CurrencyFormatter } from "../../utils";
import { Toast } from "primereact/toast";
const PropertyCard = ({
  img,
  propertyType,
  progressColor, 
  PurchasePrice,
  GrossYield,
  LVR,
  Deposit,
  RiskProfile,
  GrossRentalIncome,
  NetCashflow,
  InterestRate,
  SaleRange,
  number,
  admin,
  price,
  netrental,
  data,
  index,
  item,
  email,
  mobile,
  name,
  contact_address,
  _id
}) => {
  const toast = useRef(null);
  const [open, setOpen] = useState(true);
  const height = open ? "auto" : 0;
  const pathname = window.location.href.includes('IncomePlan')?'IncomePlan':"Profile"
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [Hovering, setHovering] = useState(false);
  const [currentHoverItem, setcurrentHoverItem] = useState();

  const handleMouseEnter = (idx, event, currentItem) => {
    if(currentItem?.medianPropertyType=="residential"){
      if(currentItem["0"]?.listing?.media?.length>0){
        setHovering(true);
      }
     
    }else{
      if(currentItem?.listing?.media?.length>0){
       
        setHovering(true);
      }
    } 
    
    

    const rect = event.currentTarget.getBoundingClientRect();

    setTooltipPosition({
      top: 40,
      left: 27,
    });
  };
  const SendEmail=async()=>{
    let data={
      portfolio: {
        address:contact_address
      },
      agent_name: name,
      agent_phone: mobile,
      url: process.env.REACT_APP_DOMAIN+`Clients/IncomePlan?id=${_id}`
    }
    try{
      let r=await API.post('contact-us-plan',data)
      if(r){
        showSuccess()
      }
      
    }catch(err){
      console.log(err)
    }
  } 
  
  const handleMouseLeave = () => {
    setHovering(false);
  };
  const splitArrayItems = (items) => {
    let currStr = "";
    items?.map((item) => {
      currStr = currStr + " " + item;
    });
    return splitWords(currStr);
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",

      life:1000,
      content: (
        <div
          style={{ textAlign:"center",width:"1000px",height:"70px",display:"flex",justifyContent:"center",alignItems:"center" }}
        >
          Thanks for contacting us. A member of our team will get in touch with you shortly.
          
        </div>
      ),
    });
  };

  const splitWords = (item) => {
    let words = item.trim();
    
    let splitIndexarray = []; // Initialize with 0
  
    for (let i = 0; i < words.length; i++) {
      let character=words.charAt(i)
      if(character === character.toUpperCase()){
     
        splitIndexarray=[...splitIndexarray,i]
      }
     
    }
    
    if (splitIndexarray.length > 1) {
      let newword = "";
      for (let i = 0; i < splitIndexarray.length; i++) {
        if (i === 0) {
          let newWord = words.slice(0, splitIndexarray[i+1]);
          newword = newword+ newWord + " ";
        } else {
          let lastval = (i === splitIndexarray.length - 1) ? words.length : splitIndexarray[i + 1];
          let newWord = words.slice(splitIndexarray[i], lastval);
          newword = newword + newWord + " ";
        }
      }
   
      return newword;
    }
    return words;
  };
  

  
  
  return (
    <PropertyCardContainer 
    onMouseEnter={(e) => {
      handleMouseEnter(index, e, item);
      
      setcurrentHoverItem(item);
      
    }}
    onMouseLeave={handleMouseLeave}
    > 
    <Toast ref={toast} />
       <ToolTip
          top={`${tooltipPosition.top}px`} //{ `${tooltipPosition.top}px`}   //"205px"
          left={`${tooltipPosition.left}px`} //"1200px"//{`${tooltipPosition.left}px`}
          width="auto"
          show={Hovering} //Hovering
          borderRadius={"20px"}
          txt={ 
            <div
            className="propertyPopUpContainer"
              style={{
                width: "400px",
                height: "auto",
                display: "flex",
                justifyContent:"center"
               
              }}
            >
              {currentHoverItem?.medianPropertyType=="residential" ?
              <Row style={{ width: "100%" }}>
              {/* <Col sm={12}>
                <div>
                  <img 
                    src={currentHoverItem["0"]?.listing?.media[0]?.url}
                    style={{ width: "100%", height: "200px",borderRadius:"12px" }}
                  />
                  
                </div>
              </Col> */}
              <Col sm={11}>
                <div style={{ width: "100%",marginTop:"10px" }}>
                  <div
                    className="PropertyRightContainer"
                    style={{ textAlign: "left", width: "100%" }}
                  >
                    {/* <p className="boldtxt" style={{ marginLeft: "10px" }}>
                      {currentHoverItem["0"]?.listing?.headline}
                    </p> */}
                    
                    <p className="boldtxt" style={{ marginLeft: "10px",marginTop:'15px' }}>
                      Property Details
                    </p>
                    <div
                      className="PropertyDetails"
                      style={{ marginLeft: "10px", width: "100%" }}
                    >
                      <div>
                        <p>Property Type</p>
                        <p>
                          {splitArrayItems(
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.allPropertyTypes
                          )}
                        </p>
                      </div>
                      <div>
                        <p>Display Price</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.priceDetails
                              ?.displayPrice
                          }
                        </p>
                      </div>
                      <div>
                        <p>Area</p>
                        <p>
                          {currentHoverItem["0"]?.listing?.propertyDetails?.area}
                        </p>
                      </div>
                      <div>
                        <p>Bathrooms</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.bathrooms
                          }
                        </p>
                      </div>
                      <div>
                        <p>Bedrooms</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.bedrooms
                          }
                        </p>
                      </div>
                      <div>
                        <p>Carspaces</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.carspaces
                          }
                        </p>
                      </div>
                      <div>
                        <p>Land Area</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.landArea
                          }
                        </p>
                      </div>
                      <div>
                        <p>Postcode</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.postcode
                          }
                        </p>
                      </div>
                      <div>
                        <p>Region</p>
                        <p>
                          {currentHoverItem["0"]?.listing?.propertyDetails?.region}
                        </p>
                      </div>
                    </div>
             {
             currentHoverItem["0"]?.listing?.propertyDetails?.features?.length>0 &&
             <p className="boldtxt" style={{ marginLeft: "10px",marginTop:"10px" }}>
             Property Features
           </p>
             }      
              
                    <ul
                      style={{
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginTop: "3px",
                        gap: "10px",
                        width:"100%",
                        marginLeft:"10px"
                       
                      }}
                    >
                      <Row style={{ width: "100%", gap: "0px" }}>
                        {currentHoverItem["0"]?.listing?.propertyDetails?.features?.map(
                          (item) => {
                            return (
                              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                <li>
                                  <p>{splitWords(item)}</p>
                                </li>
                              </Col>
                            );
                          }
                        )} 
                      </Row>
                    </ul>
                    <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <GradientButton
                      style={{
                        width: "180px !important",
                        height: "40px",
                      }}
                      width="180px"
                      txt="Contact Us Now"
                      onClick={SendEmail}
                    />
                  </div>
                  </div>
                </div>
              </Col>
            </Row>
            :
            <Row style={{ width: "100%"}}>
                {/* <Col sm={12} >
                  <div style={{width:"100%"}}>
                    <img
                      src={currentHoverItem?.listing?.media[0]?.imageUrl}
                      style={{ width: "100%", height: "200px",borderRadius:"12px" }}
                    />
                  </div>
                </Col> */}
                <Col sm={11}>
                  <div style={{ width: "100%",marginTop:"10px" }}>
                    <div
                      className="PropertyRightContainer" 
                      style={{ textAlign: "left", width: "100%" }}
                    >
                      {/* <p className="boldtxt" style={{ marginLeft: "10px" }}>
                        {currentHoverItem?.listing?.headline}
                      </p> */}
                     
                      <p className="boldtxt" style={{ marginLeft: "10px",marginTop:"15px" }}>
                        Property Highlights
                      </p>
                      <div
                        className="PropertyDetails"
                        style={{ marginLeft: "10px", width: "100%" }}
                      >
                        <div>
                          <p>Floor Area</p>
                          <p>
                            {currentHoverItem?.listing?.propertyArea}
                          </p>
                        </div>
                        <div>
                          <p>Parking</p>
                          <p>
                            {
                              currentHoverItem?.listing?.carspaceCount

                            } x Onsite parking
                          </p>
                        </div>
                        
                        <div>
                          <p>Availability</p>
                          <p>
                          {"Vacant"}
                          </p>
                        </div>
                        <div>
                          <p>Category</p>
                          <p>
                          {
                            (currentHoverItem?.listing?.propertyType)
                            }
                          </p>
                        </div>
                        {/* <div>
                       
                          <p>Gross Rent</p>
                          <p>
                          {CurrencyFormatter.format(currentHoverItem?.listingData?.GrossRent)}
                          </p>
                        </div> */}
                        {/* <div>
                          <p>TDR</p> 
                          <p>
                          {CurrencyFormatter.format(currentHoverItem?.listingData?.tdr)}
                          </p>
                        </div> */}
                      </div>
                      
                      <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <GradientButton
                        style={{
                          width: "180px !important",
                          height: "40px",
                        }}
                        width="180px"
                        txt="Contact Us Now"
                        onClick={SendEmail}
                      />
                    </div>
                    </div>
                  </div>
                </Col>
              </Row>
              }
              
            </div>
          }
        />
      <Image src={img} />
      <PropertyBox>
        <span>Property {number}</span>

        <PropertyContent pathname={pathname} admin={admin} idx={number} style={{ marginTop: "-10px" }}>
          <div className="Property-Content-header"> 
            <Image
              src={open === true ? OpenImg : CloseImg}
              onClick={() => setOpen(!open)}
            />
          </div>
          <AnimatedHeight duration={500} height={height}>
            <div className="Propery-body">
              <div className="column">
                <div className="column-content">
                  <p>Property Type</p>
                  <h1>{propertyType}</h1>
                </div>

                <div className="column-content">
                  <p>LVR</p>
                  <h1>{LVR}</h1>
                </div>
                <div className="column-content">
                  <p>
                    {propertyType === "Residential"
                      ? "Net Yield"
                      : "Net Yield"}
                  </p>
                  <h1>{(((Number(netrental)/(Number(item?.listingData?.purchasePrice)))*100)||0).toFixed(2)}%</h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Purchase Price</p>
                  <h1>{PurchasePrice}</h1>
                </div>
                <div className="column-content">
                  <p>Interest Rate</p>
                  <h1>{InterestRate}</h1>
                </div>
                <div className="column-content">
                  <p> Cashflow Annual</p>
                  <h1>{NetCashflow}</h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Deposit Required</p>
                  <h1>{Deposit}</h1>
                </div>

                <div className="column-content">
                  <p>Net Rental Income(Estimated)</p>
                  <h1>{GrossRentalIncome}</h1>
                </div>
                <div className="column-content">
                  <p>Risk Profile</p>
                  <h1 style={{ color: progressColor }}>{RiskProfile==5?'Conservative':RiskProfile==6?'Moderate':'Aggressive'}</h1>
                </div>
              </div>
            </div>

          </AnimatedHeight>
        </PropertyContent>
      </PropertyBox>
    </PropertyCardContainer>
  );
};

export default PropertyCard;
