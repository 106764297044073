const initialState=0;

const MBC=(state=initialState,action)=>{
    switch (action.type) {
        case "SET_MBC":
            state=action.payload
            return state
        default:
         return state;
    }
}
export default MBC