import React, { useState, useEffect, useRef } from "react";
import { UserContainer } from "./UserProfile.style";
import Avatar from "../Avatar/Avatar";

import { API } from "../../services/api";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../../redux/actions";
import GradientButton from "../Button/GradientButton";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const UserProfile = () => {
  const [type, setType] = useState("");
  const toast = useRef(null);
  const UserData = useSelector((state) => state.UserProfile);
  let dispatch = useDispatch();
  const [oldpassword, setoldpassword] = useState();
  const [newpassword, setnewpassword] = useState();
  const [userdata, setuserdata] = useState(UserData);

  const [photo, setPhoto] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "mobile_no") {
      const formatNumber = formatPhoneNumber(e.target.value);
      setuserdata({ ...userdata, [e.target.name]: formatNumber });
    } else if (e.target.name === "telephone_no") {
      const formatNumber = formatTelephoneNumber(e.target.value);
      setuserdata({ ...userdata, [e.target.name]: formatNumber });
    } else {
      setuserdata({ ...userdata, [e.target.name]: e.target.value });
    }
  };

  let officeId = "610a0f1dc61edfcf62d62c52";

  let updatePassword = async () => {
    let res = await API.post(`auth/change-password`, {
      oldpassword: oldpassword,
      password: newpassword,
    });
    if (res.status === true) {
      setType("success");
      NotificationManager.success(res.message);
    } else {
      setType("error");
      NotificationManager.error(res.message);
    }
  };

  let UpdateData = async () => {
    if (
      userdata?.first_name !== "" ||
      userdata?.last_name !== "" ||
      userdata?.email !== ""
    ) {
      let payload = {
        name: "PropVesting",
        first_name: userdata?.first_name,
        last_name: userdata?.last_name,
        email: userdata?.email,
        mobile: userdata?.mobile_no,
        phone: userdata?.telephone_no,
        logos: photo,
      };
      let res = await API.put(`/offices/${officeId}`, payload);
      if (res.status === true) {
        dispatch(setProfileData({ ...payload }));  
        setType("success");
        NotificationManager.success("Profile Updated Successfully");
      } else {
        setType("error");
        NotificationManager.error(res.message, "Error");
      }
    } else {
      setType("error");
      NotificationManager.error("Required Fields Cannot Be Empty", "");
    }
  };
  //6396a529ebadae2b3ea92e57
  const getuserData = async () => {
    let id = sessionStorage.getItem("userId");
    let { data } = await API.get(`contacts/getbyid/${id}`);
    if (data) {
      setPhoto(UserData.photo);
      setuserdata(data);
    }
  };
  useEffect(() => {
    getuserData();
  }, []);
  // useEffect(() => {
  //   setuserdata({ ...UserData });
  // }, [UserData]);

  const formatPhoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
        4,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
      4,
      7
    )} ${phoneNumber.slice(7, phoneNumber.length)}`;
  };

  const formatTelephoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 2) return phoneNumber;
    if (phoneNumber.length < 6) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      6
    )} ${phoneNumber.slice(6, phoneNumber.length)}`;
  };

  // useEffect(() => {
  //   setPhoto(
  //     UserData?.logos?.replace(
  //       /https:\x2F\x2Fpropvesting-xm.s3.us-east-2.amazonaws.com\x2F/g,
  //       ""
  //     )
  //   );
  // }, [UserData]);

  return (
    <>
      <UserContainer>
        <Toast ref={toast} />
        <div className="profile-container">
          <h1>Profile</h1>

          <div className="avatar-container">
            <Avatar
              text="CH"
              width="80px"
              height="80px"
              fontSize="23px"
              textColor="#fefefe"
              imgSrc={photo}
              setImgSrc={setPhoto}
              setuserdata={setuserdata}
              data={userdata}
              userProfile={true}
            />

            <div className="avatar-text-container">
              <p className="main-text">
                {userdata?.first_name}&nbsp;{userdata?.last_name}
              </p>
            </div>
          </div>
        </div>

        <div>
          <Row className="py-3">
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">First name</p>
                <InputText
                  type={"text"}
                  name="first_name"
                  className=" edit-input-field"
                  value={userdata?.first_name}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Last name</p>
                <InputText
                  type={"text"}
                  className=" edit-input-field"
                  name="last_name"
                  value={userdata?.last_name}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Email</p>
                <InputText
                  type={"email"}
                  className=" edit-input-field"
                  name="email"
                  value={userdata?.email}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row className="py-3">
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Mobile No</p>
                <InputText
                  type={"text"}
                  name="mobile_no"
                  value={userdata?.mobile_no}
                  onChange={handleChange}
                  className=" edit-input-field"
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Phone Number</p>
                <InputText
                  type={"text"}
                  name="telephone_no"
                  value={userdata?.telephone_no}
                  onChange={handleChange}
                  className=" edit-input-field"
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <GradientButton
                  style={{ height: "36px", marginTop: "28px" }}
                  txt={"Update"}
                  onClick={UpdateData}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-4 profile-container">
          <h1>Update Password</h1>
        </div>
        <div>
          <Row className="py-3">
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Old Password</p>
                <InputText
                  type={"password"}
                  name="oldpassword"
                  className=" edit-input-field"
                  value={oldpassword}
                  onChange={(e) => setoldpassword(e.target.value)}
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">New Password</p>
                <InputText
                  type={"password"}
                  className=" edit-input-field"
                  name="password"
                  value={newpassword}
                  onChange={(e) => setnewpassword(e.target.value)}
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <GradientButton
                  style={{ height: "36px", marginTop: "28px" }}
                  txt={"Update"}
                  onClick={updatePassword}
                />
              </div>
            </Col>
          </Row>
        </div>
      </UserContainer>

      {type && <NotificationContainer />}
    </>
  );
};

export default UserProfile;
