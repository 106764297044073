import {
  NavSubContainer,
  SubNavItem,
  SubNavItemContainer,
  SubNavText,
} from "./sidebar.styles";
import { useNavigate } from "react-router-dom";
import { GS } from "../../services/global";
import PlusIcon from "../../assets/images/plus.svg";
import MinusIcon from "../../assets/images/minus.svg";
import { ToggleSubSidebar } from "../../redux/actions";

import AnimateHeight from "react-animate-height";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import AnimatedHeight from "../AnimateHeight";

const SubSidebarItem = ({ item, activePath }) => {

  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.SubSidebar);
  const height = open[item.name] ? "auto" : 0;

  const navigate = useNavigate();
  const newlink = item.name;
  const result = newlink === activePath;

  const handleNavigate = (link) => {
    let x = link.includes("/Clients/");
    if (x === true) {
      navigate(`${link}?id=${GS.SOT.VendorId}`);
    } else {
      navigate(link);
    }
  };

  return (
    <div>
      <SubNavItemContainer
        onClick={() => handleNavigate(item.link)}
        active={result}
        activeColor={item.activeColor}
        style={{ marginBottom: "1px" }}
       
      >
        <SubNavItem>
          <SubNavText>{item.name}</SubNavText>
        </SubNavItem>
        {item.subItems && (
          <Image
            src={open[item.name] === false ? PlusIcon : MinusIcon}
            onClick={(e) => {
              e.stopPropagation();
              let prop = item.name;
              open[prop] = !open[prop];
              dispatch(ToggleSubSidebar({ ...open }));
            }}
          />
        )}
      </SubNavItemContainer>
      <AnimatedHeight duration={500} height={height}>
        <NavSubContainer>
          {item.subItems &&
            item.subItems.map((subitem, index) => (
              <SubSidebarItem item={subitem} key={index} />
            ))}
        </NavSubContainer>
      </AnimatedHeight>
    </div>
  );
};

export default SubSidebarItem;
