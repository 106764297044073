import React, { useState, useEffect } from "react";

export const useFetchPinderSummary = (url) => {
    const[Total,setTotal]=useState(0)
    const[Propertyoverview,setPropertyoverview]=useState([])
  useEffect(() => {
    const CallingApi = async () => {
      let result = await fetch(`${process.env.REACT_APP_API_BASEURL}${url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => data);
      let total = result.data.reduce((prevItem, nexItem) => {
        prevItem = prevItem + nexItem.total;
        return prevItem; 
      }, 0);
      setTotal(total)
     
      setPropertyoverview(result.data);
    };
    CallingApi();
  }, []);
  return {Total,Propertyoverview  };
};
