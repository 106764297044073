let initialState="Propvesting";
// initialState="Investlogic";
const ThemePattern=(state=initialState,action)=>{
    switch (action.type) {
        case "SET_THEME_DATA":
            state=action.payload
            return state
            case "GET_THEME_DATA":
               
                return state
        default:
         return state;
    }
}
export default ThemePattern