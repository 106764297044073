import { useNavigate, useSearchParams } from "react-router-dom";
import { CurrencyFormatter } from "../../utils";
import { Card, Circle, TimelineContainer } from "./IncomePlan.style";
import ToolTip from "../ToolTip/ToolTip";
import { useEffect, useState } from "react";
import Pic from "../../assets/images/incomePlan.png";
import GradientButton from "../Button/GradientButton";
import { Row, Col } from "react-bootstrap";
import { commaSeparators } from "../../utils";
const IncomePlanTimeline = ({ data, admin }) => {
  const navigate = useNavigate();
  const [currentHoverItem, setcurrentHoverItem] = useState();
  const [Hovering, setHovering] = useState(false);
  const [searchParams] = useSearchParams();
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  let id = searchParams.get("id");
  let accountType = sessionStorage.getItem("accountType");

  const getDifference = (date) => {
    const yearDifference =
      new Date(date).getFullYear() - new Date().getFullYear();
    // const monthDifference = new Date(date).getMonth() - new Date().getMonth();
    return yearDifference;
    //  + monthDifference;
  };

  const calculateCFM = (idx) => {
    let result = 0;
    for (let i = 0; i <= idx; i++) {
      result += data[i].listingData
        ? data[i].listingData.CFA / 12 
        : data[i].summaryData.CashflowMonthly;
    }
    return isNaN(result) ? 0 : result;
  };
  const handleMouseEnter = (idx, event, currentItem) => {
    if(currentItem?.medianPropertyType=="residential"){
      if(currentItem["0"]?.listing?.media?.length>0){
        setHovering(true);
      }
    }else{
      if(currentItem?.listing?.media?.length>0){
        setHovering(true);
      }
    }
    
    

    const rect = event.currentTarget.getBoundingClientRect();

    setTooltipPosition({
      top: idx % 2 == 0 ? 230 : 70,
      left: idx==0 || idx==1? rect.left - 340:rect.left - 640,
    });
  };
  
  const handleMouseLeave = () => {
    setHovering(false);
  };

  const splitArrayItems = (items) => {
    let currStr = "";
    items?.map((item) => {
      currStr = currStr + " " + item;
    });
    return currStr;
  };
  useEffect(()=>{
console.log(currentHoverItem,"current")
  },[currentHoverItem])
  return (
    <>
      <div className="relative">
        <ToolTip
          top={`${tooltipPosition.top}px`} //{ `${tooltipPosition.top}px`}   //"205px"
          left={`${tooltipPosition.left}px`} //"1200px"//{`${tooltipPosition.left}px`}
          width="auto"
          show={Hovering} //Hovering
          borderRadius={"20px"}
          txt={
            <div
            className="propertyPopUpContainer"
              style={{
                width: "400px",
                height: "auto",
                display: "flex",
                justifyContent:"center"
               
              }}
            >
              {currentHoverItem?.medianPropertyType=="residential" ?
              <Row style={{ width: "100%" }}>
              <Col sm={12}>
                <div>
                  <img
                    src={currentHoverItem["0"]?.listing?.media[0]?.url}
                    style={{ width: "100%", height: "200px",borderRadius:"12px" }}
                  />
                  
                </div>
              </Col>
              <Col sm={11}>
                <div style={{ width: "100%",marginTop:"10px" }}>
                  <div
                    className="PropertyRightContainer"
                    style={{ textAlign: "left", width: "100%" }}
                  >
                    <p className="boldtxt" style={{ marginLeft: "10px" }}>
                      {currentHoverItem["0"]?.listing?.headline}
                    </p>
                    <ul style={{ textAlign: "left" }}>
                      <li>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.extractedPrice
                          }
                        </p>
                      </li>
                      <li>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails?.displayableAddress
                          }
                        </p>
                      </li>
                      <li>
                        <p>{currentHoverItem["0"]?.listing?.listingType}</p>
                      </li>
                    </ul>
                    <p className="boldtxt" style={{ marginLeft: "10px",marginTop:'-15px' }}>
                      Property Details
                    </p>
                    <div
                      className="PropertyDetails"
                      style={{ marginLeft: "10px", width: "100%" }}
                    >
                      <div>
                        <p>AllPropertyTypes</p>
                        <p>
                          {splitArrayItems(
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.allPropertyTypes
                          )}
                        </p>
                      </div>
                      <div>
                        <p>DisplayPrice</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.priceDetails
                              ?.displayPrice
                          }
                        </p>
                      </div>
                      <div>
                        <p>Area</p>
                        <p>
                          {currentHoverItem["0"]?.listing?.propertyDetails?.area}
                        </p>
                      </div>
                      <div>
                        <p>Bathrooms</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.bathrooms
                          }
                        </p>
                      </div>
                      <div>
                        <p>Bedrooms</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.bedrooms
                          }
                        </p>
                      </div>
                      <div>
                        <p>Carspaces</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.carspaces
                          }
                        </p>
                      </div>
                      <div>
                        <p>LandArea</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.landArea
                          }
                        </p>
                      </div>
                      <div>
                        <p>Postcode</p>
                        <p>
                          {
                            currentHoverItem["0"]?.listing?.propertyDetails
                              ?.postcode
                          }
                        </p>
                      </div>
                      <div>
                        <p>Region</p>
                        <p>
                          {currentHoverItem["0"]?.listing?.propertyDetails?.region}
                        </p>
                      </div>
                    </div>
             {
             currentHoverItem["0"]?.listing?.propertyDetails?.features?.length>0 &&
             <p className="boldtxt" style={{ marginLeft: "10px",marginTop:"10px" }}>
             Property Features
           </p>
             }      
              
                    <ul
                      style={{
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginTop: "3px",
                        gap: "10px",
                        width:"100%",
                       
                      }}
                    >
                      <Row style={{ width: "100%", gap: "0px" }}>
                        {currentHoverItem["0"]?.listing?.propertyDetails?.features?.map(
                          (item) => {
                            return (
                              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                <li>
                                  <p>{item}</p>
                                </li>
                              </Col>
                            );
                          }
                        )} 
                      </Row>
                    </ul>
                    <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <GradientButton
                      style={{
                        width: "180px !important",
                        height: "40px",
                      }}
                      width="180px"
                      txt="Contact Us Now"
                    />
                  </div>
                  </div>
                </div>
              </Col>
            </Row>
            :
            <Row style={{ width: "100%"}}>
                <Col sm={12} >
                  <div style={{width:"100%"}}>
                    <img
                      src={currentHoverItem?.listing?.media[0]?.imageUrl}
                      style={{ width: "100%", height: "200px",borderRadius:"12px" }}
                    />
                  </div>
                </Col>
                <Col sm={11}>
                  <div style={{ width: "100%",marginTop:"10px" }}>
                    <div
                      className="PropertyRightContainer" 
                      style={{ textAlign: "left", width: "100%" }}
                    >
                      <p className="boldtxt" style={{ marginLeft: "10px" }}>
                        {currentHoverItem?.listing?.headline}
                      </p>
                      <ul style={{ textAlign: "left" }}>
                        <li>
                          <p>
                            {
                              currentHoverItem?.listing?.propertyType
                            }
                          </p>
                        </li> 
                        <li>
                          <p>
                            {
                              currentHoverItem?.listing?.propertyArea
                            }
                          </p>
                        </li>
                        <li>
                          <p>{currentHoverItem?.listing?.price}</p>
                        </li>
                      </ul>
                      <p className="boldtxt" style={{ marginLeft: "10px",marginTop:"-15px" }}>
                        Property Highlights
                      </p>
                      <div
                        className="PropertyDetails"
                        style={{ marginLeft: "10px", width: "100%" }}
                      >
                        <div>
                          <p>Floor Area</p>
                          <p>
                            {currentHoverItem?.listing?.propertyArea}
                          </p>
                        </div>
                        <div>
                          <p>Cars Space Count</p>
                          <p>
                            {
                              currentHoverItem?.listing?.carspaceCount

                            }
                          </p>
                        </div>
                        <div>
                          <p>Acquisition Cost</p>
                          <p>
                            {
                              currentHoverItem?.listingData?.AcquisitionCost
                            }
                          </p>
                        </div>
                        <div>
                          <p>CFA</p>
                          <p>
                            { currentHoverItem?.listingData?.CFA?.toFixed(2)}
                          </p>
                        </div>
                        <div>
                          <p>GrossRent</p>
                          <p>
                            {currentHoverItem?.listingData?.GrossRent?.toFixed(2)}
                          </p>
                        </div>
                        <div>
                          <p>TDR</p>
                          <p>
                            {currentHoverItem?.listingData?.tdr?.toFixed(2)}
                          </p>
                        </div>
                      </div>
                      
                      <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <GradientButton
                        style={{
                          width: "180px !important",
                          height: "40px",
                        }}
                        width="180px"
                        txt="Contact Us Now"
                      />
                    </div>
                    </div>
                  </div>
                </Col>
              </Row>
              }
              
            </div>
          }
        />
      </div>
      <TimelineContainer idx={data.length}>
        <div className="timeline relative mb-2">
          {data.map((item, idx) => {
            
            return (
              <>
                <Circle idx={idx} length={data.length} />
                <Card
                  admin={admin}
                  idx={idx}
                  length={data.length}
                  // onMouseEnter={(e) => {
                  //   handleMouseEnter(idx, e, item);
                  //   setcurrentHoverItem(item);
                  // }}
                  // onMouseLeave={handleMouseLeave}
                >
                  <div>
                    <p>
                      {item.nextDate
                        ? getDifference(item.nextDate) <= 1
                          ? getDifference(item.nextDate) + " Year"
                          : getDifference(item.nextDate) + " Years"
                        : ""}
                    </p>
                    <p>
                      <a
                        style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        href={
                          accountType != "Contacts" ? item.listingData?.ad : "#" //
                        }
                        target={accountType != "Contacts" ? "_blank" : "_self"}
                      >
                        <strong>Purchase property {idx + 1}</strong>
                      </a>
                    </p>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <p>{item.nextDate}</p>
                      <p>
                        {CurrencyFormatter.format(calculateCFM(idx))}
                        (CFM)
                      </p>
                    </div>
                  </div>
                </Card>
              </>
            );
          })}
        </div>
      </TimelineContainer>
    </>
  );
};

export default IncomePlanTimeline;
