import React, { useState, useEffect } from 'react';
import "./animate.css"
import { CustomAnimatedHeight } from '../SideBar/sidebar.styles';
const AnimatedHeight = ({ children, height,minheight}) => {
  const [height1, setHeight] = useState(height);
  
  useEffect(() => {
   
    setHeight(height);
  }, [height]);

  return <div className={`section ${height1!==0 ?'open':'close'} `} style={minheight &&{minHeight:minheight}}>{children}</div>;
};

export default AnimatedHeight;