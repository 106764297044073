import pg1 from "../../assets/pg1-min.png";
import pg2 from "../../assets/pg2-min.png";
import pg7 from "../../assets/pg7-min.png";
import pg4 from "../../assets/pg4-min.png";
export const pagesList = [
  // Page No 1
  {
    "meta": {},
    "printBoard": {
      "trim": "7.408",
      "bleed": "4.408",
      "style": {
        "width": "637.28pt",
        "border": "0px",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "154pt",
          "left": "79pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "30pt",
          "fontSize": "34pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Strategic",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "196pt",
          "left": "79pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "30pt",
          "fontSize": "34pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "237pt",
          "left": "79pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "30pt",
          "fontSize": "34pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Plan",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "310pt",
          "left": "79pt",
          "color": "#00c4f9,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "19pt",
          "fontSize": "22pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Transform your life",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "79pt",
          "color": "#00c4f9,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "19pt",
          "fontSize": "22pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "financially",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694489734.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "12pt",
          "left": "12pt",
          "width": "613pt",
          "height": "859pt"
        },
        "attributes": {
          "ratio": 1.6612466124661247,
          "isDrag": true,
          "isAsset": true,
          "category": "image",
          "isBackdrop": true
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 456,
          "y1": 0,
          "y2": 274.4926590538337
        }
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "12pt",
          "left": "49pt",
          "width": "341pt",
          "height": "377pt",
          "backgroundColor": "#121d47,1,0.93,0.40,0.45"
        },
        "attributes": {}
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694144592.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "65pt",
          "left": "76pt",
          "width": "256pt"
        },
        "attributes": {
          "order": 2,
          "category": "image"
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "280.5pt",
          "left": "79pt",
          "width": "58pt",
          "height": "2.5pt",
          "backgroundColor": "#00c4f9,0.65,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "389pt",
          "left": "49pt",
          "width": "341pt",
          "height": "14pt",
          "backgroundColor": "#00c4f9,0.65,0.01,0.0,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Page No 2
  {
    "meta": {},
    "printBoard": {
      "trim": "7.408",
      "bleed": "4.408",
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "232pt",
          "left": "1065pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "115pt",
          "height": "222pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.01pt"
        },
        "value": "<p>Our blended strategy encompasses both residential and commercial assets, allowing you to envision not just buying your first home but also cultivating a portfolio geared towards wealth generation. With us, this aspiration becomes attainable.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "111pt",
          "left": "47pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "181.75pt",
          "height": "33.25pt",
          "fontSize": "36pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Welcome ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "191pt",
          "left": "47pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "200pt",
          "height": "459pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.07pt"
        },
        "title": "Listing Sub Heading",
        "value": "<p>Welcome to Australia's pioneering Property Portfolio Plan, driven by AI and supported by the nation's leading property sales site. Our plan guides you towards your financial goals, offering a dynamic roadmap for property investing in residential, commercial, or other sectors. Utilizing your financial status, it displays live property listings and aids in identifying investment opportunities, setting and tracking goals. It also supplies a framework for analyzing risk-adjusted plans. With real-time valuation data and strategy adjustment tools, our property portfolio plan empowers you to act as the 'Asset Manager.' Partner with our team to optimize your property investment strategy for 2023 and beyond.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "113pt",
          "left": "644pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "439.75pt",
          "height": "24pt",
          "fontSize": "22pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Who could the plan help?  ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "142pt",
          "left": "644pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "470pt",
          "height": "42.75pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.01pt"
        },
        "title": "Listing Sub Heading",
        "value": "<p>Individuals seeking a long-term investment opportunity that has the potential to grow in value and produce passive income via rent payments from commercial enterprises.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "230pt",
          "left": "694pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "130pt",
          "height": "14pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "letterSpacing": "0.05pt"
        },
        "value": "Property Owners",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "232pt",
          "left": "694pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "130pt",
          "height": "222pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.7,
          "letterSpacing": "0.01pt"
        },
        "value": "<p>By leveraging the Utilize the hidden value in your property through an equity loan or line of credit to make investments. These investments can yield returns greater than the loan interest, enhancing your overall wealth.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "230pt",
          "left": "878pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "130pt",
          "height": "14pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "letterSpacing": "0.05pt"
        },
        "value": "Property Investors",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "232pt",
          "left": "878pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "115pt",
          "height": "222pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.01pt"
        },
        "value": "<p>With a focus on a singular Lifestyle Goal, this plan enables growth from your current position, a unique approach not common among many investors. By visualizing your portfolio on our dashboard, you can preview what your future investment landscape might resemble.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "230pt",
          "left": "1065pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "130pt",
          "height": "14pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "letterSpacing": "0.05pt"
        },
        "value": "First Home Buyers",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694485557.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "231pt",
          "left": "1013pt",
          "width": "44pt",
          "height": "44pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694485572.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "231pt",
          "left": "826pt",
          "width": "44pt",
          "height": "44pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694418884.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "12pt",
          "left": "311pt",
          "width": "305pt",
          "height": "859pt"
        },
        "attributes": {
          "ratio": 1.6612466124661247,
          "isDrag": true,
          "isAsset": true,
          "category": "image",
          "isBackdrop": true
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 456,
          "y1": 0,
          "y2": 274.4926590538337
        }
      },
      {
        "id": "asset-image-12",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694418844.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "474pt",
          "left": "616pt",
          "width": "604pt",
          "height": "397pt"
        },
        "attributes": {
          "ratio": 1.6612466124661247,
          "isDrag": true,
          "isAsset": true,
          "category": "image",
          "isBackdrop": true
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 456,
          "y1": 0,
          "y2": 274.4926590538337
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694485590.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "231pt",
          "left": "642pt",
          "width": "44pt",
          "height": "42pt"
        },
        "attributes": {
          "isLogo": true
        }
      }
    ]
  },
  // Page No 3
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "457pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "55.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Cashflow represents the profitability of a real estate asset, computed as the property's total revenues minus operating expenses. These expenses include municipal charges, property taxes, insurance, management costs, debt servicing, and capital expenditures.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "436pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Cash",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "366pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "55.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Cashflow represents the profitability of a real estate asset, computed as the property's total revenues minus operating expenses. These expenses include municipal charges, property taxes, insurance, management costs, debt servicing, and capital expenditures.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "345pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Cash",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "295pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This signifies the full amount a borrower pays for a loan, incorporating the principal, interest, and any related fees or charges. It calculates the lending for both 'Debt' & 'Equity' loans that may apply.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "274pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Total Cost of Lending ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "223pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The loan-to-value ratio (LTV) is the mortgage loan amount expressed as a percentage of the property's value. It's a critical metric in real estate lending and mortgage underwriting, assessing the risk of a mortgage loan.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "202pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Loan to Value Ration {LVR) ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "170pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "25.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This term refers to the portion of the total deposit required, funded through an Equity Release.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "149pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Total Deposit Equity ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "103pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>These encompass any extra expenses incurred during the acquisition process, such as stamp duty, legal fees, agent  commissions and miscellaneous costs.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "82pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Acquisition Costs ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "736pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Yield measures investment returns, showing the income an investment produces (like interest or dividends) as a percentage of the investment's cost.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "715pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Risk Factored Yield Return   ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "663pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Yield measures investment returns, showing the income an investment produces (like interest or dividends) as a percentage of the investment's cost.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "642pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Yield  ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "591pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Equity represents the unencumbered portion of a property's value, such as owning a $500,000 home with a remaining mortgage balance of $300,000, resulting in $200,000 equity.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "570pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Equity ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "538pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "25.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>An equity loan lets borrowers use their home's equity as collateral, providing access to additional funds.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "517pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Equity Release ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "464pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Also known as Principal Gain, this refers to the funds you apply to your loan that decrease the loan amount. It's the principal part of your mortgage payment.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "443pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Equity Gain ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "24pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Terms & References ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "82pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Lifestyle Goal ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "103pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "51.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Our Lifestyle Goal refers to a specific long-term financial target needed to support your desired lifestyle for you and your family. While it may broadly encompass objectives like healthier living, stress reduction, or new hobbies, it's specifically quantified as the pre-tax amount necessary to fund your chosen way of life.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "173pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Portfolio Makeup ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "194pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Portfolio Makeup outlines the particular blend of investments that constitute your customized investment portfolio. It defines the unique combination of assets tailored to your needs.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "245pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Australian Average Expenses",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "266pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This term refers to the Household Expenditure Measure (HEM), a standard benchmark some banks utilize to assess annual living expenses during home loan application evaluations.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "319pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "RRR",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "340pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "15.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Principal Place of Residence </p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "372pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Capital Growth ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Capital growth signifies the appreciation in an asset's value over time. This growth can be leveraged through selling the asset at a higher price than the purchase cost or through an equity release. </p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-image-1",
        "src": "https://propvesting-xm.s3.us-east-2.amazonaws.com/custom/image+6.png",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "529pt",
          "left": "615pt",
          "width": "605pt",
          "height": "342pt"
        },
        "attributes": {
          "ratio": 0.7480106100795756,
          "isDrag": true,
          "isAsset": true,
          "category": "image"
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 294.7252740775269
        }
      }
    ]
  },
  // Page No 4
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "334pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "54.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The calculator's results are estimates based on your input and should not replace professional financial advice. The results' accuracy depends on the information's precision. Consult a financial expert for tailored guidance.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "314pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Data Entry ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "249pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "54.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Though driven by Investlogic algorithms, the purchase prices in the calculator are sourced from live data on Domain.com.au and Commercialrealestate.com.au. This ensures that the highlighted properties, along with the rental income, are realistic, making the plan more actionable and comprehensive.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "229pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Property Pricing & Images ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "108pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>You're free to select any Property Type for investment. Don't be concerned by the initial types in your plan; they can be tailored to your liking.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "159pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Growth Hacking ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "179pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Our Property Optimisation team employs this strategy, using our proprietary techniques to generate positive Capital Gains quickly. This section showcases the effectiveness of our growth hacking methods.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "82pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Property Type ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "405pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "74.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The time between properties varies, based on Capital Growth of existing assets and saving ability. Since these factors are vital for future acquisitions, and the plan revolves around re-investing equity, the timeline can significantly affect reaching your Lifestyle Goal. However, the calculator may also allow inputs like inheritance or profit from asset sales, having a substantial impact on the timeline.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "385pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Time ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "334pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Using data from Domain, our calculator initially identifies key growth suburbs, forming the base of your property portfolio. You can adjust these to any preferred suburb with complete app access.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "314pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Selected Suburbs ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "265pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The first property ID leverages your Usable Equity, fully drawn at 100%, to ascertain the Purchase Price. Subsequent property purchases utilize both Risk Factored Yield Returns and rents from chosen suburbs.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "245pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "First Property ID ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "179pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "54.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This term defines the largest amount a person or business can borrow, based on factors like income, assets, and creditworthiness. In this context, it's determined by dividing income by existing debts or liabilities to produce the Debt to Income Ratio, then multiplying by income to give the Max Borrowing Capacity (MBC).</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "159pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Max Borrowing Capacity ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "24pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Critical Assumptions ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "88pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Usable Equity  ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "108pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Usable equity refers to the equity accessible for borrowing or investment purposes. It's calculated by subtracting outstanding property debts from 80% of the total value of property assets plus cash.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "real",
        "src": "https://propvesting-xm.s3.us-east-2.amazonaws.com/custom/image+7.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "550pt",
          "left": "12pt",
          "width": "1208pt",
          "height": "321pt"
        },
        "attributes": {
          "isLogo": true
        }
      }
    ]
  },
  // Page No 5 using in ts
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "845pt",
          "left": "1181pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$100,000.00",
        "attributes": {
          "ref": "yourIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "422pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "24pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "581pt",
          "height": "43.25pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Your Property Portfolio Plan Pathway",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "81pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Identify",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "107pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "559.75pt",
          "height": "67.75pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "title": "Listing Sub Heading",
        "value": "<p>Understand your financial position and Lifestyle Goal to accurately plan your first step in property investment. This information guides future purchases and helps in setting realistic financial targets.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "189pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Refine",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "215pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "560.25pt",
          "height": "71pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "title": "Listing Sub Heading",
        "value": "<p>Work with our optimization team to tailor your Portfolio Plan. Refining may involve updating expenses, specifying equity or cash usage, choosing purchase locations, or adjusting plans with forecasted profits.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "297pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Implement",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "540pt",
          "height": "65pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "title": "Listing Sub Heading",
        "value": "<p>Using live market data, our app streamlines the implementation of your plan, making it more realistic and achievable. InvestLogic ensures risk-free execution, including property buying decisions.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "405pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "161pt",
          "height": "19.25pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Track & Advance",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "431pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "547pt",
          "height": "65pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Monitor investment performance to gauge success in achieving financial goals. The plan tracks asset growth to inform future purchases, streamlining the process for a repeatable 'Rinse & Repeat' method.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "102pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "169.5pt",
          "height": "13pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Your First Property ID",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "159pt",
          "height": "16pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Commerical Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "- Legals & Valuations",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Acquistion Costs",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "150.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Loan to Value Ration ( LVR)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Main Debt Facility",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Acquistion Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "- Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "- Acquistion Fee",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "422pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Net Yeild on Property ( Year 1)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$100,000.00",
        "attributes": {
          "ref": "yourIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$35,000.00",
        "attributes": {
          "ref": "partnerIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$0.00",
        "attributes": {
          "ref": "otherIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "3",
        "attributes": {
          "ref": "dependants",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$4,963.86",
        "attributes": {
          "ref": "totalExpenses",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "5.8%",
        "attributes": {
          "ref": "interestRate",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "102pt",
          "left": "930pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "279.5pt",
          "height": "13.75pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Investment Assumptions",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "159pt",
          "height": "20.75pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Deposit Source",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Main Debt Facility",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Equity Facility",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Current Cap Rate",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Loan to value Ration",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Applied Interest Rate",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Property Management Charge",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "CPI ( Consumer Price Index)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Tenant & Incentive",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Tenant Renewal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "410pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "247pt",
          "height": "79.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.59
        },
        "title": "Listing Sub Heading",
        "value": "* Cashflow Projects are calculated based on a period of 12 months starting from the Purchase Date, and any changes in vaccancies, rental payments, interest, capex & sbatement, will be applicable during this period. ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "1095pt",
          "color": "#323332,0.70,0.62,0.63,0.59",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stamp",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11, 030.94",
        "attributes": {
          "ref": "acquistionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$100,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$12,729.6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "112pt",
          "left": "1080pt",
          "width": "1pt",
          "height": "0pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "930pt",
          "width": "1pt",
          "height": "368pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "1184pt",
          "width": "1pt",
          "height": "368pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "896pt",
          "width": "1pt",
          "height": "318pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "642pt",
          "width": "1pt",
          "height": "318pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "808pt",
          "width": "1pt",
          "height": "318pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674647728.jpeg",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "530pt",
          "left": "12pt",
          "width": "604pt",
          "height": "341pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "642pt",
          "width": "255pt",
          "height": "31pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "930pt",
          "width": "255pt",
          "height": "31pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1688464307.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "457pt",
          "left": "562pt",
          "width": "529pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674709537.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "651pt",
          "left": "982pt",
          "width": "142pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1688464552.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "468pt",
          "left": "642pt",
          "width": "256pt",
          "height": "143pt"
        },
        "attributes": {
          "ratio": 0.7480106100795756,
          "isDrag": true,
          "isAsset": true,
          "category": "image"
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 294.7252740775269
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "179pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "208pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "237pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "266pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "295pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "324pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "353pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "440pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "382pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "411pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "179pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "208pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "266pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "237pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "295pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "324pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "353pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "382pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "411pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "490pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Page No 6 using in ts
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "806pt",
          "left": "1074pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$13,269.31(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "1074pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 4",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "1074pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Sep-2029 ( 6 Years) ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "946pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$8,657.69(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "946pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "946pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jul-2026 ( 3 Years)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "820pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$7,189.30(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "820pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 2",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "820pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Sep-2023 ( 0 Year)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "691pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$2,800.00(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "691pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 1",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "691pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jun-2023 ( 0 Year)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "241pt",
          "left": "167pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "364pt",
          "height": "31.75pt",
          "fontSize": "27pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property Portfolio Metrics",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "423pt",
          "left": "93pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "423pt",
          "left": "267pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "128pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Portfolio Makeup",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "423pt",
          "left": "469pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "108pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Risk Profile",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "621pt",
          "left": "106pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Goal Year",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "621pt",
          "left": "251pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "158pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Current Portfolio Value",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "621pt",
          "left": "442pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "158pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "New Portfolio Value",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "643pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 1",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 2",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "643pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 4",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "1181pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420335.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "1098pt",
          "width": "15pt",
          "height": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421497.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "819pt",
          "left": "986pt",
          "width": "111pt",
          "height": "10pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420316.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "970pt",
          "width": "15pt",
          "height": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421497.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "819pt",
          "left": "859pt",
          "width": "111pt",
          "height": "10pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420299.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "843pt",
          "width": "15pt",
          "height": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421473.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "819pt",
          "left": "730pt",
          "width": "112pt",
          "height": "10pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420287.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "714pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "472pt",
          "left": "50pt",
          "width": "538pt",
          "height": "1.2pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "304pt",
          "left": "219pt",
          "width": "1.2pt",
          "height": "340pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "304pt",
          "left": "418pt",
          "width": "1.2pt",
          "height": "341pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209591.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "331pt",
          "left": "91pt",
          "width": "87pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209674.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "533pt",
          "left": "91pt",
          "width": "81pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209607.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "331pt",
          "left": "275pt",
          "width": "85pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209698.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "533pt",
          "left": "275pt",
          "width": "81pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209652.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "331pt",
          "left": "460pt",
          "width": "89pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695290198.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "533pt",
          "left": "460pt",
          "width": "87pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 1
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-image-2",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 2
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 3
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-image-4",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 4
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Page No 7 using in ts
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "5",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "5",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "722pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$16,815.55(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "722pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Dec-2030 ( 7 Years) Purchase property 7",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "439pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$15,441.17(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "439pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jun-2030 ( 7 Years) Purchase property 6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "158pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$14,495.87(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "158pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jan-2030 ( 7 Years) Purchase property 5",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "643pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 3",
        "attributes": {
          "header": 3,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "659pt",
          "color": "#26265f,1,0.98,0.32,0.23",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 4",
        "attributes": {
          "header": 4,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "643pt",
          "color": "#231f20,0.70,0.67,0.64,0.74",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 7",
        "attributes": {
          "header": 7,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 8",
        "attributes": {
          "header": 8,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "66pt",
          "color": "#26265f,1,0.98,0.32,0.23",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 1",
        "attributes": {
          "header": 1,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "332pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 2",
        "attributes": {
          "header": 2,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "50pt",
          "color": "#231f20,0.70,0.67,0.64,0.74",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 5",
        "attributes": {
          "header": 5,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "206pt",
          "color": "#26265f,1,0.98,0.32,0.23",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "331pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 6",
        "attributes": {
          "header": 6,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694424818.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "815pt",
          "left": "792pt",
          "width": "286pt",
          "height": "22pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420316.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "818pt",
          "left": "776pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421497.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "820pt",
          "left": "507pt",
          "width": "268pt",
          "height": "11pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420299.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "818pt",
          "left": "492pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421473.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "820pt",
          "left": "223pt",
          "width": "268pt",
          "height": "11pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420287.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "818pt",
          "left": "207pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 3
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-image-2",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 4
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 7
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-image-4",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 8
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "914pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "50pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 1
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "332pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 2
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "50pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "332pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "50pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "331pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "50pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 5
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "50pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "50pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "332pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 6
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "332pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "332pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 6
        }
      }
    ]
  },
  // Page No 8 using in ts - copy of page 7 layout
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "5",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "5",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "722pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$16,815.55(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "722pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Dec-2030 ( 7 Years) Purchase property 7",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "439pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$15,441.17(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "439pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jun-2030 ( 7 Years) Purchase property 6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "158pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$14,495.87(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "158pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "117pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jan-2030 ( 7 Years) Purchase property 5",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "643pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 3",
        "attributes": {
          "header": 3,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "659pt",
          "color": "#26265f,1,0.98,0.32,0.23",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 4",
        "attributes": {
          "header": 4,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "643pt",
          "color": "#231f20,0.70,0.67,0.64,0.74",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 7",
        "attributes": {
          "header": 7,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 7,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 8,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 8",
        "attributes": {
          "header": 8,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "66pt",
          "color": "#26265f,1,0.98,0.32,0.23",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 1",
        "attributes": {
          "header": 1,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "332pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 2",
        "attributes": {
          "header": 2,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "50pt",
          "color": "#231f20,0.70,0.67,0.64,0.74",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 5",
        "attributes": {
          "header": 5,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "66pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "206pt",
          "color": "#26265f,1,0.98,0.32,0.23",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "206pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 5,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "331pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property 6",
        "attributes": {
          "header": 6,
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "title": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "title": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "title": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "title": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "title": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "title": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "title": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "348pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "title": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "486pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 6,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694424818.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "815pt",
          "left": "792pt",
          "width": "286pt",
          "height": "22pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420316.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "818pt",
          "left": "776pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421497.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "820pt",
          "left": "507pt",
          "width": "268pt",
          "height": "11pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420299.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "818pt",
          "left": "492pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421473.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "820pt",
          "left": "223pt",
          "width": "268pt",
          "height": "11pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420287.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "818pt",
          "left": "207pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 3
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 3
        }
      },
      {
        "id": "asset-image-2",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 4
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 4
        }
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 7
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 7
        }
      },
      {
        "id": "asset-image-4",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 8
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "914pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 8
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "50pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 1
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "332pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 2
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "50pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "332pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "50pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 1
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "331pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 2
        }
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "50pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 5
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "50pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "50pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "50pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 5
        }
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "332pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 6
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "332pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "332pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {
          "property": 6
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "332pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {
          "property": 6
        }
      }
    ]
  },
  // Page No 9 using in ts
  {
    "meta": {},
    "printBoard": {
      "trim": "7.408",
      "bleed": "4.408",
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "845pt",
          "left": "1181pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "387pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$2,837.73",
        "attributes": {
          "ref": "monthly_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "365pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "YEAR 1 CASHFLOW (MONTHLY)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "302pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "5.57%",
        "attributes": {
          "ref": "average_yield",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "280pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "AVERAGE YIELD",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "217pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$200,000",
        "attributes": {
          "ref": "cash_equity",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "195pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "CASH + EQUIT Y AVAILABLE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "132pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "2023",
        "attributes": {
          "ref": "goal_achieved",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "110pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "GOAL ACHIEVED  IN YEAR",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "387pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$34,052.00",
        "attributes": {
          "ref": "yearly_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "365pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "YEAR 1 CASHFLOW",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "302pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$7,756,508.00",
        "attributes": {
          "ref": "portfolio_value",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "280pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "TOTAL PORTFOLIO VALUE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "217pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "June 2023",
        "attributes": {
          "ref": "next_purchase",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "195pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "NEXT PURCHASE DATE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "132pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$200,000",
        "attributes": {
          "ref": "life_goal",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "110pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "50pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "209.25pt",
          "height": "27pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "450pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "209pt",
          "height": "14.75pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "My Current Property Portfolio",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "465pt",
          "color": "#000000,0.0,0.0,0.0,0.0",
          "width": "1132pt",
          "border": "1pt",
          "fontSize": "12pt",
          "minWidth": "0",
          "overflow": "linebreak",
          "marginTop": "0pt",
          "minHeight": "0pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.4,
          "marginLeft": "50pt",
          "paddingTop": "9pt",
          "borderColor": "#efefef",
          "borderStyle": "solid",
          "marginRight": "0pt",
          "paddingLeft": "10pt",
          "marginBottom": "0pt",
          "paddingRight": "10pt",
          "paddingBottom": "9pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "tblData": {
          "body": [
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ]
          ],
          "head": [
            [
              "Address",
              "Type",
              "Valuation",
              "Mortgage",
              "Useable Equity"
            ]
          ]
        },
        "tblStyle": {
          "tblFoot": {},
          "tblHead": {
            "color": "#000000",
            "width": "224pt",
            "fontSize": "12pt",
            "textAlign": "center",
            "fontFamily": "myriad-bold",
            "fontWeight": "bold",
            "backgroundColor": "#eef4fb"
          }
        },
        "attributes": {
          "ref": "new_property_portfolio",
          "table": "b",
          "isEdit": false,
          "is_body": true,
          "is_foot": true,
          "is_head": true,
          "is_table": true
        },
        "tblColumns": [
          {
            "color": "#000000",
            "index": 0,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 1,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "paddingTop": "10pt",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 2,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 3,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 4,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          }
        ]
      },
      {
        "style": {
          "top": "756pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Value:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "110pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "ref": "portfolio_new_total_value",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "210pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "80pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Cashflow:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "289pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "ref": "portfolio_new_total_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "390pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "75pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Average Yield:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "465pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "64pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "ref": "portfolio_new_avg_yield",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "100pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "100pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "185pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "270pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "355pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "185pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "270pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "355pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Last Page
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "637.28pt",
        "border": "0px",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "826pt",
          "left": "12pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "613pt",
          "height": "12pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Australian Credit Licence: 12345",
        "attributes": {
          "type": "textarea",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "504pt",
          "left": "12pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "613pt",
          "height": "12pt",
          "fontSize": "12pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Phone.1300 110 140",
        "fields": [
          {
            "value": "Phone. 1300 110 140",
            "attributes": {
              "ref": "mobile",
              "formatter": "Phone. %s "
            }
          }
        ],
        "attributes": {
          "agent": 1,
          "isEdit": false,
          "isGroup": true
        }
      },
      {
        "style": {
          "top": "524pt",
          "left": "12pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "613pt",
          "height": "12pt",
          "fontSize": "12pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Email. info@investlogic.com.au",
        "fields": [
          {
            "value": "info@investlogic.com.au",
            "attributes": {
              "ref": "email",
              "formatter": "Email. %s "
            }
          }
        ],
        "attributes": {
          "agent": 2,
          "isEdit": false,
          "isGroup": true
        }
      },
      {
        "style": {
          "top": "544pt",
          "left": "12pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "613pt",
          "height": "12pt",
          "fontSize": "12pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "www.investlogic.com.au",
        "attributes": {
          "ref": "rw.address",
          "agent": 1,
          "isEdit": false,
          "isText": true,
          "isTxtEdit": false
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "12pt",
          "left": "12pt",
          "width": "613pt",
          "height": "859pt",
          "backgroundColor": "#121d47,1,0.93,0.40,0.45"
        },
        "attributes": {}
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694144592.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "402pt",
          "left": "154pt",
          "width": "322pt"
        },
        "attributes": {
          "order": 2,
          "category": "image"
        }
      }
    ]
  }
];

export const pagesList2 = [
  // Page No 1
  {
    "meta": {},
    "printBoard": {
      "trim": "7.408",
      "bleed": "4.408",
      "style": {
        "width": "637.28pt",
        "border": "0px",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "154pt",
          "left": "79pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "30pt",
          "fontSize": "34pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Strategic",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "196pt",
          "left": "79pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "30pt",
          "fontSize": "34pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Property",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "237pt",
          "left": "79pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "30pt",
          "fontSize": "34pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Plan",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "310pt",
          "left": "79pt",
          "color": "#00c4f9,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "19pt",
          "fontSize": "22pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Transform your life",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "79pt",
          "color": "#00c4f9,0.0,0.0,0.0,0.0",
          "width": "281pt",
          "height": "19pt",
          "fontSize": "22pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "financially",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694489734.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "12pt",
          "left": "12pt",
          "width": "613pt",
          "height": "859pt"
        },
        "attributes": {
          "ratio": 1.6612466124661247,
          "isDrag": true,
          "isAsset": true,
          "category": "image",
          "isBackdrop": true
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 456,
          "y1": 0,
          "y2": 274.4926590538337
        }
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "12pt",
          "left": "49pt",
          "width": "341pt",
          "height": "377pt",
          "backgroundColor": "#121d47,1,0.93,0.40,0.45"
        },
        "attributes": {}
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694144592.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "65pt",
          "left": "76pt",
          "width": "256pt"
        },
        "attributes": {
          "order": 2,
          "category": "image"
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "280.5pt",
          "left": "79pt",
          "width": "58pt",
          "height": "2.5pt",
          "backgroundColor": "#00c4f9,0.65,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "389pt",
          "left": "49pt",
          "width": "341pt",
          "height": "14pt",
          "backgroundColor": "#00c4f9,0.65,0.01,0.0,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Page No 2
  {
    "meta": {},
    "printBoard": {
      "trim": "7.408",
      "bleed": "4.408",
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "232pt",
          "left": "1065pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "115pt",
          "height": "222pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.01pt"
        },
        "value": "<p>Our blended strategy encompasses both residential and commercial assets, allowing you to envision not just buying your first home but also cultivating a portfolio geared towards wealth generation. With us, this aspiration becomes attainable.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "111pt",
          "left": "47pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "181.75pt",
          "height": "33.25pt",
          "fontSize": "36pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Welcome ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "191pt",
          "left": "47pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "200pt",
          "height": "459pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.07pt"
        },
        "title": "Listing Sub Heading",
        "value": "<p>Welcome to Australia's pioneering Property Portfolio Plan, driven by AI and supported by the nation's leading property sales site. Our plan guides you towards your financial goals, offering a dynamic roadmap for property investing in residential, commercial, or other sectors. Utilizing your financial status, it displays live property listings and aids in identifying investment opportunities, setting and tracking goals. It also supplies a framework for analyzing risk-adjusted plans. With real-time valuation data and strategy adjustment tools, our property portfolio plan empowers you to act as the 'Asset Manager.' Partner with our team to optimize your property investment strategy for 2023 and beyond.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "113pt",
          "left": "644pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "439.75pt",
          "height": "24pt",
          "fontSize": "22pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Who could the plan help?  ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "142pt",
          "left": "644pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "470pt",
          "height": "42.75pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.01pt"
        },
        "title": "Listing Sub Heading",
        "value": "<p>Individuals seeking a long-term investment opportunity that has the potential to grow in value and produce passive income via rent payments from commercial enterprises.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "230pt",
          "left": "694pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "130pt",
          "height": "14pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "letterSpacing": "0.05pt"
        },
        "value": "Property Owners",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "232pt",
          "left": "694pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "130pt",
          "height": "222pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.7,
          "letterSpacing": "0.01pt"
        },
        "value": "<p>By leveraging the Utilize the hidden value in your property through an equity loan or line of credit to make investments. These investments can yield returns greater than the loan interest, enhancing your overall wealth.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "230pt",
          "left": "878pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "130pt",
          "height": "14pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "letterSpacing": "0.05pt"
        },
        "value": "Property Investors",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "232pt",
          "left": "878pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "115pt",
          "height": "222pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.78,
          "letterSpacing": "0.01pt"
        },
        "value": "<p>With a focus on a singular Lifestyle Goal, this plan enables growth from your current position, a unique approach not common among many investors. By visualizing your portfolio on our dashboard, you can preview what your future investment landscape might resemble.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "230pt",
          "left": "1065pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "130pt",
          "height": "14pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "letterSpacing": "0.05pt"
        },
        "value": "First Home Buyers",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694485557.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "231pt",
          "left": "1013pt",
          "width": "44pt",
          "height": "44pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694485572.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "231pt",
          "left": "826pt",
          "width": "44pt",
          "height": "44pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694418884.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "12pt",
          "left": "311pt",
          "width": "305pt",
          "height": "859pt"
        },
        "attributes": {
          "ratio": 1.6612466124661247,
          "isDrag": true,
          "isAsset": true,
          "category": "image",
          "isBackdrop": true
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 456,
          "y1": 0,
          "y2": 274.4926590538337
        }
      },
      {
        "id": "asset-image-12",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694418844.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "474pt",
          "left": "616pt",
          "width": "604pt",
          "height": "397pt"
        },
        "attributes": {
          "ratio": 1.6612466124661247,
          "isDrag": true,
          "isAsset": true,
          "category": "image",
          "isBackdrop": true
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 456,
          "y1": 0,
          "y2": 274.4926590538337
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694485590.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "231pt",
          "left": "642pt",
          "width": "44pt",
          "height": "42pt"
        },
        "attributes": {
          "isLogo": true
        }
      }
    ]
  },
  // Page No 3
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "457pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "55.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Cashflow represents the profitability of a real estate asset, computed as the property's total revenues minus operating expenses. These expenses include municipal charges, property taxes, insurance, management costs, debt servicing, and capital expenditures.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "436pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Cash",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "366pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "55.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Cashflow represents the profitability of a real estate asset, computed as the property's total revenues minus operating expenses. These expenses include municipal charges, property taxes, insurance, management costs, debt servicing, and capital expenditures.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "345pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Cash",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "295pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This signifies the full amount a borrower pays for a loan, incorporating the principal, interest, and any related fees or charges. It calculates the lending for both 'Debt' & 'Equity' loans that may apply.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "274pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Total Cost of Lending ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "223pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The loan-to-value ratio (LTV) is the mortgage loan amount expressed as a percentage of the property's value. It's a critical metric in real estate lending and mortgage underwriting, assessing the risk of a mortgage loan.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "202pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Loan to Value Ration {LVR) ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "170pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "25.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This term refers to the portion of the total deposit required, funded through an Equity Release.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "149pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Total Deposit Equity ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "103pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>These encompass any extra expenses incurred during the acquisition process, such as stamp duty, legal fees, agent  commissions and miscellaneous costs.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "82pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Acquisition Costs ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "736pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Yield measures investment returns, showing the income an investment produces (like interest or dividends) as a percentage of the investment's cost.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "715pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Risk Factored Yield Return   ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "663pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Yield measures investment returns, showing the income an investment produces (like interest or dividends) as a percentage of the investment's cost.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "642pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Yield  ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "591pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Equity represents the unencumbered portion of a property's value, such as owning a $500,000 home with a remaining mortgage balance of $300,000, resulting in $200,000 equity.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "570pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Equity ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "538pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "25.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>An equity loan lets borrowers use their home's equity as collateral, providing access to additional funds.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "517pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Equity Release ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "464pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Also known as Principal Gain, this refers to the funds you apply to your loan that decrease the loan amount. It's the principal part of your mortgage payment.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "443pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Equity Gain ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "24pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Terms & References ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "82pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Lifestyle Goal ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "103pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "51.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Our Lifestyle Goal refers to a specific long-term financial target needed to support your desired lifestyle for you and your family. While it may broadly encompass objectives like healthier living, stress reduction, or new hobbies, it's specifically quantified as the pre-tax amount necessary to fund your chosen way of life.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "173pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Portfolio Makeup ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "194pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Portfolio Makeup outlines the particular blend of investments that constitute your customized investment portfolio. It defines the unique combination of assets tailored to your needs.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "245pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Australian Average Expenses",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "266pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This term refers to the Household Expenditure Measure (HEM), a standard benchmark some banks utilize to assess annual living expenses during home loan application evaluations.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "319pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "RRR",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "340pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "15.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Principal Place of Residence </p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "372pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "21pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Capital Growth ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Capital growth signifies the appreciation in an asset's value over time. This growth can be leveraged through selling the asset at a higher price than the purchase cost or through an equity release. </p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-image-1",
        "src": "https://propvesting-xm.s3.us-east-2.amazonaws.com/custom/image+6.png",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "529pt",
          "left": "615pt",
          "width": "605pt",
          "height": "342pt"
        },
        "attributes": {
          "ratio": 0.7480106100795756,
          "isDrag": true,
          "isAsset": true,
          "category": "image"
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 294.7252740775269
        }
      }
    ]
  },
  // Page No 4
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "334pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "54.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The calculator's results are estimates based on your input and should not replace professional financial advice. The results' accuracy depends on the information's precision. Consult a financial expert for tailored guidance.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "314pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Data Entry ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "249pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "54.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Though driven by Investlogic algorithms, the purchase prices in the calculator are sourced from live data on Domain.com.au and Commercialrealestate.com.au. This ensures that the highlighted properties, along with the rental income, are realistic, making the plan more actionable and comprehensive.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "229pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Property Pricing & Images ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "108pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>You're free to select any Property Type for investment. Don't be concerned by the initial types in your plan; they can be tailored to your liking.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "159pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Growth Hacking ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "179pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Our Property Optimisation team employs this strategy, using our proprietary techniques to generate positive Capital Gains quickly. This section showcases the effectiveness of our growth hacking methods.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "82pt",
          "left": "642pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Property Type ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "405pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "74.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The time between properties varies, based on Capital Growth of existing assets and saving ability. Since these factors are vital for future acquisitions, and the plan revolves around re-investing equity, the timeline can significantly affect reaching your Lifestyle Goal. However, the calculator may also allow inputs like inheritance or profit from asset sales, having a substantial impact on the timeline.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "385pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Time ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "334pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Using data from Domain, our calculator initially identifies key growth suburbs, forming the base of your property portfolio. You can adjust these to any preferred suburb with complete app access.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "314pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Selected Suburbs ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "265pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>The first property ID leverages your Usable Equity, fully drawn at 100%, to ascertain the Purchase Price. Subsequent property purchases utilize both Risk Factored Yield Returns and rents from chosen suburbs.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "245pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "First Property ID ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "179pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "54.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>This term defines the largest amount a person or business can borrow, based on factors like income, assets, and creditworthiness. In this context, it's determined by dividing income by existing debts or liabilities to produce the Debt to Income Ratio, then multiplying by income to give the Max Borrowing Capacity (MBC).</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "159pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Max Borrowing Capacity ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "24pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "35.25pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Critical Assumptions ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "88pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "345.75pt",
          "height": "20pt",
          "fontSize": "13pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "value": "Usable Equity  ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "108pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "497pt",
          "height": "34.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Usable equity refers to the equity accessible for borrowing or investment purposes. It's calculated by subtracting outstanding property debts from 80% of the total value of property assets plus cash.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "real",
        "src": "https://propvesting-xm.s3.us-east-2.amazonaws.com/custom/image+7.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "550pt",
          "left": "12pt",
          "width": "1208pt",
          "height": "321pt"
        },
        "attributes": {
          "isLogo": true
        }
      }
    ]
  },
  // Page No 5 using in ts
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "845pt",
          "left": "1181pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$100,000.00",
        "attributes": {
          "ref": "yourIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "422pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "24pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "581pt",
          "height": "43.25pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Your Property Portfolio Plan Pathway",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "81pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Identify",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "107pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "559.75pt",
          "height": "67.75pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "title": "Listing Sub Heading",
        "value": "<p>Understand your financial position and Lifestyle Goal to accurately plan your first step in property investment. This information guides future purchases and helps in setting realistic financial targets.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "189pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Refine",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "215pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "560.25pt",
          "height": "71pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "title": "Listing Sub Heading",
        "value": "<p>Work with our optimization team to tailor your Portfolio Plan. Refining may involve updating expenses, specifying equity or cash usage, choosing purchase locations, or adjusting plans with forecasted profits.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "297pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Implement",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "540pt",
          "height": "65pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "title": "Listing Sub Heading",
        "value": "<p>Using live market data, our app streamlines the implementation of your plan, making it more realistic and achievable. InvestLogic ensures risk-free execution, including property buying decisions.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "405pt",
          "left": "50pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "161pt",
          "height": "19.25pt",
          "fontSize": "18pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold",
          "lineHeight": 1.2
        },
        "title": "Listing Sub Heading",
        "value": "Track & Advance",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "431pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "547pt",
          "height": "65pt",
          "fontSize": "14pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.6
        },
        "value": "<p>Monitor investment performance to gauge success in achieving financial goals. The plan tracks asset growth to inform future purchases, streamlining the process for a repeatable 'Rinse & Repeat' method.</p>",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "102pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "169.5pt",
          "height": "13pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Your First Property ID",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "159pt",
          "height": "16pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Commerical Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "- Legals & Valuations",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Acquistion Costs",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "150.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Loan to Value Ration ( LVR)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Main Debt Facility",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Acquistion Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "- Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "- Acquistion Fee",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "422pt",
          "left": "649pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Net Yeild on Property ( Year 1)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$100,000.00",
        "attributes": {
          "ref": "yourIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$35,000.00",
        "attributes": {
          "ref": "partnerIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$0.00",
        "attributes": {
          "ref": "otherIncome",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "3",
        "attributes": {
          "ref": "dependants",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$4,963.86",
        "attributes": {
          "ref": "totalExpenses",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "5.8%",
        "attributes": {
          "ref": "interestRate",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "807pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "30 Years",
        "attributes": {
          "ref": "loanTerm",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "102pt",
          "left": "930pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "279.5pt",
          "height": "13.75pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Investment Assumptions",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "133pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "159pt",
          "height": "20.75pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Deposit Source",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Main Debt Facility",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Equity Facility",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Current Cap Rate",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Loan to value Ration",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Applied Interest Rate",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Property Management Charge",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "CPI ( Consumer Price Index)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Tenant & Incentive",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "170.25pt",
          "height": "14pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Tenant Renewal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "410pt",
          "left": "937pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "247pt",
          "height": "79.25pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.59
        },
        "title": "Listing Sub Heading",
        "value": "* Cashflow Projects are calculated based on a period of 12 months starting from the Purchase Date, and any changes in vaccancies, rental payments, interest, capex & sbatement, will be applicable during this period. ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "161pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "190pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "219pt",
          "left": "1095pt",
          "color": "#323332,0.70,0.62,0.63,0.59",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stamp",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "248pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11, 030.94",
        "attributes": {
          "ref": "acquistionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "277pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$100,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "306pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "335pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "364pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$12,729.6",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "393pt",
          "left": "1095pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "112pt",
          "left": "1080pt",
          "width": "1pt",
          "height": "0pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "930pt",
          "width": "1pt",
          "height": "368pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "1184pt",
          "width": "1pt",
          "height": "368pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "896pt",
          "width": "1pt",
          "height": "318pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "642pt",
          "width": "1pt",
          "height": "318pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "808pt",
          "width": "1pt",
          "height": "318pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674647728.jpeg",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "530pt",
          "left": "12pt",
          "width": "604pt",
          "height": "341pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "642pt",
          "width": "255pt",
          "height": "31pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "122pt",
          "left": "930pt",
          "width": "255pt",
          "height": "31pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1688464307.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "457pt",
          "left": "562pt",
          "width": "529pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674709537.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "651pt",
          "left": "982pt",
          "width": "142pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1688464552.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "468pt",
          "left": "642pt",
          "width": "256pt",
          "height": "143pt"
        },
        "attributes": {
          "ratio": 0.7480106100795756,
          "isDrag": true,
          "isAsset": true,
          "category": "image"
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 294.7252740775269
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "179pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "208pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "237pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "266pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "295pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "324pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "353pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "440pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "382pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "411pt",
          "left": "642pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "179pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "208pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "266pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "237pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "295pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "324pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "353pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "382pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "411pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "490pt",
          "left": "930pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#fafafc,0.01,0.01,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Page No 6 using in ts
  {
    "meta": {},
    "printBoard": {
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "806pt",
          "left": "1074pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$13,269.31(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "1074pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 4",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "1074pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Sep-2029 ( 6 Years) ",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "946pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$8,657.69(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "946pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "946pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jul-2026 ( 3 Years)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "820pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$7,189.30(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "820pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 2",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "820pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Sep-2023 ( 0 Year)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "806pt",
          "left": "691pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$2,800.00(CFM)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "796pt",
          "left": "691pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Purchase property 1",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "786pt",
          "left": "691pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "59pt",
          "height": "10pt",
          "fontSize": "7pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Jun-2023 ( 0 Year)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "241pt",
          "left": "167pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "364pt",
          "height": "31.75pt",
          "fontSize": "27pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property Portfolio Metrics",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "423pt",
          "left": "93pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "423pt",
          "left": "267pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "128pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Portfolio Makeup",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "423pt",
          "left": "469pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "108pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Risk Profile",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "621pt",
          "left": "106pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "98pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Goal Year",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "621pt",
          "left": "251pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "158pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Current Portfolio Value",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "621pt",
          "left": "442pt",
          "color": "#00c4f9,0.65,0.01,0.0,0.0",
          "width": "158pt",
          "height": "20pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "New Portfolio Value",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "643pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 1",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$198,556.96",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-24,628.52",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$-2,052.37",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 1,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "65pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 2",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "299pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "347pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "371pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "643pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "659pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$96,428.57",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$6,857.14",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$571.42",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "227pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "251pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "275pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "323pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "93pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Purchase Price",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Deposit",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Stamp Duty",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Acquisition Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Deposit Required",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Total Purchase Cost",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Annual)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "942pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "120pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "left",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "Cashflow (Monthly)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "593pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$147,079.23",
        "attributes": {
          "ref": "deposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "617pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$40,446.78",
        "attributes": {
          "ref": "stampDuty",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "641pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$11,030.94",
        "attributes": {
          "ref": "acquisitionCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "665pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$108,000.00",
        "attributes": {
          "ref": "totalDeposit",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "689pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$786,873.89",
        "attributes": {
          "ref": "totalPurchaseCost",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "713pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$7,680.00",
        "attributes": {
          "ref": "cashflowAnnual",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "737pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "10pt",
          "textAlign": "right",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "$640.00",
        "attributes": {
          "ref": "cashflowMonthly",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 4,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "200pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 2,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "799pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "ref": "purchasePrice",
          "isEdit": false,
          "cssClass": "textarea",
          "property": 3,
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "567pt",
          "left": "1082pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "83pt",
          "height": "11pt",
          "fontSize": "12pt",
          "textAlign": "right",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "title": "Listing Sub Heading",
        "value": "$735,396.16",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "432pt",
          "left": "926pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "73pt",
          "height": "11pt",
          "fontSize": "9pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "title": "Listing Sub Heading",
        "value": "Property 4",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true,
          "defaultFontSize": "13pt"
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "1181pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420335.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "1098pt",
          "width": "15pt",
          "height": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421497.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "819pt",
          "left": "986pt",
          "width": "111pt",
          "height": "10pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420316.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "970pt",
          "width": "15pt",
          "height": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421497.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "819pt",
          "left": "859pt",
          "width": "111pt",
          "height": "10pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420299.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "843pt",
          "width": "15pt",
          "height": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694421473.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "819pt",
          "left": "730pt",
          "width": "112pt",
          "height": "10pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1694420287.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "816pt",
          "left": "714pt",
          "width": "15pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "472pt",
          "left": "50pt",
          "width": "538pt",
          "height": "1.2pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "304pt",
          "left": "219pt",
          "width": "1.2pt",
          "height": "340pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "304pt",
          "left": "418pt",
          "width": "1.2pt",
          "height": "341pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209591.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "331pt",
          "left": "91pt",
          "width": "87pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209674.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "533pt",
          "left": "91pt",
          "width": "81pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209607.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "331pt",
          "left": "275pt",
          "width": "85pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209698.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "533pt",
          "left": "275pt",
          "width": "81pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695209652.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "331pt",
          "left": "460pt",
          "width": "89pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "real",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1695290198.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "533pt",
          "left": "460pt",
          "width": "87pt"
        },
        "attributes": {
          "isLogo": true
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 1
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-image-2",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "49pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 2
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "190pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "59pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "243pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "267pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "291pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "315pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "339pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "387pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "363pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-image-3",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "643pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 3
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "643pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "643pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "643pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-image-4",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1674637347.jpeg",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "416pt",
          "left": "926pt",
          "width": "255pt",
          "height": "141pt"
        },
        "attributes": {
          "property": 4
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 197.9127510270875
        }
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "426pt",
          "left": "926pt",
          "width": "73pt",
          "height": "21pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "557pt",
          "left": "926pt",
          "width": "255pt",
          "height": "30pt",
          "backgroundColor": "#eef4fb,0.05,0.02,0.0,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "609pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "633pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "657pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "705pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "681pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "729pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-top-red",
        "name": "Top Red Color",
        "type": "rect",
        "style": {
          "top": "753pt",
          "left": "926pt",
          "width": "255pt",
          "height": "1pt",
          "backgroundColor": "#cbcccb,0.20,0.15,0.16,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Page No 7 using in ts - same as pagesList Page No 9
  {
    "meta": {},
    "printBoard": {
      "trim": "7.408",
      "bleed": "4.408",
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "845pt",
          "left": "1181pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "387pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$2,837.73",
        "attributes": {
          "ref": "monthly_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "365pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "YEAR 1 CASHFLOW (MONTHLY)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "302pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "5.57%",
        "attributes": {
          "ref": "average_yield",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "280pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "AVERAGE YIELD",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "217pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$200,000",
        "attributes": {
          "ref": "cash_equity",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "195pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "CASH + EQUIT Y AVAILABLE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "132pt",
          "left": "323pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "2023",
        "attributes": {
          "ref": "goal_achieved",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "110pt",
          "left": "323pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "GOAL ACHIEVED  IN YEAR",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "387pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$34,052.00",
        "attributes": {
          "ref": "yearly_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "365pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "YEAR 1 CASHFLOW",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "302pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$7,756,508.00",
        "attributes": {
          "ref": "portfolio_value",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "280pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "TOTAL PORTFOLIO VALUE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "217pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "June 2023",
        "attributes": {
          "ref": "next_purchase",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "195pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "NEXT PURCHASE DATE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "132pt",
          "left": "50pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$200,000",
        "attributes": {
          "ref": "life_goal",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "110pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "50pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "209.25pt",
          "height": "27pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "450pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "209pt",
          "height": "14.75pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "My Current Property Portfolio",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "465pt",
          "color": "#000000,0.0,0.0,0.0,0.0",
          "width": "1132pt",
          "border": "1pt",
          "fontSize": "12pt",
          "minWidth": "0",
          "overflow": "linebreak",
          "marginTop": "0pt",
          "minHeight": "0pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.4,
          "marginLeft": "50pt",
          "paddingTop": "9pt",
          "borderColor": "#efefef",
          "borderStyle": "solid",
          "marginRight": "0pt",
          "paddingLeft": "10pt",
          "marginBottom": "0pt",
          "paddingRight": "10pt",
          "paddingBottom": "9pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "tblData": {
          "body": [
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ]
          ],
          "head": [
            [
              "Address",
              "Type",
              "Valuation",
              "Mortgage",
              "Useable Equity"
            ]
          ]
        },
        "tblStyle": {
          "tblFoot": {},
          "tblHead": {
            "color": "#000000",
            "width": "224pt",
            "fontSize": "12pt",
            "textAlign": "center",
            "fontFamily": "myriad-bold",
            "fontWeight": "bold",
            "backgroundColor": "#eef4fb"
          }
        },
        "attributes": {
          "ref": "new_property_portfolio",
          "table": "b",
          "isEdit": false,
          "is_body": true,
          "is_foot": true,
          "is_head": true,
          "is_table": true
        },
        "tblColumns": [
          {
            "color": "#000000",
            "index": 0,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 1,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "paddingTop": "10pt",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 2,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 3,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 4,
            "width": "224pt",
            "fontSize": "12pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          }
        ]
      },
      {
        "style": {
          "top": "756pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Value:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "110pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "ref": "portfolio_new_total_value",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "210pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "80pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Cashflow:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "289pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "ref": "portfolio_new_total_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "390pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "75pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Average Yield:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "465pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "64pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "ref": "portfolio_new_avg_yield",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "100pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "100pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "185pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "270pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "355pt",
          "left": "50pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "185pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "270pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "355pt",
          "left": "323pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      }
    ]
  },
  // Last Page
  {
    "meta": {},
    "printBoard": {
      "trim": "7.408",
      "bleed": "4.408",
      "style": {
        "width": "1232.6pt",
        "height": "883.9pt",
        "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
      }
    },
    "textLayers": [
      {
        "style": {
          "top": "845pt",
          "left": "1181pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "387pt",
          "left": "928pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$2,837.73",
        "attributes": {
          "ref": "monthly_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "365pt",
          "left": "928pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "YEAR 1 CASHFLOW (MONTHLY)",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "302pt",
          "left": "928pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "5.57%",
        "attributes": {
          "ref": "average_yield",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "280pt",
          "left": "928pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "AVERAGE YIELD",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "217pt",
          "left": "928pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$200,000",
        "attributes": {
          "ref": "cash_equity",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "195pt",
          "left": "928pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "CASH + EQUIT Y AVAILABLE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "132pt",
          "left": "928pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "2023",
        "attributes": {
          "ref": "goal_achieved",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "110pt",
          "left": "928pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "GOAL ACHIEVED  IN YEAR",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "387pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$34,052.00",
        "attributes": {
          "ref": "yearly_cashflow",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "365pt",
          "left": "642pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "YEAR 1 CASHFLOW",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "302pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$7,756,508.00",
        "attributes": {
          "ref": "portfolio_value",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "280pt",
          "left": "642pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "TOTAL PORTFOLIO VALUE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "217pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "June 2023",
        "attributes": {
          "ref": "next_purchase",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "195pt",
          "left": "642pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "NEXT PURCHASE DATE",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "132pt",
          "left": "642pt",
          "color": "#121d47,1,0.93,0.40,0.45",
          "width": "257pt",
          "height": "15pt",
          "fontSize": "18pt",
          "textAlign": "center",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "$200,000",
        "attributes": {
          "ref": "life_goal",
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "110pt",
          "left": "642pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "257pt",
          "height": "10pt",
          "fontSize": "10pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "letterSpacing": "1.20pt",
          "textTransform": "uppercase"
        },
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "50pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "347pt",
          "height": "25.75pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Current Portfolio Dashboard",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "101pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "284.75pt",
          "height": "13.75pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "My Current Property Portfolio",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "50pt",
          "left": "644pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "209.25pt",
          "height": "27pt",
          "fontSize": "24pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Lifestyle Goal",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "450pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "209pt",
          "height": "14.75pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "My New Property Portfolio",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "450pt",
          "left": "644pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "246pt",
          "height": "14.25pt",
          "fontSize": "14pt",
          "fontFamily": "myriad-bold",
          "fontWeight": "bold"
        },
        "value": "Your Finances",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "465pt",
          "color": "#000000,0.0,0.0,0.0,0.0",
          "width": "540pt",
          "border": "1pt",
          "fontSize": "10pt",
          "minWidth": "0",
          "overflow": "linebreak",
          "marginTop": "0pt",
          "minHeight": "0pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.4,
          "marginLeft": "50pt",
          "paddingTop": "5pt",
          "borderColor": "#efefef",
          "borderStyle": "solid",
          "marginRight": "0pt",
          "paddingLeft": "10pt",
          "marginBottom": "0pt",
          "paddingRight": "10pt",
          "paddingBottom": "5pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "tblData": {
          "body": [
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ]
          ],
          "head": [
            [
              "Address",
              "Type",
              "Valuation",
              "Mortgage",
              "Useable Equity"
            ]
          ]
        },
        "tblStyle": {
          "tblFoot": {},
          "tblHead": {
            "color": "#000000",
            "width": "108pt",
            "fontSize": "12pt",
            "textAlign": "center",
            "fontFamily": "myriad-bold",
            "fontWeight": "bold",
            "backgroundColor": "#eef4fb"
          }
        },
        "attributes": {
          "ref": "new_property_portfolio",
          "table": "b",
          "isEdit": false,
          "is_body": true,
          "is_foot": true,
          "is_head": true,
          "is_table": true
        },
        "tblColumns": [
          {
            "color": "#000000",
            "index": 0,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 1,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "paddingTop": "10pt",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 2,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 3,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 4,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          }
        ]
      },
      {
        "style": {
          "top": "117pt",
          "color": "#000000,0.0,0.0,0.0,0.0",
          "width": "540pt",
          "border": "1pt",
          "fontSize": "10pt",
          "minWidth": "0",
          "overflow": "linebreak",
          "marginTop": "0pt",
          "minHeight": "0pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.4,
          "marginLeft": "50pt",
          "paddingTop": "5pt",
          "borderColor": "#efefef",
          "borderStyle": "solid",
          "marginRight": "0pt",
          "paddingLeft": "10pt",
          "marginBottom": "0pt",
          "paddingRight": "10pt",
          "paddingBottom": "5pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "tblData": {
          "body": [
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ],
            [
              "12 Limkin Street, Burleigh, Qld, 4220",
              "Residential",
              "$2,332,000",
              "$2,000,000",
              "$297.069"
            ]
          ],
          "head": [
            [
              "Address",
              "Type",
              "Valuation",
              "Mortgage",
              "Useable Equity"
            ]
          ]
        },
        "tblStyle": {
          "tblFoot": {},
          "tblHead": {
            "color": "#000000",
            "width": "108pt",
            "fontSize": "12pt",
            "textAlign": "center",
            "fontFamily": "myriad-bold",
            "fontWeight": "bold",
            "backgroundColor": "#eef4fb"
          }
        },
        "attributes": {
          "ref": "current_property_portfolio",
          "table": "b",
          "isEdit": false,
          "is_body": true,
          "is_foot": true,
          "is_head": true,
          "is_table": true
        },
        "tblColumns": [
          {
            "color": "#000000",
            "index": 0,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 1,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "paddingTop": "10pt",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 2,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 3,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          },
          {
            "color": "#000000",
            "index": 4,
            "width": "108pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#FFFFFF"
          }
        ]
      },
      {
        "style": {
          "top": "406pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Value:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "50pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Value:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "110pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "406pt",
          "left": "110pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "406pt",
          "left": "210pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "80pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Cashflow:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "406pt",
          "left": "289pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$2,502,000",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "406pt",
          "left": "390pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "75pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Average Yield:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "406pt",
          "left": "465pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$230.500",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "210pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "80pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Total Cashflow:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "289pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "66pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "390pt",
          "color": "#000000,0.75,0.68,0.67,0.90",
          "width": "75pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "Average Yield:",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "756pt",
          "left": "465pt",
          "color": "#3b68b1,0.82,0.61,0.01,0.0",
          "width": "64pt",
          "height": "13.75pt",
          "fontSize": "12pt",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "$1,332,000",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      },
      {
        "style": {
          "top": "465pt",
          "color": "#000000,0.0,0.0,0.0,0.0",
          "width": "276pt",
          "border": "1pt",
          "fontSize": "10pt",
          "minWidth": "0",
          "overflow": "linebreak",
          "marginTop": "0pt",
          "minHeight": "0pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular",
          "lineHeight": 1.4,
          "marginLeft": "644pt",
          "paddingTop": "7pt",
          "borderColor": "#efefef",
          "borderStyle": "solid",
          "marginRight": "0pt",
          "paddingLeft": "10pt",
          "marginBottom": "0pt",
          "paddingRight": "10pt",
          "paddingBottom": "7pt",
          "backgroundColor": "#FFFFFF,0.0,0.0,0.0,0.0"
        },
        "tblData": {
          "body": [
            [
              "Cash Available",
              "$5,332,000"
            ],
            [
              "Liquid Assets",
              "$5,332,000"
            ],
            [
              "Income",
              "$5,332,000"
            ],
            [
              "Partner Income",
              "$5,332,000"
            ],
            [
              "Liabilities",
              "$5,332,000"
            ],
            [
              "Expenses",
              "$5,332,000"
            ],
            [
              "Saving",
              "$5,332,000"
            ],
            [
              "Max Borrowing Capacity",
              "$5,332,000"
            ],
            [
              "Total Debt",
              "$5,332,000"
            ],
            [
              "Usable Equity",
              "$5,332,000"
            ]
          ]
        },
        "attributes": {
          "ref": "your_finance",
          "table": "b",
          "isEdit": false,
          "is_body": true,
          "is_foot": true,
          "is_head": true,
          "is_table": true
        },
        "tblColumns": [
          {
            "color": "#000000",
            "index": 0,
            "width": "138pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "backgroundColor": "#eef4fb"
          },
          {
            "color": "#000000",
            "index": 1,
            "width": "138pt",
            "fontSize": "10pt",
            "overflow": "linebreak",
            "textAlign": "center",
            "fontFamily": "myriad-regular",
            "fontWeight": "regular",
            "paddingTop": "10pt",
            "backgroundColor": "#FFFFFF"
          }
        ]
      },
      {
        "style": {
          "top": "845pt",
          "left": "22pt",
          "color": "#FFFFFF,0.0,0.0,0.0,0.0",
          "width": "28pt",
          "height": "13.75pt",
          "fontSize": "6pt",
          "textAlign": "center",
          "fontFamily": "myriad-regular",
          "fontWeight": "regular"
        },
        "value": "3",
        "attributes": {
          "isEdit": false,
          "cssClass": "textarea",
          "isTxtEdit": false,
          "isTextArea": true
        }
      }
    ],
    "imageLayers": [
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "100pt",
          "left": "642pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-image-1",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1688459340.png",
        "name": "Image 1",
        "type": "img",
        "scale": 1,
        "style": {
          "top": "481pt",
          "left": "927pt",
          "width": "293pt",
          "height": "390pt"
        },
        "attributes": {
          "ratio": 0.7480106100795756,
          "isDrag": true,
          "isAsset": true,
          "category": "image"
        },
        "cropperPosition": {
          "x1": 0,
          "x2": 447,
          "y1": 0,
          "y2": 294.7252740775269
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "100pt",
          "left": "915pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "185pt",
          "left": "642pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "270pt",
          "left": "642pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "355pt",
          "left": "642pt",
          "width": "257pt",
          "height": "58pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "185pt",
          "left": "915pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "270pt",
          "left": "915pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-logo",
        "src": "https://d733qf9v56ynw.cloudfront.net/library/design_templates/images/1689333665.png",
        "name": "Logo",
        "type": "img",
        "style": {
          "top": "355pt",
          "left": "915pt",
          "width": "257pt",
          "height": "61pt"
        },
        "attributes": {
          "isDrag": true,
          "category": "image"
        }
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "22pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      },
      {
        "id": "asset-shape-2",
        "name": "Shape two",
        "type": "rect",
        "style": {
          "top": "833pt",
          "left": "1181pt",
          "width": "28pt",
          "height": "28pt",
          "backgroundColor": "#2C4263,1.0,0.90,0.57,0.0"
        },
        "attributes": {}
      }
    ]
  },
];