import React, { useState, useEffect } from "react";

export const useFetchPinderLiveData = (
  url,
  TopFilters = {
    PostCode: [],
    Page: 1,
    PerPage: 50,
    MaxPrice: 0,
    MinPrice: 0,
    PropertyType: [],
    ListingType: [],
    Keyword: "",
    State: "",
  },
  propertyType = ""
) => {
  const [TableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const[Total,setTotal]=useState(0)
  const[Propertyoverview,setPropertyoverview]=useState([])
  useEffect(() => {
    const CallListingApi = async () => {
      setIsLoading(true);
      let queryparams = "";
      let propertyTypes=[]
      if (TopFilters?.PostCode?.length > 0) {
        TopFilters.PostCode.map((item) => {
          queryparams = queryparams + `postcode[]=${item.code}&`;
          return item.code; 
        });
      }
      if (TopFilters?.Page > 0) {
        queryparams = queryparams + `pageNumber=${Number(TopFilters?.Page)}&`;
      }
      if (TopFilters?.PerPage > 0) {
        queryparams = queryparams + `pageSize=${Number(TopFilters?.PerPage)}&`;
      }
      if (TopFilters?.MaxPrice > 0) {
        queryparams = queryparams + `max=${Number(TopFilters?.MaxPrice)}&`;
      }
      if (TopFilters?.MinPrice > 0) {
        queryparams = queryparams + `min=${Number(TopFilters?.MinPrice)}&`;
      }
      if (TopFilters?.PropertyType?.length > 0) {
        TopFilters.PropertyType.map((item) => {
          propertyTypes.push(item.code)
          queryparams = queryparams + `propertyTypes[]=${item.code}&`;
          return item.code;
        });
      }
      if (TopFilters?.ListingType?.length > 0) {
        TopFilters.ListingType.map((item) => {
          queryparams = queryparams + `listingType[]=${item.code}&`;
          return item.code;
        });
      }
      if (TopFilters?.Keyword?.length > 0) {
        queryparams = queryparams + `searchMode=${TopFilters?.Keyword}`;
      }
      if (TopFilters?.State?.length > 0) {
        TopFilters.State.map((item) => {
          queryparams = queryparams + `state[]=${item.code}&`;
          return item.code;
        }); 
      }
      let Resibody= {
        pageNumber:Number(TopFilters?.Page),
        pageSize: Number(TopFilters?.PerPage),
        keywords: [
          TopFilters?.Keyword || ""
        ],
        listingType: TopFilters?.ListingType[0]?.code || "",  
        locations: [
            {
                postcode:TopFilters?.PostCode[0]?.code || "",
                state: TopFilters?.State[0]?.code || "",
                region: "",
                suburb: "",
                area: "",
                street: ""
            }
        ],
        propertyTypes:propertyTypes|| [],
        sort: {
            sortKey:"",
            direction:""
        }
    };
    let Commbody= {
      pageNumber:Number(TopFilters?.Page),
      pageSize: Number(TopFilters?.PerPage),
      locations: [
        {
            postcode:TopFilters?.PostCode[0]?.code || "",
            state: TopFilters?.State[0]?.code || "",
            region: "",
            suburb: "",
            area: "",
            street: ""
        }
    ],
      keywords: [
        TopFilters?.Keyword || ""
      ],
      searchMode: TopFilters?.ListingType[0]?.code || "",  
      price: {
        "min": 0,
        "max": 1000000000,
        type: "totalAmount"           
      },
      propertyTypes:propertyTypes|| [],
      sort: {
          sortKey:"",
          direction:""
      }
  };
      let result = await fetch(
        `${process.env.REACT_APP_API_BASEURL}${url}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
          body:propertyType=='Residential'? JSON.stringify(Resibody):JSON.stringify(Commbody)
        }
      )
        .then((response) => response.json())
        .then((property) => property);
      if (result) {
        let newArr = [];
        console.log(result,"result")
        if (propertyType == "Commercial") {
          newArr = result.data.map((item) => {
            let newObj = { 
              ...item,
              id: item?.propertyDetails?._id || "",
              propertyId: item?.propertyDetails?.id || "",
              Comments: "",
              Client: "",
              Listing: item?.ad?.url || "",
              Address: item?.address || "",
              Suburb:
                item?.metadata?.addressComponents?.suburb ||
                "",
              State:
              item?.metadata?.addressComponents?.stateShort || "",
              Postcode:
              item?.metadata?.addressComponents?.postcode ||
                "",
              PropertyType: item?.propertyType || "",
              Price: item?.price || "",
              NetRent: "",
              Outgoing: "",
              YieldReturn: "",
              LandArea: item?.LandArea || "",
              FloorArea: item?.propertyArea || "",
              TenureType: "",
              NumofTenants: "",
              Occupancy: "",
              TenantName: "",
              LeaseExpiry: "",
              Description: "",
              SWOT: "",
              SuburbProfile: "",
              DaysonMarket: "",
              AdvertUpdateDate: "",
              AgencyName:item?.advertiser?.name || "",
              AgentName: item?.advertiser?.contacts[0]?.displayFullName || "",
              AgentContact: item?.advertiser?.contacts[0]?.phoneNumbers[0]?.number || "",
              AgentEmail: "",
              SaleAuction: "",
              SaleExpressionofInterest: "", 
              TotalCarSpaces: item?.carspaceCount || 0,
              Zoning: "",
              Municipality: "",
              Image:item?.media[0]?.imageUrl||"" 
            }; 
            return newObj;
          });
        } else {
          newArr = result.data.map((item) => {
            let descp = item?.listing?.summaryDescription
              ?.replace(/<b>(.*?)<\/b>/g, "")
              .replace(/<br \/>/g, ""); 
              let Features=""
              item?.listing?.propertyDetails?.features?.map((item,index)=>{
                if(index==0){
                    Features=Features+" "+item
                }else{
                  Features=Features+" , "+item
                }
              })
            let newObj = {
              ...item,
              id: item?.listing?.id || "",
              propertyId: item?.listing?.id || "",
              Listing: item?.listing?.advertiser?.bannerUrl  || "",
              ListingType:item?.listing?.listingType||"", 
              Address:item?.listing?.propertyDetails?.displayableAddress || "",
              Suburb:item?.listing?.propertyDetails?.suburb || "",
              State:item?.listing?.propertyDetails?.state || "",
              Postcode:item?.listing?.propertyDetails?.postcode || "",
              Bedrooms:item?.listing?.propertyDetails?.bedrooms || "",
              Bathrooms:item?.listing?.propertyDetails?.bathrooms ||"",
              Ensuites: "",
              Toilets: item?.listing?.propertyDetails?.bathrooms ||"",
              HouseSize:item?.listing?.propertyDetails?.buildingArea|| "",
              LandSize: item?.listing?.propertyDetails?.landArea ||"",
              Price: item?.listing?.priceDetails?.displayPrice  || "",
              Rent: "",
              Yield: "",
              Headline: item?.listing?.headline || "",
              Description: descp || "",
              SWOT: "",
              PropertyType:
                item?.propertyDetails?.listing?.propertyDetails?.propertyType ||
                "",
              Outgoing: "",
              LandArea:
                item?.listing?.propertyDetails?.landArea || "",
              FloorArea:
                item?.listing?.propertyDetails?.buildingArea ||
                "",
              TenureType: "",
              NumofTenants: "",
              Occupancy: "",
              TenantName: "",
              LeaseExpiry: "",
              SuburbProfile: "",
              DaysonMarket: item?.listing?.dateListed|| "",
              AdvertUpdateDate: item?.listing?.dateUpdated|| "",
              AgencyName: item?.listing?.advertiser?.name ||"",
              AgentName:item?.listing?.advertiser?.contacts[0]?.name ||"",
              AgentContact: "",
              AgentEmail: "",
              SaleAuction: "",
              SaleExpressionofInterest: "",
              TotalCarSpaces:
                item?.listing?.propertyDetails?.carspaces ||
                "",
              Zoning: "",
              Municipality: "",
              Image:item?.listing?.media[0]?.url||"",
              Features:Features

            };
            return newObj;
          });
        }
        let total = result.totals.reduce((prevItem, nexItem) => {
          prevItem = prevItem + nexItem.total;
          return prevItem; 
        }, 0);
        setTotal(total)
        setPropertyoverview(result.totals);
        setTableData(newArr); 
        setIsLoading(false);
      }
    };
    CallListingApi();
  }, [TopFilters, propertyType]);
  return { isLoading, TableData,Total,Propertyoverview };
};
