const initialState = []
  
  const BasiqReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_BASIQ_USERS":
        state = action.payload ||[] ;
        return state;
      case "GET_BASIQ_USERS":
        return state;
      default:
        return state;
    }
  };
  export default BasiqReducer;