import styled from "styled-components";
import { DataTable } from "primereact/datatable";
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from 'primereact/multiselect';
export const CustomOverLayPannel=styled(OverlayPanel)`
border-radius: 8px !important;
padding:${({padding})=>padding&& padding};
box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01), 0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01), 0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02), 0 19px 69px 0 rgba(0, 0, 0, 0.03) !important;
&::before{
    display: none !important;
}
&::after{
    display: none !important;
}
.overlayPannelContainer{
    width: 355px;
    .title{
        font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  color: #000;
    }
}
`
export const CustomPanel=styled(Panel)`
width: 200px;
.p-panel-header{
    border: none !important;
    background: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .header-left-content{
        .title{
        font-family: "Inter";
        font-size: 14px;
  font-weight: 500;
    }
    }
    
    .p-panel-title{
        width: 100%;
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .p-panel-icons{
        display: none;
    }
   
   
}
.p-toggleable-content{
        .p-panel-content{
border: none !important;
padding: 0px !important;
}
    }
`
export const CustomMultiSelect=styled(MultiSelect)`
border: 1px solid ${({color})=>color?color:"white"} !important;  //#ced4da
outline: none !important;
.p-multiselect-label-container{
    .p-placeholder{
        font-size: 13px !important;
        font-family: "Inter" !important;
    }
    .p-multiselect-label{
        font-size: 13px !important;
        display: flex !important;
        height: 100% !important;
        padding-left: 10px;
        padding-top: 0px !important;
    padding-bottom: 0px !important;
    align-items: center !important;
    font-family: "Inter" !important;
    .p-multiselect-token{
        border-radius: 15px !important;
    }
    }
}
.p-multiselect-trigger{
    .pi-chevron-down, .pi-chevron-up{
        color: #999999 !important;
    }
}
box-shadow: none !important;
width: ${({width})=>width && width};
height: ${({height})=>height?height:'36px'}!important;
`
export const CustomDropDown=styled(Dropdown)`
border: 1px solid ${({color})=>color?color:"white"} !important;  //#ced4da
outline: none !important;
.p-dropdown-clear-icon{
    top:18px !important;
    right: 2rem !important;
}
.p-dropdown-trigger{
    width: 1rem !important;
    padding-right: 10px !important;
    .pi-chevron-down{
        color: #999999 !important;
    }
}
.p-hidden-accessible .p-dropdown-label{
    color: #999999 !important;
}
.p-dropdown-label{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: flex !important;
    height: 100% !important;
    align-items: center !important;
}
&:hover{
    border: 1px solid ${({color})=>color?color:"white"} !important; 
    outline: none !important;
} 
&:focus{
    border: 1px solid ${({color})=>color?color:"white"} !important; 
}
&::selection{
    border: 1px solid ${({color})=>color?color:"white"} !important; 
}
.p-focus{
    border: 1px solid ${({color})=>color?color:"white"} !important; 
}
width: ${({width})=>width && width};
`
export const PinderTable=styled(DataTable)`

.p-datatable-wrapper{
   
    overflow-x: scroll;
    .p-datatable-table{
       
        .p-datatable-thead{
           
    tr{
        
        th{
            background-color:#a9c8ff !important;
            color:white !important;
            font-size: 12px;
            .p-column-header-content{
                .p-column-title{
                    order:2
                }
                .p-column-filter-menu{
                    margin-left: 0 !important;
                    margin-right: 5px;
                    button{
                        background-color: transparent !important;
                        outline: none;
                        .pi-filter-icon{
                            color:white !important;
                        }
                        &:hover{
                            
                            background-color: transparent !important;
                        }
                        &:focus{
                            outline: none !important;
                            box-shadow: none !important;
                        }
                    }
                }
            }
        }
        .p-selection-column{
            padding-left: 16px;
            .p-checkbox{
                .p-checkbox-box {
                    border: solid 1px #fff !important;
                    background-color: #a9c8ff;
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
  } 
  .p-datatable-tbody{
   
    tr{
        
        td{
            background-color: #e8f3ff ; //!important
            font-size: 12px;
            padding-top: 18px;
            padding-bottom: 19px;
        }
        .p-selection-column{
            padding-left: 16px;
            .p-checkbox{
                .p-checkbox-box {
                    border: solid 1px #a9c8ff !important;
                    background-color: #fff;
                    width: 14px;
                    height: 14px;
                } 
            }
        }
    }
  }  
}
/* Custom Scrollbar Styles */
::-webkit-scrollbar {
    width: 5px !important;
    background: white !important;
    height: 10px !important;
  }

::-webkit-scrollbar-thumb {
    border-radius: 30px !important;
    background-color: #a9c8ff !important;
  }
}



`