export const PerPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
  { label: "200", value: 200 },
  { label: "250", value: 250 },
  { label: "300", value: 300 },
  { label: "350", value: 350 },
  { label: "400", value: 400 },
  { label: "450", value: 450 },
  { label: "500", value: 500 },
];
export const statesOfAustralia = [
  { name: "ACT", code: "ACT" },
  { name: "NSW", code: "NSW" },
  { name: "NT", code: "NT" },
  { name: "QLD", code: "QLD" },
  { name: "SA", code: "SA" },
  { name: "TAS", code: "TAS" },
  { name: "VIC", code: "VIC" },
  { name: "WA", code: "WA" },
];
export const LocalResultsItems = [
  { label: "Residential", value: "residential" },
  { label: "Commercial", value: "commercial" },
];
export const sortOptions = [
  { name: "A to Z", code: "AZ" },
  { name: "Z to A", code: "ZA" },
];
export const LocalCommercialColumnData = [
  {
    header: "Comments",
    field: "Comments",
    style: { maxWidth: "200px" },
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "55px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Client",
    field: "Client",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "45px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Listing",
    field: "Listings",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Image",
    field: "Image",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Listing Type",
    field: "Listing",
    style: { width: "120px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Address",
    field: "Address",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Suburb",
    field: "Suburb",
    style: { width: "150px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "State",
    field: "State",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Property Type",
    
    bodyStyle: { paddingLeft: "40px" },
    field: "PropertyType",
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Price",
    field: "Price",
    
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Net Rent",
    field: "NetRent", 
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },

  {
    header: "Outgoing",
    field: "Outgoing",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },

  {
    header: "Yield/Return",
    field: "YieldReturn",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Land Area",
    field: "LandArea",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Floor Area",
    field: "FloorArea",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Tenure Type",
    field: "TenureType",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Number of Tenants",
    field: "NumofTenants",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Occupancy (AGENT)",
    field: "Occupancy",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Tenant Name (AGENT)",
    field: "TenantName",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Lease Expiry (AGENT)",
    field: "LeaseExpiry",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Description",
    field: "Description",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "SWOT",
    field: "SWOT",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Suburb Profile",
    field: "SuburbProfile",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Days on Market",
    field: "DaysonMarket",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Advert Update Date",
    field: "AdvertUpdateDate",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Name",
    field: "AgentName",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Mobile",
    field: "AgentContact",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Sale-Auction",
    field: "SaleAuction",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Sale-Expression of Interest",
    field: "SaleExpressionofInterest",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Total Car Spaces",
    field: "TotalCarSpaces",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Zoning",
    field: "Zoning",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Municipality",
    field: "Municipality",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
];
export const LiveCommercialColumnData = [
  {
    header: "Listing",
    field: "Listing",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Image",
    field: "Image",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
 
  {
    header: "Address",
    field: "Address",
    // style: { width: "25%" },
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Suburb",
    field: "Suburb",
    style: { width: "150px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "State",
    field: "State",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Property Type",
    style: { width: "300px" },
    bodyStyle: { paddingLeft: "40px" },
    field: "PropertyType",
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Price",
    field: "Price",
    style: { width: "300px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
 
  {
    header: "Land Area",
    field: "LandArea",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Floor Area",
    field: "FloorArea",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Name",
    field: "AgentName",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Mobile",
    field: "AgentContact",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
 
  
  {
    header: "Total Car Spaces",
    field: "TotalCarSpaces",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
 
];
export const LocalResidentialColumnData = [
  {
    header: "Comments",
    field: "Comments",
    style: { maxWidth: "200px" },
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "55px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Client",
    field: "Client",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "55px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Listing",
    field: "Listings",
    style: { width: "100px" },
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Image",
    field: "Image",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Listing Type",
    field: "Listing",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Address",
    field: "Address",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true 
  },
  {
    header: "Suburb",
    field: "Suburb",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "State",
    field: "State",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Bedrooms",
    field: "Bedrooms",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Bathrooms",
    field: "Bathrooms",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Ensuites",
    field: "Ensuites",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Toilets",
    field: "Toilets",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "House Size",
    field: "HouseSize",
    style: { width: "120px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Land Size",
    field: "LandSize",
    style: { width: "120px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Price",
    field: "Price",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },

  {
    header: "Rent",
    field: "Rent",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Yield",
    field: "Yield",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Headline",
    field: "Headline",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Description",
    field: "Description",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "SWOT",
    field: "SWOT",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Suburb Profile",
    field: "SuburbProfile",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Days on Market",
    field: "DaysonMarket",
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Advert Update Date",
    field: "AdvertUpdateDate",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Name",
    field: "AgentName",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Mobile",
    field: "AgentMobile",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Sale-Auction",
    field: "SaleAuction",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Sale-Expression of Interest",
    field: "SaleExpressionOfInterest",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Features",
    field: "Features",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Zoning",
    field: "Zoning",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Municipality",
    field: "Municipality",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Page Views",
    field: "PageViews",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  
];
export const LiveResidentialColumnData = [
  {
    header: "Listing",
    field: "Listing",
    style: { width: "100px" },
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Image",
    field: "Image",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Listing Type",
    field: "ListingType",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "41px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Address",
    field: "Address",
    // style: { width: "25%" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true 
  },
  {
    header: "Suburb",
    field: "Suburb",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "State",
    field: "State",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Postcode",
    field: "Postcode",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Bedrooms",
    field: "Bedrooms",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Bathrooms",
    field: "Bathrooms",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Ensuites",
    field: "Ensuites",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Toilets",
    field: "Toilets",
    style: { width: "100px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "House Size",
    field: "HouseSize",
    style: { width: "120px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Land Size",
    field: "LandSize",
    style: { width: "120px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Price",
    field: "Price",
    style: { width: "250px" },
    bodyStyle: { paddingLeft: "25px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },

  {
    header: "Rent",
    field: "Rent",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Yield",
    field: "Yield",
    style: { width: "130px" },
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Headline",
    field: "Headline",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Description",
    field: "Description",
    style: { width: "350px" },
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Days on Market",
    field: "DaysonMarket",
    bodyStyle: { paddingLeft: "60px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Advert Update Date",
    field: "AdvertUpdateDate",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agency Name",
    field: "AgencyName",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Name",
    field: "AgentName",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Mobile",
    field: "AgentMobile",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Agent Email",
    field: "AgentEmail",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Sale-Auction",
    field: "SaleAuction",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Sale-Expression of Interest",
    field: "SaleExpressionOfInterest",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Features",
    field: "Features",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Zoning",
    field: "Zoning",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Municipality",
    field: "Municipality",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  {
    header: "Page Views",
    field: "PageViews",
    bodyStyle: { paddingLeft: "40px" },
    showFilterMatchModes: false,
    showAddButton: false,
    showFilterMenuOptions: false,
    filter: true,
    selected:true
  },
  
];
export const PropertyColumnData=[
  {
    header: "Created At",
    field: "changeDate",
    style: { maxWidth: "150px" },
  },
  {
    header: "Change By",
    field: "AdminName",
    style: { maxWidth: "150px" },
  },
  {
    header: "Event",
    field: "event",
    style: { maxWidth: "150px" },
  },
  {
    header: "Field Name",
    field: "fieldname",
    style: { maxWidth: "150px" },
  },
  {
    header: "Old Value",
    field: "oldvalue",
    style: { maxWidth: "150px" },
  },
  {
    header: "New Value",
    field: "newvalue",
    style: { maxWidth: "150px" },
  },
]
