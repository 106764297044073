const initialState = {
  data: {
    retail: [],
    industry: [],
    office: [],
    residential: [],
    other: [],
  },
  regionsData: {
    retail: [],
    industry: [],
    office: [],
    residential: [],
    other: [],
  },
};
const DomainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MEDIAN_PRICES":
      state = { ...state, data: action.payload };
    case "SET_REGIONS_DATA":
      state = { ...state, regionsData: action.payload };
      return state;
    default:
      return state;
  }
};
export default DomainReducer;
