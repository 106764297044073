import { useState, useEffect, useRef } from "react";
import classes from "./PinderPage.module.css";

import { InputText } from "primereact/inputtext";
import GradientButton from "../Button/GradientButton";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { PinderTable } from "./pinder.style";
import "./stylesheet.css";
import bookmarkimg from "../../assets/images/Bookmark.svg";
import { CustomDropDown } from "./pinder.style";
import arrowDown from "../../assets/images/arrows-down-up.svg";
import { CustomPanel } from "./pinder.style";
import FilterValueImg from "../../assets/images/cil-dollar.svg";
import { useFetchPinderSummary } from "../../CustomHooks/useFetchPinderSummary";
import { useFetchReportsData } from "../../CustomHooks/useFetchReports";
import { API } from "../../services/api";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import Loading from "../Loading"; 
import { Paginator } from "primereact/paginator";
import { getFieldByPath } from "../../utils";
import ArrowIcon from "../../assets/images/arrowW.png";
import { CustomOverLayPannel } from "./pinder.style";
import { SendMail } from "../../services/global";
import {
  sortOptions,
  LocalCommercialColumnData,
  PropertyColumnData,
} from "../../utils/constants";
import { HandleFilter } from "../../utils";
import { CustomPaginator } from "./CustomPaginator";
import { CustomColumn } from "../CustomColumn/CustomColumn";
import moment from "moment";
import HistoryImg from "../../assets/SVG/History.svg";
import HistoryImg2 from "../../assets/SVG/HistoryWhite.svg";
import ReportImg from "../../assets/SVG/Report.svg";
import OverviewImg from "../../assets/SVG/Overview.svg";
import OverviewImg2 from "../../assets/SVG/overview2.svg";
import EditReportImg from "../../assets/SVG/ReportEdit.svg";
import Eyeclosed from "../../assets/SVG/eye-closed.svg";
import RevertImg from "../../assets/SVG/undo.svg";
import RevertImg2 from "../../assets/SVG/undo1.svg";
import EmailImg from "../../assets/SVG/Email.svg";
import EmailImg2 from "../../assets/SVG/EmailWhite.svg";
import AnimatedHeight from "../AnimateHeight";
import { useFetchPinderLocalData } from "../../CustomHooks/useFetchPinderLocalData";
import { useSelector } from "react-redux";
import Tooltip from "../ToolTip/ToolTip";
import {SSE} from 'sse'
import { commaStringSeparators, commaSeparators } from "../../utils";

const LocalCommercial = ({ TopFilters, setTopFilters, Clear }) => {
  let VendorsData = useSelector((state) => state?.Vendors);


  const TableRef = useRef(null);
  const saveRef = useRef(null);
  const getRef = useRef(null);
  const toast = useRef(null);
  const toast1 = useRef(null);
  const columnRef = useRef(null);
  const clientRef = useRef(null);
  const [propertyHistory, setpropertyHistory] = useState([]);
  const [DialogVisible, setDialogVisible] = useState(false);
  const [HistoryVisible, setHistoryVisible] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [editingRows, seteditingRows] = useState([]);
  const [ReportName, setReportName] = useState("");
  const [ClientOptions, setClientOptions] = useState([]);
  const [ReportOptions, setReportOptions] = useState([]);
  const [Client, setClient] = useState("");
  const [ClientId, setClientId] = useState("");
  const [ReportId, setReportId] = useState("");
  const [selectedSort, setselectedSort] = useState({
    name: "A to Z",
    code: "AZ",
  });
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isPanelOpen2, setIsPanelOpen2] = useState(false);
  const [LocalColumns, setLocalColumns] = useState(LocalCommercialColumnData);
  const [visibleColumns, setVisibleColumns] = useState(
    LocalCommercialColumnData
  );
  const [PropertiesID, setPropertiesId] = useState([]);
  const [CurrentEditField, setCurrentEditField] = useState({
    fieldname: "",
    oldvalue: "",
    newvalue: "",
    propertyId: 0,
  });
  const [callingGetApi, setcallingGetApi] = useState(false);

  const handleReportOptions = (data) => {
    setReportOptions(data);
  };
  const handleColumns = (data) => {
    if (data?.length > 0) {
      let visiblecols = data.filter((item) => item.selected == true);
      setVisibleColumns(visiblecols);
      setLocalColumns(data);
    } else {
      setVisibleColumns(LocalCommercialColumnData);
      setLocalColumns(LocalCommercialColumnData);
    }
  }; 
  
  const { isReportLoading } = useFetchReportsData(
    "all-report-local",
    "commercial",
    ReportId,
    ClientId,
    ReportOptions,
    TopFilters,
    setTopFilters,
    handleReportOptions,
    handleColumns
  );
  const [initialLoader, setinitialLoader] = useState(true);
  const { isLoading, TableData, totalResult } = useFetchPinderLocalData(
    "get-local-properties/commercial?",
    TopFilters,
    "Commercial",
    callingGetApi,
    initialLoader,
    VendorsData
  );
  const { Total, Propertyoverview } = useFetchPinderSummary(
    "local-properties-totals/commercial"
  );

  let PerPage = TopFilters.PerPage === 0 ? 50 : TopFilters.PerPage;

  let totalPages = Math.ceil(totalResult / PerPage);

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        className="p-button-sm"
        onClick={options.filterApplyCallback}
        severity="success"
      >
        Apply
      </Button>
    );
  };

  const AscDecFilterTemplate = (options) => {
    const applySort = (value, e) => {
      if (value?.code == "AZ") {
        setFilterData(HandleFilter(options.field, 1, TableData));
      } else {
        setFilterData(HandleFilter(options.field, -1, TableData));
      }
    };

    const template = (options) => {
      const toggleIcon = options.collapsed
        ? "pi pi-chevron-right "
        : "pi pi-chevron-down";
      const className = `${options.className}`;

      return (
        <div className={className}>
          <div className="header-left-content">
            <img src={FilterValueImg} />
            <span className="title">Filter By Value</span>
          </div>
          <span className={toggleIcon} onClick={options.onTogglerClick}></span>
        </div>
      );
    };

    return (
      <>
        <div className={classes.filterheader}>
          <p>Filter</p>
          <span>Clear</span>
        </div>
        <div className={classes.filtersort}>
          <p>
            <img src={arrowDown} width="14px" height="14px" /> Sort by
          </p>
          <CustomDropDown
            value={selectedSort}
            onChange={(e) => {
              setselectedSort(e.value);
              applySort(e.value, e);
            }}
            options={sortOptions}
            optionLabel="name"
          />
        </div>
        <div>
          <div>
            <CustomPanel
              collapsed={!isPanelOpen}
              onToggle={() => setIsPanelOpen(!isPanelOpen)}
              headerTemplate={template}
              toggleable
            >
              <InputText
                placeholder={options?.placeholder}
                value={options.value}
                onChange={(e) =>
                  options.filterCallback(e.target.value, options.index)
                }
              />
            </CustomPanel>
          </div>
        </div>
      </>
    );
  };
  const cellEditor = (options) => {
    if (options.field === "price") return textEditor(options);
    else return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    event.preventDefault();
    const existingRowIndex = editingRows.findIndex(
      (item) => item._id === rowData._id
    );
    let oldvalue = rowData[field];
    rowData[field] = newValue;

    setCurrentEditField({
      fieldname: field,
      oldvalue: oldvalue,
      newvalue: newValue,
      propertyId: rowData.propertyId,
    });
    let ExistingProp = PropertiesID.filter(
      (item) => item == rowData.propertyId
    );
    if (ExistingProp.length == 0) {
      setPropertiesId([...PropertiesID, rowData.propertyId]);
    }
    if (existingRowIndex !== -1) {
      const updatedRows = [...editingRows];
      updatedRows[existingRowIndex] = {
        ...updatedRows[existingRowIndex],
        [field]: newValue,
      };

      seteditingRows(updatedRows);
    } else {
      seteditingRows((prevEditingRows) => [...prevEditingRows, rowData]);
    }
  };

  const showSuccess = (Message) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px", width: "100%" }}
        >
          <div className="text-center ">
            <p className="popup-warn-text">{Message}</p>
          </div>
        </div>
      ),
    });
  };
  const SaveData = async () => {
    try {
      let newobj = {
        client: Client,
        reportName: ReportName,
        filters: TopFilters,
        Columns: LocalColumns,
      };

      let response = await API.post(
        "save-report-local-properties/commercial",
        newobj
      );
      let propertyIds = [];
      TableData.map((item) => {
        propertyIds = [...propertyIds, item.propertyDetails.id];
      });
      
      showSuccess(response.message);
      if (response?.id) {
        let newobj2 = {
          clientId: Client,
          reportId: response?.id,
          reportName: ReportName,
          properties: propertyIds,
        };
        let response2 = await API.post(
          "attach-client-reports/commercial",
          newobj2
        );
        console.log(response2);
        setLocalColumns(LocalCommercialColumnData);
        setVisibleColumns(LocalCommercialColumnData);
        setcallingGetApi((prevVal) => !prevVal);
        setClient("");
        setReportName("");
      }
    } catch (err) {
      showSuccess("Something Went Wrong");
    }
  };

  const renderHeader = (title) => {
    return (
      <div>
        <p>{title}</p>
      </div>
    );
  };
  const prevBtnNavigation = (e) => {
    e.preventDefault();
    var tableContainer = document.querySelector(".p-datatable-wrapper");
    tableContainer.scrollLeft -= 550;
  };

  const nextBtnNavigation = (e) => {
    e.preventDefault();
    var tableContainer = document.querySelector(".p-datatable-wrapper");
    tableContainer.scrollLeft += 550;
  };
  const rowClass = (data) => {
    let check = data?.Background || false;
    // console.log(data,"data")
    return {
      rowbackground: check,
    };
  };
  const DeleteReport = async () => {
    let body = {
      id: ReportId,
      client: ClientId,
    };
    try {
      let result = await fetch(
        `${process.env.REACT_APP_API_BASEURL}delete-report-properties/commercial`, ///${ReportId}
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
          body: JSON.stringify(body),
        }
      );
      if (result) {
        setReportId("");
        setClientId("");
        setTopFilters({
          PostCode: [],
          Page: 1,
          PerPage: 0,
          MaxPrice: 0,
          MinPrice: 0,
          PropertyType: [],
          ListingType: [],
          Keyword: "",
          State: [],
        });

        showSuccess("report deleted");
      }
    } catch (err) {}
  };
  const EditReport = async () => {
    try {
      let newobj = {
        client: ClientId,
        filters: TopFilters,
        Columns: LocalColumns,
      };

      let response = await API.put(
        `update-report-local-properties/${ReportId}`,
        newobj
      );
      showSuccess(response.message);
    } catch (err) {
      showSuccess("Something Went Wrong");
    }
  };
  const HistoryBodyTemplate = (rowData) => {
    let check = rowData.Background;
    return (
      <>
        <Button
          onClick={() => {
            let HistoryArr = rowData.propertyHistory.map((item) => {
              let FieldArr = item.ChangeFields.map((item1) => {
                let newobj = {
                  ...item1,
                  AdminId: item.AdminId || "",
                  AdminName: item?.AdminName || "",
                };
                return newobj;
              });
              return FieldArr;
            });
            let arr = [];
            HistoryArr.map((item) => {
              item.map((item2) => {
                arr.push(item2);
              });
            });

            setpropertyHistory(arr);
            setHistoryVisible(true);
          }}
          className="flex justify-content-center align-items-center text-sm "
          style={{
            width: "35px",
            height: "36px",
            color: "#262660",
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            background: "none",
            border: "none",
          }}
          type="button"
          severity="success"
        >
          {check ? <img src={HistoryImg2} /> : <img src={HistoryImg} />}
        </Button>
      </>
    );
  };
  const EmailBodyTemplate = (rowData) => {
    let check = rowData?.Background || false;
    const SendEmail = async() => {
      if((rowData?.SuburbProfile!==null && rowData?.SuburbProfile!=="")||(rowData?.SWOT!==null && rowData?.SWOT!=="")){
        const email ="";
        const subject = "Profile";
        const bodyObj = {
        }; 
        let text = document.getElementById('mailtext').innerHTML;
        try {
          await navigator.clipboard.writeText(text);
          
          showSuccess("Text Copied")
        } catch (err) {
          
          showSuccess("Failed to copy")
         
        }
        SendMail(bodyObj, email, subject);
      }
      
    };
    return (
      <>
        <Button
          onClick={SendEmail}
          className="flex justify-content-center align-items-center text-sm "
          style={{
            width: "35px",
            height: "36px",
            color: "#262660",
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            background: "none",
            border: "none",
            outline:"none",
            border:"none"
          }}
          type="button"
          severity="success"
        >
          {check ? <img src={EmailImg2} /> : <img src={EmailImg} />}
        </Button>
        <div id="mailtext" style={{display:"none",textAlign:"left"}}>
{`
Dear ${rowData?.ClientNames || ""} 
         
I am writing to you because we have identified a property opportunity located at ${rowData?.Address||""} that we think would suit your investment profile. 
${rowData?.Description}
          
Check out a very high-level SWOT Analysis;
${rowData?.SWOT||""}
 
Check out this insight into the area;
${rowData?.SuburbProfile||""}
 
Thanks ${rowData?.ClientNames || ""} 
          
       `}
        </div>
      </>
    );
  };
  const DeleteSaveProperty = async (propertyId) => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}delete-local-properties/commercial/${propertyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
        }
      );
      if (response.status == 201) {
        setcallingGetApi((prevVal) => !prevVal);
      }
    } catch (err) {}
  };
  const RevertBodyTemplate = (rowData) => {
    let check = rowData.Background;
    
    return (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => DeleteSaveProperty(rowData.propertyDetails.id)}
      >
        {check ? (
          <img src={RevertImg2} style={{ width: "20px" }} />
        ) : (
          <img src={RevertImg} style={{ width: "20px" }} />
        )}
      </span>
    );
  };
  const DateBodyTemplate = (rowData) => {
    return <p>{moment(rowData.createdAt).format("DD/MM/YYYY")}</p>;
  };
  const AdvertDateBodyTemplate = (rowData) => {
    let date = rowData?.AdvertUpdateDate
      ? moment(rowData?.AdvertUpdateDate).format("DD/MM/YYYY h:mma")
      : "";

    return <p>{date}</p>;
  };
  const SearchModeTemplate = (rowData) => {
    let listing =
      rowData.Listing.charAt(0).toUpperCase() + rowData.Listing.slice(1);
    let capitalLetterCount = 0;
    let indexSecondCapitalLetter = -1;
    for (let i = 0; i < listing.length; i++) {
      if (listing[i] === listing[i].toUpperCase()) {
        capitalLetterCount++;
        if (capitalLetterCount === 2) {
          indexSecondCapitalLetter = i;
          break;
        }
      }
    }
    if (indexSecondCapitalLetter != -1) {
      listing =
        listing.slice(0, indexSecondCapitalLetter) +
        " " +
        listing.slice(indexSecondCapitalLetter);
    }
    return <span>{listing}</span>;
  };
  const AdminBodyTemplate = (rowData) => {
    return <p>{rowData.AdminId}</p>;
  };
  const ToolTipComp = (ClientNames) => {
    let [idx, setIdx] = useState("6");
    let ClientArr = ClientNames?.ClientNames?.split(",");
    return (
      <div className="ToolTip-container">
        <div className="ToolTip">
          <Tooltip
            top="25px"
            width="270px"
            textAlign="left"
            show={idx === "hovering" ? true : false}
            txt={
              <div
                style={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                {ClientArr?.length > 0 &&
                  ClientArr?.map((item, index) => {
                    return <span>{item}</span>;
                  })}
              </div>
            }
          />
        </div>
        <div style={{ width: "70px", height: "40px", overflow: "hidden" }}>
          <p
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            onMouseEnter={() => setIdx("hovering")}
            onMouseLeave={() => setIdx(6)}
          >
            {ClientNames?.ClientNames}
          </p>
        </div>
      </div>
    );
  };
  const ClientBodyTemplate = (rowData) => {
    return (
      <>
        <ToolTipComp ClientNames={rowData.Client} />
      </>
    );
  };
  const ImageTipComp = (Image) => {
    
    let [idx, setIdx] = useState("6");
    return (
      <div className="ToolTip-container">
        <div className="ToolTip">
          <Tooltip
            top="25px"
            width="320px"
            textAlign="left"
            show={idx === "hovering" ? true : false}
            txt={
              <img
                style={{ width:"300px",height:"300px" }}
                src={Image?.Image}
                alt="image"
              />
               
              
            }
          />
        </div>
        <a
            style={{ cursor:"pointer" }}
            onMouseEnter={() => setIdx("hovering")}
            onMouseLeave={() => setIdx(6)}
          >
            Image
          </a>
      </div>
    );
  };
  const ImageBodyTemplate=(rowData)=>{
   
    return(
      <>
      <ImageTipComp  Image={rowData.Image}/>
      </>
    )
  }
  const HistoryDialogContent = () => {
    return (
      <div className="pinderDialogBodyContainer">
        <PinderTable
          paginator
          rows={10}
          editMode="cell"
          className="propertyDataTable"
          value={propertyHistory}
          tableStyle={{ width: "100%" }}
        >
          {PropertyColumnData.map((item, index) => {
            return (
              <Column
                key={index}
                field={item.field}
                header={item.header}
                style={item.style}
                body={
                  item.field == "changeDate"
                    ? DateBodyTemplate
                    : item.field == "AdminId" && AdminBodyTemplate
                }
              ></Column>
            );
          })}
        </PinderTable>
      </div>
    );
  };
  const onColumnToggle = (header) => {
    const updatedColumns = LocalColumns?.map((column) => {
      if (column.header === header) {
        return { ...column, selected: !column.selected };
      }
      return column;
    });
    setLocalColumns(updatedColumns);

    let visblecolumns = updatedColumns.filter((item) => item.selected == true);

    setVisibleColumns(visblecolumns);
    // SaveMyColumns(updatedColumns);
  };
  const SaveMyColumns = async (Columns) => {
    let body = {
      Columns: Columns,
    };
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}save-my-columns/commercial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
          body: JSON.stringify(body),
        }
      );
    } catch (err) {}
  };
  const PriceBodyTemplate = (rowData) => {
    return <span>${rowData.extractedPrice}</span>;
  };
  const getMyColumns = async () => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}get-my-columns/commercial`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => data);
      if (response?.data?.length > 0) {
        let userId = sessionStorage.getItem("userId");
        let filteredUserColumns = response.data.filter(
          (item) => item.userId == userId
        );
        if (filteredUserColumns?.length > 0) {
          setLocalColumns(filteredUserColumns[0].Columns);
          let visiblecols = filteredUserColumns[0].Columns.filter(
            (item) => item.selected == true
          );
          setVisibleColumns(visiblecols);
        }
      } else {
        setLocalColumns(LocalCommercialColumnData);
      }
    } catch (err) {}
  };

  useEffect(() => {
    setFilterData(TableData);
  }, [TableData]);

  useEffect(() => {
    let newoptionsArr = VendorsData.map((item) => {
      let newobj = {
        label: item.full_name,
        value: item._id,
      };
      return newobj;
    });
    setClientOptions(newoptionsArr);
  }, [VendorsData]);

  const SavePropertyData = async () => {
    const currentEditProp = editingRows.filter(
      (item) => item.propertyId === CurrentEditField.propertyId
    );
    let PropertyHistory = currentEditProp[0]?.propertyHistory || [];

    if (PropertyHistory.length > 0) {
      let AdminId = sessionStorage.getItem("userId");
      PropertyHistory = PropertyHistory?.filter(
        (item) => item.AdminId == AdminId
      );

      if (PropertyHistory.length > 0) {
        PropertyHistory = PropertyHistory[0]?.ChangeFields || [];
      } else {
        PropertyHistory = [];
      }
    } else {
      PropertyHistory = [];
    }

    let AdminId = sessionStorage.getItem("userId");
    let userName = sessionStorage.getItem("name");
    let body = {
      AdminPropertyChanges: {
        AdminId: AdminId,
        propertyDetails: {
          ...currentEditProp[0]?.propertyDetails,
          Comments: currentEditProp[0]?.Comments || "",
          Client: currentEditProp[0]?.Client || "",
          NetRent: currentEditProp[0]?.NetRent || "",
          Outgoing: currentEditProp[0]?.Outgoing || "",
          YieldReturn: currentEditProp[0]?.YieldReturn || "",
          TenureType: currentEditProp[0]?.TenureType || "",
          NumofTenants: currentEditProp[0]?.NumofTenants || "",
          Occupancy: currentEditProp[0]?.Occupancy || "",
          TenantName: currentEditProp[0]?.TenantName || "",
          LeaseExpiry: currentEditProp[0]?.LeaseExpiry || "",
          SWOT: currentEditProp[0]?.SWOT || "",
          SuburbProfile: currentEditProp[0]?.SuburbProfile || "",
          AgencyName: currentEditProp[0]?.AgencyName || "",
          AgentName: currentEditProp[0]?.AgentName || "",
          AgentContact: currentEditProp[0]?.AgentContact || "",
          AgentEmail: currentEditProp[0]?.AgentEmail || "",
          SaleAuction: currentEditProp[0]?.SaleAuction || "",
          SaleExpressionofInterest:currentEditProp[0]?.SaleExpressionofInterest || "",
          Zoning: currentEditProp[0]?.Zoning || "",
          Municipality: currentEditProp[0]?.Municipality || "",

          address:currentEditProp[0]?.Address || "",

          metadata:{
            ...currentEditProp[0].propertyDetails.metadata,
            addressComponents:{
              ...currentEditProp[0].propertyDetails.metadata.addressComponents,
              suburb:currentEditProp[0]?.Suburb || "",
              stateShort:currentEditProp[0]?.State || "",
              postcode:currentEditProp[0]?.Postcode || "",
            },
          },
          propertyType:currentEditProp[0]?.PropertyType || "",
          price:currentEditProp[0]?.Price || "",
         
          listDetails:{
            ...currentEditProp[0].propertyDetails.listDetails,
            landAreaSqm:currentEditProp[0]?.LandArea || "",
            buildingAreaSqm:currentEditProp[0]?.FloorArea || "",
            description:currentEditProp[0]?.Description || "",
            dateListed:currentEditProp[0]?.AdvertUpdateDate || "",
            carspaces:currentEditProp[0]?.TotalCarSpaces || 0,
          },
        },
      },
      propertyHistory: {
        AdminId: AdminId,
        AdminName: userName,
        propertyId: CurrentEditField.propertyId,
        ChangeFields:
          PropertyHistory?.length > 0
            ? [
                ...PropertyHistory,
                {
                  changeDate: Date.now(),
                  event: "Update",
                  fieldname: CurrentEditField.fieldname,
                  oldvalue: CurrentEditField.oldvalue,
                  newvalue: CurrentEditField.newvalue,
                },
              ]
            : [
                {
                  changeDate: Date.now(),
                  event: "Update",
                  fieldname: CurrentEditField.fieldname,
                  oldvalue: CurrentEditField.oldvalue,
                  newvalue: CurrentEditField.newvalue,
                },
              ],
      },
    };

    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}set-property-changes/commercial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
          },
          body: JSON.stringify(body),
        }
      );
      if (response.status === 201) {
        seteditingRows([]);
        setcallingGetApi((prevVal) => !prevVal);
      }
    } catch (err) {}
  };
  const ActiveDateBodyTemplate = (rowData) => {
    let currentDate = new Date();
    let listingDate = new Date(rowData?.DaysonMarket);
    let timeDifference = currentDate - listingDate;
    let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return <span>{daysDifference || ""}</span>;
  };
  const RentBodyTemplate = (rowData) => {
    return <p>{commaSeparators(rowData.NetRent)}</p>;
  };
  const LinkBodyTemplate = (options) => {
    return (
      <a
        style={{ color: "blue" }}
        href={`${options?.Listings}`}
        target="_blank"
      >
        Link
      </a>
    );
  };
  const onGetDataComplete = (rowData, newValue, field) => {
    const existingRowIndex = editingRows.findIndex(
      (item) => item._id === rowData.id
    );
    rowData[field] = newValue;
    setCurrentEditField({
      fieldname: field,
      oldvalue: "",
      newvalue: newValue,
      propertyId: rowData.propertyId,
    });
    if (existingRowIndex !== -1) {
      const updatedRows = [...editingRows];

      updatedRows[existingRowIndex] = {
        ...updatedRows[existingRowIndex],
        [field]: newValue,
      };

      seteditingRows(updatedRows);
    } else {
      seteditingRows((prevEditingRows) => [...prevEditingRows, rowData]);
    }
  };
  const SuburbProfile = ({ rowData }) => {
    const [profile, setprofile] = useState(rowData?.SuburbProfile || "");
    const [loading, setloading] = useState(false);
    const [open, setOpen] = useState(rowData?.SuburbProfile!==""? false:true);
    const height = open ? "auto" : 0;
    
   
    
    const HandleClick = () => {
      setOpen(!open);
    };
    const CallingChatGpt=async()=>{
      try{
        const conversation = [
          { role: 'system', content: 'You are a writer creating a suburb profile.' },
          { role: 'user', content: `Name this article Suburb Profile - (2. Suburb:${rowData.Suburb}, 2. State: ${rowData.State}, 2. Postcode: ${rowData.Postcode}). Additionally, include a 50-word InvestLogic Disclaimer at the beginning of the profile. This disclaimer should inform readers that the profile is based on available data and does not constitute investment advice.` },
      ];
      const apiUrl = 'https://api.openai.com/v1/chat/completions';
      const apiKey = process.env.REACT_APP_OPENAI_API_KEY; 
      let apidata=""
      const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
              model: 'gpt-3.5-turbo',
              messages: conversation,
              temperature: 0.8,
              max_tokens: 256,
              stream: true,
          }),
          stream:true
      }).catch((err)=>{
        showSuccess(err);
      });
      const reader = response.body.getReader();
      const decoder=new TextDecoder("utf-8")
      while(true){
        const { done, value } = await reader.read();
        if(done){
          break;
        }
        const decodedChunk=decoder.decode(value)
        const lines=decodedChunk.split("\n")
        const parsedLines=lines.map((line)=>line.replace(/^data: /,"").trim()).filter((line)=>line!=="").map((line)=>{
          if(line!=="[DONE]"){
          return  JSON.parse(line)
          }else{
            return line
          }
        }) //&& line!=="[DONE]"
        for(const parsedLine of parsedLines){
          if(parsedLine=="[DONE]"){
        
            onGetDataComplete(rowData, apidata, "SuburbProfile");
            break;
          }else{
            const {choices}=parsedLine
            const {delta}=choices[0]
            const {content}=delta
            if(content){
              apidata+=content
              setprofile((prev)=>prev+content)
              setloading(false)
            }
          }
         
        }
      }
      }catch(err){
        showSuccess(err);
      }
      }
      const GetSuburbData = async (e) => {
        e.preventDefault();
        setloading(true); 
        CallingChatGpt();
      };
    return (
      <>
        {profile.length == 0 ? (
          <>
            <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
              <GradientButton
                onClick={GetSuburbData}
                icon={undefined}
                classes="text-sm"
                style={{ width: "100px", height: "36px" }}
                txt="Generate AI"
              />
              {loading == true && (
                <div className="px-1">
                  <Loading height="0" />
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <AnimatedHeight duration={500} height={height} minheight={"40px"}>
              <button
                style={{
                  whiteSpace: "wrap",
                  textAlign: "left",
                  background: "transparent",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  border: "none",
                  color: "#495057",
                }}
                onClick={HandleClick}
              >
                {profile}
              </button>
              
            </AnimatedHeight>

            
          </>
        )}
      </>
    );
  };
  const SWOTProfile = ({ rowData }) => {
    const [profile, setprofile] = useState(rowData?.SWOT || "");
    const [loading, setloading] = useState(false);
    const [open, setOpen] = useState(false);
    const height = open ? "auto" : 0;
    const GetSWOTData = async () => {
      setloading(true);
      try {
        await fetch(
          `${process.env.REACT_APP_API_BASEURL}chat-gpt/suburb?suburb=${rowData.Suburb}&postcode=${rowData.Postcode}&state=${rowData.State}&swot=1`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data?.gptData) {
              onGetDataComplete(rowData, data.gptData, "SWOT");
              setprofile(data.gptData);
            } else {
              setloading(false);
              showSuccess(data?.message);
            }
          });
      } catch (err) {}
    };
   
    const HandleClick = () => {
      setOpen(!open);
    };
    return (
      <>
        {profile.length == 0 ? (
          <>
            <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
              <GradientButton
                onClick={GetSWOTData}
                icon={undefined}
                classes="text-sm"
                style={{ width: "100px", height: "36px" }}
                txt="Generate AI"
              />
              {loading == true && (
                <div className="px-1">
                  <Loading height="0" />
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <AnimatedHeight duration={500} height={height} minheight={"40px"}>
              <button
                style={{
                  whiteSpace: "wrap",
                  textAlign: "left",
                  background: "transparent",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  border: "none",
                  color: "#495057",
                }}
                onClick={HandleClick}
              >
                {profile}
              </button>
            </AnimatedHeight>

            
          </>
        )}
      </>
    );
  };
  const SuburbBodyTemplate = (rowData) => {
    return (
      <>
        <SuburbProfile rowData={rowData} />
      </>
    );
  };
  const SWOTBodyTemplate = (rowData) => {
    return (
      <>
        <SWOTProfile rowData={rowData} />
      </>
    );
  };
  const DescriptionComp = ({ rowData }) => {
    const [open, setOpen] = useState(false);
    const height = open ? "auto" : 0;
    const HandleClick = () => {
      setOpen(!open);
    };
    return (
      <>
        <AnimatedHeight duration={500} height={height} minheight={"40px"}>
          <button
            style={{
              whiteSpace: "wrap",
              textAlign: "left",
              background: "transparent",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
              border: "none",
              color: "#495057",
            }}
            onClick={HandleClick}
          >
            {rowData.Description}
          </button>
        </AnimatedHeight>
      </>
    );
  };
  const DescriptionBodyTemplate = (rowData) => {
    return (
      <>
        <DescriptionComp rowData={rowData} />
      </>
    );
  };
  useEffect(() => {
    if (editingRows.length !== 0) {
      const editingRowId = editingRows[0]?.propertyDetails?.propertyId;
      let newfilterdata = filterData.map((item) => {
        if (item.propertyId == editingRowId) {
          let newobj = {
            ...item,
            Background: true,
          };
          return newobj;
        } else {
          return item;
        }
      });
      setFilterData(newfilterdata);
      SavePropertyData();
    }
  }, [editingRows]);
  useEffect(() => {
    if (initialLoader) {
      setinitialLoader(false);
    }
  }, [initialLoader]);
  useEffect(() => {
    if (Clear) {
      setClient("");
      setReportName("");
      setClientId("");
      setReportId("");
    }
  }, [Clear]);
  return (
    <div>
      <Toast ref={toast} />
     
      <Dialog
        header={() => renderHeader("Overview")}
        className="pinder-Dialog"
        visible={DialogVisible}
        style={{ width: "408px", height: "550px", overflowY: "auto" }}
        modal
        onHide={() => setDialogVisible(false)}
      >
        <div className="pinderDialogBodyContainer">
          <div className="TotalContainer flex justify-content-between px-3 py-2">
            <p>Total</p>
            <p>{Total}</p>
          </div>
          {Propertyoverview?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-content-between px-3 py-2"
              >
                <p style={{ width: "150px" }}>{item._id}</p>
                <p>{item.total}</p>
              </div>
            );
          })}
        </div>
      </Dialog>
      <Dialog
        header={() => renderHeader("History")}
        className="pinder-Dialog"
        visible={HistoryVisible}
        style={{
          width: "608px",
          height: "auto",
          minHeight: "550px",
          overflowY: "auto",
        }}
        modal
        onHide={() => setHistoryVisible(!HistoryVisible)}
      >
        {HistoryDialogContent()}
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <span className="result">
          Total Results: {commaStringSeparators(totalResult)}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "7px",
          }}
        >
          <Button
            onClick={(e) => columnRef.current.toggle(e)}
            className="flex justify-content-center align-items-center text-sm bg-white "
            style={{
              width: "35px",
              height: "36px",
              borderColor: "#cccccc",
              color: "#262660",
              fontFamily: "Inter",
              justifyContent: "center",
              alignItems: "center",
            }}
            type="button"
            severity="success"
          >
            <img style={{ width: "15px" }} src={OverviewImg} />
          </Button>
          <Button
            onClick={() => setDialogVisible(true)}
            className="flex justify-content-center align-items-center text-sm bg-white "
            style={{
              width: "35px",
              height: "36px",
              borderColor: "#cccccc",
              color: "#262660",
              fontFamily: "Inter",
              justifyContent: "center",
              alignItems: "center",
            }}
            type="button"
            severity="success"
          >
            <img style={{ width: "15px" }} src={OverviewImg2} />
          </Button>
          {ClientId !== "" && ReportId == "" ? (
            <Button
              className={`flex justify-content-center align-items-center gap-2 text-sm bg-white ${classes.deleteReport}`}
              icon="pi pi-trash"
              type="button"
              severity="success"
              onClick={() => DeleteReport()}
            >
              Delete Shortlist
            </Button>
          ) : (
            ClientId !== "" &&
            ReportId !== "" && (
              <Button
                className={`flex justify-content-center align-items-center gap-2 text-sm bg-white ${classes.deleteReport}`}
                icon="pi pi-trash"
                type="button"
                severity="success"
                onClick={() => DeleteReport()}
              >
                Delete Shortlist
              </Button>
            )
          )}
          {ClientId !== "" && ReportId !== "" ? (
            <GradientButton
              onClick={EditReport}
              classes="text-sm"
              icon={
                <img
                  width="15px"
                  height="15px"
                  className="mr-1"
                  src={EditReportImg}
                />
              }
              style={{ width: "81px", height: "36px" }}
              txt="Edit"
            />
          ) : (
            <GradientButton
              onClick={(e) => {
                saveRef.current.toggle(e);
              }}
              classes="text-sm"
              icon={
                <img
                  width="15px"
                  height="15px"
                  className="mr-1"
                  src={bookmarkimg}
                />
              }
              style={{ width: "81px", height: "36px" }}
              txt="Save"
            />
          )}

          <GradientButton
            onClick={(e) => getRef.current.toggle(e)}
            classes="text-sm"
            icon={
              <img
                width="15px"
                height="15px"
                className="mr-1"
                src={ReportImg}
              />
            }
            style={{ width: "81px", height: "36px" }}
            txt="Clients"
          />
        </div>
        <CustomOverLayPannel ref={saveRef}>
          <div className="overlayPannelContainer">
            <p className="title">Save Data</p>

            <CustomDropDown
              filter
              width="100%"
              color="#ced4da"
              // className={classes.filterDropdown}
              value={Client}
              options={ClientOptions}
              onChange={(e) => {
                setClient(e.value);
                setReportOptions([]);
              }}
              placeholder="Client"
              className="mt-3 mb-3"
            />

            <InputText
              disabled={Client == ""}
              value={ReportName}
              onChange={(e) => setReportName(e.target.value)}
              placeholder="Title"
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <GradientButton
                onClick={(e) => {
                  setClient("");
                  setReportName("");
                  setTopFilters({
                    PostCode: [],
                    Page: 1,
                    PerPage: 0,
                    MaxPrice: 0,
                    MinPrice: 0,
                    PropertyType: [],
                    ListingType: [],
                    Keyword: "",
                    State: "",
                  });
                  setLocalColumns(LocalCommercialColumnData);
                  setVisibleColumns(LocalCommercialColumnData);
                }}
                classes="text-sm"
                icon={
                  <img
                    width="15px"
                    height="15px"
                    className="mr-1"
                    src={bookmarkimg}
                  />
                }
                style={{ width: "81px", height: "36px", marginTop: "30px" }}
                txt="Clear"
              />
              <GradientButton
                onClick={(e) => {
                  SaveData();
                  saveRef.current.hide();
                }}
                classes="text-sm"
                icon={
                  <img
                    width="15px"
                    height="15px"
                    className="mr-1"
                    src={bookmarkimg}
                  />
                }
                style={{ width: "81px", height: "36px", marginTop: "30px" }}
                txt="Save"
              />
            </div>
          </div>
        </CustomOverLayPannel>
        <CustomOverLayPannel ref={getRef}>
          <div className="overlayPannelContainer">
            <p className="title mb-3">Client Shortlist</p>
            <CustomDropDown
              filter
              width="100%"
              color="#ced4da"
              value={ClientId}
              options={ClientOptions}
              onChange={(e) => {
                e.preventDefault();
                setClientId(e.value);
                setReportId("");
              }}
              placeholder="Select Client"
            />
            <div style={{ marginTop: "20px" }}>
              <CustomDropDown
                disabled={ClientId == ""}
                filter
                width="100%"
                color="#ced4da"
                value={ReportId}
                options={ReportOptions}
                onChange={(e) => {
                  setReportId(e.value);
                  setTimeout(() => {
                    getRef.current.hide();
                  }, 1000);
                }}
                placeholder="Select Shortlist"
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <GradientButton
                onClick={(e) => {
                  setClientId("");
                  setReportId("");
                  setTopFilters({
                    PostCode: [],
                    Page: 1,
                    PerPage: 0,
                    MaxPrice: 0,
                    MinPrice: 0,
                    PropertyType: [],
                    ListingType: [],
                    Keyword: "",
                    State: "",
                  });
                  setLocalColumns(LocalCommercialColumnData);
                  setVisibleColumns(LocalCommercialColumnData);
                }}
                classes="text-sm"
                icon={
                  <img
                    width="15px"
                    height="15px"
                    className="mr-1"
                    src={bookmarkimg}
                  />
                }
                disabled={ClientId == ""}
                style={{ width: "81px", height: "36px", marginTop: "30px" }}
                txt="Clear"
              />
            </div>
          </div>
        </CustomOverLayPannel>
        <CustomOverLayPannel padding="none !important" ref={columnRef}>
          <div style={{ height: "400px", overflowY: "auto", margin: "5px " }}>
            {LocalColumns?.map((item) => {
              return (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    onColumnToggle(item.header);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <span style={{ fontSize: "14px" }}>{item.header}</span>
                  {item.selected ? (
                    <img
                      style={{ cursor: "pointer", width: "15px" }}
                      src={OverviewImg}
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer", width: "15px" }}
                      src={Eyeclosed}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </CustomOverLayPannel>
      </div>
      {isLoading ? (
        <Loading />
      ) : filterData.length > 0 ? (
        <>
          <div className={`d-flex relative`}>
            <span
              onClick={prevBtnNavigation}
              className={`${classes.prevNavigationBtn2} ${classes.activeImg}`}
            >
              <img
                src={ArrowIcon}
                className={classes.prevNavigationBtnImg}
                alt="<"
              />
            </span>
            <span
              onClick={nextBtnNavigation}
              className={`${classes.navigationBtn2} ${classes.activeImg}`}
            >
              <img
                src={ArrowIcon}
                className={classes.navigationBtnImg}
                alt=">"
              />
            </span>
          </div>
          <div className="scrollable-table-container"></div>

          <PinderTable
            ref={TableRef}
            editMode="cell"
            className="propertyDataTable"
            value={filterData}
            rows={Number(TopFilters.PerPage)}
            tableStyle={{ minWidth: "400rem", width: "100%" }}
            selectionMode="checkbox"
            selection={selectedCustomers}
            rowClassName={rowClass}
            onSelectionChange={(e) => {
              setSelectedCustomers(e.value);
            }}
          >
            {/* <Column
             
             selectionMode='multiple'
                style={{ width: '3rem'  }}
              ></Column> */}
            <Column
              style={{ width: "1.8rem" }}
              header=""
              body={HistoryBodyTemplate}
            ></Column>
            <Column
              style={{ width: "2.2rem" }}
              header=""
              body={EmailBodyTemplate}
            ></Column>
            <Column
              style={{ width: "1rem" }}
              header=""
              body={RevertBodyTemplate}
            ></Column>
            {visibleColumns.map((item, index) => {
              return (
                <Column
                  key={index}
                  field={item.field}
                  header={item.header}
                  style={item.style}
                  bodyStyle={item.bodyStyle}
                  body={
                    item.header == "Extracted Price"
                      ? PriceBodyTemplate
                      : item.header == "Listing Type"
                      ? SearchModeTemplate
                      : item.header == "Client"
                      ? ClientBodyTemplate
                      : item.header == "Image"
                      ? ImageBodyTemplate
                      : item.header == "Days on Market"
                      ? ActiveDateBodyTemplate
                      : item.header == "Advert Update Date"
                      ? AdvertDateBodyTemplate
                      : item.header == "Net Rent"
                      ? RentBodyTemplate
                      : item.header == "Listing"
                      ? LinkBodyTemplate
                      : item.header == "Suburb Profile"
                      ? SuburbBodyTemplate
                      : item.header == "SWOT"
                      ? SWOTBodyTemplate
                      : item.header == "Description" && DescriptionBodyTemplate
                  }
                  showFilterMatchModes={item.showFilterMatchModes}
                  showAddButton={item.showAddButton}
                  showFilterMenuOptions={item.showFilterMenuOptions}
                  filter={item.filter}
                  filterElement={AscDecFilterTemplate}
                  filterApply={filterApplyTemplate}
                  editor={
                    item.header !== "Client" &&
                    item.header !== "Listing" &&
                    item.header !== "Suburb Profile" &&
                    item.header !== "SWOT" &&
                    item.header !== "Description" &&
                    item.header !== "Image" &&
                    cellEditor
                  }
                  onCellEditComplete={(e) => {
                    onCellEditComplete(e);
                  }}
                ></Column>
              );
            })}
          </PinderTable>
          <div>
            <CustomPaginator
              totalRecords={totalResult}
              Filters={TopFilters}
              FilterParamsData={TopFilters}
              setFilterParamsData={setTopFilters}
              totalPages={totalPages}
            />
          </div>
        </>
      ) : (
        "No Data Found"
      )}
    </div>
  );
};

export default LocalCommercial;
