import { useState, useMemo, useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import Table from "react-bootstrap/Table";
import Pagination from "../Pagination/Pagination";
import Avatar from "../Avatar/Avatar";
import { Image } from "react-bootstrap";
import DeleteIcon from "../../assets/images/Delete-icon.svg";
import EditIcon from "../../assets/images/Edit-icon.svg";
import { API } from "../../services/api";
import { useDispatch,useSelector } from "react-redux";
import { getBasiqUser } from "../../redux/actions";
import { SplitContactType } from "../../services/global";
const ContactTable = ({ VendorsData, UpdateData, SearchQuery }) => {
  const toast = useRef(null);
  const basiqUsers=useSelector((state)=>state.BasiqReducer||[])
  let useremail=""
  if(localStorage.getItem("email")!==null && localStorage.getItem("email")!==undefined){
    useremail=JSON.parse(localStorage.getItem("email"))
  }
  const [AdminAllow,setAdminAllow]=useState(useremail==process.env.REACT_APP_ADMIN_EMAIL)
  console.log(AdminAllow,"adminAllow")
  let [data,setData] = useState(VendorsData || []);
  let PageSize = 50;

  const nPages =
    data?.length > PageSize ? Math.ceil(data?.length / PageSize) : 1;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (SearchQuery !== "") {
      setCurrentPage(1);
    }
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = async (id,email) => {
    let response = await API.delete(`/contacts/${id}`);
    let user=basiqUsers?.filter((item)=>item.email==email)
   
    let basiqToken=sessionStorage.getItem('Token')
    const headers={
      Authorization:`Bearer ${basiqToken}`,
      'Content-Type':'application/json'
    }
    if(user.length>0){
    //   await fetch(`${process.env.REACT_APP_API_BASEURL}basiq/users/${user[0].id}`,
    // {
    //   method:'DELETE',
    //   headers:headers,

    // }
    // )
    // .then((response)=>response.json())
    // .then((data)=>data.data)
    }
    if (response) {
      UpdateData(id);
      showSuccess(response.message);
    }
  };
  const showSuccess = (Message) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px",width:"100%" }}
        >
          <div className="text-center ">
            
            <p className="popup-warn-text">
              {Message}
            </p>
          </div>
          
        </div>
      ),
    });
  };
  // const showSuccess = (Message) => {
  //   toast.current.show({ severity: "success", detail: Message, life: 2000 });
  // };
  useEffect(() => {
    const getAdminData = async () => {
      let response = await API.get(`users?role=610a0f1dc61edfcf62d62bb1`);
      // setalldata(response.data);
      console.log(response,"admin")
      let admindata=response.data.map((item)=>{
        let newObj={
          contact_address: item?.address || "",
          contact_type: item?.role,
          email:item?.email || "",
          first_name: item?.first_name || "",
          full_name:item?.full_name || "",
          last_name:item?.last_name || "",
          mobile_no:item?.mobile || "",
          photo: item.photo,
          telephone_no:item?.telephone || "" ,
          user_id: null,
          _id: item._id
        }
        return newObj
      })
      if(VendorsData!==null){
        let newadminArr=admindata.filter((item)=>item.email!==process.env.REACT_APP_ADMIN_EMAIL)
        setData([...VendorsData,...newadminArr])
      }else{
        let newadminArr=admindata.filter((item)=>item.email!==process.env.REACT_APP_ADMIN_EMAIL)
        setData([...newadminArr])
      }
      console.log(VendorsData,"data",admindata)
      
    };
  
    if((AdminAllow) && (sessionStorage.getItem("accountType")=="Master Admin")){
      getAdminData();
    }else{
      setData(VendorsData)
    }
  }, [VendorsData]);

  return (
    <>
      <Toast ref={toast} />
      <Table responsive>
        <thead>
          <tr>
            <th className="name">Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Address</th>
            <th style={{ width: "7%" }}>Type</th>
            <th style={{ width: "3%" }}></th>
            <th style={{ width: "4.9%" }}></th>
          </tr>
        </thead>
        <tbody>
          {currentTableData?.map((item, idx) => {
            return (
              <tr key={idx}>
             
                <td>
                  <div className="avatar-column">
                    <Link to={`/Contact/?id=${item._id}`}>
                      <Avatar
                        width="30px"
                        height="30px"
                        text={item.full_name}
                        fontSize="8.8px"
                        textColor="#fefefe"
                        imgSrc={item.photo}
                      />
                    </Link>
                    <Link to={`/Contact/?id=${item._id}`}>
                      {item.full_name}
                    </Link>
                  </div>
                </td>
                <td>
                  <Link to={`/Contact/?id=${item._id}`}>{item.email}</Link>
                </td>
                <td>
                  <Link to={`/Contact/?id=${item._id}`}>{item.mobile_no}</Link>
                </td>
                <td>
                  <Link to={`/Contact/?id=${item._id}`}>
                    {item.contact_address}
                  </Link>
                </td>
                <td>
                  
                  {/* {(sessionStorage.getItem("accountType")=="Master Admin") &&  (AdminAllow)?
                <Link to={`/Contact/?id=${item._id}`}>{item.contact_type}</Link>
                :
                <Link to={`/Contact/?id=${item._id}`}>Primary</Link>  
                } */}
<Link to={`/Contact/?id=${item._id}`}>{SplitContactType(item.contact_type)}</Link>
                </td>
                <td className="LineTd">
                  <Link to={`/Contact/?id=${item._id}`}>
                    <Image src={EditIcon} width="15px" height="15px" />
                  </Link>
                </td>
                <td className="delete-icon">
                  <Image
                    src={DeleteIcon}
                    width="15px"
                    height="15px" 
                    onClick={() => handleDelete(item._id,item.email)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination-container">
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default ContactTable;
