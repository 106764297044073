import React, { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import { API } from "../../services/api";
import { GS, publish, subscribe, isWhatPercentOf } from "../../services/global";
import "./css.css";
import EditIcon from "../../assets/images/cil-pencil.png";
import { useNavigate } from "react-router-dom";
import ToolTip from "../ToolTip/ToolTip";
import GradientButton from "../Button/GradientButton";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import {
  Vendors,
  setLockedProperties,
  setCurrentRegions,
  getImagesFromDomain
} from "../../redux/actions";
import { BsEye, BsEyeSlash, BsLockFill, BsUnlockFill } from "react-icons/bs";

import { InputTexts } from "../Equity/Equity.style";
import { commaSeparators, CurrencyFormatter, getMonthName } from "../../utils";
import { ContactDropDown } from "../Contact/Contact.style";
import { setGeneratedProperties } from "../../redux/actions/index";
import { ProgressSpinner } from 'primereact/progressspinner';
import { isInArray } from "../../services/global";
import { SetAutoSave } from "../../redux/actions";
import { useSearchParams } from "react-router-dom";
import ToolTips from "../ToolTip/ToolTips";
function PortfolioSummary(props: any) {
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
   const toast = useRef<any>(null);
   const Savestate1=useSelector((state:any)=>state.AutoSave)
   const[Savestate,setSavestate]=useState(Savestate1)
  const[DSR,setDSR]=useState(props.DSR)
  const [DTI,setDTI]=useState(props.DTI)
  const [Textcolor, setTextcolor] = useState(false);
  const [ScrollPosition, setScrollPosition] = useState(0);
  const [verticalScrollPosition, setVerticalScrollPosition] = useState(0);
  const[showPriceReduce,setshowPriceReduce]=useState(false)
  const [inputLoader, setinputLoader] = useState(false);
  const[idx,setIdx]=useState<any>(6)
  const[editInputVal,seteditInputVal]=useState<any>("")
  const[propertyval,setpropertyval]=useState(
    (
      GS?.SOT?.Equity?.properties?.reduce((total:any,NextProperty:any)=>{
         return total+=NextProperty.REAValuation
      },0)
    )
  ) 

  const [regions, setRegions] = useState<any>({
    industry: Array(),
    other: Array(), 
    residential: Array(),
    office: Array(),
    retail: Array(), 
  });
  const [PageScrollPosition, setPageScrollPosition] = useState(0);
  const { data } = useSelector((state: any) => state.Domain);
  const handlePageScrollPosition = () => {
    const position = window.pageYOffset;
    setPageScrollPosition(position); 
  };
  useEffect(() => {
    window.addEventListener("scroll", handlePageScrollPosition, {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", handlePageScrollPosition);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const TableRef = useRef<any>();
  const scrollbarRef = useRef<any>();
  const tableHeader1Ref = useRef<any>();
  const tableHeader2Ref = useRef<any>();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.Vendors);
  const lockedPropeties = useSelector((state: any) => state.PropertyLocks);

  const regionsNo = useSelector((state: any) => state.propertyRegions);

  let [PropertyGenerators, setPropertyGenerators] = useState(
    GS.SOT.PropertyGenerators
  );

  const [hovering, setHovering] = useState(100);
  let [B3] = useState(parseFloat(GS?.SOT?.BorrowingCalculator?.income_goal));
  let [B4, setB4] = useState(0);
  const [edit, setEdit] = useState({
    financialYear: false,
    cashFlows: false,
    dal: false,
    col: false, 
  });
  let [B6] = useState(3);
  let accountType = props.VendorData.data?.contact_type  //sessionStorage.getItem("accountType");

  let [B9, setB9] = useState(6);
  let [A12, setA12] = useState(20);
  let [tableData, setTableData] = useState(
    GS.SOT.PortfolioSummary.tableData ? GS.SOT.PortfolioSummary.tableData : []
  );
  const navigate = useNavigate();

  let FY = 0;
  if (isNaN(FY) || FY < 2021) {
    FY = 2022;
  }
  if (FY > 2999) {
    FY = 2999;
  }
  let vlookup: any[] = [];
  for (let year = 2022, n = -1; year < 2999; year++, n++) {
    if (vlookup.length < 1) {
      vlookup.push([year, (B3 / 100) * B6 + B3]);
    } else {
      vlookup.push([year, (vlookup[n][1] / 100) * B6 + vlookup[n][1]]);
    }
  }

  let [B8, setB8] = useState(
    vlookup.find((n: any) => {
      return n[0] === FY;
    })[1]
  );
  let [B11, setB11] = useState((B8 * 100) / (B9 / 2));
  let [B12, setB12] = useState(B11 * (A12 / 100) + B11 * 0.06);

  let [nextDate, setNextDate] = useState(
    GS?.SOT?.PropertyGenerators[1]?.nextDate
  );
  let [currentProgress, setCurrentProgress] = useState(100);
  const [editable, setEditable] = useState({ row: 0, col: 0 });

  const comparison=(obj1:any,obj2:any)=>{
    console.log(obj1,obj2) 
    for (const key in obj1){
     
      if(obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)){
       
        if(obj1[key] !==obj2[key]){
          return true
        }
      }
    }
    return false;
  } 
  const getdata=async()=>{
    let response = await API.get(`/vendor-profile/get/${id}`);
    console.log(response,"response")
  }
  useEffect(() => {
    publish(`fixedtop`, { fixedtop: false });
  //  let equitycompar= comparison(GS.SOT.Equity,GS.SOT2.Equity)
  // console.log(equitycompar,"equitycompar")
    if (data && data.office?.length > 0) {
      // CalculateOnPurchaseMethodChanged([]);
     
      if(Savestate || GS?.SOT?.PropertyGenerators?.length==1 ){
  CalculateOnPurchaseMethodChanged([]);
  dispatch(SetAutoSave(false))
      }else{
        let item:any=localStorage.getItem('SOT')
    
        item=JSON.parse(item)
        console.log(item,"item")  
        if((item!==null) && (item!==undefined)){
         
          CalculateOnPurchaseMethodChanged([]);
  dispatch(SetAutoSave(false)) 
          // setTableData(item?.PortfolioSummary?.tableData)
          //   setPropertyGenerators(item?.PropertyGenerators)
          //   setB4(item?.BorrowingCalculator?.FinancialYear)
          //   GS.SOT.PropertyGenerators=item?.PropertyGenerators
          //   GS.SOT.PortfolioSummary.tableData=item?.PortfolioSummary?.tableData
          //   setNextDate( GS?.SOT?.PropertyGenerators[0]?.nextDate)
          //   dispatch(SetAutoSave(false))
          //   localStorage.setItem('SOT',JSON.stringify(GS.SOT))
          //   API.AutoSave();
        }else{
          CalculateOnPurchaseMethodChanged([]);
  dispatch(SetAutoSave(false))
        }
         
       
        // setTableData(lockedPropeties?.tableData)
        //  setPropertyGenerators(lockedPropeties?.
        //   PropertyGenerators)
        //   setB4(GS?.SOT?.BorrowingCalculator?.FinancialYear)
        // setTableData(GS?.SOT?.PortfolioSummary.tableData)
        // setPropertyGenerators(GS?.SOT?.
        //   PropertyGenerators)
        //   setB4(GS?.SOT?.BorrowingCalculator?.FinancialYear)
      }
     
      
      // console.log(GS.SOT2,GS.SOT.PortfolioSummary.tableData )
      const data = GS.SOT.PortfolioSummary.Properties["office"].sort(
        (a: any, b: any) => a.suburb.localeCompare(b.suburb)
      );
      const data1 = GS.SOT.PortfolioSummary.Properties["retail"].sort(
        (a: any, b: any) => a.suburb.localeCompare(b.suburb)
      );
      const data2 = GS.SOT.PortfolioSummary.Properties["residential"].sort(
        (a: any, b: any) => a.region.localeCompare(b.suburb)
      );
      const data3 = GS.SOT.PortfolioSummary.Properties["other"].sort(
        (a: any, b: any) => a.suburb.localeCompare(b.suburb)
      );
      const data4 = GS.SOT.PortfolioSummary.Properties["industry"].sort(
        (a: any, b: any) => a.suburb.localeCompare(b.suburb)
      );
      let retail = data1.map((i: any) => {
        return {
          label:
            i.region,
          value:
            i.region,
          price: i.price,
          suburb: i.suburb,
          state: i.name,
          region: i.region,
          growth_percent:i.growth_percent
        };
      })
      retail=retail.sort((a: any, b: any) => a.label.localeCompare(b.label));
      
      let industry = data4.map((i: any) => {
        return {
          label:
            i.suburb,
          value:
            i.suburb,
          price: i.price,
          suburb: i.suburb,
          state: i.name,
          growth_percent:i.growth_percent
        };
      })
      industry = industry.sort((a:any,b:any)=>a.label.localeCompare(b.label));
      
      let residential = data2.map((i: any) => {
        return {
          label:
            i.region
          ,
          value:
            i.region,
          price: i.price,
          suburb: i.suburb,
          state: i.name,
          region: i.region,
          growth_percent:i.growth_percent
        };
      })
      residential=residential.sort((a:any,b:any)=>a.label.localeCompare(b.label));

      let office = data.map((i: any) => { 
        return {
          label:
            i.region,
          value:
            i.region,
          price: i.price,
          suburb: i.suburb,
          state: i.name,
          region: i.region,
          growth_percent:i.growth_percent
        };
      })
      office=office.sort((a:any,b:any)=>a.label.localeCompare(b.label));
      let other = data3.map((i: any) => {
        return {
          label:
            i.suburb,
          value:
            i.suburb,
          price: i.price,
          suburb: i.suburb,
          state: i.name,
          growth_percent:i.growth_percent
        };
      })
      other=other.sort((a:any,b:any)=>a.label.localeCompare(b.label));
      setRegions({
        retail: [...retail],
        office: [...office],
        residential: [...residential],
        industry: [...industry],
        other: [...other],
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps


  window.setTimeout(() => publish("removeoffscreen", {}), 1000);
  subscribe("_property", (e: any) => {
    const pGenerators: any = GS.SOT.PropertyGenerators;
    let tempTableData: any = [...tableData];
    tempTableData.map((row: any, idx: any) => {
      row.map((col: any, cidx: any) => {
        if (pGenerators[e?.detail]) {
          try {
            if (pGenerators[e?.detail].hasOwnProperty("tableData"))
              tempTableData[idx][cidx + 1] =
                Math.round( 
                  (pGenerators[cidx].tableData[
                    Object.keys(pGenerators[cidx].tableData)[idx]
                  ] +
                    Number.EPSILON) *
                  100
                ) / 100;
          } catch (e) { }
        }
      });
    });

    let sumPurchasePrice = 0,
      sumTotalCashRequired = 0,
      sumNetAnnualCashflowReturn1Year = 0,
      sumCashflowaftermortagecosts = 0;

    tempTableData.map((row: any, idx: any) => {
      row.map((col: any, cidx: any) => {
        for (let i = 0; i < GS.SOT.PropertyGenerators.length; i++) {
          if (pGenerators[i]) {
            try { 
              if (pGenerators[i].hasOwnProperty("tableData")) {
                switch (idx) {
                  case 0:
                    if (cidx === 1) {
                      sumPurchasePrice += col * 1;
   
                      tempTableData[idx][row.length - 1] =
                        Math.round((sumPurchasePrice + Number.EPSILON) * 100) /
                        100;
                    }
                    break;
                  case 6:
                    if (cidx === 1) {
                      sumTotalCashRequired += col * 1;
                      tempTableData[idx][row.length - 1] =
                        Math.round(
                          (sumTotalCashRequired + Number.EPSILON) * 100
                        ) / 100;
                    }
                    break;
                  case 8:
                    if (cidx === 1) {
                      sumNetAnnualCashflowReturn1Year += col * 1;
                      tempTableData[idx][row.length - 1] =
                        Math.round(
                          (sumNetAnnualCashflowReturn1Year + Number.EPSILON) *
                          100
                        ) / 100;
                    }
                    break;
                  case 14:
                    if (cidx === 1) {
                      sumCashflowaftermortagecosts += col * 1;
                      tempTableData[idx][row.length - 1] =
                        Math.round(
                          (sumCashflowaftermortagecosts + Number.EPSILON) * 100
                        ) / 100;
                    }
                    break;
                }
              } 
            } catch (e) { }
          }
        }
      });
    });
    GS.SOT.PortfolioSummary.tableData = tempTableData;

    setTableData(tempTableData);
  }); 

  subscribe("rendersummary", (e: any) => {
    dispatch(setGeneratedProperties(GS.SOT.PropertyGenerators));
    setPropertyGenerators(GS.SOT.PropertyGenerators);

    // let newtablearr=GS.SOT.PortfolioSummary.tableData.map((item:any,index:any)=>{
    //   if(item[0]==="Property Rent Income (Year 1)"){
    //     let purchasePrice=item[2]
    //     let newarr=item[0]?.map((item:any,index:any)=>{
    //       if(index>2){

    //       }else{
    //         return item
    //       }
    //     })
    //   }else{
    //     return item
    //   }
    // })
    // console.log("newtablearr", GS.SOT.PortfolioSummary.tableData?.[8]?.slice(-1)[0])
    
    setTableData(GS.SOT.PortfolioSummary.tableData);
  });
  //idhar
  function CalculateOnPurchaseMethodChanged(
    growthHacking: {
      equityGainGrowth: 0;
      cashGainGrowth: 0;
      AnnualGrowth: 0;
    }[],
    startAt: any = 1,
    endAt: any = 20,
    purchasePrice:any=0,
    idx:any=null
  ) {
 
    if (growthHacking.length == 0)
      growthHacking.push({
        equityGainGrowth: 0,
        cashGainGrowth: 0,
        AnnualGrowth: 0,
      });
     
    let Externals = {
      RISKProfilingCommercialE7: 900000,
      EquityCapacityH14: 856837,
      RISKProfilingCommercialM7: 720000,
      RISKProfilingCommercialL7: 288000,
      RISKProfilingCommercialT7: 720000,
      RISKProfilingCommercialS7: 108000,
      ClientInputsC35: GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn,
      RemoveTabCalculateF5: 1000000,
      RemoveTabCalculateF48: 3.7,
      RemoveTabCalculateG49: GS.SOT.BorrowingCalculator.can_borrow1,
      RemoveTabCalculateF49: GS.SOT.BorrowingCalculator.can_borrow1,
      MedianPrices: data,
      regions: regionsNo,
    };
   console.log("PurchasePrice", purchasePrice)
    if (
      calculateCashAndEquityAvailable() < 202500 &&
      GS?.SOT?.RiskProfile?.initialPropertyType === "Blended"
    ) {
      if(GS?.SOT?.PropertyGenerators[0]){
        GS.SOT.PropertyGenerators[0].targetYieldReturn = 5;
      }
    

      Externals = { 
        ...Externals,
        ClientInputsC35: GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn,
      };
    } else if ( 
      calculateCashAndEquityAvailable() >= 202500 &&
      GS?.SOT?.RiskProfile?.initialPropertyType === "Blended"
    ) {
      let riskprofile = GS?.SOT?.RiskProfile?.NetYieldonProperty;
      riskprofile = parseFloat(riskprofile.replace("%", ""));
      GS.SOT.PropertyGenerators[0].targetYieldReturn = riskprofile;
      Externals = {
        ...Externals,
        ClientInputsC35: GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn,
      };
    }

    if (!GS?.SOT.PortfolioSummary.hasOwnProperty("Properties")) {
     
      GS.SOT.PortfolioSummary.Properties = {
        retail: [],
        office: [],
        residential: [],
        // other: [],
        // industry: [],
      };
    }
   
    let medianPropertyType = "residential";

    if (Externals.ClientInputsC35 <= 5) {
      medianPropertyType = "residential";
    } else if (
      Externals.ClientInputsC35 > 5 &&
      Externals.ClientInputsC35 <= 6
    ) {
      medianPropertyType = "retail";
    } else if (
      Externals.ClientInputsC35 > 6 &&
      Externals.ClientInputsC35 <= 7.5
    ) {
      medianPropertyType = "office";
    } else {
      medianPropertyType = "other";
    } 
    
    if (GS?.SOT.PortfolioSummary.Properties["office"].length === 0) {
      for(let i=0;i<data["office"].length;i++){
        if (data["office"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["office"], data["office"][i])) {
          GS.SOT.PortfolioSummary.Properties["office"].push({
            region: data["office"][i].region,
            suburb: data["office"][i].Suburb_Name,
            name: data["office"][i].State,
            sale_price:data["office"][i].Median_Sale_Price,
            price: data["office"][i].Median_Rental_Price, 
            postcode:data["office"][i]?.postcode,
            growth_percent:data["office"][i]?.growth_percent
          });
        }
       }
       for(let i=0;i<data["residential"].length;i++){
        if (data["residential"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["residential"], data["residential"][i])) {
          GS.SOT.PortfolioSummary.Properties["residential"].push({
            region: data["residential"][i].region,
            suburb: data["residential"][i].Suburb_Name,
            name: data["residential"][i].State,
            sale_price:data["residential"][i].Median_Sale_Price,
            price: data["residential"][i].Median_Rental_Price,
            postcode:data["residential"][i]?.postcode,
            growth_percent:data["office"][i]?.growth_percent
          });
        }
       }
      //  for(let i=0;i<data["industry"].length;i++){
      //   if (data["industry"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["industry"], data["industry"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["industry"].push({
      //       region: data["industry"][i].region,
      //       suburb: data["industry"][i].Suburb_Name,
      //       name: data["industry"][i].State,
      //       sale_price:data["industry"][i].Median_Sale_Price,
      //       price: data["industry"][i].Median_Rental_Price,
      //       postcode:data["industry"][i]?.postcode,
      //     });
      //   }
      //  }
      //  for(let i=0;i<data["other"].length;i++){
      //   if (data["other"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["other"], data["other"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["other"].push({
      //       region: data["other"][i].region,
      //       suburb: data["other"][i].Suburb_Name,
      //       name: data["other"][i].State,
      //       sale_price:data["other"][i].Median_Sale_Price,
      //       price: data["other"][i].Median_Rental_Price,
      //       postcode:data["other"][i]?.postcode,
      //     });
      //   }
      //  }
       for(let i=0;i<data["retail"].length;i++){
        if (data["retail"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["retail"], data["retail"][i])) {
          GS.SOT.PortfolioSummary.Properties["retail"].push({
            region: data["retail"][i].region,
            suburb: data["retail"][i].Suburb_Name,
            name: data["retail"][i].State,
            sale_price:data["retail"][i].Median_Sale_Price,
            price: data["retail"][i].Median_Rental_Price,
            postcode:data["retail"][i]?.postcode,
            growth_percent:data["office"][i]?.growth_percent
          });
        }
       }
      // for (let i = 0; i < 10; i++) {
      //   if (data["office"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["office"], data["office"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["office"].push({
      //       region: data["office"][i].region,
      //       suburb: data["office"][i].Suburb_Name,
      //       name: data["office"][i].State,
      //       sale_price: data["office"][i].Median_Sale_Price,
      //       postcode: data["office"][i]?.postcode,
      //       price: data["office"][i].Median_Rental_Price,
      //     });
      //   }
      //   if (data["residential"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["residential"], data["residential"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["residential"].push({
      //       region: data["residential"][i].region,
      //       suburb: data["residential"][i].Suburb_Name,
      //       name: data["residential"][i].State,
      //       sale_price: data["residential"][i].Median_Sale_Price,
      //       postcode: data["residential"][i]?.postcode,
      //       price: data["residential"][i].Median_Rental_Price,
      //     });
      //   }
      //   if (data["industry"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["industry"], data["industry"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["industry"].push({
      //       region: data["industry"][i].region,
      //       suburb: data["industry"][i].Suburb_Name,
      //       name: data["industry"][i].State,
      //       sale_price: data["industry"][i].Median_Sale_Price,
      //       postcode: data["industry"][i]?.postcode,
      //       price: data["industry"][i].Median_Rental_Price,
      //     });
      //   }
      //   if (data["other"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["other"], data["other"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["other"].push({
      //       region: data["other"][i].region, //pehlay residential tha
      //       suburb: data["other"][i].Suburb_Name,
      //       name: data["other"][i].State,
      //       sale_price: data["other"][i].Median_Sale_Price,
      //       postcode: data["other"][i]?.postcode,
      //       price: data["other"][i].Median_Rental_Price,
      //     });
      //   }
      //   if (data["retail"][i] && !isInArray(GS.SOT.PortfolioSummary.Properties["retail"], data["retail"][i])) {
      //     GS.SOT.PortfolioSummary.Properties["retail"].push({
      //       region: data["retail"][i].region,
      //       suburb: data["retail"][i].Suburb_Name,
      //       name: data["retail"][i].State,
      //       sale_price: data["retail"][i].Median_Sale_Price,
      //       postcode: data["retail"][i]?.postcode,
      //       price: data["retail"][i].Median_Rental_Price,
      //     });
      //   }
      // }
      // GS?.SOT.PortfolioSummary.Properties.sort(() => Math.random() - 0.5);
    }
 //generateData GS?.Data[0]
    GS.generateData();
  
    if (startAt <= 1) {
      GS.SOT.PropertyGenerators = [...GS.SOT.PropertyGenerators.splice(0, 1)];
    
      let totalDepositLeft =
        GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired;
      const equityAvailable: number = parseInt(
        !isNaN(GS?.SOT?.PropertyGenerators[0]?.equityGain)
          ? GS?.SOT?.PropertyGenerators[0]?.equityGain
          : 1
      );
      
      const cashAvailable = parseInt(
        isNaN(
          GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.cashOnHand.total
        )  
          ? 0
          : GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.cashOnHand.total
      );                                               

      totalDepositLeft = 0; 
      if (equityAvailable > 0) {
        GS.SOT.PropertyGenerators[0].useEquity = "Y";
        if (medianPropertyType === "residential") {
          GS.SOT.PropertyGenerators[0].percentageEquity = 100;
        } else {
          GS.SOT.PropertyGenerators[0].percentageEquity = 100;
        }
      } else {
        GS.SOT.PropertyGenerators[0].useEquity = "N";
        GS.SOT.PropertyGenerators[0].percentageEquity = 0;
      }
      if (cashAvailable > 0) {
        GS.SOT.PropertyGenerators[0].useCash = "Y";
        GS.SOT.PropertyGenerators[0].percentageCash = 100;
      } else {
        GS.SOT.PropertyGenerators[0].useCash = "N";
        GS.SOT.PropertyGenerators[0].percentageCash = 0;
      }

      GS.SOT.PropertyGenerators[0].useExtraCash = "N";
      GS.SOT.PropertyGenerators[0].useLiquidAssets = "N"; 
      let currentProperty =
        lockedPropeties?.PropertyGenerators &&
          lockedPropeties?.PropertyGenerators[0]?.isLocked === true
          ? lockedPropeties.PropertyGenerators[0]
          : GS.SOT.PropertyGenerators[0];
      currentProperty = {
        ...currentProperty,
        nextDate:
          getMonthName(new Date().getMonth()) + "-" + new Date().getFullYear(),
      };
      // GS.SOT.PropertyGenerators=[currentProperty]
     
      let growthHack:any=growthHacking
      if(growthHacking[0].AnnualGrowth<=0){
        growthHack[0]={
          equityGainGrowth: GS.SOT.PropertyGenerators[0].summaryData.EquityGainPERCorDOLLAR,
      cashGainGrowth: GS.SOT.PropertyGenerators[0].summaryData.CashGainDOLLAR,
      AnnualGrowth: GS.SOT.PropertyGenerators[0].summaryData.AppliedGrowthRateannualPERC,
        } 
      } 

    const rules = {
      rule1: [5, 7, 7, 6, 5],
      rule2: [7, 5, 6, 6, 5],
      rule3: [5.5, 7, 5, 6, 5],
      rule4: [6.5, 5, 7, 5, 6],
    };
    const nY = parseFloat(
      GS?.SOT?.RiskProfile?.NetYieldonProperty.slice(0, -1)
    );  
    let RiskFactorYieldReturn=0;
    if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
      if (GS?.SOT?.RiskProfile?.NetYieldonProperty) {
        const rule =
          nY <= 5.5
            ? "rule1"
            : nY <= 6
              ? "rule3"
              : nY <= 6.5
                ? "rule4"
                : Math.round(nY) <= 7
                  ? "rule2"
                  : 0;
        // console.log(rule,"rule",i,"i",nY)
        if (rule !== 0) {
          const arrayDuplicates = Math.floor(
            0 / rules[rule].length
          );
          if (arrayDuplicates > 0) {
            for (let zz = 0; zz < arrayDuplicates; zz++) {
              rules[rule].push(...rules[rule]);
            }
          }

          RiskFactorYieldReturn = rules[rule][0];
          
        }
      }
    }else { 
      RiskFactorYieldReturn = parseFloat(
        GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
          .toString()
          .replace("%", "")
      );
    }
    console.log(idx,"idx")
    GS.calculatePropertyGenerator(
        {
          Data: {
            ...Externals,
            ClientInputsC35: RiskFactorYieldReturn,
            ...currentProperty,
            ...growthHacking[0],
            purchasePrice:idx==null||idx!==0 ? 0: purchasePrice
          },
          DSR:DSR,
          DTI:DTI,
        
        },
        
        GS.SOT.PropertyGenerators.length - 1
      );
 
      GS.SOT.PropertyGenerators[0] = {
        ...GS.SOT.PropertyGenerators[0],
        nextDate:
          getMonthName(new Date().getMonth()) + "-" + new Date().getFullYear(),
      };
      setNextDate(
        getMonthName(new Date().getMonth()) + "-" + new Date().getFullYear()
      );

    } else {
      GS.SOT.PropertyGenerators = [
        ...GS.SOT.PropertyGenerators.splice(0, startAt),
      ];
    }
    let totalcashAvailable =
      GS?.SOT?.PropertyGenerators[0]?.F3 +
      GS?.SOT?.PropertyGenerators[0]?.F4 +
      GS?.SOT?.PropertyGenerators[0]?.equityGain;
    if (
      totalcashAvailable < 
      GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired
    ) {
      return false;
    }

    let desireIncomeGoal = GS?.SOT?.BorrowingCalculator?.income_goal || 0;
    let currentIncomeGoal =
      lockedPropeties.PropertyGenerators &&
        lockedPropeties.PropertyGenerators.length > 0 &&
        lockedPropeties.PropertyGenerators[0].isLocked === true
        ? lockedPropeties.PropertyGenerators[0].summaryData?.CashflowAnnual
        : GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual || 0;
    let financialYear: any;
   
    if (currentIncomeGoal < desireIncomeGoal) {
      if (
        GS.SOT.PortfolioSummary.Properties[0] === undefined ||
        GS.SOT.PortfolioSummary.Properties[0] === null
      ) {
        GS.SOT.PropertyGenerators[0].summaryData.State =
          GS?.SOT?.PortfolioSummary?.Properties[0]?.city;
      } 
      let region;
      if (regionsNo && regionsNo.length > 0 && regionsNo[0]) {
        region = regionsNo[0];
        if (!GS.SOT.PortfolioSummary.Properties[medianPropertyType][region]) {
          region = 0;
        }
      } else {
        const regionsLength =
          GS?.SOT.PortfolioSummary.Properties[medianPropertyType].length;
        region = Math.floor(Math.random() * regionsLength);
      }

      let ResidentialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["residential"][region].price *
        0.68 *
        52;
      let ComercialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["office"][region]?.price * 100;
      let RetailMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["retail"][region]?.price * 100;
      let RiskFactoredYieldReturn = 0;
      if (GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn !== null) {
        RiskFactoredYieldReturn = parseFloat(
          GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
            .toString()
            .replace("%", "")
        );
      }

      let thePurchasePrice = 0;
      let MEDIANRENTPRICE_RentIncome = 0;
      const rules = {
        rule1: [5, 7, 7, 6, 5],
        rule2: [7, 5, 6, 6, 5],
        rule3: [5.5, 7, 5, 6, 5],
        rule4: [6.5, 5, 7, 5, 6],
      };

      const nY = parseFloat(
        GS?.SOT?.RiskProfile?.NetYieldonProperty.slice(0, -1)
      );

      for (let i = startAt < 1 ? 1 : startAt; i <= endAt; i++) {
        let propertyno = i;
 
        GS.generateDataForProperty(moment());

        if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
          // console.log(GS?.SOT?.RiskProfile?.NetYieldonProperty,"pro",propertyno,nY)
          if (GS?.SOT?.RiskProfile?.NetYieldonProperty) {
            const rule =
              nY <= 5.5
                ? "rule1"
                : nY <= 6
                  ? "rule3"
                  : nY <= 6.5
                    ? "rule4"
                    : Math.round(nY) <= 7
                      ? "rule2"
                      : 0;
            // console.log(rule,"rule",i,"i",nY)
            if (rule !== 0) {
              const arrayDuplicates = Math.floor(
                propertyno / rules[rule].length
              );
              if (arrayDuplicates > 0) {
                for (let zz = 0; zz < arrayDuplicates; zz++) {
                  rules[rule].push(...rules[rule]);
                }
              }

              RiskFactoredYieldReturn = rules[rule][propertyno]; 
              // console.log(RiskFactoredYieldReturn,propertyno,"rules",rule,GS?.SOT?.RiskProfile?.NetYieldonProperty,parseFloat(
              //   GS?.SOT?.RiskProfile?.NetYieldonProperty.slice(0, GS?.SOT?.RiskProfile?.NetYieldonProperty?.length-1)
              // ))
            }
          }
        } else { 
          RiskFactoredYieldReturn = parseFloat(
            GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
              .toString()
              .replace("%", "") 
          );
        }
        let medianPropertyType = "";
        // console.log(RiskFactoredYieldReturn,"RiskFactoredYieldReturn",propertyno)
        if (RiskFactoredYieldReturn <= 5) {
          medianPropertyType = "residential";
        } else if (
          RiskFactoredYieldReturn > 5 &&
          RiskFactoredYieldReturn < 6.9
        ) { 
          medianPropertyType = "retail";
        } else if (RiskFactoredYieldReturn <= 7.5) {
          medianPropertyType = "office";
        } else {
          medianPropertyType = "other";
        }
        let regionNo;
        if (regionsNo && regionsNo.length > 0 && (regionsNo[propertyno] === 0 || regionsNo[propertyno])) {
          regionNo = regionsNo[propertyno];
        } else {
          const regionsLength =
            GS?.SOT.PortfolioSummary.Properties[medianPropertyType].length;
          regionNo = Math.floor(Math.random() * regionsLength);
        }
        ResidentialMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties["residential"][regionNo]
            ?.price *
          0.68 *
          52;
        ComercialMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties["office"][regionNo]?.price * 100;
        RetailMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties["retail"][regionNo]?.price * 100;

        let ResidentialMedianSalePrice =
          GS?.SOT?.PortfolioSummary?.Properties["residential"][regionNo]
            ?.sale_price;
        let ComercialMedianSalePrice =
          GS?.SOT?.PortfolioSummary?.Properties["office"][regionNo]?.sale_price * 100;
        let RetailMedianSalePrice =
          GS?.SOT?.PortfolioSummary?.Properties["retail"][regionNo]?.sale_price * 100;

        if (RiskFactoredYieldReturn < 6) {
          thePurchasePrice =
            (ResidentialMedianPrice / RiskFactoredYieldReturn) * 100;
          // (ResidentialMedianPrice / RiskFactoredYieldReturn) * 100;

          MEDIANRENTPRICE_RentIncome =
            ResidentialMedianPrice;
        } else if (RiskFactoredYieldReturn < 6.99) {
          thePurchasePrice =
            (RetailMedianPrice / RiskFactoredYieldReturn)*100
          //  RetailMedianSalePrice/ RiskFactoredYieldReturn; 
          MEDIANRENTPRICE_RentIncome = RetailMedianPrice / 100
        } else {
          thePurchasePrice =
           ( ComercialMedianPrice / RiskFactoredYieldReturn)*100
          // ComercialMedianSalePrice / RiskFactoredYieldReturn;
          MEDIANRENTPRICE_RentIncome =
            ComercialMedianPrice / 100
          //  ComercialMedianPrice / 100;
        }
       
            
        GS.SOT.PropertyGenerators.push({
          liquidAssets: GS.SOT.PropertyGenerators[i - 1]?.liquidAssets,
          netIncome: GS?.Data[0][1]?.monthlyCashflow?.income?.total,
          extraCash: GS.SOT.PropertyGenerators[i - 1]?.extraCash,
          cashAvailable: GS?.Data[0][1]?.balanceSheet?.cashOnHand?.total,
          useEquity: "Y",
          equityAvailable:
            GS.SOT.PropertyGenerators[i - 1].tableData
              .ReturnonEquitywitha5capitalgrowthrate *
            (GS.SOT.PropertyGenerators[i - 1].tableData.PurchasePrice / 100),
          equityGain:
            GS.SOT.PropertyGenerators[i - 1].tableData
              .ReturnonEquitywitha5capitalgrowthrate *  
            (GS.SOT.PropertyGenerators[i - 1].tableData.PurchasePrice / 100),
          percentageEquity: GS.SOT.PropertyGenerators[i - 1].percentageEquity,
          totalAvailableFunds: 0,
          useCash: "Y",
          //@TODO: to stop changing LVR of next properties after lock static value given
          // slider1: GS.SOT.PropertyGenerators[i - 1].slider1,
          slider1: 80,

          percentageCash: GS.SOT.PropertyGenerators[i - 1].percentageCash,
          totalAcquisitionlabel: 0,
          useLiquidAssets: "N",
          totalAcquisition: 0,
          targetYieldReturn: 0,
          useExtraCash: "N",
          slider2: 0,
          totalAssetsRequiredLeveragedLabel: 0,
          totalAssetsRequiredLeveraged: 0, 
          cashRequiredLeveragedLabel: 0,
          cashRequiredLeveraged: 0,
          methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
          tableData: {
            PurchasePrice: thePurchasePrice,
            Depositassumingleverage20: 0,
            StampDuty: 0,
            SolicitorValuation: 0,
            BuildingReport: 0,
            LoanArrangementFee: 0,
            TotalCashRequired: 0,
            TotalPurchaseCosts: 0,
            NetAnnualCashflowReturnYear1: 0,
            NetYieldonPropertyYear1: RiskFactoredYieldReturn,
            NetYieldonTotalPurchaseCostsYear1: 0,
            CashonCashReturn: 0,
            TotalCashRequired20Costs: 0,
            CostofLoan5paonleverage: 0,
            Cashflowaftermortagecosts: 0,
            CashflowaftermortgageMonthly: 0,
            ReturnonEquitypurecashflowreturn: 0,
            ReturnonEquitywitha5capitalgrowthrate: 0,
            ReturnonEquitywitha7capitalgrowthrate: 0,
            ReturnonEquitywitha10capitalgrowthrate: 0,
          },
          summaryData: {
            PropertyType:
              RiskFactoredYieldReturn < 6 ? "Residential" : "Commercial",
            PurchasePrice: thePurchasePrice,
            Deposit: 0,
            StampDuty: 0,
            TotalDepositRequired: 0,
            AcquisitionCost: 0,
            TotalPurchaseCosts: 0,
            LoantoValueRatioLVR: 0,
            TotalDebt: 0,
            TotalDepositEquity: 0,
            TotalDepositCash: 0,
            AppliedInterestRate: 0,
            PropertyRentIncomeYear1: MEDIANRENTPRICE_RentIncome,
            TotalCostofLending: 0,
            CashflowAnnual: 0,
            CashflowMonthly: 0,
            ReturnonEquityCOC: 0,
            AppliedGrowthRateannualPERC: growthHacking[i]?.AnnualGrowth
              ? growthHacking[i].AnnualGrowth
              :  0,
            EquityGainPERCorDOLLAR: growthHacking[i]?.equityGainGrowth
              ? growthHacking[i].equityGainGrowth
              :  0,
            CashGainDOLLAR: growthHacking[i]?.cashGainGrowth
              ? growthHacking[i].cashGainGrowth
              : 0,
            LMI: 0,
          },
          G3: "",
          A44: 0,
          F44: 0,
          C44: 0,
          A45: "",
          B45: 0,
          A46: 0,
          B46: 0,
          B47: 0,
          B48: 0,
          B49: 0,
          B50: 0,
          B51: 0,
          C51: 0,
          B52: 0,
          B53: 0,
          B54: 0,
          B55: 0,
          B56: 0,
          B57: 0,
          A58: 0,
          B58: 0,
          B59: 0,
          C59: 0,
          B60: 0,
          B61: 0,
          A62: 0,
          B62: 0,
          A63: 0,
          B63: 0,
          A64: 0,
          B64: 0,
        });
       
        GS.SOT.PropertyGenerators[i].summaryData.State =
          GS?.SOT?.PortfolioSummary?.Properties[i]?.city;
          let growthHack:any=growthHacking
         
        if(growthHacking[i]?.AnnualGrowth<=0 || growthHacking[i]?.AnnualGrowth==undefined){
          growthHack[i]={
            equityGainGrowth: GS.SOT.PropertyGenerators[i].summaryData.EquityGainPERCorDOLLAR,
        cashGainGrowth: GS.SOT.PropertyGenerators[i].summaryData.CashGainDOLLAR,
        AnnualGrowth: GS.SOT.PropertyGenerators[i].summaryData.AppliedGrowthRateannualPERC,
          }
        }
        GS.calculatePropertyGenerator(
          {
            Data: {
              ...Externals,
              ClientInputsC35: RiskFactoredYieldReturn,
              ...GS.SOT.PropertyGenerators[i],
              ...growthHack[i],
              purchasePrice:idx==null||idx!==i ? 0:purchasePrice
            },
            DSR:DSR,
            DTI:DTI,
          
          },
          GS.SOT.PropertyGenerators.length - 1 
        );

        let oldproperty_pTotalCashRequired = 0;
      
        //original one
        // for (let k = 0; k <= i; k++) {
     
        //   oldproperty_pTotalCashRequired +=
        //     lockedPropeties.PropertyGenerators &&
        //       lockedPropeties.PropertyGenerators[k] &&
        //       lockedPropeties.PropertyGenerators[k].isLocked === true
        //       ? lockedPropeties.PropertyGenerators[k].summaryData
        //         ?.TotalDepositRequired
        //       : GS?.SOT?.PropertyGenerators[k]?.summaryData
        //         ?.TotalDepositRequired;
              
        // }
      
        for (let k = 0; k <= i; k++) {
         
          oldproperty_pTotalCashRequired +=
          GS?.SOT?.PropertyGenerators[k]?.summaryData
          ?.TotalDepositRequired
              
        }
        // console.log("i",i,"oldproperty_pTotalCashRequired",oldproperty_pTotalCashRequired)
   
       
//         let tdepositrequired=0
//         for (let k = 0; k <= i; k++) {
         
//           tdepositrequired += GS?.SOT?.PropertyGenerators[k]?.summaryData?.TotalDepositRequired;
              
//         }


        let pTotalCashRequired = oldproperty_pTotalCashRequired;
     
        let nextDate: any = null;
        let LResult: any = null;
        let currentCashOnHand: any = 0;
        let currentEquityAvailable = 0;
        let InterestValue = GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsInterestValue;
    let PrincipleValue = GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsPaymentValue;
    let cal = PrincipleValue - InterestValue;
        let Principles:any=[
          { 
            PropertyName: "PPR",
            PrincipleValue: cal,
          },
        ]
        GS?.SOT?.Equity?.properties?.map((property:any) => {
          Principles.push({
            PropertyName: property.PropertyName,
            PrincipleValue: 
              property.LoanRepaymentsPrincipleInterest -
              property.LoanRepaymentsInterest,
          });
          // return null;
        });
      
        // console.log(GS?.Data[0],"GS?.Data[0]")
        // console.log( pTotalCashRequired," pTotalCashRequired")
        let dt:any=GS?.SOT?.StrategicSpending?.Data
        // console.log(dt,"dt")
        //original one
        // console.log(GS?.Data[0],"GS?.Data[0]")
        let printlocked=
             lockedPropeties?.PropertyGenerators &&
              lockedPropeties?.PropertyGenerators[i-1] &&
              lockedPropeties?.PropertyGenerators[i-1].isLocked === true
              ? (lockedPropeties?.PropertyGenerators[i-1].extraCash+lockedPropeties?.PropertyGenerators[i-1].liquidAssets) || 0
              : 0;
            
             
       //here the changes of locked liquid and extra cash
       
        for (const pdata of GS?.Data[0]) {
         
        let EquitygrowthHackMonthlyVal= growthHack[i - 1]?.equityGainGrowth? growthHack[i - 1]?.equityGainGrowth:0
        let CashgrowthHackMonthlyVal=growthHack[i - 1]?.cashGainGrowth? growthHack[i - 1]?.cashGainGrowth:0
        // console.log(growthHack[i - 1]?.cashGainGrowth,"growthHack[i - 1]?.cashGainGrowth")  
        if (
            pdata?.balanceSheet?.total +
            pdata?.balanceSheet?.cashOnHand.total 
            +
            EquitygrowthHackMonthlyVal +
            CashgrowthHackMonthlyVal+printlocked 
              >= pTotalCashRequired
          ) {
          // console.log(nextDate," ",pdata)
            if (nextDate) {
              // console.log(nextDate," nextDate")
              break;
            }
           
            if (!nextDate) {
              LResult =
                pdata?.balanceSheet?.cashOnHand?.total 
                +
                EquitygrowthHackMonthlyVal +
                CashgrowthHackMonthlyVal
                  +printlocked;
              currentCashOnHand = pdata?.balanceSheet?.cashOnHand?.total;
              currentEquityAvailable = pdata?.balanceSheet?.total;
                 
                  // LResult=LResult+lockedPropeties.PropertyGenerators[i - 1].
              nextDate = pdata?.date;
              /* same result as above, but a different function return type */
            }
          }
        }
      
      

        // for (const pdata of dt) {
       
        //   if ( 
        //     pdata?.balanceSheet?.total +
        //     (growthHacking[i - 1]?.equityGainGrowth
        //       ? growthHacking[i - 1]?.equityGainGrowth
        //       : 0) +
        //     (growthHacking[i - 1]?.cashGainGrowth
        //       ? growthHacking[i - 1]?.cashGainGrowth
        //       : 0) >= 
        //     pTotalCashRequired
        //   ) {
          
        //     if (nextDate) {
        //       break;
        //     }
        //     console.log(pdata,"pdata3")
        //     if (!nextDate) {
        //       LResult =
        //         pdata?.balanceSheet?.cashOnHand?.total +
        //         (growthHacking[i - 1]?.equityGainGrowth
        //           ? growthHacking[i - 1]?.equityGainGrowth
        //           : 0) +
        //         (growthHacking[i - 1]?.cashGainGrowth
        //           ? growthHacking[i - 1]?.cashGainGrowth
        //           : 0);
        //       currentCashOnHand = pdata?.balanceSheet?.cashOnHand?.total;
        //       currentEquityAvailable = pdata?.balanceSheet?.total;
        //       console.log(pdata?.date,"inside if")
        //       nextDate = pdata?.date;
        //       /* same result as above, but a different function return type */
        //     }
        //   }
        // }
      

        let usedCash = 0;
        let usedEquity = 0;

        for (let k = 0; k < i; k++) {
          
          const currentData =
            lockedPropeties.PropertyGenerators &&
              lockedPropeties.PropertyGenerators[k] &&
              lockedPropeties.PropertyGenerators[k]?.isLocked === true
              ? lockedPropeties.PropertyGenerators
              : GS?.SOT.PropertyGenerators;
          usedCash += currentData[k].summaryData.TotalDepositCash;
          usedEquity += currentData[k].summaryData.TotalDepositEquity;
        }

        GS.SOT.PropertyGenerators[i].cashAvailable =
          currentCashOnHand - usedCash > 0 ? currentCashOnHand - usedCash : 0; 

        GS.SOT.PropertyGenerators[i].equityAvailable =
          currentEquityAvailable - usedEquity > 0
            ? currentEquityAvailable - usedEquity
            : 0;
        GS.SOT.PropertyGenerators[i] = {
          ...GS.SOT.PropertyGenerators[i],
          nextDate: nextDate,
        };
        let totalDepositLeft =
          GS.SOT.PropertyGenerators[i].summaryData.TotalDepositRequired;
        const percentageEquityUsed = isWhatPercentOf(
          totalDepositLeft,
          GS.SOT.PropertyGenerators[i].equityAvailable
        );
        const availableExtraCash =
          parseInt(
            GS?.SOT?.StrategicSpending?.ExtraCash?.reduce((a: any, b: any) => {
              return (a += b.value);
            }, 0)
          ) -
          parseInt(
            GS?.SOT?.PropertyGenerators?.reduce((a: any, b: any) => {
              return (a += b.extraCash);
            }, 0)
          );
        const availableLiquidAssets =
          parseInt(GS?.SOT?.Equity?.LiquidAssets?.value) -
          parseInt(
            GS?.SOT?.PropertyGenerators?.reduce((a: any, b: any) => {
              return (a += b.liquidAssets);
            }, 0)
          );
        const cashAvailable = GS.SOT.PropertyGenerators[i].cashAvailable;
        const equityAvailable = GS.SOT.PropertyGenerators[i].equityAvailable;
        if (totalDepositLeft > equityAvailable) {
          if (equityAvailable > 0) {
            GS.SOT.PropertyGenerators[i].useEquity = "Y";
            GS.SOT.PropertyGenerators[i].percentageEquity = 100;
            totalDepositLeft = totalDepositLeft - equityAvailable;
          } else { 
            GS.SOT.PropertyGenerators[i].useEquity = "N";
            GS.SOT.PropertyGenerators[i].percentageEquity = 0;
          }
          if (totalDepositLeft > cashAvailable) {
            if (cashAvailable > 0) {
              GS.SOT.PropertyGenerators[i].useCash = "Y";
              GS.SOT.PropertyGenerators[i].percentageCash = 100;
              totalDepositLeft = totalDepositLeft - cashAvailable;
            } else {
              GS.SOT.PropertyGenerators[i].useCash = "N";
              GS.SOT.PropertyGenerators[i].percentageCash = 0;
            }
            if (totalDepositLeft > availableLiquidAssets) {
              if (availableLiquidAssets > 0) {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
                GS.SOT.PropertyGenerators[i].liquidAssets =
                  availableLiquidAssets;
                totalDepositLeft = totalDepositLeft - availableLiquidAssets;
              } else {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
                GS.SOT.PropertyGenerators[i].liquidAssets = 0;
              }
              if (totalDepositLeft >= availableExtraCash) {
                if (availableExtraCash > 0) {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "Y";
                  GS.SOT.PropertyGenerators[i].extraCash = availableExtraCash;
                  totalDepositLeft = totalDepositLeft - availableExtraCash;
                } else {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "N";
                  GS.SOT.PropertyGenerators[i].extraCash = 0;
                }
              } else {
                if (totalDepositLeft > 0) {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "Y";
                  GS.SOT.PropertyGenerators[i].extraCash =
                    availableExtraCash - totalDepositLeft;
                  totalDepositLeft = 0;
                } else {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "N";
                  GS.SOT.PropertyGenerators[i].extraCash = 0;
                }
              }
            } else if (totalDepositLeft === availableLiquidAssets) {
              GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
              GS.SOT.PropertyGenerators[i].liquidAssets = availableLiquidAssets;
              GS.SOT.PropertyGenerators[i].useExtraCash = "N";
              GS.SOT.PropertyGenerators[i].extraCash = 0;
            } else {
              if (totalDepositLeft > 0) {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
                GS.SOT.PropertyGenerators[i].liquidAssets =
                  availableLiquidAssets - totalDepositLeft;
                totalDepositLeft = 0;
              } else {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
                GS.SOT.PropertyGenerators[i].liquidAssets = 0;
              }
              GS.SOT.PropertyGenerators[i].useExtraCash = "N";
              GS.SOT.PropertyGenerators[i].extraCash = 0;
            }
          } else if (totalDepositLeft === cashAvailable) {
            totalDepositLeft = 0;
            GS.SOT.PropertyGenerators[i].useCash = "Y";
            GS.SOT.PropertyGenerators[i].percentageCash = 100;
            GS.SOT.PropertyGenerators[i].useExtraCash = "N";
            GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
          } else {
            const percentageCashUsed = isWhatPercentOf(
              totalDepositLeft, 
              cashAvailable
            );
            totalDepositLeft = 0;
            GS.SOT.PropertyGenerators[i].useCash = "Y";
            GS.SOT.PropertyGenerators[i].percentageCash =
              percentageCashUsed > 100 ? 100 : percentageCashUsed;
            GS.SOT.PropertyGenerators[i].useExtraCash = "N";
            GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
          }
        } else if (totalDepositLeft === equityAvailable) {
          GS.SOT.PropertyGenerators[i].useEquity = "Y";
          GS.SOT.PropertyGenerators[i].percentageEquity = 100;
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[i].useCash = "N";
          GS.SOT.PropertyGenerators[i].percentageCash = 0;
          GS.SOT.PropertyGenerators[i].useExtraCash = "N";
          GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
        } else {
          GS.SOT.PropertyGenerators[i].useEquity = "Y";
          GS.SOT.PropertyGenerators[i].percentageEquity =
            percentageEquityUsed > 100 ? 100 : percentageEquityUsed;
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[i].useCash = "N";
          GS.SOT.PropertyGenerators[i].percentageCash = 0;
          GS.SOT.PropertyGenerators[i].useExtraCash = "N";
          GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
        }
      
        GS.calculatePropertyGenerator(
          {
            Data: {
              ...Externals,
              ClientInputsC35: RiskFactoredYieldReturn,
              ...GS.SOT.PropertyGenerators[i],
              ...growthHack[i],
              purchasePrice:idx==null||idx!==i ? 0:purchasePrice
            },
            DSR:DSR,
            DTI:DTI,
            
          },
          GS.SOT.PropertyGenerators.length - 1
        );
 
        if (
          lockedPropeties.PropertyGenerators &&
          lockedPropeties.PropertyGenerators[i] &&
          lockedPropeties.PropertyGenerators[i].isLocked === true
        ) {
          
          const oldData = lockedPropeties.PropertyGenerators;
          const updatedData = lockedPropeties.PropertyGenerators[i];
          updatedData["nextDate"] = nextDate;
          updatedData.cashAvailable =
            currentCashOnHand - usedCash > 0 ? currentCashOnHand - usedCash : 0;
          updatedData.equityAvailable = currentEquityAvailable - usedEquity > 0;
          updatedData.useEquity = GS?.SOT?.PropertyGenerators[i]?.useEquity;
          updatedData.useCash = GS?.SOT?.PropertyGenerators[i]?.useCash;
          updatedData.percentageEquity =
            GS?.SOT?.PropertyGenerators[i]?.percentageEquity;
          updatedData.percentageCash =
            GS?.SOT?.PropertyGenerators[i]?.percentageCash;
          updatedData.summaryData.TotalDepositCash =
            GS?.SOT?.PropertyGenerators[i]?.summaryData?.TotalDepositCash;
          updatedData.summaryData.TotalDepositEquity =
            GS?.SOT?.PropertyGenerators[i]?.summaryData?.TotalDepositEquity;
            updatedData.summaryData.TotalCostofLending=GS?.SOT?.PropertyGenerators[i]?.summaryData?.TotalCostofLending;
            updatedData.summaryData.AppliedInterestRate=GS?.SOT?.PropertyGenerators[i]?.summaryData?.AppliedInterestRate;
            updatedData.summaryData.interestRate=GS?.SOT?.PropertyGenerators[i]?.summaryData?.interestRate;
          
            updatedData.equityAvailable = 
            currentEquityAvailable - usedEquity > 0
              ? currentEquityAvailable - usedEquity
              : 0;
          oldData[i] = updatedData;

          setLockedProperties({
            tableData: [...lockedPropeties.tableData],
            PropertyGenerators: [oldData],
          });
          
        }

        // if (GS.SOT.PropertyGenerators[i].summaryData?.PurchasePrice < 350000) {
        //   setB4(nextDate?.split("-")[1]);
        //   GS.SOT.BorrowingCalculator.FinancialYear = parseInt(
        //     nextDate?.split("-")[1]
        //   );
        //   props.setIncomeGoal({
        //     ...props.IncomeGoal,
        //     year: parseInt(nextDate?.split("-")[1]),
        //   });
        //   financialYear = parseInt(nextDate?.split("-")[1]);

        //   i = 999999999;

        // } else {
        currentIncomeGoal +=
          GS?.SOT?.PropertyGenerators[i]?.summaryData?.CashflowAnnual;

        let currentIn = 0
        // console.log('hey',lockedPropeties)
        GS?.SOT?.PropertyGenerators.map((item: any, index: any) => {
          if (lockedPropeties!==null && lockedPropeties?.PropertyGenerators!==null && lockedPropeties?.PropertyGenerators[index]?.isLocked) {

            currentIn = currentIn + lockedPropeties?.PropertyGenerators[index]?.summaryData?.CashflowAnnual
           

          } else {
            currentIn = currentIn + item.summaryData.CashflowAnnual
          }
        

        })
     
        if (currentIn >= desireIncomeGoal) {
         
          if (nextDate) {
            setB4(nextDate?.split("-")[1]);
            GS.SOT.BorrowingCalculator.FinancialYear = parseInt(
              nextDate?.split("-")[1]
            );
            financialYear = parseInt(nextDate?.split("-")[1]);
            props.setIncomeGoal({
              ...props.IncomeGoal,
              year: parseInt(nextDate?.split("-")[1]),
            });
          }
          i = 9999999;
        }
        // }
        // console.log("propno",propertyno," generated",GS.SOT.PropertyGenerators)
      }
    }

    let diff = financialYear - new Date().getUTCFullYear();
    const regions = GS.SOT.PropertyGenerators.map(
      (i: any) => i.summaryData.regionNo
    );
    GS.SOT.PortfolioSummary.selectedRegions = regions;
    dispatch(setCurrentRegions(regions));
    setCurrentProgress(
      isWhatPercentOf(1, diff) >= 100 ? 100 : isWhatPercentOf(1, diff)
    );
    localStorage.setItem('SOT',JSON.stringify(GS.SOT))
    publish(
      "setgoalprogress",
      isWhatPercentOf(currentIncomeGoal, desireIncomeGoal)
    );
    revertLockedData();
  }
  const Wrapper1 = useRef<any>();
  const revertLockedData = () => {
    if (lockedPropeties?.tableData && lockedPropeties?.PropertyGenerators) {
      lockedPropeties?.PropertyGenerators?.map((item: any, idx: any) => {
        if (item?.isLocked == true && GS.SOT.PropertyGenerators[idx]) {
          GS.SOT.PropertyGenerators[idx] =
            lockedPropeties?.PropertyGenerators[idx];
          lockedPropeties?.tableData.forEach((row: any, col: any) => {
            let rowTotal = 0; 
            if ( 
              col !== 0 &&
              col !== 1 &&
              col !== 10 &&
              col !== 9 &&
              col !== 15 &&
              col !== 16 &&
              col !== 21 &&
              col !== 22 &&
              col !== 23
            ) {
              row.forEach((result: any, item: any) => {
                if (item > 0 && item < row.length - 1) {
                  rowTotal += result;
                }
              }); 
            }
            const rowlength = row.filter((i: any) => {
              return i !== undefined;
            });

            GS.SOT.PortfolioSummary.tableData[col][idx + 1] = row[idx + 1];
            GS.SOT.PortfolioSummary.tableData[col][rowlength - 1] = rowTotal;
            
          });
        } 
      }); 
    }

    //set financial year as last year property if income goal not met
    let financialYear =
      GS.SOT?.PropertyGenerators[
        GS.SOT?.PropertyGenerators?.length - 1
      ]?.nextDate?.split("-")[1];
    if (financialYear && B4 === 0) {
    
      setB4(financialYear);
      GS.SOT.BorrowingCalculator.FinancialYear = parseInt(financialYear);
      props.setIncomeGoal({
        ...props.IncomeGoal, 
        year: financialYear,
      });
    } 
    
    API.AutoSave();
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setVerticalScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps 

  const updateProfile = async () => {
    GS.SOT.Progress = { steps: 4, completed: 3, current: 4 };
    await API.AutoSave(window.location.href);
    const updateData = [...state];
    const i = updateData.findIndex((vendor) => vendor._id === props.vendorid);
    updateData[i] = { 
      ...updateData[i],
      Progress: [GS.SOT.Progress],
    }; 
    dispatch(Vendors(updateData));
  }; 
  
  const calculateCashAndEquityAvailable = () => {
    let sum =
      GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.cashOnHand.total;
    sum += isNaN(GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain)
      ? 0
      : GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain;
    let v = 0;
    // if (GS?.SOT?.Equity?.EquityGains?.length > 0) {
    //   GS.SOT.Equity.EquityGains.map((item: any, index: any) => {
    //     let v1 = item.value / item.noOfMonths;
    //     v = v + v1;
    //   });
    // } 
    let result = isNaN(sum + v) ? 0 : sum + v;
    return result;
  };
  //check lazmi
  const showNotifications = () => {
    let shownoti = false;
    // const equityGain = GS.SOT.PropertyGenerators[0]?.equityGain;
    const cashAvailable = isNaN(
      GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.cashOnHand.total
    )
      ? 0
      : GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.cashOnHand.total;

    const equityAvailable = !isNaN(GS?.SOT?.PropertyGenerators[0]?.equityGain)
      ? GS?.SOT?.PropertyGenerators[0]?.equityGain
      : 0;
    if (GS.SOT.PropertyGenerators[0]) {
      if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
        if (
          GS.SOT.PropertyGenerators[0].summaryData?.propertyType ===
          "Residential"
        ) {
          if (
            equityAvailable +
            GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
            cashAvailable <
            94500
          ) {
            shownoti = true;
          }
        } else {
          if (
            equityAvailable +
            GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
            cashAvailable <
            210000
          ) { 
            shownoti = true;
          } 
        }
      } else if (GS?.SOT?.RiskProfile?.initialPropertyType === "Residential") {
        if (
          equityAvailable +
          GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
          cashAvailable <
          94500
        ) {
          shownoti = true;
        }
      } else {
        if (
          equityAvailable +
          GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
          cashAvailable <
          210000
        ) {
          shownoti = true;
        }
      }
    }
 
    if (shownoti) {
      let TotalDepositReq =
        GS?.SOT?.PropertyGenerators[0]?.summaryData.TotalDepositRequired;
        
      let amount: any;
      amount =
        TotalDepositReq -
        (equityAvailable +
          GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
          cashAvailable); 
      if (isNaN(amount)) {  
        amount = ""; 
      } else { 
        amount = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }).format(amount);
      }
      return <></>;
      // return (
      //   <>
      //     <p
      //       style={{
      //         width: "100%",
      //         padding: "35px 10px",
      //         backgroundColor: "#FFF",
      //         textAlign: "center",
      //       }}
      //     >
      //       {amount} Cash and Equity Required
      //     </p>
      //   </>
      // );
    } else {
      return <></>;
    }
  };
  // useEffect(() => {
  //   const element: any = TableRef.current;
  //   const scrollbar: any = scrollbarRef.current;

  //   scrollbar.firstChild.style.width = `${element.scrollWidth}px`;
  //   // scrollbar.firstChild.style.paddingTop = '1px';

  //   scrollbar.onscroll = function () { 
  //     element.scrollLeft = scrollbar.scrollLeft;
  //   };

  //   element.onscroll = function () {
  //     scrollbar.scrollLeft = element.scrollLeft;
  //   };
    
  // }, []);
  const handleTableScroll=(event:any)=>{
    event.preventDefault()
  
    if(TableRef.current.scrollLeft==0){
      scrollbarRef.current.scrollLeft=0
    }
    // scrollbarRef.current.scrollLeft=TableRef.current.scrollLeft
    
  }
  const handleTopScrollBar=(event:any)=>{
    event.preventDefault()
   
    TableRef.current.scrollLeft=scrollbarRef.current.scrollLeft
    
  }
  const renderSummaryTable = () => {
    let showtable = true;
    
// console.log(tableData,"tableData")
    // if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
    //   if (GS?.SOT?.PropertyGenerators[0].summaryData.PurchasePrice < 350000) {
    //     showtable = false;
    //   }
    // }
    if (showtable === true) {
      return (
        <>
         

          <div className={`double-scroll `}>
            <div
              className="topScrollBar"
              style={{
                width: TableRef?.current?.getBoundingClientRect()?.width,
              }}
              ref={scrollbarRef}
              onScrollCapture={(e:any)=>handleTopScrollBar(e)}

            >
              <div
                style={{ width: TableRef?.current?.scrollWidth, height: "10px" }}
              />
            </div>
            <div 
              className="table-container mt-4"
              id="table"
              ref={TableRef}
             onScrollCapture={(e:any)=>handleTableScroll(e)}
           
            >
              <table
                className={Textcolor === true ? `tblData1` : `tblData`}
                id="tbl"
                style={{
                  width: `${GS.SOT.PropertyGenerators.length >= 7
                      ? GS.SOT.PropertyGenerators.length * 9
                      : 100
                    }%`,
                }}
              >  
                <tbody>
                  <tr
                    className={`${PageScrollPosition > 1200 ? "fixedTop" : ""} `}
                    ref={tableHeader1Ref}
                    style={{ position: "relative" }}
                  > 
                    <td
                      valign="bottom"
                      className={`${verticalScrollPosition > 300 ? "ml-5" : "ml-5"
                        } 
                      FirstRowTitle
                      `}
                    >
                      <p>
                        <span
                          className={`propertyhead ${Textcolor == true && "blacktxt"
                            }`}
                        >
                          Risk Factored Yield Return
                        </span>
                      </p>
                    </td>

                    {PropertyGenerators?.map((n: any, idx: any) => {
                     
                      return (
                        <>
                          <td
                            key={idx}
                            valign="bottom"
                            className={`${Textcolor == true ? "tbackground" : ""
                              } ${GS.SOT.PropertyGenerators.length == 1
                                ? "tleft"
                                : "tcenter"
                              }`}
                          >
                            {GS.SOT.PropertyGenerators[idx]?.hidden === false && (
                              <p>
                                <span
                                  className={`${Textcolor == true && idx > 0
                                      ? "tbackground"
                                      : ""
                                    } ${GS.SOT.PropertyGenerators.length == 1
                                      ? ""
                                      : "ml-4"
                                    } tablet tablet-${Math.round(
                                      n?.tableData.NetYieldonPropertyYear1
                                    )}`}
                                >
                                  {Textcolor == true && idx > 0 ? (
                                    <span className="tbackground">**.**</span>
                                  ) : isNaN( 
                                    n?.tableData.NetYieldonPropertyYear1 
                                  ) === false &&                                                                 
                                    n?.tableData.NetYieldonPropertyYear1 ? (
                                    n?.tableData.NetYieldonPropertyYear1.toFixed(
                                      1
                                    ) + "%"
                                  ) : (
                                    ""  
                                  )}
                                </span>
                              </p>
                            )}
                          </td>
                        </>
                      );
                    })}
                  </tr>

                  <tr
                    className={`${PageScrollPosition > 1200 ? "fixedTopSecondRow" : ""
                      }`}
                    ref={tableHeader2Ref}
                  >
                    <td
                      valign="bottom"
                      className="tblRowTitle"
                      style={{ width: "50px" }}
                    >
                      <p>
                        <span
                          className={`propertyhead ${Textcolor == true && "blacktxt"
                            }`}
                        >
                          Feasibility Markers
                        </span>
                      </p>
                    </td>  
 
                    {PropertyGenerators?.map((n: any, idx: any) => {
                      
                      return (
                        <td
                          key={idx}
                          className="mr-5"
                          valign="bottom"
                          style={
                            GS.SOT.PropertyGenerators.length == 1
                              ? {
                                padding: "8px 15px 10px 0px",
                                textAlign: "left",
                              }
                              : {
                                padding: "8px 15px 10px 0px",
                                textAlign: "center",
                              }
                          }
                        >
                          {/* <div className="d-flex ml-3"> */}
                          {GS.SOT.PropertyGenerators[idx]?.hidden === true && (
                            <div className="pb-3">
                              <BsEyeSlash
                                className="remove-icon ml-1"
                                onClick={() => {                                                        
                                  GS.SOT.PropertyGenerators[idx].hidden = false;
                                  setHovering(hovering + 1);
                                }}
                              />              
                            </div>
                          )}
                          {GS.SOT.PropertyGenerators[idx]?.hidden === false && (
                            <div style={{ display: "inline-block" }}>
                              <>
                                <p
                                  className={`${Textcolor == true ? "dNone" : ""
                                    }`} 
                                  style={
                                    GS.SOT.PropertyGenerators.length == 1
                                      ? {
                                        marginLeft: "30px",
                                        fontSize: "8px", 
                                        fontWeight: "bolder",
                                      }
                                      : { fontSize: "8px", fontWeight: "bolder" }
                                  }
                                >
                                  {GS?.SOT?.PortfolioSummary?.Properties[
                                    PropertyGenerators[idx]?.medianPropertyType
                                  ]?.length > 0 &&
                                    GS?.SOT?.PortfolioSummary?.Properties[
                                      PropertyGenerators[idx]?.medianPropertyType 
                                    ][
                                      GS?.SOT?.PropertyGenerators[idx]?.summaryData
                                        ?.regionNo
                                    ]?.name}
                                </p>
                                <p className="datepill">{n.nextDate}</p>
                              </>

                              <div
                                style={
                                  GS.SOT.PropertyGenerators.length == 1
                                    ? { marginLeft: "35px" }
                                    : {}
                                }
                              >
                                <BsEye
                                  className="remove-icon ml-1"
                                  onClick={() => {
                                    GS.SOT.PropertyGenerators[idx].hidden = true;
                                    setHovering(hovering + 1);
                                  }}
                                />
                              </div>
                              <div className="ToolTip-container"> 
                                <a
                                  style={{ color: "black" }}
                                  className="propertypill"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    if ((accountType != "Contacts" )||(accountType != "Contact Lite")) {
                                      publish("PropertyActivate", idx);
                                    }
                                  }}
                                  onMouseEnter={() => setHovering(idx)}
                                  onMouseLeave={() => setHovering(100)}
                                >
                                  <div className="ToolTip">
                                    <ToolTip
                                    borderRadius={"4px"}
                                      top="-70px"
                                      left="0px"
                                      textAlign="center"
                                      width="120px"
                                      style={{color:'black'}}
                                      show={idx === hovering ? true : false}
                                      txt={`${isNaN(n.tableData.PurchasePrice)
                                          ? 0
                                          : new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 0,
                                          }).format(n.tableData.PurchasePrice)
                                        }`}  
                                    />
                                    Property {idx + 1}
                                  </div>
                                </a>

                                {lockedPropeties.PropertyGenerators?.length > 0 &&
                                  lockedPropeties?.PropertyGenerators[idx]
                                    ?.isLocked === true ? (
                                  <BsLockFill
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "1px",
                                    }}
                                    onClick={() => {
                                      GS.SOT.PropertyGenerators[idx].isLocked =
                                        false;

                                      dispatch(
                                        setLockedProperties({
                                          tableData: [ 
                                            ...GS.SOT.PortfolioSummary.tableData,
                                          ],
                                          PropertyGenerators: [
                                            ...GS.SOT.PropertyGenerators,
                                          ],
                                        })
                                      );
                                      setSavestate(true)
                                      API.AutoSave();
                                    }}
                                  />
                                ) : (
                                  <BsUnlockFill
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "1.5px",
                                    }}
                                    onClick={() => {
                                      GS.SOT.PropertyGenerators[idx].isLocked =
                                        true;
                                      dispatch( 
                                        setLockedProperties({
                                          tableData: [
                                            ...GS.SOT.PortfolioSummary.tableData,
                                          ],
                                          PropertyGenerators: [
                                            ...GS.SOT.PropertyGenerators,
                                          ],
                                        })
                                      );
                                      setSavestate(true)
                                      API.AutoSave();
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </td>
                      ); 
                    })}
                    <td width="106" valign="bottom">
                      <p>
                        <strong>
                          <span>TOTAL</span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  {Boolean(tableData.length) && 
                    tableData.map((itm: any, key: any) => {
                    
                      return (
                        <>
                         <tr
                        key={key} 
                       
                        className={`${Textcolor == true &&
                            (itm[0] == "Property Type" ||
                              itm[0] == "Region" ||
                              itm[0] == "Total Purchase Costs" ||
                              itm[0] == "Total Deposit Equity" ||
                              itm[0] == "Total Deposit Cash" ||
                              itm[0] == "Property Rent Income (Year 1)" ||
                              itm[0] == "Return on Equity (COC)" ||
                              itm[0] == "Equity Gain($)" ||
                              itm[0] == "Cash Gain($)" ||
                              itm[0] == "Purchase Price" ||
                              itm[0] == "Deposit" ||
                              itm[0] == "Stamp Duty" ||
                              itm[0] == "Applied Interest Rate" ||
                              itm[0] == "Acquisition Cost")
                            ? "tbackground"
                            : itm[0]=="Cashflow (Annual)" || itm[0]=="Cashflow (Monthly)"?"bgGolden":""
                          } `}
                      >
                        {itm.map((col: any, idx: any) => {
                          // console.log( lockedPropeties?.PropertyGenerators[idx - 1]
                          //   ?.isLocked === true,"hey",idx,lockedPropeties?.PropertyGenerators[idx - 1])
                          if (itm.length - 1 === idx) {
                            //totals
                            return col === 0 ||
                              isNaN(col) === true ||
                              [10, 15, 21, 23].includes(key) === true ? (
                              <td key={idx}> 
                                <p>
                                  <span></span> 
                                </p>
                              </td>
                            ) : (
                              <td key={idx}>
                                <p>
                                  <div className="icon"></div>
                                  <span>{commaSeparators(col)} </span>
                                </p>
                              </td>
                            );
                          } else if (idx === 0) {
                            //first column
                            return (
                              <>
                              {/* Region hide  bgGolden*/}
                              <td
                               key={idx}
                               className={`${Textcolor == true ? "blacktxt" : col=="Cashflow (Annual)" || col=="Cashflow (Monthly)"?"bgGolden": ""
                                 }`}
                             >
                               <p>
                                 <div className="icon"></div>

                                 <span
                                   style={
                                     col === "PURCHASE" ||
                                       col === "FINANCE" ||
                                       col === "CASHFLOW" ||
                                       col === "GROWTH HACKING"
                                       ? {
                                         fontWeight: "bold",
                                         color: "black ",
                                       }
                                       : {}
                                   }
                                 > 
                                   {col == "Region" && Textcolor == true
                                     ? "Premium Suburbs"
                                     : col ==
                                       "Property Rent Income (Year 1)" &&
                                       Textcolor == true
                                       ? "Premium Suburbs Income"
                                       : col == "Return on Equity (COC)" &&
                                         Textcolor == true
                                         ? "Return on Equity (Cash on Cash)"
                                         : col=="Region"?"Suburb":col} 
                                 </span>
                               </p>
                             </td>
                             
                              </>
                            );
                          } else { 
                            return key === 0 || key === 1 ? ( //first and second row except first column   hide region row
                              <> 
                              
                              <td 
                              key={idx}
                              className={` ${GS.SOT.PropertyGenerators.length == 1
                                  ? "tleft"
                                  : "tcenter"
                                } ${Textcolor && idx === 1 ? "hueColor" : ""} `}
                            >
                              {GS.SOT.PropertyGenerators[idx - 1]?.hidden ===
                                false && (
                                  <p>
                                    {lockedPropeties?.PropertyGenerators &&
                                      lockedPropeties?.PropertyGenerators[idx - 1]
                                        ?.isLocked === true &&
                                      editable.row === key &&
                                      editable.col === idx ? ( 
                                      <div>
                                        <span 
                                          style={{ color: "black" }}
                                          onMouseLeave={() =>
                                            setEditable({ row: 0, col: 0 })
                                          }
                                        >
                                          <div className="ToolTip">
                                            <ToolTip
                                            borderRadius={"4px"}
                                              top="-70px"
                                              left="0px"
                                              textAlign="center"
                                              width="150px"
                                              show={true}
                                              style={{color:'black'}}
                                              txt={`Unlock to edit`}
                                            />
                                            {col}
                                          </div>
                                        </span>
                                      </div>
                                    ) : editable.row === key &&
                                      editable.col === idx ? (
                                      <span>
                                        <ContactDropDown
                                          style={{
                                            alignSelf: "center",
                                            border: "1px solid blue",
                                            
                                          }}
                                          width="130px !important"
                                          value={col}
                                          onChange={(e: any) => {
                                            const propertyType =
                                              PropertyGenerators[idx - 1]
                                                .medianPropertyType;

                                            // @ts-ignore: Unreachable code error
                                            const region = regions[
                                              propertyType
                                            ].find(
                                              (region: any) =>
                                                region.value === e.target.value
                                            );
                                            const regionIndex = 
                                              GS.SOT.PortfolioSummary.Properties[
                                                PropertyGenerators[idx - 1]
                                                  .medianPropertyType
                                              ].findIndex(  
                                                (i: any) =>
                                                  i.region === region.region
                                              );
                                              let regionGrowthRate =
                                              GS.SOT.PortfolioSummary.Properties[
                                                PropertyGenerators[idx - 1]
                                                  .medianPropertyType
                                              ][regionIndex].growth_percent
                                            
                                            
                                              if(regionGrowthRate){
                                                regionGrowthRate=regionGrowthRate.slice(0,regionGrowthRate.length-2) 
                                              }
                                              regionGrowthRate=Number(regionGrowthRate)


                                              const currentRegionsData =
                                              regionsNo;
                                            currentRegionsData[idx - 1] =
                                              regionIndex;
                                            dispatch(
                                              setCurrentRegions(
                                                currentRegionsData
                                              )
                                            );

                                            // GS.SOT.PortfolioSummary.Properties[
                                            //   PropertyGenerators[
                                            //     idx - 1
                                            //   ].medianPropertyType
                                            // ][idx - 1] = {
                                            //   region: region.region,
                                            //   price: region.price,
                                            //   suburb: region.suburb,
                                            //   name: region.state,
                                            // };

                                            const data = [...tableData];
                                            let arr: any = [];

                                            data[22]
                                              .slice(1) 
                                              .forEach(
                                                (item: any, index: any) => {
                                                  if(index==idx-1){
                                                    arr.push({
                                                      AnnualGrowth: regionGrowthRate, 
                                                      equityGainGrowth:
                                                      (data[2][idx] / 100) *regionGrowthRate,
                                                      cashGainGrowth:
                                                        data[24][index + 1],
                                                    });  
                                                  }else{
                                                    arr.push({
                                                      AnnualGrowth: item, 
                                                      equityGainGrowth:
                                                        data[23][index + 1],
                                                      cashGainGrowth:
                                                        data[24][index + 1],
                                                    });  
                                                  }
                                                  
                                                }
                                              );
                                              setTableData(data)
                                              setSavestate(true)
                                            CalculateOnPurchaseMethodChanged(
                                              arr,
                                              idx - 1,
                                              20
                                            );
                                          }}
                                          options={
                                            // @ts-ignore: Unreachable code error
                                            Changeoptions(idx)

                                            // regions[
                                            // PropertyGenerators[idx - 1]
                                            //   .medianPropertyType
                                            // ]
                                          }
                                        /> 
                                        <span
                                          className="px-1 "
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }} 
                                          onClick={() => {
                                            setEditable({ row: 0, col: 0 });
                                          }}
                                        >
                                          Done 
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                      style={{display:"flex",flexDirection:"column"}}
                                        onClick={() => {
                                          if (
                                            [1].includes(key) === true &&
                                            Textcolor == false
                                          ) {
                                            setEditable({
                                              row: key,
                                              col: idx,
                                            });
                                          }
                                        }}
                                      >
                                        
                                      {key==1 && 
                                       <span style={{fontSize:'8px'}}>
                                       {
                                        GS?.SOT?.PortfolioSummary?.Properties[
                                          PropertyGenerators[idx-1]?.medianPropertyType
                                        ]?.length > 0 &&
                                          GS?.SOT?.PortfolioSummary?.Properties[
                                            PropertyGenerators[idx-1]?.medianPropertyType
                                          ][
                                            GS?.SOT?.PropertyGenerators[idx-1]?.summaryData
                                              ?.regionNo
                                          ]?.name
                                       }
                                        </span>
                                      }  
                                     
                                        {col}
                                      </span>
                                    )}
                                  </p>
                                )} 
                            </td>
                              </>
                            ) : [10, 11,20, 22].includes(key) === true ? ( //percentage row
                              isNaN(col) === true ? (
                                <td
                                  key={idx}
                                  className={`${GS.SOT.PropertyGenerators.length == 1
                                      ? "tleft"
                                      : "tcenter"
                                    } ${Textcolor && idx === 1 ? "hueColor" : ""
                                    }`}
                                >
                                  <p>
                                    <span></span>
                                  </p>
                                </td>
                              ) : (
                                <td
                                  key={idx}
                                  className={`${GS.SOT.PropertyGenerators.length == 1
                                      ? "tleft"
                                      : "tcenter"
                                    } ${Textcolor && idx === 1 ? "hueColor" : ""
                                    }`} 
                                >
                                  {GS.SOT.PropertyGenerators[idx - 1] 
                                    ?.hidden === false && ( 
                                      <p>
                                        {lockedPropeties?.PropertyGenerators &&
                                          lockedPropeties?.PropertyGenerators[
                                            idx - 1
                                          ]?.isLocked === true &&
                                          editable.row === key &&
                                          editable.col === idx ? (
                                          <div>
                                            <span 
                                              style={{ color: "black" }}
                                              onMouseLeave={() =>
                                                setEditable({ row: 0, col: 0 })
                                              }
                                            >
                                              <div className="ToolTip">
                                                <ToolTip
                                                borderRadius={"4px"}
                                                  top="-70px"
                                                  left="0px" 
                                                  textAlign="center"
                                                  width="150px"
                                                  show={true}
                                                  style={{color:'black'}}
                                                  txt={`Unlock to edit`} 
                                                />
                                                {Math.round(
                                                  (Number(col) + Number.EPSILON) * 100
                                                ) / 100}
                                                % 
                                              </div>
                                            </span>
                                          </div>
                                        ) : editable.row === key &&
                                          editable.col === idx ? (
                                          <span
                                          style={{alignItems:"center"}}
                                            onMouseLeave={(e: any) => {

                                              const data = [...tableData];
                                            
                                              const checkNumber = parseInt(
                                                String(e.target.value).replace(
                                                  /%/g, 
                                                  ""
                                                ) 
                                              );
                                            
                                              // if (!isNaN(checkNumber)) {
                                              //   let arr: any = [];
                                              //   data[22]
                                              //     .slice(1)
                                              //     .forEach(
                                              //       (item: any, index: any) => {
                                              //         ///
                                              //         arr.push({
                                              //           AnnualGrowth: item,
                                              //           equityGainGrowth:
                                              //             data[23][index + 1],
                                              //           cashGainGrowth:
                                              //             data[24][index + 1],
                                              //         });
                                              //       }
                                              //     );
                                              //   CalculateOnPurchaseMethodChanged(
                                              //     arr,
                                              //     idx - 1,
                                              //     PropertyGenerators.length - 1
                                              //   );
                                              // }
                                              let arr: any = [];
                                              data[22]
                                                .slice(1)
                                                .forEach(
                                                  (item: any, index: any) => {
                                                    ///
                                                    arr.push({
                                                      AnnualGrowth: item,
                                                      equityGainGrowth:
                                                        data[23][index + 1],
                                                      cashGainGrowth:
                                                        data[24][index + 1],
                                                    });
                                                  }
                                                );
                                                setSavestate(true)
                                              CalculateOnPurchaseMethodChanged(
                                                arr,
                                                idx - 1,
                                                PropertyGenerators.length - 1
                                              );
                                              setEditable({ row: 0, col: 0 });
                                            }} 
                                          > 
                                            <InputTexts
                                              onFocus={(e: any) =>
                                                (e.target.value = "")
                                              } 

                                              onBlur={(e: any) => {
                                                (e.target.value = `$${col.toFixed(
                                                  2
                                                )}%`);

                                              } 
                                              }
                                              type="number"
                                              style={{ border: "1px solid blue" }}
                                              width="80px"
                                              value={col}
                                              onChange={(e: any) => {
                                                setinputLoader(true)
                                                const data = [...tableData];
                                                const checkNumber = parseInt(
                                                  String(e.target.value) 
                                                );
                                               
                                                if (!isNaN(checkNumber)) {
                                                  console.log(checkNumber,e.target.value,typeof(e.target.value),!isNaN(checkNumber))
                                                  data[key][idx] = parseFloat(
                                                    e.target.value
                                                  );
                                                  console.log(data[2][idx],"idx",idx,data[key][idx])
                                                  data[key + 1][idx] =
                                                    (data[2][idx] / 100) *
                                                    data[key][idx];
                                                  setTableData(data);
                                                } 
                                              }}
                                              suffix="%"
                                              className=" bg-white rounded-0"
                                            />
                                            {inputLoader && 
                                              <ProgressSpinner style={{ width: '20px', height: '20px', marginLeft:"5px",marginTop:"5px" }}  strokeWidth="8" animationDuration=".5s" />
                                            }
                                          </span>
                                        ) : ( 
                                          <span
                                            onClick={() => {
                                              if ([22].includes(key) === true) {
                                                setEditable({
                                                  row: key,
                                                  col: idx,
                                                });
                                              }
                                            }} 
                                          >

                                            {(Math.round(
                                              (Number(col) + Number.EPSILON) * 100
                                            ) / 100).toFixed(2)}
                                            %
                                          </span>
                                        )}
                                      </p>
                                    )}
                                </td>
                              )
                            ) : [9, 15, 21].includes(key) === true || //header rows
                              isNaN(col) === true ? (
                              <td
                                key={idx}
                                className={`${GS.SOT.PropertyGenerators.length == 1
                                    ? "tleft"
                                    : "tcenter"
                                  } `}
                              >
                                <p>
                                  <span></span>
                                </p>
                              </td>
                            ) : (
                              // $ values rows
                              <td
                                key={idx}
                                className={`${GS.SOT.PropertyGenerators.length == 1
                                    ? "tleft"
                                    : "tcenter"
                                  } ${Textcolor && idx === 1 ? "hueColor" : ""}`}
                              >
                                {/* {idx} */}
                                {GS.SOT.PropertyGenerators[idx - 1]?.hidden ===
                                  false && (
                                    <p>
                                      { lockedPropeties?.PropertyGenerators &&
                                        lockedPropeties?.PropertyGenerators[idx - 1]
                                          ?.isLocked === true &&
                                        editable.row === key &&
                                        editable.col === idx ? (
                                        <div>
                                          <span 
                                            style={{ color: "black" }}
                                            onMouseLeave={() =>
                                              setEditable({ row: 0, col: 0 })
                                            }
                                          > 
                                            <div className="ToolTip">
                                              <ToolTip
                                              borderRadius={"4px"}
                                              style={{color:'black'}}
                                                top="-70px"
                                                left="0px"
                                                textAlign="center"
                                                width="150px"
                                                show={true}
                                                txt={`Unlock to edit`}
                                              />
                                              {col > 0 || col < 0
                                                ? new Intl.NumberFormat("en-US", {
                                                  style: "currency",
                                                  currency: "USD", 
                                                  maximumFractionDigits: 2,
                                                }).format(col)
                                                : "$0.00"}
                                            </div>
                                          </span>
                                        </div>
                                      ) : editable.row === key &&
                                        editable.col === idx ? (
                                        <span

                                          onMouseLeave={(e: any) => {
                                            
                                            const data = [...tableData];
                                            const checkNumber = parseInt(
                                              String(e.target.value)
                                                .replace(/,/g, "")
                                                .substring(1)
                                            );
                                            console.log("i am leaving",checkNumber,editInputVal)
                                            if (!isNaN(editInputVal)) {
                                              let equityGain: any;
                                              let cashGain: any;
                                              let AnnualGrowth: any;
                                              
                                              let purchasePrice=0
                                              if(key==2){
                                                purchasePrice=editInputVal
                                              }
                                              // console.log(key,"key",purchasePrice,idx)
                                              if (key === 23) {
                                                AnnualGrowth =
                                                  (editInputVal / data[2][idx]) *
                                                  100;
                                                  // console.log(checkNumber,"checkNumber",data[2][idx])
                                                equityGain = editInputVal;
                                                cashGain = data[key + 1][idx];
                                              } else if (key === 24) {
                                                equityGain = data[key - 1][idx];
                                                cashGain = editInputVal;
                                              }

                                              let arr: any = []; 
                                              data[22]
                                                .slice(1)
                                                .forEach(
                                                  (item: any, index: any) => { 
                                                    arr.push({
                                                      AnnualGrowth: item,
                                                      equityGainGrowth:
                                                        data[23][index + 1],
                                                      cashGainGrowth:
                                                        data[24][index + 1],

                                                    });
                                                  }
                                                );

                                                setSavestate(true)
                                              CalculateOnPurchaseMethodChanged(
                                                arr,
                                                idx - 1,
                                                PropertyGenerators.length - 1,
                                                purchasePrice,
                                                idx-1 
                                              ); 
                                              seteditInputVal("");
                                             
                                            }
                                            setEditable({ row: 0, col: 0 });
                                          }}
                                        > 
                                          <InputNumber
                                            style={{ border: "1px solid blue" }}
                                            onFocus={(e) => (e.target.value = "")}
                                            onBlur={(e) => {
                                              e.target.value = `${col.toFixed( 
                                                2
                                              )}` 
                                              console.log(col,"col")
                                              seteditInputVal(col)
                                                ; 
                                            }}
                                            value={col}
                                            onChange={(e) => {
                                              const data = [...tableData];
                                              seteditInputVal(e.value)
                                              const checkNumber = parseInt(
                                                String(e.value)
                                              ); 
                                              if (!isNaN(checkNumber)) {
                                                data[key][idx] = e.value;

                                               
                                                if (key === 23) {
                                                  data[key - 1][idx] =
                                                    (data[key][idx] /
                                                      data[2][idx]) *
                                                    100;
                                                }
                                                setTableData(data);
                                              }
                                            }}
                                            mode="currency"
                                            currency="USD"
                                            locale="en-US"
                                            inputClassName="tbl-input border-none bg-white rounded-0"
                                          />
                                        </span>
                                      ) : 
                                      <>
                                      {
                                        key==2 && idx<=3?
                                        <span
                                       
                                        style={{position:"relative"}}
                                        onClick={() => {
                                          if ([2].includes(key) === true) {
                                            setEditable({
                                              row: key,
                                              col: idx,
                                            });
                                          }
                                        }}
                                      >
                                     {GS.SOT.PropertyGenerators[(idx-1)].priceReduce =="Y" &&
                                     <ToolTip
                                     borderRadius={"4px"}
                                     top="-70px"
                                     left="0px"
                                     textAlign="center"
                                     width="150px"
                                     show={showPriceReduce}
                                     style={{color:'red'}}
                                     txt={<p>Not enough cash <br/> purchase price reduced.</p>}
                                   />
                                     }   
                                        {col > 0 || col < 0
                                          ? new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 2,
                                          }).format(col)
                                          : "$0.00"}
                                          {  
                                          GS.SOT.PropertyGenerators[(idx-1)].priceReduce =="Y" &&
                                          <i
                                      onMouseEnter={()=>setshowPriceReduce(true)}
                                      onMouseLeave={()=>setshowPriceReduce(false)}
                        className="ml-2 pi pi-info-circle " style={{color:'red'}}
                      ></i>
                                          
                                          }
                                          
                                           
                                      </span>:
                                       <span
                                       onClick={() => {
                                         if ([2,23, 24].includes(key) === true) {
                                           setEditable({
                                             row: key,
                                             col: idx,
                                           });
                                         }
                                       }}
                                     >
                                       {col > 0 || col < 0
                                         ? new Intl.NumberFormat("en-US", {
                                           style: "currency",
                                           currency: "USD",
                                           maximumFractionDigits: 2, 
                                         }).format(col)
                                         : "$0.00"} 
                                     </span> 
                                      }
                                      </>
                                      
                                      }
                                    </p>
                                  )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                        </>
                      );
                    })}
                 
                  <tr>
                    {[...Array(PropertyGenerators.length + 2)].map(
                      (n: any, idx: any) => (
                        <td key={idx}></td>
                      )
                    )}
                  </tr>
                </tbody> 
              </table>
            </div>
          </div>

        </>
      );
    } else {
      return (
        <>
          <p
            style={{
              width: "100%",
              padding: "35px 10px",
              backgroundColor: "#FFF",
              textAlign: "center",
            }}
          >
            {"More Cash and Equity Required"}
          </p>
        </>
      );
    }
  };
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType")=="Contacts"? props.VendorData.data?.contact_type:sessionStorage.getItem("accountType")  //sessionStorage.getItem("accountType");
    if ((accountType === "Contacts")|| (accountType === "Contact Lite") ) {
      setTextcolor(true);
      setB4(9999);
    } else {
      setTextcolor(false);
    }
   
  }, []);
 const Changeoptions=(idx:any)=>{
  let regionsArr=regions[
    PropertyGenerators[idx - 1]
      .medianPropertyType
    ]
  let regs=regionsArr.map((item:any)=>{
    let newobj={
      ...item,
      label:item.label+`, ${item.state}`
    } 
    return newobj
  }) 

 return regs
 }
  const showSuccess = (Message:any) => {
    toast.current.show({
      severity: "warn",
      life:1000,
      content: (
        <div
          style={{ textAlign:"center",width:"100%" }}
        >
          {Message}
          
        </div>
      ),
    });
  };
  
  return (
    <>
    <Toast ref={toast} />
      <div className="sumaryboxes" style={{ marginTop: "40px" }}>
        <div className="boxs1 myboxes">
          <div className="grid">
            <div className="col-fixed1">
              <div
                className="icon"
              // onClick={() => setEdit({ ...edit, cashFlows: !edit.cashFlows })}
              >
                <img style={{ color: "var(--main-blue)" }} src={EditIcon} />
              </div>
              <p style={{ marginTop: "10px" }}>
                Lifestyle Goal <br />
              </p>
              {edit.cashFlows === false ? (
                <p>
                  {!isNaN(B3)
                    ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    }).format(B3)
                    : "$0"}
                </p>
              ) : (
                <div> 
                  <InputNumber
                    value={B3}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    min={0.0}
                    className="input-field mr-3 w-100"
                  />
                </div>
              )}
            </div>

            <div className="col-fixed1">
              <div className="icon">
                <img src={EditIcon} />
              </div>
              <p style={{ marginTop: "10px",fontSize:"13px",color:"#000",display:"flex",flexDirection:"row",gap:"3px",alignItems:"center",justifyContent:"center" }}>Goal Achieved in Year
             {B4==0 && 
             <i
             onMouseEnter={() => setIdx("hovering")}
             onMouseLeave={() => setIdx(6)}
             className="pi pi-info-circle "
           ></i>
             } 
             <div className="ToolTip">
             <ToolTips
                  top="25px" 
                    width="270px"
                    textAlign="left"
                    show={idx === "hovering" ? true : false}
                    txt="Some values are negative check spending or finance"
                  /> 
             </div>
              </p>
              {edit.financialYear === false ? (
                <div className={Textcolor == true ? "blur1" : ""}>
                  <p className={Textcolor == true ? "BlurYear" : ""}>
                    {B4 === 0 ? "" : B4}
                  </p>
                  <div className={Textcolor == true ? "premiumaccess" : ""}>
                    {Textcolor == true ? "Premium Access Only." : ""}
                  </div>
                </div>
              ) : (
                <div 
                  className={Textcolor == true ? "blur" : ""}
                  style={{ marginTop: "15px" }}
                ></div>
              )}
            </div>
            <div className="col-fixed">
              <p className="mt-2 ">Total Portfolio Value</p>
              <p className={Textcolor == true ? "  mb-2" : "mb-2"}>
                {/* {console.log(tableData,"tableData")} */}
                {tableData &&
                  CurrencyFormatter.format(Number(tableData[2]?.slice(-1)[0])+(GS?.SOT?.Equity?.PPR?.DomainAutoVal || 0)+(propertyval||0)
                    
                    )}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 ">Average Yield</p> 
              <p className="mb-2">
                
                {(
                  GS?.SOT?.PropertyGenerators?.reduce(
                    (total: any, NextProperty: any) =>
                    (total +=
                      Number(NextProperty.summaryData.RiskFactoredYieldReturn) > 0
                        ? Number(NextProperty.summaryData.RiskFactoredYieldReturn)
                        : 5), 
                    0
                  ) / GS?.SOT?.PropertyGenerators.length 
                ).toFixed(2)}
                %
              </p> 
            </div>

            <div className="col-fixed boxShow">
              <p className="purchase-txt">
                <br />
                Next Purchase Date
              </p>
              <p>{nextDate}</p>
            </div>
            <div className="col-fixed boxShow">
              <p className="mt-2 ">Cash + Equity Available</p>
              <p className="mb-2">
                {CurrencyFormatter.format(calculateCashAndEquityAvailable())}
              </p>
            </div>
            <div className="col-fixed boxShow">
              <p className="mt-2">Year 1 Cashflow</p>
              <p className="mb-2">
                {CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowAnnual
                )}
              </p>
            </div>
            <div className="col-fixed boxShow">
              <p className="mt-2 ">Year 1 Cashflow (Monthly)</p>
              <p className="mb-2">
                {CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowMonthly
                )}
              </p>
            </div>
          </div>
          <div className="grid boxHide">
            <div className="col-fixed">
              <p className="purchase-txt">
                <br />
                Next Purchase Date
              </p>
              <p>{nextDate}</p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 ">Cash + Equity Available</p>
              <p className="mb-2">
                {CurrencyFormatter.format(calculateCashAndEquityAvailable())}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2">Year 1 Cashflow</p>
              <p className="mb-2">
                {CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowAnnual
                )}
              </p>
            </div> 
            <div className="col-fixed">
              <p className="mt-2 ">Year 1 Cashflow (Monthly)</p>
              <p className="mb-2">
                {CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowMonthly
                )}
              </p>
            </div>
          
        
          </div>
          {sessionStorage.getItem("accountType")=="Master Admin" &&
           <div style={{display:'flex',justifyContent:'flex-end',marginTop:"-20px"}}>
           <GradientButton
            width="124px" 
            style={{height:"35px",width:"150px"}}
           icon={undefined}
           txt={"Property Refresh"}
           onClick={(e:any)=>
            
             {
               CalculateOnPurchaseMethodChanged([])
               setTimeout(()=>{
                 showSuccess("Property Regenerate Successfully")
               },2000)
              }
           
           }
           />
           </div>
          }
         
        </div>

        {/* <div
          className={`${
            PageScrollPosition > 1200 ? "fixedTopFilters" : ""
          } filter-container  my-3 d-flex  justify-content-end align-items-center`}
        >
          <span
            onClick={prevBtnNavigation}
            className={`prevNavigation1 ${
              ScrollPosition >= 180 ? "active" : ""
            }`}
          >
            <img src={ArrowIcon} alt="" />
          </span>
          <span
            onClick={nextBtnNavigation}
            className={`navigation1 ${
              ScrollPosition <= TableRef?.current?.scrollWidth ? "active" : ""
            }`}
          >
            <img src={ArrowIcon} alt="" />
          </span>
        </div> */}

        {showNotifications()}
        {renderSummaryTable()}
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "50px" }}>
        <GradientButton
          style={{ 
            position: "fixed",
            bottom: "31px",
            right: "94px",
            width:"89px !important"
          }}
          width="89px"
          icon={undefined} 
          txt={"Next"}
          onClick={async () => {
            await updateProfile();
            navigate(`/Clients/IncomePlan?id=${props.vendorid}`);
          }}
        /> 
        <GradientButton
          style={{ 
            position: "fixed",
            bottom: "31px",
            right: "196px",
            width:"89px !important"
          }}
          width="89px"
          icon={undefined} 
          onClick={() => {
            // Navigate(-1);
            props.onBack(); 
          }}
          txt="Back"
        />
      </div>
    </>
  );
}

export default PortfolioSummary;
