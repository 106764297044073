import React, { useState, useEffect } from "react";

export const useFetchReportsData = (
  url = "all-report-local",
  type = "commercial",
  id = "",
  Client = "",
  ReportOptions = [],
  TopFilters,
  setTopFilters,
  callback,
  handleColumns
) => {
  const [isReportLoading, setisReportLoading] = useState(false);
  const CallListingApi = async () => {
    setisReportLoading(true);
    let queryparams = `?type=${type}`;

    if (id?.length !== 0 && Client?.length !== 0) {
      queryparams =
        queryparams +
        `&client=${Client}&id=${id}&perPage=${
          TopFilters.PerPage > 0 ? TopFilters.PerPage : 50
        }&pageNo=${TopFilters.Page}`; 
    } else if (Client?.length !== 0 && id?.length == 0) {
      queryparams =
        queryparams +
        `&client=${Client}&perPage=${
          TopFilters.PerPage > 0 ? TopFilters.PerPage : 50
        }&pageNo=${TopFilters.Page}`;
    }

    let result = await fetch(
      `${process.env.REACT_APP_API_BASEURL}${url}${queryparams}`,
      {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((property) => property);

    if (result) {
      setisReportLoading(false);
      let reportsdata = result.data;
      let reportsArr = [];
      let columns=[]
      
      reportsdata.map((item) => {
        // console.log(id,"id",item)
        if (id !== "") {
          setTopFilters(item.filters);
          if(item.Columns){
            columns=item.Columns
          }
        }
        

        reportsArr = [
          ...reportsArr,
          { label: item.reportName, value: item._id },
        ];
      });
      if(id!==""){
        handleColumns(columns)
      }
      if (id == "") {
        callback(reportsArr);
        
      }
      setisReportLoading(false);
    }
  };

  useEffect(() => {
    if (Client !== "") {
      CallListingApi();
    }
  }, [id, Client]);

  return { isReportLoading };
};
