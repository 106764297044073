import PropertyCardProfile from "../PropertyCard/PropertyCardProfile";
import { IncomePlanContainer } from "./IncomePlan.style";
import IncomePlanTimeline from "./IncomePlanTimeline";
// import Pic1 from "../../../src/assets/images/propertybg.png";
import PoweredBy from "../../../src/assets/SVG/Domain_PoweredbyDomain_Logo_RGB_GREEN.svg";
import Pic1 from "../../../src/assets/empty.svg";
import { GS } from "../../services/global";
import { Row, Col } from "react-bootstrap";
import { commaSeparators, CurrencyFormatter } from "../../utils";
import { API } from "../../services/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImagesFromDomain } from "../../redux/actions";
import GradientButton from "../Button/GradientButton";
import Loading from "../Loading";
import MessegenerChat from "../Messenger";
import "./css.css"
const IncomePlan = (props) => {
  const [state, setData] = useState(GS.SOT.PropertyGenerators);
  

 let NetYield= GS?.SOT?.PropertyGenerators?.reduce(
    (total, NextProperty) =>
    (total +=
      Number(NextProperty.targetYieldReturn) > 0
        ? Number(NextProperty.targetYieldReturn)
        : 5), 
    0
  ) / GS?.SOT?.PropertyGenerators.length
 
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    let checkAccount=localStorage.getItem("accountType") 
    if(accountType=="Master Admin"){
      setIsAdmin(true)
    }else{
      if ( checkAccount !== "Contact Lite") { 
        setIsAdmin(true);
      }
    }
    localStorage.setItem('SOT',JSON.stringify(GS.SOT))
  }, []);
  const { data } = useSelector((state) => state.IncomePlan);
  const regions = useSelector((state) => state.Domain);
   
  const dispatch = useDispatch();
  let getImagesData = async () => {
    const arr = state.map((item, idx) => {
      let propertyType = item.summaryData.propertyType.includes("Commercial")
        ? "commercial"
        : "residential";

      let type =
        item.medianPropertyType == "residential"
          ? null
          : item.medianPropertyType;
         
      return {
        type,
        state:
          GS.SOT.PortfolioSummary?.Properties[item.medianPropertyType][
            item.summaryData.regionNo
          ]?.name, 
        postcode: 
          regions.data[item.medianPropertyType][item.summaryData.regionNo]
            ?.postcode,
        price: item.summaryData.PurchasePrice,
        propertyTypes: propertyType,
        // region:
        //   GS.SOT.PortfolioSummary?.Properties[item.medianPropertyType][item.regionNo]
        //     ?.region,
      };
    });

    let body = [];
    let postcodes = [];
    
    arr.map((i) => {
      if (body.length === 0) {
        body.push(i);
        postcodes.push(i.postcode);
      } else {
        let alreadyExistPostCode = body.find(
          (data) => data.postcode === i.postcode
        );
        if (alreadyExistPostCode) {
          if (i.propertyTypes === "residential") {
            let postcodeData = regions.regionsData.residential.find((data) => {
              return (
                data.State === i.state &&
                !postcodes.includes( 
                  data.address ? data.address.postcode : data.postcode
                )
              );
            });
            if (!postcodeData) {
              postcodeData = regions.regionsData.residential.find((data) => {
                return (
                  data.State !== i.state &&
                  !postcodes.includes(
                    data.address ? data.address.postcode : data.postcode
                  )
                );
              });
              if (!postcodeData) {
                postcodes.push(i.postcode); 
                return body.push(i);
              }
            }

            const postcode = postcodeData.address
              ? postcodeData.address.postcode
              : postcodeData.postcode;
            postcodes.push(postcode);
            body.push({ ...i, postcode: Number(postcode) });
          } else if (i.propertyTypes === "commercial") {
            if (i.type === "retail") {
              let postcodeData = regions?.regionsData?.retail?.find((data) => {
                return (
                  data.State === i.state && !postcodes.includes(data.postcode)
                ); 
              });
              if (postcodeData) { 
                postcodes.push(postcodeData.postcode);
              }else if (!postcodeData) {
                postcodeData = regions?.regionsData?.retail?.find((data) => {
                  return (
                    data.State !== i.state && !postcodes.includes(data.postcode)
                  );
                });
                if (!postcodeData) {
                  postcodes.push(i.postcode);
                  return body.push(i);
                }
              }
              body.push({ ...i, postcode: Number(postcodeData.postcode) });
            }
            if (i.type === "office") {
              let postcodeData = regions.regionsData.office.find((data) => {
                return (
                  data.State === i.state && !postcodes.includes(data.postcode)
                );
              });
              if (postcodeData) {
                postcodes.push(postcodeData.postcode);
              } else if (!postcodeData) {
                postcodeData = regions.regionsData.office.find((data) => {
                  return (
                    data.State !== i.state && !postcodes.includes(data.postcode)
                  );
                });
                if (!postcodeData) {
                  postcodes.push(i.postcode);
                  return body.push(i);
                }
              }
              body.push({ ...i, postcode: Number(postcodeData.postcode) });
            }
          }
        } else {
          postcodes.push(i.postcode);
          body.push(i);
        }
      }
    });

    try {
      const res = await API.post("domain/propertiesData", {
        propertiesData: body,
      });
    //  console.log(res?.data,"res.data")
      const updated = res?.data?.map((item, idx) => {
        if (item?.props === null || item?.props?.length === 0) {
          return {
            ...state[idx],
            listingData: {
              TotalPurchaseCosts: state[idx]?.summaryData?.TotalPurchaseCosts,
              AcquisitionCost: state[idx]?.summaryData?.AcquisitionCost,
              StampDuty: state[idx]?.summaryData?.StampDuty,
              Deposit: state[idx]?.summaryData?.Deposit,
              image: Pic1,
              purchasePrice: state[idx]?.summaryData?.PurchasePrice,
              ad: `#`,
              tdr: state[idx]?.summaryData?.TotalDepositRequired,
              GrossRent: state[idx]?.summaryData?.PropertyRentIncomeYear1,
              NetYield: state[idx]?.tableData?.NetYieldonPropertyYear1?.toFixed(1),
              CFA: state[idx]?.summaryData?.CashflowAnnual,
            },
            domainPropertyType: "notfound",
            idx: idx,
          };
        }
        if (item.propertyTypes === "residential") {
          let listing = item?.props[0]?.listing
            ? item?.props[0]?.listing
            : item?.props[0]?.listings[0];
            
          const pp = item?.props[0]?.extractedPrice
            ? item?.props[0]?.extractedPrice
            : 0;
          //  parseInt(item.price.toFixed(2));  //extracted price
          const {
            tdr,
            NetYield,
            CFA,
            GrossRent,
            TotalPurchaseCosts,
            AcquisitionCost,
            StampDuty,
            Deposit,
          } = calculate(pp, state[idx], listing?.propertyDetails?.state,idx);
         
          return {
            ...state[idx],
            ...item?.props,
            listingData: {
              image: listing?.media?.length>0 && listing?.media[0]?.url,
              purchasePrice: pp,
              ad: `https://www.domain.com.au/${listing?.listingSlug}`,
              tdr,
              NetYield,
              GrossRent,
              CFA,
              TotalPurchaseCosts,
              AcquisitionCost,
              StampDuty,
              Deposit,
            },
            domainPropertyType: "resi",
            idx: idx, 
          };
        } else {
          
          const pp =
            item?.props[0]?.extractedPrice && item?.props[0]?.extractedPrice > 0
              ? parseInt(item?.props[0]?.extractedPrice)
              : item?.price;
            
          const {
            tdr,
            NetYield,
            CFA,
            GrossRent,
            TotalPurchaseCosts,
            AcquisitionCost, 
            StampDuty,
            Deposit,
          } = calculate(
            pp,
            state[idx],
            regions?.data[state[idx]?.medianPropertyType][
              state[idx]?.summaryData?.regionNo
            ]?.State,
            idx
          );
          
          return {
            ...state[idx],
            ...item?.props,
            listing: { ...item?.props[0] },
            listingData: {
              TotalPurchaseCosts,
              AcquisitionCost,
              StampDuty,
              Deposit,
              image: item?.props[0]?.media[0]?.imageUrl,
              purchasePrice: pp,
              ad: item?.props[0]?.ad?.url,
              tdr,
              NetYield,
              GrossRent,
              CFA,
            },
            domainPropertyType: "commercial",
            idx: idx,
          };
        }
      });
      dispatch(getImagesFromDomain(updated));

    } catch (err) {
      console.log("here", err);
    }
  };


  const calculate = (pp, data, stateName,idx) => {
   
    let StampDuty;
    let AcquisitionCost;
    let TotalDebt;
    let Deposit;
    let TotalDepositRequired;
    let TotalCostofLending;
    let TotalPurchaseCosts;
    let CashflowAnnual;
    let acquistonCost=6.50
    let riskfactor=data?.summaryData?.RiskFactoredYieldReturn||6
    let rent=data.summaryData.PropertyRentIncomeYear1
    let interestRate=data.summaryData.interestRate || 5
    let debt;
    if(idx<=3){
       StampDuty = GS.stampDutyRate(stateName, pp);
       AcquisitionCost = pp * 0.025;
       TotalDebt = pp * (data.summaryData.LoantoValueRatioLVR);
      let deposit=pp-TotalDebt
       let equitydeposit=deposit-data.cashAvailable
       Deposit = pp - TotalDebt;
      TotalDepositRequired = Math.floor(
        data.cashAvailable +equitydeposit  + AcquisitionCost
      );
       TotalCostofLending =
        TotalDebt * (data.summaryData.DebtInterestRate / 100) +
        equitydeposit * (data.summaryData.EquityInterestRate / 100);
       TotalPurchaseCosts = pp + StampDuty + AcquisitionCost;
      let newpropertyIncome=pp*(riskfactor/100)
      let PropertyRentIncomeYear1=newpropertyIncome
      rent=PropertyRentIncomeYear1
       CashflowAnnual = Number(PropertyRentIncomeYear1)- ((Number(pp-TotalDepositRequired))*(Number(interestRate/100)))    //PropertyRentIncomeYear1 - TotalCostofLending;
       
      //  console.log(
      //     "PurchasePrice",pp,"\n",
      //     "deposit",deposit,"\n",
      //     "TotalDebt", TotalDebt,"\n",
      //   "TotalDepositRequired",TotalDepositRequired,"\n",
      //   "AcquisitionCost",AcquisitionCost,"\n",
      //   "propertyno",idx,"\n",
      //   "equitydeposit",equitydeposit,"\n",
      //   "PropertyRentIncomeYear1",PropertyRentIncomeYear1,"\n",
      //   "interestRate",interestRate,"\n",
      //   "CashflowAnnual",CashflowAnnual,
      //   ) 
    }else{
       StampDuty = GS.stampDutyRate(stateName, pp);
       AcquisitionCost = pp * 0.025;
       TotalDebt = pp * data.summaryData.LoantoValueRatioLVR;
       Deposit = pp - TotalDebt;
       TotalDepositRequired = Math.floor(
        Deposit + StampDuty + AcquisitionCost
      );
       TotalCostofLending =
        TotalDebt * (data.summaryData.DebtInterestRate  / 100) +
        data.summaryData.TotalDepositEquity *
          (data.summaryData.EquityInterestRate/ 100);
       TotalPurchaseCosts = pp + StampDuty + AcquisitionCost;
       CashflowAnnual =
        data.summaryData.PropertyRentIncomeYear1 - TotalCostofLending;
    }
    let NetYield=(pp*(riskfactor/100))
    // console.log(pp,"pp",data,"idx",idx,NetYield,riskfactor)
 
    return {
      TotalPurchaseCosts,
      Deposit, 
      AcquisitionCost,
      StampDuty,
      GrossRent: rent,
      CFA: CashflowAnnual,
      tdr: TotalDepositRequired, //TotalDepositRequired
      NetYield: NetYield , //data.targetYieldReturn
    };
  };

  useEffect(() => {
    if (regions.regionsData.residential.length > 0) {
      getImagesData();
    }
  }, [regions.regionsData]); // eslint-disable-line react-hooks/exhaustive-deps

const compareProperties=()=>{
const arr1=GS.SOT.PropertyGenerators
const arr2=GS.SOT2.PropertyGenerators
if (arr1.length !== arr2.length) {
  // If the arrays have different lengths, there must be a change
  return true;
}
for (let i = 0; i < arr1.length; i++) {
  const obj1 = arr1[i];
  const obj2 = arr2[i]; 
 
  // Compare the properties of each object
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
      // If a property is different, there is a change
      return true;
    }
  }
  return false;
}
}
  useEffect(() => {
    if (data){
      let checkComman=compareProperties()
    
      if(checkComman){
        setData(data);
        GS.SOT.IncomePlan=data
        API.AutoSave()
      }else{
        setData(GS.SOT.IncomePlan)
      }
     
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps
 
  return (
    <IncomePlanContainer>
      <p className="propertyTitle">Property Timeline</p>
      <IncomePlanTimeline admin={isAdmin} data={state} />
      <div className="poweredby flex justify-content-end">
      {/* <img src={PoweredBy}></img> */}
      </div>  
      <div className="income-plan-detail"> 
      
        <Row className="w-100">
            
          {Boolean(!data) ? ( 
            <Loading />
          ) : (
            state?.map((i, indx) => (
              <Col key={indx} md={4} style={{ marginBottom: "10px" }}>
                <PropertyCardProfile
                  number={indx + 1}
                  img={i.listingData ? i.listingData.image : Pic1}
                  propertyType={i.summaryData?.propertyType}
                  price={i?.listingData?.purchasePrice || 0}
                  netrental={i?.listingData?.GrossRent||0}
                  PurchasePrice={
                    i.listingData
                      ? commaSeparators(
                          isNaN(i.listingData.purchasePrice)
                            ? 0
                            : i.listingData.purchasePrice
                        )
                      : 0
                  }
                  Deposit={commaSeparators( 
                    isNaN(i.listingData?.tdr) ? 0 : i.listingData?.tdr
                  )}
                  GrossYield={ NetYield.toFixed(2)} //  isNaN(i.targetYieldReturn) ? 0 : i.targetYieldReturn
                  LVR={
                    isNaN(i.summaryData?.LoantoValueRatioLVR)
                      ? 0
                      : (i.summaryData?.LoantoValueRatioLVR * 100) + 
                        "%"
                  }
                  InterestRate={`${i?.summaryData?.interestRate}%`}
                  RiskProfile={i.tableData?.NetYieldonPropertyYear1?.toFixed(
                    1
                  )}
                  NetCashflow={CurrencyFormatter.format(
                    isNaN(i.listingData?.CFA) ? 0 : i.listingData?.CFA
                  )}
                  GrossRentalIncome={
                    i.listingData && isNaN
                      ? commaSeparators(
                          isNaN(i.listingData.GrossRent)
                            ? 0
                            : i.listingData.GrossRent
                        ) 
                      : 0
                  }
                  SaleRange="1,086"
                  admin={isAdmin}
                  data={state}
                  index={indx}
                  item={i}
                  email={props?.VendorData?.data?.email || ""}
                  mobile={props?.VendorData?.data?.mobile_no || ""}
                  name={props?.VendorData?.data?.full_name|| ""}
                  contact_address={props?.VendorData?.data?.contact_address || ""}
                  _id={props?.VendorData?.data?._id || ""}
                />
              </Col>
            ))
          )}
        </Row>
      </div>
      <GradientButton 
        style={{
          position: "fixed",
          bottom: "10px",
          // right: "10px  ",
        }}
        onClick={() => {
          props.onBack();
        }}
        width="89px"
        txt="Back"
      />
      <MessegenerChat/>
    </IncomePlanContainer>
  );
};

export default IncomePlan;
