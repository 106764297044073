const initialState = [];

const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REGIONS":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export default regionReducer;
