import React from 'react'
import { Col, Image, Row } from "react-bootstrap";
import Logo from "../../assets/images/invest-logic.png";
import Logo1 from "../../assets/images/logo.png";
import SignUpBg from "../../assets/images/signup-bg.png";
import SignInBg from "../../assets/images/signin-bg.png";
import classes from "../SignIn/stylesheet.module.css";
import myclasses from "./stylesheet.module.css"
import SignupForm from '../../components/SignupForm';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
const Signup = () => {
 
  const mystate=useSelector((state)=>state.ThemePattern)
  return ( 
    <Row className={classes.mainContainer}>
      <Col xs={12} sm={12} md={6} lg={6} className={myclasses.vectorInfo}>
      {mystate==="Propvesting" && <div className={classes.logoContainer}>
          <Image src={Logo1} className={classes.logo} />
        </div>} 
        <div className={myclasses.vectorImgContainer} style={mystate==="Propvesting"?{paddingTop:"180px"}:{}}>
        <Image src={mystate==="Propvesting"?SignInBg:SignUpBg} className={classes.vectorImg} />
        </div>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} className={myclasses.RemovePadding}>
        <div className={myclasses.formContainer}>
        {mystate==="Investlogic" && <div className={myclasses.logoContainer}>
          <Image src={mystate==="Propvesting"?Logo1:Logo} className={classes.logo} />
        </div>} 
          <div>
            <h2 className={`${myclasses.greeting} my-4`}>{mystate==="Propvesting"?"WELCOME TO PROPVESTING":"WELCOME TO INVESTLOGIC"}</h2>
          </div>
          
            <h3 className={myclasses.title}>Sign up</h3>
          <SignupForm/>
          <p className={myclasses.link}>Already have an account? <Link to="/signin" className={myclasses.link}>Login</Link></p>
        </div>
      </Col>
    </Row>
  )
}

export default Signup