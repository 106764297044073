import { InputText } from "primereact/inputtext";
import { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { API } from "../../services/api";
import Avatar from "../Avatar/Avatar";
import "./stylesheet.css";
import { publish } from "../../services/global";
import { ContactDropDown, ContactContainer } from "../Contact/Contact.style";
import { validateImage } from "../../utils";
import { GS } from "../../services/global";
import GradientButton from "../Button/GradientButton";
import { useDispatch,useSelector } from "react-redux";
import { setLockedProperties,getImagesFromDomain,SetBasiqUser } from "../../redux/actions";
import { formatAusNumber } from "../../services/global";
import { Toast } from "primereact/toast";
import filedata from "./dummy.json"
import ClientPreferences from "../RiskProfile/ClientPreferences";
import { getAllBasicUsers } from "../../core/services/basiq-io";
export default function VendorProfileInfo({
  vendorid,
  setActiveIndex,
  VendorData,
  OnChange, 
  IncomeGoal,
  setIncomeGoal,
}) {
  
  localStorage.setItem("accountType",VendorData.data.contact_type)
  const [CheckUser, setCheckUser] = useState(false);
  const [FirstName, setFirstName] = useState(VendorData?.data?.first_name);
  const [LastName, setLastName] = useState(VendorData?.data?.last_name);
  const [Mobile, setMobile] = useState(VendorData?.data?.mobile_no?.length>3?VendorData?.data?.mobile_no:"+61");
  const toast = useRef(null);
  const dispatch=useDispatch();
  const allbasiqusers=useSelector((state)=>state.BasiqReducer)
  const [userdata, setuserdata] = useState({
    photo: VendorData?.data?.photo,
  });
  const [propertyType, setPropertyType] = useState("Blended");
  const [initialRender, setInitial] = useState(true);
const[initialShow,setInitialShow]=useState(true);
  const [PhoneNumber, setPhoneNumber] = useState(
    VendorData?.data?.telephone_no
  );
  const [Email, setEmail] = useState(VendorData?.data?.email);
  const [ContactType, setContactType] = useState(
    VendorData?.data?.contact_type
  );
  const [photo, setPhoto] = useState("");
  const [Address, setAddress] = useState(VendorData?.data?.contact_address);
  const [PrimaryContact, setPrimaryContact] = useState(
    VendorData?.data?.enquiry_source
  );
  const [Agent, setAgent] = useState(VendorData?.data?.agent);

  const [agentTypes, setAgentTypes] = useState([]);
  let useremail=""
  if(localStorage.getItem("email")!==null && localStorage.getItem("email")!==undefined){
    useremail=JSON.parse(localStorage.getItem("email"))
  }
  const ContactTypes = [
    { label: "Lite", value: "Contact Lite" },
    { label: "Premium", value: "Contact Paid" },
  ];
  const ContactTypes2 = [
    { label: "Lite", value: "Contact Lite" },
    { label: "Premium", value: "Contact Paid" },
  ]
  const accounttype=sessionStorage.getItem("accountType")
  const [AdminAllow,setAdminAllow]=useState(useremail==process.env.REACT_APP_ADMIN_EMAIL)
  const ChangePhoneFormat=()=>{
    if(Mobile!=="+"){
     if(Mobile[0]=="0"){
       let newnumber="+61"+Mobile.slice(1,Mobile.length)
       setMobile(newnumber)
     }else{
       let newnumber="+61"+Mobile
       setMobile(newnumber)
     }
    }
   
   }
  const getUsers = async () => {
    const data = await API.get(`users`);
    let usersData = [];
    data.data.map((user) =>{
      if(user.full_name=="PropVesting Admin"){
        usersData.push({ label: "InvestLogic", value: "InvestLogic" })
      }else{
        usersData.push({ label: user.full_name, value: user.full_name })
      }
    }
    );
    setInitial(false);
    if (!Agent) {
      setAgent(usersData[0].label);
    }
    if(Agent=="PropVesting Admin"){
      setAgent("InvestLogic");
    }
    setAgentTypes(usersData);
    
  };

  const AddressRef = useRef();
  const autoCompleteRef = useRef();
  let UpdateVendorProfile = async () => {
    let UpdatedData = {
      first_name: FirstName,
      last_name: LastName,
      contact_type: ContactType,
      mobile_no: Mobile,
      telephone_no: PhoneNumber,
      email: Email,
      contact_address: AddressRef?.current?.value || "",
      enquiry_source: PrimaryContact,
      agent: Agent,
    }; 
    let test = validateImage(userdata.photo);
    if (test === true) {
      UpdatedData = { ...UpdatedData, photo: userdata.photo };
      
      await API.put(`contacts/${vendorid}`, UpdatedData);
      updateBasiqUser()
      console.log(localStorage.getItem("BasiqUserId"),localStorage.getItem("BasiqUserId")==undefined)
      
    }else{
      await API.put(`contacts/${vendorid}`, UpdatedData);
      
    
      updateBasiqUser()
    }
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      AddressRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      setAddress(place.formatted_address);
    });
  }, []);
 
 
const updateBasiqUser=async()=>{
  let token= sessionStorage.getItem('Token')
  let BasiqUserId=''
  if(( localStorage.getItem('BasiqUserId')!== null) && (localStorage.getItem('BasiqUserId')!== undefined)){
    BasiqUserId=JSON.parse(localStorage.getItem('BasiqUserId'))||''
   
  }
  
  let userexist=allbasiqusers?.filter((item)=>item.email==Email)
  if(userexist.length>0){
    
    BasiqUserId=userexist[0].id
    localStorage.setItem('BasiqUserId',JSON.stringify(BasiqUserId))
  }

  try{
    let user={
      email:Email,
      mobile:formatAusNumber(Mobile),
      firstName:FirstName,
      middleName:'',
      lastName:LastName,
      url:`users/${BasiqUserId}`
    }
    const headers={
      Authorization:`Bearer ${token}`,
      'Content-Type':'application/json'
    }
   let result= await fetch(`${process.env.REACT_APP_API_BASEURL}all-url-basiq`,
    {
      method:'POST',
      headers:headers,
      body:JSON.stringify(user)

    }
    )
    .then((response)=>response.json())
    .then((data)=>data.data)
    if(result?.id){
      localStorage.setItem('BasiqUserId',JSON.stringify(result?.id))
    }
    console.log(result)
    return result
   }catch(err){

   }
}
  const createBasiqUser=async(token)=>{
  
   try{
    let user={
      email:Email,
      mobile:Mobile,
      firstName:FirstName,
      middleName:'',
      lastName:LastName,
      url:'users'
    }
    const headers={
      Authorization:`Bearer ${token}`,
      'Content-Type':'application/json'
    }
   let result= await fetch(`${process.env.REACT_APP_API_BASEURL}all-url-basiq`,
    {
      method:'POST',
      headers:headers,
      body:JSON.stringify(user)

    }
    )
    .then((response)=>response.json())
    .then((data)=>data.data)
    if(result?.id){
      console.log("new basiq user created")
      localStorage.setItem('BasiqUserId',JSON.stringify(result?.id))
    }
    return result
   }catch(err){

   }
  }
  
 
  
  useEffect(() => {
    publish(`fixedtop`, { fixedtop: false });
    // getUsers();
  
    // BasiqUser()
    
    return () => {
      setInitial(true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
const getbasiqusers=async()=>{
  let result=await getAllBasicUsers();
 
  let checkuserExist=false
  let user={};
  if(result){
    if(result?.length>0){
      result?.map((item)=>{
        if((item?.email===Email)){
          checkuserExist=true
          user=item
        }
      })
    }
    if(Object.keys(user).length!==0){
      localStorage.setItem('BasiqUserId',JSON.stringify(user?.id))
    }
  }
 
  return checkuserExist
}
  useEffect(() => {
    if (initialShow) {
      setInitialShow(false);
      let result=getbasiqusers()
      if(!result){
        console.log("should create anothe user")
       createBasiqUser();
      }else{
        console.log("user exist")
      }
      
      
    }else{
      UpdateVendorProfile();
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    userdata,
    FirstName,
    LastName,
    ContactType,
    Mobile,
    PhoneNumber,
    Email,
    Address,
    PrimaryContact,
    Agent,
  ]);
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    if (accountType.toLowerCase() === "contacts") {
      setCheckUser(true);
    } else {
      setCheckUser(false);
    }
   
  }, []);
 
  const showSuccess = (Message) => { 
    toast.current.show({
      severity: "warn",
      life:1000,
      content: (
        <div
          style={{ textAlign:"center",width:"100%" }}
        >
          {Message}
          
        </div>
      ),
    });
  };

  return (
    <>
     <ContactContainer margin="none"  paddingTop="0px" paddingBottom="10px">
      <Toast ref={toast} />
      <div className="profile-container" >
        <h1 >Profile</h1>
     
        <div className="avatar-container" style={{marginTop:"10px"}}>
          <Avatar
            text={VendorData?.data?.full_name} 
            imgSrc={userdata.photo}
            setImgSrc={setPhoto}
            setuserdata={setuserdata}
            data={userdata}
            width="60px"
            height="60px"
            fontSize="20px"
            textColor="#fefefe"
          />

          <div className="avatar-text-container">
            <p className="main-text">
              {FirstName ? FirstName : ""}&nbsp;{LastName ? LastName : ""}
            </p> 
            <p className="sub-text">{ContactType}</p>
          </div>
        </div> 
      </div> 

      <div style={{marginTop:"-8px"}}>
        <Row className=" profile-input-container">
          <Col sm={12} md={4} lg={4}>
            <div className="profile-input">
              <p className="input-label mb-2">First Name</p>
              <InputText
                type={"text"}
                value={FirstName}
                onChange={(e) => setFirstName(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="profile-input">
              <p className="input-label mb-2">Last Name</p>
              <InputText
                type={"text"}
                value={LastName}
                onChange={(e) => setLastName(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="profile-input">
              <p className="input-label mb-2">Email</p>
              <InputText
                type={"email"}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
        </Row>
        <Row className=" profile-input-container">
          <Col sm={12} md={4} lg={4}>
            <div className="profile-input">
              <p className="input-label mb-2">Mobile No</p>
              <InputText
              onBlur={ChangePhoneFormat}
                type={"tel"}
                value={Mobile}
                onChange={(e) => setMobile(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="profile-input">
              <p className="input-label mb-2">Phone Number</p>
              <InputText
                type={"tel"}
                value={PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="profile-input">
              <p className="input-label mb-2">{accounttype=="Master Admin"?"Membership Type":"Membership Type abnd" }</p>
              
              <ContactDropDown
                value={ContactType}
                onChange={(e) => setContactType(e.value)}
                options={AdminAllow? ContactTypes2:ContactTypes}
                disabled={!AdminAllow ? true : false }
              />
            </div>
          </Col>
        </Row>
         {/* <Col sm={12} md={4} lg={4}>
            <div className="profile-input">
              <p className="input-label mb-2"> Address</p>
              <InputText
                onFocus={(e) => (e.target.value = "")}
                ref={AddressRef}
                type={"text"}
                value={Address}
                placeholder="Enter Address"
                onChange={(e) => setAddress(AddressRef.current.value)}
                className=" edit-input-field"
              />
            </div>
          </Col> */}
      {VendorData.data.user_type!=="Contact" && 
      <Row className="container profile-input-container">
         
      {/* <Col sm={12} md={4} lg={4}>
        <div>
          <p className="input-label mb-2">Primary Contact</p>
          <InputText
            type={"text"}
            value={PrimaryContact}
            onChange={(e) => setPrimaryContact(e.target.value)}
            className=" edit-input-field"
          />
        </div>
      </Col> */}
 <Col sm={12} md={4} lg={4}>
  <div className="profile-input">
    <p className="input-label mb-2">Agent</p>
    <ContactDropDown
      style={{ alignSelf: "center" }}
      value={Agent}
      onChange={(e) => setAgent(e.value)}
      options={agentTypes}
    />
  </div>
</Col>   
      {/* <Col sm={12} md={4} lg={4}>
      <GradientButton
    onClick={handlePropertyReset}
   style={{height:"35px",marginTop:"30px"}}
      txt="Property Reset"
    />
      </Col> */}
    </Row>
      }  
      
      </div>
    
    </ContactContainer>
    </>
  );
}
