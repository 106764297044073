import { useState, useEffect } from "react";
import classes from "../PinderPage.module.css";
import useWindowDimensions from "../../../hooks";

import { InputText } from "primereact/inputtext";
import { Button } from "react-bootstrap";
import { API } from "../../../services/api";
import { CustomDropDown } from "../pinder.style";
import { CustomMultiSelect } from "../pinder.style";
import {
  PerPageOptions,
  statesOfAustralia,
  LocalResultsItems,
} from "../../../utils/constants";
import { LiveCommercial } from "./LiveCommercial";
import { LiveResidential } from "./LiveResidential";
import ClearImg from "../../../assets/SVG/clear-format.svg";
import { useDispatch,useSelector } from "react-redux";
import { SetPinderFilter } from "../../../redux/actions";
export default function Liveproperties() {
  const { width } = useWindowDimensions();
  const dispatch=useDispatch()
  const pinderstate=localStorage.getItem("pinderfilter")

  const [LocalItem, setLocalItem] = useState(pinderstate || "residential"); //commercial
  const [TopFilters, setTopFilters] = useState({
    PostCode: [],
    Page: 1,
    PerPage:pinderstate=="residential"?200: 500,
    MaxPrice: 0,
    MinPrice: 0,
    PropertyType: [],
    ListingType: [],
    Keyword: "",
    State: [],
  });
  const ClearFilters = () => {
    setTopFilters({
      PostCode: [],
      Page: 1,
      PerPage: 0,
      MaxPrice: 0, 
      MinPrice: 0,
      PropertyType: [],
      ListingType: [],
      Keyword: "",
      State: [],
    });
  };
  const [ListingTypeSelectItems, setListingTypeSelectItems] = useState([
    { label: "For Sale", value: "Sale" },
    { label: "Rent", value: "Rent" },
    { label: "Sold", value: "Sold" },
  ]);
  const [postcodes, setpostcodes] = useState([]);
  const [propertyOptions, setpropertyOptions] = useState([]);

  const getFilterOptions = async () => {
    let response = await API.get(`type-properties-totals/${LocalItem}`);
    let postoptions = response?.data?.postcodes?.map((item) => {
      let newObj = {
        name: item._id,
        code: item._id,
      };
      return newObj;
    });
    postoptions.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
    setpostcodes(postoptions);
    let listingoptions = response?.data?.listingType?.map((item) => {
      let listing = item._id.charAt(0).toUpperCase() + item._id.slice(1);
      let capitalLetterCount = 0;
      let indexSecondCapitalLetter = -1;
      for (let i = 0; i < listing.length; i++) {
        if (listing[i] === listing[i].toUpperCase()) {
          capitalLetterCount++;
          if (capitalLetterCount === 2) {
            indexSecondCapitalLetter = i; 
            break;
          }
        }
      }
      if (indexSecondCapitalLetter != -1) {
        listing =
          listing.slice(0, indexSecondCapitalLetter) +
          " " +
          listing.slice(indexSecondCapitalLetter);
      }
      let newObj = {
        name: listing,
        code: item._id,
      };
      return newObj;
    }); 
    listingoptions.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
    setListingTypeSelectItems(listingoptions);
    let propertyoptions = response?.data?.propType?.map((item) => {
      let listing=item._id
      if(LocalItem=="residential"){
         listing = item._id.charAt(0).toUpperCase() + item._id.slice(1);
        let capitalLetterCount = 0;
        let indexSecondCapitalLetter = -1;
        let indexThirdCapitalLetter = -1;
        for (let i = 0; i < listing.length; i++) {
          if (listing[i] === listing[i].toUpperCase()) {
            capitalLetterCount++;
            if (capitalLetterCount === 2) {
              indexSecondCapitalLetter = i;
              
            }else if(capitalLetterCount === 3){
              indexThirdCapitalLetter=i
              break;
            } 
          }
        }
        if (indexThirdCapitalLetter!=-1) {
          listing =
            listing.slice(0, indexSecondCapitalLetter) +
            " " +
            listing.slice(indexSecondCapitalLetter,indexThirdCapitalLetter) +
            " "+listing.slice(indexThirdCapitalLetter)
            
            ;
        }else if(indexSecondCapitalLetter != -1 ){
          listing =
          listing.slice(0, indexSecondCapitalLetter) +
          " " +listing.slice(indexSecondCapitalLetter)
        }
      }
      let newObj = {
        name: listing,
        code: item._id,
      };
      return newObj;
    });
    propertyoptions.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
    setpropertyOptions(propertyoptions);
  };

  useEffect(() => {
    getFilterOptions(LocalItem);
  }, [LocalItem]);
  return (
    <div
      style={{ width: width - 290 + "px" }}
      className={`pinderContainer ${classes.PinderPageContainer}`}
    >
      <div className="d-flex flex-wrap  justify-content-between mt-4">
        <div>
          <h2 className={classes.pageTitle}>Live Data</h2>
        </div>
        <div style={{ width: "150px" }}>
          <CustomDropDown
            filter
            className={classes.filterDropdown}
            value={LocalItem}
            options={LocalResultsItems}
            onChange={(e) => {setLocalItem(e.value);
              if(e.value=="residential"){
                setTopFilters({ ...TopFilters, PerPage: 200 })
              }else{
                setTopFilters({ ...TopFilters, PerPage:500 })
              }
              dispatch(SetPinderFilter(e.value))
              localStorage.setItem("pinderfilter",e.value)
            }} 
            placeholder="Data Type" 
            color="#ced4da"
          />
        </div>
      </div>
      <div className={`${classes.FilterContainer}`}>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search " style={{ marginTop: "-5px" }} />
            <InputText
              placeholder="Search"
              value={TopFilters.Keyword}
              onChange={(e) =>
                setTopFilters({ ...TopFilters, Keyword: e.target.value })
              }
            />  
          </span>
        </div>
        <div className="w-10rem">
          <CustomMultiSelect
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.ListingType}
            options={ListingTypeSelectItems}
            onChange={(e) =>
              setTopFilters({ ...TopFilters, ListingType: e.value })
            }
            placeholder="Listing Type"
            color="#ced4da"
            maxSelectedLabels={3}
          />
        </div>
        <div className="w-10rem">
          <CustomMultiSelect
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.PostCode}
            options={postcodes}
            onChange={(e) =>
              setTopFilters({ ...TopFilters, PostCode: e.value })
            }
            placeholder="Postcode"
            color="#ced4da" 
            maxSelectedLabels={3}
          />
        </div>
        <div className="w-10rem">
          <CustomMultiSelect
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.State}
            options={statesOfAustralia}
            onChange={(e) => setTopFilters({ ...TopFilters, State: e.value })}
            placeholder="State"
            color="#ced4da"
            maxSelectedLabels={3}
          />
        </div>

        <div className="w-10rem">
          <CustomMultiSelect
            filter
            optionLabel="name"
            display="chip"
            className={classes.filterDropdown}
            value={TopFilters.PropertyType}
            options={propertyOptions}
            onChange={(e) =>
              setTopFilters({ ...TopFilters, PropertyType: e.value })
            }
            placeholder="Property Type"
            color="#ced4da"
            maxSelectedLabels={3}
          />
        </div>
        <div>
        <InputText
            type="number"
              placeholder="Current Page"
              value={TopFilters.Page}
              onChange={(e) =>
                setTopFilters({ ...TopFilters, Page: Number(e.target.value) })
              }
            />
        </div>
        <div className="w-10rem">
          <CustomDropDown
            filter
            showClear={TopFilters.PerPage > 0 ? true : false}
            className={classes.filterDropdown}
            value={TopFilters.PerPage}
            options={PerPageOptions}
            onChange={(e) => setTopFilters({ ...TopFilters, PerPage: e.value })}
            placeholder="Per Page"
            color="#ced4da"
          />
        </div>

        <div>
          <Button
            className={`${classes.ClearFilterButton}`}
            onClick={ClearFilters}
            type="button"
            severity="success"
          >
            <img style={{ width: "15px" }} src={ClearImg} />
          </Button>
        </div>
      </div>
      {LocalItem == "commercial" ? (
        <LiveCommercial TopFilters={TopFilters} setTopFilters={setTopFilters} />
      ) : (
        <LiveResidential
          TopFilters={TopFilters}
          setTopFilters={setTopFilters}
        />
      )}
    </div>
  );
}
