import React, { useEffect, useRef, useState } from "react";
import { Col, Form, FormGroup, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import StrategicSpendingTableColumn from "./StrategicSpendingTableColumn";
import { API } from "../../services/api";
import { GS, publish } from "../../services/global";
import FilterIcon from "../../assets/images/filter.png";
import { useDispatch, useSelector } from "react-redux";
import GradientButton from "../Button/GradientButton";
import "./styleSheets.css";

import ArrowIcon from "../../assets/images/arrowIcon1.png";

import CollapseCard from "../CollapseCard";
import { InputNumber } from "primereact/inputnumber";
import { CurrencyFormatter } from "../../utils";
import { Vendors, setGenericData } from "../../redux/actions";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import FloatingBox from "../FloatingBox";
import classes from "../FloatingBox/FloatingBox.module.css";
import { InputText } from "primereact/inputtext";
import { SetAutoSave } from "../../redux/actions";
function StrategicSpending({ vendorid, setActiveIndex, OnChange, onBack ,VendorData}) {
  const state = useSelector((state) => state.Vendors);
  const lockedProperties = useSelector((state) => state.PropertyLocks);
  const [OverLay, setOverLay] = useState(false);
  const [EquityGains, setEquityGains] = useState([]);
  const [PrincipleData, setPrincipleData] = useState([]);
  const [ShowPrinciples, setShowPrinciples] = useState(false);
  const [ShowIncome,setShowIncome]=useState(false)
  const [ShowLiabilities,setShowLiabilities]=useState(false)
  const [ShowLivingExpenses,setShowLivingExpenses]=useState(false);
  const [TotalExpense,setTotalExpense]=useState(0)
  const[monthlySavings,setmonthlySavings]=useState(0)
  const[OverviewLiabilities,setOverviewLiabilities]=useState(0)
  const CalculatePrinciple = () => {
    let InterestValue = GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyInterestValue;
    let PrincipleValue = GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyPaymentValue;
    let cal = PrincipleValue - InterestValue;
    let Principles = [
      {
        PropertyName: "PPR",
        PrincipleValue: cal,
      },
    ];
 
    GS?.SOT?.Equity?.properties?.map((property) => {
      Principles.push({
        PropertyName: property.PropertyName,
        PrincipleValue:
          property.LoanRepaymentsMonthlyPrincipleInterest -
          property.LoanRepaymentsMonthlyInterest,
      });
      // return null;
    });

    setPrincipleData([...Principles]);
    return Principles;
  };
  let accountType = sessionStorage.getItem("accountType")=="Contacts"? VendorData?.data?.contact_type:sessionStorage.getItem("accountType")    //sessionStorage.getItem("accountType");

  let [CreditCards, setCreditCards] = useState(
    GS?.SOT?.BorrowingCalculator?.CreditCards || []
  );
  const [currentColumn, setCurrentColumn] = useState(0);
  const inputCalculateAnnualMonthlyAmmount = (term, ammount) =>
    term === "Weekly"
      ? (ammount / 52) * 12
      : term === "Annually"
      ? ammount * 12
      : term === "Fortnightly"
      ? (ammount / 26) * 12
      : ammount;
  const calculateAnnualMonthlyAmmount = (term, ammount) =>
    term === "Weekly"
      ? (ammount * 52) / 12
      : term === "Annually"
      ? ammount / 12
      : term === "Fortnightly"
      ? (ammount * 26) / 12
      : ammount || 0.0; 

  const [Editable, setEditable] = useState("");

  const [balanceSheet, setBalanceSheet] = useState(
    GS?.SOT?.StrategicSpending?.balanceSheet || 0.0
  );
  const [FinanceCash, setFinanceCash] = useState(
    GS?.SOT?.Equity?.Cash?.value || 0
  );
  const [cashOnHand, setCashOnHand] = useState(
    GS?.SOT?.StrategicSpending?.cashOnHand || 0.0
  );
  const [accountYour, setAccountYour] = useState(
    GS?.SOT?.StrategicSpending?.accountYour || 0.0
  );
  const [accountPartner, setAccountPartner] = useState(
    GS?.SOT?.StrategicSpending?.accountPartner || 0.0
  );
  const [EstimatedOutgoings,setEstimatedOutgoings]=useState(0)

  const [EquityGain, setEquityGain] = useState(
    GS?.SOT?.Equity?.PPR?.totalEquity || 0.0
  );
  const [ExtraCash, setExtraCash] = useState(
    GS?.SOT?.StrategicSpending?.ExtraCash || []
  );
  const [LiquidAssets, setLiquidAssets] = useState(
    GS?.SOT?.Equity?.LiquidAssets?.value ||0
  );
  const [totalAssets,setTotalAssets]=useState(0)
  const [liabilities, setLiabilities] = useState(
    GS?.SOT?.StrategicSpending?.liabilities || 0.0
  );
  const [creditCardYours, setCreditCardYours] = useState(
    GS?.SOT?.BorrowingCalculator?.CreditCardMinimumPayment || 0.0
  );
  const [creditCardPartner, setCreditCardPartner] = useState(
    GS?.SOT?.BorrowingCalculator?.CreditCardMinimumPayment_Partner || 0.0
  );
  const [monthlyCashflow, setMonthlyCashflow] = useState(
    GS?.SOT?.StrategicSpending?.monthlyCashflow || 0.0
  );

  const [income, setIncome] = useState(GS?.SOT?.StrategicSpending?.income);
  const [netSalaryYour, setNetSalaryYour] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.salary_after_tax_term,
      GS?.SOT?.BorrowingCalculator?.salary_after_tax
    )
  );
  const [netSalaryPartner, setNetSalaryPartner] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax_term,
      GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax
    )
  );
  const [rentalIncome, setRentalIncome] = useState(
    GS?.SOT?.BorrowingCalculator?.NetRentalIncome / 12 || 0.0
  );
  const [OtherIncome, setOtherIncome] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.other_income_net_term,
      GS?.SOT?.BorrowingCalculator?.other_income_net || 0.0
    )
  );
  const [expenses, setExpenses] = useState(
    GS?.SOT?.StrategicSpending?.expenses || 0.0
  );
  const [BillsUtilities, setBillsUtilities] = useState(
    (GS?.SOT?.BorrowingCalculator?.NetRentalIncome * (32.5 / 100)) / 12
  );
  const [Education, setEducation] = useState(
    GS?.SOT?.StrategicSpending?.Education || 0.0
  );
  const [Entertainment, setEntertainment] = useState(
    GS?.SOT?.StrategicSpending?.Entertainment || 0.0
  );
  const [
    HigherEducationandVocationalTraining,
    setHigherEducationandVocationalTraining,
  ] = useState(
    GS?.SOT?.StrategicSpending?.HigherEducationandVocationalTraining || 0.0
  );
  const [Childcare, setChildcare] = useState(
    GS?.SOT?.StrategicSpending?.Childcare || 0.0
  );
  const [PrimaryResidence, setPrimaryResidence] = useState(
    GS?.SOT?.StrategicSpending?.PrimaryResidence || 0.0
  );
  //GS?.SOT?.Equity?.PPR?.HomeLoanRepayments * (25 / 100)
  const [FoodDining, setFoodDining] = useState(
    GS?.SOT?.StrategicSpending?.FoodDining || 0.0
  );
  const [RecreationHolidays, setRecreationHolidays] = useState(
    GS?.SOT?.StrategicSpending?.RecreationHolidays || 0.0
  );
  const [ScrollPosition, setScrollPosition] = useState(0);
  const [ScrollPosition2, setScrollPosition2] = useState(0);

  const [GeneralInsurance, setGeneralInsurance] = useState(
    GS?.SOT?.StrategicSpending?.GeneralInsurance || 0.0
  );
  const [LoansCreditCards, setLoansCreditCards] = useState(
    GS?.SOT?.StrategicSpending?.LoansCreditCards || 0.0
  );
  const [RentMortgageProperty, setRentMortgageProperty] = useState(0.0);
  const [Shopping, setShopping] = useState(
    GS?.SOT?.StrategicSpending?.Shopping || 0.0
  );
  
  const [CarLoan, setCarLoan] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.car_loan_payment_2_term,
      GS?.SOT?.BorrowingCalculator?.car_loan_payment_2
    ) || 0.0
  );
  const [HECSPayments, setHECSPayments] = useState(
    GS?.SOT?.BorrowingCalculator?.HECSPayments?.balance
  );
  const getotherexpenses = () => {
    // if (GS?.SOT?.BorrowingCalculator?.other_payments_2_term === "Annually") {
    //   return isNaN(GS?.SOT?.StrategicSpending?.OtherExpenses / 12)
    //     ? 0
    //     : GS?.SOT?.StrategicSpending?.OtherExpenses / 12;
    // } else if (
    //   GS?.SOT?.BorrowingCalculator?.other_payments_2_term === "Monthly"
    // ) {
    //   return isNaN(GS?.SOT?.StrategicSpending?.OtherExpenses)
    //     ? 0
    //     : GS?.SOT?.StrategicSpending?.OtherExpenses;
    // } else if (
    //   GS?.SOT?.BorrowingCalculator?.other_payments_2_term === "Weekly"
    // ) {
    //   return isNaN((GS?.SOT?.StrategicSpending?.OtherExpenses * 52) / 12)
    //     ? 0
    //     : (GS?.SOT?.StrategicSpending?.OtherExpenses * 52) / 12;
    // } else {
    //   return isNaN((GS?.SOT?.StrategicSpending?.OtherExpenses * 26) / 12)
    //     ? 0
    //     : (GS?.SOT?.StrategicSpending?.OtherExpenses * 26) / 12;
    // }
    return GS?.SOT?.StrategicSpending?.Other
  };
  // const [OtherExpenses, setOtherExpenses] = useState(getotherexpenses());

  let [uaaae, setUaaae] = useState(
    GS?.SOT?.BorrowingCalculator?.averageAustralianAnnual || false
  );

  // const [CreditCardPayments, setCreditCardPayments] = useState(
  //   GS?.SOT?.BorrowingCalculator?.credit_card_current_debt / 12 || 0.0
  // );

  const [PersonalExpenses, setPersonalExpenses] = useState(
    GS?.SOT?.StrategicSpending?.PersonalExpenses || 0.0
  );
  const [Utilities, setUtilities] = useState(
    GS?.SOT?.StrategicSpending?.Utilities || 0.0
  );
  const [InvestmentExpenses, setInvestmentExpenses] = useState(
    GS?.SOT?.StrategicSpending?.InvestmentExpenses || 0.0
  );




  const [AlcoholicBeverages,setAlcoholicBeverages]=useState(GS?.SOT?.StrategicSpending?.AlcoholicBeverages || 0.0)
  const [ClothingAndFootwear,setClothingAndFootwear]=useState(GS?.SOT?.StrategicSpending?.ClothingAndFootwear || 0.0)
  const [Communication,setCommunication]=useState(GS?.SOT?.StrategicSpending?.Communication || 0.0)
  const [CurrentHousingCosts,setCurrentHousingCosts]=useState(GS?.SOT?.StrategicSpending?.CurrentHousingCosts || 0.0)
  const [DomesticFuelAndPower,setDomesticFuelAndPower]=useState(GS?.SOT?.StrategicSpending?.DomesticFuelAndPower || 0.0)
  const [FoodAndNonAlcoholicBeverages,setFoodAndNonAlcoholicBeverages]=useState(GS?.SOT?.StrategicSpending?.FoodAndNonAlcoholicBeverages || 0.0)
  const [HouseholdFurnishingsAndEquipment,setHouseholdFurnishingsAndEquipment]=useState(GS?.SOT?.StrategicSpending?.HouseholdFurnishingsAndEquipment || 0.0)
  const [HouseholdServicesAndOperation,setHouseholdServicesAndOperation]=useState(GS?.SOT?.StrategicSpending?.HouseholdServicesAndOperation || 0.0)
  const [HealthFitness, setHealthFitness] = useState(
    GS?.SOT?.StrategicSpending?.HealthFitness || 0.0
  );
  const [PersonalCare,setPersonalCare]=useState(GS?.SOT?.StrategicSpending?.PersonalCare || 0.0)
  const [Recreation,setRecreation]=useState(GS?.SOT?.StrategicSpending?.Recreation || 0.0)
  
  const [Travel, setTravel] = useState(
    GS?.SOT?.StrategicSpending?.Travel || 0.0
  );
  const [AccountantFees,setAccountantFees]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[0]?.AccountantFees || 0.0)
  const [BodyCorporatePayments,setBodyCorporatePayments]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[1]?.BodyCorporatePayments || 0.0)
  const [CashGiftsAndDonations,setCashGiftsAndDonations]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[2]?.CashGiftsAndDonations || 0.0)
  const [FeesNecGovernment,setFeesNecGovernment]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[3]?.FeesNecGovernment || 0.0)
  const [FeesNecPrivate,setFeesNecPrivate]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[4]?.FeesNecPrivate || 0.0)
  const [FinancialInstitutionCharges,setFinancialInstitutionCharges]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[5]?.FinancialInstitutionCharges || 0.0)
  const [HireServicesNec,setHireServicesNec]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[6]?.HireServicesNec || 0.0)
  const [InsuranceNec,setInsuranceNec]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[7]?.InsuranceNec|| 0.0)
  const [MiscellaneousGoodsNec,setMiscellaneousGoodsNec]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[8]?.MiscellaneousGoodsNec || 0.0)
  const [MiscellaneousGoodsNfd,setMiscellaneousGoodsNfd]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[9]?.MiscellaneousGoodsNfd || 0.0)
  const [MiscellaneousServicesNec,setMiscellaneousServicesNec]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[10]?.MiscellaneousServicesNec || 0.0)
  const [NonHolidayAccommodation,setNonHolidayAccommodation]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[11]?.NonHolidayAccommodation || 0.0)
  const [StationeryEquipmentnfd,setStationeryEquipmentnfd]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[12]?.StationeryEquipmentnfd || 0.0)
  const [TravelGoods,setTravelGoods]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[13]?.TravelGoods || 0.0)
  const [UnionDues,setUnionDues]=useState(GS?.SOT?.StrategicSpending?.GoodsAndServices?.summary[14]?.UnionDues || 0.0)
  const [OtherExpenses,setOtherExpenses]=useState(GS?.SOT?.StrategicSpending?.OtherExpenses || 0.0)
  const [cashWithdrawals,setcashWithdrawals]=useState(GS?.SOT?.StrategicSpending?.cashWithdrawals || 0.0)
  const [BankFees,setBankFees]=useState(GS?.SOT?.StrategicSpending?.Recreation || 0.0)
  const [loanInterests,setloanInterests]=useState(GS?.SOT?.StrategicSpending?.loanInterests || 0.0)
  const [externalTransfers,setexternalTransfers]=useState(GS?.SOT?.StrategicSpending?.Recreation || 0.0)










  const [endDate,setendData]=useState()
  const [Data, setData] = useState([]);
  const [Data2, setData2] = useState([]);
  const[lockedProp,setlockedProp]=useState(false)
  const [FilterBy, setFilterBy] = useState("");

  const TableRef = useRef();

  // useEffect(() => {
  //   let v = 0;
  // if (EquityGains.length > 0) {
  //   EquityGains.map((item, index) => {
  //     let v1 = item.value / item.noOfMonths;

  //     v = v + v1;
  //     return null;
  //   });
  // }
  // let p1 = 0;
  // if (PrincipleData.length > 0) {
  //   PrincipleData.map((item, index) => {
  //     let p2 = item.PrincipleValue;

  //     p1 = p1 + p2;
  //     return null;
  //   });
  // }
  // let value = v + p1 + EquityGain || 0.0;

  // setBalanceSheet(value);
  // }, [cashOnHand, liabilities, EquityGain, EquityGains, PrincipleData]);

  let CashColumns = [
    {
      title: "Cash-on hand",
      classes: "sidebar-cell cashOnHand-primary",
      value: cashOnHand,
    },
    { 
      title: "Account (yours)",
      classes: "sidebar-cell cashOnHand-secondary",
      value: accountYour,
    },
    {
      title: "Account (partners)",
      classes: "sidebar-cell cashOnHand-secondary",
      value: accountPartner,
    },
    {
      title: "Extra Cash",
      classes: "sidebar-cell cashOnHand-secondary",
      value: ExtraCash,
    },
    {
      title:"Liquid Assets",
      classes:"sidebar-cell cashOnHand-secondary",
      value:LiquidAssets
    }
  ];

  let PrincipleColumns = [];

  PrincipleData?.map((principle) => {
    PrincipleColumns.push({
      title: `${principle.PropertyName} - Principal`,
      classes: "sidebar-cell cashOnHand-secondary",
    });
    return null;
  });

  let LiabilitiesColumns = [
    {
      title: "Liabilities",
      classes: "sidebar-cell liabilities-primary",
      value: liabilities,
    },
  ];

  EquityGains.map((item) => {
    CashColumns.push({
      title: `${item?.PropertyName} - Capital Growth Equity`,
      classes: "sidebar-cell cashOnHand-secondary",
      value: item.value,
    });
    return null;
  });

  CashColumns.push({
    title: "Equity Gain",
    classes: "sidebar-cell cashOnHand-secondary",
  });
  CreditCards.map((cardItem) => {
    LiabilitiesColumns.push({
      title: `${cardItem?.type} (${cardItem?.name}) (${cardItem?.owner})`,
      classes: "sidebar-cell cashOnHand-secondary",
      value: cardItem?.minimumPayment,
    });
    return null;
  });

  let MonthlyCashFlowColumns = [
    { title: "MONTHLY SAVINGS", classes: "sidebar-cell monthlyCashFlow" },
  ];
  let PPRLoanColumns = [
    { title: "PPR Monthly Mortgage", classes: "sidebar-cell income-secondary" },
  ];
  let IncomeColums = [
    { title: "Income", classes: "sidebar-cell income-primary", value: income },
    {
      title: "Net Salary (Yours)",
      classes: "sidebar-cell income-secondary",
      value: netSalaryYour,
    },
    {
      title: "Net Salary (Partners)",
      classes: "sidebar-cell income-secondary",
      value: netSalaryPartner,
    },
    {
      title: "Rental Income",
      classes: "sidebar-cell income-secondary",
      value: rentalIncome,
    },
    {
      title: "Other Income",
      classes: "sidebar-cell income-secondary",
      value: OtherIncome,
    },
  ];

  let ExpensesColumns = [
    {
      title: "Expenses",
      classes: "sidebar-cell expense-primary",
      value: expenses,
    },
    { 
      title: "Alcoholic beverages",
      classes: "sidebar-cell expense-secondary",
      value: AlcoholicBeverages,
    },
    { 
      title: "Clothing and footwear",
      classes: "sidebar-cell expense-secondary",
      value: ClothingAndFootwear,
    },
    { 
      title: "Communication",
      classes: "sidebar-cell expense-secondary",
      value: Communication,
    },
    { 
      title: "Current housing costs",
      classes: "sidebar-cell expense-secondary",
      value: CurrentHousingCosts,
    },
    { 
      title: "Domestic Fuel & Power",
      classes: "sidebar-cell expense-secondary",
      value: DomesticFuelAndPower,
    },
    { 
      title: "Education",
      classes: "sidebar-cell expense-secondary",
      value: Education,
    },
    { 
      title: "Food & Non-Alcoholic Beverages",
      classes: "sidebar-cell expense-secondary",
      value: FoodAndNonAlcoholicBeverages,
    },
    { 
      title: "Household Furnishings & Equipment",
      classes: "sidebar-cell expense-secondary",
      value: HouseholdFurnishingsAndEquipment,
    },
    { 
      title: "Household Services & Operation",
      classes: "sidebar-cell expense-secondary",
      value: HouseholdServicesAndOperation,
    },
    { 
      title: "Medical Care & Health Expenses",
      classes: "sidebar-cell expense-secondary",
      value: HealthFitness,
    },
    { 
      title: "Personal Care",
      classes: "sidebar-cell expense-secondary",
      value: PersonalCare,
    },
    { 
      title: "Recreation",
      classes: "sidebar-cell expense-secondary",
      value: Recreation,
    },
    { 
      title: "Transport",
      classes: "sidebar-cell expense-secondary",
      value: Travel,
    },
    { 
      title: "Accountant Fees",
      classes: "sidebar-cell expense-secondary",
      value: AccountantFees,
    },
    { 
      title: "Body Corporate Payments",
      classes: "sidebar-cell expense-secondary",
      value: DomesticFuelAndPower,
    },
    { 
      title: "Cash Gifts, Donations To Charity Nec",
      classes: "sidebar-cell expense-secondary",
      value: CashGiftsAndDonations,
    },
    { 
      title: "Fees Nec - Government",
      classes: "sidebar-cell expense-secondary",
      value: FeesNecGovernment,
    },
    { 
      title: "Fees Nec - Private",
      classes: "sidebar-cell expense-secondary",
      value: FeesNecPrivate,
    },
    { 
      title: "Financial Institution Charges",
      classes: "sidebar-cell expense-secondary",
      value: FinancialInstitutionCharges,
    },
    { 
      title: "Hire Services Nec",
      classes: "sidebar-cell expense-secondary",
      value: HireServicesNec,
    },
    { 
      title: "Insurance Nec",
      classes: "sidebar-cell expense-secondary",
      value: InsuranceNec,
    },
    { 
      title: "Miscellaneous Goods Nec",
      classes: "sidebar-cell expense-secondary",
      value: MiscellaneousGoodsNec,
    },
    { 
      title: "Miscellaneous Goods Nfd",
      classes: "sidebar-cell expense-secondary",
      value: MiscellaneousGoodsNfd,
    },
    { 
      title: "Miscellaneous Services Nec",
      classes: "sidebar-cell expense-secondary",
      value: MiscellaneousServicesNec,
    },
    { 
      title: "Non-Holiday Accommodation",
      classes: "sidebar-cell expense-secondary",
      value: NonHolidayAccommodation,
    },
    { 
      title: "Stationery Equipment Nfd",
      classes: "sidebar-cell expense-secondary",
      value: StationeryEquipmentnfd,
    },
    { 
      title: "Travel goods, handbags & related",
      classes: "sidebar-cell expense-secondary",
      value: TravelGoods,
    },
    { 
      title: "Cash Withdrawls",
      classes: "sidebar-cell expense-secondary",
      value: cashWithdrawals,
    },
    { 
      title: "Bank Fees",
      classes: "sidebar-cell expense-secondary",
      value: BankFees,
    },
    { 
      title: "Union dues, professional association subscriptions",
      classes: "sidebar-cell expense-secondary",
      value: UnionDues,
    },
    { 
      title: "Loan Interest",
      classes: "sidebar-cell expense-secondary",
      value: loanInterests,
    },
    { 
      title: "External Transfers",
      classes: "sidebar-cell expense-secondary",
      value: externalTransfers,
    },
    {
      title: "Other",
      classes: "sidebar-cell expense-secondary",
      value: OtherExpenses,
    },
  ];

  let AsutralianAverageExpensesColumn = [
    {
      title: "Expenses",
      classes: "sidebar-cell expense-primary",
      value: expenses,
    },

    {
      title: "Other",
      classes: "sidebar-cell expense-secondary",
      value: OtherExpenses,
    },
  ];

  let BalanceSheetColumns = [
    { title: "Balance Sheet", classes: "sidebar-cell balanceSheet" },
  ];

  let handleLowerScroll = (e) => {
    setScrollPosition(e.target.scrollLeft);
  };

  let nextBtnNavigation = () => {
    TableRef.current.scrollLeft += 140;
    setScrollPosition(TableRef.current.scrollLeft);
    setCurrentColumn((prevColumn) => (prevColumn >= 84 ? 84 : prevColumn + 1));
  };

  let prevBtnNavigation = () => {
    TableRef.current.scrollLeft -= 140;
    setScrollPosition(TableRef.current.scrollLeft); 
    setCurrentColumn((prevColumn) => (prevColumn <= 0 ? 0 : prevColumn - 1));
  };
  const checkIsFinite = (number) => {
    if (!Number.isFinite(number)) {
      return 0;
    } else {
      return number;
    }
  };
  let generateData = async () => {
    let userId = "610a0f1dc61edfcf62d62c52";
    let res = await API.get(`settings?office_id=${userId}`);
    let CPI = res.data[0].settings.CPI;
    // let principals=CalculatePrinciple();

    let Today = moment();
    let NextMonth = moment();
    NextMonth.add(1, "month");
    let propertyPurchase=[]
    let no_of_months = 7 * 12;
    const months = [];
    const dateStart = moment();
    const dateEnd = moment().add(no_of_months, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push(dateStart.format("MMM-YYYY"));
      dateStart.add(1, "month");
    }
    let prevLiquidAsset=0
    let prevCashOnHand = 0;
    let prevExpenses = 0;
    let prevBillsUtilities = 0;
    let prevEducation = 0;
    let prevEntertainment = 0;
    let prevHigherEducationandVocationalTraining = 0;
    let prevChildcare = 0;
    let prevPrimaryResidence = 0;
    let prevFoodDining = 0;
    let prevRecreationHolidays = 0;
   
    let prevGeneralInsurance = 0;
    let prevLoansCreditCards = 0;
    let prevRentMortgageProperty = 0;
    let prevShopping = 0;
    let prevCarLoan = 0;

   
    
    let prevAlcoholicBeverages=0;
    let prevClothingAndFootwear=0;
    let prevCommunication=0;
    let prevCurrentHousingCosts=0;
    let prevDomesticFuelAndPower=0;
    let prevFoodAndNonAlcoholicBeverages=0;
    let prevHouseholdFurnishingsAndEquipment=0;
    let prevHouseholdServicesAndOperation=0;
    let prevHealthFitness = 0;
    let prevPersonalCare=0;
    let prevRecreation=0;
    let prevTravel = 0;
    let prevAccountantFees=0;
    let prevBodyCorporatePayments=0;
    let prevCashGiftsAndDonations=0;
    let prevFeesNecGovernment=0;
    let prevFeesNecPrivate=0;
    let prevFinancialInstitutionCharges=0;
    let prevHireServicesNec=0;
    let prevInsuranceNec=0;
    let prevMiscellaneousGoodsNec=0;
    let prevMiscellaneousGoodsNfd=0;
    let prevMiscellaneousServicesNec=0;
    let prevNonHolidayAccommodation=0;
    let prevStationeryEquipmentnfd=0;
    let prevTravelGoods=0;
    let prevUnionDues=0;
    let prevOtherExpenses = 0;
    let prevcashWithdrawals=0;
    let prevBankFees=0;
    let prevloanInterests=0;
    let prevexternalTransfers=0
    let prevExtraCash=0











    let prevHECSPayments = 0;
    let prevYear = Today.format("YYYY");
    let prevPersonalExpenses = 0;
    let prevEquityGain = 0;
    let prevCashOnHand2=0;
    let prevTotal=0
    let prevEquityGain2=0;
    let v = 0;
    if (EquityGains.length > 0) {
      EquityGains.map((item, index) => {
        let v1 = item.value / item.noOfMonths;
        v = v + v1;
        return null;
      });
    }
 
    let p1 = 0;

    if (PrincipleData.length > 0) {
      PrincipleData.map((item, index) => {
        let p2 = item.PrincipleValue;
        p1 = p1 + p2;
        return null;
      });
    }
    let value = v + p1 + EquityGain || 0.0;
    let cash=cashOnHand
    let equityGain=EquityGain 
    let cashextra=ExtraCash
    let data = months?.map((date) => {
      let currentDatePurchase;
      if (lockedProperties.PropertyGenerators && lockedProperties.PropertyGenerators.length > 0) {
       let propertiesPurchased = lockedProperties.PropertyGenerators.filter((i) => i.nextDate === date && i.isLocked===true);
     
       if(propertiesPurchased.length>0){
         currentDatePurchase= propertiesPurchased.reduce((acc,curr)=>{
          acc['price']= acc.price+ curr.summaryData.TotalDepositRequired;
          acc['depositCash'] = acc.depositCash + curr.summaryData.TotalDepositCash;
          acc['depositEquity']=acc.depositEquity+curr.summaryData.TotalDepositEquity;
          acc['extraCash']=acc.extraCash+curr.extraCash
          acc['useExtraCash']=curr.useExtraCash
          acc['useLiquidAssets']=curr.useLiquidAssets
          acc['liquidAssets']=acc.liquidAssets+curr.liquidAssets
          acc['date']=new Date(curr.nextDate)
          return acc
        },{price:0,depositCash:0,depositEquity:0,extraCash:0,useLiquidAssets:'N',useExtraCash:'N',liquidAssets:0,date})
      }
    }

      const d = new Date(date);
      let extracashvalue=0
      if(currentDatePurchase){
        extracashvalue=currentDatePurchase.extraCash
        cashextra=[...cashextra,{date:date,value:extracashvalue}]
        // setExtraCash((prevState) => [...prevState, { date: date, value: extracashvalue }]);
      }else{
        extracashvalue=0
      }
   
   
    // let extracashvalue=date === Today.format("MMM-YYYY")  
    // ? 20 //first month
    // : date === NextMonth.format("MMM-YYYY")
    // ? 20
    // :20;
   
  
      let cashOnHandValue =
        date === Today.format("MMM-YYYY")
          ? FinanceCash //first month
          : date === NextMonth.format("MMM-YYYY")
          ? cash
          : prevCashOnHand +
            (accountYour - accountYour + (accountPartner - accountPartner)) +
            monthlyCashflow;
            // if(currentDatePurchase){
            //   if(date === Today.format("MMM-YYYY")){
            //     if(String(currentDatePurchase.useExtraCash)=='Y'){
            //       cashOnHandValue=cashOnHandValue+currentDatePurchase.extraCash
            //     }
             
            //     if(String(currentDatePurchase.useLiquidAssets)=='Y'){
            //       cashOnHandValue=cashOnHandValue+currentDatePurchase.liquidAssets
            //     }
            //   }
            // }
            // console.log(currentDatePurchase,console.log(currentDatePurchase))
// console.log(cashOnHandValue,"cashOnHandValue")
        // let balanceSheetValue = date === Today.format("MMM-YYYY") ? equityGain : value;  old balance sheet value         
      let overallprincipleValue=PrincipleData.reduce((total,item)=>{
        return total+item.PrincipleValue
      },0)
   
      // console.log(overallprincipleValue,"overallprincipalvalue",prevEquityGain,"prevEquityGain",PrincipleData)
        let EquityGainValue =
        date === Today.format("MMM-YYYY")
          ? equityGain
          : date === NextMonth.format("MMM-YYYY")
          ? equityGain +
          overallprincipleValue
          : prevEquityGain +
          overallprincipleValue;
          // console.log("before",equityGain,"EquityGain","currentDatePurchase",currentDatePurchase,"EquityGainValue",EquityGainValue)
            if (currentDatePurchase) {
              // console.log(currentDatePurchase,"currentDatePurchase")
              if(date === Today.format("MMM-YYYY")){
                // console.log("before",equityGain,"EquityGain","currentDatePurchase",currentDatePurchase,"EquityGainValue",EquityGainValue)
                // equityGain=equityGain-currentDatePurchase.depositEquity
                // EquityGainValue=EquityGainValue-currentDatePurchase.depositEquity
                // console.log("after",equityGain,"EquityGain","currentDatePurchase",currentDatePurchase,"EquityGainValue",EquityGainValue)
               
              }
             else{
                // EquityGainValue=EquityGainValue-currentDatePurchase.depositEquity
              } 
              // console.log(currentDatePurchase,"currentDatePurchaseNot")        
    

              // cashOnHandValue = cashOnHandValue - currentDatePurchase.depositCash;
              // cash=cash-currentDatePurchase.depositCash
            }
            let liquidassetValue= 
            date === Today.format("MMM-YYYY")
            ?0
            :d.getFullYear() !== prevYear
            ?0:0   
      let expensesValue =
        date === Today.format("MMM-YYYY")
          ? expenses
          : d.getFullYear() !== prevYear
          ? prevExpenses + (CPI / 100) * expenses
          : prevExpenses;

      let BillsUtilitiesValue =
        date === Today.format("MMM-YYYY")
          ? BillsUtilities
          : d.getFullYear() !== prevYear
          ? prevBillsUtilities + (CPI / 100) * BillsUtilities
          : prevBillsUtilities;

     

      let EntertainmentValue =
        date === Today.format("MMM-YYYY")
          ? Entertainment
          : d.getFullYear() !== prevYear
          ? prevEntertainment + (CPI / 100) * Entertainment
          : prevEntertainment;

      let HigherEducationandVocationalTrainingValue =
        date === Today.format("MMM-YYYY")
          ? HigherEducationandVocationalTraining
          : d.getFullYear() !== prevYear
          ? prevHigherEducationandVocationalTraining +
            (CPI / 100) * HigherEducationandVocationalTraining
          : prevHigherEducationandVocationalTraining;

      let ChildcareValue =
        date === Today.format("MMM-YYYY")
          ? Childcare
          : d.getFullYear() !== prevYear
          ? prevChildcare + (CPI / 100) * Childcare
          : prevChildcare;

      let PrimaryResidenceValue =
        date === Today.format("MMM-YYYY")
          ? PrimaryResidence
          : d.getFullYear() !== prevYear
          ? prevPrimaryResidence + (CPI / 100) * PrimaryResidence
          : prevPrimaryResidence;

      let FoodDiningValue =
        date === Today.format("MMM-YYYY")
          ? FoodDining
          : d.getFullYear() !== prevYear
          ? prevFoodDining + (CPI / 100) * FoodDining
          : prevFoodDining;

      let RecreationHolidaysValue =
        date === Today.format("MMM-YYYY")
          ? RecreationHolidays
          : d.getFullYear() !== prevYear
          ? prevRecreationHolidays + (CPI / 100) * RecreationHolidays
          : prevRecreationHolidays;

      let HealthFitnessValue =
        date === Today.format("MMM-YYYY")
          ? HealthFitness
          : d.getFullYear() !== prevYear
          ? prevHealthFitness + (CPI / 100) * HealthFitness
          : prevHealthFitness;

      let GeneralInsuranceValue =
        date === Today.format("MMM-YYYY")
          ? GeneralInsurance
          : d.getFullYear() !== prevYear
          ? prevGeneralInsurance + (CPI / 100) * GeneralInsurance
          : prevGeneralInsurance;

      let LoansCreditCardsValue =
        date === Today.format("MMM-YYYY")
          ? LoansCreditCards
          : d.getFullYear() !== prevYear
          ? prevLoansCreditCards + (CPI / 100) * LoansCreditCards
          : prevLoansCreditCards;

      let RentMortgagePropertyValue =
        date === Today.format("MMM-YYYY")
          ? RentMortgageProperty
          : d.getFullYear() !== prevYear
          ? prevRentMortgageProperty + (CPI / 100) * RentMortgageProperty
          : prevRentMortgageProperty;

      let ShoppingValue =
        date === Today.format("MMM-YYYY")
          ? Shopping
          : d.getFullYear() !== prevYear
          ? prevShopping + (CPI / 100) * Shopping
          : prevShopping;

      let TravelValue =
        date === Today.format("MMM-YYYY")
          ? Travel
          : d.getFullYear() !== prevYear
          ? prevTravel + (CPI / 100) * Travel
          : prevTravel;

      let HECSPaymentsValue =
        date === Today.format("MMM-YYYY")
          ? HECSPayments
          : d.getFullYear() !== prevYear
          ? prevHECSPayments + (CPI / 100) * HECSPayments
          : prevHECSPayments;

      let CarLoanValue =
        d.getFullYear() === Today.format("YYYY")
          ? CarLoan
          : d.getFullYear() !== prevYear
          ? prevCarLoan + (CPI / 100) * CarLoan
          : prevCarLoan;

      let OtherExpensesValue =
        date === Today.format("MMM-YYYY")
          ? OtherExpenses
          : d.getFullYear() !== prevYear
          ? prevOtherExpenses + (CPI / 100) * OtherExpenses
          : prevOtherExpenses;

      let PersonalExpensesValue =
        date === Today.format("MMM-YYYY")
          ? PersonalExpenses
          : d.getFullYear() !== prevYear
          ? prevPersonalExpenses + (CPI / 100) * PersonalExpenses
          : prevPersonalExpenses;

      let EducationValue =
          date === Today.format("MMM-YYYY")
            ? Education
            : d.getFullYear() !== prevYear
            ? prevEducation + (CPI / 100) * Education
            : prevEducation;
      
      let AlcoholicBeveragesValue=
      date === Today.format("MMM-YYYY")
      ? AlcoholicBeverages
      : d.getFullYear() !== prevYear
      ? prevAlcoholicBeverages + (CPI / 100) * AlcoholicBeverages
      : prevAlcoholicBeverages;

      let ClothingAndFootwearValue=
      date === Today.format("MMM-YYYY")
      ? ClothingAndFootwear
      : d.getFullYear() !== prevYear
      ? prevClothingAndFootwear + (CPI / 100) * ClothingAndFootwear
      : prevClothingAndFootwear;

      let CommunicationValue=
      date === Today.format("MMM-YYYY")
      ? Communication
      : d.getFullYear() !== prevYear
      ? prevCommunication + (CPI / 100) * Communication
      : prevCommunication;

      let CurrentHousingCostsValue=
      date === Today.format("MMM-YYYY")
      ? CurrentHousingCosts
      : d.getFullYear() !== prevYear
      ? prevCurrentHousingCosts + (CPI / 100) * CurrentHousingCosts
      : prevCurrentHousingCosts;

      let DomesticFuelAndPowerValue=
      date === Today.format("MMM-YYYY")
      ? DomesticFuelAndPower
      : d.getFullYear() !== prevYear
      ? prevDomesticFuelAndPower + (CPI / 100) * DomesticFuelAndPower
      : prevDomesticFuelAndPower;

      let FoodAndNonAlcoholicBeveragesValue=
      date === Today.format("MMM-YYYY")
      ? FoodAndNonAlcoholicBeverages
      : d.getFullYear() !== prevYear
      ? prevFoodAndNonAlcoholicBeverages + (CPI / 100) * FoodAndNonAlcoholicBeverages
      : prevFoodAndNonAlcoholicBeverages;

      let HouseholdFurnishingsAndEquipmentValue=
      date === Today.format("MMM-YYYY")
      ? HouseholdFurnishingsAndEquipment
      : d.getFullYear() !== prevYear
      ? prevHouseholdFurnishingsAndEquipment + (CPI / 100) * HouseholdFurnishingsAndEquipment
      : prevHouseholdFurnishingsAndEquipment;

      let HouseholdServicesAndOperationValue=
      date === Today.format("MMM-YYYY")
      ? HouseholdServicesAndOperation
      : d.getFullYear() !== prevYear
      ? prevHouseholdServicesAndOperation + (CPI / 100) * HouseholdServicesAndOperation
      : prevHouseholdServicesAndOperation;

      let PersonalCareValue=
      date === Today.format("MMM-YYYY")
      ? PersonalCare
      : d.getFullYear() !== prevYear
      ? prevPersonalCare + (CPI / 100) * PersonalCare
      : prevPersonalCare;

      let RecreationValue=
      date === Today.format("MMM-YYYY")
      ? Recreation
      : d.getFullYear() !== prevYear
      ? prevRecreation + (CPI / 100) * Recreation
      : prevRecreation;

      let AccountantFeesValue=
      date === Today.format("MMM-YYYY")
      ? AccountantFees
      : d.getFullYear() !== prevYear
      ? prevAccountantFees + (CPI / 100) * AccountantFees
      : prevAccountantFees;

      let BodyCorporatePaymentsValue=
      date === Today.format("MMM-YYYY")
      ? BodyCorporatePayments
      : d.getFullYear() !== prevYear
      ? prevBodyCorporatePayments + (CPI / 100) * BodyCorporatePayments
      : prevBodyCorporatePayments;

      let CashGiftsAndDonationsValue=
      date === Today.format("MMM-YYYY")
      ? CashGiftsAndDonations
      : d.getFullYear() !== prevYear
      ? prevCashGiftsAndDonations + (CPI / 100) * CashGiftsAndDonations
      : prevCashGiftsAndDonations;

      let FeesNecGovernmentValue=
      date === Today.format("MMM-YYYY")
      ? FeesNecGovernment
      : d.getFullYear() !== prevYear
      ? prevFeesNecGovernment + (CPI / 100) * FeesNecGovernment
      : prevFeesNecGovernment;

      let FeesNecPrivateValue=
      date === Today.format("MMM-YYYY")
      ? FeesNecPrivate
      : d.getFullYear() !== prevYear
      ? prevFeesNecPrivate + (CPI / 100) * FeesNecPrivate
      : prevFeesNecPrivate;

      let FinancialInstitutionChargesValue=
      date === Today.format("MMM-YYYY")
      ? FinancialInstitutionCharges
      : d.getFullYear() !== prevYear
      ? prevFinancialInstitutionCharges + (CPI / 100) * FinancialInstitutionCharges
      : prevFinancialInstitutionCharges;

      let HireServicesNecValue=
      date === Today.format("MMM-YYYY")
      ? HireServicesNec
      : d.getFullYear() !== prevYear
      ? prevHireServicesNec + (CPI / 100) * HireServicesNec
      : prevHireServicesNec;

      let InsuranceNecValue=
      date === Today.format("MMM-YYYY")
      ? InsuranceNec
      : d.getFullYear() !== prevYear
      ? prevInsuranceNec + (CPI / 100) * InsuranceNec
      : prevInsuranceNec;

      let MiscellaneousGoodsNecValue=
      date === Today.format("MMM-YYYY")
      ? MiscellaneousGoodsNec
      : d.getFullYear() !== prevYear
      ? prevMiscellaneousGoodsNec + (CPI / 100) * MiscellaneousGoodsNec
      : prevMiscellaneousGoodsNec;

      let MiscellaneousGoodsNfdValue=
      date === Today.format("MMM-YYYY")
      ? MiscellaneousGoodsNfd
      : d.getFullYear() !== prevYear
      ? prevMiscellaneousGoodsNfd + (CPI / 100) * MiscellaneousGoodsNfd
      : prevMiscellaneousGoodsNfd;

      let MiscellaneousServicesNecValue=
      date === Today.format("MMM-YYYY")
      ? MiscellaneousServicesNec
      : d.getFullYear() !== prevYear
      ? prevMiscellaneousServicesNec + (CPI / 100) * MiscellaneousServicesNec
      : prevMiscellaneousServicesNec;

      let NonHolidayAccommodationValue=
      date === Today.format("MMM-YYYY")
      ? NonHolidayAccommodation
      : d.getFullYear() !== prevYear
      ? prevNonHolidayAccommodation + (CPI / 100) * NonHolidayAccommodation
      : prevNonHolidayAccommodation;

      let StationeryEquipmentnfdValue=
      date === Today.format("MMM-YYYY")
      ? StationeryEquipmentnfd
      : d.getFullYear() !== prevYear
      ? prevStationeryEquipmentnfd + (CPI / 100) * StationeryEquipmentnfd
      : prevStationeryEquipmentnfd;

      let TravelGoodsValue=
      date === Today.format("MMM-YYYY")
      ? TravelGoods
      : d.getFullYear() !== prevYear
      ? prevTravelGoods + (CPI / 100) * TravelGoods
      : prevTravelGoods;

      let UnionDuesValue=
      date === Today.format("MMM-YYYY")
      ? UnionDues
      : d.getFullYear() !== prevYear
      ? prevUnionDues + (CPI / 100) * UnionDues
      : prevUnionDues;

      let cashWithdrawalsValue=
      date === Today.format("MMM-YYYY")
      ? cashWithdrawals
      : d.getFullYear() !== prevYear
      ? prevcashWithdrawals + (CPI / 100) * cashWithdrawals
      : prevcashWithdrawals;

      let BankFeesValue=
      date === Today.format("MMM-YYYY")
      ? BankFees
      : d.getFullYear() !== prevYear
      ? prevBankFees + (CPI / 100) * BankFees
      : prevBankFees;

      let loanInterestsValue=
      date === Today.format("MMM-YYYY")
      ? loanInterests
      : d.getFullYear() !== prevYear
      ? prevloanInterests + (CPI / 100) * loanInterests
      : prevloanInterests;

      let externalTransfersValue=
      date === Today.format("MMM-YYYY")
      ? externalTransfers
      : d.getFullYear() !== prevYear
      ? prevexternalTransfers + (CPI / 100) * externalTransfers
      : prevexternalTransfers;
    
      prevLiquidAsset=liquidassetValue
      prevCashOnHand = cashOnHandValue;
      prevPersonalExpenses = PersonalExpensesValue;
      prevExpenses = expensesValue;
      prevBillsUtilities = BillsUtilitiesValue;
   
      prevEntertainment = EntertainmentValue;
      prevHigherEducationandVocationalTraining =
        HigherEducationandVocationalTrainingValue;
      prevChildcare = ChildcareValue;
      prevPrimaryResidence = PrimaryResidenceValue;
      prevFoodDining = FoodDiningValue;
      prevRecreationHolidays = RecreationHolidaysValue;
      prevHealthFitness = HealthFitnessValue;
      prevGeneralInsurance = GeneralInsuranceValue;
      prevLoansCreditCards = LoansCreditCardsValue;
      prevRentMortgageProperty = RentMortgagePropertyValue;
      prevShopping = ShoppingValue;
      prevTravel = TravelValue;
      prevEducation = EducationValue;
      prevAlcoholicBeverages=AlcoholicBeveragesValue;
      prevClothingAndFootwear=ClothingAndFootwearValue;
      prevCommunication=CommunicationValue;
      prevCurrentHousingCosts=CurrentHousingCostsValue
      prevDomesticFuelAndPower=DomesticFuelAndPowerValue
      prevFoodAndNonAlcoholicBeverages=FoodAndNonAlcoholicBeveragesValue
      prevHouseholdFurnishingsAndEquipment=HouseholdFurnishingsAndEquipmentValue
      prevHouseholdServicesAndOperation=HouseholdServicesAndOperationValue
      prevPersonalCare=PersonalCareValue
      prevRecreation=RecreationValue
      prevAccountantFees=AccountantFeesValue
      prevBodyCorporatePayments=BodyCorporatePaymentsValue
      prevCashGiftsAndDonations=CashGiftsAndDonationsValue
      prevFeesNecGovernment=FeesNecGovernmentValue
      prevFeesNecPrivate=FeesNecPrivateValue
      prevFinancialInstitutionCharges=FinancialInstitutionChargesValue
      prevHireServicesNec=HireServicesNecValue
      prevInsuranceNec=InsuranceNecValue
      prevMiscellaneousGoodsNec=MiscellaneousGoodsNecValue
      prevMiscellaneousGoodsNfd=MiscellaneousGoodsNfdValue
      prevMiscellaneousServicesNec=MiscellaneousServicesNecValue
      prevNonHolidayAccommodation=NonHolidayAccommodationValue
      prevStationeryEquipmentnfd=StationeryEquipmentnfdValue
      prevTravelGoods=TravelGoodsValue
      prevUnionDues=UnionDuesValue
      prevcashWithdrawals=cashWithdrawalsValue
      prevBankFees=BankFeesValue
      prevloanInterests=loanInterestsValue


      prevCarLoan = CarLoanValue;
      prevOtherExpenses = OtherExpensesValue;
      prevHECSPayments = HECSPaymentsValue;
      prevEquityGain = EquityGainValue;

      prevYear = d.getFullYear();
let total=0
let cashOnHand2=
date === Today.format("MMM-YYYY")
? FinanceCash //first month
: prevCashOnHand2 +
(accountYour - accountYour + (accountPartner - accountPartner)) +
monthlyCashflow;

let equityGain2=
date === Today.format("MMM-YYYY")
? equityGain
: prevEquityGain2 +
overallprincipleValue
if(currentDatePurchase){
  
  equityGain2=equityGain2-currentDatePurchase.depositEquity
  equityGain2=equityGain2<0?0:equityGain2
  cashOnHand2=cashOnHand2-currentDatePurchase.depositCash
  cashOnHand2=cashOnHand2<0?0:cashOnHand2
  total=equityGain2+cashOnHand2
}else{
  total=equityGain2 +cashOnHand2
}
if(currentDatePurchase){
         if(String(currentDatePurchase.useLiquidAssets)=='Y'){
          liquidassetValue=currentDatePurchase.liquidAssets
                }else{
                  liquidassetValue=currentDatePurchase.liquidAssets
                }
}
if(equityGain2<0){
  equityGain2=0
}
if(cashOnHand2<0){
  cashOnHand2=0
}
prevCashOnHand2=cashOnHand2
prevEquityGain2=equityGain2
if(prevCashOnHand<0){
  prevCashOnHand=0
}
if(prevEquityGain2<0){
  prevEquityGain2=0
}
// console.log(prevEquityGain2,"prevEquityGain2",overallprincipleValue,cash,FinanceCash,((accountYour - accountYour + (accountPartner - accountPartner)) + monthlyCashflow),date,currentDatePurchase)
      return { 
        date,
        balanceSheet: {
          total: total, //cash on hand added in balance sheet EquityGainValue +cashOnHandValue
          cashOnHand: {
            total: cashOnHandValue,
            total2:cashOnHand2, //cashOnHandValue
            accountYour,
            accountPartner,
            extraCash:extracashvalue,
            liquidassets:liquidassetValue,
          },
          EquityGain: EquityGainValue,
          EquityGain2: equityGain2,
          liabilities: {
            total: liabilities,
            creditCardPartner,
            creditCardYours,
          },
        },
        monthlyCashflow: {
          total: monthlyCashflow,
          expenses: {
            total: expensesValue,
            PersonalExpenses: PersonalExpensesValue,
            InvestmentExpenses,
            BillsUtilities: BillsUtilitiesValue,
            Education: EducationValue,
            Entertainment: EntertainmentValue,
            HigherEducationandVocationalTraining:
              HigherEducationandVocationalTrainingValue,
            Childcare: ChildcareValue,
            PrimaryResidence: PrimaryResidenceValue,
            FoodDining: FoodDiningValue,
            RecreationHolidays: RecreationHolidaysValue,
           
            GeneralInsurance: GeneralInsuranceValue,
            LoansCreditCards: LoansCreditCardsValue,
            RentMortgageProperty: RentMortgagePropertyValue,
            Shopping: ShoppingValue,
            Travel: TravelValue,
            HECSPayments: HECSPaymentsValue,
            CarLoan: CarLoanValue,
            AlcoholicBeverages:AlcoholicBeveragesValue,
            ClothingAndFootwear:ClothingAndFootwearValue,
            Communication:CommunicationValue,
            CurrentHousingCosts:CurrentHousingCostsValue,
            DomesticFuelAndPower:DomesticFuelAndPowerValue,
            FoodAndNonAlcoholicBeverages:FoodAndNonAlcoholicBeveragesValue,
            HouseholdFurnishingsAndEquipment:HouseholdFurnishingsAndEquipmentValue,
            HouseholdServicesAndOperation:HouseholdServicesAndOperationValue,
            HealthFitness: HealthFitnessValue,
            PersonalCare:PersonalCareValue,
            Recreation:RecreationValue,
            Travel:TravelValue,
            AccountantFees:AccountantFeesValue,
            BodyCorporatePayments:BodyCorporatePaymentsValue,
            CashGiftsAndDonations:CashGiftsAndDonationsValue,
            FeesNecGovernment:FeesNecGovernmentValue,
            FeesNecPrivate:FeesNecPrivateValue,
            FinancialInstitutionCharges:FinancialInstitutionChargesValue,
            HireServicesNec:HireServicesNecValue,
            InsuranceNec:InsuranceNecValue, 
            MiscellaneousGoodsNec:MiscellaneousGoodsNecValue,
            MiscellaneousGoodsNfd:MiscellaneousGoodsNfdValue,
            MiscellaneousServicesNec:MiscellaneousServicesNecValue,
            NonHolidayAccommodation:NonHolidayAccommodationValue,
            StationeryEquipmentnfd:StationeryEquipmentnfdValue,
            TravelGoods:TravelGoodsValue,
            UnionDues:UnionDuesValue,
            OtherExpenses: OtherExpensesValue,
            cashWithdrawals:cashWithdrawalsValue,
            BankFees:BankFeesValue,
            loanInterests:loanInterestsValue,
            externalTransfers:externalTransfersValue,


            
          },
          income: {
            total: income,
            netSalaryYour,
            netSalaryPartner,
            rentalIncome,
            OtherIncome,
          },
        },
      };
    });
   
    setData([...data]);
  };
  let ApplyFilter = (value = "ByMonth") => {
    setFilterBy(() => value);
    generateData();
  };
  useEffect(() => {
    const EquityGainsDB = GS.SOT.Equity.EquityGains;
    let EquityGainList = [];
    let Properties = EquityGainsDB?.filter((item) =>
      item.PropertyName.includes("Property")
    );
    if (Properties?.length > 0) {
      EquityGainList = Properties?.filter((item, idx) => {
        let Property = GS?.SOT?.Equity?.properties;
        if (Property[idx]) {
          return {
            PropertyName: item.PropertyName,
            Percentage: item.Percentage,
            startDate: item.startDate,
            endDate: item.endDate,
            noOfMonths: item.noOfMonths,
            value: Property[idx]?.REAValuation * (item.Percentage / 100),
          };
        } else {
          return null;
        }
      });
    } else {
      if (GS?.SOT?.Equity?.properties.length) {
        EquityGainList = GS?.SOT?.Equity?.properties?.map((item, idx) => {
          return {
            PropertyName: item.PropertyName,
            Percentage: 5,
            startDate: new Date(moment().add(1, "month")),
            endDate: new Date(
              new Date(moment().add(1, "month")).getTime() + 31536000000
            ),
            noOfMonths: 12,
            value: item?.REAValuation * 0.05,
          };
        });
      }
    }

    let PPR = EquityGainsDB?.filter((item) => item.PropertyName === "PPR");
    if (PPR?.length > 0) {
      EquityGainList.unshift({
        PropertyName: "PPR",
        Percentage: PPR[0].Percentage,
        startDate: PPR[0].startDate,
        endDate: PPR[0].endDate,
        noOfMonths: PPR[0].noOfMonths,
        value: GS?.SOT?.Equity?.PPR?.DomainAutoVal * (PPR[0].Percentage / 100),
      });
    } else {
      EquityGainList.unshift({
        PropertyName: "PPR",
        Percentage: 5,
        startDate: new Date(moment().add(1, "month")),
        endDate: new Date(
          new Date(moment().add(1, "month")).getTime() + 31536000000
        ),
        noOfMonths: 12,
        value: GS?.SOT?.Equity?.PPR?.DomainAutoVal * 0.05,
      });
    }

    setEquityGains([...EquityGainList]);
    CalculatePrinciple();
    let value = 0;
    GS?.SOT?.Equity?.properties?.map((property) => {
      value += property?.MortgagePayments?.value;
      return null;
    });
    setRentMortgageProperty(value / 12 || 0.0);
    let cardPayments=GS.SOT.BorrowingCalculator.CreditCards.reduce((prevItem,nextItem)=>{
      return prevItem+nextItem.minimumPayment
    },0)

    let carddebtPayments=GS.SOT.BorrowingCalculator.CreditCards.reduce((prevItem,nextItem)=>{
      return prevItem+Number(nextItem.currentDebt)
    },0)
    let propertynetIncomVal=GS?.SOT?.Equity?.properties?.reduce((prevItem,nextItem)=>{
      if(nextItem.NetIncome.value<0){
          prevItem=prevItem+Math.abs(nextItem.NetIncome.value)
      }
      return prevItem
    },0)
    let propertyvaluation=GS?.SOT?.Equity?.properties?.reduce((prevItem,nextItem)=>{
      return prevItem+Number(nextItem.REAValuation        )
      
    },0)
   
    propertyvaluation=propertyvaluation+Number(GS?.SOT?.Equity?.PPR?.DomainAutoVal)

    let propertypaymentval=GS?.SOT?.Equity?.properties?.reduce((prevItem,nextItem)=>{
      if(nextItem.interestOnly){
        if(nextItem.LoanRepayments===0){
          prevItem=prevItem+0
        }else{
          prevItem=prevItem+nextItem.LoanRepaymentsMonthlyInterest
        }
       
      }else{
        if(nextItem.LoanRepayments===0){
          prevItem=prevItem+0
        }else{
          prevItem=prevItem+nextItem.LoanRepaymentsMonthlyPrincipleInterest
        }
      }
      return prevItem
    },0)
    let propertyloanval=GS?.SOT?.Equity?.properties?.reduce((prevItem,nextItem)=>{
      
      return prevItem+Number(nextItem.IPMortage)

    },0)

    let Repaymentval=GS?.SOT?.Equity?.PPR?.PrincipleInterest? GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyPaymentValue:GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsMonthlyInterestValue
    let annualexpenses=0
    if(GS?.SOT?.BorrowingCalculator?.averageAustralianAnnual || GS?.SOT?.BorrowingCalculator?.manualInput){
      annualexpenses=GS?.SOT?.BorrowingCalculator?.annual_expenses_2/12
    }else{
      annualexpenses=GS?.SOT?.BorrowingCalculator?.annual_expenses_2
    }
    let totalexpense=annualexpenses+cardPayments+Repaymentval+(propertynetIncomVal/12)+propertypaymentval
    let pprLoan=Number(GS?.SOT?.Equity?.PPR?.PPRMortgage?.value)
    console.log(GS?.SOT?.BorrowingCalculator?.averageAustralianAnnual,"annualexpenses",annualexpenses,"cardPayments",cardPayments,"Repaymentval",Repaymentval,"(propertynetIncomVal/12)",(propertynetIncomVal),"propertypaymentval",propertypaymentval)
    setmonthlySavings(income-totalexpense)
    setTotalExpense(totalexpense)
    
    setTotalAssets(LiquidAssets+Number(GS?.SOT?.Equity?.Cash?.value)+propertyvaluation)
    setOverviewLiabilities(pprLoan+propertyloanval+carddebtPayments)
  }, []);
  
  useEffect(() => {
    let value =  CreditCards?.reduce(
      (partialSum, nextItem) =>
        (nextItem?.type === "Car Loan" && nextItem?.type === "Loan")
          ? partialSum + nextItem.minimumPayment
          : partialSum,
      0
    );
    let pprloanvalue =
      GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentType === "Monthly"
        ? GS?.SOT?.Equity?.PPR?.HomeLoanRepayments
        : GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentType === "Weekly"
        ? (GS?.SOT?.Equity?.PPR?.HomeLoanRepayments * 52) / 12
        : GS?.SOT?.Equity?.PPR?.HomeLoanRepayments / 12;
    let iploanvalue = 0;
    GS.SOT.Equity.properties.map((item, index) => {
      let v =
        item.LoanRepaymentsType === "Monthly"
          ? item.LoanRepayments 
          : item.LoanRepaymentsType === "Weekly"
          ? (item.LoanRepayments * 52) / 12
          : item.LoanRepayments / 12;
      iploanvalue = iploanvalue + v;
      return null;
    });
    setLiabilities(value + pprloanvalue + iploanvalue);
  }, [CreditCards]);
  useEffect(() => {
    let value =
      netSalaryYour + netSalaryPartner + rentalIncome + OtherIncome || 0.0;
    setIncome(value);
  }, [netSalaryYour, netSalaryPartner, rentalIncome, OtherIncome]);
  useEffect(() => {
  
    let value = uaaae
      ?  //CarLoan +
        // OtherExpenses +
        GS?.SOT?.BorrowingCalculator?.annual_expenses_2 / 12
      : 
      AlcoholicBeverages +
      ClothingAndFootwear +
      Communication +
      CurrentHousingCosts +
      DomesticFuelAndPower +
      Education +
      FoodAndNonAlcoholicBeverages +
      HouseholdFurnishingsAndEquipment +
      HouseholdServicesAndOperation +
        HealthFitness +
        PersonalCare +
        Recreation +
        Travel +
        AccountantFees +
        BodyCorporatePayments +
        CashGiftsAndDonations +
        FeesNecGovernment +
        FeesNecPrivate +
        FinancialInstitutionCharges +
        HireServicesNec+
        InsuranceNec +
        MiscellaneousGoodsNec +
        MiscellaneousGoodsNfd +
        MiscellaneousServicesNec +
        NonHolidayAccommodation +
        StationeryEquipmentnfd +
        TravelGoods +
        UnionDues +
        OtherExpenses +
        cashWithdrawals +
        BankFees +
        loanInterests +
        externalTransfers +

        HECSPayments;

    setPersonalExpenses(value);
    GS.SOT.StrategicSpending.Utilities = Utilities;
  }, [
    AlcoholicBeverages ,
    ClothingAndFootwear ,
    Communication ,
    CurrentHousingCosts ,
    DomesticFuelAndPower ,
    Education ,
    FoodAndNonAlcoholicBeverages ,
    HouseholdFurnishingsAndEquipment ,
    HouseholdServicesAndOperation ,
      HealthFitness ,
      PersonalCare ,
      Recreation ,
      Travel ,
      AccountantFees ,
      BodyCorporatePayments ,
      CashGiftsAndDonations ,
      FeesNecGovernment ,
      FeesNecPrivate ,
      FinancialInstitutionCharges ,
      HireServicesNec,
      InsuranceNec ,
      MiscellaneousGoodsNec ,
      MiscellaneousGoodsNfd ,
      MiscellaneousServicesNec ,
      NonHolidayAccommodation ,
      StationeryEquipmentnfd ,
      TravelGoods ,
      UnionDues ,
      OtherExpenses ,
      cashWithdrawals ,
      BankFees ,
      loanInterests ,
      externalTransfers ,
      HECSPayments,

    
    uaaae,
  ]);
  useEffect(() => {
    let value = income - (PersonalExpenses + liabilities) || 0.0;
    // console.log(income,"income",PersonalExpenses,"Personalexpenses","liabilities",liabilities)
    setMonthlyCashflow(value);
  }, [income, PersonalExpenses, liabilities]);
  useEffect(() => {
    let TotalExtraCash = ExtraCash.reduce(
      (partialSum, nextItem) => (partialSum += nextItem.value),
      0
    );
    let value =
      accountYour +
        accountPartner +
        monthlyCashflow +
        TotalExtraCash +
        GS?.SOT?.Equity.Cash?.value || 0.0;
      
    setCashOnHand(value);
  }, [accountYour, accountPartner, monthlyCashflow, ExtraCash]);
  useEffect(() => {
    GS.SOT.StrategicSpending.balanceSheet = balanceSheet;
    GS.SOT.StrategicSpending.cashOnHand = cashOnHand;
    GS.SOT.StrategicSpending.accountYour = accountYour;
    GS.SOT.StrategicSpending.accountPartner = accountPartner;
    GS.SOT.StrategicSpending.liabilities = liabilities;
    GS.SOT.StrategicSpending.creditCardYours = creditCardYours;
    GS.SOT.StrategicSpending.creditCardPartner = creditCardPartner;
    GS.SOT.StrategicSpending.monthlyCashflow = monthlyCashflow;
    GS.SOT.StrategicSpending.income = income;
    GS.SOT.StrategicSpending.OtherIncome = OtherIncome;
    GS.SOT.StrategicSpending.netSalaryYour = netSalaryYour;
    GS.SOT.StrategicSpending.netSalaryPartner = netSalaryPartner;
    GS.SOT.StrategicSpending.rentalIncome = rentalIncome;
    GS.SOT.StrategicSpending.expenses = expenses;
    GS.SOT.StrategicSpending.BillsUtilities = BillsUtilities;
    GS.SOT.StrategicSpending.Education = Education;
    GS.SOT.StrategicSpending.Entertainment = Entertainment;
    GS.SOT.StrategicSpending.HigherEducationandVocationalTraining =
      HigherEducationandVocationalTraining;

    GS.SOT.StrategicSpending.Childcare = Childcare;
    GS.SOT.StrategicSpending.PrimaryResidence = PrimaryResidence;
    GS.SOT.StrategicSpending.FoodDining = FoodDining;
    GS.SOT.StrategicSpending.RecreationHolidays = RecreationHolidays;
    GS.SOT.StrategicSpending.HealthFitness = HealthFitness;
    GS.SOT.StrategicSpending.GeneralInsurance = GeneralInsurance;
    GS.SOT.StrategicSpending.LoansCreditCards = LoansCreditCards;
    GS.SOT.StrategicSpending.RentMortgageProperty = RentMortgageProperty;
    GS.SOT.StrategicSpending.Shopping = Shopping;
    GS.SOT.StrategicSpending.Travel = Travel;
    GS.SOT.StrategicSpending.CarLoan = CarLoan;
    GS.SOT.StrategicSpending.Data = Data;
    GS.SOT.StrategicSpending.OtherExpenses = OtherExpenses;
    GS.SOT.BorrowingCalculator.averageAustralianAnnual = uaaae;

 
    GS.SOT.StrategicSpending.AlcoholicBeverages=AlcoholicBeverages || 0
    GS.SOT.StrategicSpending.ClothingAndFootwear=ClothingAndFootwear
    GS.SOT.StrategicSpending.Communication=Communication
    GS.SOT.StrategicSpending.CurrentHousingCosts=CurrentHousingCosts
    GS.SOT.StrategicSpending.DomesticFuelAndPower=DomesticFuelAndPower
    GS.SOT.StrategicSpending.FoodAndNonAlcoholicBeverages=FoodAndNonAlcoholicBeverages
    GS.SOT.StrategicSpending.HouseholdFurnishingsAndEquipment=HouseholdFurnishingsAndEquipment
    GS.SOT.StrategicSpending.HouseholdServicesAndOperation=HouseholdServicesAndOperation
    GS.SOT.StrategicSpending.HealthFitness=HealthFitness
    GS.SOT.StrategicSpending.PersonalCare=PersonalCare
    GS.SOT.StrategicSpending.Recreation=Recreation
    GS.SOT.StrategicSpending.Travel=Travel
    GS.SOT.StrategicSpending.GoodsAndServices={
      value:0, 
      summary:[
        {
          AccountantFees:AccountantFees,
        },
        {
          BodyCorporatePayments:BodyCorporatePayments,
        },
        {
          CashGiftsAndDonations:CashGiftsAndDonations,
        },
        {
          FeesNecGovernment:FeesNecGovernment,
        },
        {
          FeesNecPrivate:FeesNecPrivate,
        },
        {
          FinancialInstitutionCharges:FinancialInstitutionCharges,
        },
        {
          HireServicesNec:HireServicesNec,
        },
        {
          InsuranceNec:InsuranceNec,
        },
        {
          MiscellaneousGoodsNec:MiscellaneousGoodsNec,
        },
        {
          MiscellaneousGoodsNfd:MiscellaneousGoodsNfd,
        },
        {
          MiscellaneousServicesNec:MiscellaneousServicesNec,
        },
        {
          NonHolidayAccommodation:NonHolidayAccommodation,
        },
        {
          StationeryEquipmentnfd:StationeryEquipmentnfd,
        },
        {
          TravelGoods:TravelGoods,
        },
        {
          UnionDues:UnionDues,
        }
      ]
    }
    GS.SOT.StrategicSpending.Education = Education;
    GS.SOT.StrategicSpending.OtherExpenses=OtherExpenses
    GS.SOT.StrategicSpending.BankFees=BankFees
    GS.SOT.StrategicSpending.cashWithdrawals=cashWithdrawals
    GS.SOT.StrategicSpending.externalTransfers=externalTransfers
    GS.SOT.StrategicSpending.loanInterests=loanInterests











    GS.SOT.BorrowingCalculator.salary_after_tax =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.salary_after_tax_term,
        netSalaryYour
      );
    GS.SOT.BorrowingCalculator.partner_salary_after_tax =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax_term,
        netSalaryPartner
      );
    GS.SOT.BorrowingCalculator.NetRentalIncome =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.NetRentalIncome_term, 
        rentalIncome
      );
    GS.SOT.BorrowingCalculator.car_loan_payment_2 =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.car_loan_payment_2_term,
        CarLoan
      );
    GS.SOT.BorrowingCalculator.other_payments_2 =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.other_payments_2_term,
        OtherExpenses
      );
        if(GS?.SOT?.BorrowingCalculator?.HECSPayments?.balance){
          GS.SOT.BorrowingCalculator.HECSPayments.balance =
          inputCalculateAnnualMonthlyAmmount(
            GS?.SOT?.BorrowingCalculator?.HECSPayments?.type,
            HECSPayments 
          );
        }
     
    GS.SOT.StrategicSpending.ExtraCash = ExtraCash;
    GS.SOT.Equity.EquityGains = EquityGains;
    GS.SOT.BorrowingCalculator.CreditCards = CreditCards;
    GS.SOT.PropertyGenerators[0].netIncome = income;
    GS.SOT.PropertyGenerators[0].cashAvailable =
      Data[0]?.balanceSheet.cashOnHand.total;
    GS.SOT.PropertyGenerators[0].equityGain = Data[0]?.balanceSheet.EquityGain;
    //  +
    // EquityGains.reduce(
    //   (prevValue, currentValue) =>
    //     (prevValue += currentValue.value / currentValue.noOfMonths),
    //   0
    // );

    GS.SOT.StrategicSpending.totalMonth =
      ((Data?.length > 0 && Data[0]?.balanceSheet.cashOnHand.total) ||
        cashOnHand) +
      ((Data?.length > 0 && Data[0]?.balanceSheet.EquityGain) || EquityGain) +
      EquityGains.reduce(
        (prevValue, currentValue) =>
          (prevValue += currentValue.value / currentValue.noOfMonths),
        0
      );
      localStorage.setItem('SOT',JSON.stringify(GS.SOT))
    API.AutoSave(window.location.href);
  });
  useEffect(() => {
    let value = PersonalExpenses + InvestmentExpenses;
    setExpenses(value);
  }, [PersonalExpenses, InvestmentExpenses]);

  useEffect(() => {
    let value = BillsUtilities + RentMortgageProperty;
    setInvestmentExpenses(value);
  }, [BillsUtilities, RentMortgageProperty, uaaae]);
 
  useEffect(() => {
    setFilterBy(() => "ByMonth");
    generateData();
  }, [
    balanceSheet,
    PrincipleData,
    cashOnHand,
    InvestmentExpenses,
    expenses,
    monthlyCashflow,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  let Navigate = useNavigate();
  const dispatch = useDispatch();
  const updateStrategicSpending = async () => {
    GS.SOT.Progress = { steps: 5, completed: 2, current: 3 };
    localStorage.setItem('SOT',JSON.stringify(GS.SOT))
    await API.AutoSave(window.location.href);
  }; 

  const updateCreditCard = (updatable_id, key, value) => {
    const updated_list = CreditCards.map((item, idx) => {
      if (updatable_id === idx) {
        item[key] = value;
      }
      return item;
    }); 
    setCreditCards([...updated_list]);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition2(position);
  };

  const GetEquityGainsTotalVal = () => { 
    let value = 0;
    let vk = 0;
    EquityGains.map((item) => {
      var condition = moment(new Date()).isBetween(
        item.startDate,
        item.endDate
      );
      if (condition) {
        let vk = item.value / item.noOfMonths;
        value = value + vk;
      }
    });
    return value;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
   if(GS?.SOT?.Equity?.properties?.length>0){
    let estimatedvalue=GS?.SOT?.Equity?.properties?.reduce((total,item)=>{
        return total+Number(item?.EstimatedOutgoings)
             
        },0)

    setEstimatedOutgoings(estimatedvalue || 0)
   }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const addExtraCash = () => {
    setExtraCash((prevState) => [...prevState, { date: new Date(), value: 0 }]);
  };

  const updateExtraCash = (updatable_id, key, value) => { 
    const updated_list = ExtraCash?.map((item, idx) => {
      if (updatable_id === idx) {
        item[key] = value;
      } 
      return item;
    }); 
    setExtraCash([...updated_list]);
  };

  const removeExtraCash = (e, removal_id) => {
    const updated_list = ExtraCash.filter((_, idx) => removal_id !== idx);
    setExtraCash([...updated_list]);
  };

  const updateEquityGains = (updatable_id, key, value) => {
    const updated_list = EquityGains?.map((item, idx) => {
      if (updatable_id === idx) {
        item[key] = value;
      }
      return item;
    });
    if (key === "Percentage" || key === "remove") {
      if (EquityGains[updatable_id].PropertyName.includes("Property")) {
        GS?.SOT?.Equity?.properties?.map((item) => {
          if (EquityGains[updatable_id].PropertName === item.PropertName) {
            updated_list[updatable_id].value = parseFloat(
              (item?.REAValuation * (value / 100)).toFixed(2)
            );

            updated_list[updatable_id].Percentage = value;
          }
          return null;
        });
      } else {
        updated_list[0].Percentage = value;

        updated_list[0].value = parseFloat(
          (GS?.SOT?.Equity?.PPR?.DomainAutoVal * (value / 100)).toFixed(2)
        );
      }
    }
    if (key === "endDate") {
      let startDate = moment(EquityGains[updatable_id].startDate);
      let noOfMonths = Math.abs(startDate.diff(value, "months"));
      EquityGains[updatable_id].noOfMonths = noOfMonths;
    }
    if (key === "startDate") {
      EquityGains[updatable_id].endDate = new Date(
        new Date(value).getTime() + 31536000000
      );
      let startDate = moment(value);
      let noOfMonths = Math.abs(
        startDate.diff(
          new Date(new Date(value).getTime() + 31536000000),
          "months"
        )
      );
      EquityGains[updatable_id].noOfMonths = noOfMonths;
    }

    setEquityGains([...updated_list]);
  };
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType")=="Contacts"? VendorData?.data?.contact_type:sessionStorage.getItem("accountType");
    if (accountType === "Contacts"|| accountType === "Contact Lite"|| accountType === "Contact Paid") {
      setOverLay(true);
    } else {
      setOverLay(false);
    }
   
  }, []);
  return (
    <>
    
      {OverLay == true && (
        <>
       <div style={{minHeight:"90vh"}}>
       <Row className="mt-4 d-flex justify-content-between" style={{background:"#ffffff",borderRadius:"8px",padding:"36px",paddingLeft:"18px",paddingRight:"18px",paddingBottom:"28px",marginLeft:"2px",marginRight:"2px"}}>
        <div className="boxs1 myboxes">
        <div className="grid">
        <div className="col-fixed">
              <p className="mt-2 txtRed">Total Income</p>
              <p >
                {CurrencyFormatter.format(income)}
              </p>
            </div>
        
            <div className="col-fixed">
              <p className="mt-2 txtRed">Monthly Savings</p>
              <p >
                {CurrencyFormatter.format(monthlySavings)}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 txtRed">Total Assets</p>
              <p >
                {CurrencyFormatter.format(totalAssets)}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 txtRed">Estimated Outgoings</p>
              <p >
                {CurrencyFormatter.format(EstimatedOutgoings)}
              </p>
            </div>
           
          </div>
       </div>
       <div className="boxs1 myboxes " style={{marginTop:"30px"}}>
        <div className="grid">
        <div className="col-fixed">
              <p className="mt-2 txtRed">Total Expenses</p>
              <p >
                {CurrencyFormatter.format(TotalExpense)}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 txtRed">Cash On Hand</p>
              <p >
                {CurrencyFormatter.format(Number(GS?.SOT?.Equity?.Cash?.value))}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 txtRed">Total Liabilities</p>
              <p >
                {CurrencyFormatter.format(OverviewLiabilities)}
              </p>
            </div>
            <div className="col-fixed">
          
              <p className="mt-2 txtRed">Useable Equity</p>
              <p >
                {CurrencyFormatter.format(GS?.SOT?.Equity?.PPR?.totalEquity)}
              </p>
            </div>
           
          </div>
       </div>
       <div className="button-container">
                <GradientButton
                  style={{
                    position: "fixed",
                    bottom: "31px",
                    right: "94px",
                    width:"89px !important"
                  }}
                  width="89px"
                  onClick={async () => {
                    await updateStrategicSpending();
                    GS.SOT.Progress = { steps: 4, completed: 2, current: 3 };
                    const updateData = [...state];
                    const i = updateData.findIndex(
                      (vendor) => vendor._id === vendorid
                    );
                    updateData[i] = {
                      ...updateData[i],
                      Progress: [GS.SOT.Progress],
                    };
                    dispatch(Vendors(updateData)); 
                    publish("PropertyActivate", null);
                    setActiveIndex(3);
                    if ((accountType != "Contacts") || (accountType != "Contact Lite")) {
                      dispatch(setGenericData({ ActiveProperty: null }));
                    }
                    // Navigate(`/Clients/Finances?id=${vendorid}`);
                    Navigate(`/Clients/Summary?id=${vendorid}`);
                  }}
                  txt="Next"
                />
                <GradientButton
                  style={{
                    position: "fixed",
                    bottom: "31px",
                    right: "196px",
                    width:"89px !important"
                  }}
                  width="89px"
                  onClick={() => {
                    // Navigate(-1);
                    onBack();
                  }}
                  txt="Back"
                />
              </div>
        </Row>
       </div>
        {/* Gradient-Box w-80 */}
         {/* <Row className="mt-7  d-flex justify-content-between"> 
                <Col md={6} lg={6}>
                <div className="d-flex align-items-end justify-content-between spendingBox">
                    <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p>Equity Gained</p>
                        <p >{CurrencyFormatter.format(EquityGain)}</p>
                      </div>
                      <hr/>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p>Monthly Savings</p>
                        <p >{CurrencyFormatter.format(monthlyCashflow)}</p>
                      </div>
                      <hr/>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p>Extra Cash</p>
                        <p >{CurrencyFormatter.format(ExtraCash)}</p>
                      </div>
                      <hr/>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p>Liquid Assets</p>
                        <p >{CurrencyFormatter.format(LiquidAssets)}</p>
                      </div>
                    </div>
                   
                  </div>
                 
                </Col>
                <Col md={6} lg={6}>
                  <div className="d-flex align-items-end justify-content-between spendingBox">
                    <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p>Total Income</p>
                        <p >{CurrencyFormatter.format(income)}</p>
                        
                      </div>
                      <hr/>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p>Total Liabilities</p>
                        <p >{CurrencyFormatter.format(liabilities)}</p>
                      </div>
                      <hr/>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p>Total Expenses</p>
                        <p >{CurrencyFormatter.format(PersonalExpenses)}</p>
                      </div>
                      <hr/>
                      <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p className="BlueTxt">Cash On Hand</p>
                        <p className="BlueTxt">{CurrencyFormatter.format(cashOnHand)}</p>
                      </div>
                    </div>
                   
                  </div>
                </Col>
              </Row> */}
              </>
      )}

      {OverLay == false && (
        <div style={{ marginTop: "30px" }}>
          <Form className="mt-3">
            {sessionStorage.getItem("accountType") !== "Contacts" &&
            <CollapseCard title={"Balance Sheet"}>
            <div className="d-flex bg-white align-items-center gap-3">
              <FormGroup className="mb-3" controlId="cash">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      balanceSheet
                    )}`)
                  }
                  value={balanceSheet}
                  placeholder="Balance"
                  onChange={(e) => {
                    setBalanceSheet(e.value);
                    OnChange();
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={1}
                  inputClassName="border-none bg-light rounded-0"
                  className="input-field mr-3 mb-1"
                />
              </FormGroup>
            </div>
            <Row>
              <Col sm={12} md={12} lg={3}>
                <FormGroup className="mb-3" controlId="cash">
                  <p className="input-label mb-2">Cash On Hand:</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        cashOnHand
                      )}`)
                    }
                    value={cashOnHand}
                    onChange={(e) => {
                      setCashOnHand(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                    inputClassName="border-none cashOnHand-primary rounded-0"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={12} lg={3}>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Account (Yours)</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        accountYour
                      )}`)
                    }
                    value={accountYour}
                    onChange={(e) => {
                      setAccountYour(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={12} lg={3}>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Account (Partner)</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        accountPartner
                      )}`)
                    }
                    value={accountPartner}
                    onChange={(e) => {
                      setAccountPartner(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US" 
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={12} lg={3}>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Equity Gain</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        EquityGain
                      )}`)
                    }
                    value={EquityGain}
                    onChange={(e) => {
                      setEquityGain(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                  />
                </FormGroup>
              </Col>
              <Col sm={10} xs={10} lg={3} md={5} className="pr-5">
                {ExtraCash.length > 0 ? (
                  <Table bordered className="ExtraCashTable">
                    <thead>
                      <tr>
                        <th>
                          <p className="input-label">Extra Cash</p>
                        </th>
                        <th>
                          <p className="input-label">Date</p>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {ExtraCash?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="py-1">
                              <InputNumber
                                onFocus={(e) => (e.target.value = "")}
                                onBlur={(e) =>
                                  (e.target.value = `${CurrencyFormatter.format(
                                    item?.value || 0.0
                                  )}`)
                                }
                                value={item?.value}
                                onChange={(e) => {
                                  updateExtraCash(idx, "value", e.value);
                                }}
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                                minfractiondigits={1}
                                inputClassName="extraCash-input-field"
                                className="border-0 extraCash-input-field"
                              />
                            </td>
 
                            <td className="py-1">
                              <Calendar
                                minDate={new Date(moment())}
                                maxDate={new Date(moment().add(84, "month"))}
                                value={new Date(item?.date)}
                                dateFormat="dd-M-yy"
                                onChange={(e) =>
                                  updateExtraCash(idx, "date", e.value)
                                }
                                inputClassName="border-0 extraCash-input-field-calender"
                                className="extraCash-input-field-calender"
                              />
                            </td>
                            <td className="ExtraCashActionTD" hover>
                              <span
                                onClick={(e) => {
                                  removeExtraCash(e, idx);
                                }}
                                style={{
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  color: "#323232",
                                  padding: "0px 2px",
                                }}
                              >
                                x
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                      <tr
                        onMouseEnter={(e) =>
                          (e.target.style.background = "#F7F9FA")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.background = "#fff")
                        }
                      >
                        <td
                          className="text-center"
                          onClick={addExtraCash}
                          style={{ cursor: "pointer", fontSize: "14px" }}
                          colSpan={7}
                        >
                          Add New
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>
                          <p className="input-label">Extra Cash</p>
                        </th>
                        <th>
                          <p className="input-label">Date</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="text-center "
                          onClick={addExtraCash}
                          style={{ cursor: "pointer", fontSize: "14px" }}
                          colSpan={6}
                        >
                          No Extra Cash
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={3}>
                <FormGroup className="mb-3" controlId="CapitalGrowthRate">
                  <p className="input-label mb-2">Capital Growth Rate:</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={10} xs={10} lg={5} md={5} className="pr-5">
                {EquityGains.length > 0 && (
                  <Table bordered className="EquityGainsTable">
                    <thead>
                      <tr>
                        <th>
                          <p className="input-label">Property Name</p>
                        </th>
                        {/* <th>
                          <p className="input-label">Equity Gain</p>
                        </th> */}
                        <th>
                          <p className="input-label">Percentage</p>
                        </th>
                        <th>
                          <p className="input-label">Start Date</p>
                        </th>
                        <th>
                          <p className="input-label">End Date</p>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {EquityGains?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="py-1 ">
                              <div className="d-flex align-items-center justify-content-between">
                                <InputText
                                  value={item?.PropertyName || ""}
                                  onChange={(e) => {
                                    updateEquityGains(
                                      idx,
                                      "PropertyName",
                                      e.value
                                    );
                                  }}
                                  mode="currency"
                                  currency="USD" 
                                  locale="en-US"
                                  minfractiondigits={1}
                                  className="border-0 EquityGains-field"
                                  readOnly
                                />
                              </div>
                            </td>
                            {/* <td className="py-1 ">
                              <div className="d-flex align-items-center justify-content-between">
                                <InputNumber
                                  value={item.value}
                                  onChange={(e) => {
                                    updateEquityGains(idx, "value", e.value);
                                  }}
                                  mode="currency"
                                  currency="USD"
                                  locale="en-US"
                                  minfractiondigits={1}
                                  className="border-0 EquityGains-field"
                                  readOnly
                                />
                              </div>
                            </td> */}
                            <td className="py-1 ">
                              <div
                                style={{ width: 64 }}
                                className="d-flex align-items-center justify-content-between"
                              >
                                <InputNumber
                                  style={{ width: 64 }}
                                  onFocus={(e) => (e.target.value = "")}
                                  onBlur={(e) =>
                                    (e.target.value = `${item.Percentage}%`)
                                  }
                                  value={item.Percentage}
                                  onChange={(e) => {
                                    updateEquityGains(
                                      idx,
                                      "Percentage",
                                      e.value
                                    );
                                  }}
                                  mode="decimal"
                                  minFractionDigits={0}
                                  maxFractionDigits={2}
                                  suffix="%"
                                  className="border-0 EquityGains-fields"
                                />
                              </div>
                            </td>
                            <td className="py-1">
                              <Calendar
                                minDate={new Date(moment())}
                                maxDate={new Date(moment().add(84, "month"))}
                                value={new Date(item.startDate)}
                                onChange={(e) =>
                                  updateEquityGains(idx, "startDate", e.value)
                                }
                                dateFormat="dd-M-yy"
                                inputClassName="border-0 EquityGains-name"
                              />
                            </td>
                            <td className="py-1">
                              <Calendar
                                minDate={new Date(moment())}
                                maxDate={new Date(moment().add(84, "month"))}
                                value={new Date(item.endDate)}
                                onChange={(e) =>
                                  updateEquityGains(idx, "endDate", e.value)
                                }
                                dateFormat="dd-M-yy"
                                inputClassName="border-0 EquityGains-name"
                              />
                            </td>

                            <td className="ExtraCashActionTD" hover>
                              <span
                                onClick={(e) => {
                                  updateEquityGains(idx, "remove", 0);
                                }}
                                style={{
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  color: "#323232",
                                  padding: "0px 2px",
                                }}
                              >
                                x
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row> 
            <Row>
              <Col sm={12} md={12} lg={3}>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Liabilities:</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        liabilities
                      )}`)
                    }
                    value={liabilities}
                    onChange={(e) => {
                      setLiabilities(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                    inputClassName="border-none liabilities-primary rounded-0"
                  />
                </FormGroup>
              </Col>
              {CreditCards?.map((cardItem, idx) => {
                return (
                  <Col key={idx} sm={12} md={12} lg={3}>
                   {((cardItem?.type=="")&&(cardItem?.name=="")&&(cardItem?.owner=="")) && 
                    <p className="input-label mb-2" style={{opacity:"0"}}>Liabilities:</p>
                   } 
                    <FormGroup className="mb-3" controlId="equityValue">
                      <p className="input-label mb-2">
                        {cardItem?.type}{" "}
                        {`${
                          cardItem?.name ? "(" + cardItem?.name + ")" : ""
                        }`}{" "}
                        {`${
                          cardItem?.owner ? "(" + cardItem?.owner + ")" : ""
                        }`}
                      </p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            cardItem?.minimumPayment
                          )}`)
                        }
                        value={cardItem?.minimumPayment}
                        onChange={(e) => {
                          updateCreditCard(idx, "minimumPayment", e.value);
                        }}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        className="input-field mr-3 mb-1"
                      />
                    </FormGroup>
                  </Col>
                );
              })}
            </Row>
          </CollapseCard>
            }
            
            <CollapseCard title={"Monthly Savings"}>
              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        monthlyCashflow
                      )}`) 
                    }
                    value={monthlyCashflow}
                    onChange={(e) => {
                      setMonthlyCashflow(e.value);
                      OnChange();
                    }}
                    placeholder="Equity Value"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                    inputClassName="border-none bg-light rounded-0"
                  />
                </FormGroup>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Income:</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(income)}`)
                    }
                    value={income}
                    placeholder="Equity Value"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                    inputClassName="border-none income-primary rounded-0 text-white"
                  />
                </FormGroup>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Net Salary (Yours)</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        netSalaryYour
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Equity Value"
                    value={netSalaryYour}
                    onChange={(e) => {
                      setNetSalaryYour(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Net Salary (Partners)</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        netSalaryPartner
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Equity Value"
                    value={netSalaryPartner}
                    onChange={(e) => {
                      setNetSalaryPartner(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Rental Income</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        rentalIncome
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Equity Value"
                    value={rentalIncome || 0}
                    onChange={(e) => {
                      setRentalIncome(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3" controlId="OtherIncome">
                  <p className="input-label mb-2">Other Income</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        OtherIncome
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Other Income"
                    value={OtherIncome}
                    onChange={(e) => {
                      setOtherIncome(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Living Expenses:</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        PersonalExpenses
                      )}`)
                    }
                    value={PersonalExpenses}
                    placeholder="Equity Value"
                    onChange={(e) => {
                      setPersonalExpenses(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="border-none expense-primary rounded-0 text-white"
                    className="input-field mr-3 mb-1"
                  />
                </FormGroup>
                <div className="d-flex align-items-center">
                  <Checkbox
                    inputId="UseAverageAustralianAnnual"
                    checked={uaaae}
                    onChange={(e) => {
                      setUaaae(e.checked);
                    }}
                  />
                  <label
                    className="checkbox-label mx-2"
                    htmlFor="UseAverageAustralianAnnual"
                  >
                    Use average Australian annual
                  </label>
                </div>
              </div>
              {!uaaae && (
                <>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="AlcoholicBeverages">
                        <p className="input-label mb-2">Alcoholic beverages</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(AlcoholicBeverages)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Alcoholic Beverages"
                          value={checkIsFinite(AlcoholicBeverages)}
                          onChange={(e) => {
                            setAlcoholicBeverages(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="equityValue">
                        <p className="input-label mb-2">Clothing and footwear</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(ClothingAndFootwear)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Clothing And Footwear"
                          value={checkIsFinite(ClothingAndFootwear)}
                          onChange={(e) => {
                            setClothingAndFootwear(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="Communication">
                        <p className="input-label mb-2">Communication</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(Communication)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Communication"
                          value={checkIsFinite(Communication)}
                          onChange={(e) => {
                            setCommunication(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup
                        className="mb-3"
                        controlId="CurrentHousingCosts"
                      >
                        <p className="input-label mb-2">
                        Current housing costs
                        </p>
                        <InputNumber 
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(CurrentHousingCosts)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Current Housing Costs"
                          value={checkIsFinite(CurrentHousingCosts)}
                          onChange={(e) => {
                            setCurrentHousingCosts(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup
                        className="mb-3"
                        controlId="DomesticFuelAndPower"
                      >
                        <p className="input-label mb-2">
                        Domestic Fuel & Power
                        </p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(DomesticFuelAndPower)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Domestic Fuel And Power"
                          value={checkIsFinite(DomesticFuelAndPower)}
                          onChange={(e) => { 
                            setDomesticFuelAndPower(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="HealthFitness">
                        <p className="input-label mb-2">Education</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(Education)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Education"
                          value={checkIsFinite(Education)}
                          onChange={(e) => {
                            setEducation(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="FoodAndNonAlcoholicBeverages">
                        <p className="input-label mb-2">Food & Non-Alcoholic Beverages</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(FoodAndNonAlcoholicBeverages)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Food And NonAlcoholic Beverages"
                          value={checkIsFinite(FoodAndNonAlcoholicBeverages)}
                          onChange={(e) => {
                            setFoodAndNonAlcoholicBeverages(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="HouseholdFurnishingsAndEquipment">
                        <p className="input-label mb-2"> Household Furnishings & Equipment</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(HouseholdFurnishingsAndEquipment)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Household Furnishings And Equipment"
                          value={checkIsFinite(HouseholdFurnishingsAndEquipment)}
                          onChange={(e) => {
                            setHouseholdFurnishingsAndEquipment(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup
                        className="mb-3"
                        controlId="HouseholdServicesAndOperation"
                      >
                        <p className="input-label mb-2">Household Services & Operation</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(
                                HouseholdServicesAndOperation
                              )
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Household Services And Operation"
                          value={checkIsFinite(
                            HouseholdServicesAndOperation
                          )}
                          onChange={(e) => {
                            setHouseholdServicesAndOperation(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                </>
              )}
              {!uaaae && (
                <>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="HealthFitness">
                        <p className="input-label mb-2">Medical Care & Health Expenses</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(HealthFitness)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Health Fitness"
                          value={checkIsFinite(HealthFitness)}
                          onChange={(e) => {
                            setHealthFitness(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="PersonalCare">
                        <p className="input-label mb-2">Personal Care</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(PersonalCare)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Personal Care"
                          value={checkIsFinite(PersonalCare)}
                          onChange={(e) => {
                            setPersonalCare(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="Recreation">
                        <p className="input-label mb-2">Recreation</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(Recreation)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Recreation"
                          value={checkIsFinite(Recreation)}
                          onChange={(e) => {
                            setRecreation(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                </>
              )}
              {!uaaae && (
                <>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="Travel">
                        <p className="input-label mb-2">Transport</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(Travel)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Travel"
                          value={checkIsFinite(Travel)}
                          onChange={(e) => {
                            setTravel(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>{" "}
                      <FormGroup className="mb-3" controlId="AccountantFees">
                        <p className="input-label mb-2">Accountant Fees</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(AccountantFees)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Accountant Fees"
                          value={checkIsFinite(AccountantFees)}
                          onChange={(e) => {
                            setAccountantFees(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="BodyCorporatePayments">
                        <p className="input-label mb-2">Body Corporate Payments</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(BodyCorporatePayments)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Body Corporate Payments"
                          value={checkIsFinite(BodyCorporatePayments)}
                          onChange={(e) => {
                            setBodyCorporatePayments(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="CashGiftsAndDonations">
                        <p className="input-label mb-2">Cash Gifts, Donations To Charity Nec</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(CashGiftsAndDonations)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Cash Gifts And Donations"
                          value={checkIsFinite(CashGiftsAndDonations)}
                          onChange={(e) => {
                            setCashGiftsAndDonations(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="FeesNecGovernment">
                        <p className="input-label mb-2">Fees Nec - Government</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(FeesNecGovernment)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Fees Nec Government"
                          value={checkIsFinite(FeesNecGovernment)}
                          onChange={(e) => {
                            setFeesNecGovernment(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="FeesNecPrivate">
                        <p className="input-label mb-2">Fees Nec - Private</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(FeesNecPrivate)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Fees Nec Private"
                          value={checkIsFinite(FeesNecPrivate)}
                          onChange={(e) => {
                            setFeesNecPrivate(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="FinancialInstitutionCharges">
                        <p className="input-label mb-2">Financial Institution Charges</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(FinancialInstitutionCharges)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Financial Institution Charges"
                          value={checkIsFinite(FinancialInstitutionCharges)}
                          onChange={(e) => {
                            setFinancialInstitutionCharges(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="HireServicesNec">
                        <p className="input-label mb-2">Hire Services Nec</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(HireServicesNec)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Hire Services Nec"
                          value={checkIsFinite(HireServicesNec)}
                          onChange={(e) => {
                            setHireServicesNec(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="InsuranceNec">
                        <p className="input-label mb-2">Insurance Nec</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(InsuranceNec)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Insurance Nec"
                          value={checkIsFinite(InsuranceNec)}
                          onChange={(e) => {
                            setInsuranceNec(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="LoansCreditCards">
                        <p className="input-label mb-2">Miscellaneous Goods Nec</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(MiscellaneousGoodsNec)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Miscellaneous Goods Nec"
                          value={checkIsFinite(MiscellaneousGoodsNec)}
                          onChange={(e) => {
                            setMiscellaneousGoodsNec(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="MiscellaneousGoodsNfd">
                        <p className="input-label mb-2">Miscellaneous Goods Nfd</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(MiscellaneousGoodsNfd)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Miscellaneous Goods Nfd"
                          value={checkIsFinite(MiscellaneousGoodsNfd)}
                          onChange={(e) => {
                            setMiscellaneousGoodsNfd(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="MiscellaneousServicesNec">
                        <p className="input-label mb-2">Miscellaneous Services Nec</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(MiscellaneousServicesNec)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Miscellaneous Services Nec"
                          value={checkIsFinite(MiscellaneousServicesNec)}
                          onChange={(e) => {
                            setMiscellaneousServicesNec(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="NonHolidayAccommodation">
                        <p className="input-label mb-2">Non-Holiday Accommodation</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(NonHolidayAccommodation)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Other"
                          value={checkIsFinite(NonHolidayAccommodation)}
                          onChange={(e) => {
                            setNonHolidayAccommodation(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="StationeryEquipmentnfd">
                        <p className="input-label mb-2">Stationery Equipment Nfd</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(StationeryEquipmentnfd)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Stationery Equipmentn fd"
                          value={checkIsFinite(StationeryEquipmentnfd)}
                          onChange={(e) => {
                            setStationeryEquipmentnfd(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="TravelGoods">
                        <p className="input-label mb-2">Travel goods, handbags & related</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(TravelGoods)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Travel Goods"
                          value={checkIsFinite(TravelGoods)}
                          onChange={(e) => {
                            setTravelGoods(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                   
                      <FormGroup className="mb-3" controlId="cashWithdrawals">
                        <p className="input-label mb-2">Cash Withdrawls</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(cashWithdrawals)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Cash Withdrawals"
                          value={checkIsFinite(cashWithdrawals)}
                          onChange={(e) => {
                            setcashWithdrawals(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="BankFees">
                        <p className="input-label mb-2">Bank Fees</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(BankFees)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Bank Fees"
                          value={checkIsFinite(BankFees)}
                          onChange={(e) => {
                            setBankFees(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="UnionDues">
                        <p className="input-label mb-2">Union dues, professional association subscriptions</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(UnionDues)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Union Dues"
                          value={checkIsFinite(UnionDues)}
                          onChange={(e) => {
                            setUnionDues(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      </div>
                      <div className="d-flex flex-wrap align-items-center gap-3">
                      <FormGroup className="mb-3" controlId="loanInterests">
                        <p className="input-label mb-2">Loan Interest</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(loanInterests)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="loan Interests"
                          value={checkIsFinite(loanInterests)}
                          onChange={(e) => {
                            setloanInterests(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="externalTransfers">
                        <p className="input-label mb-2">External Transfers</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(externalTransfers)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="external Transfers"
                          value={checkIsFinite(externalTransfers)}
                          onChange={(e) => {
                            setexternalTransfers(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="OtherExpenses">
                        <p className="input-label mb-2">Other Payment</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              checkIsFinite(OtherExpenses)
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="OtherExpenses"
                          value={checkIsFinite(OtherExpenses)}
                          onChange={(e) => {
                            setOtherExpenses(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                        </div>
                </>
              )}
              {uaaae && (
                <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="OtherExpenses">
                    <p className="input-label mb-2">Other</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          OtherExpenses
                        )}`)
                      }
                      className="input-field mr-3 mb-1"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      placeholder="Other"
                      value={OtherExpenses}
                      onChange={(e) => {
                        setOtherExpenses(e.value);
                        OnChange();
                      }}
                    />
                  </FormGroup>
                </div>
              )}
            </CollapseCard>
          </Form>
          <div className="filters-container my-3 d-flex flex-wrap justify-content-end align-items-center">
            <span
              onClick={prevBtnNavigation}
              className={`prevNavigationBtn ${
                ScrollPosition >= 180 ? "active-img" : ""
              }`}
            >
              <img src={ArrowIcon} alt="" />
            </span>
            <div
              onClick={() => ApplyFilter("ByMonth")}
              className={FilterBy === "ByMonth" ? "filter-active" : ""}
            >
              By Month <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Expenses")}
              className={FilterBy === "Expenses" ? "filter-active" : ""}
            >
              Expenses <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Income")}
              className={FilterBy === "Income" ? "filter-active" : ""}
            >
              Income <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Liabilties")}
              className={FilterBy === "Liabilties" ? "filter-active" : ""}
            >
              Liabilties <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Cash")}
              className={FilterBy === "Cash" ? "filter-active" : ""}
            >
              Cash
              <img src={FilterIcon} alt="" />
            </div>
            <span
              onClick={nextBtnNavigation}
              className={`navigationBtn ${
                ScrollPosition <= TableRef?.current?.scrollWidth
                  ? "active-img"
                  : ""
              }`}
            >
              <img src={ArrowIcon} alt="" />
            </span>
          </div>

          {Data?.length > 0 && (
            <>
              <div className="d-flex">
                <div
                  className={`table-containers ${
                    ScrollPosition >= 120 ? "leftPadding" : ""
                  }`}
                  ref={TableRef}
                  onScroll={handleLowerScroll}
                >
                  <StrategicSpendingTableColumn
                    ShowPrinciples={ShowPrinciples}
                    setShowPrinciples={setShowPrinciples}
                    ShowIncome={ShowIncome}
                    setShowIncome={setShowIncome}
                    ShowLiabilities={ShowLiabilities}
                    setShowLiabilities={setShowLiabilities}
                    ShowLivingExpenses={ShowLivingExpenses}
                    setShowLivingExpenses={setShowLivingExpenses}
                    TableRef={TableRef}
                    ScrollPosition={ScrollPosition}
                    isSideBar={true}
                    FilterBy={FilterBy}
                    LiabilitiesColumns={LiabilitiesColumns}
                    MonthlyCashFlowColumns={MonthlyCashFlowColumns}
                    IncomeColums={IncomeColums}
                    ExpensesColumns={ExpensesColumns}
                    BalanceSheetColumns={BalanceSheetColumns}
                    CashColumns={CashColumns}
                    PrincipleColumns={PrincipleColumns}
                    AsutralianAverageExpensesColumn={
                      AsutralianAverageExpensesColumn
                    }
                    uaaae={uaaae}
                    Editable={Editable}
                    setEditable={setEditable}
                    OnChange={() => OnChange()}
                  />

                  {Data &&
                    Data?.map((data, idx) => (
                      <StrategicSpendingTableColumn
                        ShowPrinciples={ShowPrinciples}
                        setShowPrinciples={setShowPrinciples}
                        ShowIncome={ShowIncome}
                    setShowIncome={setShowIncome}
                    ShowLiabilities={ShowLiabilities}
                    setShowLiabilities={setShowLiabilities}
                    ShowLivingExpenses={ShowLivingExpenses}
                    setShowLivingExpenses={setShowLivingExpenses}
                        PrincipleData={PrincipleData}
                        EquityGains={EquityGains}
                        updateEquityGains={updateEquityGains}
                        HECSPayments={HECSPayments}
                        setHECSPayments={setHECSPayments}
                        ScrollPosition={ScrollPosition}
                        uaaae={uaaae}
                        FilterBy={FilterBy}
                        Editable={Editable}
                        setEditable={setEditable}
                        balanceSheet={balanceSheet}
                        ExtraCash={ExtraCash}
                        updateExtraCash={updateExtraCash}
                        setBalanceSheet={setBalanceSheet}
                        setCashOnHand={setCashOnHand}
                        cashOnHand={cashOnHand}
                        accountYour={accountYour}
                        setAccountYour={setAccountYour}
                        accountPartner={accountPartner}
                        setAccountPartner={setAccountPartner}
                        liabilities={liabilities}
                        setLiabilities={setLiabilities}
                        creditCardYours={creditCardYours}
                        setCreditCardYours={setCreditCardYours}
                        creditCardPartner={creditCardPartner}
                        setCreditCardPartner={setCreditCardPartner}
                        monthlyCashflow={monthlyCashflow}
                        PPRLoanColumns={PPRLoanColumns}
                        setMonthlyCashflow={setMonthlyCashflow}
                        income={income}
                        setIncome={setIncome}
                        setOtherIncome={setOtherIncome}
                        OtherIncome={OtherIncome}
                        netSalaryYour={netSalaryYour}
                        setNetSalaryYour={setNetSalaryYour}
                        netSalaryPartner={netSalaryPartner}
                        setNetSalaryPartner={setNetSalaryPartner}
                        rentalIncome={rentalIncome}
                        setRentalIncome={setRentalIncome}
                        expenses={expenses}
                        setExpenses={setExpenses}
                        BillsUtilities={BillsUtilities}
                        setBillsUtilities={setBillsUtilities}
                        
                       
                       
                       //Expenses
                        PrimaryResidence={34}
                        setPrimaryResidence={setPrimaryResidence}
                        AlcoholicBeverages={AlcoholicBeverages}
                        setAlcoholicBeverages={setAlcoholicBeverages}
                        ClothingAndFootwear={ClothingAndFootwear}
                        setClothingAndFootwear={setClothingAndFootwear}
                        Communication={Communication}
                        setCommunication={setCommunication}
                        CurrentHousingCosts={CurrentHousingCosts}
                        setCurrentHousingCosts={setCurrentHousingCosts}
                        DomesticFuelAndPower={DomesticFuelAndPower}
                        setDomesticFuelAndPower={setDomesticFuelAndPower}
                        FoodAndNonAlcoholicBeverages={FoodAndNonAlcoholicBeverages}
                        setFoodAndNonAlcoholicBeverages={setFoodAndNonAlcoholicBeverages}
                        HouseholdFurnishingsAndEquipment={HouseholdFurnishingsAndEquipment}
                        setHouseholdFurnishingsAndEquipment={setHouseholdFurnishingsAndEquipment}
                        HouseholdServicesAndOperation={HouseholdServicesAndOperation}
                        setHouseholdServicesAndOperation={setHouseholdServicesAndOperation}
                        HealthFitness={HealthFitness}
                        setHealthFitness={setHealthFitness}
                        PersonalCare={PersonalCare}
                        setPersonalCare={setPersonalCare}
                        Recreation={Recreation}
                        setRecreation={setRecreation}
                        Travel={Travel}
                        setTravel={setTravel}
                        AccountantFees={AccountantFees}
                        setAccountantFees={setAccountantFees}
                        BodyCorporatePayments={BodyCorporatePayments}
                        setBodyCorporatePayments={setBodyCorporatePayments}
                        CashGiftsAndDonations={CashGiftsAndDonations}
                        setCashGiftsAndDonations={setCashGiftsAndDonations}
                        FeesNecGovernment={FeesNecGovernment}
                        setFeesNecGovernment={setFeesNecGovernment}
                        FeesNecPrivate={FeesNecPrivate}
                        setFeesNecPrivate={setFeesNecPrivate}
                        FinancialInstitutionCharges={FinancialInstitutionCharges}
                        setFinancialInstitutionCharges={setFinancialInstitutionCharges}
                        HireServicesNec={HireServicesNec}
                        setHireServicesNec={setHireServicesNec}
                        InsuranceNec={InsuranceNec}
                        setInsuranceNec={setInsuranceNec}
                        MiscellaneousGoodsNec={MiscellaneousGoodsNec}
                        setMiscellaneousGoodsNec={setMiscellaneousGoodsNec}
                        MiscellaneousGoodsNfd={MiscellaneousGoodsNfd}
                        setMiscellaneousGoodsNfd={setMiscellaneousGoodsNfd}
                        MiscellaneousServicesNec={MiscellaneousServicesNec}
                        setMiscellaneousServicesNec={setMiscellaneousServicesNec}
                        NonHolidayAccommodation={NonHolidayAccommodation}
                        setNonHolidayAccommodation={setNonHolidayAccommodation}
                        StationeryEquipmentnfd={StationeryEquipmentnfd}
                        setStationeryEquipmentnfd={setStationeryEquipmentnfd}
                        TravelGoods={TravelGoods}
                        setTravelGoods={setTravelGoods}
                        UnionDues={UnionDues}
                        setUnionDues={setUnionDues}
                        cashWithdrawals={cashWithdrawals}
                        setcashWithdrawals={setcashWithdrawals}
                        BankFees={BankFees}
                        setBankFees={setBankFees}
                        loanInterests={loanInterests}
                        setloanInterests={setloanInterests}
                        externalTransfers={externalTransfers}
                        setexternalTransfers={setexternalTransfers}
                        Education={Education}
                        setEducation={setEducation}
                        setOtherExpenses={setOtherExpenses}
                        OtherExpenses={OtherExpenses}


                        PersonalExpenses={PersonalExpenses}
                        setPersonalExpenses={setPersonalExpenses}
                        InvestmentExpenses={InvestmentExpenses}
                        setInvestmentExpenses={setInvestmentExpenses}
                        EquityGain={EquityGain}
                        setEquityGain={setEquityGain}
                        CreditCards={CreditCards}
                        updateCreditCard={updateCreditCard}
                        key={idx}
                        data={data}
                        OnChange={() => OnChange()}
                      />
                    ))}
                </div>
                <FloatingBox
                  style={{
                    top: ScrollPosition2,
                    marginTop: "-63px",
                    minWidth: "20%",
                  }}
                >
                  <p  className={`${classes.itemTitle} mt-4`}>Cash on Hand</p>
                  <p className="itemValue">
                    {CurrencyFormatter.format(
                      (Data?.length > 0 &&
                        Data[currentColumn < 0 ? 0 : currentColumn]
                          ?.balanceSheet.cashOnHand.total) ||
                        cashOnHand
                    )}{" "}
                    -{" "}
                    {(Data?.length > 0 &&
                      Data[currentColumn < 0 ? 0 : currentColumn]?.date.replace(
                        /-/g,
                        " "
                      )) ||
                      ""}
                  </p>
                  <p className={`${classes.itemTitle} mt-4`}>Equity Gained</p>
                  <p className="itemValue">
                    {CurrencyFormatter.format(
                      (Data?.length > 0 &&
                        Data[currentColumn < 0 ? 0 : currentColumn]
                          ?.balanceSheet.EquityGain) ||
                        EquityGain
                    )}{" "}
                    -{" "}
                    {(Data?.length > 0 &&
                      Data[currentColumn < 0 ? 0 : currentColumn]?.date.replace(
                        /-/g,
                        " "
                      )) ||
                      ""}
                  </p>
                  <div className={`mt-4 ${classes.ResultBox}`}>
                    <p  className={`${classes.itemTitle}`}>
                      Cash on Hand + Equity Gained
                    </p>
                    <p className="itemValue">
                      {CurrencyFormatter.format(
                        (Data?.length > 0 &&
                          Data[currentColumn < 0 ? 0 : currentColumn]
                            ?.balanceSheet.cashOnHand.total) +
                          ((Data?.length > 0 &&
                            Data[currentColumn < 0 ? 0 : currentColumn]
                              ?.balanceSheet.EquityGain) ||
                            EquityGain) +
                          (currentColumn == 0 ? 0 : GetEquityGainsTotalVal()) ||
                          0
                      )}
                    </p>
                  </div>
                </FloatingBox>
              </div>

              <Row className="mt-4 Gradient-Box w-80 d-flex justify-content-between">
                <Col md={4} lg={4}>
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="text-left">
                      <p className="strong-text my-1"></p>
                      <p className="my-1">Total Income</p>
                      <p className="my-1">Total Liabilities</p>
                      <p className="my-1">Total Expenses</p>
                      <p className="my-1">Net Personal Income </p>
                    </div>
                    <div className="text-right">
                      <p className="my-1">{CurrencyFormatter.format(income)}</p>
                      <p className="my-1">
                        {CurrencyFormatter.format(liabilities)}
                      </p>
                      <p className="my-1">
                        {CurrencyFormatter.format(expenses)}
                      </p>
                      <p className="my-1">
                        {CurrencyFormatter.format(
                          income - (liabilities + expenses)
                        )}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  className="net-personal-icome-saving-container"
                  md={4}
                  lg={4}
                >
                  <Row className="net-personal-icome-saving bg-white">
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <div>
                        <p className="strong-text">Cash On Hand</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <div className="text-right">
                        <p>{CurrencyFormatter.format(cashOnHand)}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* {window.scrollY > 340 && (
        <GradientButton
          txt="Next"
          style={{
            position: "fixed",
            bottom: "10px",
            right: "28px",
           
          }}
          // onClick={updateProfile}
        />
      )} */}
              <div className="button-container">
                <GradientButton
                  style={{
                    position: "fixed",
                    bottom: "31px",
                    right: "94px",
                    width:"89px !important"
                  }}
                  width="89px"
                  onClick={async () => {
                    await updateStrategicSpending();
                    GS.SOT.Progress = { steps: 4, completed: 2, current: 3 };
                    const updateData = [...state];
                    const i = updateData.findIndex(
                      (vendor) => vendor._id === vendorid
                    );
                    updateData[i] = {
                      ...updateData[i],
                      Progress: [GS.SOT.Progress],
                    };
                    dispatch(Vendors(updateData));
                    publish("PropertyActivate", null);
                    setActiveIndex(3);
                    if ((accountType != "Contacts") || (accountType != "Contact Lite")) {
                      dispatch(setGenericData({ ActiveProperty: null }));
                    }
                    // Navigate(`/Clients/Finances?id=${vendorid}`);
                    Navigate(`/Clients/Summary?id=${vendorid}`);
                  }}
                  txt="Next"
                />
                <GradientButton
                  style={{
                    position: "fixed",
                    bottom: "31px",
                    right: "196px",
                    width:"89px !important"
                  }}
                  width="89px"
                  onClick={() => {
                    // Navigate(-1);
                    onBack();
                  }}
                  txt="Back"
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default StrategicSpending;
