import { GS } from "../services/global";
import { API } from "../services/api";

const SeparateCamelCase = (text = "") => {
  return text?.replace(/([a-z])([A-Z])/g, "$1 $2");
};
export const getMonthName = (i) => {
  var month = new Array(12);
  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sep";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  return month[i];
};
export const  ContainsLetter=(str)=> {
  var pattern = /[a-zA-Z]/; // Regular expression pattern to match any letter

  return pattern.test(str);
}
export const  ContainsNumber=(str)=> {
  var pattern = /[0-9]/; // Regular expression pattern to match any letter

  return pattern.test(str);
}
const parseErrors = (errObj) => {
  try {
    let message = "";
    switch (errObj.status) {
      case 401:
        if (!window.location.href.includes("login")) {
          sessionStorage.removeItem("Token");
          window.location.href = "/";
        }
        return {
          success: false,
          message:
            errObj.data && errObj.data.message
              ? errObj.data.message
              : "You are not authorized. Please login",
        };
      case 403:
      case 404:
      case 409:
      case 422:
        if (errObj.data && errObj.data.message) {
          message = errObj.data.message;
        } else if (errObj.message) {
          message = errObj.message;
        } else {
          message = "An error occured while processing your request.";
        }
        return {
          success: false,
          message,
        };

      default:
        return {
          success: false,
          message: "An error occured while processing your request.",
        };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occured while processing your request.",
    };
  }
};

let CurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const ValidateName = (name) => {
  if (name.length === 0) {
    return false;
  } else {
    return true;
  }
};
const ValidateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    //eslint-disable-line
    return true;
  }

  return false;
};

const validateImage = (img) => {
  const base64regex = new RegExp("/[A-Za-z0-9+/=]/");
  if (base64regex.test(img)) {
    return true;
  } else {
    return false;
  }
};

const commaSeparators = (num) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }).format(num || 0); 
};
const commaStringSeparators = (num) => {
  return new Intl.NumberFormat("en-US").format(num || 0);
};
const checkPasswordStrength = (password) => {
  let count = 0;

 if(ContainsLetter(password)){
  count++
 }
 if(ContainsNumber(password)){ 
  count++
 }
 if(password.length>=8){
  count++;
 }
 if(count==3){
  return {valid:count==3,message:""}
 }else{
 if(password.length==0){
  return {valid:count==3,message:""}
 }else{
  return {valid:count==3,message:"Weak Password"}
 }
 }
 

};

export  function getBase64ImageType(base64String) {
  const header = base64String.substring(0, 30); // Only examine the first 30 characters of the string
  if (header.includes('data:image/jpeg;base64,')) {
    return 'jpeg';
  } else if (header.includes('data:image/png;base64,')) {
    return 'png';
  } else {
    throw new Error('Unsupported image type');
  }
}
const ChangeStatus = async () => {
  if (GS.SOT.Progress.completed === 5 || GS.SOT.Progress.completed === 6) {
    GS.SOT.Progress.completed = 4;
  }
  await API.AutoSave(window.location.href);
  return GS.SOT.Progress.completed;
};
const PMT = (rate, nper, pv, fv, type) => {
  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate === 0) return -(pv + fv) / nper;

  var pvif = Math.pow(1 + rate, nper);
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type === 1) {
    pmt /= 1 + rate;
  }

  return pmt;
};
const CalculateIMPT = (rate, per, nper, pv, fv, type) => {
  if (per < 1 || per >= nper + 1) return null;
  var pmt = PMT(rate, nper, pv, fv, type);
  var ipmt = IPMT(pv, pmt, rate, per - 1);
  return ipmt;
};
const IPMT = (pv, pmt, rate, per) => {
  var tmp = Math.pow(1 + rate, per);
  return 0 - (pv * tmp * rate + pmt * (tmp - 1));
};
const PPMT = (rate, per, nper, pv, fv, type) => {
  if (per < 1 || per >= nper + 1) return null;
  var pmt = PMT(rate, nper, pv, fv, type);
  var ipmt = IPMT(pv, pmt, rate, per - 1);
  
  return pmt - ipmt;
};
const PMTCalculate = (rate, per, nper, pv, fv, type) => {
  var pmt = PMT(rate, nper, pv, fv, type);
  return pmt;
};
const calculatePrincipalPayment=(rate, per, nper, pv, fv, type)=> {
  var pmt = PPMT(rate, per, nper, pv, fv, type);
  console.log(pmt,"pmt")
  return Math.round(pmt);
}
const getRate = (loan, LVR) => {
  if (loan < 300000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.48;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.49;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.6;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.66;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.73;
    } else if (LVR > 85 && LVR <= 86) {
      return 0.88;
    } else if (LVR > 86 && LVR <= 87) {
      return 0.93;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.06;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.3;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.46;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.01;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.01;
    } else if (LVR > 92 && LVR <= 93) {
      return 2.33;
    } else if (LVR > 93 && LVR <= 94) {
      return 2.38;
    } else if (LVR > 94 && LVR <= 95) {
      return 2.61;
    }
  } else if (loan === 300000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.48;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.49;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.6;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.66;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.73;
    } else if (LVR > 85 && LVR <= 86) {
      return 0.88;
    } else if (LVR > 86 && LVR <= 87) {
      return 0.93;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.06;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.3;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.46;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.01;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.01;
    } else if (LVR > 92 && LVR <= 93) {
      return 2.33;
    } else if (LVR > 93 && LVR <= 94) {
      return 2.38;
    } else if (LVR > 94 && LVR <= 95) {
      return 2.61;
    }
  } else if (loan > 300000 && loan <= 500000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.57;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.57;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.7;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.83;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.97;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.08;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.15;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.31;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.62;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.87;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.62;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.67;
    } else if (LVR > 92 && LVR <= 93) {
      return 3.03;
    } else if (LVR > 93 && LVR <= 94) {
      return 3.03;
    } else if (LVR > 94 && LVR <= 95) {
      return 3.35;
    }
  } else if (loan > 500000 && loan <= 600000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.9;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.9;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.93;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.96;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.17;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.26;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.41;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.46;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.95;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.18;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.51;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.57;
    } else if (LVR > 92 && LVR <= 93) {
      return 3.8;
    } else if (LVR > 93 && LVR <= 94) {
      return 3.8;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.0;
    }
  } else if (loan > 600000 && loan <= 750000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.9;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.9;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.09;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.09;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.33;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.41;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.63;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.63;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.22;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.37;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.78;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.87;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.08;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.29;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.61;
    }
  } else if (loan > 750000 && loan <= 1000000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.91;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.91;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.11;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.15;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.41;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.46;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.73;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.75;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.4;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.52;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.82;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.93;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.16;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.32;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.6;
    }
  } else if (loan > 1000000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.91;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.91;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.11;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.15;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.41;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.46;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.73;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.75;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.4;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.52;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.82;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.93;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.16;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.32;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.6;
    }
  }
};
export const stampDutyWA = (pp) => {
  let fee = 0;
  if (pp > 725000) {
    fee = 28453 + 0.0515 * (pp - 725000);
  } else if (pp > 360000) {
    fee = 11115 + 0.0475 * (pp - 360000);
  } else if (pp > 150000) {
    fee = 3135 + 0.038 * (pp - 150000);
  } else if (pp > 120000) {
    fee = 2280 + 0.0285 * (pp - 120000);
  } else if (pp > 1) {
    fee = 0.019 * (pp - 1);
  }
  fee += pp * 0.15/100;
  return fee;
};
export const getDifference = (date) => {
  const yearDifference =
    new Date(date).getFullYear() - new Date().getFullYear();
  const monthDifference = new Date(date).getMonth() - new Date().getMonth();
  return yearDifference;
};
export const stampDutyQLD = (pp) => {
  let result;

  if (pp > 1000000) {
   
    result = 38025 + 0.0575 * (pp - 1000000);
  } else if (pp > 540000) {
    result = 17325 + 0.045 * (pp - 540000);
  } else if (pp > 75000) {
    result = 1050 + 0.035 * (pp - 75000);
  } else if (pp > 5000) {
    result = 0.015 * (pp - 5000);
  } else {
    result = 0;
  }
  return result + (pp * 0.3) / 100;
};
export const stampDutyVIC = (pp) => {
  let result;
  if (pp > 2000000) {
    result = 110000 + 0.065 * (pp - 2000000);
  } else if (pp > 960000) {
    result = 0.055 * (pp - 319000);
  } else if (pp > 130000) {
    result = 2870 + 0.06 * (pp - 130000);
  } else if (pp > 25000) {
    result = 350 + 0.024*(pp - 25000);
  } else if (pp > 1) {
    result = 0.014 * (pp - 1);
  } else {
    result = 0;
  }
  return result + (pp * 0.3) / 100;
};
export const stampDutySA = (pp) => {
  
  let result;
  if (pp > 500000) {
    result = 21330 + 0.055 * (pp - 500000);
  } else if (pp > 300000) {
    result = 11330 + 0.05 * (pp - 300000);
  } else if (pp > 250000) {
    result = 8955 + 0.0475 * (pp - 250000);
  } else if (pp > 200000) {
    result = 6830 + 0.0425 * (pp - 200000);
  } else if (pp > 100000) {
    result = 2830 + 0.04 * (pp - 100000);
  } else if (pp > 50000) {
    result = 1080 + 0.035 * (pp - 50000);
  }  else if (pp >= 30000) {
    result = 760 + 0.035 * (pp - 3000);
  } 
  else if (pp < 30000) {
    result = 480 + 0.03 * (pp - 30000);
  } else if (pp < 12000) {
    result = 120 + 0.02 * (pp - 12000);
  }
  return result + (pp * 0.95) / 100;
};

export const stampDutyNSW = (pp) => {
  let value = 0;

  if (pp > 31940000) {
    value = 160237 + 0.07 * (pp - 31940000);
  } else if (pp > 1064000) {
    value = 43087 + 0.055 * (pp - 1064000);
  } else if (pp > 319000) {
    value = 9562 + 0.045 * (pp - 319000);
  } else if (pp > 85000) {
    value = 1372 + 0.035 * (pp - 85000);
  } else if (pp > 32000) {
    value = 445 + 0.0175 * (pp - 32000);
  } else if (pp > 14000) {
    value = 175 + 0.015 * (pp - 14000);
  }

  return value + (pp * 1) / 1000;
};
export const getFieldByPath = (object, fieldParts) => {
  let value = object;
  for (const part of fieldParts) {
    if (value && value.hasOwnProperty(part)) {
      value = value[part];
    } else {
      return undefined; 
    }
  }

  return value;
};
export const HandleFilter = (field, sortOrder,TableData) => {
  const filteredResult = TableData;
  const fieldParts = field.split(".");
  if (fieldParts.length > 1) {
    filteredResult.sort((a, b) => {
      const fieldA = getFieldByPath(a, fieldParts);
      const fieldB = getFieldByPath(b, fieldParts);

      if (sortOrder === 1) {
        return fieldA > fieldB ? 1 : -1;
      } else if (sortOrder === -1) {
        return fieldA < fieldB ? 1 : -1;
      }

      return 0; // If sortOrder is not 1 or -1, maintain the current order
    });
  } else {
    if (sortOrder === 1) {
      filteredResult.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    } else if (sortOrder === -1) {
      filteredResult.sort((a, b) => (a[field] < b[field] ? 1 : -1));
    }
  }

  return filteredResult
  // setFilterData(filteredResult);
};
export {
  CurrencyFormatter,
  SeparateCamelCase,
  commaSeparators,
  commaStringSeparators,
  parseErrors,
  ValidateEmail,
  validateImage,
  ValidateName,
  PMTCalculate,
  PPMT,
  CalculateIMPT,
  ChangeStatus,
  getRate,
  checkPasswordStrength,
  calculatePrincipalPayment
};
