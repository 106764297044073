import { useState,useRef,useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/api";
import { Vendors, getMedianPrice, setMedianData } from "../../redux/actions";
import { Link } from "react-router-dom";
import classes from "./stylesheet.module.css";
import { Toast } from "primereact/toast";
const resiRegions = [
  { name: "Playford", state: "SA" },
  { name: "Bundaberg", state: "QLD" },
  { name: "Onkaparinga", state: "SA" },
  { name: "Tamworth", state: "NSW" },
  { name: "Ipswich", state: "QLD" },
  { name: "Geelong", state: "VIC" },
  { name: "Gladstone", state: "QLD" },
  { name: "Rockhampton", state: "QLD" },
  { name: "Toowoomba", state: "QLD" },
  { name: "Onkaparinga", state: "QLD" },
];
export default function SignInForm() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [ShowPassword, setShowPassword] = useState(false);
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resToken, setResToken] = useState(null);
  const [resAccessToken, setAccessToken] = useState(null);
  const[verifyEmail,setVerifyEmail]=useState(false);
  const[otp,setotp]=useState("") 
  const[otp2,setotp2]=useState();
  const [accountType,setaccountType]=useState()
  const[userId,setuserId]=useState()
    const toast = useRef(null);
  const [Agree, setAgree] = useState(
    localStorage.getItem("Agree") ? true : false
  );

  const getVendors = async () => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(`contacts?office_id=${user_id}`);
   
    (async () => {
      // try {
      // const { data, offices, other, retail, warehouse } = await API.get(
      //   "pricefinder/suburb/statistics"
      // );

      const regionsData = await API.get(`median/suburb/all`);  //median/regions/all median/suburb/all
    
     
      //All data within all reigon
      const retailregionData = await API.get(
        `pricefinder/comm/median-prices/Retail`
      ); 
      
      const officeregionData = await API.get(
        `pricefinder/comm/median-prices/Offices`
      );
      console.log( officeregionData," officeregionData")
     let url = `pricefinder/suburb/median-prices?type=Residential&`;
      for (let rg of resiRegions) {
        url += `regions[]=` + rg.name + `__` + rg.state + `&`;
      }
      const residentialregionData = await API.get(url);

      
      const resdata = regionsData?.data?.find(
        (i) => i.propertyType === "Residential"
      ).regions;
      const officeData = regionsData?.data?.find(
        (i) => i.propertyType === "Offices"
      ).regions;
      const retailData = regionsData?.data?.find(
        (i) => i.propertyType === "Retail"
      ).regions;
      // const others = regionsData?.data?.find(
      //   (i) => i.propertyType === "Other"
      // ).regions;
      // const industrial = regionsData?.data?.find(
      //   (i) => i.propertyType === "Industrial Warehouse"
      // ).regions;
      let residentialdata = [];
      let officedata = [];
      // let otherdata = [];
      // let industrialdata = [];
      let retaildata = [];

      // for (let i = 0; i < 10; i++) {
      //   const res = resdata[i];
      //   const ret = retailData[i];
      //   const off = officeData[i];
      //   const other = others[i];
      //   const ind = industrial[i];

      //   if (res && res.median > 0) {
      //     residentialdata.push({
      //       ...res,
      //       Suburb_Name: "",
      //       region: res.name,
      //       State: res.state,
      //       Median_Sale_Price: res.median,
      //       Median_Rental_Price: res.medianRental,
      //       postcode: res.postcode,
      //     });
      //   }
      //   if (ret && ret.median > 0) {
      //     retaildata.push({
      //       ...ret,
      //       Suburb_Name: "",
      //       region: ret.name,
      //       State: ret.state,
      //       Median_Sale_Price: ret.median,
      //       Median_Rental_Price: ret.medianRental,
      //       postcode: ret.postcode,
      //     });
      //   }

      //   if (off && off.median > 0) {
      //     officedata.push({
      //       ...off,
      //       Suburb_Name: "",
      //       region: off.name,
      //       State: off.state,
      //       Median_Sale_Price: off.median,
      //       Median_Rental_Price: off.medianRental,
      //       postcode: off.postcode,
      //     });
      //     if (other && other.median > 0) {
      //       otherdata.push({
      //         ...other,
      //         Suburb_Name: "",
      //         region: other.name,
      //         State: other.state,
      //         Median_Sale_Price: other.median,
      //         Median_Rental_Price: other.medianRental,
      //         postcode: other.postcode,
      //       });
      //     }
      //     if (ind && ind.median > 0) {
      //       industrialdata.push({
      //         ...ind,
      //         Suburb_Name: "",
      //         region: ind.name,
      //         State: ind.state,
      //         Median_Sale_Price: ind.median,
      //         Median_Rental_Price: ind.medianRental,
      //         postcode: ind.postcode,
      //       });
      //     }
      //   }
      // }

      for(let i=0;i<resdata.length;i++){
        const res = resdata[i]; 
        if (res && res.median>0) {
          residentialdata.push({
            ...res,
            Suburb_Name: "",
            region: res.name,
            State: res.state,
            Median_Sale_Price: res.median,
            Median_Rental_Price: res.medianRental,
            postcode:res.postcode,
            growthPercent: res.growth_percent,
          });
        }
      }
      for(let i=0;i<officeData.length;i++){
        const off = officeData[i];
        if (off && off.median>0) {
          officedata.push({
            ...off,
            Suburb_Name: "",
            region: off.name,
            State: off.state,
            Median_Sale_Price: off.median,
            Median_Rental_Price: off.medianRental,
            postcode:off.postcode,
            growthPercent: off.growth_percent,

          });
         
        } 
      }
      for(let i=0;i<retailData.length;i++){
        const ret = retailData[i];
        if (ret && ret.median>0) {
          retaildata.push({
            ...ret,
            Suburb_Name: "",
            region: ret.name,
            State: ret.state,
            Median_Sale_Price: ret.median,
            Median_Rental_Price: ret.medianRental,
            postcode:ret.postcode,
            growthPercent: ret.growth_percent,

          });
        }
      }
      // for(let i=0;i<others.length;i++){
      //   const other = others[i];
      //   if (other && other.median>0) {
      //     otherdata.push({
      //       ...other,
      //       Suburb_Name: "",
      //       region: other.name,
      //       State: other.state,
      //       Median_Sale_Price: other.median,
      //       Median_Rental_Price: other.medianRental,
      //       postcode:other.postcode

      //     });
      //   }
      // }
      // for(let i=0;i<industrial.length;i++){
      //   const ind = industrial[i];
      //   if (ind && ind.median>0) {
      //     industrialdata.push({
      //       ...ind,
      //       Suburb_Name: "",
      //       region: ind.name,
      //       State: ind.state,
      //       Median_Sale_Price: ind.median,
      //       Median_Rental_Price: ind.medianRental,
      //       postcode:ind.postcode

      //     });
      //   }
      // }
      const medianPrices = {
        office: officedata,
        // other: otherdata, 
        retail: retaildata,
        // industry: industrialdata,
        residential: residentialdata,
      };
      // console.log(regionsData,"regionsData",medianPrices)
      dispatch(getMedianPrice(medianPrices));
      dispatch(
        setMedianData({
          retail: retailregionData.data,
          office: officeregionData.data,
          residential: residentialregionData.data,
        })
      );
    })();

    dispatch(Vendors(response?.data || []));
  };

  const handleSubmit = async () => {
    localStorage.setItem("Agree", Agree);
    const params = {
      email,
      password,
    };
    setVerifyEmail(true);
    const res = await API.post("auth/login", params);
    console.log(res,"res")
    if (res.access_token) {
      sessionStorage.setItem("accountType", res.accountType);
      sessionStorage.setItem("userId", res.userId);
      setaccountType(res.accountType)
      setuserId(res.userId)
      if(!res.check_otp){
        sessionStorage.setItem("Token", res.access_token);
        localStorage.setItem("email",JSON.stringify(email))
      
      setTimeout(() => getVendors(), 500);
      if (res.accountType === "Contacts" || res.accountType === "Contact Lite" || res.accountType === "Contact Paid") {
        navigation(`/Clients/Profile?id=${res.userId}`);
      } else {
        navigation("/"); 
      }
      }else{
        setotp2(res.otp)
        setAccessToken(res.access_token)
        // setVerifyEmail(true);
        setResToken(""); 
        setTimeout(()=>{
          showSuccess("Email Verification Send")
        },2000)
      }
    } else {
      setResToken(res.message);
    }
  };
  const handleSubmit2=()=>{
    if(otp.length==0){
        setResToken("Enter OTP")
    }else{
      GenerateOtp();
    }
    }
  const GenerateOtp=async()=>{
    const params = {
        email:email,
        password:password,
        otp:otp
      }
      const res = await API.post("auth/generate-otp", params);
      if(res?.status){
        console.log(res)
        sessionStorage.setItem("Token", resAccessToken); 
        setTimeout(() => getVendors(), 500);
        showSuccess(res.message)
        localStorage.setItem("email",JSON.stringify(email))
        if (accountType === "Contacts" || accountType === "Contact Lite" || accountType === "Contact Paid") {
          navigation(`/Clients/Profile?id=${userId}`);
        } else {
          navigation("/");
        }
      }else{
        setResToken(res.message)
      }
      
}
const showSuccess = (Message) => {
  toast.current.show({
    severity: "warn",
    life: 2000,
    content: (
      <div
        className="flex flex-column "
        style={{ paddingTop: "0px", paddingBottom: "0px",width:"100%" }}
      >
        <div className="text-center ">
          
          <p className="popup-warn-text">
            {Message}
          </p>
        </div>
        
      </div>
    ),
  });
};
  return (
    <>
    <Toast ref={toast} />
    {!verifyEmail ?
   <Form
   onSubmit={(e) => {
     e.preventDefault();
     handleSubmit() 
   }}
 >
   <Form.Group className="mb-3" controlId="signinForm.email">
     <Form.Control
       type="email"
       value={email}
       onChange={(e) => setEmail(e.target.value)}
       className={classes.EmailInput}
       placeholder="Email Address"
       required
     />
   </Form.Group>
   <Form.Group className="mb-3" controlId="signinForm.password">
     <InputGroup className={classes.PasswordInputGroup}>
       <Form.Control
         type={ShowPassword ? "text" : "password"}
         value={password}
         onChange={(e) => setPassword(e.target.value)}
         className={classes.PasswordInput}
         placeholder="Password" 
         required
       />
       <InputGroup.Text className={classes.passwordShowHide}>
         <span
           onClick={() => setShowPassword(!ShowPassword)}
           className={classes.showHideBtn}
         >
           {ShowPassword ? "Hide" : "Show"}
         </span>
       </InputGroup.Text>
     </InputGroup>
   </Form.Group>
   <div className="flex justify-content-between mb-3">
   {resToken && <p className={classes.hiddenMsg}>{resToken}</p>}
   {/* <p onClick={()=>{
     
     localStorage.setItem("email",JSON.stringify(email))
     localStorage.setItem("password",JSON.stringify(password))
     if(email.length==0){
       setResToken("email should not be empty")
     }else if(password.length==0){
       setResToken("password should not be empty")
     }else{
       setVerifyEmail(true)
       GenerateOtp();
     }
     
   }} className={classes.link}>
          Verify Email
           </p> */}
   </div>
   
   <Form.Group className="mb-3" controlId="signinForm.checkbox">
     <Form.Check>
       <Form.Check.Input
         type={"checkbox"}
         checked={Agree}
         onChange={() => setAgree(!Agree)}
         className={classes.CheckboxInput}
         required
       />
       <Form.Check.Label className={classes.CheckboxLabel}>
         I agree to all the statements included in the&nbsp;
         <Link to="/Terms" className={classes.link}>
          Terms of use / Privacy policy.
           </Link>
       </Form.Check.Label> 
     </Form.Check>
   </Form.Group>
   {/* <Form.Check.Label className={classes.CheckboxLabel}>
       Note: User Requires Email Verification
         
       </Form.Check.Label>  */}
   <Button className={classes.loginBtn} type="submit">
     Login
   </Button>
 </Form>:
 
 <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit2();
      }}
    >
      <Form.Group className="mb-3" controlId="signinForm.email">
        <Form.Control
          type="text"
          value={otp}
          onChange={(e) => setotp(e.target.value)}
          className={classes.EmailInput}
          placeholder="Enter OTP"
          required
        />
      </Form.Group>
      
      {resToken && <p className={classes.hiddenMsg}>{resToken}</p>}
      <Form.Check.Label className={classes.CheckboxLabel}>
      Please add the verification code sent to your email.
         
       </Form.Check.Label> 
      <Button className={classes.loginBtn} type="submit">
        Submit
      </Button>
    </Form>
  }
   
    </>
  );
}
