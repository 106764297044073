import React, { Component} from 'react';
import { FacebookProvider, CustomChat } from 'react-facebook';

const MessegenerChat = () => {
  return (
    <FacebookProvider appId="1043124436656668" chatSupport>
        <CustomChat pageId="113211171837269" minimized={true} style={{
      Bottom: '50px',
      position: 'relative', // Set the container to relative position
    }}/>
      </FacebookProvider>
  )
}

export default MessegenerChat   

// style={{ marginBottom: '50px' }} className="messenger"