import styled from "styled-components";

export const IncomePlanContainer = styled.div`
  margin-top: 48px;
  padding-bottom: 50px;

  .income-plan-detail {
    display: flex;
    flex-wrap: wrap;
    row-gap: 60px;
    column-gap: 48px;
    justify-content: center;
  }
  .poweredby{
    width:100%;
  img{
    width:150px;
    padding:10px;
  }  }
`;

export const TimelineContainer = styled.div`
  margin-bottom: 20px;
  padding: 85px 35px 48px;
  height: 280px;

  overflow-x: auto;
  
  z-index: 0;
position: relative;
  &::-webkit-scrollbar {
    height: 6px !important;
   
    margin-bottom: 10px;
  }

  
  &::-webkit-scrollbar-track {
    background: #f3f4f8 !important;
  }


  &::-webkit-scrollbar-thumb {
    width: 124px !important;
    max-height: 6px !important;
    border-radius: 30px !important;
    background-color: #666666 !important;
  }

  .timeline {
    width: ${(props) => props.idx > 4 && `${12 * props.idx}%`};
    
    @media (max-width: 1760px) {
      width: ${(props) =>
        props.idx > 4 &&
        `${props.idx > 20 ? 15 * props.idx + 5 : 15 * props.idx}%`};
    }
    @media (max-width: 1600px) {
      width: ${(props) =>
        props.idx > 4 &&
        `${props.idx > 20 ? 17.5 * props.idx + 10 : 17.5 * props.idx}%`};
    }
    @media (max-width: 1425px) {
      width: ${(props) =>
        props.idx > 4 &&
        `${props.idx > 20 ? 22 * props.idx + 10 : 22 * props.idx}%`};
    }
    height: 3px;
    margin: 25px 0 16px 0;
    flex-grow: 0;
    object-fit: cover;
    transform: rotate(-360deg);
    background-image: linear-gradient(
      to right,
      var(--gredient-blue-1) 0%,
      var(--gredient-blue-2) 100%
    );
    background-size: contain;
      
    position: relative;

    &::after {
      content: "";
      height: 3px;
      right: 0;
      top: -3px;
      position: absolute;
      border: solid var(--gredient-blue-2); 
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
     
      transform: rotate(-45deg);
    }
  }
`;

export const Circle = styled.div`
  height: 20px;
  width: 20px;
  flex-grow: 0;
  object-fit: contain;
  background-color: var(--goldColor);
  border-radius: 50%;
  position: absolute;
  bottom: -8px;
  left: ${(props) =>
    props.idx === 0
      ? "25px"
      : props.length > 4
      ? `${props.idx * 200}px`
      : `${props.idx * 350}px`};
`;

export const Card = styled.div`
z-index: 100;
  left: ${(props) =>
    props.idx === 0
      ? "25px"
      : props.length > 4
      ? `${props.idx * 200}px`
      : `${props.idx * 350}px`};
  top: ${(props) => (props.idx % 2 === 0 ? "35px" : `-120px`)};
  background-color: white;
  width: 159px;
  height: 90px;
  flex-grow: 0;
  gap: 16px;
  margin: 0 362px 25px 0;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  position: absolute;
  a,div {
    filter:  ${ (props) => props.admin===true  ? 'blur(0px)': props.idx>0?'blur(5px)':'blur(0px)'};

    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #323232;
  }
`;
