import styled from "styled-components";

export const TermsContainer = styled.div`
  
  margin: 51px 32px 59px;
  padding: 48px 0px 48px 0px;
  background-color: var(--white);
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02), 0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02), 0 19px 122px 0 rgba(7, 62, 132, 0.04);
  border-radius: 8px;
  box-sizing: border-box;
  margin-left: 1rem;
  margin-right: 1rem;
  .Terms{
    display: flex;
    flex-direction: column;
   
    flex-wrap:wrap;
    gap:20px;   
    margin-bottom: 38px;
    padding-right: 28px;
    padding-left: 26px;
    h1{
      font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; 
  color:var(--grey-dark);
  margin-bottom: 0px;
  text-align:center;
  width:100%;
    }
    div{
        h2{ 
        font-family: "Inter";
  font-size: 20px;
  font-weight: 600; 
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color:var(--grey-dark) !important;
  margin-bottom: 0px;
    }
    p{
        font-family: "Inter";
        line-height:20px; 
        color: var(--grey-dark) !important;
    }
    .termtable{
      .p-datatable-table{
        .p-datatable-tbody{
          tr{
            vertical-align:initial;
          }
        }
      }
    }
    
    }
}
  `