const initialState = false
  
  const AutoSaveReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_AUTO_SAVE":
        state = action.payload;
        return state;
      case "GET_AUTO_SAVE":
        return state;
      default:
        return state;
    }
  };
  export default AutoSaveReducer;