import SidebarReducer from "./ToogleSidebar";
import { combineReducers } from "redux";
import SubSidebarReducer from "./ToggleSubSidebar";
import VendorsReducer from "./Vendors";
import UserProfileReducer from "./UserProfile";
import FinancesReducer from "./FinancesReducer";
import Mortgage from "./Mortgage";
import Property from "./Property";
import generic from "./generic";
import PropertyGenerator from "./generator";
import PropertyLocks from "./lockReducer";
import DomainReducer from "./DomainReducer";
import IncomePlanReducer from "./IncomeReducer";
import ThemePattern from "./ThemePattern";
import PropertyPurchaseReducer from "./PurchaseDateReducer";
import regionReducer from "./regionReducer";
import BasiqReducer from "./BasiqReducer";
import MBC from "./MBCReducer";
import AutoSaveReducer from "./AutoSaveReducer";
import MiniSidebarReducer from "./MiniSideBar";
import PinderFilterReducer from "./PinderFilter";
export const rootReducer = combineReducers({
  SidebarReducer,
  Mortgage,
  SubSidebar: SubSidebarReducer,
  Vendors: VendorsReducer,
  Property: Property,
  generic: generic,
  UserProfile: UserProfileReducer,
  Finances: FinancesReducer,
  Domain: DomainReducer,
  PropertyGenerator: PropertyGenerator,
  IncomePlan: IncomePlanReducer,
  PropertyLocks: PropertyLocks,
  ThemePattern: ThemePattern,
  PropertyPurchase: PropertyPurchaseReducer,
  propertyRegions:regionReducer,
  BasiqReducer:BasiqReducer,
  MBC:MBC,
  AutoSave:AutoSaveReducer,
  MiniSidebar:MiniSidebarReducer,
  PinderFilter:PinderFilterReducer
});
