export const ToggleSidebar = (data) => {
  return {
    type: data === true ? "OPEN_SIDEBAR" : "CLOSE_SIDEBAR",
    payload: data,
  };
};
export const SetPinderFilter=(data)=>{
  return{
    type:data=="commercial"?"SET_COMMERCIAL":"SET_RESIDENTIAL",
    payload:data
  }
}
export const ToggleMiniSidebar = (data) => {
  return {
    type: data === true ? "OPEN_MINI_SIDEBAR" : "CLOSE_MINI_SIDEBAR",
    payload: data,
  };
};

export const ToggleSubSidebar = (data) => {
  return {
    type: "SET_SUBSIDEBAR",
    payload: data,
  };
};

export const Vendors = (data) => {
  return {
    type: "SET_VENDORS_DATA",
    payload: data,
  };
};

export const UpdateVendors = (id) => {
  return {
    type: "UPDATE_VENDORS_DATA",
    payload: id,
  };
};
export const GetTotalMortgage = () => {
  return {
    type: "GET_MORTGAGE",
  };
};
export const SetMortgageValue = (data) => {
  return {
    type: "SET_MORTGAGE",
    payload: data,
  };
};
export const SetPropertyData = (data) => {
  return {
    type: "SET_PROPERTY_DATA",
    payload: data,
  };
};
export const getPropertyData = () => {
  return {
    type: "GET_PROPERTY_DATA",
  };
};
export const setGenericData = (data) => {
  return {
    type: "SET_PROPERTY_DATA",
    payload: data,
  };
};
export const getGenericData = () => {
  return {
    type: "GET_PROPERTY_DATA",
  };
};

export const setProfileData = (data) => {
  return {
    type: "SET_PROFILE",
    payload: data,
  };
};

export const updateProfileData = (data) => {
  return {
    type: "UPDATE_PROFILE",
    payload: data,
  };
};

export const getProfileData = () => {
  return {
    type: "GET_PROFILE",
  };
};

export const getFinancesData = () => {
  return {
    type: "GET_FINANCES",
  };
};

export const setFinancesData = (data) => {
  return {
    type: "SET_FINANCES",
    payload: data,
  };
};

export const updateFinancesData = (data) => {
  return {
    type: "UPDATE_FINANCES",
    payload: data,
  };
};
export const getGeneratedProperties = () => {
  return {
    type: "GET_PROPERTY",
  };
};
export const setGeneratedProperties = (data) => {
  return {
    type: "SET_PROPERTY",
    payload: data,
  };
};

export const setLockedProperties = (data) => {
  return {
    type: "SET_LOCKED_PROPERTY",
    payload: data,
  };
};

export const getImagesFromDomain = (data) => {
  return {
    type: "GET_PROPERTY_IMAGES",
    payload: data,
  };
};
export const updatePropertyData = (data) => {
  return {
    type: "UPDATE_PROPERTY_IMAGES",
    payload: data,
  };
};

export const updatePurchaseDate = (data) => {
  return {
    type: "SET_PURCHASE_DATE",
    payload: data,
  };
};

export const setCurrentRegions = (data) => {
  return {
    type: "SET_REGIONS",
    payload: data,
  };
};

export const getMedianPrice = (data) => {
  return {
    type: "GET_MEDIAN_PRICES",
    payload: data,
  };
};

export const setMedianData = (data)=>{
  return {
    type: "SET_REGIONS_DATA",
    payload: data,
  };
}
export const setThemePattern = (data) => {
  return {
    type: "SET_THEME_DATA",
    payload: data,
  };
};
export const getThemePattern = () => {
  return {
    type: "GET_THEME_DATA",
  };
};
export const SetBasiqUser=(data)=>{
  return {
    type: "SET_BASIQ_USERS",
    payload: data,
  };
}
export const getBasiqUser=()=>{
  return {
    type: "GET_BASIQ_USERS",
  
  };
}
export const SetMBC=(data)=>{
  return {
    type: "SET_MBC",
    payload: data,
  };
}
export const SetAutoSave=(data)=>{
  return{
    type:"SET_AUTO_SAVE",
    payload:data
  }
}