import { Slider } from "primereact/slider";
import styled from "styled-components";
const ProgessIndicator = ({
  value,
  progressColor,
  txtColor = "white",
  min,
  max,
  step,
  setSliderVal,
  locked,
  SetRisk,
  Sliderval,
}) => {
  return (
    <>
      <MyProgess
        defaultValue={Sliderval}
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MySlider
          className="slider"
          min={min}
          max={max}
          step={step}
          progressColor={progressColor}
          value={Sliderval > 87.5 ? 87.5 : Sliderval < 17.5 ? 17.5 : Sliderval}
          onChange={(e) => {
            if (locked.locked === true) {
              return locked.error();
            }
            setSliderVal(e.value);
            SetRisk(e.value);
          }}
          txtColor={txtColor}
          RiskVal={value}
        />
      </MyProgess>
    </>
  );
};

export default ProgessIndicator;

const MyProgess = styled.div`
  display: "flex";
  position: "relative";
  flex-direction: "row";
  justify-content: "center";
  align-items: "center";
`;
const MySlider = styled(Slider)`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 4px !important;
  border-radius: 5px !important;
  -webkit-appearance: none !important;
  outline: none;
  top: 15px;
  background-color: transparent !important;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -10px;
    width: 50px;
    height: 50px;
    background-color: ${({ progressColor }) => progressColor};

    cursor: pointer;
    outline: none;
    box-shadow: 0 0 0 0 rgba(98, 0, 238, 0.1);
    transition: 0.5s ease;
    position: relative;
    z-index: 2;
  }
  .p-slider-range {
    background-color: transparent !important;
  }
  .p-slider-handle {
    margin-top: -20px !important;
    width: 10px !important;
    height: 10px !important;
    color: green !important;
    background-color: ${({ progressColor }) =>
      progressColor ? progressColor : "orange"} !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    background-image: none !important;
    position: relative;
    z-index: 3;

    &::before {
      content: "${({ RiskVal }) => RiskVal}";
      position: absolute;
      top: -40px;
      left: -10px;
      width: 30px;
      height: 30px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Inter";
      font-size: 11px;
      font-weight: "bold";
      color: ${({ txtColor }) => (txtColor ? txtColor : "")};
    }
    &::after {
      content: "";
      position: absolute;
      top: -40px;
      left: -10px;
      width: 30px;
      height: 30px;
      background-color: ${({ progressColor }) =>
        progressColor ? progressColor : ""};
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;

      border-bottom-right-radius: 50%;
      transform: rotate(-45deg);
    }
  }
  .p-slider-handle:focus {
    outline: none !important;
    border: none !important;
  }
`;
