import React from 'react'
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import AssumptionSettings from "../../components/AssumptionSettings";
import Propertytype from '../../components/Propertytype/Propertytype';
import { HomeContainer, OuterContainer } from "../Home/Home.styles";
const PropertyType = () => {
  return (
    <OuterContainer>
    <UserSidebar headerPath="Settings" />
    <HomeContainer>
      <Header headerPath="/Settings/Assumptions" />
      <Propertytype/>
    
    </HomeContainer>
  </OuterContainer>
  )
} 

export default PropertyType