import React, { useState, useEffect } from "react";
import { API } from "../../services/api";
import { useSearchParams } from "react-router-dom";
import { GS } from "../../services/global";
import Loading from "../Loading";

import ContactProfile from "./ContactProfile";
const Contact = () => {
  const [userdata, setuserdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    contact_address: "",
    contact_type: "",
    telephone_no: "",
    preferred_contact_method: "",
  });
  const [loader, setLoader] = useState(true);

  const [photo, setPhoto] = useState("");

  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  GS.user_id = id;
  const formatPhoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
        4,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
      4,
      7
    )} ${phoneNumber.slice(7, phoneNumber.length)}`;
  };
  const formatTelephoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 2) return phoneNumber;
    if (phoneNumber.length < 6) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      6
    )} ${phoneNumber.slice(6, phoneNumber.length)}`;
  };
 
  useEffect(() => {
    const getData = async () => {
      let response = await API.get(`contacts/getbyid/${id}`);
      console.log(response,"userResponse")
      if (id !== null) {
        let { data } = response;
        if (data) {
          GS.update_user.first_name = data.first_name;
          GS.update_user.last_name = data.last_name;
          GS.update_user.email = data.email;
          const formatNumber = formatPhoneNumber(data.mobile_no);
          const formatTeleNumber = formatTelephoneNumber(data.telephone_no);
          GS.update_user.mobile_no = formatNumber;
          GS.update_user.contact_address = data.contact_address;

          setPhoto(data.photo);
          GS.update_user.contact_type = data?.contact_type;
          GS.update_user.telephone_no = formatTeleNumber;
          setuserdata(GS.update_user);
          setLoader(false);
        }
      }
    };
    getData();
  }, [id]);

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <ContactProfile vendorid={id} VendorData={userdata} userphoto={photo} />
      )}
    </>
  );
};

export default Contact;
