import React, { useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import classes from "./stylesheet.module.css";
import myclasses from "../SignInForm/stylesheet.module.css";
import { API } from "../../services/api";
import { GS } from "../../services/global";
import { useNavigate,Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { checkPasswordStrength } from "../../utils";
import { CustomToast } from "./Signupform.style";
import PasswordCheck from "./PasswordCheck";
const SignupForm = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  let SOT = {
    VendorId: null,
    Sheets: {}, 
    ai_matrix: {
      Scenario: [1, 3, 3, 2, 1, 1, 3, 3, 2, 1],
      Dependant: 7000,
      BaseExpense: [17800, 26040],

      BuildingReport: 1200,
      PurchasePriceDeviation: 5.3,
      RiskPercentage: { 1: 5, 2: 6, 3: 7 },
    },
    PortfolioSummary: {
      tableData: [],
      Properties: {
        retail: [],
        office: [],
        residential: [],
        other: [],
        industry: [],
      },
      isLocked: false,
      PropertyPurchase: [],
      selectedRegions:[],
    },
    PropertyGenerators: [],
    BorrowingCalculator: {
      income_goal: 0,
      FinancialYear: 0,
      salary_after_tax: 0,
      salary_after_tax_term: "Annually",
      other_income_net: 0,
      other_income_net_term: "Annually",
      partner_salary_after_tax: 0,
      partner_salary_after_tax_term: "Annually",
      dependants: 0,
      car_loan_payment: 0,
      car_loan_payment_2: 0,
      car_loan_payment_2_term: "Monthly",
      annual_expenses: 0,
      annual_expenses_2: 0,
      other_payments: 0,
      other_payments_2: 0,
      other_payments_2_term: "Monthly",
      uaaae: true,
      credit_card_limit: 0,
      credit_card_limit_2: 0, 
      credit_card_limit_2_term: "Annually",
      existing_home_loan: 0,
      existing_home_loan_2_term: "Monthly",
      interest_rate: 0,
      loan_term: 0,
      uirb: false,
      base_expanse: 0,
      dependant_base_expanse: 0,
      servicable_income_factor: 0,
      default_bp_interest_rate: 0,
      can_borrow1: 0,
      monthly_repayment: 0,
      can_borrow2: 0,
      monthly_repayment2: 0,
      period: "Annually",
      value1: 0,
      value2: 0,
      Checked: false,
      InterestOnly: false,
      PrincipalInterest: false,
      IncomeType: "Net",
      IncomeGoalStatus: false,
    },
    Equity: {
      Cash: {
        type: "Net",
        value: 0,
      },
      LiquidAssets: {
        type: "Net",
        value: 0,
      },
      PPRInterestRate: "",
      PPRMortage: "",
      HomeLoanPayment: {
        type: "Yearly",
        value: 0,
      },
      interest: false,
      PrincipleInterest: false,
      properties: [],
    },
    StrategicSpending: {
      balanceSheet: 0,
      cashOnHand: 0,
      accountYour: 0,
      accountPartner: 0,
      liabilities: 0,
      creditCardYours: 0,
      creditCardPartner: 0,
      monthlyCashflow: 0,
      income: 0,
      netSalaryYour: 0,
      netSalaryPartner: 0,
      rentalIncome: 0,
      expenses: 0,
      AutoTransport: 0,
      BillsUtilities: 0,
      Education: 0,
      Entertainment: 0,
      Equipment: 0,
      FeesCharges: 0,
      OtherExpenses: 0,
      FinancialAccounting: 0,
      FoodDining: 0,
      GiftsDonations: 0,
      HealthFitness: 0,
      HealthInsurance: 0,
      LoansCreditCards: 0,
      Misc: 0,
      RentMortgageProperty: 0,
      Shopping: 0,
      Travel: 0,
      CarLoan: 0,
      Data: [],
    },
    Progress: {
      steps: 5,
      completed: 0,
      current: 1,
    },
    RiskProfile: {
      NetYieldonProperty: "5%",
      initialPropertyType: "Residential",
    },
  };
  GS.SOT = SOT;
  const [userdata, setuserdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "+61",
    telephone_no: "",
    contact_type:"Contact Paid",
    password: "",
    agent: "", 
    preferred_contact_method: "",
    photo: "",
    role_id: "6193a50669fff80382457d27",
    office_id: "610a0f1dc61edfcf62d62c52",
  });
  const [ShowPassword, setShowPassword] = useState(false);
  const [ShowPassword1, setShowPassword1] = useState(false);
  const [password, setPassword] = useState("");
  const [Agree, setAgree] = useState(
    localStorage.getItem("Agree") ? true : false
  );
  function isEmailValid(email) {
    // Regular expression pattern to match email addresses ending with a "+"
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{2,3})+$/ 
    
    return pattern.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = userdata;
    const data = JSON.stringify(params);
    const {valid,message}=checkPasswordStrength(userdata.password)
    if(!(isEmailValid(userdata.email))){
      showSuccess("Please provide an valid email.");
    }else if (userdata.password !== password) {
      showSuccess("password donot match");
    }else if(valid==false){
      showPassMessage(userdata.password)
    }else if(userdata.first_name.length==0){
      showSuccess("Firstname should not be empty");
    }else if(userdata.last_name.length==0){
      showSuccess("Lastname should not be empty");
    }
     else {
      await fetch(`${process.env.REACT_APP_API_BASEURL}contacts/signup`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.insertedId) {
            showSuccess(data.message);
            navigate("/signin");
          } else {
            showSuccess(data.message);
          }
        });
    }
  };
  // const showSuccess = (Message) => {
  //   toast.current.show({
  //     severity: "info",
  //     sticky: true,
  //     life: 3000,
  //     content: (
  //       <div
  //         className="flex flex-column "
  //         style={{ paddingTop: "0px", paddingBottom: "20px", width: "100%" }}
  //       >
  //         <div className="text-center ">
  //           <p className="popup-info-text">{Message}</p>
  //         </div>
  //       </div>
  //     ),
  //   });
  // };
  const showSuccess = (Message) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px",width:"100%" }}
        >
          <div className="text-center ">
            
            <p className="popup-warn-text">
              {Message}
            </p>
          </div>
          
        </div>
      ),
    });
  };
  const showPassMessage=(password)=>{
    let {valid,message}=checkPasswordStrength(password)
    return message
  }
  const ChangePhoneFormat=()=>{
   if(userdata.mobile_no[0]!=="+"){
    if(userdata.mobile_no[0]=="0"){
      let newnumber="+61"+userdata.mobile_no.slice(1,userdata.mobile_no.length)
      setuserdata({...userdata,mobile_no:newnumber})
    }else{
      let newnumber="+61"+userdata.mobile_no
      setuserdata({...userdata,mobile_no:newnumber})
    }
   }
  
  }
  return (
    <>
      <CustomToast
        ref={toast}
        position="center"
        style={{ zIndex: "2", opacity: "1" }}
      />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Form.Group className="mb-3" controlId="signinForm.email">
          <Form.Control
            type="text"
            value={userdata.first_name}
            onChange={(e) =>
              setuserdata({ ...userdata, first_name: e.target.value })
            }
            className={classes.EmailInput}
            placeholder="First Name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="signinForm.email">
          <Form.Control
            type="text"
            value={userdata.last_name}
            onChange={(e) =>
              setuserdata({ ...userdata, last_name: e.target.value })
            }
            className={classes.EmailInput}
            placeholder="Last Name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="signinForm.email">
          <Form.Control
            type="email"
            value={userdata.email}
            onChange={(e) =>
              setuserdata({ ...userdata, email: e.target.value })
            }
            className={classes.EmailInput}
            placeholder="Email Address"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="signinForm.mobile_no">
          <Form.Control
            type="text"
            onBlur={ChangePhoneFormat}
            value={userdata.mobile_no}
            onChange={(e) =>
              setuserdata({ ...userdata, mobile_no: e.target.value })
            }
            className={classes.EmailInput}
            placeholder="Mobile No"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="signinForm.password">
          <InputGroup className={classes.PasswordInputGroup}>
            <Form.Control
              type={ShowPassword ? "text" : "password"}
              value={userdata.password}
              onChange={(e) =>
                setuserdata({ ...userdata, password: e.target.value })
              }
              className={classes.PasswordInput}
              placeholder="Password"
              required
            />
            <InputGroup.Text className={classes.passwordShowHide}>
              <span
                onClick={() => setShowPassword(!ShowPassword)}
                className={classes.showHideBtn}
              >
                {ShowPassword ? "Hide" : "Show"}
              </span>
            </InputGroup.Text>
          </InputGroup>

              <PasswordCheck password={userdata.password}/>
      
        
          <p className={`${showPassMessage(userdata.password)}`} style={{fontSize:'13px',marginTop:'5px'}}>
              {showPassMessage(userdata.password)}
            </p>
        </Form.Group> 
        <Form.Group className="mb-3" controlId="signinForm.password">
          <InputGroup className={classes.PasswordInputGroup}>
            <Form.Control
              type={ShowPassword1 ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.PasswordInput}
              placeholder="Confirm Password"
              required
            />
            <InputGroup.Text className={classes.passwordShowHide}>
              <span
                onClick={() => setShowPassword1(!ShowPassword1)}
                className={classes.showHideBtn}
              >
                {ShowPassword1 ? "Hide" : "Show"}
              </span>
            </InputGroup.Text>
           
          </InputGroup>
         
        </Form.Group>

        <Form.Group className="mb-3" controlId="signinForm.checkbox">
          <Form.Check>
            <Form.Check.Input
              type={"checkbox"}
              checked={Agree} 
              onChange={() => setAgree(!Agree)}
              className={classes.CheckboxInput}
              required
            />
            <Form.Check.Label className={classes.CheckboxLabel}>
            I agree to all the statements included in the&nbsp;
            <Link to="/Terms" className={classes.link}>
             Terms of use / Privacy policy.
              </Link>
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>
        <Button className={myclasses.loginBtn} type="submit">
          Sign Up
        </Button>
      </Form>
    </>
  );
};

export default SignupForm;
