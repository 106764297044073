import axios, { AxiosResponse } from 'axios';
const API_KEY = process.env.REACT_APP_BASIQ_API_KEY
const BASE_URL = process.env.REACT_APP_BASIQ_BASE_URL
let AUTH_TOKEN: string = ""

//#region User
export const getAccessToken=async()=>{
    const headers:any={
      Authorization:`Basic ${process.env.REACT_APP_BASIC_API_KEY}`,
      "basiq-version":3.0
    }
    //${process.env.REACT_APP_API_BASEURL}
  const response=  await fetch(`${process.env.REACT_APP_API_BASEURL}basiq/auth/token`,
    {
      method:'GET',
      headers:headers,

    }
    )
    .then((response)=>response.json())
    .then((data)=>{
      
      localStorage.setItem('accessToken',JSON.stringify(data?.data?.access_token))
      return data?.data?.access_token
    })
  return response
  }

export  const getAllBasicUsers=async()=>{
    let token:any=sessionStorage.getItem('Token')
    try{
      const headers:any={
        Authorization:`Bearer ${token}`,
        "basiq-version":3.0
      }
      
     let result= await fetch(`${process.env.REACT_APP_API_BASEURL}all-url-basiq?url=users`,
      {
        method:'GET',
        headers:headers,
        
      }
      )
      .then((response)=>response.json())
      .then((data)=>data.data)
    //   dispatch(SetBasiqUser(result.data))
      return result.data
    }catch(err){

    }
  }
 export const createBasiqUser=async(body:any)=>{
    let token:any=sessionStorage.getItem('Token')
    try{
     let user={
       email:body.email,
       mobile:body.mobile_no,
       firstName:body.first_name,
       middleName:'',
       lastName:body.last_name,
       url:'users'
     }
     const headers={
       Authorization:`Bearer ${token}`,
       'Content-Type':'application/json'
     }
    let result= await fetch(`${process.env.REACT_APP_API_BASEURL}all-url-basiq`,
     {
       method:'POST',
       headers:headers,
       body:JSON.stringify(user)
 
     }
     )
     .then((response)=>response.json())
     .then((data)=>data.data)
     return result
    }catch(err){
 
    }
   }
const createUser = async (userData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/users`, userData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
};

const getUser = async (userId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/users/${userId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const updateUser = async (userId: string = "", userData: any = null) => {

    try {
        const response: any = await axios.patch(`${BASE_URL}/users/${userId}`, userData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const deleteUser = async (userId: string = "") => {

    try {
        const response: any = await axios.delete(`${BASE_URL}/users/${userId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion User

//#region Accounts
const getAccounts = async (userId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/users/${userId}/accounts`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getAccount = async (accountId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/accounts/${accountId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Accounts

//#region Transactions

const getTransactions = async (accountId: string = "", params: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/accounts/${accountId}/transactions`, {
            params,
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getTransaction = async (transactionId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/transactions/${transactionId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Transactions

//#region Connections
const createConnection = async (userId: string = "", connectionData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/users/${userId}/connections`, connectionData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getConnection = async (connectionId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/connections/${connectionId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getConnections = async (userId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/users/${userId}/connections`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const deleteConnection = async (connectionId: string = "") => {
    try {
        const response: any = await axios.delete(`${BASE_URL}/connections/${connectionId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const refreshConnection = async (connectionId: string = "") => {
    try {
        const response: any = await axios.post(`${BASE_URL}/connections/${connectionId}/refresh`, {}, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Connections

//#region Jobs

const createJob = async (jobData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/jobs`, jobData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getJob = async (jobId: string = "") => {

    try {
        const response: any = await axios.get(`${BASE_URL}/jobs/${jobId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getJobResults = async (jobId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/jobs/${jobId}/results`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const deleteJob = async (jobId: string = "") => {
    try {
        const response: any = await axios.delete(`${BASE_URL}/jobs/${jobId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getAllJobs = async () => {
    try {
        const response: any = await axios.get(`${BASE_URL}/jobs`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getUserJobs = async (userId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/users/${userId}/jobs`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Jobs

//#region Institution

const searchInstitutions = async (query: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/institutions`, {
            params: { q: query },
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getInstitution = async (institutionId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/institutions/${institutionId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getInstitutionLogos = async () => {
    try {
        const response: any = await axios.get(`${BASE_URL}/institutions/logos`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getInstitutionBrands = async () => {
    try {
        const response: any = await axios.get(`${BASE_URL}/institutions/brands`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getInstitutionLoginBrands = async () => {
    try {
        const response: any = await axios.get(`${BASE_URL}/institutions/login/brands`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getInstitutionCapabilities = async (institutionId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/institutions/${institutionId}/capabilities`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        }); 
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Institution

//#region Income

const createIncome = async (connectionId: string = "", incomeData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/connections/${connectionId}/incomes`, incomeData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getIncomes = async (connectionId: string = "") => {

    try {
        const response: any = await axios.get(`${BASE_URL}/connections/${connectionId}/incomes`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getIncome = async (connectionId: string = "", incomeId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/connections/${connectionId}/incomes/${incomeId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const updateIncome = async (connectionId: string = "", incomeId: string = "", incomeData: any = null) => {
    try {
        const response: any = await axios.patch(`${BASE_URL}/connections/${connectionId}/incomes/${incomeId}`, incomeData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }  
}

const deleteIncome = async (connectionId: string = "", incomeId: string = "") => {
    try {
        const response: any = await axios.delete(`${BASE_URL}/connections/${connectionId}/incomes/${incomeId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Income

//#region Expense

const createExpense = async (connectionId: string = "", expenseData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/connections/${connectionId}/expenses`, expenseData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getExpenses = async (connectionId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/connections/${connectionId}/expenses`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getExpense = async (connectionId: string = "", expenseId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/connections/${connectionId}/expenses/${expenseId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const updateExpense = async (connectionId: string = "", expenseId: string = "", expenseData: any = null) => {
    try {
        const response: any = await axios.patch(`${BASE_URL}/connections/${connectionId}/expenses/${expenseId}`, expenseData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const deleteExpense = async (connectionId: string = "", expenseId: string = "") => {
    try {
        const response: any = await axios.delete(`${BASE_URL}/connections/${connectionId}/expenses/${expenseId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Expense

//#region AuthLink

const createAuthLink = async (authLinkData: null = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/auth_links`, authLinkData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getAuthLink = async (authLinkId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/auth_links/${authLinkId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const deleteAuthLink = async (authLinkId: string = "") => {
    try {
        const response: any = await axios.delete(`${BASE_URL}/auth_links/${authLinkId}`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}


//#endregion AuthLink

//#region Affordability

const createAffordability = async (connectionId: string = "", affordabilityData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/connections/${connectionId}/affordability`, affordabilityData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const getAffordability = async (connectionId: string = "") => {
    try {
        const response: any = await axios.get(`${BASE_URL}/connections/${connectionId}/affordability`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}


//#endregion Affordability

//#region Misc

const getEnrich = async () => {
    try {
        const response: any = await axios.get(`${BASE_URL}/enrich`, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const createStatement = async (userId: string = "", userData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/users/${userId}/statements`, userData, {
            headers: {
                Authorization: `Basic ${AUTH_TOKEN}`,
            },
        });
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

const postToken = async (userData: any = null) => {
    try {
        const response: any = await axios.post(`${BASE_URL}/AUTH_TOKEN`, userData, {
            headers: {
                Authorization: `Basic ${API_KEY}`,
                [`basiq-version`]: `3.0`
            },
        });
        const { token_type, access_token } = response
        AUTH_TOKEN = `${token_type} ${access_token}` || ''
        return handleApiResponse(response);
    } catch (error) {
        return handleApiError(error);
    }
}

//#endregion Misc

function handleApiResponse(response: AxiosResponse | any) {
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    } else {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
    }
}

function handleApiError(error: any = null) {
    if (error.response) {
        throw new Error(`API error: ${error.response.status} ${error.response.data}`);
    } else {
        throw new Error(`Network error: ${error.message}`);
    }
}

  
const User = { createUser, getUser, updateUser, deleteUser }
const Accounts = { getAccounts, getAccount }
const Transactions = { getTransactions, getTransaction, updateUser, deleteUser }
const Connections = { createConnection, getConnection, getConnections, deleteConnection, refreshConnection }
const Jobs = { createJob, getJob, getJobResults, deleteJob, getAllJobs, getUserJobs }
const Institution = { searchInstitutions, getInstitution, getInstitutionLogos, getInstitutionBrands, getInstitutionLoginBrands, getInstitutionCapabilities }
const Income = { createIncome, getIncomes, getIncome, updateIncome, deleteIncome }
const Expense = { createExpense, getExpenses, getExpense, updateExpense, deleteExpense }
const AuthLink = { createAuthLink, getAuthLink, deleteAuthLink, deleteUser }
const Affordability = { createAffordability, getAffordability }
const Misc = { getEnrich, createStatement, postToken }


export const BasiqWrapper = { User, Accounts, Transactions, Connections, Jobs, Institution, Income, Expense, AuthLink, Affordability, Misc, BASE_URL };