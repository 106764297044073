import { InputText } from "primereact/inputtext";
import { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { API } from "../../services/api";
import Avatar from "../Avatar/Avatar";
import "../VendorProfileInfo/stylesheet.css";
import { ContactDropDown, ContactContainer } from "../Contact/Contact.style";
import { validateImage } from "../../utils";
import { ValidateEmail, ValidateName,checkPasswordStrength } from "../../utils";
import { Toast } from "primereact/toast";
import { Vendors } from "../../redux/actions";
import { useDispatch } from "react-redux";
import PasswordCheck from "../SignupForm/PasswordCheck";
const ContactProfile = ({ vendorid, VendorData, userphoto }) => {
  const dispatch = useDispatch();
  const accounttype=sessionStorage.getItem("accountType")
  const [userdata, setuserdata] = useState({
    first_name: VendorData.first_name,
    last_name: VendorData.last_name,
    email: VendorData.email,
    mobile_no: VendorData.mobile_no, 
    contact_address: VendorData.contact_address,
    contact_type: VendorData.contact_type,
    telephone_no: VendorData.telephone_no,
    preferred_contact_method: "",
    password:"",
  });
  const [photo, setPhoto] = useState(userphoto);
  const [initialRender, setInitial] = useState(true);
  const toast = useRef();
  const [showPass, setShowPass] = useState(true);
  const [Address, setAddress] = useState(VendorData.contact_address);
  let useremail=""
  if(localStorage.getItem("email")!==null && localStorage.getItem("email")!==undefined){
    useremail=JSON.parse(localStorage.getItem("email"))
  }
  const ContactTypes = [
    { label: "Lite", value: "Contact Lite" },
  ];
  const ContactTypes2 = [
    { label: "Lite", value: "Contact Lite" },
    { label: "Premium", value: "Contact Paid" },
  ]
  
  const [AdminAllow,setAdminAllow]=useState(useremail==process.env.REACT_APP_ADMIN_EMAIL)
  console.log(VendorData,"Vendordata",useremail,process.env.REACT_APP_ADMIN_EMAIL)
  const AddressRef = useRef();
  const autoCompleteRef = useRef();
  const handleChange = (e) => {
    if (e.target.name === "mobile_no") {
      const formatNumber = formatPhoneNumber(e.target.value);

      setuserdata({ ...userdata, mobile_no: formatNumber });
    } else if (e.target.name === "telephone_no") {
      const formatNumber = formatTelephoneNumber(e.target.value);
      setuserdata({ ...userdata, telephone_no: formatNumber });
    }else{
      setuserdata({ ...userdata, [e.target.name]: e.target.value });
    }
  };
  let UpdateData = async () => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(`contacts?office_id=${user_id}`);
    const data1 = response?.data.sort(function (a, b) {
      var textA = a.first_name.toUpperCase();
      var textB = b.first_name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    dispatch(Vendors(data1));
  };
  const saveUser = async () => {
    let newData = {
      first_name: userdata.first_name,
      last_name: userdata.last_name,
      email: userdata.email,
      mobile_no: userdata.mobile_no,
      telephone_no: userdata.telephone_no,
      contact_type: userdata.contact_type,
      contact_address: Address,
      password:userdata.password
    };
    let test = validateImage(photo);
    if (test === true) {
      newData = { ...newData, photo: photo };
      await API.put(`contacts/${vendorid}`, newData);
      if(VendorData.contact_type!=="Master Admin"){
        UpdateData();
      }
      
    } else {
      await API.put(`contacts/${vendorid}`, newData);
      if(VendorData.contact_type!=="Master Admin"){
        UpdateData();
      }
    }
  };

  // const options = {
  //   componentRestrictions: { country: "ng" },
  //   fields: ["address_components", "geometry", "icon", "name"],
  //   types: ["establishment"],
  // };
  const formatPhoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
        4,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
      4,
      7
    )} ${phoneNumber.slice(7, phoneNumber.length)}`;
  };
  const formatTelephoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 2) return phoneNumber;
    if (phoneNumber.length < 6) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      6
    )} ${phoneNumber.slice(6, phoneNumber.length)}`;
  };
  const showError = (Message) => {
    toast.current.show({
      severity: "error",

      detail: Message,
      life: 2000,
    });
  };
  const ValidatePassword=()=>{
    if(userdata.password.length>0){
      let {valid,message}=checkPasswordStrength(userdata.password)
      if(valid){
        return true
      }else{
        return false
      }
    }else{
      return true
    }
  }
  const handleEdit = async () => {
    ValidateName(userdata.first_name) === false
      ? showError("First name should not be empty")
      : ValidateName(userdata.last_name) === false
      ? showError("Last name should not be empty")
      : ValidateName(userdata.email) === false
      ? showError("Email should not be empty")
      : ValidateEmail(userdata.email) === false
      ? showError("Invalid Email format ")
      : ValidatePassword()==true && saveUser();
  };
  const ChangePhoneFormat=()=>{
    if(userdata.mobile_no[0]!=="+"){
     if(userdata.mobile_no[0]=="0"){
       let newnumber="+61"+userdata.mobile_no.slice(1,userdata.mobile_no.length)
     
       setuserdata({...userdata,mobile_no:newnumber})
     }else{
       let newnumber="+61"+userdata.mobile_no
      
       setuserdata({...userdata,mobile_no:newnumber})
     }
    }
   
   }
  const showPassMessage=(password)=>{
    let {valid,message}=checkPasswordStrength(password)
    return message
  }
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      AddressRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      setAddress(place.formatted_address);
      setuserdata({ ...userdata, contact_address: place.formatted_address });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialRender) {
      setInitial(false);
    } else {
      handleEdit();
    }
  }, [userdata]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Toast ref={toast} />
      <ContactContainer>
        <div className="profile-container">
          <h1>Profile</h1>

          <div className="avatar-container">
            <Avatar
              text="CH"
              width="80px"
              height="80px"
              fontSize="23px"
              textColor="#fefefe"
              imgSrc={photo}
              setImgSrc={setPhoto}
              setuserdata={setuserdata}
              data={userdata}
            />

            <div className="avatar-text-container">
              <p className="main-text">
                {userdata.first_name ? userdata.first_name : ""}&nbsp;
                {userdata.last_name ? userdata.last_name : ""}
              </p>
              <p className="sub-text"> {userdata.contact_type}</p>
            </div>
          </div>
        </div>

        <div>
          <Row className="py-3">
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">First Name</p>
                <InputText
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) => (e.target.value = `${userdata.first_name}`)}
                  type={"text"}
                  value={userdata.first_name}
                  onChange={(e) =>
                    setuserdata({ ...userdata, first_name: e.target.value })
                  }
                  className=" edit-input-field"
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Last Name</p>
                <InputText
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) => (e.target.value = `${userdata.last_name}`)}
                  type={"text"}
                  value={userdata.last_name}
                  onChange={(e) =>
                    setuserdata({ ...userdata, last_name: e.target.value })
                  }
                  className=" edit-input-field"
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Email</p>
                <InputText
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) => (e.target.value = `${userdata.email}`)}
                  type={"email"}
                  value={userdata.email}
                  onChange={(e) =>
                    setuserdata({ ...userdata, email: e.target.value })
                  }
                  className=" edit-input-field"
                />
              </div>
            </Col>
          </Row>
          <Row className="py-3">
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Mobile No</p>
                <InputText
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) => {(e.target.value = `${userdata.mobile_no}`);ChangePhoneFormat()}}
                  name="mobile_no"
                  type={"tel"}
                  value={userdata.mobile_no}
                  onChange={handleChange}
                  className=" edit-input-field"
                />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div>
                <p className="input-label mb-2">Phone Number</p>
                <InputText
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) => (e.target.value = `${userdata.telephone_no}`)}
                  name="telephone_no"
                  type={"tel"}
                  value={userdata.telephone_no}
                  onChange={handleChange}
                  className=" edit-input-field"
                />
              </div>
            </Col>
          {VendorData.contact_type!=="Master Admin" ?  
          <Col sm={12} md={4} lg={4}>
          <div>
            <p className="input-label mb-2">{accounttype=="Master Admin"?"Membership Type":"Membership Type abnd" }</p>
            <ContactDropDown
              value={userdata.contact_type}
              onChange={(e) =>
                setuserdata({ ...userdata, contact_type: e.value })
              }
              options={AdminAllow? ContactTypes2:ContactTypes}
            />
          </div>
        </Col>:
        <></>
        // <Col sm={12} md={4} lg={4}>
        //       <div>
        //         <p className="input-label mb-2">Residential Address</p>
        //         <InputText
        //           onFocus={(e) => (e.target.value = "")}
        //           onBlur={(e) => (e.target.value = `${Address}`)}
        //           ref={AddressRef}
        //           type={"text"}
        //           value={Address}
        //           placeholder="Enter Address"
        //           onChange={(e) => setAddress(AddressRef.current.value)}
        //           className=" edit-input-field"
        //         />
        //       </div>
        //     </Col>
          }  
          </Row>
          {(AdminAllow && VendorData.contact_type!=='Master Admin') &&
          <Row style={{paddingBottom:"0px !important",paddingTop:"1rem"}}>
          <div style={{width:"345px"}}>
            <p className="input-label mb-2">Password</p>
            <span className="p-input-icon-right">
              {showPass === false ? (
                <i
                  className="pi pi-eye-slash"
                  style={{ cursor: "pointer", paddingTop: "3px" }}
                  onClick={() => setShowPass(!showPass)}
                ></i>
              ) : ( 
                <i
                  className="pi pi-eye-slash pi-eye"
                  style={{ cursor: "pointer", paddingTop: "3px" }}
                  onClick={() => setShowPass(!showPass)}
                ></i>
              )}
              <InputText
                style={{ width: "320px" }}
                type={showPass === false ? "password" : "text"}
                name="password"
                value={userdata.password}
                onChange={handleChange}
                autoComplete={false}
              />
            </span>
            <PasswordCheck password={userdata.password}/>
            <p className={`${showPassMessage(userdata.password)}`} style={{fontSize:'13px',marginTop:'5px',width:'300px',textAlign:'center'}}>
              {showPassMessage(userdata.password)}
            </p>
          </div>
          </Row>
          }
          
          {/* {VendorData.contact_type !=="Master Admin" &&
          <Row style={{ paddingBottom: "0px !important", paddingTop: "1rem" }}>
          <Col sm={12} md={4} lg={4}>
            <div>
              <p className="input-label mb-2">Residential Address</p>
              <InputText
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${Address}`)}
                ref={AddressRef}
                type={"text"}
                value={Address}
                placeholder="Enter Address"
                onChange={(e) => setAddress(AddressRef.current.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
        </Row>
          } */}
        </div>
      </ContactContainer>
    </>
  );
};

export default ContactProfile;
