import React from "react";
import { BsCheck } from "react-icons/bs";

import classes from "./ProgressNavbar.module.css";
export default function ProgressNavbar({
  steps = 0,
  current = 0,
  completed = 0,
}) {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${classes.ProgressNavbarContainer}`}
    >
      <p className={classes.stepsTitle}>Portfolio Journey</p>
      {[...Array(steps)]?.map((_, idx) => {
        return (
          <React.Fragment key={idx}>
            {idx > 0 && idx < steps && (
              <span
                className={`
                 ${idx < completed + 1 ? classes.hlineCompleted : classes.hline}
                 ${idx === 1 ? classes.Shorthline : ""}
                `}
              ></span>
            )}
            <span
              className={`${classes.stepCircle} ${
                idx < completed
                  ? classes.stepCircleCompleted
                  : idx === current - 1 && classes.stepCircleActive
              }`}
            >
              {idx < completed ? (
                <BsCheck style={{ fontSize: "18px" }} color="white" />
              ) : idx === current ? (
                idx + 1
              ) : (
                idx + 1
              )}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
}
