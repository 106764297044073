import React, { useState, useEffect } from "react";

export const useGeneratorECLE = (
    useEquity,
    percentageEquity,
    useCash,
    percentageCash,
    useLiquidAssets,
    PercentageLiquidAssets,
    liquidAssets,
    useExtraCash,
    extraCash,
    TotalDepositEquity,
    TotalDepositCash,
    cashAvailable,
    equityAvailable,
    TotalDepositLiquidAssets,
    TotalDepositExtraCash,
    Locked,
    TotalDepositRequired,
    handleCallBack
 
) => {
const[notice,setnotice]=useState("")

const handleDeposit=()=>{
    if(!Locked){
        let totaldebtRequired=TotalDepositRequired
        let totaldepositextracash=0;
        let totaldepositLiquidAssets=0
        let totaldepositcash=0
        let totaldepositequity=0
        let cashPercentage = 100 
         let equityPercentage = 100
         let liquidPercent=0
        if(useExtraCash=='Y'){
            totaldepositextracash=((extraCash*100)/100||0)>totaldebtRequired ? totaldebtRequired:((extraCash * 100) / 100||0)
            totaldebtRequired = totaldebtRequired - totaldepositextracash
        }else{
            totaldepositextracash=0
            totaldebtRequired = totaldebtRequired - totaldepositextracash
        }
        if(useLiquidAssets=='Y'){
            totaldepositLiquidAssets = ((liquidAssets * PercentageLiquidAssets)||0) / 100 > totaldebtRequired ? totaldebtRequired : ((liquidAssets * PercentageLiquidAssets) / 100 || 0)
            totaldebtRequired = totaldebtRequired - totaldepositLiquidAssets
        }else{
            totaldepositLiquidAssets=0
            liquidPercent=0
            totaldebtRequired = totaldebtRequired - totaldepositLiquidAssets
          
        }
        if(useExtraCash=='N' && useLiquidAssets=='N'){
            if(useEquity=='Y'){
                totaldepositequity=((equityAvailable*equityPercentage)/100||0)>totaldebtRequired ? totaldebtRequired:((equityAvailable * equityPercentage)/100 || 0)
                totaldepositequity = totaldepositequity < 0 ? 0 : totaldepositequity
                totaldebtRequired = totaldebtRequired - totaldepositequity
                equityPercentage=(totaldepositequity/equityAvailable)*100||0
            }else{
                totaldepositequity=0
            }
            if(useCash=='Y'){
                totaldepositcash = ((cashAvailable * cashPercentage) / 100 || 0 )> totaldebtRequired ? totaldebtRequired : ((cashAvailable * cashPercentage) / 100||0)
                totaldepositcash = totaldepositcash < 0 ? 0 : totaldepositcash
                cashPercentage=(totaldepositcash / cashAvailable) * 100||0
            }else{
                totaldepositcash=0
            }
           
            
            
        }else{
           if(useCash=='Y'){
            totaldepositcash = ((cashAvailable * cashPercentage) / 100 || 0 )> totaldebtRequired ? totaldebtRequired : ((cashAvailable * cashPercentage) / 100||0)
            totaldepositcash = totaldepositcash < 0 ? 0 : totaldepositcash
            totaldebtRequired = totaldebtRequired - totaldepositcash
           }else{
            totaldepositcash=0
           }
           if(useEquity=='Y'){
            totaldepositequity=((equityAvailable * equityPercentage)/100||0)>totaldebtRequired ? totaldebtRequired:((equityAvailable * equityPercentage)/100 || 0)
            totaldepositequity = totaldepositequity < 0 ? 0 : totaldepositequity
           }else{
            totaldepositequity=0
           }
        }
        handleCallBack(
            totaldepositextracash,
            totaldepositLiquidAssets,
            liquidPercent,
            totaldepositequity,
            totaldepositcash,
            equityPercentage,
            cashPercentage
            );
       
    }
}

useEffect(()=>{
handleDeposit();
},[
useLiquidAssets,
PercentageLiquidAssets,
useExtraCash,
extraCash,
useCash,
useEquity,
])
  return {notice  };
};
