import React from "react";
import { ButtonGradient } from "./Button.style";
const GradientButton = ({ txt="Next", onClick, style = {},classes='',icon,width,disabled=false }) => {
  return (
    <ButtonGradient txt={txt} width={width} className={classes} style={style} onClick={onClick} disabled={disabled}>
      {icon!==undefined && icon}
    {txt === undefined ? "Next" : txt}
    </ButtonGradient>
  );
}; 

export default GradientButton;
