import React, { useState, useEffect } from "react";
import {
  DashboardContainer,
  BoxContainer,
  OuterContainer,
  LeftContainer,
  RightContainer,
  TaskCard,
  BlueCard1,
  BlueCard2,
  CardsContainer,
  SmallCard,
  DonotContainer,
  PropertyPortfolio,
  PropertyType,
  PortfolioSection,
  PortfolioCard,
} from "./UserDashboard.style";
import pic1 from "../../assets/images/Vector1.png";
import pic2 from "../../assets/images/Vector2.png";
import pic3 from "../../assets/images/Vector3.png";
import pic4 from "../../assets/images/Vector4.png";
import pic5 from "../../assets/images/Vector5.png";
import pic6 from "../../assets/images/Vector6.png";
import thunder from "../../assets/images/thunder.png";
import ArrowLeft from "../../assets/images/arrow-left.png";
import PropertyPic from "../../assets/images/property-img.png";
import PropertyPic2 from "../../assets/images/property-img2.png";
import PropertyPic3 from "../../assets/images/property-img3.png";
import PropertyPic4 from "../../assets/images/property-img4.png";
import BoxActive from "../SvgComponents/BoxActive";
import BoxInactive from "../SvgComponents/BoxInactive";
import { CurrencyFormatter } from "../../utils";
import { useSelector } from "react-redux";
import Chart from "../Charts/Chart";
import { GS } from "../../services/global";
import { API } from "../../services/api";
import { commaSeparators } from "../../utils";
import "./home.css";
const UserDashboard = () => {
  const [CheckAdmin, setCheckAdmin] = useState(true);
  let accountType = sessionStorage.getItem("accountType")=="Contacts"?localStorage.getItem("accountType"):sessionStorage.getItem("accountType");
  const mystate=useSelector((state)=>state.UserProfile)
  const[goalValue,setgoalValue]=useState(0);
  let [tableData, setTableData] = useState(
    GS.SOT.PortfolioSummary.tableData ? GS.SOT.PortfolioSummary.tableData : []
  );
  let [CashflowMonthly,setCashflowMonthly]=useState(GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowMonthly)
  const [primaryProperty, setprimaryProperty] = useState(GS.SOT.Equity.PPR);
  const [investmentProperties, setinvestmentProperties] = useState(
    GS.SOT.Equity.properties
  );
  const [cashOnHand, setCashOnHand] = useState(
    GS?.SOT?.StrategicSpending?.cashOnHand
  );
  const [CashPlusEquity, setCashPlusEquity] = useState(
    GS?.SOT?.Equity.TequityGain
  );
  const [EquityGain, setEquityGain] = useState(
    isNaN(GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain)
      ? 0
      : GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain
  );
  const [EquityGains, setEquityGains] = useState(
    GS?.SOT?.Equity?.EquityGains || []
  );
  const [TaskLeft, setTaskLeft] = useState(
    GS.SOT.Progress?.steps - GS.SOT.Progress.completed
  );
  const [AverageYield, setAverageYield] = useState(
    isNaN(
      GS?.SOT?.PropertyGenerators?.reduce(
        (total, NextProperty) =>
          (total += NextProperty?.tableData?.NetYieldonPropertyYear1),
        0
      ) / GS?.SOT?.PropertyGenerators.length
    )
      ? "0"
      : (
          GS?.SOT?.PropertyGenerators?.reduce(
            (total, NextProperty) =>
              (total += NextProperty?.tableData?.NetYieldonPropertyYear1),
            0
          ) / GS?.SOT?.PropertyGenerators.length
        ).toFixed(2)
  );
  const [IncomeGoal, setIncomeGoal] = useState({
    value: parseFloat(GS?.SOT?.BorrowingCalculator?.income_goal),
    year: parseInt(GS?.SOT?.BorrowingCalculator?.FinancialYear),
  });
  const options = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        padding: 50,
      },

      datalabels: {
        color: "white",
        backgroundColor: "black",
        formatter: (value, context) => {
          return `${value}%`;
        },
      },
    },

    // plugins:[ChartDataLabels]
  };
  const options2 = {
    circumference:180,
    rotation:270,
    cutout:'90%',
    plugins: {
      legend: {
        display: false,
        position: "right",
        padding: 50,
      },

      datalabels: {
        display: false,
      
      },
    },

    // plugins:[ChartDataLabels]
  };
  const donutdata = {
    labels: ["Product 1", "Product 2"],
    datasets: [
      {
        label: "Weekly Sales",
        data: [goalValue, 100-goalValue],
        backgroundColor: ["#00a779", "#f3f4f8"],

        borderWidth: 1,
      },
    ],
  };
  const data = {
    labels: ["Product 1", "Product 2", "Product 3", "Product 4"],
    datasets: [
      {
        label: "Weekly Sales",
        data: [15, 15, 15, 9],
        backgroundColor: ["#00a779", "#fee468", "#c1e5e3", "#1c816d"],

        borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: ["Product 1", "Product 2", "Product 3"],
    datasets: [
      {
        label: "Weekly Sales",
        data: [15, 15, 40],
        backgroundColor: ["#1c816d", "#00a76d", "#c3de95"],

        borderWidth: 1,
      },
    ],
  };
  const data3 = {
    labels: ['Product 1', 'Product 2'],
    datasets: [{
      label: 'Weekly Sales',
      data: [20, 55],
      backgroundColor: [
        '#bae7e5',
        '#e5f5f4',
       
      ],
      
      borderWidth: 1
    }]
  };
   const data4 = {
    labels: ['Product 1'],
    datasets: [{
      label: 'Weekly Sales',
      data: [ 100],
      backgroundColor: [
        '#bae7e5',
       
       
      ],
      
      borderWidth: 1
    }]
  };
  // config 
  // const options = {
    
    
  //     plugins:{
  //       legend:{
  //         display:true,
  //         position:"right",
  //         padding: 50,
  //       },
       
  //       datalabels:{
  //         color:"white",
  //         backgroundColor:"black",
  //         formatter:(value,context)=>{
  //           return `${value}%`
  //         }
  //       },
        
  //     },
    
  // };
  // config
 
  const calculateCashAndEquityAvailable = () => {
    // let sum = cashOnHand;
    // sum += EquityGain
    // let v = 0;
    // if (EquityGains.length > 0) {
    //   EquityGains.map((item, index) => {
    //     let v1 = item.value / item.noOfMonths;
    //     v = v + v1;
    //   });
    // }
    // return sum + v;
    return CashPlusEquity;
  };
  const checkAdminAccount=()=>{
     accountType = sessionStorage.getItem("accountType")=="Contacts"?localStorage.getItem("accountType"):sessionStorage.getItem("accountType");
  
    if (accountType === "Contacts" || accountType === "Contact Lite" || accountType === "Contact Paid") {
      setCheckAdmin(false);
      
    } else {
      setCheckAdmin(true);
    }
    return ((accountType === "Contacts")|| (accountType === "Contact Lite") )
  }
  const [portfolioValue,setPortfolioValue]=useState(0)
const getUserData=async()=>{
  let id=sessionStorage.getItem("userId")
  let checkAdmin=checkAdminAccount();
  if(checkAdmin){
    let response = await API.get(`/vendor-profile/get/${id}`);
    setinvestmentProperties(response.data.Equity.properties
      )
      setprimaryProperty(response.data.Equity.PPR)
      setTaskLeft(response.data.Progress.steps-response.data.Progress.completed)
      setIncomeGoal({ value: parseFloat(response.data.BorrowingCalculator.income_goal), year: parseInt(response.data.BorrowingCalculator.FinancialYear) })
      setCashOnHand(response.data.StrategicSpending?.cashOnHand||0)
      setEquityGain(isNaN(response.data.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain)? 0: response.data.StrategicSpending.Data[0]?.balanceSheet?.EquityGain)
      setEquityGains(response.data.Equity.EquityGains||[])
      setTableData( response.data.PortfolioSummary.tableData ? response.data.PortfolioSummary.tableData : [])
     setCashPlusEquity(response.data.Equity.TequityGain)
     if(response.data.PortfolioSummary.tableData.length>0){
      response.data.Equity.properties.length>0?setPortfolioValue(response.data.PortfolioSummary.tableData[8].slice(-1)[0]):setPortfolioValue(0)
    }
    if(response.data.PropertyGenerators.length>0){
      
      setAverageYield(
        isNaN(
          response.data.PropertyGenerators.reduce(
            (total, NextProperty) =>
              (total += NextProperty.tableData.NetYieldonPropertyYear1),
            0
          ) / response.data.PropertyGenerators.length
        )
          ? "0"
          : (
            response.data.PropertyGenerators?.reduce(
                (total, NextProperty) =>
                  (total += NextProperty.tableData.NetYieldonPropertyYear1),
                0
              ) / response.data.PropertyGenerators.length
            ).toFixed(2)
      )
      setCashflowMonthly(response.data.PropertyGenerators[0].summaryData.CashflowMonthly)
      let cashflowValue=response.data.PropertyGenerators[0].summaryData.CashflowAnnual
      if(cashflowValue==0){
        setgoalValue(0)
      }else{
        let income_goal=response.data.BorrowingCalculator.income_goal
        let percentage=parseInt((cashflowValue/income_goal)*100)
      
        if(cashflowValue>=income_goal){
          percentage=100
        }
        if(isNaN(percentage)){
          percentage=0
        }
        setgoalValue(percentage)
      }
    }
  }

  
  
}
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
   
    {CheckAdmin===true && <DashboardContainer>
      <h1> </h1>
      <div>
        <BoxContainer>
          <p>
            <div></div> Coming Soon
          </p>
          <h1>
            We Are <br /> Launching Soon
          </h1>
        </BoxContainer>
      </div>
    </DashboardContainer>}
    {CheckAdmin===false && <>
    <OuterContainer>
      <LeftContainer>
<TaskCard style={{color:"black "}}>
Hi {mystate.first_name}! <br/>
{/* You have <span>{TaskLeft} task</span> to complete. */}
</TaskCard>
<CardsContainer>
  <div className="card-row" style={{marginBottom:"20px"}}>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Lifestyle Goal</span>
      <span className="span2">{IncomeGoal
                        ? CurrencyFormatter.format(IncomeGoal?.value)
                        : "$0.00"}</span>
    </div>
    <div className="imgContainer"> 
      <img src={pic1}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>In Which Financial Year</span>
      <span className="span2">{IncomeGoal.year}</span>
    </div>
    <div className="imgContainer">
      <img src={pic2}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Total Portfolio Value</span>
      <span className="span2"> 
      {tableData &&
                  CurrencyFormatter.format(portfolioValue)}
          
           </span>
    </div>
    <div className="imgContainer">
      <img src={pic3}/>
    </div>
  </SmallCard>
  </div>
  <div className="card-row">
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Average Yield</span>
      <span className="span2"> {
     AverageYield}%</span>
    </div>
    <div className="imgContainer">
      <img src={pic4}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Cash + Equity Available</span>
      <span className="span2">{CurrencyFormatter.format(
                  // GS.SOT.StrategicSpending.cashOnHand +
                  //   GS?.SOT?.PropertyGenerators[0]?.equityAvailable
                  calculateCashAndEquityAvailable() || 0
                )}</span>
    </div>
    <div className="imgContainer">
      <img src={pic5}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Year 1 Cashflow (Monthly)</span>
      <span className="span2">{CashflowMonthly===undefined?0:CashflowMonthly.toFixed(2)}</span>
    </div>
    <div className="imgContainer">
      <img src={pic6}/>
    </div>
  </SmallCard>
  </div>
</CardsContainer>
<PropertyPortfolio>
  <div className="portfolio-header">
<span >My Property Portfolio</span>
{/* <a>View All <img src={ArrowLeft}/></a> */}
  </div>
  <div className="propertyList">
    <table>
      
      <tbody>
      {(primaryProperty.Address.length>0 || primaryProperty.DomainAutoVal.length>0) && <tr>
          <td className="property-list-img">
            {/* <div><img src={PropertyPic}/></div> */}
            <span>{primaryProperty.Address}</span></td>
<PropertyType color="#00a779" bgcolor="#ebfff9">Primary</PropertyType>
<td className="value">Valued at
<br/><span className="Blurtxt">$0000000</span></td>
{/* <td className="property-icon"> <BoxActive color="#272660"/></td>  {commaSeparators(primaryProperty.DomainAutoVal)}*/}
        </tr>} 
        {investmentProperties.length>0 && <>
        {investmentProperties.map((item,index)=>{
          let PropertyImg=index%3==0?PropertyPic2:index%3==1?PropertyPic3:index%3==2?PropertyPic4:PropertyPic2
        
          return(
 
  <tr key={index}>
          <td className="property-list-img">
            {/* <div><img src={PropertyImg}/></div> */}
            <span>{item.IPAddress}</span></td>
<PropertyType color="#b7b6e2" bgcolor="#272660">Investment</PropertyType>
<td className="value">Valued at
<br/>{accountType==="Contact Lite"?<span className="Blurtxt">$0000000</span>:<span>{item?.REAValuation}</span> } 

</td>  

        </tr>
           )
          //  {commaSeparators(item.REAValuation)}
        })}
        
        </>} 
      {investmentProperties.length<0 &&(primaryProperty.Address.length<0 || primaryProperty.DomainAutoVal.length<0)&& <tr style={{display:"flex",justifyContent:"center"}}>
          <td >No Properties</td>
        </tr>}  
     
       
                    </tbody>
                  </table>
                </div>
              </PropertyPortfolio>
            </LeftContainer>
            <RightContainer>
              <BlueCard1>
                <div className="txt-container">
                  <span className="goal">Income Goal</span>
                  <span>
                    {IncomeGoal
                      ? CurrencyFormatter.format(IncomeGoal?.value)
                      : "$0.00"}
                  </span>
                </div>
                <div className="animation-container">
                  <img
                    id="1"
                    loop="infinite"
                    src={"https://i.ibb.co/nPzKW1j/goal-Anime-Animation.gif"}
                    alt="Goal Anime Animation"
                    playing={true}
                  />
                </div>
              </BlueCard1>
              <BlueCard2>
                <span style={{ color: "white" }}>Lifestyle Goal</span>
                <DonotContainer>
                <div className="piechart-section">
  <Chart 
  data={donutdata}
  type='donut'
  options={options2}
  />
  <div className="semidonut ">
                      <div>
                        <img src={thunder} />
                      </div>
                      <span className="icometxt">
                        {IncomeGoal
                          ? CurrencyFormatter.format(IncomeGoal?.value)
                          : "$0.00"}
                      </span>
                      <div className="indicators">
                      <span>0%</span>
                      <span>100%</span>
                    </div>
                    <div className="year">
                      <span className="year-desc">Year {IncomeGoal.year}</span>
                    </div>
                    </div>
  </div>
                 
                </DonotContainer>
              </BlueCard2>
            </RightContainer>
          </OuterContainer>
        
          {/* <PortfolioSection>
<PortfolioCard>
  <div className="chart-header-section">
    <div className="chart-names">
      <span className="secondarytxt">SUMMARY</span>
      <span className="bold-txt">5.987,34</span>
      <span className="secondarytxt">Current Portfolio Allocation</span>
    </div>
    <div className="chart-info">i</div>
  </div>
  <div className="piechart-section">
  <Chart 
  data={data}
  
  options={options}
  />
  </div>
</PortfolioCard>
<PortfolioCard>
<div className="chart-header-section">
    <div className="chart-names">
      <span className="secondarytxt">INCOME PLAN</span>
      <span className="bold-txt">5.987,34</span>
      <span className="secondarytxt">Current Portfolio Allocation</span>
    </div>
    <div className="chart-info">i</div>
  </div>
  <div className="piechart-section">
  <Chart 
  data={data2}
  options={options}
  />
  </div>
</PortfolioCard>
<PortfolioCard>
<div className="chart-header-section">
    <div className="chart-names">
      <span className="secondarytxt">FINANCE</span>
      <span className="bold-txt">5.987,34</span>
      <span className="secondarytxt">Current Portfolio Allocation</span>
    </div>
    <div className="chart-info">i</div>
  </div>
  <div className="piechart-section">
  <Chart 
  data={data3}
  options={options}
  />
   </div>
</PortfolioCard>
<PortfolioCard>
<div className="chart-header-section">
    <div className="chart-names">
      <span className="secondarytxt">FINANCE</span>
      <span className="bold-txt">5.987,34</span>
      <span className="secondarytxt">Current Portfolio Allocation</span>
    </div>
    <div className="chart-info">i</div>
  </div>
  <div className="piechart-section">
  <Chart 
  data={data4}
  options={options}
  type='doughnut'
  />
   </div>
</PortfolioCard>
    </PortfolioSection> */}
        </>
      }
    </>
  );
};

export default UserDashboard;
