import React, { useState, useRef, useEffect } from "react";
import { ContactContainer} from "./Contact.style";
import Button from "../Button/Button";
import Avatar from "../Avatar/Avatar";
import { API } from "../../services/api";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ValidateEmail, ValidateName,checkPasswordStrength } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContactUser from "../SvgComponents/ContactUser";

const CreateAdmin = () => {
  const toast = useRef(null);
  const [alldata, setalldata] = useState([]);
  const navigate = useNavigate();

  let dispatch = useDispatch();
  const mystate = useSelector((state) => state.Vendors);

  const [showPass, setShowPass] = useState(true);
  const [ButtonDisable, setButtonDisable] = useState(false);
 

  const [userdata, setuserdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "+61",
    telephone_no: "",
    contact_type: "Master Admin",
    password: "",
    agent: "",

    preferred_contact_method: "",
    photo: "",
    role_id: "610a0f1dc61edfcf62d62bb1",   //610a0f1dc61edfcf62d62bb1 master admin id
    office_id: "610a0f1dc61edfcf62d62c52",
  });

  const [address, setaddress] = useState("");

  

  const handleChange = (e) => {
    if (e.target.name === "mobile_no") {
      const formatNumber = formatPhoneNumber(e.target.value);
      setuserdata({ ...userdata, [e.target.name]: formatNumber });
    } else if (e.target.name === "telephone_no") {
      const formatNumber = formatTelephoneNumber(e.target.value);
      setuserdata({ ...userdata, [e.target.name]: formatNumber });
    } else {
      setuserdata({ ...userdata, [e.target.name]: e.target.value });
    }
  };

  const formatPhoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
        4,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
      4,
      7
    )} ${phoneNumber.slice(7, phoneNumber.length)}`;
  };
  const formatTelephoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 2) return phoneNumber;
    if (phoneNumber.length < 6) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      6
    )} ${phoneNumber.slice(6, phoneNumber.length)}`;
  };
  const ChangePhoneFormat=()=>{
    if(userdata.mobile_no[0]!=="+"){
     if(userdata.mobile_no[0]=="0"){
       let newnumber="+61"+userdata.mobile_no.slice(1,userdata.mobile_no.length)
       setuserdata({...userdata,mobile_no:newnumber})
     }else{
       let newnumber="+61"+userdata.mobile_no
       setuserdata({...userdata,mobile_no:newnumber})
     }
    }
   
   }
  const saveUser = async () => {
   
    let response = await API.post("/contacts", {
      ...userdata,
      contact_address: address,
    });
    if(response?.insertedId){
        showSuccess()
    }else{
        showError()
    }
   console.log(response,"response")

  };
  
  const checkEmailExistance = (email) => {
    let check = false;
    alldata.map((item) => {
      if (item.email === email) {
        check = true;
      }
    });
   
    return check;
  };
  const handleAdd = async () => {
    let {valid,message}=checkPasswordStrength(userdata.password)
    if (ButtonDisable == false) {
      if (userdata.first_name === "") {
        showError("First name should not be empty");
      } else if (userdata.last_name === "") {
        showError("Last name should not be empty");
      } else if (userdata.email === "") {
        showError("Email should not be empty");
      } else if (userdata.password === "") {
        showError("Password should not be empty");
      } else {
        ValidateName(userdata.first_name) === false
          ? showError("Name contain atleast 3 characters")
          : ValidateName(userdata.last_name) === false
          ? showError("Name contain atleast 3 characters")
          : ValidateEmail(userdata.email) === false
          ? showError("Invalid Email format ")
          : checkEmailExistance(userdata.email) === true
          ? showError(" Email Already Exist ")
          : valid && saveUser();
      }
    }
    setButtonDisable(true);
  };
  const showPassMessage=(password)=>{
    let {valid,message}=checkPasswordStrength(password)
    return message
  }
  const clear = () => {
    toast.current.clear();
    setButtonDisable(false);
  };

  useEffect(() => {
    const getAdminData = async () => {
      let response = await API.get(`users?role=610a0f1dc61edfcf62d62bb1`);
      setalldata(response.data);
      console.log(response.data,"response")
      
    };
    getAdminData();
  }, []);
  const showError = (Message) => {
    toast.current.show({
      severity: "success",
      life: 2000,
      content: (
        <div className="flex flex-column p-5" style={{ flex: "1" }}>
          <div className="text-center py-1">
            <p className="popup-error-text">{Message}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span
              className="toast-anchor toast-no"
              style={{ marginTop: "15px" }}
              onClick={clear}
            > 
              Ok
            </span>
          </div>
        </div>
      ),
    });
  };

  const showSuccess = (userId) => {
    console.log(userId,"newuserId")
    toast.current.show({
      severity: "success",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "55px", paddingBottom: "55px" }}
        >
          <div className="text-center ">
            <div>
              <ContactUser />
            </div>
            <p className="popup-success-text">
              Admin is created
            </p>
          </div>
          
        </div>
      ),
    });
  };
 
  const inputRef = useRef();
  const autoCompleteRef = useRef();

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current 
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      setaddress(place.formatted_address);
    });
  }, []);

  return (
    <ContactContainer>
      <Toast
        ref={toast}
        position="center"
        style={{ zIndex: "2", opacity: "1" }}
      />
      <div className="profile-container">
        <h1>Profile</h1>
        <Button onClick={handleAdd} txt="Create Admin" paddingTop />
      </div>
      <div className="avatar-container pb-3">
        <Avatar 
          text="CH"
          width="80px"
          height="80px"
          fontSize="23px"
          textColor="#fefefe"
          imgSrc={userdata.photo}
          setuserdata={setuserdata}
          data={userdata}
        />

        <div className="avatar-text-container">
          <p className="main-text">
            {userdata.first_name}&nbsp;{userdata.last_name}
          </p>
          <p className="sub-text">{userdata.contact_type}</p>
        </div>
      </div>
      <div>
        <div
          className="py-3"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          <div>
            <p className="input-label mb-2">First name</p>
            <InputText
              style={{ width: "320px" }}
              type={"text"}
              name="first_name"
              className=" edit-input-field"
              value={userdata.first_name}
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-label mb-2">Last name</p>
            <InputText
              style={{ width: "320px" }}
              type={"email"}
              className=" edit-input-field"
              name="last_name"
              value={userdata.last_name}
              onChange={handleChange}
            />
          </div>

          <div>
            <p className="input-label mb-2">Email</p>
            <InputText
              style={{ width: "320px" }}
              type={"email"}
              name="email"
              value={userdata.email}
              onChange={handleChange}
              className=" edit-input-field"
            />
          </div>
        </div>
        <div
          className="py-3"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          <div>
            <p className="input-label mb-2">Mobile No</p>
            <InputText
              style={{ width: "320px" }}
              onBlur={ChangePhoneFormat}
              name="mobile_no"
              value={userdata.mobile_no}
              onChange={(e)=>{
                setuserdata({ ...userdata, mobile_no: e.target.value });
              }}
              className=" edit-input-field"
              format
            />
          </div>
          <div>
            <p className="input-label mb-2">Phone Number</p>
            <InputText
              style={{ width: "320px" }}
              type={"text"}
              name="telephone_no"
              className=" edit-input-field"
              value={userdata.telephone_no}
              onChange={handleChange}
            />
          </div>

          
        </div>
        <div
          className="py-3"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          {/* <div>
            <p className="input-label mb-2">Residential Address</p>
            <InputText
              style={{ width: "320px" }}
              onFocus={(e) => (e.target.value = "")}
              placeholder="Enter Residential Address"
              ref={inputRef}
              name="contact_address"
              onChange={(e) => setaddress(e.target.value)}
              className=" edit-input-field"
            />
          </div> */}

          <div>
            <p className="input-label mb-2">Password</p>
            <span className="p-input-icon-right">
              {showPass === false ? (
                <i
                  className="pi pi-eye-slash"
                  style={{ cursor: "pointer", paddingTop: "3px" }}
                  onClick={() => setShowPass(!showPass)}
                ></i>
              ) : ( 
                <i
                  className="pi pi-eye-slash pi-eye"
                  style={{ cursor: "pointer", paddingTop: "3px" }}
                  onClick={() => setShowPass(!showPass)}
                ></i>
              )}
              <InputText
                style={{ width: "320px" }}
                type={showPass === false ? "password" : "text"}
                name="password"
                value={userdata.password}
                onChange={handleChange}
                autoComplete={false}
              />
            </span>
            <p className={`${showPassMessage(userdata.password)}`} style={{fontSize:'13px',marginTop:'5px',width:'300px',textAlign:'center'}}>
              {showPassMessage(userdata.password)}
            </p>
          </div>
        </div>
      </div>
    </ContactContainer>
  );
};

export default CreateAdmin;
