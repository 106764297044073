import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { API } from "../../services/api";
import { CurrencyFormatter } from "../../utils";
import styles from "./stylesheet.module.css";
import { RadioButton } from "primereact/radiobutton";
export default function AssumptionSettings() {
  const [BaseExpense, setBaseExpense] = useState(0);
  const [DependentBaseExpense, setDependentBaseExpense] = useState(0);
  const [ServiceIncomeFactors, setServiceIncomeFactors] = useState(0);
  const[propertytype,setpropertytype]=useState("")
  const [DSR,setDSR]=useState(40);
  const [DefaultBPIntrest, setDefaultBPIntrest] = useState(0);
  const [InterestRateBuffer, setInterestRateBuffer] = useState(0);
  const [CPI, setCPI] = useState(0);
  const [isCall, setIsCall] = useState(false);
  const [DTI, setDTI] = useState(6);

  const[InterestRate,setInterestRate]=useState(5)
  const[DebtInterestRate,setDebtInterestRate]=useState(4)
  const[DebtPrincipalRate,setDebtPrincipalRate]=useState(0)
  const[EquityInterestRate,setEquityInterestRate]=useState(1)
  const[EquityPrincipalRate,setEquityPrincipalRate]=useState(0)

  let office_id = "610a0f1dc61edfcf62d62c52";

  const handleAssumptionData = async () => {
    setTimeout(async () => {
      let data = {
        name: "Borrowing Assumptions",
        slug: "borrowing-assumptions",
        settings: {
          SingleBaseExpense: BaseExpense,
          DependentBaseExpensive: DependentBaseExpense,
          ServiceIncomeFactors: ServiceIncomeFactors,
          DefaultBPInterestRate: DefaultBPIntrest,
          InterestRateBuffer: InterestRateBuffer,
          CPI: CPI,
          DSR:DSR,
          DTI,
          InterestRate:InterestRate,
          DebtInterestRate:DebtInterestRate,
          DebtPrincipalRate:DebtPrincipalRate,
          EquityInterestRate:EquityInterestRate,
          EquityPrincipalRate:EquityPrincipalRate
        },
        office_id: office_id,
      };
      await API.post(`settings`, data);
    }, 2000);
  };

  let getAssumptionData = async () => {
    let res = await API.get(`settings?office_id=${office_id}`);
    setBaseExpense(res.data[0].settings.SingleBaseExpense);
    setDependentBaseExpense(res.data[0].settings.DependentBaseExpensive);
    setServiceIncomeFactors(res.data[0].settings.ServiceIncomeFactors);
    setDefaultBPIntrest(res.data[0].settings.DefaultBPInterestRate);
    setInterestRateBuffer(res.data[0].settings.InterestRateBuffer);
    setCPI(res.data[0].settings.CPI);

  
    
   
    
   
    if (
      res.data[0].settings.DTI !== undefined ||
      res.data[0].settings.DTI !== null
    ) {
      setDTI(res.data[0].settings.DTI);
    } else {
      setDTI(6);
    } 
    if (
      res.data[0].settings.DSR !== undefined ||
      res.data[0].settings.DSR !== null
    ) {
      setDSR(res.data[0].settings.DSR);
    } else {
      setDSR(40);
    }
    // if(res?.data[0]?.settings?.InterestRate !== undefined || res?.data[0]?.settings?.InterestRate !== null){
    //   setInterestRate(res.data[0].settings.InterestRate);
    //   }else{
    //     setInterestRate(5);
    //   }

      if(res?.data[0]?.settings?.DebtInterestRate !== undefined || res?.data[0]?.settings?.DebtInterestRate !== null){
        setDebtInterestRate(res.data[0].settings.DebtInterestRate);
        setInterestRate(res.data[0].settings.DebtInterestRate);
        }else{
          setDebtInterestRate(5);
          setInterestRate(5);
        }
        if(res?.data[0]?.settings?.EquityInterestRate !== undefined || res?.data[0]?.settings?.EquityInterestRate !== null){
          setEquityInterestRate(res.data[0].settings.EquityInterestRate);
          }else{
            setEquityInterestRate(5);
          }



    if(res?.data[0]?.settings?.DebtPrincipalRate !== undefined || res?.data[0]?.settings?.DebtPrincipalRate !== null){
      setDebtPrincipalRate(res.data[0].settings.DebtPrincipalRate);
      }else{
        setDebtPrincipalRate(0);
      }
      if(res?.data[0]?.settings?.EquityPrincipalRate !== undefined || res?.data[0]?.settings?.EquityPrincipalRate !== null){
        setEquityPrincipalRate(res.data[0].settings.EquityPrincipalRate);
        }else{
          setEquityPrincipalRate(0);
        }
    setIsCall(true);
  };

  useEffect(() => {
    getAssumptionData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isCall && handleAssumptionData();
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    BaseExpense,
    DependentBaseExpense,
    ServiceIncomeFactors,
    DefaultBPIntrest,
    InterestRateBuffer,
    CPI,
    DTI,
    DSR,
    InterestRate,
    EquityInterestRate,
    EquityPrincipalRate,
    DebtInterestRate,
    DebtPrincipalRate
  ]);

  return (
    <Card className={`card ${styles.container}`}>
      <h1 className="card-title mb-3">Assumptions</h1>
      <Row>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-3">Single Base Expense</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={BaseExpense}
                  onChange={(e) => {
                    setBaseExpense(e.value);
                  }}
                  className={"slider-field"}
                  step={1}
                  min={1}
                  max={100000}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => {
                  setBaseExpense(e.value);
                }}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) =>
                  (e.target.value = `${CurrencyFormatter.format(BaseExpense)}`)
                }
                value={BaseExpense}
                mode="currency"
                currency="USD"
                locale="en-US"
                minfractiondigits={0}
                inputClassName={"readonly-input-field w-98"}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={6}>
          <div>
            <p className="input-label mb-3">Dependent Base Expensive</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={DependentBaseExpense}
                  onChange={(e) => setDependentBaseExpense(e.value)}
                  step={1}
                  min={1}
                  max={100000}
                  className={"slider-field "}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => setDependentBaseExpense(e.value)}
                value={DependentBaseExpense}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) =>
                  (e.target.value = `${CurrencyFormatter.format(
                    DependentBaseExpense
                  )}`)
                }
                mode="currency"
                currency="USD"
                locale="en-US"
                minfractiondigits={0}
                inputClassName={"readonly-input-field w-89"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-3">Service Income Factors</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={ServiceIncomeFactors}
                  onChange={(e) => setServiceIncomeFactors(e.value)}
                  className={"slider-field"}
                  step={0.1}
                  min={1}
                  max={100}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => setServiceIncomeFactors(e.value)}
                value={ServiceIncomeFactors}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${ServiceIncomeFactors}%`)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
                inputClassName={"readonly-input-field w-67"}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={5}>
          <div>
            <p className="input-label mb-3">Default BP Interest Rate</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={DefaultBPIntrest}
                  onChange={(e) => setDefaultBPIntrest(e.value)}
                  className={"slider-field"}
                  step={0.1}
                  min={1}
                  max={100}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => setDefaultBPIntrest(e.value)}
                value={DefaultBPIntrest}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${DefaultBPIntrest}%`)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
                inputClassName={"readonly-input-field w-67"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-3">Interest Rate Buffer</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={InterestRateBuffer}
                  onChange={(e) => setInterestRateBuffer(e.value)}
                  className={"slider-field"}
                  step={0.1}
                  min={1}
                  max={100}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => setInterestRateBuffer(e.value)}
                value={InterestRateBuffer}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${InterestRateBuffer}%`)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
                inputClassName={"readonly-input-field w-67"}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-3">Consumer Price Index (CPI)</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={CPI}
                  onChange={(e) => setCPI(e.value)}
                  className={"slider-field"}
                  step={0.1}
                  min={1}
                  max={100}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => setCPI(e.value)}
                value={CPI}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${CPI}%`)}
                suffix="%"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                inputClassName={"readonly-input-field w-67"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-3">DTI</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={DTI}
                  onChange={(e) => setDTI(e.value)}
                  className={"slider-field"}
                  step={1}
                  min={1}
                  max={6}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => setDTI(e.value)}
                value={DTI}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${DTI}`)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                inputClassName={"readonly-input-field w-67"}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
          <p className="input-label mb-3">Required DSR (Banks Benchmark)</p>
            <div className="d-flex gap-3 flex-wrap">
              <div className="mb-4">
                <Slider
                  value={DSR}
                  onChange={(e) => setDSR(e.value)}
                  className={"slider-field"}
                  step={0.1}
                  min={1}
                  max={100}
                />
                <div className="d-flex justify-content-between mt-3"></div>
              </div>
              <InputNumber
                onChange={(e) => setDSR(e.value)}
                value={DSR}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${DSR}%`)}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                suffix="%"
                inputClassName={"readonly-input-field w-67"}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
      {/* <Col sm={12} xs={12} md={12} lg={4}>
            <div>
              <p className="input-label mb-3">Interest Rate</p>
              <div className="d-flex gap-3 flex-wrap">
                <div className="mb-4">
                  <Slider
                    value={InterestRate}
                    onChange={(e) => setInterestRate(e.value)}
                    className={"slider-field"}
                    step={0.1}
                    min={1}
                    max={10}
                  />
                  <div className="d-flex justify-content-between mt-3"></div>
                </div>
                <InputNumber
                 onFocus={(e) => (e.target.value = "")}
                 onBlur={(e) => (e.target.value = `${InterestRate}%`)}
                  onChange={(e) => setInterestRate(e.value)}
                  value={InterestRate}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                  inputClassName={"readonly-input-field w-67"}
                />
              </div>
            </div>
          </Col> */}
          
          <Col sm={12} xs={12} md={12} lg={4}>
            <div>
              <p className="input-label mb-3">Debt Interest Rate</p>
              <div className="d-flex gap-3 flex-wrap">
                <div className="mb-4">
                  <Slider
                    value={DebtInterestRate}
                    onChange={(e) => {setDebtInterestRate(e.value);setInterestRate(e.value)}}
                    className={"slider-field"}
                    step={0.1}
                    min={1}
                    max={10}
                  />
                  <div className="d-flex justify-content-between mt-3"></div>
                </div>
                <InputNumber
                 onFocus={(e) => (e.target.value = "")}
                 onBlur={(e) => (e.target.value = `${DebtInterestRate}%`)}
                  onChange={(e) => {setDebtInterestRate(e.value);setInterestRate(e.value)}}
                  value={DebtInterestRate}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                  inputClassName={"readonly-input-field w-67"}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} xs={12} md={12} lg={4}>
            <div>
              <p className="input-label mb-3">Debt Principal Rate</p>
              <div className="d-flex gap-3 flex-wrap">
                <div className="mb-4">
                  <Slider
                    value={DebtPrincipalRate}
                    onChange={(e) => setDebtPrincipalRate(e.value)}
                    className={"slider-field"}
                    step={0.1}
                    min={0}
                    max={10}
                  />
                  <div className="d-flex justify-content-between mt-3"></div>
                </div>
                <InputNumber 
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = `${DebtPrincipalRate}%`)}
                  onChange={(e) => setDebtPrincipalRate(e.value)}
                  value={DebtPrincipalRate}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                  inputClassName={"readonly-input-field w-67"}
                />
              </div>
            </div>
          </Col>
      </Row>
      <Row className="card-row">
    
          <Col sm={12} xs={12} md={12} lg={4}>
            <div>
              <p className="input-label mb-3">Equity Interest Rate</p>
              <div className="d-flex gap-3 flex-wrap">
                <div className="mb-4">
                  <Slider
                    value={EquityInterestRate}
                    onChange={(e) => setEquityInterestRate(e.value)}
                    className={"slider-field"}
                    step={0.1}
                    min={1}
                    max={10}
                  />
                  <div className="d-flex justify-content-between mt-3"></div>
                </div>
                <InputNumber
                   onFocus={(e) => (e.target.value = "")}
                   onBlur={(e) => (e.target.value = `${EquityInterestRate}%`)}
                  onChange={(e) => setEquityInterestRate(e.value)}
                  value={EquityInterestRate}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                  inputClassName={"readonly-input-field w-67"}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} xs={12} md={12} lg={4}>
            <div>
              <p className="input-label mb-3">Equity Principal Rate</p>
              <div className="d-flex gap-3 flex-wrap">
                <div className="mb-4">
                  <Slider
                    value={EquityPrincipalRate}
                    onChange={(e) => setEquityPrincipalRate(e.value)}
                    className={"slider-field"}
                    step={0.1}
                    min={0}
                    max={10}
                  />
                  <div className="d-flex justify-content-between mt-3"></div>
                </div>
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) => (e.target.value = `${EquityPrincipalRate}%`)}
                  onChange={(e) => setEquityPrincipalRate(e.value)}
                  value={EquityPrincipalRate}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                  inputClassName={"readonly-input-field w-67"}
                />
              </div>
            </div>
          </Col>
          
      </Row>
      {/* <Row className="card-row">
      <Col sm={12} xs={12} md={12} lg={4}>
            <div>
            <div className="mb-3">
                <span className="lbl">Property Type</span>
                <div className="d-flex flex-wrap">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype1"
                      name="propertytype"
                      value="Residential"
                      onChange={(e) => { 
                       
                       }}
                      checked={propertytype === "Residential"}
                    />
                    <label htmlFor="propertytype1">Residential</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype2"
                      name="propertytype"
                      value="Commercial Retail"
                      onChange={(e) => { 
                     
                     }}
                      checked={propertytype === "Commercial Retail"}
                    />
                    <label htmlFor="propertytype2">Retail</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype3"
                      name="propertytype"
                      value="Commercial Office"
                      onChange={(e) => { 
                        
                       }}
                      checked={propertytype === "Commercial Office"}
                    />
                    <label htmlFor="propertytype3">Office</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype4"
                      name="propertytype"
                      value="Industrial"
                      onChange={(e) => { 
                       
                       }}
                      checked={propertytype === "Industrial"}
                    />
                    <label htmlFor="propertytype4">Industrial</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype5"
                      name="propertytype"
                      value="Granny Flat"
                      onChange={(e) => { 
                    
                     }}
                      checked={propertytype === "Granny Flat"}
                    />
                    <label htmlFor="propertytype5">Granny Flat</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype6"
                      name="propertytype"
                      value="NDIS"
                      onChange={(e) => { 
                     
                     }}
                      checked={propertytype === "NDIS"}
                    />
                    <label htmlFor="propertytype6">NDIS</label>
                  </div>

                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype7"
                      name="propertytype"
                      value="Rooming House"
                      onChange={(e) => { 
                       
                       }}
                      checked={propertytype === "Rooming House"}
                    />
                    <label htmlFor="propertytype7">Rooming House</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype8"
                      name="propertytype"
                      value="Micro Apartment"
                      onChange={(e) => { 
                   
                     }}
                      checked={propertytype === "Micro Apartment"}
                    />
                    <label htmlFor="propertytype8">Micro Apartment</label>
                  </div>


                </div>
              </div>
            </div>
          </Col>
      </Row> */}
    
    </Card>
  );
}
