import styled from 'styled-components'

export const PropertyContainer = styled.div`
margin-top: 20px;
padding: 1.5rem 0px;
padding-bottom: 1.5rem;
padding-left: 32px;
border-radius: 8px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02), 0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02), 0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);
 
  overflow-x: hidden;
  box-sizing: border-box;
  .error1{
    color:red;
    padding:5px 35px 10px 0px;
    margin-top:-95px;
    margin-bottom:2.5rem;
    width:300px;
    font-size:14px;
    font-weight:500;
  }
`
export const PropertyHeader=styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding-right: 32px;
div{
  display: flex;
  flex-direction: row;
  align-items: center;
  a{
    font-size: 12px;
    color:var(--main-blue);
    cursor: pointer;
    text-decoration: none;
    font-family: "Inter";
    margin-right: 3rem;
    font-weight: bold;
    margin-bottom: 0px;
  }
  i{
    width: 18px;
  height: 9px;
  cursor: pointer;
  color:var(--arrowColor)
}
}
h1{
  font-size: 24px !important;
    font-weight: 500;
    color: var(--blackColor);
    font-family: "Inter";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
 
  cursor: pointer;
}

`
export const PropertyBody=styled.div`
transition: all .2s ease;
margin-top: 0.8rem;
`
export const PropertyOuterContainer=styled.div`
display:flex;
flex-direction: column;
`
export const PropertyRowContainer=styled.div`
display: flex;
flex-direction: row;
margin-bottom:${({marginBottom})=>marginBottom?marginBottom:'3rem'} ;
flex-wrap: wrap;
`
export const ProperyGroup=styled.div`
 display:flex;
      flex-direction: column;
      margin-right: ${props=>props.marginRight};
      padding-top: ${props=>props.paddingTop};
      margin-bottom: ${props=>props.marginBottom};
      position: relative;
      label{
        font-family: "Inter";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-main-color);
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  position: relative;
      }
      .ToolTip-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:10px;
   
        .ToolTip{
          position: relative;
          margin-bottom: 8px;
          img{
            cursor: pointer;
          }
        }
      }
      .slider-field{
        width: 300px;
        margin-top: 20px;
      }
      .blue-tooltip.p-tooltip .p-tooltip-text {
    background-color: var(--blue-500);
}
      .Value{
        margin-top: 18px;
        font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; 
  text-align: left;
  color: var(--black-main-color);
      }
     
      .dropDown-container{
        display: flex;
        flex-direction: row;
        gap:10px;
        .InputBox{
            border-radius: 4px;
  border: solid 1px #e1e1e1;
  padding: 14px 16px 14px 10px;
  width: 120px;
  margin-left: 16px;
        }
        .dropDown-field{
            
  
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
 
  padding: 14px 7px;
  border-radius: 4px;
  
        }
      }
     
      .Drop-box{
          
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  padding:0px 0px 0px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
          .dropDown-field{
            width: 100%;
  border-radius: 0px;
  border: none !important;
  height:36px;
        }
        }
`
export const PropertyAddButton=styled.button`
padding: 23px 0 23px 0;
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap:10px;
text-decoration: none;
  border-radius: 8px;
  background-color: var(--light-blue);
  margin-top: 20px;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--solid-colors);
`
