import React from 'react'
import {ToolTipContainer} from "./ToolTip.style"
type TootipTypes={
  show?:any,
  txt?:any,
  width?:any,
  textAlign?:any,
  left?:any,
  top?:any,
  style?:any,
  borderRadius?:any
}
const ToolTips = ({show,txt,width,textAlign,left,top,style,borderRadius}:TootipTypes) => {
  
  return (
    <ToolTipContainer borderRadius={borderRadius} top={top} style={style} textAlign={textAlign} width={width} show={show} left={left}>{txt}</ToolTipContainer>
  )
}

export default ToolTips