import React from 'react';
import TermsAndPolicy from '../../components/TermsAndPolicy';
const Terms = () => {
 
  return (
   <> 
   
   <TermsAndPolicy/>
   
   </>
  )
}



export default Terms