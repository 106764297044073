import Navigation from "./routes/Navigation";
import "animate.css";
import { useEffect } from "react";
import { API } from "./services/api";
import { useDispatch, useSelector } from "react-redux";
import { getMedianPrice, setMedianData, Vendors } from "./redux/actions";
import { randomData } from "./randomPrices";
import Loading from "./components/Loading";
const resiRegions = [
  { name: "Playford", state: "SA" },
  { name: "Bundaberg", state: "QLD" },
  { name: "Onkaparinga", state: "SA" },
  { name: "Tamworth", state: "NSW" },
  { name: "Ipswich", state: "QLD" },
  { name: "Geelong", state: "VIC" },
  { name: "Gladstone", state: "QLD" },
  { name: "Rockhampton", state: "QLD" },
  { name: "Toowoomba", state: "QLD" },
  { name: "Onkaparinga", state: "QLD" },
];
function App() {
  let dispatch = useDispatch();
  let state = useSelector((state: any) => state?.Vendors);
  let data= useSelector((state:any)=>state.Domain)

  const getVendors = async () => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(`contacts?office_id=${user_id}`);
    dispatch(Vendors(response?.data || []));
  };

  const getSuburbStats = () => {
    const token = sessionStorage.getItem("Token");

    // console.log(state,"state")
    if (token && state.length < 1) {
      (async () => {
        // try { 
        // const { data, offices, other, retail, warehouse } = await API.get(
        //   "pricefinder/suburb/statistics"
        // );
        // const suburbResi = await API.get(`median/suburb/residential`)

        const regionsData = await API.get(`median/suburb/all`); //median/regions/all  median/suburb/all

 console.log(regionsData,"regionData")
        
     
        const retailregionData = await API.get(
          `pricefinder/comm/median-prices/Retail`
        );
       
        const officeregionData = await API.get(
          `pricefinder/comm/median-prices/Offices`
        );
       
       let url = `pricefinder/suburb/median-prices?type=Residential&`;
        for (let rg of resiRegions) {
          url += `regions[]=` + rg.name + `__` + rg.state + `&`;
        }
        const residentialregionData = await API.get(url);
 
        console.log(regionsData,"regionsData")
        const resdata = regionsData?.data?.find(
          (i: any) => i.propertyType === "Residential"
        ).regions;
        const officeData = regionsData?.data?.find(
          (i: any) => i.propertyType === "Offices"
        ).regions;
        const retailData = regionsData?.data?.find(
          (i: any) => i.propertyType === "Retail"
        ).regions;
        // const others = regionsData?.data?.find(
        //   (i: any) => i.propertyType === "Other"
        // ).regions;
        // const industrial = regionsData?.data?.find(
        //   (i: any) => i.propertyType === "Industrial Warehouse"
        // ).regions;
        let residentialdata = [];
        let officedata = [];
        // let otherdata = [];
        // let industrialdata = [];
        let retaildata = [];

        // console.log(resdata,"resdata")
      
        // for (let i = 0; i < 10; i++) {
        //   const res = resdata[i]; 
        //   const ret = retailData[i];
        //   const off = officeData[i];
        //   const other = others[i];
        //   const ind = industrial[i];

        //   if (res && res.median>0) {
        //     residentialdata.push({
        //       ...res,
        //       Suburb_Name: "",
        //       region: res.name,
        //       State: res.state,
        //       Median_Sale_Price: res.median,
        //       Median_Rental_Price: res.medianRental,
        //       postcode:res.postcode
        //     });
        //   }
        //   if (ret && ret.median>0) {
        //     retaildata.push({
        //       ...ret,
        //       Suburb_Name: "",
        //       region: ret.name,
        //       State: ret.state,
        //       Median_Sale_Price: ret.median,
        //       Median_Rental_Price: ret.medianRental,
        //       postcode:ret.postcode

        //     });
        //   }

        //   if (off && off.median>0) {
        //     officedata.push({
        //       ...off,
        //       Suburb_Name: "",
        //       region: off.name,
        //       State: off.state,
        //       Median_Sale_Price: off.median,
        //       Median_Rental_Price: off.medianRental,
        //       postcode:off.postcode

        //     });
        //     if (other && other.median>0) {
        //       otherdata.push({
        //         ...other,
        //         Suburb_Name: "",
        //         region: other.name,
        //         State: other.state,
        //         Median_Sale_Price: other.median,
        //         Median_Rental_Price: other.medianRental,
        //         postcode:other.postcode

        //       });
        //     }
        //     if (ind && ind.median>0) {
        //       industrialdata.push({
        //         ...ind,
        //         Suburb_Name: "",
        //         region: ind.name,
        //         State: ind.state,
        //         Median_Sale_Price: ind.median,
        //         Median_Rental_Price: ind.medianRental,
        //         postcode:ind.postcode

        //       });
        //     }
        //   } 
        // }
        for(let i=0;i<resdata?.length;i++){
          const res = resdata[i]; 
          if (res && res.median>0) {
            residentialdata.push({
              ...res,
              Suburb_Name: "", 
              region: res.name,
              State: res.state,
              Median_Sale_Price: res.median,
              Median_Rental_Price: res.medianRental, 
              postcode:res.postcode,
              growthPercent: res.growth_percent,
            });
          }
        }
        for(let i=0;i<officeData?.length;i++){
          const off = officeData[i];
          if (off && off.median>0) {
            officedata.push({
              ...off,
              Suburb_Name: "",
              region: off.name,
              State: off.state,
              Median_Sale_Price: off.median,
              Median_Rental_Price: off.medianRental,
              postcode:off.postcode,
              growthPercent: off.growth_percent,

            });
           
          } 
        }
        for(let i=0;i<retailData?.length;i++){
          const ret = retailData[i];
          if (ret && ret.median>0) {
            retaildata.push({
              ...ret,
              Suburb_Name: "",
              region: ret.name,
              State: ret.state,
              Median_Sale_Price: ret.median,
              Median_Rental_Price: ret.medianRental,
              postcode:ret.postcode,
              growthPercent: ret.growth_percent,

            });
          }
        }
        // for(let i=0;i<others.length;i++){
        //   const other = others[i];
        //   if (other && other.median>0) {
        //     otherdata.push({
        //       ...other,
        //       Suburb_Name: "",
        //       region: other.name,
        //       State: other.state,
        //       Median_Sale_Price: other.median,
        //       Median_Rental_Price: other.medianRental,
        //       postcode:other.postcode

        //     });
        //   }
        // }
        // for(let i=0;i<industrial.length;i++){
        //   const ind = industrial[i];
        //   if (ind && ind.median>0) {
        //     industrialdata.push({
        //       ...ind,
        //       Suburb_Name: "",
        //       region: ind.name,
        //       State: ind.state,
        //       Median_Sale_Price: ind.median,
        //       Median_Rental_Price: ind.medianRental,
        //       postcode:ind.postcode

        //     });
        //   }
        // }
        const medianPrices = {
          office: officedata,
          // other: otherdata,
          retail: retaildata,
          // industry: industrialdata,
          residential: residentialdata,
        };
// console.log(medianPrices,"medianPrices")
        dispatch(getMedianPrice(medianPrices));
        dispatch(
          setMedianData({
            retail: retailregionData?.data,
            office: officeregionData?.data,
            residential: residentialregionData?.data,
          })
        );
      })();
      
      getVendors();
      
    }
  };

  useEffect(() => {
    getSuburbStats();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <Navigation />;
}

export default App;
