import { useState, useEffect, useRef } from "react";
import classes from "../PinderPage.module.css";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { PinderTable } from "../pinder.style";
import "../stylesheet.css";
import { CustomDropDown } from "../pinder.style";
import arrowDown from "../../../assets/images/arrows-down-up.svg";
import { CustomPanel } from "../pinder.style";
import FilterValueImg from "../../../assets/images/cil-dollar.svg";
import { useFetchPinderSummary } from "../../../CustomHooks/useFetchPinderSummary";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import Loading from "../../Loading";
import ArrowIcon from "../../../assets/images/arrowW.png";
import ToolTip from "../../ToolTip/ToolTip";
import {
  sortOptions,
  LocalCommercialColumnData,
  LiveResidentialColumnData
} from "../../../utils/constants";
import { HandleFilter } from "../../../utils";
import { CustomPaginator } from "../CustomPaginator";
import moment from "moment";
import OverviewImg from "../../../assets/SVG/Overview.svg";
import { useFetchPinderLiveData } from "../../../CustomHooks/useFetchPinderLiveData";
import { useSelector } from "react-redux";

export const LiveResidential = ({ TopFilters, setTopFilters }) => {
  let VendorsData = useSelector((state) => state?.Vendors);
  const TableRef = useRef(null);
  const toast = useRef(null);
  const [DialogVisible, setDialogVisible] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedSort, setselectedSort] = useState({
    name: "A to Z",
    code: "AZ",
  });
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(
    LiveResidentialColumnData
  );

  const { isLoading, TableData,Total, Propertyoverview } = useFetchPinderLiveData(
    "listing/domain/resi",
    TopFilters, 
    "Residential"
  );
 
  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        className="p-button-sm"
        onClick={options.filterApplyCallback}
        severity="success"
      >
        Apply
      </Button> 
    );
  };

  const AscDecFilterTemplate = (options) => {
    const applySort = (value, e) => {
      if (value?.code == "AZ") {
        setFilterData(HandleFilter(options.field, 1, TableData));
      } else {
        setFilterData(HandleFilter(options.field, -1, TableData));
      }
    };

    const template = (options) => {
      const toggleIcon = options.collapsed
        ? "pi pi-chevron-right "
        : "pi pi-chevron-down";
      const className = `${options.className}`;

      return (
        <div className={className}>
          <div className="header-left-content">
            <img src={FilterValueImg} />
            <span className="title">Filter By Value</span>
          </div>
          <span className={toggleIcon} onClick={options.onTogglerClick}></span>
        </div>
      );
    };

    return (
      <>
        <div className={classes.filterheader}>
          <p>Filter</p>
          <span>Clear</span>
        </div>
        <div className={classes.filtersort}>
          <p>
            <img src={arrowDown} width="14px" height="14px" /> Sort by
          </p>
          <CustomDropDown
            value={selectedSort}
            onChange={(e) => {
              setselectedSort(e.value);
              applySort(e.value, e);
            }}
            options={sortOptions}
            optionLabel="name"
          />
        </div>
        <div>
          <div>
            <CustomPanel
              collapsed={!isPanelOpen}
              onToggle={() => setIsPanelOpen(!isPanelOpen)}
              headerTemplate={template}
              toggleable
            >
              <InputText
                placeholder={options?.placeholder}
                value={options.value}
                onChange={(e) =>
                  options.filterCallback(e.target.value, options.index)
                }
              />
            </CustomPanel>
          </div>
        </div>
      </>
    );
  };
  const renderHeader = (title) => {
    return (
      <div>
        <p>{title}</p>
      </div>
    );
  };
  const prevBtnNavigation = (e) => {
    e.preventDefault();
    var tableContainer = document.querySelector(".p-datatable-wrapper");
    tableContainer.scrollLeft -= 550;
  };

  const nextBtnNavigation = (e) => {
    e.preventDefault();
    var tableContainer = document.querySelector(".p-datatable-wrapper");
    tableContainer.scrollLeft += 550;
  };

  const SearchModeTemplate = (rowData) => {
    let listing =
      rowData.Listing.charAt(0).toUpperCase() + rowData.Listing.slice(1);
    let capitalLetterCount = 0;
    let indexSecondCapitalLetter = -1;
    for (let i = 0; i < listing.length; i++) {
      if (listing[i] === listing[i].toUpperCase()) {
        capitalLetterCount++;
        if (capitalLetterCount === 2) {
          indexSecondCapitalLetter = i;
          break;
        }
      }
    }
    if (indexSecondCapitalLetter != -1) {
      listing =
        listing.slice(0, indexSecondCapitalLetter) +
        " " +
        listing.slice(indexSecondCapitalLetter);
    }
    return <span>{listing}</span>;
  };

  const PriceBodyTemplate = (rowData) => {
    return <span style={{ marginLeft: "20px" }}>${rowData.Price}</span>;
  };
  const ImageTipComp = (Image) => {
    
    let [idx, setIdx] = useState("6");
    return (
      <div className="ToolTip-container">
        <div className="ToolTip">
          <ToolTip
            top="25px"
            width="320px"
            textAlign="left"
            show={idx === "hovering" ? true : false}
            txt={
              <img
                style={{ width:"300px",height:"300px" }}
                src={Image?.Image}
                alt="image"
              />
               
              
            }
          />
        </div>
        <a
            style={{ cursor:"pointer" }}
            onMouseEnter={() => setIdx("hovering")}
            onMouseLeave={() => setIdx(6)}
          >
            Image
          </a>
      </div>
    );
  };
  const ImageBodyTemplate=(rowData)=>{
   
    return(
      <>
      <ImageTipComp  Image={rowData.Image}/>
      </>
    )
  }
  const LinkBodyTemplate = (options) => {
    return (
      <a
        style={{ color: "blue" }}
        href={`${options?.Listing}`}
        target="_blank"
      >
        Link
      </a>
    );
  };
  const ActiveDateBodyTemplate = (rowData) => {
    let currentDate = new Date();
    let listingDate = new Date(rowData.DaysonMarket);
    let timeDifference = currentDate - listingDate;
    let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return <span>{daysDifference}</span>;
  };
  useEffect(() => {
    setFilterData(TableData);
  }, [TableData]);
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header={() => renderHeader("Overview")}
        className="pinder-Dialog"
        visible={DialogVisible}
        style={{ width: "408px", height: "550px", overflowY: "auto" }}
        modal
        onHide={() => setDialogVisible(false)}
      >
        <div className="pinderDialogBodyContainer">
          <div className="TotalContainer flex justify-content-between px-3 py-2">
            <p>Total</p>
            <p>{Total}</p>
          </div>
          {Propertyoverview?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-content-between px-3 py-2"
              >
                <p style={{ width: "150px" }}>{item.name}</p>
                <p>{item.total}</p>
              </div>
            );
          })}
        </div>
      </Dialog>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "7px",
          marginBottom: "20px",
        }}
      >
        <Button
          onClick={() => setDialogVisible(true)}
          className="flex justify-content-center align-items-center text-sm bg-white "
          style={{
            width: "35px",
            height: "36px",
            borderColor: "#cccccc",
            color: "#262660",
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
          }}
          type="button"
          severity="success"
        >
          <img style={{ width: "15px" }} src={OverviewImg} />
        </Button>
      </div>
      {isLoading ? (
        <Loading />
      ) : filterData.length > 0 ? (
        <>
          <div className={`d-flex relative`}>
            <span
              onClick={prevBtnNavigation}
              className={`${classes.prevNavigationBtn2} ${classes.activeImg}`}
            >
              <img
                src={ArrowIcon}
                className={classes.prevNavigationBtnImg}
                alt="<"
              />
            </span>
            <span
              onClick={nextBtnNavigation}
              className={`${classes.navigationBtn2} ${classes.activeImg}`}
            >
              <img
                src={ArrowIcon}
                className={classes.navigationBtnImg}
                alt=">"
              />
            </span>
          </div>
          <div className="scrollable-table-container"></div>

          <PinderTable
            ref={TableRef}
            editMode="cell"
            className="propertyDataTable"
            value={filterData}
            rows={Number(TopFilters.PerPage)}
            tableStyle={{ minWidth: "320rem", width: "100%" }}
            selectionMode="checkbox"
            selection={selectedCustomers}
            onSelectionChange={(e) => {
              setSelectedCustomers(e.value);
            }}
          >
            {visibleColumns.map((item, index) => {
              return (
                <Column
                  key={index}
                  field={item.field}
                  header={item.header}
                  style={item.style}
                  bodyStyle={item.bodyStyle}
                  body={
                    item.header == "Price"
                      ? PriceBodyTemplate
                      :item.header == "Image"
                      ? ImageBodyTemplate
                      : item.header == "Days on Market"
                      ? ActiveDateBodyTemplate
                      : item.header == "Listing"
                      && LinkBodyTemplate 
                  }
                  showFilterMatchModes={item.showFilterMatchModes}
                  showAddButton={item.showAddButton}
                  showFilterMenuOptions={item.showFilterMenuOptions}
                  filter={item.filter}
                  filterElement={AscDecFilterTemplate}
                  filterApply={filterApplyTemplate}
                ></Column>
              );
            })}
          </PinderTable>
          {/* <div>
            <CustomPaginator
              totalRecords={totalResult}
              Filters={TopFilters}
              FilterParamsData={TopFilters}
              setFilterParamsData={setTopFilters}
              totalPages={totalPages}
            />
          </div> */}
        </>
      ) : (
        "No Data Found"
      )}
    </div>
  );
};
