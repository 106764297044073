import React, { useState, useEffect, useRef } from "react";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  Text,
} from "./Header.styles";
import { Image } from "react-bootstrap";
import ExpandDown from "../../assets/images/Expand_down.svg";
import Searchbar from "../Searchbar/Searchbar";
import Avatar from "../Avatar/Avatar";
import { useSelector, useDispatch } from "react-redux";
import { setProfileData, ToggleSidebar } from "../../redux/actions/index";
import { Link } from "react-router-dom";
import { API } from "../../services/api";
import autosave from "../../assets/images/cloud-check.svg"

const Header = ({ headerPath, data, SearchQuery, setSearchQuery }) => {
  const Savestate=useSelector((state)=>state.AutoSave)
  const [patharray, setPatharray] = useState([]);
  const IconRef = useRef(null);
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state.SidebarReducer);
  let accountType = sessionStorage.getItem("accountType");

  const [Photo, setPhoto] = useState("");
  const UserData = useSelector((state) => state.UserProfile);

  let officeId = "610a0f1dc61edfcf62d62c52";

  const getOfficeData = async () => {
    let res = await API.get(`/offices/${officeId}`);

    setPhoto(res.data.logos);
    dispatch(
      setProfileData({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        email: res.data.email,
        mobile_no: res.data.mobile,
        logos: res.data.logos,
      })
    );
  };
  const getUserData = async () => {
    let id = sessionStorage.getItem("userId");
    let res = await API.get(`contacts/getbyid/${id}`);
    setPhoto(res.data.photo);

    dispatch(
      setProfileData({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        email: res.data.email,
        mobile_no: res.data.mobile,
        logos: res.data.photo,
      })
    );
  };

  useEffect(() => {
    const mypath = headerPath.slice(1).split("/");
    let d = sessionStorage.getItem("accountType");
    if (d === "Contacts") {
      getUserData();
    } else {
      getOfficeData();
    }
    
    setPatharray(mypath);
  }, [headerPath]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setPhoto(
  //     UserData?.logos?.replace(
  //       /https:\x2F\x2Fpropvesting-xm.s3.us-east-2.amazonaws.com\x2F/g,
  //       ""
  //     )
  //   );
  // }, [UserData]); // eslint-disable-line react-hooks/exhaustive-deps

  // const handleClickOutside = (event) => {
  //   if (IconRef.current && !IconRef.current.contains(event.target)) {
  //     IconRef.current.style.display === "flex"
  //       ? (IconRef.current.style.display = "none")
  //       : (IconRef.current.style.display = "flex");
  //   } else {
  //     IconRef.current.style.display === "flex"
  //       ? (IconRef.current.style.display = "none")
  //       : (IconRef.current.style.display = "flex");
  //   }
  // };

  const me = sessionStorage.getItem("me");



  
  return (
    <HeaderContainer>
      <div
        className="coming-DropDown animate__animated animate__fadeInDown"
        ref={IconRef}
      >
        Coming Soon
      </div>
      <HeaderLeft>
        <i
          className="pi pi-bars"
          onClick={() => {
            dispatch(ToggleSidebar(!mystate));
          }}
        ></i>
        <Text>Home</Text>
        {patharray.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item === "Dashboard" ? (
                ""
              ) : (
                <>
                  <Image src={ExpandDown} />
                  <Text
                    fontWeight={index === patharray.length - 1 ? "bold" : ""}
                    key={index}
                  >
                    {item === "Equity"
                      ? `${item} Calculator`
                      : item === "Vendor"
                      ? `${item} Detail`
                      : item === "Summary"
                      ? `Portfolio ${item}`
                      : item === "RiskProfiling"
                      ? "Risk Profiling"
                      : item=="IncomePlan"
                      ?"Income Plan"
                    :item}
                  </Text>
                </>
              )}
            </React.Fragment>
          );
        })}
      </HeaderLeft>
      <HeaderRight>
      {((patharray[1]=="Finances") &&(Savestate) ) &&   //&& (accountType!=="Master Admin")
         <div style={{
          fontFamily:"Inter",
          fontSize:"10px",
          fontWeight:"normal",
          color:"#323232",
          display:"flex",
          alignItems:"center",
          gap:"5px",
         
        }}> 
          <img src={autosave}/>
          Auto Save</div>
        }
        {accountType != "Contacts" && (
          <>
            <Searchbar
              SearchQuery={SearchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Vendors"
              FromHeader={true}
            />
            {/* <NotificationIcon onClick={handleClickOutside} /> */}
          </>
        )}
        
       
        <Link to="/Profile">
          <Avatar
            text={data && data.logo_name ? data.logo_name : me}
            width="46px"
            height="46px"
            fontSize="16px"
            textColor="var(--white)"
            imgSrc={Photo}
            data={UserData}
            userProfile={true}
          />
        </Link>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
