import React,{useState} from 'react'
import { TermsContainer } from './Terms.style'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button} from "react-bootstrap";
import myclasses from "./stylesheet.module.css";
import { useNavigate } from 'react-router-dom';
const TermsAndPolicy = () => {
  const navigate=useNavigate();
  const [products, setProducts] = useState([
    {purpose:"To enable you to access and use our platform and to provide our Services to you, including to provide you with a login, to assess your financial position and provide guidance on investment opportunities.",type:["Identity Data","Contact Data","Background Financial Data"]},
    {purpose:"To contact and communicate with you about our Services including in response to any support requests you lodge with us or other enquiries you make with us.",type:["Identity Data","Contact Data","Background Financial Data"]},
    {purpose:"",type:["Identity Data","Contact Data"]},
    {purpose:"To contact and communicate with you about any enquiries you make with us via our website.",type:["Identity Data","Contact Data","Financial Data","Transaction Data"]},
    {purpose:"For internal record keeping, administrative, invoicing and billing purposes.",type:["Identity Data","Technical and Usage Data"]},
    {purpose:"For analytics, market research and business development, including to operate and improve our Services, associated applications and associated social media platforms.",type:["Identity Data","Contact Data","Technical and Usage Data","Profile Data","Marketing and Communications Data"]},
    {purpose:"For advertising and marketing, including to send you promotional information about our events and experiences and information that we consider may be of interest to you.",type:["Identity Data","Contact Data","Profile Data","Interaction Data","Marketing and Communications Data"]},
    {purpose:"To run promotions, competitions and/or offer additional benefits to you.",type:["Identity Data","Contact Data","Professional Data"]},
    {purpose:"To comply with our legal obligations or if otherwise required or authorised by law. ",type:["Any relevant Personal Information"]},
  ]);
  const priceBodyTemplate = (product) => {
    return (
      <div >
         <ul>
        {product?.type?.map((item,index)=>{
          return(
           <li key={index}>
            {item}
           </li>
          ) 
        })}
        </ul>
      </div>
    );
};
const purposeBodyTemplate = (product) => {
  return (
    <div >
      {product.purpose}
    </div>
  );
};
  return (
    <TermsContainer>
<div className='Terms'>
 
  <h1 >PROPVESTING – PRIVACY POLICY</h1>
 
  <div className='subheading-container'>
  <h2 >The information we collect</h2>
  <p className='mt-2'><b>Personal information:</b> is information or an opinion, whether true or not and whether recorded in a material form or not, about an individual who is identified or reasonably identifiable.SA</p>
  <p className='mt-2'> The types of personal information we may collect about you include:</p>
  <ul>
    <li>
    <p className='mt-2'><b>Identity Data</b> including first name, middle name, last name, title, date of birth, job title, and photographic identification.</p>
    </li>
    <li>
    <p className='mt-2'><b>Contact Data</b> including billing address, email address and telephone numbers.</p>
    </li>
    <li> 
    <p className='mt-2'><b>Financial Data</b> including bank account and payment card details (through our third party payment processor, Stripe).</p>
    </li>
    <li>
    <p className='mt-2'><b>Background Financial Data</b> including your cashflow goal, risk profile, cash available, liquid assets, dependents, loans, annual expenses, property details or other details requested as part of our onboarding process to determine your financial position to supply the Services as well as to comply with our due diligence obligations, anti-money laundering laws and related ongoing monitoring commitments.</p>
    </li>
    <li>
    <p className='mt-2'><b>Transaction Data</b> including details about payments to you from us and from you to us and other details of products and services you have purchased from us or we have purchased from you.</p>
    </li>
    <li>
    <p className='mt-2'><b>Technical and Usage Data</b> including internet protocol (IP) address, your login data, your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour, information about your access and use of our website, including through the use of Internet cookies, your communications with our website, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider.</p>
    </li>
    <li>
    <p className='mt-2'><b>Profile Data</b> including your username and password for our Services, purchases or orders you have made with us, support requests you have made, content you post, send receive and share through our platform, information you have shared with our social media platforms, your interests, preferences, feedback and survey responses.</p>
    </li>
    <li>
    <p className='mt-2'><b>Interaction Data</b> including information you provide to us when you participate in any interactive features of our Services, including surveys, contests, promotions, activities or events. </p>
    </li>
    <li>
    <p className='mt-2'><b>Marketing and Communications Data</b> including your preferences in receiving marketing from us and our third parties and your communication preferences.</p>
    </li>
    <li>
    <p className='mt-2'><b>Professional data</b> including where you are a worker of ours or applying for a role with us, your professional history such as your previous positions and professional experience. </p>
    </li>
    <li>
    <p className='mt-2'><b>Sensitive information</b> is a sub-set of personal information that is given a higher level of protection. Sensitive information means information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation or practices, criminal records, health information or biometric information. In the course of providing our Services, we may collect, or come across such sensitive information in different situations, including during the course of understanding your background financial and asset position. We do not actively request sensitive information about you. If at any time we need to collect sensitive information about you, unless otherwise permitted by law, we will first obtain your consent and we will only use it as required or authorised by law.</p>
    </li>
  </ul>
  </div>
  <div className='subheading-container'>
  <h2 >How we collect personal information</h2>
  <p className='mt-2'> We collect personal information in a variety of ways, including:</p>
  <ul>
    <li>
    <p className='mt-2'><b>Directly:</b> We collect personal information which you directly provide to us, including when you register for an account, through the ‘contact us’ form on our website or when you request our assistance via email, or over the telephone.</p>
    </li>
    <li>
    <p className='mt-2'><b>Indirectly:</b> We may collect personal information which you indirectly provide to us while interacting with us, such as when you use our platform or our website, in emails, over the telephone and in your online enquiries.</p>
    </li>
    <li>
    <p className='mt-2'><b>From third parties:</b> We collect personal information from third parties, such as details of your use of our website from our analytics and cookie providers and marketing providers. See the “Cookies” section below for more detail on the use of cookies.</p>
    </li>
    <li>
    <p className='mt-2'><b>From publicly available sources:</b> We collect personal data from publicly available resources such as the Australian Securities and Investment Commission (ASIC) and professional networking sites such as LinkedIn.</p>
    </li>
    </ul>
  </div>
  <div className='subheading-container'>
  <h2 >Why we collect, hold, use and disclose personal information</h2>
  <DataTable value={products} showGridlines  className='termtable' >
                <Column field="purpose" header="Purpose of use / disclosure" body={purposeBodyTemplate} style={{width:"40rem"}}></Column>
                <Column field="type" header="Type of Personal Information" body={priceBodyTemplate}></Column>
                
            </DataTable>
  </div>
  <div className='subheading-container'>
  <h2 >Our disclosures of personal information to third parties</h2>
  <p className='mt-2'> We may disclose personal information to:</p>
  <ul>
    <li>
    <p className='mt-2'>our employees, contractors and/or related entities;</p>
    </li>
    <li>
    <p className='mt-2'>IT service providers, data storage, web-hosting and server providers such as Amazon Web Services;</p>
    </li>
    <li>
    <p className='mt-2'>marketing or advertising providers;</p>
    </li>
    <li>
    <p className='mt-2'>professional advisors, bankers, auditors, our insurers and insurance brokers;</p>
    </li>
    <li>
    <p className='mt-2'>payment systems operators;</p>
    </li>
    <li>
    <p className='mt-2'>our existing or potential agents or business partners;</p>
    </li>
    <li>
    <p className='mt-2'>anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;</p>
    </li>
    <li>
    <p className='mt-2'>courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;</p>
    </li>
    <li>
    <p className='mt-2'>courts, tribunals, regulatory authorities and law enforcement officers, as required or authorised by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights; and</p>
    </li>
    <li>
    <p className='mt-2'>any other third parties as required or permitted by law, such as where we receive a subpoena.</p>
    </li>
    </ul>
  </div>
  <div className='subheading-container'>
  <h2 >Overseas disclosure</h2>
  <p className='mt-2'>While we store personal information in Australia, where we disclose your personal information to the third parties listed above, these third parties may store, transfer or access personal information outside of Australia. We will only disclose your personal information overseas in accordance with the Australian Privacy Principles.</p>
  </div>
  <div className='subheading-container'>
  <h2 >Your rights and controlling your personal information</h2>
  <p className='mt-2'><b>Your choice:</b> Please read this Privacy Policy carefully. If you provide personal information to us, you understand we will collect, hold, use and disclose your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect our ability to provide our Services to you and your use of our Services.</p>
  <p className='mt-2'><b>Information from third parties:</b> If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us. </p>
  <p className='mt-2'><b>Anonymity:</b> Where practicable we will give you the option of not identifying yourself or using a pseudonym in your dealings with us.  </p>
  <p className='mt-2'><b>Restrict and unsubscribe:</b> To object to processing for direct marketing/unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.</p>
  <p className='mt-2'><b>Access:</b> You may request access to the personal information that we hold about you. An administrative fee may be payable for the provision of such information. Please note, in some situations, we may be legally permitted to withhold access to your personal information. If we cannot provide access to your information, we will advise you as soon as reasonably possible and provide you with the reasons for our refusal and any mechanism available to complain about the refusal. If we can provide access to your information in another form that still meets your needs, then we will take reasonable steps to give you such access.</p>
  <p className='mt-2'><b>Correction:</b> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to promptly correct any information found to be inaccurate, out of date, incomplete, irrelevant or misleading. Please note, in some situations, we may be legally permitted to not correct your personal information. If we cannot correct your information, we will advise you as soon as reasonably possible and provide you with the reasons for our refusal and any mechanism available to complain about the refusal.</p>
  <p className='mt-2'><b>Complaints:</b> If you wish to make a complaint, please contact us using the details below and provide us with full details of the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take in response to your complaint.</p>
  </div>
  <div className='subheading-container'>
  <h2 >Storage and security</h2>
  <p className='mt-2'>We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures, to safeguard and secure personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</p>
  <p className='mt-2'>While we are committed to security, we cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk.</p>
 
  </div>
  <div className='subheading-container'>
  <h2 >Cookies</h2>
  <p className='mt-2'>We may use cookies on our website from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do recognise you when you return to our online website and allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our online website with personal information, this information may be linked to the data stored in the cookie.</p>
  <p className='mt-2'>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.</p>


  </div>
  <div className='subheading-container'>
  <h2 >Links to other websites</h2>
  <p className='mt-2'>Our website may contain links to other party’s websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting those websites. Those websites are not governed by this Privacy Policy.</p>
  </div>
  <div className='subheading-container'>
  <h2 >Amendments</h2>
  <p className='mt-2'>We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our website. We recommend you check our website regularly to ensure you are aware of our current Privacy Policy.</p>
  <p className='mt-2'><b>For any questions or notices, please contact us at:</b></p>
  <p className='mt-2'>Propvesting Pty Ltd (ACN 662 099 461)</p>
  <p className='mt-2'><b>Email:</b> info@propvesting.com.au</p>
  </div>

  <Button className={myclasses.loginBtn} type="button" onClick={()=>navigate('/signin')}>
       Go Back To Login
      </Button>
 
</div>

    </TermsContainer>
  )
}
 
export default TermsAndPolicy