import styled from "styled-components";

export const PropertyCardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    height: 324.9px;
    border-radius: 8px;
  }
`;
export const PropertyBox = styled.div`
  margin-left: 10px; //22
  margin-right: 10px;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  span {
    white-space: nowrap;
    font-size: 13px;
    font-family: "Inter";
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    display: inline-block;
    width: 93px;
    font-weight: bold;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  @media (max-width: 1870px) {
    span{
      font-size: 13px;
    }
  }
  @media (max-width: 1599px) {
    span{
      font-size: 11px;
    }
  }
  @media (max-width: 1280px) {
    span{
      font-size: 10px;
    }
  }
`;
export const PropertyContent = styled.div`
  background-color: white;
  padding: 14px;
  padding-top: 20px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  .Property-Content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-family: Inter;
      font-size: 17.7px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 0px;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .Propery-body {
    display: flex;
    flex-direction: row;
    @media (max-width: 2560px) {
      gap: 20%;
    }
    @media (max-width: 2200px) {
      gap: 14%;
    }
    @media (max-width: 2000px) {
      gap: 11%;
    }
    @media (max-width: 1870px) {
      gap: 9%;
      .column {
        .column-content {
          h1 {
            font-size: 11px;
          }
          p {
            font-size: 10px;

          }
        }
      }
    }


    @media (max-width: 1599px) {
      gap: 3.5%;
      .column {
        .column-content {
          h1 {
            font-size: 10px;
          }
          p {
            font-size: 9px;

          }
        }
      }
    }
    @media (max-width: 1500px) {
      gap: 5%;
    }
    @media (max-width: 1430px) {
      gap: 4.5%;
    }
    @media (max-width: 1280px) {
      gap: 2.3%;

      .column {
        .column-content {
          h1 {
            font-size: 8px;
          }
          p {
            font-size: 9px;
  
          }
        }
      }
    }
  }
  .availableProperty {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .content {
      p {
        font-family: Inter;
        font-size: 13px;
      }
      h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-family: Inter;
        font-size: 12.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
    .column-content {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      gap: 5px;
      h1 {
        filter: ${(props) =>
          props.admin === true || props.pathname === "Profile"
            ? "blur(0px)"
            : props.idx > 1
            ? "blur(5px)"
            : "blur(0px)"};
        margin-bottom: 0px;
        font-family: Inter;
        font-size: 12.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
      p {
        margin-bottom: 0px;
        font-family: Inter;
        font-size: 12.8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }
`;
